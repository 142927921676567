<template>
  <div class="mt-5">
    <AddressForm
      class="mt-3"
      :values="address"
      :handleSubmit="() => {}"
      :skipLastStep="true"
      :showPrevious="false"
      :showNextButton="false"
      :isDisabled="true"
      :isLoading="false"
    />
  </div>
</template>

<script>
import AddressForm from '@/components/common/address/Form';

export default {
  name: 'Address',

  components: {
    AddressForm
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    address() {
      return this.company.related.address;
    }
  }
};
</script>

<style lang="scss">
.headquarters {
  &__title {
    padding: 20px 0px;
    font-weight: 800;
    // font-size: 14px;
    color: $black;
  }
}
</style>
