<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <SubcontractorForm
      :program-id="programId"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import SubcontractorForm from './Form';

const { mapFields } = createHelpers({
  getterType: 'teacher/create/getField',
  mutationType: 'teacher/create/updateField'
});

export default {
  components: {
    SubcontractorForm
  },

  props: {
    programId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations']),
    ...mapGetters('auth', ['getCompany'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createSubcontractor');
    }
  },

  methods: {
    ...mapActions({
      deleteItem: 'relationship/del/del'
    }),
    ...mapActions('teacher/create', {
      createTeacher: 'create'
    }),
    ...mapActions('learningactivitysubcontractor/create', {
      createLearningActivitySubcontractor: 'create'
    }),

    onSendForm(item) {
      //Create relation between activity and teacher (subcontractors)
      this.createLearningActivitySubcontractor({
        activity: item.activitySelected,
        subcontractor: item.subcontractorSelected,
        fee: parseFloat(item.fee)
      });

      this.createTeacher({
        learningActivities: [item.activitySelected],
        user: item.subcontractorSelected
      });
    }
  }
};
</script>
