<template>
  <div>
    <div class="text-center mt-4" v-if="!canAddLedger">
      <h6>There are no Ledgers to show yet</h6>
      <CustomButton
        type="Modal"
        :content="{ title: 'Add Ledger', modalType: 'createLedger' }"
      />
    </div>

    <b-row class="mt-4" v-else>
      <b-col cols="3">
        <b-form-group label="Sales VAT High" label-for="salesVatHigh">
          <b-form-input id="salesVatHigh" :value="item.salesVatHigh" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Sales VAT Low" label-for="salesVatLow">
          <b-form-input id="salesVatLow" :value="item.salesVatLow" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Sales Vat Low Low" label-for="salesVatLowLow">
          <b-form-input
            id="salesVatLowLow"
            :value="item.salesVatLowLow"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group label="Expenses Vat High" label-for="expensesVatHigh">
          <b-form-input
            id="expensesVatHigh"
            :value="item.expensesVatHigh"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group label="Expenses Vat Low" label-for="expensesVatLow">
          <b-form-input
            id="expensesVatLow"
            :value="item.expensesVatLow"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Expenses Vat Low Low"
          label-for="expensesVatLowLow"
        >
          <b-form-input
            id="expensesVatLowLow"
            :value="item.expensesVatLowLow"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group label="Aprova" label-for="aprovaTraining">
          <b-form-input
            id="aprovaTraining"
            :value="item.aprovaTraining"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Rii AprovaTraining"
          label-for="riiAprovaTraining"
          v-if="item.riiAprovaTraining"
        >
          <b-form-input
            id="riiAprovaTraining"
            :value="item.riiAprovaTraining"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group
          label="Rii Learning Materials"
          label-for="riiLearningMaterials"
        >
          <b-form-input
            id="riiLearningMaterials"
            :value="item.riiLearningMaterials"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Amplifica Vocational Education"
          label-for="amplificaVocationalEducation"
        >
          <b-form-input
            id="amplificaVocationalEducation"
            :value="item.amplificaVocationalEducation"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Rii Amplifica Vocational Education"
          label-for="riiAmplificaVocationalEducation"
        >
          <b-form-input
            id="riiAmplificaVocationalEducation"
            :value="item.riiAmplificaVocationalEducation"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Consumption"
          label-for="consumption"
          v-if="item.consumption"
        >
          <b-form-input id="consumption" :value="item.consumption" disabled>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group label="Hotel Room" label-for="hotelRoom">
          <b-form-input id="hotelRoom" :value="item.hotelRoom" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="ClassRoom" label-for="classRoom">
          <b-form-input id="classRoom" :value="item.classRoom" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Union Bibliotheca" label-for="unionBibliotheca">
          <b-form-input
            id="unionBibliotheca"
            :value="item.unionBibliotheca"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Aprova Fee"
          label-for="aprovaFee"
          v-if="item.aprovaFee"
        >
          <b-form-input id="aprovaFee" :value="item.aprovaFee" disabled>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-row class="mt-4 float-right">
        <b-col cols="12">
          <b-form-group class="float-right">
            <b-button type="button" variant="primary" @click="edit(item)"
              >Edit</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-row>

    <b-modal id="editLedger" title="Edit Ledger" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Ledger</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editLedger')"
        ></i>
      </template>
      <LedgerUpdate :ledger="editLedger" @updated="refreshData"></LedgerUpdate>
    </b-modal>

    <b-modal id="createLedger" title="Create Ledger" hide-footer size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Ledger</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createLedger')"
        ></i>
      </template>
      <LedgerCreate @created="refreshData"></LedgerCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import LedgerUpdate from './Update.vue';
import LedgerCreate from './Create.vue';
import CustomButton from '@/components/_ui/CustomButton';

export default {
  components: {
    LedgerUpdate,
    LedgerCreate,
    CustomButton
  },

  computed: {
    ...mapGetters('auth', ['getCompany', 'getCompanyId']),

    ...mapFields('ledger/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    item() {
      return this.$store.state.ledgers.list.items[0];
    },

    canAddLedger() {
      return this.$store?.state?.ledgers?.list?.items?.length > 0 || 0;
    }
  },

  data() {
    return {
      editLedger: null,
      deleting: {},
      page: 1
    };
  },

  mounted() {
    this.getLedgers(`${this.getCompanyId}/ledgers`);
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }

      this.refreshData();
    }
  },

  methods: {
    ...mapActions({
      getLedgers: 'ledgers/list/default',
      deleteItem: 'ledgers/del/del'
    }),

    changePage(page) {
      let request = `${this.getCompanyId}/ledgers`;
      this.page = page;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `?order[${this.sort.sortBy}]=${order}&page=${page}`;
      } else {
        request += `?page=${page}`;
      }

      this.getLedgers(request);
    },

    handleSortChange(sort) {
      this.sort = sort;
      const order = sort.sortDesc ? 'asc' : 'desc';

      this.getLedgers(
        `${this.getCompanyId}/ledgers?order[${this.sort.sortBy}]=${order}&`
      );
    },

    filter(filter) {
      this.getLedgers(`${this.getCompanyId}/ledgers?search=${filter}`);
    },

    refreshData() {
      this.getLedgers(`${this.getCompanyId}/ledgers?page=${this.page}`);
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this article ?')) {
        let itemId = item['@id'];
        this.deleting[itemId] = true;
        this.deleteItem({ '@id': itemId });
      }
    },

    deleteIsLoading(ledgerId) {
      return this.deleting.hasOwnProperty(ledgerId);
    },

    edit(item) {
      this.editLedger = item;
      this.$bvModal.show('editLedger');
    }
  }
};
</script>
