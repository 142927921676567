<template>
  <b-form @submit.prevent="onSendForm(item)">
    <div v-if="subcontractors.length > 0">
      <b-form-group
        label="Activity"
        label-for="learningActivities"
        :invalid-feedback="violations.learningActivities"
        :state="isValid('learningActivities')"
      >
        <b-form-select v-if="learningModulesIsLoading" disabled value="">
          <b-form-select-option selected value=""
            >Loading...
          </b-form-select-option>
        </b-form-select>
        <b-form-select
          v-else
          :state="isValid('learningActivities')"
          v-model="formMetadata.activitySelected"
          id="learningActivities"
          :options="learningActivitiesSelectItems"
          text-field="title"
          value-field="@id"
          required
        >
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Subcontractor Company"
        label-for="companies"
        :invalid-feedback="violations.companies"
        :state="isValid('companies')"
      >
        <b-form-select v-if="companyIsLoading" disabled value="">
          <b-form-select-option selected value=""
            >Loading...
          </b-form-select-option>
        </b-form-select>
        <b-form-select
          v-else
          :state="isValid('companies')"
          v-model="formMetadata.companySelected"
          id="companies"
          required
          @change="pickSubcontrator"
        >
          <b-form-select-option
            v-for="(company, idx) in subcontractors"
            :value="company.related['@id']"
            :key="company.related['@id']"
          >
            {{ company.related.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Teacher"
        label-for="subcontractor"
        :invalid-feedback="violations.subcontractor"
        :state="isValid('subcontractor')"
      >
        <b-form-select v-if="employeesIsLoading" disabled value="">
          <b-form-select-option selected value=""
            >Loading...
          </b-form-select-option>
        </b-form-select>
        <b-form-select
          v-else
          :state="isValid('subcontractor')"
          v-model="formMetadata.subcontractorSelected"
          id="companies"
          required
          :disabled="!formMetadata.companySelected"
        >
          <b-form-select-option
            v-for="(employee, index) in employeesItems"
            :value="employee.user['@id']"
            :key="index"
            >{{ employee.user.firstName }} {{ employee.user.firstName }} -
            {{ employee.user.email }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group label="Fee (%)" label-for="subcontratorFee">
        <b-form-input
          id="subcontratorFee"
          type="number"
          v-model="formMetadata.fee"
          min="0"
          max="100"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group class="float-right">
        <b-button
          @click="closeModal"
          class="mr-2"
          type="button"
          variant="secondary"
          v-if="!isLoading"
          >Cancel
        </b-button>

        <b-button type="submit" variant="primary" v-if="!isFormLoading"
          >{{ getButtonLabel }}
        </b-button>
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </div>
    <div v-else>
      Please add first subcontractors that have trainer employees.
    </div>
  </b-form>
</template>

<script>
import { get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    programId: {
      type: String,
      required: true
    },

    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      formMetadata: {
        companySelected: '',
        subcontractorSelected: null,
        fee: 0
      }
    };
  },

  created() {
    // Load activities if not preloaded
    this.learningModulesGetSelectItems({ page: `${this.programId}/modules` });
  },

  computed: {
    ...mapFields('learningmodule/list', {
      learningModulesIsLoading: 'isLoading',
      learningModulesSelectItems: 'selectItems'
    }),

    ...mapFields('company/list', {
      companyIsLoading: 'isLoading',
      companyItems: 'items'
    }),

    ...mapFields('companyuser/list', {
      employeesItems: 'items',
      employeesIsLoading: 'isLoading'
    }),

    learningActivitiesSelectItems() {
      if (!this.learningModulesSelectItems) {
        return [];
      }

      let activities = {};

      // Make it unique
      this.learningModulesSelectItems.forEach((m) => {
        m.activities.forEach((a) => {
          if (!activities[a['@id']]) {
            activities[a['@id']] = a;
          }
        });
      });

      return Object.values(activities);
    },

    // isLoading
    isFormLoading() {
      return this.isLoading;
    },

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    isUpdating() {
      return !!this.initialValues;
    },

    violations() {
      let errors = {};
      if (this.errors) {
        errors = { ...errors, ...this.errors };
      }
      return errors;
    },

    getButtonLabel() {
      return this.isUpdating ? 'Update Subcontractor' : 'Add Subcontractor';
    },

    subcontractors() {
      return this.companyItems.filter((item) => {
        return item.related.employees
          ? item.related.employees.length > 0
          : false;
      });
    }
  },

  watch: {
    'formMetadata.activitySelected': function (activityId) {
      this.item.learningActivities = [activityId];
    }
  },

  methods: {
    ...mapActions({
      learningModulesGetSelectItems: 'learningmodule/list/getSelectItems',
      getEmployees: 'companyuser/list/default',
      resetEmployees: 'companyuser/list/reset'
    }),

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    onSendForm() {
      this.handleSubmit(this.formMetadata);
    },

    closeModal() {
      this.$bvModal.hide('createSubcontractor');
    },

    pickSubcontrator(companyId) {
      this.getEmployees(`${companyId}/employees`);
    }
  },

  beforeDestroy() {
    this.resetEmployees();
  }
};
</script>
