<template>
  <form @submit.prevent="handleSubmit(item)">
    <b-form-group label="Name" label-for="name">
      <b-form-input
        id="name"
        :value="`${item.user.firstName} ${item.user.lastName}`"
        type="text"
        disabled
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Email Address" label-for="email">
      <b-form-input id="email" v-model="item.user.email" type="text" disabled>
      </b-form-input>
    </b-form-group>

    <b-form-group label="Primary Contact" label-for="default">
      <b-form-checkbox
        id="default"
        v-model="item.isDefault"
        :disabled="isSelectDisabled"
      ></b-form-checkbox>
    </b-form-group>

    <b-button-group class="float-right">
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button v-else disabled type="submit" variant="primary">
        <b-spinner small />
      </b-button>
    </b-button-group>
  </form>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isSelectDisabled: false
    };
  },

  mounted() {
    this.isSelectDisabled = this.item.isDefault;
  }
};
</script>
