<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div>
      <TabGroup :tabs="tabs" />
    </div>
  </b-container>
</template>

<script>
import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  name: 'ReservesButtonGroup',

  components: {
    TabGroup
  },

  data() {
    return {
      tabs: [
        {
          active: 'GeneralReserves',
          text: 'General Reserve',
          visible: true
        },
        {
          active: 'PaymentDeposits',
          text: 'Payment Deposits',
          visible: true
        },
        {
          active: 'TaxReserves',
          text: 'Tax Reserves',
          visible: true
        },
        {
          active: 'RIIReserves',
          text: 'RII Reserves',
          visible: true
        }
      ]
    };
  }
};
</script>
