<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <span v-if="step === 1">
      <h6>
        <i class="fa fa-building text-primary"></i
        ><strong class="ml-2">
          {{ getText }}
        </strong>
      </h6>

      <CompanyForm
        :skip-company-roles="skipCompanyRoles"
        :companyRoles="companyRoles"
        :skip-company-admin="skipCompanyAdmin"
        :return-if-exists="returnIfExists"
        :is-loading="isLoading"
        :handle-submit="onSendForm"
        :handle-retrieve="onRetrievedCompany"
        :values="item"
        :retrieved="retrieved"
        :errors="violations"
        :show-type-person="showTypePerson"
        :is-creating-accountant="isCreatingAccountant"
        :is-creating-sub-contractor="isCreatingSubContractor"
        :is-onboarding="isOnboarding"
        :role="role"
        :is-only-commercial="isOnlyCommercial"
        :is-creating-branch="isCreatingBranch"
        :is-creating-relation="isCreatingRelation"
        :is-creating-ubo="isCreatingUbo"
        :goToSecondStep="goToSecondStep"
        :availableCompanyTypes="availableCompanyTypes"
        :isRoleRequest="isRoleRequest"
        @type="getType"
        :block-branch="blockBranch"
      />
    </span>
    <!-- STEP 3: ADDRESS INFORMATION -->
    <span v-if="step === 2">
      <i class="fa fa-address-card text-primary"></i
      ><strong class="ml-2">
        {{ isCreatingUbo ? 'Add UBO' : 'Add Company' }} | User
      </strong>

      <UserInfo @onUserInfo="onUserInfo" />
    </span>

    <span v-if="step === 3">
      <h6>
        <i class="fa fa-address-card text-primary"></i
        ><strong class="ml-2">
          {{ isCreatingUbo ? 'Add UBO' : 'Add Company' }} | Address</strong
        >
      </h6>
      <AddressCreate
        @created="onAddressForm"
        @handlePrevious="handlePrevious"
        :skipLastStep="skipLastStep"
        :showPrevious="true"
        :initialValues="item.address"
        :is-loading="isLoading"
      />
    </span>

    <!-- STEP 3: ADMINSTRATOR (user) -->

    <span v-if="step === 4 && !skipLastStep">
      <h6>
        <i class="fa fa-user-plus text-primary"></i
        ><strong class="ml-2"
          >{{ isCreatingUbo ? 'Add UBO' : 'Add Company' }} | User
          Details</strong
        >
      </h6>
      <UserCreate
        :show-alert="false"
        @created="onSendUserForm"
        :skip-company-roles="skipCompanyRoles"
        :show-submit="showSubmit"
      />
    </span>

    <!-- STEP 4: Details -->

    <span v-if="step === 5">
      <h6>
        <i class="fa fa-info text-primary"></i
        ><strong class="ml-2">
          {{ isCreatingUbo ? 'Add UBO' : 'Add Company' }}| Info</strong
        >
      </h6>
      <CompanyForm
        :companyRoles="companyRoles"
        :skip-company-roles="skipCompanyRoles"
        :skip-company-admin="skipCompanyAdmin"
        :return-if-exists="returnIfExists"
        :is-loading="isLoading"
        :handle-submit="onSendForm"
        :values="item"
        :retrieved="retrieved"
        :errors="violations"
        :show-off-mode="true"
        :show-type-person="showTypePerson"
        :is-creating-sub-contractor="isCreatingSubContractor"
        :is-onboarding="isOnboarding"
        :role="role"
        :is-only-commercial="isOnlyCommercial"
        :is-creating-branch="isCreatingBranch"
        :is-creating-relation="isCreatingRelation"
        :is-creating-accountant="isCreatingAccountant"
        :is-creating-ubo="isCreatingUbo"
        :goToSecondStep="goToSecondStep"
        :availableCompanyTypes="availableCompanyTypes"
        :block-branch="blockBranch"
      />
    </span>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import CompanyForm from './Form';
import UserCreate from '../user/Create';
import { SwalAlert } from '../../../utils/swal';
import AddressCreate from '../../common/address/Create';
import { entityService as fetch, entityService } from '../../../utils/fetch';
import * as types from '../../../store/modules/company/update/mutation_types';
import SubmissionError from '../../../error/SubmissionError';
import { mapFields } from 'vuex-map-fields';
import UserInfo from './create/UserInfo.vue';

const STEP_SHOW_DISABLED_FORM = 5;

export default {
  components: {
    CompanyForm,
    AddressCreate,
    UserCreate,
    UserInfo
  },

  props: {
    /**
     * If true, return the company item if it already exists
     * Emit error instead of this is false
     */
    returnIfExists: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    skipLastStep: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    /**
     * When creating a company from another place than admin, we want to skip the company roles
     */
    skipCompanyRoles: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    /**
     * When creating a company from another place than admin, we can skip adding user admin
     */
    skipCompanyAdmin: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    companyRoles: {
      type: String,
      default: null
    },

    isCreatingRelation: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    showTypePerson: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    isCreatingAccountant: {
      type: Boolean,
      default: false
    },

    isCreatingSubContractor: {
      type: Boolean,
      default: false
    },

    isOnboarding: {
      type: Boolean,
      default: false
    },

    role: {
      type: String,
      default: null
    },

    parentId: {
      type: String,
      default: null
    },

    isOnlyCommercial: {
      type: Boolean,
      default: false
    },

    isCreatingBranch: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    blockBranch: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    isCreatingUbo: {
      type: Boolean,
      default: false
    },

    availableCompanyTypes: {
      type: Array,
      required: true
    },

    isRoleRequest: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.reset();
  },

  data() {
    return {
      step: 1,
      retrieved: false,
      item: {},
      showSubmit: true,
      address: null,
      goToSecondStep: false,
      companyType: null
    };
  },

  computed: {
    ...mapFields('company/create', [
      'error',
      'isLoading',
      'created',
      'violations'
    ]),
    ...mapFields('companyuser/create', {
      companyUserCreated: 'created'
    }),

    getText() {
      let text = 'Add Company | Company';

      if (this.isCreatingUbo) {
        text = 'Add Ubo | UBO';
      }

      if (this.companyType === 'OTHER' || this.companyType === 'PERSON') {
        text = 'Add Person | Person';
      }

      return text;
    }
  },

  watch: {
    created: function (created) {
      if (!created) {
        return;
      }

      // remove metadata
      if (localStorage.getItem('metadata')) {
        localStorage.removeItem('metadata');
      }

      if (this.item.type !== 'PERSON') {
        this.createAddress();
      } else {
        this.onFinishForm(false);
      }
    },

    companyUserCreated: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', this.created);
      // end?
      if (!this.isCreatingRelation) {
        this.onFinishForm();
      }
    }
  },

  methods: {
    ...mapActions('company/create', ['create', 'reset']),
    ...mapActions({
      companyUserCreate: 'companyuser/create/create'
    }),

    onRetrievedCompany(item) {
      this.item = item;
      this.step = STEP_SHOW_DISABLED_FORM;

      if (localStorage.getItem('metadata') !== null) {
        this.item.metadata = JSON.parse(localStorage.getItem('metadata'));
      }

      this.$emit('created', item, true);
    },

    onSendForm() {
      //Set isRoleRequest
      this.item.isRequestingRole = this.isRoleRequest;

      // To get to this step we always know that the user accepted that the filled company VAT is not valid so, we can make this false
      this.item.validateFiscalId = false;

      if (this.parentId) {
        this.item.parent = this.parentId;
      }

      this.item.status = 'ENABLED';

      if (this.companyRoles) {
        this.item[`is${this.companyRoles}`] = true;
      }

      if (localStorage.getItem('metadata') !== null) {
        this.item.metadata = JSON.parse(localStorage.getItem('metadata'));
      }

      // If type is person we must get the user first name, last name and email address
      if (this.item.type === 'PERSON' && !this.isOnboarding) {
        // We hve this info now, skip form
        this.onUserInfo({
          email: this.item.email,
          firstName: this.item.name,
          lastName: this.item.name
        });

        // this.step++;
      } else {
        this.step = 3;
      }
    },

    onSendUserForm(user) {
      const payload = {
        company: this.created['@id'],
        user: user['@id'],
        isAdmin: true
      };
      this.companyUserCreate(payload);
    },

    onAddressForm(address) {
      // Link them first
      const establishments = [];
      establishments.push({
        isDefault: true,
        address: address['@id']
      });

      this.item.establishments = establishments;

      this.create(this.item);
    },

    /**
     *
     * @param showAlert Show alert of successful operation
     */
    onFinishForm(showAlert = true) {
      this.$emit('created', this.created);

      if (showAlert) {
        this.$bvModal.hide('createCompany');
        this.showSubmit = false;
        SwalAlert({
          title: 'Company added',
          icon: 'user',
          type: 'success',
          buttons: {
            cancel: {
              label: 'Form another',
              type: 'warning',
              callback: () => {
                this.$bvModal.show('createCompany');
              }
            },
            confirm: {
              label: 'OK',
              type: 'success'
            }
          }
        });
      }
    },

    createAddress() {
      const payload = {
        address: this.address
      };

      entityService(this.created['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(payload)
      })
        .then((response) => response.json())
        .then((data) => {
          if (
            (this.isOnlyCommercial || this.isCreatingBranch) &&
            !this.isOnboarding
          ) {
            this.onFinishForm();
          } else if (this.skipCompanyAdmin) {
            this.step = STEP_SHOW_DISABLED_FORM;
            this.onFinishForm(false);
          } else if (this.isCreatingSubContractor) {
            this.step++;
          } else {
            SwalAlert({
              title: 'Admin',
              subTitle: 'Do you want to add a admin to this company?',
              icon: 'user',
              type: 'warning',
              buttons: {
                cancel: {
                  label: 'No',
                  type: 'warning'
                },
                confirm: {
                  label: 'Yes, go ahead',
                  type: 'success'
                }
              }
            }).then((result) => {
              if (result.isConfirmed) {
                this.step++;
              } else {
                this.onFinishForm(false);
              }
            });
          }
        })
        .catch((e) => {
          this.error = 'Something wrong happened';
        });
    },

    handlePrevious() {
      this.step = 1;
      this.goToSecondStep = true;
    },

    onUserInfo(item) {
      SwalAlert({
        title: 'Almost there...',
        subTitle: 'Do you want to fill in the address?',
        icon: 'user',
        type: 'warning',
        buttons: {
          cancel: {
            label: 'No, I will do it later',
            type: 'warning',
            callback: () => {
              this.create(this.item);
            }
          },
          confirm: {
            label: 'Yes',
            type: 'success',
            callback: () => {
              this.step += 2;
            }
          }
        }
      });
    },

    getType(type) {
      this.companyType = type;
    }
  }
};
</script>
