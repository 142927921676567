import ThemeList from '@/components/admin/theme/List';
import ThemeCreate from '@/components/admin/theme/Create';
import ThemeUpdate from '@/components/admin/theme/Update';
import ThemeShow from '@/components/admin/theme/Show';

export default [
  { name: 'ThemeList', path: '/themes/', component: ThemeList },
  { name: 'ThemeCreate', path: '/themes/create', component: ThemeCreate },
  { name: 'ThemeUpdate', path: '/themes/edit/:id', component: ThemeUpdate },
  { name: 'ThemeShow', path: '/themes/show/:id', component: ThemeShow }
];
