export const COUNTRYACCOUNTANTTYPE_CREATE_RESET =
  'COUNTRYACCOUNTANTTYPE_CREATE_RESET';
export const COUNTRYACCOUNTANTTYPE_CREATE_SET_CREATED =
  'COUNTRYACCOUNTANTTYPE_CREATE_SET_CREATED';
export const COUNTRYACCOUNTANTTYPE_CREATE_SET_ERROR =
  'COUNTRYACCOUNTANTTYPE_CREATE_SET_ERROR';
export const COUNTRYACCOUNTANTTYPE_CREATE_SET_VIOLATIONS =
  'COUNTRYACCOUNTANTTYPE_CREATE_SET_VIOLATIONS';
export const COUNTRYACCOUNTANTTYPE_CREATE_TOGGLE_LOADING =
  'COUNTRYACCOUNTANTTYPE_CREATE_TOGGLE_LOADING';
