<template>
  <div v-if="company" class="component">
    <div class="content shadow-sm">
      <div
        class="title mt-2 pb-4 d-flex justify-content-between align-items-center"
      >
        <div>
          <h3>Handshakes Preview</h3>
          <span
            >You can check the full page
            <router-link to="requests">here</router-link></span
          >
          <!--@TODO: Link to view details about this actions-->
        </div>
        <div class="actions">
          <button
            class="btn btn-transparent"
            @click="toggleCollapse = !toggleCollapse"
          >
            <i v-if="toggleCollapse" class="fa fa-chevron-up" />
            <i v-else class="fa fa-chevron-down" />
          </button>
        </div>
      </div>
      <b-collapse v-model="toggleCollapse">
        <HandshakeView
          :handshakeUrl="handshakeUrl"
          :activeFields="computedFields"
          :disableActions="disableActions"
        />
      </b-collapse>
    </div>
  </div>
</template>
<script>
import HandshakeView from '@/components/handshake/List';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'DashActions',
  components: {
    HandshakeView
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    companyIsLoading: {
      type: Boolean,
      default: true
    },
    refreshData: {
      type: Function
    },
    requestType: {
      type: String,
      default: 'Pending'
    }
  },
  data() {
    return {
      toggleCollapse: true,
      content: {
        title: '',
        status: ''
      },
      disableActions: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId', 'isRole']),

    handshakeUrl() {
      if (this.content.request) {
        return `/handshakes?${this.content.request}`;
      }
      return '/handshakes';
    },

    computedFields() {
      if (this.isRole('ROLE_ACCOUNTANT')) {
        return ['company.name', 'type', 'status', 'actions'];
      }

      if (this.isRole('ROLE_PROVIDER') || this.isRole('ROLE_ADMIN')) {
        return ['type', 'company.name', 'user.email', 'actions', 'status'];
      }

      if (this.isRole('ROLE_USER')) {
        return ['type', 'company.name', 'user.email', 'actions', 'status'];
      }
      return '';
    }
  },

  beforeMount() {
    this.setContent();
  },
  methods: {
    ...mapActions({
      getHandshakes: `handshake/list/default`
    }),
    setContent() {
      const status = this.requestType;

      if (status === 'Rejected') {
        this.content.request = 'status=REJECTED';
      } else if (status === 'History') {
        this.content.request = 'status=ACCEPTED';
        this.disableActions = true;
      } else {
        this.content.request = 'status=PENDING';
        this.disableActions = false;
      }

      if (this.handshakeUrl) {
        this.getHandshakes(`${this.handshakeUrl}`);
      }
    }
  }
};
</script>
