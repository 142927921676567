<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Name"
      label-for="settings_name"
      :invalid-feedback="violations.name"
      :state="!isValid('settings_name')"
    >
      <b-form-input
        id="settings_name"
        v-model="item.name"
        type="text"
        required="required"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Type"
      label-for="type"
      :invalid-feedback="violations.type"
      :state="!isValid('type')"
    >
      <b-form-select
        v-model="item.type"
        :options="typeOptions"
        class="font-size form-input"
        @change="fetchOptions"
        required
      ></b-form-select>
    </b-form-group>

    <div>
      <b-form-group
        v-if="item.type === 'Company'"
        label="Company"
        label-for="company"
        :invalid-feedback="violations.company"
        :state="!isValid('company')"
      >
        <b-form-select v-if="contentIsLoading" disabled value="">
          <b-form-select-option selected value=""
            >Loading...</b-form-select-option
          >
        </b-form-select>
        <b-form-select
          v-else
          id="settings_company"
          v-model="item.company"
          required
        >
          <b-form-select-option
            v-for="selectItem in companySelectItems"
            :key="selectItem['@id']"
            :value="selectItem['@id']"
            >{{ selectItem.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        v-else-if="item.type === 'Protocol'"
        label="Protocol"
        label-for="protocol"
        :invalid-feedback="violations.protocol"
        :state="!isValid('protocol')"
      >
        <b-form-select v-if="contentIsLoading" disabled value="">
          <b-form-select-option selected value=""
            >Loading...</b-form-select-option
          >
        </b-form-select>
        <b-form-select
          v-else
          id="protocol"
          v-model="item.protocol"
          class="form-control"
        >
          <b-form-select-option
            v-for="selectItem in protocolSelectItems"
            :key="selectItem['@id']"
            :value="selectItem['@id']"
            >{{ selectItem.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        v-else-if="item.type === 'User'"
        label="User"
        label-for="user"
        :invalid-feedback="violations.user"
        :state="!isValid('user')"
      >
        <b-form-select v-if="contentIsLoading" disabled value="">
          <b-form-select-option selected value=""
            >Loading...</b-form-select-option
          >
        </b-form-select>
        <b-form-select
          v-else
          id="companyuser_user"
          v-model="item.user"
          class="form-control"
        >
          <b-form-select-option
            v-for="selectItem in userSelectItems"
            :key="selectItem['@id']"
            :value="selectItem['@id']"
            >{{ selectItem.firstName }} - {{ selectItem.email }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        v-else-if="item.type === 'Value'"
        label="Value"
        label-for="value"
        :invalid-feedback="violations.value"
        :state="!isValid('value')"
      >
        <b-form-input
          id="value"
          type="text"
          v-model="item.value"
          placeholder="Type value..."
        >
        </b-form-input>
      </b-form-group>
    </div>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    //first fetch
    this.fetchInitialState();

    if (this.item.protocol) {
      this.$set(this.item, 'protocol', this.item.protocol['@id']);
    }

    if (this.item.company) {
      this.$set(this.item, 'company', this.item.company['@id']);
    }

    if (this.item.user) {
      this.$set(this.item, 'user', this.item.user['@id']);
    }
  },

  computed: {
    ...mapFields('company/list', {
      companySelectItems: 'selectItems'
    }),

    ...mapFields('protocol/list', {
      protocolSelectItems: 'items'
    }),

    ...mapFields('user/list', {
      userSelectItems: 'selectItems'
    }),

    contentIsLoading() {
      return (
        this.$store.state.company.list.isLoading ||
        this.$store.state.user.list.isLoading ||
        this.$store.state.protocol.list.isLoading
      );
    },

    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  data() {
    return {
      typeOptions: ['Protocol', 'Company', 'User', 'Value']
    };
  },

  methods: {
    ...mapActions({
      companyGetSelectItems: 'company/list/getSelectItems',
      protocolGetSelectItems: 'protocol/list/default',
      userGetSelectItems: 'user/list/getSelectItems'
    }),

    fetchOptions(value) {
      if (value === 'Company') {
        this.companyGetSelectItems({ params: { isAmplifica: true } });
        return;
      }

      if (value === 'Protocol') {
        this.protocolGetSelectItems();
        return;
      }

      if (value === 'User') {
        this.userGetSelectItems();
        return;
      }
    },

    fetchInitialState() {
      if (this.item.type) {
        this.fetchOptions(this.item.type);
      }
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createSettings');
      this.$bvModal.hide('editSettings');
    }
  }
};
</script>
