<template>
  <div>
    <b-form @submit.prevent="doRolePick">
      <b-form-group>
        <b-form-select class="form-control" v-model="form.company">
          <b-form-select-option value="" disabled
            >Select your company</b-form-select-option
          >
          <b-form-select-option
            v-for="(company, idx) in getAvailableUserCompanies"
            :value="company.id"
            :key="company.id"
          >
            {{ company.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group v-model="form.role">
        <b-form-select v-model="form.role" class="form-control" name="role">
          <b-form-select-option value="" disabled
            >Select your role</b-form-select-option
          >
          <b-form-select-option
            v-for="(role, idx) in getAvailableUserRoles"
            :value="role.id"
            :key="idx"
          >
            {{ role.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group>
        <b-button variant="primary" block type="submit" v-if="!loading"
          >Proceed to Dashboard</b-button
        >
        <b-button variant="primary" block type="submit" v-else>
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
/** eslint-disable */
import { mapGetters, mapActions } from 'vuex';
import {
  AUTH_LOGIN_CONTEXT,
  AUTH_REFRESH_TOKEN
} from '../../store/modules/auth/actions';
import { mapFields } from 'vuex-map-fields';
import helpers from '@/mixins/helpers';

export default {
  name: 'RoleView',

  mixins: [helpers],

  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      errors: [],
      form: {
        role: '',
        company: ''
      }
    };
  },
  created() {
    // If only has 1 ROLE and 1 COMPANY, select and go
    if (
      this.getAvailableUserCompanies.length === 1 &&
      this.getAvailableUserRoles.length === 1
    ) {
      this.form.role = this.getAvailableUserRoles[0];
      this.form.company = this.getAvailableUserCompanies[0].id;
      this.doRolePick();
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser', 'getToken']),
    /**
     * Get from the token of user
     * @returns {*}
     */
    getAvailableUserRoles: function () {
      let roles = [];

      this.getUser.entity.roles.forEach((role) => {
        roles.push({
          id: role,
          name: this.parseRoleName(role)
        });
      });

      return roles;
    },
    /**
     * Call the User entity
     * @returns {{name: string, id: number}[]}
     */
    getAvailableUserCompanies() {
      let companies = [];

      this.getUser.entity.companies.forEach((company) => {
        companies.push({
          id: this.parseRelationToId(company.company['@id']),
          name: company.company.name,
          isAdmin: company.isAdmin
        });
      });

      return companies;
    }
  },

  methods: {
    ...mapActions('auth', [AUTH_LOGIN_CONTEXT, AUTH_REFRESH_TOKEN]),
    ...mapActions({
      retrieve: 'user/show/retrieve'
    }),

    // checkLogin() {
    //   if (this.isAuthenticated) {
    //     console.warn('User ended up in / with login');
    //     this.$router.push('/dashboard');
    //   }
    // },
    doRolePick() {
      // Loading!
      this.loading = true;

      // Send to server
      this.AUTH_LOGIN_CONTEXT({
        role: this.form.role,
        company: this.form.company
      })
        .then(() => {
          // Refresh token so we get the new attributes
          this.AUTH_REFRESH_TOKEN({
            refresh_token: this.getToken.refresh_token
          })
            .then(() => {
              // All good!
              this.$router.push('/dashboard');
            })
            .catch((err) => {
              this.errors.push(err);
            });
        })
        .catch((err) => {
          this.errors.push(err);
        })
        .finally(() => {
          // After all is done, remove loading
          this.loading = false;
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
form {
  color: white;
  margin-top: 35px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;

  .form-group {
    margin-bottom: 5px;
  }

  button {
    width: 100%;
    height: 45px;
    //line-height: 45px;
    padding: 0px 20px;
    line-height: 45px;
    //font-size: 16px;
    border: 0px solid;
    font-weight: 400;
    color: $white;
  }
}

a.text-center {
  color: $gray-500;
}

.row {
  width: 100%;
  display: flex;
}
</style>
