<template>
  <b-container class="px-4" fluid>
    <b-form @submit.prevent="handleSubmit">
      <b-form-group label="Description" label-for="description">
        <b-form-input id="description" v-model="item.description" type="text">
        </b-form-input>
      </b-form-group>
      <b-form-group label="VAT Number" label-for="fiscal_id">
        <b-form-input id="fiscal_id" v-model="item.fiscalId" type="text">
        </b-form-input>
      </b-form-group>

      <b-form-group label="Headquarter" label-for="headquarter">
        <b-form-checkbox
          id="headquarter"
          v-model="item.isDefault"
          :checked="true"
          :unchecked-value="false"
          :disabled="isSelectDisabled"
        ></b-form-checkbox>
      </b-form-group>
    </b-form>
    <AddressForm
      :is-loading="isLoading"
      :errors="violations"
      :handle-submit="onSendAddress"
      :values="item.address"
      :skipLastStep="true"
      :showPrevious="false"
    />
  </b-container>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import AddressForm from '../common/address/Form';

const { mapFields } = createHelpers({
  getterType: 'address/create/getField',
  mutationType: 'address/create/updateField'
});

export default {
  components: {
    AddressForm
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  data() {
    return {
      isSelectDisabled: false
    };
  },

  mounted() {
    this.isSelectDisabled = this.item.isDefault;
  },

  methods: {
    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },
    onSendAddress(item) {
      const body = {
        address: item,
        isDefault: this.item.isDefault ? this.item.isDefault : false,
        description: this.item.description,
        fiscalId: this.item.fiscalId
      };

      this.handleSubmit(body);
    }
  }
};
</script>

<style scoped lang="scss">
button {
  // font-size: 16px !important;
  border-radius: 8px !important;
  padding: 0px 66px !important;
  background-color: $success !important;
  &:hover {
    background-color: $secondary !important;
  }
}
</style>
