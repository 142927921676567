import ScheduleList from '@/components/dashboard/program/schedule/List.vue';
import ScheduleUpdate from '@/components/dashboard/program/schedule/Update.vue';

import Default from '@/views/dashboard/Programs/Default';

export default [
  {
    path: '/programs/schedule',
    component: Default,
    children: [
      {
        name: 'ScheduleList',
        path: '/programs/schedule/',
        component: ScheduleList
      },
      {
        name: 'ScheduleUpdate',
        path: '/programs/schedule/:id/edit',
        component: ScheduleUpdate
      }
    ]
  }
];
