<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <GroupForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
      :program="program"
    />
    <b-spinner v-else class="text-center" size="lg"></b-spinner>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import GroupForm from './Form.vue';

export default {
  components: {
    GroupForm
  },

  props: {
    groupId: {
      type: String,
      required: true
    },

    program: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapFields('group/create', {
      created: 'created'
    }),
    ...mapFields('group/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$bvModal.hide('editGroup');
      this.$emit('updated', updated);
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.groupId);
  },

  methods: {
    ...mapActions({
      createReset: 'group/create/reset',
      retrieve: 'group/update/retrieve',
      updateReset: 'group/update/reset',
      update: 'group/update/update',
      updateRetrieved: 'group/update/updateRetrieved'
    }),

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm() {
      this.update(this.retrieved);
    }
  }
};
</script>
