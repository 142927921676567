<template>
  <div>
    <div v-if="!isLoading">
      <b-form-row
        v-for="(item, idx) in items"
        :key="item['@id']"
        class="bgGroup p-4 mb-2 font-weight-bolder"
      >
        <b-col cols="3">
          <b-icon
            v-if="item.learners.length > 0"
            icon="check-circle-fill"
            variant="success"
          ></b-icon>
          <b-icon v-else icon="check-circle-fill" variant="warning"></b-icon>
          <span class="ml-2">Group {{ idx + 1 }}: {{ item.name }}</span>
          <a href="#" @click.prevent="edit(item)">
            <b-icon icon="pencil"></b-icon>
          </a>
        </b-col>
        <b-col cols="4">Learners in group: {{ item.learners.length }}</b-col>
        <b-col cols="5" class="text-right">
          <b-spinner v-if="deleteIsLoading(item)" small></b-spinner>
          <span v-else-if="!readOnly">
            <b-button @click="editLearners(item)" variant="success" class="mr-2"
              >Manage Learners</b-button
            >
            <b-button @click="del(item)" variant="danger">
              <span class="fa fa-trash text-info"></span>
            </b-button>
          </span>
        </b-col>
      </b-form-row>

      <b-form-row class="bgGroup bg-white p-4">
        <b-col cols="12">
          Group {{ numberGroups + 1 }}:
          <b-button
            variant="info"
            v-b-modal:createGroup
            class="ml-4 bg-transparent float-right"
            >Create group</b-button
          >
        </b-col>
      </b-form-row>
    </div>
    <div v-else>
      <b-spinner size="lg"></b-spinner>
    </div>

    <!-- Edit Group modal -->
    <b-modal id="editGroup" title="Edit Group" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Group</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editGroup')"
        ></i>
      </template>

      <GroupUpdate
        @updated="refreshData"
        :group-id="editGroup"
        :program="program"
      ></GroupUpdate>
    </b-modal>

    <!-- Edit Group modal -->
    <b-modal id="editLearners" title="Manage Learners" hide-footer size="lg">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Manage Learners</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editLearners')"
        ></i>
      </template>

      <GroupLearnersUpdate
        @updated="refreshData"
        :program="program"
        :group-id="editGroup"
      ></GroupLearnersUpdate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import GroupUpdate from './Update.vue';
import GroupLearnersUpdate from './Learners/Update.vue';

export default {
  components: {
    GroupUpdate,
    GroupLearnersUpdate
  },

  props: {
    program: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  data() {
    return {
      editGroup: null,
      deleting: {}
    };
  },

  computed: {
    ...mapFields('group/del', {
      deleted: 'deleted'
    }),
    ...mapFields('group/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view',
      totalItems: 'totalItems'
    }),

    programId() {
      return this.program['@id'];
    },

    numberGroups() {
      if (this.isLoading) {
        return 0;
      }
      return this.items.length || 0;
    },

    getGroups() {
      if (this.isLoading) {
        return [];
      }
      return this.items;
    },

    getLearners() {
      if (this.isLoading) {
        return 0;
      }
      let buffer = 0;
      for (let g in this.items) {
        buffer += this.items[g].learners.length;
      }
      return buffer;
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }

      this.$emit('deleted', deleted);
      this.refreshData();
    }
  },

  created() {
    this.getPage(`${this.programId}/learner-groups`);
  },

  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId == 'editLearners') {
        this.closeManageLearner();
      }
    });
  },

  methods: {
    ...mapActions({
      getPage: 'group/list/default',
      deleteItem: 'group/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(`${this.programId}/learner-groups`);
    },

    edit(item) {
      this.editGroup = item['@id'];
      this.$bvModal.show('editGroup');
    },

    editLearners(item) {
      this.editGroup = item['@id'];
      this.$bvModal.show('editLearners');
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this group?')) {
        let itemId = item['@id'];
        this.deleting[itemId] = true;
        this.deleteItem({ '@id': itemId });
      }
    },

    deleteIsLoading(item) {
      return this.deleting.hasOwnProperty(item['@id']);
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    },
    isScheduled(groupId) {
      return true;
    },
    closeManageLearner() {
      this.$bvModal.hide('editLearners');
      this.refreshData();
    }
  }
};
</script>

<style>
.bgGroup {
  background: #f4f5f7;
  border: 2px solid #e3e5ea;
  border-radius: 8px;
}
</style>
