<template>
  <div class="Main">
    <Menu></Menu>
    <router-view></router-view>
  </div>
</template>

<script>
import Menu from '../../components/admin/Menu';

export default {
  name: 'AdminMain',
  components: {
    Menu
  }
};
</script>
