<template>
  <div>
    <Form @created="created" />
  </div>
</template>

<script>
import Form from './Form';
import { SwalAlert } from '../../../../utils/swal';

export default {
  components: {
    Form
  },

  methods: {
    created() {
      this.$bvModal.hide('importProspect');
      this.$emit('created');
      SwalAlert({
        title: 'Prospect import completed',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('importProspect');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {
              return;
            }
          }
        }
      });
    }
  }
};
</script>
