<template>
  <div>
    <CustomTable :fields="fields" list="program" @changePage="changePage">
      <template v-slot:actions="data">
        <span
          class="program__accept"
          @click="proposalAcceptOrReject(data.item['@id'], 'to_accepted')"
        >
          <i class="fas fa-check"></i>
        </span>
        <span
          class="program__reject ml-3"
          @click="
            proposalAcceptOrReject(data.item['@id'], 'to_customer_rejected')
          "
        >
          <i class="fas fa-times"></i>
        </span>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import programs from '@/mixins/programs';
import helpers from '@/mixins/helpers';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'ProposalList',

  components: { CustomTable },

  mixins: [helpers, programs],

  data() {
    return {
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'customer.name', label: 'Beneficiary', sortable: true },
        { key: 'accountant.name', label: 'Accountant', sortable: true },
        { key: 'theme.name', label: 'Theme', sortable: true },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.price);
          }
        },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      page: 1
    };
  },
  computed: {
    request() {
      let request = '/programs?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status=PROPOSED`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  created() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    handleResult(type) {
      this.isLoading = false;
      if (type === 'to_customer_rejected') {
        SwalAlert({
          title: 'Your request was rejected',
          icon: 'user',
          type: 'success',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'success',
              callback: () => {
                this.$router.push({ name: 'CancelledList' });
              }
            }
          }
        });
      } else {
        SwalAlert({
          title: 'Your request was rejected',
          icon: 'user',
          type: 'success',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'success',
              callback: () => {
                this.$router.push({ name: 'ScheduleList' });
              }
            }
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
.program {
  &__accept {
    cursor: pointer;

    .fas {
      color: $success;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__reject {
    cursor: pointer;

    .fas {
      color: $danger;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
