import AdministrationInvoiceablesList from '@/components/dashboard/administration/Invoiceables/List';
import AdministrationInvoicesList from '@/components/dashboard/administration/Invoices/List';
import AdministrationShareablesList from '@/components/dashboard/administration/Shareables/List';
import FinancialImportImportedList from '@/components/dashboard/financial-import/imported/List';

export default [
  {
    name: 'AdministrationInvoiceables',
    path: '/administration/invoiceables',
    component: AdministrationInvoiceablesList
  },
  {
    name: 'AdiministrationInvoices',
    path: '/administration/invoices',
    component: AdministrationInvoicesList
  },
  {
    name: 'AdministrationShareables',
    path: '/administration/shareables',
    component: AdministrationShareablesList
  },
  {
    name: 'FinancialImportImported',
    path: '/administration/financial-import/imported',
    component: FinancialImportImportedList
  }
];
