<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div>
      <TabGroup :tabs="tabs" :company="retrieved" />
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  name: 'StructureButtonGroup',

  components: {
    TabGroup
  },

  data() {
    return {
      tabs: [
        {
          active: 'CompanyPendingRequests',
          text: 'Pending',
          visible: true
        },
        {
          active: 'CompanyRejectedRequests',
          text: 'Rejected',
          visible: true
        },
        {
          active: 'CompanyAcceptedRequests',
          text: 'History',
          visible: true
        }
      ]
    };
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapFields('company/show', ['retrieved']),

    getCompanyId() {
      return this.getUser.company.entity.company['@id'];
    }
  },

  mounted() {
    this.retrieve(this.getCompanyId);
  },

  methods: {
    ...mapActions({
      retrieve: 'company/show/retrieve'
    })
  }
};
</script>
