<template>
  <b-form @submit.prevent="onSendForm">
    <b-row>
      <b-col cols="6">
        <b-form-group label="Company Name" label-for="name">
          <b-form-input id="name" :value="company.name" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Email Address" label-for="email">
          <b-form-input id="email" v-model="form.email" type="email">
          </b-form-input>
        </b-form-group>

        <b-form-group label="VAT Number" label-for="fiscal_id">
          <b-form-input id="fiscal_id" :value="company.fiscal_id" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Sector" label-for="company_sector">
          <b-form-select
            id="company_sector"
            :options="sectorItems"
            v-model="form.sector"
            text-field="name"
            value-field="@id"
            type="text"
          >
          </b-form-select>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Trade Name" label-for="tradename">
          <b-form-input id="tradename" v-model="form.tradename"> </b-form-input>
        </b-form-group>

        <b-form-group label="Phone Number" label-for="phone">
          <b-form-input id="phone" v-model="form.phone"> </b-form-input>
        </b-form-group>

        <b-form-group label="Chamber of Commerce" label-for="chamberOfCommerce">
          <b-form-input
            id="chamberOfCommerce"
            :value="company.chamberOfCommerce"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="RII Reference"
          label-for="riiReference"
          v-if="company.RIIReference"
        >
          <b-form-input
            id="riiReference"
            :value="company.RIIReference"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group class="float-right">
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { entityService } from '../../../../../../utils/fetch';
import { SwalAlert } from '../../../../../../utils/swal';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import helpers from '@/mixins/helpers';

export default {
  mixins: [helpers],
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      isLoading: false,
      form: {}
    };
  },

  computed: {
    ...mapGetters('auth', ['isRole']),
    ...mapFields('sector/list', {
      sectorItems: 'selectItems'
    })
  },

  mounted() {
    this.getSectorItems();

    this.form.tradename = this.company.tradename;
    this.form.sector = this.company.sector ? this.company.sector['@id'] : '';
    this.form.email = this.company.email;
    this.form.phone = this.company.phone;
  },

  methods: {
    ...mapActions({
      getSectorItems: 'sector/list/getSelectItems'
    }),

    onSendForm() {
      this.isLoading = true;

      entityService(this.company['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(this.form)
      })
        .then((response) => response.json())
        .then((data) => {
          this.$emit('updated', this.form);

          this.$bvModal.hide('updateContactInformation');
          SwalAlert({
            title: 'Contact Information updated',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'Form another',
                type: 'warning',
                callback: () => {
                  this.$bvModal.show('updateContactInformation');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Company not updated',
            description: e.message,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
