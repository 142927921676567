<template>
  <div class="pt-5">
    <b-row class="mb-4 pt-4">
      <b-col cols="12">
        <b-alert
          v-if="handshakeStatus === 'PENDING' && !isRole('ROLE_PROVIDER')"
          variant="danger"
          show
        >
          <h4 class="font-weight-bold">You are reviewing this handshake.</h4>
          <p>
            Once you ACCEPT it, the CREATOR is not able to correct any Customer
            data
          </p>
          <br />

          <b-button
            variant="danger"
            @click="handshakeAcceptOrReject(handshakeId, 'to_rejected')"
            >Reject</b-button
          >

          <b-button
            class="ml-2"
            variant="success"
            @click="handshakeAcceptOrReject(handshakeId, 'to_approved')"
            >Accept</b-button
          >
        </b-alert>
        <div v-else>
          <b-alert
            v-if="importStatus.customer === 'PENDING'"
            variant="warning"
            show
          >
            <i class="fa fa-clock"></i> Accountant is reviewing your customer
            data
          </b-alert>
          <b-alert
            v-else-if="importStatus.customer === 'REJECTED'"
            variant="danger"
            show
          >
            <i class="fa fa-exclamation mr-2"></i> Accountant has rejected your
            customer data, please upload the corrected data.
          </b-alert>
          <b-alert
            v-else-if="importStatus.customer === 'ACCEPTED'"
            variant="success"
            show
          >
            <i class="fa fa-check"></i> Accountant has approved your customer
            data.
          </b-alert>
        </div>
      </b-col>
      <b-col
        offset="6"
        cols="6"
        v-if="isRole('ROLE_PROVIDER') && importStatus.customer !== 'ACCEPTED'"
      >
        <CustomButton
          class="relation__buttons--import"
          type="Modal"
          size="md"
          :content="{
            title: 'Import Customer Data',
            modalType: 'importCustomerData'
          }"
        />
        <a
          href="/downloads/templates/relations.csv"
          download="relations.csv"
          class="btn mt-3 float-right btn-secondary mr-2"
        >
          <i aria-hidden="true" class="fa fa-download"></i>
          Download CSV Template
        </a>
      </b-col>
    </b-row>

    <div class="filters mb-4">
      <b-row>
        <b-col cols="4" class="filters__column">
          <div class="filters__item">
            <b-form-checkbox
              id="devianting"
              v-model="search.devianting"
              disabled
            >
            </b-form-checkbox>
            <label for="devianting">Relations with deviating data</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox id="missing" v-model="search.missing" disabled>
            </b-form-checkbox>
            <label for="missing">Relations with missing identifiers</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              id="isVatLiable"
              v-model="search.isVatLiable"
              value="isVatLiable"
            >
            </b-form-checkbox>
            <label for="isVatLiable">VAT Liable relations</label>
          </div>
        </b-col>
        <b-col cols="4" class="filters__column">
          <div class="filters__item">
            <b-form-checkbox
              id="isVatExampted"
              v-model="search.isVatLiable"
              value="isVatExampted"
            >
            </b-form-checkbox>
            <label for="isVatExampted">VAT Exampted relations</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              id="wowLearners"
              v-model="search.wowLearners"
              :checked="true"
              :unchecked-value="false"
            >
            </b-form-checkbox>
            <label for="wowLearners">WOW+ Learners</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              id="approvedRelations"
              v-model="search.approvedRelations"
              value="approved"
            >
            </b-form-checkbox>
            <label for="approvedRelations">Validated Relations</label>
          </div>
        </b-col>
        <b-col cols="4" class="filters__column">
          <div class="filters__item">
            <b-form-checkbox
              id="nonApprovedRelations"
              v-model="search.approvedRelations"
              value="nonApproved"
            >
            </b-form-checkbox>
            <label for="nonApprovedRelations">Non-validated Relations</label>
          </div>

          <div class="filters__item">
            <v-select
              placeholder="Only show from Country..."
              v-model="search.country"
              id="company_fiscal_id_country"
              :options="countrySelectItems"
              label="name"
              :reduce="(c) => c.name"
            >
            </v-select>
          </div>

          <div class="filters__item">
            <b-form-input
              type="search"
              placeholder="Search specific Company..."
              v-model="search.company"
              debounce="500"
            ></b-form-input>
          </div>
        </b-col>
      </b-row>
    </div>

    <!---
      TODO: Later we should move this file to backend side...
            Right now it is located on the public folder
    -->

    <!--    <ChatModal-->
    <!--    :conversation="conversation"-->
    <!--    v-if="-->
    <!--        handshakeId &&-->
    <!--        conversation &&-->
    <!--        !conversationError &&-->
    <!--        !conversationIsLoading-->
    <!--      "-->
    <!--    />-->

    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="company/importedCustomers"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:fiscalId="data">
        <span v-if="data.item.fiscalId">
          {{ data.item.fiscalId }}
        </span>
        <span class="text-danger" v-else>
          <strong>!!Missing</strong>
        </span>
      </template>
      <template v-slot:company="data">
        <span v-if="data.item.company">
          {{ data.item.company.name }}
        </span>
        <span class="text-danger" v-else>
          <strong>!!Missing</strong>
        </span>
      </template>
      <template v-slot:type="data">
        <span v-if="data.item.type">
          {{ data.item.type }}
        </span>
        <span class="text-danger" v-else>
          <strong>!!Missing</strong>
        </span>
      </template>
      <template v-slot:name="data">
        <span v-if="data.item.name">
          {{ data.item.name }}
        </span>
        <span class="text-danger" v-else>
          <strong>!!Missing</strong>
        </span>
      </template>
      <template v-slot:country="data">
        <span v-if="data.item.country">
          {{ data.item.country.code }}
        </span>
        <span class="text-danger" v-else>
          <strong>!!Missing</strong>
        </span>
      </template>
      <template v-slot:actions="data">
        <a
          href="#"
          class="mr-2"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Edit"
          ><i class="fa fa-edit text-info"></i
        ></a>
        <a
          href="#"
          @click.prevent="del(data.item)"
          v-b-tooltip.hover
          title="Delete"
          v-if="!isRole('ROLE_ACCOUNTANT')"
          ><i class="fa fa-trash text-info"></i
        ></a>
      </template>
    </CustomTable>

    <b-modal id="editImport" title="Edit Import" hide-footer size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Import</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editImport')"
        ></i>
      </template>

      <ImportUpdate
        :item="editImport"
        @updated="refreshData"
        :canEdit="!isRole('ROLE_ACCOUNTANT')"
        :handshakeId="handshakeId"
      ></ImportUpdate>
    </b-modal>

    <b-modal
      class="ml-4"
      size="xl"
      id="importCustomerData"
      title="Import Customer Data"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Import Customer Data</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('importCustomerData')"
        ></i>
      </template>

      <ImportCustomerData @created="refreshData"></ImportCustomerData>
    </b-modal>
  </div>
</template>

<script>
import CustomTable from '@/components/_ui/tables/CustomTable';
import CustomButton from '@/components/_ui/CustomButton';
import { mapActions, mapGetters } from 'vuex';
import ImportUpdate from './Update';
import helpers from '@/mixins/helpers';
import ImportCustomerData from './Create';
import { mapFields } from 'vuex-map-fields';
import { SwalAlert } from '@/utils/swal';
import { entityService } from '@/utils/fetch';
import handshakes from '@/mixins/handshakes';

export default {
  props: {
    companyId: {
      type: String,
      default: null
    },

    handshakeId: {
      type: String,
      default: null
    },

    handshakeStatus: {
      type: String,
      default: ''
    }
  },

  components: {
    CustomTable,
    CustomButton,
    ImportUpdate,
    ImportCustomerData
  },

  mixins: [helpers, handshakes],

  data() {
    return {
      importStatus: {},
      editImport: {},

      fields: [
        { key: 'name', label: 'Organization Name', sortable: true },
        {
          key: 'contactName',
          label: 'Contact Name',
          sortable: true,
          formatter: (value, key, item) => {
            return `${item.contactFirstName ?? ''} ${
              item.contactLastName ?? ''
            }`;
          }
        },
        { key: 'sector.name', label: 'Sector', sortable: true },
        { key: 'type', label: 'Recognize as', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: true }
      ],

      requiredFields: [
        'creatorCompany',
        'name',
        'addressState',
        'country',
        'addressCity',
        'addressStreet',
        'addressNumber',
        'addressZip',
        'contactFirstName',
        'contactLastName',
        'contactEmail'
      ],

      search: {
        country: null,
        company: null,
        isVatLiable: null,
        wowLearners: null,
        approvedRelations: null
      },
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapGetters('auth', ['getUser', 'isRole']),
    ...mapFields('country/list', {
      countrySelectItems: 'items'
    }),
    ...mapFields('company/importedCustomers/del', ['deleted']),
    ...mapGetters('chat', ['conversationError', 'conversationIsLoading']),
    ...mapFields('company/importedCustomers/list', {
      importedCustomersItems: 'items'
    }),
    getCompanyId() {
      return this.companyId ?? this.getUser.company.entity.company['@id'];
    },

    // conversation() {
    //   let handshakeIri = this.handshakeId;
    //   return
    //   // return this.getConversationByContext(handshakeIri) || {};
    // },

    handshakes() {
      return this.$store.state.handshake.list.items;
    },

    request() {
      let request = `${this.getCompanyId}/imported-customers?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.getCountries();
    this.refreshData();
    // this.getHandshakes(`${this.getCompanyId}/handshakes?type=ImportedCustomersHandshake&status=ACCEPTED`);

    // Get status data
    // @TODO(trepatudo): turn this into store
    entityService('/import/status')
      .then((response) => response.json())
      .then((data) => {
        this.importStatus = data;
      });

    if (this.handshakeId) {
      this.CHAT_FETCH_CONVERSATIONS(
        `/conversations?context=${this.handshakeId}`
      );
    }
  },

  methods: {
    ...mapActions({
      getCountries: 'country/list/default',
      getPage: 'company/importedCustomers/list/default',
      delete: 'company/importedCustomers/del/del',
      getHandshakes: `handshake/list/default`
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    hasAllValidFields(item) {
      let isValid = true;

      if (item.finalRelation) {
        return false;
      }

      this.requiredFields.forEach((val) => {
        if (item[val] === undefined || item[val] === null || item[val] === '') {
          isValid = false;
        }
      });
      return isValid;
    },

    edit(item) {
      this.editImport = item;
      this.$bvModal.show('editImport');
    },

    getSeparatorForRequest(search) {
      return search === '' ? '?' : '&';
    },

    del(item) {
      SwalAlert({
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'danger',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning'
          },
          confirm: {
            label: 'Yes, go ahead',
            type: 'success',
            callback: () => {
              this.delete(item);
            }
          }
        }
      });
    },

    handleSearch() {
      let search = '';

      if (this.search.country) {
        search += `${this.getSeparatorForRequest(search)}country.name=${
          this.search.country
        }`;
      }

      if (this.search.company) {
        search += `${this.getSeparatorForRequest(search)}finalCompany.name=${
          this.search.company
        }`;
      }

      if (this.search.isVatLiable) {
        search += `${this.getSeparatorForRequest(search)}sector.isVatLiable=${
          this.search.isVatLiable === 'isVatLiable' ? true : false
        }`;
      }

      if (this.search.wowLearners) {
        search += `${this.getSeparatorForRequest(search)}type=PERSON`;
      }

      if (this.search.approvedRelations) {
        search += `${this.getSeparatorForRequest(search)}exists[finalCompany]=${
          this.search.approvedRelations === 'approved' ? true : false
        }`;
      }

      return search;
    }
  },

  watch: {
    search: {
      deep: true,

      handler() {
        const search = this.handleSearch();

        this.getPage(`${this.getCompanyId}/imported-customers${search}`);
      }
    },

    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.refreshData();
    }
  }
};
</script>

<style lang="scss" scoped>
.relation__download {
  // font-size: 25px;
  color: $primary;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

.relation {
  &__actions {
    display: flex;
    align-items: center;
  }
}
</style>
