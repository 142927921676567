<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <UserForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :show-submit="showSubmit"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import UserForm from './Form';
import { SwalAlert } from '../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'user/create/getField',
  mutationType: 'user/create/updateField'
});

export default {
  components: {
    UserForm
  },
  props: {
    showAlert: {
      type: Boolean,
      default() {
        return true;
      }
    },
    showSubmit: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }
      this.$emit('created', created);
      this.$bvModal.hide('createUser');

      if (this.showAlert) {
        SwalAlert({
          title: 'User added',
          icon: 'user',
          type: 'success',
          buttons: {
            cancel: {
              label: 'Form another',
              type: 'warning',
              callback: () => {
                this.$bvModal.show('createUser');
              }
            },
            confirm: {
              label: 'OK',
              type: 'success'
            }
          }
        });
      }
    }
  },

  methods: {
    ...mapActions('user/create', ['create']),

    onSendForm() {
      this.create(this.item);
    }
  }
};
</script>
