<template>
  <div>
    <div class="prospect-tabs">
      <div
        class="prospect-tab"
        :class="{
          'prospect-tab--active': step === index + 1,
          'prospect-tab--disabled': isManual
        }"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="goToStep(index + 1)"
      >
        <span class="prospect-tab__title">{{ tab.title }}</span>
      </div>
    </div>

    <GeneralInformation
      @updateData="updateData"
      @step="goToStep"
      v-show="step === 1"
      :isManual="isManual"
      :initialValues="initialValues"
    />

    <Duplicates
      @updateData="updateData"
      @step="goToStep"
      v-show="step === 2"
      :is-creating="isCreating"
      :initialValues="initialValues"
      :is-loading="isLoading"
      @onSendForm="onSendForm"
    />

    <Notes
      @updateData="updateData"
      @step="goToStep"
      v-show="step === 3"
      :is-loading="isLoading"
      @onSendForm="onSendForm"
      :initialValues="initialValues"
    />
  </div>
</template>

<script>
import GeneralInformation from './tabs/GeneralInformation';
import Notes from './tabs/Notes';
import Duplicates from './tabs/Duplicates';

export default {
  components: {
    GeneralInformation,
    Notes,
    Duplicates
  },

  props: {
    initialValues: {
      type: Object,
      default: () => {}
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      required: true
    },

    isManual: {
      type: Boolean,
      default: false
    },

    isCreating: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      tabs: [
        {
          title: 'General Information',
          step: 1,
          visible: true
        },
        {
          title: 'Duplicates',
          step: 2,
          visible: true
        },
        {
          title: 'Notes',
          step: 3,
          visible: true
        }
      ],
      step: 1,
      item: null
    };
  },

  mounted() {
    if (this.isCreating) {
      this.tabs.pop();
    }
  },

  methods: {
    goToStep(step) {
      this.step = step;
    },

    updateData(data) {
      this.item = { ...this.item, ...data };
    },

    onSendForm() {
      this.item.json = JSON.stringify(this.item.json);

      this.handleSubmit(this.item);
    }
  }
};
</script>

<style lang="scss">
.prospect {
  &-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    border-bottom: 1px solid $gray-400;

    margin-bottom: 20px;
  }

  &-tab {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: 0.2 all linear;

    &:not(:first-child) {
      margin-left: 1px;
    }

    &:hover {
      .prospect-tab__title {
        color: $primary;
        font-weight: bold;
      }
    }

    &--active {
      .prospect-tab__title {
        color: $primary;
        font-weight: bold;
      }
    }

    &--disabled {
      pointer-events: none;
    }
  }
}
</style>
