<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group
      label-cols="9"
      content-cols="3"
      label="Is teacher a subcontractor?"
      label-for="isSubcontractor"
      label-size="sm"
    >
      <b-form-checkbox
        id="isSubcontractor"
        v-model="formMetadata.isSubcontractor"
        size="lg"
        switch
        class="float-right"
      ></b-form-checkbox>
    </b-form-group>

    <b-form-group
      label-cols="4"
      content-cols="8"
      label="Company"
      label-for="company"
      label-size="sm"
      :invalid-feedback="violations.company"
      :state="isValid('company')"
      v-if="formMetadata.isSubcontractor"
    >
      <div v-if="getSubcontractorRelationships.length > 0">
        <b-form-select v-if="relationshipIsLoading" disabled value="" size="sm">
          <b-form-select-option selected value=""
            >Loading...
          </b-form-select-option>
        </b-form-select>
        <b-form-select
          v-else
          required
          :options="getSubcontractorRelationships"
          v-model="item.company"
          id="company"
          value-field="@id"
          text-field="name"
          size="sm"
        />
      </div>
      <template v-else>
        <span
          >You don't have any subcontractors,
          <router-link to="/relationships/subcreators/overview"
            >add one</router-link
          ></span
        >
      </template>
    </b-form-group>
    <b-form-group
      label-cols="4"
      content-cols="8"
      label="Subcontractor Fee"
      label-for="subcontractorFee"
      label-size="sm"
      :invalid-feedback="violations.subcontractorFee"
      :state="isValid('subcontractorFee')"
      v-if="formMetadata.isSubcontractor"
    >
      <b-form-input
        id="subcontractorFee"
        v-model="item.subcontractorFee"
        type="text"
      >
      </b-form-input>
    </b-form-group>

    <hr class="my-4" />

    <div v-if="isUpdating">
      <b-form-group label="Email Address" label-for="user_email">
        <b-form-input :value="user.email" readonly type="text"></b-form-input>
      </b-form-group>

      <b-form-group label="First Name" label-for="user_firstName">
        <b-form-input
          readonly
          id="user_firstName"
          :value="user.firstName"
          type="text"
        >
        </b-form-input>
      </b-form-group>

      <b-form-group label="Last Name" label-for="user_lastName">
        <b-form-input
          readonly
          id="user_lastName"
          :value="user.lastName"
          type="text"
        >
        </b-form-input>
      </b-form-group>
    </div>
    <div v-else>
      <b-form-group label="Email Address" label-for="user_email">
        <UserSearchInput @selected="selectedUser" />

        <div
          role="alert"
          class="d-block invalid-feedback"
          v-if="violations.email"
        >
          {{ violations.email }}
        </div>
      </b-form-group>

      <b-form-group
        label="First Name"
        label-for="user_firstName"
        :invalid-feedback="violations.firstName"
        :state="!isValid('firstName')"
      >
        <b-form-input
          :readonly="!!formMetadata.userSelected"
          id="user_firstName"
          v-model="user.firstName"
          type="text"
          required
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Last Name"
        label-for="user_lastName"
        :invalid-feedback="violations.lastName"
        :state="!isValid('lastName')"
      >
        <b-form-input
          :readonly="!!formMetadata.userSelected"
          id="user_lastName"
          v-model="user.lastName"
          type="text"
          required
        >
        </b-form-input>
      </b-form-group>
    </div>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        v-if="!isLoading"
        >Cancel
      </b-button>

      <b-button
        type="submit"
        variant="primary"
        v-if="!isFormLoading"
        :disabled="!canCreate"
        >{{ getButtonLabel }}
      </b-button>
      <b-button type="submit" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import UserSearchInput from '../../../_ui/users/SearchInput.vue';

export default {
  components: {
    UserSearchInput
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      formMetadata: {
        userSelected: null,
        isSubcontractor: false
      },
      user: {}
    };
  },

  mounted() {
    // Reset
    this.getRelationSelectItems({
      page: `${this.getCompany.entity.company['@id']}/relationships`,
      params: {
        type: 'SUBCONTRACTOR'
      }
    });

    // GET comes with relations
    if (this.item.user) {
      this.user = this.item.user;
      this.item.user = this.user['@id'];
    }

    if (!this.item.type) {
      this.item.type = 'TEACHER';
    } else if (this.item.type === 'SUBCONTRACTOR') {
      this.formMetadata.isSubcontractor = true;
    }
  },

  beforeDestroy() {
    this.userReset();
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),
    ...mapFields('user/create', {
      userErrors: 'errors',
      userIsLoading: 'isLoading',
      userCreated: 'created',
      userViolations: 'violations'
    }),
    ...mapFields('relationship/list', {
      relationshipError: 'error',
      relationshipSelectItems: 'selectItems',
      relationshipIsLoading: 'isLoading'
    }),

    learningActivitiesSelectItems() {
      if (!this.learningModulesSelectItems) {
        return [];
      }

      let activities = {};

      // Make it unique
      this.learningModulesSelectItems.forEach((m) => {
        m.activities.forEach((a) => {
          if (!activities[a['@id']]) {
            activities[a['@id']] = a;
          }
        });
      });

      return Object.values(activities);
    },

    // isLoading
    isFormLoading() {
      return this.isLoading || this.userIsLoading || this.userCreated;
    },

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    isUpdating() {
      return !!this.initialValues;
    },

    violations() {
      let errors = {};
      if (this.errors) {
        errors = { ...errors, ...this.errors };
      }
      if (this.userViolations) {
        errors = { ...errors, ...this.userViolations };
      }
      return errors;
    },

    getButtonLabel() {
      return this.isUpdating ? 'Update Teacher' : 'Add Teacher';
    },
    canCreate() {
      return !!this.user.email || this.isUpdating;
    },
    getSubcontractorRelationships() {
      if (!this.relationshipSelectItems) return [];
      return this.relationshipSelectItems.map((item) => {
        return { ...item.related };
      });
    }
  },

  watch: {
    'formMetadata.isSubcontractor': function (isSubcontractor) {
      this.item.type = isSubcontractor ? 'SUBCONTRACTOR' : 'TEACHER';
    },
    'formMetadata.userSelected': function (userSelected) {
      this.item.user = userSelected ? userSelected['@id'] : null;
    },
    userCreated: function (userCreated) {
      if (!userCreated) {
        return;
      }

      // Move it, move it!
      this.item.user = userCreated['@id'];
      this.handleSubmit(this.item);
    }
  },

  methods: {
    ...mapActions({
      userCreate: 'user/create/create',
      userReset: 'user/create/reset',
      getRelationSelectItems: 'relationship/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    onSendForm(item) {
      // Do we have user? if not, register
      if (!this.item.user) {
        // Create new user and only then handle the submit
        return this.userCreate(this.user);
      }
      // Updating!
      else if (this.item.user.email) {
        this.item.user = this.item.user['@id'];
      }

      // Cast
      if (this.item.subcontractorFee) {
        this.item.subcontractorFee = parseFloat(this.item.subcontractorFee);
      }

      // Delete unused
      if (!this.formMetadata.isSubcontractor) {
        delete this.item.company;
        delete this.item.subcontractorFee;
      }

      // All good
      this.handleSubmit(item);
    },

    closeModal() {
      this.$bvModal.hide('createTeacher');
      this.$bvModal.hide('editTeacher');
    },

    /**
     * Handle output of typeahead
     * @param userSelected
     */
    selectedUser(userSelected) {
      if (userSelected.fetched) {
        this.$set(this.formMetadata, 'userSelected', userSelected.user);
        this.$set(this.user, 'email', userSelected.user.email);
        this.$set(this.user, 'firstName', userSelected.user.firstName);
        this.$set(this.user, 'lastName', userSelected.user.lastName);
      } else {
        this.$set(this.formMetadata, 'userSelected', null);
        this.$set(this.user, 'email', userSelected.user);
      }
    }
  }
};
</script>
