<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <LedgerForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import LedgerForm from './Form';
import { SwalAlert } from '@/utils/swal';

const { mapFields } = createHelpers({
  getterType: 'ledgers/create/getField',
  mutationType: 'ledgers/create/updateField'
});

export default {
  components: {
    LedgerForm
  },

  data() {
    return {
      item: {}
    };
  },
  created() {
    this.reset();
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId']),
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }
      this.$emit('created', created);

      this.$bvModal.hide('createLedger');
      SwalAlert({
        title: 'Ledger added',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createLedger');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  methods: {
    ...mapActions('ledgers/create', ['create', 'reset']),

    onSendForm(item) {
      item.company = this.getCompanyId;

      item.amplificaProfit = parseInt(item.amplificaProfit);
      item.aprovaProfit = parseInt(item.aprovaProfit);
      item.highVAT = parseInt(item.highVAT);
      item.learningMaterialRevenue = parseInt(item.learningMaterialRevenue);
      item.lowVAT = parseInt(item.lowVAT);
      item.otherRevenue = parseInt(item.otherRevenue);

      this.create(item);
    }
  }
};
</script>
