<template>
  <div class="show__content" v-if="handshake">
    <div class="show__item" v-if="canAcceptOrReject">
      <div class="d-flex">
        <span class="show__item__title show__item__title--black"
          >Is the following data input correct?
        </span>
        <b-form-checkbox
          class="ml-5"
          id="acceptCompany"
          v-model="acceptCompany"
          name="acceptCompany"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </div>
    </div>
    <table v-if="!isLoading" class="table table-striped table-hover mt-4">
      <thead>
        <tr>
          <th>Year</th>
          <th>Type</th>
          <th>Vat Deduction</th>
          <th>Rent</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in handshake.proRatas" :key="index">
          <td>{{ item.year }}</td>
          <td>{{ item.crkboRegistrationType }}</td>
          <td>{{ formatCurrency(item.vatDeductionAmount, 4) }}</td>
          <td>{{ formatCurrency(item.rentAmount, 4) }}</td>
        </tr>
      </tbody>
    </table>

    <div v-else>
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { entityService } from '@/utils/fetch';
import helpers from '@/mixins/helpers';

export default {
  name: 'ProRataHandshake',

  mixins: [helpers],

  props: {
    handshake: {
      type: Object,
      required: true
    },
    canAcceptOrReject: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canSubmit() {
      return this.acceptCompany;
    }
  },

  data() {
    return {
      isLoading: false,
      acceptCompany: false,
      proRata: []
    };
  },

  watch: {
    canSubmit: function (newVal) {
      this.$emit('isSubmitAllowed', newVal);
    }
  }
};
</script>
