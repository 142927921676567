<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group label="New Password">
      <b-row>
        <b-col>
          <b-form-input
            id="profile_password"
            type="password"
            v-model="item.password"
            :state="hasConfirmedPassword"
            placeholder="************"
            autocomplete="new-password"
          >
          </b-form-input>
        </b-col>
        <b-col>
          <b-form-input
            id="profile_repeat_password"
            type="password"
            :state="hasConfirmedPassword"
            v-model="item.passwordConfirm"
            placeholder="************"
            autocomplete="new-password"
          >
          </b-form-input>
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group label="Old Password">
      <b-row>
        <b-col>
          <b-form-input
            id="oldPassword"
            type="password"
            :state="hasConfirmedOldPassword"
            v-model="item.oldPassword"
            placeholder="************"
            autocomplete="old-password"
          >
          </b-form-input>
        </b-col>
      </b-row>
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        type="submit"
        variant="primary"
        v-if="!isLoading"
        :disabled="!hasConfirmedPassword || !hasConfirmedOldPassword"
        >Save new Password</b-button
      >
      <b-button type="button" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
export default {
  props: {
    changePassword: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasConfirmedPassword() {
      if (
        this.item.password.length === 0 ||
        this.item.passwordConfirm.length === 0
      ) {
        return null;
      }
      return this.item.password === this.item.passwordConfirm;
    },

    hasConfirmedOldPassword() {
      if (!this.item.oldPassword) {
        return null;
      }

      return this.item.oldPassword.length > 0 ? true : false;
    }
  },

  data() {
    return {
      item: {
        password: '',
        passwordConfirm: '',
        oldPassword: ''
      }
    };
  },

  methods: {
    onSendForm() {
      let payload = {
        oldPassword: this.item.oldPassword,
        newPassword: this.item.password
      };

      this.changePassword(payload);
    }
  }
};
</script>
