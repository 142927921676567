<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <SettingsForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
    />
  </div>
</template>
s
<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import SettingsForm from './Form.vue';
import { SwalAlert } from '../../../utils/swal';

export default {
  components: {
    SettingsForm
  },

  props: {
    setting: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapFields('settings/create', {
      created: 'created'
    }),
    ...mapFields('settings/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated', updated);
      this.$bvModal.hide('editSettings');
      SwalAlert({
        title: 'Settings added',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('editSettings');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.setting['@id']);
  },

  methods: {
    ...mapActions({
      createReset: 'settings/create/reset',
      deleteItem: 'settings/del/del',
      delReset: 'settings/del/reset',
      retrieve: 'settings/update/retrieve',
      updateReset: 'settings/update/reset',
      update: 'settings/update/update',
      updateRetrieved: 'settings/update/updateRetrieved'
    }),

    del() {
      if (window.confirm('Are you sure you want to delete this settings ?')) {
        this.deleteItem(this.retrieved);
      }
    },

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm() {
      this.update(this.retrieved);
    }
  }
};
</script>
