<template>
  <b-form @submit.prevent="onFormSend(item)">
    <b-container fluid>
      <b-row>
        <b-col cols="6">
          <b-form-group label="Template Name" label-for="name">
            <b-form-input id="name" v-model="item.name" type="text" required>
            </b-form-input>
          </b-form-group>
          <b-form-group label="Description" label-for="description">
            <b-form-input
              id="description"
              v-model="item.description"
              type="text"
              required
            >
            </b-form-input>
          </b-form-group>

          <b-form-group label="Key" label-for="tkey">
            <b-form-select id="key" v-model="item.tkey" required="required">
              <b-form-select-option value="Invoice"
                >Invoice</b-form-select-option
              >
              <b-form-select-option value="Collection Note"
                >Collection Note</b-form-select-option
              >
            </b-form-select>
          </b-form-group>

          <b-form-group>
            <b-form-checkbox
              id="isActive"
              v-model="item.isActive"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              switch
            >
              Active
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Document" label-for="doc">
            <vue-dropzone
              ref="myVueDropzone"
              id="doc"
              :options="dropzoneOptions"
              @vdropzone-file-added="addedFile"
            ></vue-dropzone>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
  components: {
    vueDropzone: vue2Dropzone
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      required: true
    }
  },
  created() {
    if (!this.initialValues) {
      this.$set(this.item, 'isActive', true);
    }
  },

  data() {
    return {
      dropzoneOptions: {
        url: 'localhost',
        autoProcessQueue: false,
        dictDefaultMessage: "<i class='fa fa-plus'></i><p>UPLOAD DOCUMENT</p>",
        maxFiles: 1,
        acceptedFiles: '.doc,.docx'
      },
      typeOptions: ['Option 1', 'Option 2', 'Option 3', 'Option 4']
    };
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({}),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },
    addedFile(file) {
      this.item.file = file;
    },
    onFormSend(item) {
      this.handleSubmit(item);
    },
    closeModal() {
      this.$bvModal.hide('templateCreate');
    }
  }
};
</script>

<style lang="scss">
.dropzone .dz-preview {
  max-width: 100%;
  max-height: 135px;
  margin: 0;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  height: 135px;
}

.dz-progress,
.dz-size {
  display: none !important;
}

.dz-details {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
