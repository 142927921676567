<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="prospects"
      @changePage="changePage"
      @checkAll="checkAll"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Edit"
        >
          <i class="fas fa-edit text-info"></i>
        </a>

        <a
          class="mr-2"
          href="#"
          @click.prevent="showInvites(data.item)"
          v-b-tooltip.hover
          title="Show"
        >
          <i class="fas fa-user-plus text-info"></i>
        </a>

        <a
          href="#"
          @click.prevent="del(data.item)"
          v-if="data.item.isSuperspreader"
          ><i class="fa fa-trash text-info"></i
        ></a>
      </template>
      <template v-slot:checked="data">
        <b-form-group>
          <b-form-checkbox
            :id="`checkbox-${data.item['@id']}`"
            v-model="checkedItems"
            :value="data.item['@id']"
          ></b-form-checkbox>
        </b-form-group>
      </template>
      <template #buttons class="d-flex">
        <b-button
          class="mr-3"
          @click.native="importProspects"
          variant="primary"
          :disabled="checkedItems.length === 0"
        >
          Import Prospects
        </b-button>
        <b-button
          class="mr-3"
          @click="$bvModal.show('prospectCreate')"
          variant="primary"
        >
          <i class="fas fa-plus"></i>
          Add Prospect
        </b-button>
        <b-button @click="$bvModal.show('importProspect')" variant="primary">
          Import File
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      class="ml-4"
      size="xl"
      id="importProspect"
      title="Import Prospect"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Import Prospect</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('importProspect')"
        ></i>
      </template>

      <ImportProspect @created="refreshData"></ImportProspect>
    </b-modal>

    <b-modal
      class="ml-4"
      size="xl"
      id="prospectCreate"
      title="Create Prospect"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Prospect</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('prospectCreate')"
        ></i>
      </template>

      <CreateProspect @created="refreshData"></CreateProspect>
    </b-modal>

    <b-modal
      class="ml-4"
      size="xl"
      id="updateProspect"
      title="Update Prospect"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Update Prospect</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateProspect')"
        ></i>
      </template>

      <UpdateProspect
        @updated="refreshData"
        :prospect="editProspect"
      ></UpdateProspect>
    </b-modal>

    <b-modal
      class="ml-4"
      size="xl"
      id="showAllInvites"
      title="Invites"
      hide-footer
    >
      GET HANDSHAKES FOR INVITES (STATUS ETC)
    </b-modal>
  </div>
</template>

<script>
import CustomTable from '../../_ui/tables/CustomTable';
import ImportProspect from './import/Create';
import CreateProspect from './Create';
import UpdateProspect from './Update';
import { mapActions } from 'vuex';
import { SwalAlert } from '@/utils/swal';
import { mapFields } from 'vuex-map-fields';
import helpers from '@/mixins/helpers';
import { entityService } from '../../../utils/fetch';

export default {
  components: {
    CustomTable,
    ImportProspect,
    CreateProspect,
    UpdateProspect
  },

  mixins: [helpers],

  computed: {
    ...mapFields('prospects/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    request() {
      let request = '/prospects?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&exists[duplicated]=false`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },
  data() {
    return {
      deleting: {},
      editProspect: {},
      checkedItems: [],
      fields: [
        { key: 'checked', label: 'checked', sortable: true },
        { key: 'name', label: 'Name', sortable: true },
        { key: 'origin', label: 'Origin', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'prospects/list/default',
      prospectDelete: 'prospects/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editProspect = item;
      this.$bvModal.show('updateProspect');
    },

    showInvites() {
      this.$bvModal.show('showAllInvites');
    },

    checkAll(value) {
      const checkboxes = document.querySelectorAll('input[type=checkbox]');

      checkboxes.forEach((item, index) => {
        if (index === 0) {
          return;
        }

        if (value) {
          if (!this.checkedItems.includes(item.value)) {
            this.checkedItems.push(item.value);
          }
        } else {
          this.checkedItems.pop();
        }
      });
    },

    del(item) {
      SwalAlert({
        title: 'Delete Prospect',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.prospectDelete({ '@id': itemId });
            }
          }
        }
      });
    },

    importProspects() {
      entityService('/import/prospects', {
        method: 'POST',
        body: JSON.stringify(this.checkedItems)
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }

      this.refreshData();
    }
  }
};
</script>

<style lang="scss">
.prospect {
  &__button {
    div {
      button {
        margin: 10px;
      }
    }
  }
}
</style>
