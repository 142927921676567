import SectorList from '@/components/admin/sector/List';
import SectorCreate from '@/components/admin/sector/Create';
import SectorUpdate from '@/components/admin/sector/Update';
import SectorShow from '@/components/admin/sector/Show';

export default [
  { name: 'SectorList', path: '/sectors/', component: SectorList },
  { name: 'SectorCreate', path: '/sectors/create', component: SectorCreate },
  { name: 'SectorUpdate', path: '/sectors/edit/:id', component: SectorUpdate },
  { name: 'SectorShow', path: '/sectors/show/:id', component: SectorShow }
];
