<template>
  <b-container fluid>
    <h6>
      <i class="fa fa-user-plus text-primary"></i
      ><strong class="ml-2"> Onboarding | Welcome at AmplificA </strong>
    </h6>
    <p class="gray-text p-3">
      By filling out this form and by following the next<br />
      steps in this onboarding procedure, we welcome<br />
      you in our Life Long Learning community
    </p>

    <hr />

    <div v-if="!canCreateUserIsLoading">
      <div class="welcome__fistStep" v-if="step === 1">
        <b-form-group
          class="mt-5"
          label-cols="6"
          content-cols="6"
          label="Did you receive a Governmental invitation?"
        >
          <b-form-radio v-model="governmentalInvitation" :value="true" inline>
            Yes
          </b-form-radio>
          <b-form-radio v-model="governmentalInvitation" :value="false" inline>
            No
          </b-form-radio>
        </b-form-group>

        <b-row align-h="end">
          <b-form-group class="float-right">
            <b-button
              type="button"
              variant="primary"
              @click="nextStep"
              :disabled="governmentalInvitation === null"
              >Next</b-button
            >
          </b-form-group>
        </b-row>
      </div>

      <div class="welcome__secondStep" v-if="step === 2">
        <b-row>
          <b-col cols="12" v-if="governmentalInvitation">
            <b-form-group label="Company" label-for="company">
              <b-form-input
                id="company"
                v-model="item.company"
                type="text"
                placeholder="Company"
              >
              </b-form-input>
            </b-form-group>

            <b-form-group
              label="VAT Number"
              label-for="vat_number"
              v-if="governmentalInvitation"
            >
              <b-form-input
                id="vat_number"
                v-model="item.fiscalId"
                type="text"
                placeholder="VAT Number"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="!governmentalInvitation && !allowRegistration">
            <b-form-group
              class="mt-5"
              label-cols="6"
              content-cols="6"
              label="What role do you want to register?"
            >
              <b-form-radio v-model="item.role" value="Creator" name="role">
                Creator+
              </b-form-radio>
              <b-form-radio v-model="item.role" value="Learner" name="role">
                Learner
              </b-form-radio>
              <b-form-radio v-model="item.role" value="Accountant" name="role">
                Accountant
              </b-form-radio>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="form__buttons">
          <b-form-group class="float-left">
            <b-button type="button" variant="primary" @click="prevStep"
              >Back</b-button
            >
          </b-form-group>

          <b-form-group class="float-right">
            <b-button
              type="button"
              variant="primary"
              @click="nextStep"
              :disabled="isDisabled"
              >Next</b-button
            >
          </b-form-group>
        </b-row>
      </div>

      <div class="welcome__thirdStep" v-if="step === 3">
        <b-form @submit.prevent="onSendForm(item)">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="First Name"
                label-for="user_firstName"
                :invalid-feedback="violations.firstName"
                :state="!isValid('firstName')"
              >
                <b-form-input
                  id="user_firstName"
                  v-model="item.firstName"
                  type="text"
                  placeholder="First Name"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Last Name"
                label-for="user_lastName"
                :invalid-feedback="violations.lastName"
                :state="!isValid('lastName')"
              >
                <b-form-input
                  id="user_lastName"
                  v-model="item.lastName"
                  type="text"
                  placeholder="Last Name"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Email Address"
                label-for="user_email"
                :invalid-feedback="violations.email"
                :state="!isValid('email')"
              >
                <b-form-input
                  id="user_email"
                  v-model="item.email"
                  type="email"
                  placeholder="Business or private email address..."
                >
                </b-form-input>
              </b-form-group>

              <b-form-invalid-feedback v-if="errors">
                {{ errors }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <hr />
          <b-row class="form__buttons">
            <b-form-group class="float-left">
              <b-button type="button" variant="primary" @click="prevStep"
                >Back</b-button
              >
            </b-form-group>
            <b-form-group class="float-right">
              <b-button type="submit" variant="primary" v-if="!isLoading"
                >Submit</b-button
              >
              <b-button type="button" disabled v-else>
                <b-spinner small></b-spinner>
              </b-button>
            </b-form-group>
          </b-row>
        </b-form>
      </div>
    </div>

    <div v-else>
      <b-spinner variant="primary"></b-spinner>
    </div>
  </b-container>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { entityService } from '@/utils/fetch';
import _ from 'lodash';
import helpers from '@/mixins/helpers';
import { SwalAlert } from '@/utils/swal';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: String,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    showSubmit: {
      type: Boolean,
      default: true
    }
  },

  mixins: [helpers],

  computed: {
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    ...mapFields('role/list', {
      roles: 'items'
    }),

    isDisabled() {
      if (this.governmentalInvitation) {
        return !this.item.fiscalId || !this.item.company;
      } else {
        if (!this.allowRegistration) {
          return !this.item.role;
        }
      }

      return false;
    }
  },

  data() {
    return {
      step: 1,
      governmentalInvitation: null,
      canCreateUserIsLoading: false,
      allowRegistration: false
    };
  },

  mounted() {
    this.canCreateUser();
  },

  methods: {
    async canCreateUser() {
      this.canCreateUserIsLoading = true;

      const response = await entityService('/can-create-user');
      this.allowRegistration = await response.json();

      this.canCreateUserIsLoading = false;
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createUser');
    },

    onSendForm(item) {
      item.company = this.cleanStr(item.company);
      item.email = this.cleanStr(item.email);
      item.firstName = this.cleanStr(item.firstName);
      item.fiscalId = this.cleanStr(item.fiscalId);
      item.lastName = this.cleanStr(item.lastName);

      this.handleSubmit(
        item,
        !this.governmentalInvitation && this.allowRegistration
      );
    },

    nextStep() {
      if (this.userChooseCreatorRole()) return;

      if (
        this.governmentalInvitation ||
        (!this.governmentalInvitation && !this.allowRegistration)
      ) {
        this.step = this.step + 1;
      } else {
        this.step = 3;
      }
    },

    prevStep() {
      if (
        this.governmentalInvitation ||
        (!this.governmentalInvitation && !this.allowRegistration)
      ) {
        this.step = this.step - 1;
      } else {
        this.step = 1;
      }
    },

    userChooseCreatorRole() {
      if (
        this.step === 2 &&
        !this.allowRegistration &&
        this.item.role === 'Creator'
      ) {
        SwalAlert({
          html: 'In the Pilot phase, Creator+ access is on Invitation only. <br>You can leave a request for access',
          icon: 'delete',
          type: 'warning',
          buttons: {
            cancel: {
              label: 'Leave',
              type: 'warning'
            },
            confirm: {
              label: 'Yes, go ahead!',
              type: 'success',
              callback: () => {
                this.step++;
              }
            }
          }
        });

        return true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.gray-text {
  color: $gray-500;
}

.invalid-feedback {
  background-color: $danger;
  color: $white;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.form__buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
