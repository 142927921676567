<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-tabs>
      <b-tab title="VAT" :active="active === 'VAT'" class="mt-4">
        <b-form-group
          label="Sales VAT High"
          label-for="salesVatHigh"
          :invalid-feedback="violations.salesVatHigh"
          :state="!isValid('salesVatHigh')"
        >
          <b-form-input
            id="salesVatHigh"
            v-model="item.salesVatHigh"
            type="number"
            required
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Sales VAT Low"
          label-for="salesVatLow"
          :invalid-feedback="violations.salesVatLow"
          :state="!isValid('salesVatLow')"
        >
          <b-form-input
            id="salesVatLow"
            v-model="item.salesVatLow"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Sales VAT Low Low"
          label-for="salesVatLowLow"
          :invalid-feedback="violations.salesVatLowLow"
          :state="!isValid('salesVatLowLow')"
        >
          <b-form-input
            id="salesVatLowLow"
            v-model="item.salesVatLowLow"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Expenses VAT High"
          label-for="expensesVatHigh"
          :invalid-feedback="violations.expensesVatHigh"
          :state="!isValid('expensesVatHigh')"
        >
          <b-form-input
            id="expensesVatHigh"
            v-model="item.expensesVatHigh"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Expenses VAT Low"
          label-for="expensesVatLow"
          :invalid-feedback="violations.expensesVatLow"
          :state="!isValid('expensesVatLow')"
        >
          <b-form-input
            id="expensesVatLow"
            v-model="item.expensesVatLow"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Expenses VAT Low Low"
          label-for="expensesVatLowLow"
          :invalid-feedback="violations.expensesVatLowLow"
          :state="!isValid('expensesVatLowLow')"
        >
          <b-form-input
            id="expensesVatLowLow"
            v-model="item.expensesVatLowLow"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="float-right">
          <b-button type="button" variant="primary" @click="active = 'Revenues'"
            >Next</b-button
          >
        </b-form-group>
      </b-tab>

      <b-tab title="Revenues" :active="active === 'Revenues'" class="mt-4">
        <b-form-group
          label="Consumption"
          label-for="consumption"
          :invalid-feedback="violations.consumption"
          :state="!isValid('consumption')"
        >
          <b-form-input
            id="consumption"
            v-model="item.consumption"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="Hotel Room"
          label-for="hotelRoom"
          :invalid-feedback="violations.hotelRoom"
          :state="!isValid('hotelRoom')"
        >
          <b-form-input
            id="hotelRoom"
            v-model="item.hotelRoom"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Classroom"
          label-for="classRoom"
          :invalid-feedback="violations.classRoom"
          :state="!isValid('classRoom')"
        >
          <b-form-input
            id="classRoom"
            v-model="item.classRoom"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="UNION+Bibliotheca"
          label-for="unionBibliotheca"
          :invalid-feedback="violations.unionBibliotheca"
          :state="!isValid('unionBibliotheca')"
        >
          <b-form-input
            id="unionBibliotheca"
            v-model="item.unionBibliotheca"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="float-right">
          <b-button
            type="button"
            variant="primary"
            @click="active = 'VAT'"
            class="mr-2"
            >Previous</b-button
          >
          <b-button
            type="button"
            variant="primary"
            @click="active = 'SharedProfit'"
            >Next</b-button
          >
        </b-form-group>
      </b-tab>

      <b-tab
        title="Shared Profit"
        :active="active === 'SharedProfit'"
        class="mt-4"
      >
        <b-form-group
          label="Aprova Training"
          label-for="aprovaTraining"
          :invalid-feedback="violations.aprovaTraining"
          :state="!isValid('aprovaTraining')"
        >
          <b-form-input
            id="aprovaTraining"
            v-model="item.aprovaTraining"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="RII AprovA Training"
          label-for="riiAprovaTraining"
          :invalid-feedback="violations.riiAprovaTraining"
          :state="!isValid('riiAprovaTraining')"
        >
          <b-form-input
            id="riiAprovaTraining"
            v-model="item.riiAprovaTraining"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="RII Learning Materials"
          label-for="riiLearningMaterials"
          :invalid-feedback="violations.riiLearningMaterials"
          :state="!isValid('riiLearningMaterials')"
        >
          <b-form-input
            id="riiLearningMaterials"
            v-model="item.riiLearningMaterials"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="AmplicA Vocational Education"
          label-for="amplificaVocationalEducation"
          :invalid-feedback="violations.amplificaVocationalEducation"
          :state="!isValid('amplificaVocationalEducation')"
        >
          <b-form-input
            id="amplificaVocationalEducation"
            v-model="item.amplificaVocationalEducation"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="RII AmplicA Vocational Education"
          label-for="riiAmplificaVocationalEducation"
          :invalid-feedback="violations.riiAmplificaVocationalEducation"
          :state="!isValid('riiAmplificaVocationalEducation')"
        >
          <b-form-input
            id="riiAmplificaVocationalEducation"
            v-model="item.riiAmplificaVocationalEducation"
            type="number"
            required="required"
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="float-right">
          <b-button
            type="button"
            variant="primary"
            @click="active = 'Revenues'"
            class="mr-2"
            >Previous</b-button
          >
          <b-button type="button" variant="primary" @click="active = 'Expenses'"
            >Next</b-button
          >
        </b-form-group>
      </b-tab>

      <b-tab title="Expenses" :active="active === 'Expenses'" class="mt-4">
        <b-form-group
          label="AprovA Fee"
          label-for="aprovaFee"
          :invalid-feedback="violations.aprovaFee"
          :state="!isValid('aprovaFee')"
        >
          <b-form-input
            id="aprovaFee"
            v-model="item.aprovaFee"
            type="number"
            required="required"
            placeholder=""
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="float-right">
          <b-button
            type="button"
            variant="primary"
            @click="active = 'SharedProfit'"
            class="mr-2"
            >Previous</b-button
          >

          <b-button type="submit" variant="primary" v-if="!isLoading"
            >Submit</b-button
          >
          <b-button type="submit" variant="primary" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-form>
</template>

<script>
import { find, get, isUndefined, isString } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true,
      default: () => {}
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data: function () {
    return {
      backupArticleSplits: [],
      newArticle: {
        childArticle: '',
        splitValue: 0
      },
      active: 'VAT'
    };
  },

  computed: {
    item() {
      return this.initialValues || Object.keys(this.values).length > 0
        ? this.values
        : {
            salesVatHigh: 1700,
            salesVatLow: 1701,
            salesVatLowLow: 1702,
            expensesVatHigh: 1720,
            expensesVatLow: 1721,
            expensesVatLowLow: 1722,
            aprovaTraining: 3000,
            riiAprovaTraining: 3000,
            riiLearningMaterials: 3000,
            amplificaVocationalEducation: 3001,
            riiAmplificaVocationalEducation: 3001,
            consumption: 8000,
            hotelRoom: 8000,
            classRoom: 8000,
            unionBibliotheca: 8001,
            aprovaFee: 4000
          };
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('editLedger');
      this.$bvModal.hide('createLedger');
    }
  }
};
</script>

<style lang="scss">
.ledgers__title {
  // font-size: 24px;
  text-align: center;
  margin: 30px 0;
}
</style>
