<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group
      :invalid-feedback="violations.name"
      :label="getNameLabel"
      :state="!isValid('name')"
      label-for="company_name"
    >
      <b-form-input
        id="company_name"
        v-model="item.name"
        :disabled="showOffMode"
        placeholder=""
        required
        type="text"
      />
    </b-form-group>

    <b-form-row>
      <b-col>
        <b-form-group
          v-if="canAddTradeName"
          :invalid-feedback="violations.tradename"
          :label="getTradenameLabel"
          :state="!isValid('tradename')"
          label-for="company_tradename"
        >
          <b-form-input
            id="company_tradename"
            v-model="item.tradename"
            :disabled="showOffMode"
            :placeholder="showOffMode ? '' : 'Optional'"
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <div v-if="!skipCompanyRoles && !showOffMode">
      <b-form-group
        v-if="isRole('ROLE_ADMIN')"
        :invalid-feedback="violations.isAmplifica"
        :state="!isValid('isAmplifica')"
        class="d-inline-flex mr-1"
        label="Amplifica"
        label-for="company_isAmplifica"
      >
        <b-form-checkbox
          id="company_isAdmin"
          v-model="item.isAmplifica"
          size="lg ml-1"
          switch
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group
        :invalid-feedback="violations.isAccountant"
        :state="!isValid('isAccountant')"
        class="d-inline-flex mr-1"
        label="Accountant"
        label-for="company_isAccountant"
      >
        <b-form-checkbox
          id="company_isAccountant"
          v-model="item.isAccountant"
          size="lg ml-1"
          switch
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group
        :invalid-feedback="violations.isProvider"
        :state="!isValid('isProvider')"
        class="d-inline-flex mr-1"
        label="Creator"
        label-for="company_isProvider"
      >
        <b-form-checkbox
          id="company_isProvider"
          v-model="item.isProvider"
          size="lg ml-1"
          switch
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group
        :invalid-feedback="violations.isInstructor"
        :state="!isValid('isInstructor')"
        class="d-inline-flex mr-1"
        label="Instructor"
        label-for="company_isInstructor"
      >
        <b-form-checkbox
          id="company_isInstructor"
          v-model="item.isInstructor"
          size="lg ml-1"
          switch
        ></b-form-checkbox>
      </b-form-group>
    </div>

    <b-form-row>
      <b-col cols="8">
        <b-form-group
          :invalid-feedback="violations.email"
          :state="!isValid('email')"
          label="Email Address *"
          label-for="company_email"
        >
          <b-form-input
            id="company_email"
            v-model="item.email"
            :disabled="showOffMode"
            required
            type="email"
          />
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-form-group
          :invalid-feedback="violations.phone"
          :state="!isValid('phone')"
          label="Phone Number *"
          label-for="company_phone"
        >
          <b-form-input
            id="company_phone"
            v-model="item.phone"
            :disabled="showOffMode"
            required
            type="text"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row v-if="companyRoles != 'Provider' && !isCreatingPerson">
      <b-col cols="12">
        <b-form-group
          v-if="
            !isCreatingAccountant &&
            !isCreatingUbo &&
            item.type !== 'GOVERNMENTAL'
          "
          :invalid-feedback="violations.sector"
          :state="!isValid('company_sector')"
          label="Sector *"
          label-for="company_sector"
        >
          <b-form-select v-if="sectorIsLoading" disabled value="">
            <b-form-select-option selected value=""
              >Loading...
            </b-form-select-option>
          </b-form-select>

          <b-form-select
            v-else
            id="company_sector"
            v-model="selectedSector"
            :disabled="showOffMode"
            :options="showOffMode ? [this.item.sector] : sectorItems"
            text-field="name"
            value-field="@id"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row
      v-if="isRole(['ROLE_ACCOUNTANT', 'ROLE_PROVIDER']) && isOnboarding"
    >
    </b-form-row>

    <b-form-row v-if="!showOffMode" class="float-right">
      <b-button
        :disabled="isLoading"
        class="mr-2"
        type="button"
        variant="secondary"
        @click="handlePrevious"
        >Previous
      </b-button>
      <b-button v-if="!isLoading" type="submit" variant="success"
        >Next
      </b-button>
      <b-button v-else disabled type="button" variant="success">
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-row>
  </b-form>
</template>

<script>
import { get, isUndefined } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import helpers from '@/mixins/helpers';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handlePrevious: {
      type: Function,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    showOffMode: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    // -------------- PARENT PROPS --------------
    skipCompanyRoles: {
      type: Boolean,
      required: true
    },

    companyRoles: {
      type: String,
      default: null
    },

    isCreatingSubContractor: {
      type: Boolean,
      default: false
    },

    isOnboarding: {
      type: Boolean,
      default: false
    },

    isCreatingRelation: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    isCreatingAccountant: {
      type: Boolean,
      default: false
    },

    isCreatingUbo: {
      type: Boolean,
      default: false
    }
  },

  mixins: [helpers],

  data: function () {
    return {
      selectedSector: null
    };
  },

  created() {
    if (this.item.sector) {
      this.selectedSector = this.item.sector['@id'];
    }

    if (!this.showOffMode) {
      this.getSectorItems();
    }
  },

  watch: {
    // Make sure we only process it after
    sectorItems: function (items) {
      if (this.item.type === 'GOVERNMENTAL') {
        this.selectedSector = items.find((item) => {
          return item.name === 'Government';
        })['@id'];
      }
    }
  },

  computed: {
    ...mapGetters('auth', ['isRole']),
    violations() {
      return this.errors || {};
    },

    ...mapFields('sector/list', {
      sectorItems: 'selectItems',
      sectorIsLoading: 'isLoading'
    }),

    getLabel() {
      switch (this.item.type) {
        case 'GOVERNMENTAL':
          return 'Organization';
        case 'PERSON':
          return '';
        case 'COMMERCIAL':
          return 'Company';
      }

      return '';
    },

    getNameLabel() {
      return `${this.getLabel} Name *`;
    },

    getEmailLabel() {
      return `${this.getLabel} E-mail *`;
    },

    getPhoneLabel() {
      return `${this.getLabel} Phone *`;
    },

    getTradenameLabel() {
      return `${this.getLabel} Trade Name`;
    },

    canAddTradeName() {
      if (
        this.item.type === 'COMMERCIAL' ||
        this.item.type === 'GOVERNMENTAL' ||
        this.item.type === 'OTHER'
      ) {
        return true;
      }

      return false;
    },
    isCreatingPerson() {
      return this.item.type === 'PERSON';
    }
  },

  methods: {
    ...mapActions({
      getSectorItems: 'sector/list/getSelectItems'
    }),

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    onSendForm(item) {
      this.item.name = this.capitalize(this.item.name.toLowerCase(), true);

      if (
        this.isCreatingAccountant ||
        this.isCreatingUbo ||
        this.companyRoles === 'Provider'
      ) {
        this.selectedSector = this.sectorItems.find(
          (sector) => sector.name === 'Other'
        )['@id'];
      } else if (this.isCreatingPerson) {
        this.selectedSector = this.sectorItems.find(
          (sector) => sector.name === 'Vocational Educatee'
        )['@id'];
      }

      this.item.sector = this.selectedSector;

      this.handleSubmit(item);
    }
  }
};
</script>
