import CompanyRequestList from '@/components/dashboard/requests/company/Tabs';
import UserRequestList from '@/components/dashboard/requests/user/Tabs';

export default [
  {
    name: 'Requests',
    path: '/requests',
    redirect: '/requests/user'
  },
  {
    name: 'RequestPending',
    path: '/requests/user',
    component: UserRequestList
  },
  {
    name: 'RequestHistory',
    path: '/requests/company',
    component: CompanyRequestList
  }
];
