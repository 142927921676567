<template>
  <b-container class="pb-4 bg-white" fluid>
    <div class="mt-3">
      <TabGroup :tabs="tabs" />
    </div>
  </b-container>
</template>

<script>
import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  components: {
    TabGroup
  },

  data() {
    return {
      tabs: [
        {
          active: 'Employees',
          text: 'Employees',
          visible: true
        }
      ]
    };
  }
};
</script>
