<template>
  <b-form-row>
    <b-col cols="7">
      <b-form @submit.prevent="onSendForm(item)">
        <div>
          <!--- start step 1 --->
          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Vocational Education"
            label-for="isNotVocationEducation"
            label-size="sm"
            :invalid-feedback="violations.vocationEducation"
            :state="isValid('vocationEducation')"
          >
            <b-form-checkbox
              id="isNotVocationEducation"
              v-model="item.vocationEducation"
              size="lg"
              switch
              :disabled="isEditing"
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Open enrollment"
            label-for="isOpenEnrollment"
            label-size="sm"
            :invalid-feedback="violations.isOpenEnrollment"
            :state="isValid('isOpenEnrollment')"
          >
            <b-form-checkbox
              id="isOpenEnrollment"
              v-model="item.isOpenEnrollment"
              size="lg"
              switch
              :disabled="isEditing"
            ></b-form-checkbox>
          </b-form-group>
          <b-form-group
            label-cols="3"
            content-cols="3"
            label="Minimum amount of learners"
            label-for="minLearners"
            label-size="sm"
            :invalid-feedback="violations.minLearners"
            :state="isValid('minLearners')"
            v-if="item.isOpenEnrollment"
          >
            <b-form-input
              required
              v-model="item.minLearners"
              type="text"
              id="minLearners"
              placeholder="10"
              size="sm"
            />
          </b-form-group>
          <b-form-group
            label-cols="3"
            content-cols="3"
            label="Maximum amount of learners"
            label-for="maxLearners"
            label-size="sm"
            :invalid-feedback="violations.maxLearners"
            :state="isValid('maxLearners')"
            v-if="item.isOpenEnrollment"
          >
            <b-form-input
              required
              v-model="item.maxLearners"
              type="text"
              id="maxLearners"
              placeholder="100"
              size="sm"
            />
          </b-form-group>

          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Proposal/Program for"
            label-for="customer"
            label-size="sm"
            :invalid-feedback="violations.customerId"
            :state="isValid('customerId')"
            v-if="!item.isOpenEnrollment"
          >
            <template v-if="getCustomerRelationships.length > 0">
              <b-form-row>
                <b-col cols="9">
                  <b-form-select
                    v-if="relationshipIsLoading"
                    disabled
                    value=""
                    size="sm"
                  >
                    <b-form-select-option selected value=""
                      >Loading...
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-select
                    v-else
                    required
                    :options="getCustomerRelationships"
                    v-model="item.customer"
                    id="customer"
                    value-field="@id"
                    text-field="name"
                    size="sm"
                  />
                </b-col>
                <b-col cols="3">
                  <b-button
                    v-b-modal.createRelationship
                    variant="primary"
                    size="sm"
                    class="w-100"
                  >
                    New
                    <b-icon icon="plus" class="float-right" />
                  </b-button>
                </b-col>
              </b-form-row>
            </template>
            <template v-else>
              <span
                >You don't have any beneficiaries, please
                <router-link to="/relationships/beneficiaries/overview"
                  >add at least one</router-link
                ></span
              >
            </template>
          </b-form-group>

          <!--        <div class="form-group row col-sm-12">-->
          <!--          <label for="proposalTemplate" class="col-sm-6 col-form-label">-->
          <!--            Form this proposal based on a template?-->
          <!--          </label>-->
          <!--          <div class="col-sm-6">-->
          <!--            <select class="form-control" id="proposalTemplate">-->
          <!--              <option selected disabled>Make a choice...</option>-->
          <!--              <option>Template 1</option>-->
          <!--              <option>Template 2</option>-->
          <!--              <option>Template 3</option>-->
          <!--            </select>-->
          <!--          </div>-->
          <!--        </div>-->
        </div>
        <!--- end step 1 --->

        <!--- start step 2 --->
        <div>
          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Proposal/Program name"
            label-for="proposalName"
            label-size="sm"
            :invalid-feedback="violations.name"
            :state="isValid('name')"
          >
            <b-form-input
              required
              v-model="item.name"
              type="text"
              id="proposalName"
              placeholder="Number / Name / ..."
              size="sm"
            />
          </b-form-group>

          <b-form-group
            class="mt-5"
            label-cols="3"
            content-cols=""
            label="Theme"
            label-for="proposalTheme"
            label-size="sm"
            :invalid-feedback="violations.themeId"
            :state="isValid('themeId')"
          >
            <b-form-row>
              <b-col cols="4">
                <b-form-select
                  v-if="themeIsLoading"
                  disabled
                  value=""
                  size="sm"
                >
                  <b-form-select-option selected value=""
                    >Loading...
                  </b-form-select-option>
                </b-form-select>
                <b-form-select
                  v-else
                  required
                  :options="themeSelectItems"
                  v-model="item.themeId"
                  id="proposalTheme"
                  value-field="@id"
                  text-field="name"
                  size="sm"
                />
              </b-col>
            </b-form-row>
          </b-form-group>

          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Learning Goal"
            label-for="proposalLearningGoal"
            label-size="sm"
          >
            <b-form-input
              disabled
              :value="getLearningGoal"
              type="text"
              id="proposalLearningGoal"
              size="sm"
            />
          </b-form-group>

          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Specific Learning Goal"
            label-for="proposalLearningGoalDetails"
            label-size="sm"
            :invalid-feedback="violations.goal"
            :state="isValid('goal')"
          >
            <b-form-input
              v-model="item.goal"
              type="text"
              id="proposalLearningGoalDetails"
              placeholder="Fill..."
              size="sm"
            />
          </b-form-group>

          <b-form-group class="mt-5">
            Pricing Type
            <b-form-radio
              class="float-right"
              v-model="item.calculateGeneralCosts"
              :value="false"
              inline
              :disabled="item.isOpenEnrollment"
            >
              Per Module
            </b-form-radio>

            <b-form-radio
              class="float-right"
              v-model="item.calculateGeneralCosts"
              :value="true"
              inline
            >
              Per Program
            </b-form-radio>
          </b-form-group>

          <b-form-group
            label-cols="3"
            content-cols="3"
            label="Calculation"
            label-for="proposalCalculationMethod"
          >
            <b-form-select
              v-model="item.pricingType"
              :options="options"
              value-field="item"
              text-field="name"
              id="proposalCalculationMethod"
              placeholder="Calculation method"
              :invalid-feedback="violations.pricingType"
              :state="isValid('pricingType')"
              :disabled="
                item.calculateGeneralCosts === false || item.isOpenEnrollment
              "
            />
          </b-form-group>
          <b-form-group
            label-cols="3"
            content-cols="3"
            label="Learners"
            label-for="maxLearners"
            v-if="!item.isOpenEnrollment"
          >
            <b-form-input
              type="number"
              min="1"
              v-model="item.maxLearners"
              placeholder="Max Learners"
              id="maxLearners"
              required
              :disabled="
                item.calculateGeneralCosts === false ||
                item.pricingType === 'LUMPSUM'
              "
              :invalid-feedback="violations.maxLearners"
              :state="isValid('maxLearners')"
            />
          </b-form-group>
          <b-form-group
            label-cols="3"
            content-cols="3"
            label="Learners/Group"
            label-for="learnersPerGroup"
          >
            <b-form-input
              type="number"
              min="1"
              v-model="item.learnersPerGroup"
              placeholder="Learners per Group"
              id="learnersPerGroup"
              required
              :disabled="
                item.calculateGeneralCosts === false ||
                item.pricingType !== 'GROUP'
              "
              :invalid-feedback="violations.learnersPerGroup"
              :state="isValid('learnersPerGroup')"
            />
          </b-form-group>

          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Price"
            label-for="proposalCalculationPrice"
          >
            <b-form-row>
              <b-col cols="4">
                <money
                  v-model="item.price"
                  class="font-size form-input form-control form-control-sm"
                  id="proposalCalculationPrice"
                  placeholder="Price"
                  required
                  :disabled="item.calculateGeneralCosts === false"
                />
              </b-col>
            </b-form-row>
          </b-form-group>

          <b-form-group
            label-cols="3"
            content-cols="9"
            label="Payment in advance?"
            label-for="proposalPaymentAdvance"
            v-if="!item.isOpenEnrollment"
          >
            <b-form-row>
              <b-col cols="1">
                <b-form-checkbox
                  id="isPayingInAdvance"
                  v-model="payingInAdvance"
                  size="lg"
                  switch
                  :disabled="item.calculateGeneralCosts === false"
                ></b-form-checkbox>
              </b-col>
              <b-col cols="3" class="pl-2">
                <b-form-input
                  v-model="item.downpaymentPercentage"
                  type="text"
                  id="proposalPaymentAdvance"
                  :disabled="
                    item.calculateGeneralCosts === false || !payingInAdvance
                  "
                  placeholder="Payment %"
                />
              </b-col>
            </b-form-row>
          </b-form-group>
        </div>

        <!--- buttons --->
        <b-form-group>
          <b-button
            v-if="!isLoading"
            type="submit"
            class="p-2 float-right"
            variant="success"
            size="sm"
            :disabled="pricing.isLoading"
          >
            Next
          </b-button>
          <b-button class="p-2 float-right" variant="success" v-else>
            <b-spinner small></b-spinner>
          </b-button>
          <b-button
            :disabled="isLoading || pricing.isLoading"
            type="button"
            @click="onSaveDraft"
            class="mr-3 p-2 pr-5 float-right"
            variant="primary"
            size="sm"
          >
            Save Draft
          </b-button>
          <!--          <button class="btn btn-primary float-right mr-1">Previous</button>-->

          <b-button
            :disabled="isLoading || pricing.isLoading"
            variant="secondary"
            @click.prevent="goBack"
            class="float-right mr-3"
          >
            Cancel
          </b-button>
        </b-form-group>
        <!--- end buttons --->
      </b-form>
    </b-col>
    <b-col cols="5">
      <Pricing
        v-if="item"
        :program="item"
        :customer="getCustomer"
        @loading="onPricingLoading"
      ></Pricing>
    </b-col>
    <b-modal id="createRelationship" title="Add Relation" hide-footer>
      <RelationCreate
        @created="createdCustomer"
        :skipCompanyAdmin="false"
      ></RelationCreate>
    </b-modal>
  </b-form-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { get, isUndefined } from 'lodash';
import helpers from '@/mixins/helpers';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import Pricing from './../Pricing.vue';
import RelationCreate from '../../relationship/Create.vue';

export default {
  components: {
    Pricing,
    RelationCreate
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  mixins: [helpers],

  data() {
    return {
      pricing: {
        isLoading: false,
        data: {}
      },
      options: [
        { item: 'LUMPSUM', name: 'LUMPSUM' },
        { item: 'GROUP', name: 'Per Group' },
        { item: 'LEARNER', name: 'Per Learner' }
      ],
      priceFrom: 'PER_PROGRAM',
      currencyMask: createNumberMask({
        prefix: '€',
        allowNegative: false,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ','
      }),
      percentageMask: createNumberMask({
        prefix: '',
        suffix: '',
        allowNegative: false,
        includeThousandsSeparator: false,
        allowDecimal: true,
        integerLimit: 3,
        decimalLimit: 2,
        decimalSymbol: ',',
        max: 100
      }),
      payingInAdvance: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),

    ...mapFields('relationship/list', {
      relationshipError: 'error',
      relationshipSelectItems: 'selectItems',
      relationshipIsLoading: 'isLoading'
    }),

    ...mapFields('theme/list', {
      themeError: 'error',
      themeSelectItems: 'selectItems',
      themeIsLoading: 'isLoading'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    isEditing() {
      return !!this.item['@id'];
    },

    violations() {
      return this.errors || {};
    },

    getCustomerRelationships() {
      if (!this.relationshipSelectItems) return [];
      return this.relationshipSelectItems
        .filter((relation) => {
          return relation.type === 'CUSTOMER';
        })
        .map((item) => {
          return { ...item.related };
        });
    },

    getAccountantRelationships() {
      if (!this.relationshipSelectItems) return [];
      return this.relationshipSelectItems.filter((relation) => {
        return relation.type === 'COMPANY' && relation.isAccountant;
      });
    },

    getLearningGoal() {
      if (!this.item.themeId || !this.themeSelectItems) {
        return '';
      }

      let theme = this.themeSelectItems.find((_theme) => {
        return _theme['@id'] === this.item.themeId;
      });
      return theme ? theme.goal : '';
    },

    getMaxLearners() {
      // @TODO(trepatudo): ugh
      return this.item.maxLearners
        ? parseInt(this.item.maxLearners)
        : 9999999999999999;
    },

    getCustomer() {
      if (!this.item.customer || !this.relationshipSelectItems) {
        return null;
      }

      let customer = this.relationshipSelectItems.find((_relation) => {
        return _relation.related['@id'] == this.item.customer;
      });

      return customer;
    },

    getPricing() {
      if (this.pricing.isLoading) {
        return {};
      }

      return this.pricing.data;
    }
  },

  watch: {
    // Select first only if only one exists
    relationshipSelectItems: function (relations) {
      if (!this.initialValues && this.getCustomerRelationships.length === 1) {
        this.item.customer = this.getCustomerRelationships[0]['@id'];
      }
    },

    'item.downpaymentPercentage': function (newValue) {
      let number = parseFloat(newValue);
      this.item.downpaymentPercentage = Math.min(100, Math.max(0, number));
    },

    'item.isOpenEnrollment': function (isOpenEnrollment) {
      if (isOpenEnrollment) {
        this.item.priceFrom = 'PER_PROGRAM';
        this.item.pricingType = 'LEARNER';
        this.item.downpaymentPercentage = 0;
        this.payingInAdvance = false;
      }
    }
  },

  mounted() {
    // Clean up
    this.item.savingDraft = null;

    // Load dependencies
    this.getProtocolSelectItems();
    this.getRelationSelectItems({
      page: `${this.getCompany.entity.company['@id']}/relationships`
    });
    this.getThemeSelectItems();

    if (this.initialValues) {
      // Fix some casts
      if (this.item.calculateGeneralCosts) {
        this.$set(this.item, 'calculateGeneralCosts', true);
      }
      if (this.item.customer) {
        this.$set(this.item, 'customer', this.item.customer['@id']);
      }

      // Fix vocational education
      this.$set(
        this.item,
        'vocationEducation',
        !this.item.isNotVocationEducation
      );
    } else {
      // Default filled up values on creation
      this.$set(this.item, 'calculateGeneralCosts', true);
      this.$set(this.item, 'numberGroups', 1);
      this.$set(this.item, 'numberLearners', 1);
      this.$set(this.item, 'vocationEducation', true);
    }
  },

  methods: {
    ...mapActions({
      getProtocolSelectItems: 'protocol/list/getSelectItems',
      getRelationSelectItems: 'relationship/list/getSelectItems',
      getThemeSelectItems: 'theme/list/getSelectItems',

      create: 'program/create/create'
    }),
    ...mapActions('program/create', ['create']),

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    onPricingLoading(isPricingLoading) {
      this.pricing.isLoading = isPricingLoading;
    },

    onSaveDraft() {
      // Send to form but go back to proposal list instead
      this.item.savingDraft = true;
      this.onSendForm(this.item);
    },
    onSendForm(item) {
      // Send 0 to API
      if (!this.payingInAdvance) {
        this.downpaymentPercentage = 0;
      }

      // Cast the real property
      item.isNotVocationEducation = !item.vocationEducation;

      // Cast objects
      // item.price = parseFloat(this.removeCommasFromString(item.price));
      item.subcontractorFee = this.item.subcontractorFee
        ? parseFloat(this.item.subcontractorFee)
        : 0;

      if (item.maxLearners) {
        this.item.maxLearners = parseInt(this.item.maxLearners);
      }

      if (item.minLearners) {
        this.item.minLearners = parseInt(this.item.minLearners);
      }

      if (item.numberGroups) {
        this.item.numberGroups = parseInt(this.item.numberGroups);
      }

      if (item.learnersPerGroup) {
        this.item.learnersPerGroup = parseInt(this.item.learnersPerGroup);
      }

      item.priceFrom = this.item.calculateGeneralCosts
        ? 'PER_PROGRAM'
        : 'PER_MODULE';

      // item.customerId = this.item.customer;
      if (item.isOpenEnrollment) {
        delete item.customers;
        delete item.customer;
      } else {
        item.customers = [{ company: this.item.customer }];
      }

      this.handleSubmit(item);
    },
    goBack() {
      this.$router.push({ name: 'ProposalList' });
    },

    /**
     * O M G hacky
     * @param val
     * @returns {*}
     */
    removeCommasFromString(val) {
      const regex = /[.€\s]/g;

      if (typeof val === 'string') {
        const result = val.replace(regex, '');
        return result.replace(',', '.');
      }

      return val;
    },

    createdCustomer(customer) {
      this.relationshipSelectItems.push(customer);
      this.item.customers = [{ company: customer.related['@id'] }];
    }
  }
};
</script>

<style scoped lang="scss">
button {
  min-width: 100px !important;
}
</style>
