<template>
  <b-form-row>
    <b-form-group class="col-md-12">
      <h3 class="p-1">Teachers</h3>
      <p>The following teachers will attend this program:</p>

      <table class="table">
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>E-mail</th>
            <th>Teaching</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item['@id']">
            <td v-if="item.user.firstName">{{ item.user.firstName }}</td>
            <td v-if="item.user.lastName">{{ item.user.lastName }}</td>
            <td v-if="item.user.email">{{ item.user.email }}</td>
            <td>
              <span
                v-for="learningActivity in item.learningActivities"
                :key="learningActivity['@id']"
              >
                {{ learningActivity.title }}<br />
              </span>
            </td>
            <td class="text-right">
              <b-spinner v-if="deleteIsLoading(item)" small></b-spinner>
              <a v-else-if="!readOnly" @click.prevent="del(item)" href="#"
                ><i class="fa fa-trash text-info"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form-group>
  </b-form-row>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  props: {
    programId: {
      type: String,
      required: true
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },

  data() {
    return {
      deleting: {}
    };
  },

  computed: {
    ...mapFields('teacher/del', {
      deleted: 'deleted'
    }),
    ...mapFields('teacher/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view',
      totalItems: 'totalItems'
    }),

    getTeachers() {
      return this.items.length;
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }
      this.refreshData();
    }
  },

  created() {
    this.getPage(`${this.programId}/teachers`);
  },

  methods: {
    ...mapActions({
      getPage: 'teacher/list/default',
      deleteItem: 'teacher/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(`${this.programId}/teachers`);
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this teacher?')) {
        let itemId = item['@id'];
        this.deleting[itemId] = true;
        this.deleteItem({ '@id': itemId });
      }
    },

    deleteIsLoading(item) {
      return this.deleting.hasOwnProperty(item['@id']);
    }
  }
};
</script>
