import Vue from 'vue';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

Vue.use(BootstrapVue, {
  formControls: {
    size: 'sm'
  },
  BButton: { size: 'sm' }
});
Vue.use(BootstrapVueIcons);
