<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Name"
      label-for="sector_name"
      :invalid-feedback="violations.name"
      :state="!isValid('name')"
    >
      <b-form-input
        id="sector_name"
        v-model="item.name"
        type="text"
        required="required"
      />
    </b-form-group>

    <b-form-group
      label="Enabled"
      label-for="sector_enabled"
      :invalid-feedback="violations.enabled"
      :state="!isValid('enabled')"
    >
      <b-form-checkbox
        id="sector_enabled"
        switch
        v-model="item.enabled"
        size="lg"
      ></b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Vat Liable"
      label-for="isVatLiable"
      :invalid-feedback="violations.isVatLiable"
      :state="!isValid('isVatLiable')"
    >
      <b-form-checkbox
        id="isVatLiable"
        switch
        v-model="item.isVatLiable"
        size="lg"
      ></b-form-checkbox>
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({}),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createSector');
      this.$bvModal.hide('updateSector');
    }
  }
};
</script>
