<template>
  <div class="company-update">
    <div class="company-update__tabs">
      <TabGroup
        v-if="retrieved"
        :tabs="tabs"
        :company="retrieved"
        @updated="updated"
        :disabled="disabled"
        :is-creating-branch="isCreatingBranch"
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { SwalAlert } from '@/utils/swal';
import { mapGetters } from 'vuex';

export default {
  name: 'CompanyUpdate',

  components: {
    TabGroup: () => import('@/components/_ui/tabs/TabGroup')
  },

  props: {
    isCreatingBranch: {
      type: Boolean,
      default: () => false
    },
    companyId: {
      type: String,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isRole']),
    ...mapFields('company/update', {
      retrieved: 'retrieved'
    })
  },

  data() {
    return {
      tabs: [
        {
          active: 'CompanyGeneral',
          text: 'General Information',
          visible: true
        },
        {
          active: 'Establishments',
          text: 'Establishments',
          visible: true
        },
        {
          active: 'CompanyRoles',
          text: 'Roles',
          visible: true
        }
      ]
    };
  },

  created() {
    if (!this.isRole('ROLE_ADMIN')) {
      this.tabs.pop();
    }

    this.getSectorItems();
    this.retrieve(this.companyId);
  },

  methods: {
    ...mapActions({
      retrieve: 'company/update/retrieve',
      getSectorItems: 'sector/list/getSelectItems'
    }),

    updated() {
      this.$bvModal.hide('editCompany');
      SwalAlert({
        title: 'Company updated',
        icon: 'user',
        type: 'success',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  }
};
</script>
