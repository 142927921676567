<template>
  <div>
    <b-form @submit.prevent="onSendForm(item)">
      <b-form-group
        label="What is the name of the module?"
        label-for="learningModuleName"
        :invalid-feedback="violations.name"
        :state="isValid('name')"
      >
        <b-form-input
          v-model="item.name"
          :state="isValid('name')"
          type="text"
          id="learningModuleName"
          placeholder="Type the name of the Module..."
        />
      </b-form-group>

      <b-form-group v-if="!retrievedProgram.calculateGeneralCosts">
        <b-form-group
          label-cols="4"
          content-cols="4"
          label="Calculation method"
          label-for="proposalCalculationMethod"
        >
          <b-form-select
            v-model="pricingType"
            class="form-control"
            :options="options"
            id="proposalCalculationMethod"
            placeholder="Calculation method"
          />
        </b-form-group>
      </b-form-group>

      <template v-if="pricingType === 'LEARNER'">
        <b-form-group
          label-cols="4"
          content-cols="4"
          label="Max number of Learners"
          label-for="maxLearners"
        >
          <b-form-input
            type="number"
            min="1"
            v-model="item.maxLearners"
            class="form-control"
            id="maxLearners"
            required
          />
        </b-form-group>
      </template>

      <template v-else-if="pricingType === 'GROUP'">
        <b-form-group
          label-cols="4"
          content-cols="4"
          label="Learners Per Group"
          label-for="learnersPerGroup"
        >
          <b-form-input
            type="number"
            min="1"
            v-model="item.learnersPerGroup"
            class="form-control"
            id="learnersPerGroup"
            required
          />
        </b-form-group>

        <b-form-group
          label-cols="4"
          content-cols="4"
          label="Max Learners"
          label-for="maxLearners"
        >
          <b-form-input
            type="number"
            min="1"
            v-model="item.maxLearners"
            class="form-control"
            id="maxLearners"
            required
          />
        </b-form-group>
      </template>

      <b-form-group
        label-cols="4"
        content-cols="4"
        label="Price"
        label-for="proposalCalculationPrice"
        v-if="!retrievedProgram.calculateGeneralCosts"
      >
        <money
          v-model="item.price"
          class="font-size form-input form-control form-control-sm"
          id="proposalCalculationPrice"
          placeholder="Price"
          required
        />
      </b-form-group>

      <b-form-group label="Add Activity" label-for="learningModuleActivities">
        <b-button
          size="sm"
          v-b-modal.createLearningActivity
          variant="primary"
          block
          >+ New
        </b-button>
      </b-form-group>

      <div class="mb-3">
        <div
          class="alert alert-primary"
          v-for="(activity, idx) in item.activities"
          :key="idx"
        >
          {{ getActivityById(activity).title }} ({{
            getActivityById(activity).type
          }})
          <a href="#" class="close" @click.prevent="delActivity(idx)">×</a>
        </div>
      </div>

      <b-form-group
        label-cols="10"
        content-cols="2"
        label="Do you want to add Learning Materials?"
        label-for="isAddingMaterial"
      >
        <b-form-checkbox
          class="float-right"
          id="isAddingMaterial"
          v-model="isAddingMaterial"
          size="lg"
          switch
        ></b-form-checkbox>
      </b-form-group>
      <b-form-group
        label=""
        label-for="learningModuleLearningMaterial"
        v-if="isAddingMaterial"
      >
        <b-form-row>
          <b-col cols="9">
            <b-form-select v-if="materialsIsLoading" disabled value>
              <b-form-select-option selected value
                >Loading...
              </b-form-select-option>
            </b-form-select>
            <b-form-select
              v-else
              @change="addMaterial"
              v-model="newMaterial"
              id="learningModuleLearningMaterial"
              value
            >
              <b-form-select-option value disabled>
                {{ getMaterialSelectText }}
              </b-form-select-option>
              <b-form-select-option
                v-for="material in availableLearningMaterials"
                :key="material['@id']"
                :value="material['@id']"
                >{{ material.title }} ({{ material.author }})
              </b-form-select-option>
            </b-form-select>
          </b-col>
          <b-col cols="3">
            <b-button v-b-modal.createLearningMaterial variant="primary" block
              >+ New
            </b-button>
          </b-col>
        </b-form-row>
      </b-form-group>

      <div class="mb-3" v-if="isAddingMaterial">
        <div
          class="alert alert-primary"
          v-for="(material, idx) in item.materials"
          :key="idx"
        >
          {{ getMaterialById(material).title }}
          <i>({{ getMaterialById(material).author }})</i>
          <a href="#" class="close" @click.prevent="delMaterial(idx)">×</a>
        </div>
      </div>

      <b-form-group class="float-right">
        <b-button
          @click="closeModal"
          class="mr-2"
          type="button"
          variant="secondary"
          v-if="!isLoading"
          >Cancel
        </b-button>

        <b-button
          type="submit"
          variant="success"
          v-if="!isLoading"
          :disabled="!canSaveModule"
          >Save Module
        </b-button>
        <b-button type="submit" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </b-form>

    <!-- Add learning activity modal -->
    <b-modal
      id="createLearningActivity"
      title="Add Learning Activity"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Learning Activity</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createLearningActivity')"
        ></i>
      </template>

      <LearningActivityCreate
        :show-alert="false"
        @created="addActivityFromCreate"
      ></LearningActivityCreate>
    </b-modal>

    <!-- Add learning module modal -->
    <b-modal
      id="createLearningMaterial"
      title="Add Learning Material"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Learning Material</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createLearningMaterial')"
        ></i>
      </template>

      <LearningMaterialCreate
        :show-alert="false"
        @created="addMaterialFromCreate"
      ></LearningMaterialCreate>
    </b-modal>
  </div>
</template>

<script>
import { find, get, isUndefined, isString } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import LearningMaterialCreate from '../../../common/learningmaterial/Create.vue';
import LearningActivityCreate from '../learningactivity/Create.vue';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default {
  components: {
    LearningMaterialCreate,
    LearningActivityCreate
  },
  props: {
    programId: {
      type: String,
      required: true
    },

    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      newMaterial: '',
      newActivity: '',
      options: ['LUMPSUM', 'GROUP', 'LEARNER'],
      pricingType: 'LUMPSUM',
      currencyMask: createNumberMask({
        prefix: '€',
        allowNegative: false,
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ','
      }),
      isAddingMaterial: false
    };
  },

  mounted() {
    if (!this.item.materials) {
      this.$set(this.item, 'materials', []);
    }
    if (!this.item.activities) {
      this.$set(this.item, 'activities', []);
    }
    if (!this.item.calculateModuleCosts) {
      this.$set(this.item, 'calculateModuleCosts', true);
    }

    if (this.item.pricingType) {
      this.pricingType = this.item.pricingType;
    }

    // Retrieve missing data
    this.retrieveProgram(this.programId);

    // Select
    this.materialsGetSelectItems();
    this.activitiesGetSelectItems({
      params: { createdByCompany: this.getCompanyId, itemsPerPage: 10000 }
    });
  },

  watch: {},

  computed: {
    ...mapFields('program/show', {
      isLoadingProgram: 'isLoading',
      retrievedProgram: 'retrieved'
    }),

    ...mapGetters('auth', ['getCompanyId']),

    ...mapFields('learningmaterial/list', {
      materialsIsLoading: 'isLoading',
      materialsSelectItems: 'selectItems'
    }),
    ...mapFields('learningactivity/list', {
      activitiesIsLoading: 'isLoading',
      activitiesSelectItems: 'selectItems'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    availableLearningMaterials() {
      if (!this.materialsSelectItems) {
        return [];
      }

      if (!this.item.materials) {
        return this.materialsSelectItems || [];
      }

      return this.materialsSelectItems.filter((material) => {
        return !this.item.materials.includes(material['@id']);
      });
    },

    getMaterialSelectText() {
      if (this.availableLearningMaterials.length === 0) {
        return 'No Learning Materials available, add new one';
      }
      return 'Choose from your library';
    },

    availableLearningActivities() {
      if (!this.activitiesSelectItems) {
        return [];
      }
      if (!this.item.activities) {
        return this.activitiesSelectItems || [];
      }

      let currentIds = this.item.activities;
      return this.activitiesSelectItems.filter(
        (activity) => !currentIds.includes(activity['@id'])
      );
    },
    canSaveModule() {
      return this.item.activities && this.item.activities.length > 0;
    }
  },

  methods: {
    ...mapActions({
      retrieveProgram: 'program/show/retrieve',
      materialsGetSelectItems: 'learningmaterial/list/getSelectItems',
      activitiesGetSelectItems: 'learningactivity/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    closeModal() {
      this.$bvModal.hide('createLearningModule');
      this.$bvModal.hide('editLearningModule');
    },

    // -------------- Activities --------------
    getActivityById(activityId) {
      if (!this.activitiesSelectItems) {
        return null;
      }
      // Return if object
      if (!isString(activityId)) {
        return activityId;
      }
      let _id = activityId;
      let ret = this.activitiesSelectItems.find(
        (activity) => activity['@id'] === _id
      );
      // @TODO(trepatudo): ugh, ugly hack, we should push the created back and not refresh the data.
      if (!ret) {
        return { title: '', author: '' };
      }
      return ret;
    },
    addActivity() {
      let activity = this.getActivityById(this.newActivity);

      this.item.activities.push({
        createdByCompany: activity.createdByCompany,
        duration: activity.duration,
        materials: activity.materials,
        maxLearners: activity.maxLearners,
        status: activity.status,
        title: activity.title,
        type: activity.type
      });

      this.newActivity = '';
    },
    delActivity(activityIndex) {
      this.item.activities.splice(activityIndex, 1);
    },

    addActivityFromCreate(createdActivity) {
      // Refresh data
      this.activitiesGetSelectItems({
        params: { createdByCompany: this.getCompanyId, itemsPerPage: 10000 }
      });
      // Add new one automatically
      this.item.activities.push(createdActivity['@id']);
    },

    onSendForm() {
      // clean up materials if not using
      if (!this.isAddingMaterial) {
        this.item.materials = [];
      }

      this.item.program = this.programId;
      this.item.subcontractorFee = 0;
      this.item.paymentAfterCompletion = !!this.item.paymentAfterCompletion;
      this.item.pricingType = this.pricingType;

      // const price = this.removeCommasFromString(this.item.price);
      // this.item.price = parseFloat(price);

      if (this.item.pricingType === 'GROUP') {
        this.item.learnersPerGroup = parseInt(this.item.learnersPerGroup);
        this.item.maxLearners = parseInt(this.item.maxLearners);
      }

      if (this.item.pricingType === 'LEARNER') {
        this.item.maxLearners = parseInt(this.item.maxLearners);
      }

      this.handleSubmit(this.item);
    },

    removeCommasFromString(val) {
      const regex = /[.€\s]/g;

      if (typeof val === 'string') {
        const result = val.replace(regex, '');
        return result.replace(',', '.');
      }

      return val;
    },

    // -------------- Materials --------------
    getMaterialById(materialId) {
      if (!this.materialsSelectItems) {
        return false;
      }
      let _id = isString(materialId) ? materialId : materialId['@id'];
      let ret = this.materialsSelectItems.find(
        (material) => material['@id'] === _id
      );
      // @TODO(trepatudo): ugh, ugly hack, we should push the created back and not refresh the data.
      if (!ret) {
        return { title: '', name: '' };
      }
      return ret;
    },

    addMaterial() {
      this.item.materials.push(this.newMaterial);
      this.newMaterial = '';
    },
    addMaterialFromCreate(createdMaterial) {
      // Refresh data
      this.materialsGetSelectItems();
      // Add new one automatically
      this.item.materials.push(createdMaterial['@id']);
    },
    delMaterial(materialIndex) {
      this.item.materials.splice(materialIndex, 1);
    }
  }
};
</script>

<style scoped lang="scss">
.alert {
  padding: 5px 10px !important;
  // font-size: 10px !important;
  margin-bottom: 5px;

  .close {
    // font-size: 15px;
  }
}
</style>
