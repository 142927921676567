import Vue from 'vue';
import dayjs from 'dayjs';

let utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    }
  }
});
