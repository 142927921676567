<template>
  <b-card class="dashboard-card">
    <div v-if="!userIsLoading">
      <div v-if="error" class="alert alert-danger" role="alert">
        <span class="fa fa-exclamation-triangle" aria-hidden="true">
          {{ error }}
        </span>

        <p class="error__text text-center" @click="fetchData">Try again.</p>
      </div>

      <div v-else>
        <span class="dashboard-card__completedTasks" v-b-toggle.collapse-1
          >Show completed Tasks</span
        >

        <b-collapse id="collapse-1" class="mt-2">
          <div class="dashboard-card__content border p-2">
            <div v-for="(item, index) in completedTasks" :key="index">
              <div class="dashboard-card__progress">
                <span class="dashboard-card__progress__title">
                  {{ getTitle(item.title)
                  }}<i
                    class="fas fa-angle-double-right dashboard-card__progress__icon"
                  ></i>
                </span>
                <b-progress
                  :value="item.progress.progress"
                  :max="100"
                  animated
                  :variant="getColorVariant(item.progress.progress)"
                  class="mt-2"
                  vertical
                ></b-progress>
              </div>
            </div>
          </div>
        </b-collapse>

        <div class="dashboard-card__content">
          <div
            class="dashboard-card__item mt-3"
            v-if="progress.profile.progress !== 100"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Your Personal Profile<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :value="progress.profile.progress"
                :max="100"
                animated
                :variant="getColorVariant(progress.profile.progress)"
                class="mt-2"
                vertical
              ></b-progress>
            </div>
            <div class="dashboard-card__completed ml-3">
              <div v-if="progress.profile.progress === 100">
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                  >{{ progress.profile.reason }}</span
                >
                <i class="fas fa-check dashboard-card__completed__icon"></i>
              </div>
              <div v-else>
                <router-link
                  class="dashboard-card__completed__text"
                  :to="getPath('PROFILE', progress.profile.reason)"
                >
                  {{ progress.profile.reason }}
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="dashboard-card__item mt-3"
            v-if="progress.company.progress !== 100"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Your company profile<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :value="progress.company.progress"
                :max="100"
                animated
                :variant="getColorVariant(progress.company.progress)"
                class="mt-2"
                vertical
              ></b-progress>
            </div>
            <div class="dashboard-card__completed ml-3">
              <div v-if="progress.company.progress === 100">
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                  >{{ progress.company.reason }}</span
                >
                <i class="fas fa-check dashboard-card__completed__icon"></i>
              </div>
              <div v-else>
                <router-link
                  class="dashboard-card__completed__text"
                  :to="getPath('COMPANY', progress.company.reason)"
                >
                  {{ progress.company.reason }}
                </router-link>
              </div>
            </div>
          </div>

          <div
            class="dashboard-card__item mt-3"
            v-if="
              progress.impactAnalysis &&
              progress.impactAnalysis.progress !== 100
            "
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Impact Analysis<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :value="progress.impactAnalysis.progress"
                :max="100"
                animated
                :variant="getColorVariant(progress.impactAnalysis.progress)"
                class="mt-2"
                vertical
              ></b-progress>
            </div>

            <div class="dashboard-card__completed ml-3">
              <div v-if="progress.impactAnalysis.progress === 100">
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                  >{{ progress.impactAnalysis.reason }}</span
                >
                <i class="fas fa-check dashboard-card__completed__icon"></i>
              </div>
              <div v-else-if="progress.impactAnalysis.progress === 50">
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                >
                  {{ progress.impactAnalysis.reason }}
                </span>
              </div>
              <div v-else>
                <span
                  class="dashboard-card__completed__text"
                  @click="$bvModal.show('impactAnalysis')"
                >
                  {{ progress.impactAnalysis.reason }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="dashboard-card__item mt-3"
            v-if="progress.proRata && progress.proRata.progress !== 100"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Impact Analysis - Annual Specifics Questionnaire<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :value="progress.proRata.progress"
                :max="100"
                animated
                :variant="getColorVariant(progress.proRata.progress)"
                class="mt-2"
                vertical
              ></b-progress>
            </div>

            <div class="dashboard-card__completed ml-3">
              <div v-if="progress.proRata.progress === 100">
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                  >{{ progress.proRata.reason }}</span
                >
                <i class="fas fa-check dashboard-card__completed__icon"></i>
              </div>

              <div v-else-if="progress.proRata.progress === 50">
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                >
                  {{ progress.proRata.reason }}
                </span>
              </div>

              <div v-else>
                <span
                  class="dashboard-card__completed__text"
                  @click="$bvModal.show('proRata')"
                >
                  {{ progress.proRata.reason }}
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="
              progress.importedCustomers &&
              isRole('ROLE_PROVIDER') &&
              progress.importedCustomers.progress !== 100
            "
            class="dashboard-card__item mt-3"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Imported Relations<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :max="100"
                :value="progress.importedCustomers.progress"
                :variant="getColorVariant(progress.importedCustomers.progress)"
                animated
                class="mt-2"
                vertical
              ></b-progress>
            </div>

            <div class="dashboard-card__completed ml-3">
              <div v-if="progress.importedCustomers.progress === 0">
                <router-link
                  class="dashboard-card__completed__text"
                  :to="
                    getPath(
                      'IMPORTED_CUSTOMERS',
                      progress.importedCustomers.reason
                    )
                  "
                >
                  {{ progress.importedCustomers.reason }}
                </router-link>
              </div>
              <a
                v-else-if="progress.importedCustomers.progress === 25"
                href="#"
                @click.prevent="
                  requestHandshakeApproval('ImportedCustomersHandshake')
                "
              >
                {{ progress.importedCustomers.reason }}</a
              >
              <div v-else>
                <span
                  class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                >
                  {{ progress.importedCustomers.reason }}
                  <i
                    v-if="progress.importedCustomers.progress === 100"
                    class="fas fa-check dashboard-card__completed__icon"
                  ></i>
                </span>
              </div>
            </div>
          </div>

          <div
            v-if="
              progress.invoices &&
              isRole('ROLE_PROVIDER') &&
              progress.invoices.progress !== 100
            "
            class="dashboard-card__item mt-3"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Imported Invoices<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :max="100"
                :value="progress.invoices.progress"
                :variant="getColorVariant(progress.invoices.progress)"
                animated
                class="mt-2"
                vertical
              ></b-progress>
            </div>
            <div class="dashboard-card__completed ml-3">
              <div v-if="progress.invoices.progress === 0">
                <router-link
                  class="dashboard-card__completed__text"
                  :to="getPath('INVOICES', progress.invoices.reason)"
                >
                  {{ progress.invoices.reason }}
                </router-link>
              </div>
              <a
                v-else-if="progress.invoices.progress === 25"
                href="#"
                @click.prevent="requestHandshakeApproval('InvoicesHandshake')"
              >
                {{ progress.invoices.reason }}</a
              >
              <div v-else-if="progress.invoices.progress === 50">
                <router-link
                  class="dashboard-card__completed__text"
                  :to="getPath('INVOICES', progress.invoices.reason)"
                >
                  {{ progress.invoices.reason }}
                </router-link>
              </div>
              <span
                class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                v-else
              >
                {{ progress.invoices.reason }}
                <i
                  v-if="progress.invoices.progress === 100"
                  class="fas fa-check dashboard-card__completed__icon"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="
              progress.aprovaReport && progress.aprovaReport.progress !== 100
            "
            class="dashboard-card__item mt-3"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Aprova Report<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :max="100"
                :value="progress.aprovaReport.progress"
                :variant="getColorVariant(progress.aprovaReport.progress)"
                animated
                class="mt-2"
                vertical
              ></b-progress>
            </div>
            <div class="dashboard-card__completed ml-3">
              <a
                v-if="progress.aprovaReport.progress === 25"
                href="#"
                @click.prevent="
                  requestHandshakeApproval('AprovaReportHandshake')
                "
              >
                {{ progress.aprovaReport.reason }}</a
              >
              <span
                class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                v-else
              >
                {{ progress.aprovaReport.reason }}
                <i
                  v-if="progress.aprovaReport.progress === 100"
                  class="fas fa-check dashboard-card__completed__icon"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="
              progress.rii &&
              isRole('ROLE_PROVIDER') &&
              progress.rii.progress !== 100
            "
            class="dashboard-card__item mt-3"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                RII Payment<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :max="100"
                :value="progress.rii.progress"
                :variant="getColorVariant(progress.rii.progress)"
                animated
                class="mt-2"
                vertical
              ></b-progress>
            </div>

            <div class="dashboard-card__completed ml-3">
              <span
                class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
              >
                {{ formatCurrency(progress.rii.total) }} ({{
                  progress.rii.progress
                }}%)
              </span>
              <i
                v-if="progress.rii.progress === 100"
                class="fas fa-check dashboard-card__completed__icon"
              ></i>
            </div>
          </div>

          <div
            v-if="
              progress.creatorApproval &&
              isRole('ROLE_PROVIDER') &&
              progress.creatorApproval.progress !== 100
            "
            class="dashboard-card__item mt-3"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Creator Approval<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :max="100"
                :value="progress.creatorApproval.progress"
                :variant="getColorVariant(progress.creatorApproval.progress)"
                animated
                class="mt-2"
                vertical
              ></b-progress>
            </div>
            <div class="dashboard-card__completed ml-3">
              <a
                v-if="progress.creatorApproval.progress === 25"
                href="#"
                @click.prevent="
                  requestHandshakeApproval('ProviderSignupHandshake')
                "
              >
                {{ progress.creatorApproval.reason }}</a
              >
              <span
                class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                v-else
              >
                {{ progress.creatorApproval.reason }}
                <i
                  v-if="progress.creatorApproval.progress === 100"
                  class="fas fa-check dashboard-card__completed__icon"
                ></i>
              </span>
            </div>
          </div>

          <div
            v-if="
              progress.accountantApproval &&
              progress.accountantApproval.progress !== 100
            "
            class="dashboard-card__item mt-3"
          >
            <div class="dashboard-card__progress">
              <span class="dashboard-card__progress__title">
                Accountant Approval<i
                  class="fas fa-angle-double-right dashboard-card__progress__icon"
                ></i>
              </span>
              <b-progress
                :max="100"
                :value="progress.accountantApproval.progress"
                :variant="getColorVariant(progress.accountantApproval.progress)"
                animated
                class="mt-2"
                vertical
              ></b-progress>
            </div>
            <div class="dashboard-card__completed ml-3">
              <a
                v-if="progress.accountantApproval.progress === 0"
                href="#"
                @click.prevent="requestApproval"
              >
                {{ progress.accountantApproval.reason }}</a
              >
              <span v-else>
                {{ progress.accountantApproval.reason }}
                <i
                  v-if="progress.accountantApproval.progress === 100"
                  class="fas fa-check dashboard-card__completed__icon"
                ></i>
              </span>
            </div>
          </div>

          <b-modal id="proRata" hide-footer size="xl" v-if="progress.proRata">
            <template #modal-header>
              <div class="custom-modal__popup">
                <span class="custom-modal__popup__title"
                  >Impact Analysis - Annual Specifics Questionary</span
                >
              </div>
              <i
                class="fa fa-times custom-modal__popup__close"
                @click="$bvModal.hide('proRata')"
              ></i>
            </template>
            <ProRata :items="progress.proRata.years" @created="refreshData" />
          </b-modal>

          <b-modal
            id="impactAnalysis"
            hide-footer
            size="xl"
            no-close-on-backdrop
          >
            <template #modal-header>
              <div class="custom-modal__popup">
                <span class="custom-modal__popup__title">Impact Analysis</span>
              </div>
              <i
                class="fa fa-times custom-modal__popup__close"
                @click="$bvModal.hide('impactAnalysis')"
              ></i>
            </template>
            <ImpactAnalysis @created="refreshData" />
          </b-modal>

          <b-modal id="createAccountantApprovalRequest" hide-footer hide-header>
            <template #modal-header>
              <div class="custom-modal__popup">
                <span class="custom-modal__popup__title"
                  >Accountant Approval</span
                >
              </div>
              <i
                class="fa fa-times custom-modal__popup__close"
                @click="$bvModal.hide('createAccountantApprovalRequest')"
              ></i>
            </template>

            <AccountantData @next="onAccountantData" />
          </b-modal>
        </div>
      </div>
    </div>

    <div class="text-center" v-else>
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ImpactAnalysis from '@/components/impact-analysis/Main';
import ProRata from '@/components/pro-rata/Main';

import { entityService } from '@/utils/fetch';
import helpers from '@/mixins/helpers';
import { SwalAlert } from '@/utils/swal';
import AccountantData from '@/components/welcome/AccountantData/Main';

export default {
  name: 'Status',

  components: {
    ImpactAnalysis,
    ProRata,
    AccountantData
  },

  mixins: [helpers],

  props: {
    content: {
      type: Object,
      default: () => {}
    },

    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser', 'getCompanyId', 'isRole']),

    ...mapFields('company/show', ['retrieved']),

    ...mapFields('handshake/create', {
      createdHandshake: 'created'
    }),

    completedTasks() {
      const completed = [];

      Object.entries(this.progress)
        .filter((progress) => {
          return progress[1].progress === 100;
        })
        .forEach((item) => {
          completed.push({
            title: this.capitalize(item[0]).replace(/[A-Z]/g, ' $&').trim(),
            progress: item[1]
          });
        });

      return completed;
    }
  },

  data() {
    return {
      user: null,
      userIsLoading: false,
      error: null,
      progress: null
    };
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    ...mapActions('handshake/create', { createHandshake: 'create' }),

    fetchData() {
      this.fetchUser();
      this.fetchProgress();
    },

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.$emit('refreshData');
    },

    fetchProgress() {
      this.userIsLoading = true;
      entityService(`/dashboard/progress`)
        .then((response) => response.json())
        .then((data) => {
          this.progress = data;
          this.error = '';
        })
        .catch((e) => {
          this.error = 'Something went wrong.';
        })
        .finally(() => {
          this.userIsLoading = false;
        });
    },

    fetchUser() {
      this.userIsLoading = true;
      entityService(`/users/${this.getUser.id}`)
        .then((response) => response.json())
        .then((data) => {
          this.user = data;
          this.error = '';
        })
        .catch((e) => {
          this.error = 'Something went wrong.';
        })
        .finally(() => {
          this.userIsLoading = false;
        });
    },

    requestHandshakeApproval(type) {
      const message = type === 'InvoicesHandshake' ? 'invoices' : 'relations';

      let text = `This action will send a request to your accountant to approve your ${message}`;

      if (type === 'ProviderSignupHandshake') {
        text =
          'This action will send a request to your accountant to approve your company';
      }
      SwalAlert({
        subTitle: 'Are you sure?',
        description: text,
        icon: 'delete',
        type: 'danger',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning'
          },
          confirm: {
            label: 'Yes, go ahead',
            type: 'success',
            callback: () => {
              this.createHandshake({
                type: type
              });
            }
          }
        }
      }).finally((e) => {
        this.isLoading = false;
      });
    },

    onAccountantData(data) {
      // Create handshake
      this.createHandshake({
        type: 'AccountantSignupHandshake',
        metadata: {
          accountant: {
            type: data.accountantRegistrationType,
            number: data.accountantRegistrationType,
            professionalOrganization: data.accountantProfessionalOrganization
          }
        }
      });
    },

    requestApproval() {
      this.$bvModal.show('createAccountantApprovalRequest');
    },

    getPath(type, reason) {
      if (type === 'PROFILE') {
        if (reason.includes('Language')) {
          return '/my/profile/account';
        }

        return '/my/profile/personal';
      } else if (type == 'COMPANY') {
        if (reason.includes('Bank Account')) {
          return '/my/company/financial';
        } else if (reason.includes('Primary Contact')) {
          return '/my/company/employees';
        } else if (reason.includes('Accountant')) {
          return '/my/company/financial';
        }

        return '/my/company';
      } else if (type === 'IMPORTED_CUSTOMERS') {
        return '/toolbox/imported/relations';
      } else if (type === 'INVOICES') {
        return '/toolbox/imported/invoices';
      }

      return '/';
    },

    getColorVariant(value) {
      let color = 'danger';

      if (value === 100) {
        color = 'success';
      } else if (value < 50) {
        color = 'danger';
      } else {
        color = 'warning';
      }
      return color;
    },

    getTitle(title) {
      return title === 'Pro Rata'
        ? 'Impact Analysis - Annual Specifics Questionary'
        : title;
    }
  },

  watch: {
    createdHandshake: function (created) {
      /**
       * TODO: Change title when handshake type is: Accountant and Relations
       */
      if (!created) {
        return;
      }
      this.$bvModal.hide('createAccountantApprovalRequest');
      // refresh data
      this.refreshData();
      // Swal, invitation sent now must wait
      SwalAlert({
        title: 'Company Approval',
        description: 'A request was sent!',
        icon: 'user',
        type: 'success',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.dashboard-card {
  &__completedTasks {
    text-decoration: underline;
    transition: all 0.2s ease;

    &:hover {
      color: $primary;
    }
  }

  &__title {
    // font-size: 32px;
    font-weight: 800;
  }

  &__item {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__progress {
    width: 50%;
    color: $gray-800;

    &__title {
      // font-size: 12px;
      margin-bottom: 5px;
    }

    &__icon {
      // font-size: 10px;
      margin-left: 3px;
    }
  }

  &__completed {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 12px;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__text {
      font-family: $default-font-family;
      font-weight: bold;
      // font-size: 12px;
      color: $black;

      transition: all 0.2s linear;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      &--nohover {
        &:hover {
          color: $black;
        }
      }
    }

    &__icon {
      color: $success;
    }
  }

  &__audit {
    &__title {
      // font-size: 12px;
      color: $gray-700;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 800;

      &__value {
        // font-size: 28px;
      }

      &__title {
        // font-size: 12px;
        color: $gray-700;
      }
    }
  }
}

.error__text {
  text-decoration: underline;
  cursor: pointer;
}
</style>
