import * as types from './mutation_types';

export default {
  [types.EMPLOYEES_LIST_RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      items: [],
      selectItems: null,
      view: [],
      totalItems: 0
    });
  },

  [types.EMPLOYEES_LIST_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.EMPLOYEES_LIST_SET_ITEMS](state, items) {
    Object.assign(state, {
      error: '',
      items
    });
  },

  [types.EMPLOYEES_LIST_SET_SELECT_ITEMS](state, selectItems) {
    Object.assign(state, { selectItems });
  },

  [types.EMPLOYEES_LIST_TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },

  [types.EMPLOYEES_LIST_SET_VIEW](state, view) {
    Object.assign(state, { view });
  },

  [types.EMPLOYEES_LIST_SET_TOTAL_ITEMS](state, totalItems) {
    Object.assign(state, { totalItems });
  }
};
