import CompanyList from '@/components/dashboard/company/List';
import CompanyCreate from '@/components/dashboard/company/Create';
import CompanyUpdate from '@/components/dashboard/company/Update';

export default [
  { name: 'CompanyList', path: '/companies', component: CompanyList },

  {
    name: 'CompanyCreate',
    path: '/companies/create',
    component: CompanyCreate
  },
  {
    name: 'CompanyUpdate',
    path: '/companies/edit/:id',
    component: CompanyUpdate
  }
];
