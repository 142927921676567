<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="program"
      @changePage="changePage"
      showSearchInput
      @changeFilter="changeFilter"
      showTitle
    >
      <template v-slot:title>
        <img src="@/assets/img/icons/menu/programs.svg" />
        Executions overview
      </template>
      <template v-slot:customerName="data">
        <i v-if="data.item.isOpenEnrollment">(Open Enrollment)</i>
        <span v-else>{{ data.item.customer.name }}</span>
      </template>
      <template v-slot:status="data">
        <Status
          :value="data.item['status'] === 'INVOICED'"
          class="text-center"
        />
      </template>

      <template v-slot:actions="data">
        <div class="program__icon">
          <router-link
            v-b-tooltip.hover
            title="View Program"
            :to="{
              name: 'ExecuteUpdate',
              params: { id: iriToId(data.item['@id']) }
            }"
          >
            <i aria-hidden="true" class="fa fa-eye text-info mr-2"></i>
          </router-link>
          <a
            v-b-tooltip.hover
            title="Open Document"
            v-if="data.item.auditStatus === 'ACCEPTED'"
            class="mr-2"
            href="#"
            @click.prevent="openDocument(data.item)"
          >
            <i class="fas fa-file-invoice text-info"></i>
          </a>
          <a
            class="mr-2"
            href="#"
            @click.prevent="openHtml(data.item['@id'])"
            v-b-tooltip.hover
            title="Open HTML"
          >
            <b-spinner small v-if="isFetchingDocument[data.item['@id']]" />
            <i class="fas fa-download text-info" v-else></i>
          </a>
          <Cancel
            class="program__icon--reject"
            @click.native="
              proposalAcceptOrReject(data.item['@id'], 'to_cancelled')
            "
            v-if="data.item['status'] === 'EXECUTED'"
          />
        </div>
      </template>
    </CustomTable>

    <b-modal id="viewDocument" hide-footer size="xl" title="Program Documents">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Program Documents</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('viewDocument')"
        ></i>
      </template>

      <DocumentList :viewDocument="viewDocument"></DocumentList>
    </b-modal>

    <b-modal id="viewHtml" hide-footer modal-class="modal-fs"
      ><span v-html="viewHtml"></span>
    </b-modal>

    <b-modal
      id="greenLight"
      hide-footer
      no-close-on-backdrop
      @hide="closeGreenLightModal"
    >
      <div class="text-center text-primary">
        You must have green light before creating any program
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Status from '@/components/_ui/tables/Status';
import CustomTable from '@/components/_ui/tables/CustomTable';
import DocumentList from '@/components/document/List';
import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';
import helpers from '@/mixins/helpers';
import programs from '@/mixins/programs';
import Cancel from '@/components/_ui/svg/Cancel';
import { SwalAlert } from '@/utils/swal';
import { entityService } from '@/utils/fetch';

export default {
  components: { Status, CustomTable, DocumentList, Cancel },

  mixins: [helpers, programs],

  data() {
    return {
      viewDocument: '',
      viewHtml: '',
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'status',
          label: 'Completed',
          sortable: true
        },
        {
          key: 'customerName',
          label: 'Beneficiary',
          sortable: true
        },
        {
          key: 'accountant.name',
          label: 'Accountant',
          sortable: true
        },
        {
          key: 'theme.name',
          label: 'Theme',
          sortable: true
        },
        {
          key: 'totalPrice',
          label: 'Price',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.price);
          }
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      isFetchingDocument: {},
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapGetters('auth', ['getCompany', 'getCompanyId']),

    ...mapFields('program/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/programs?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status[]=STARTED&status[]=EXECUTED&status[]=INVOICED&status[]=COMPLETED`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.fetchCompany();
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    async fetchCompany() {
      await entityService(this.getCompanyId)
        .then((response) => response.json())
        .then((data) => {
          if (data.semaphoreState !== 'GREEN') {
            this.$bvModal.show('greenLight');
            return;
          }
        });
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    openDocument(item) {
      this.viewDocument = item;
      this.$bvModal.show('viewDocument');
    },

    openHtml(id) {
      this.$set(this.isFetchingDocument, id, true);

      const link = `${BUSINESS_SERVICE_ENTRYPOINT}${id}/invoicing-report`;
      fetch(link)
        .then((response) => response.json())
        .then((data) => {
          const linkSource = `data:application/pdf;base64,${data.data}`;
          const downloadLink = document.createElement('a');
          const fileName = `Invoice-Report-${id}`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();

          this.$set(this.isFetchingDocument, id, false);
        })
        .catch(() => {
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: 'Could not download document',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        })
        .finnaly(() => {
          this.$set(this.isFetchingDocument, id, false);
        });
    },

    closeGreenLightModal() {
      this.$router.push('/');
    }
  }
};
</script>
<style lang="scss">
.modal-fs .modal-dialog {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
  z-index: 999 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.program {
  &__icon {
    display: flex;
    align-items: center;

    cursor: pointer;

    &--reject {
      height: 25px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
