<template>
  <div>
    <Chat />
  </div>
</template>

<script>
import Chat from '../components/chat/Main.vue';

export default {
  components: {
    Chat
  }
};
</script>
