<template>
  <b-form @submit.prevent="onFormSend(item)">
    <b-form-group label="Beneficiary Name" label-for="customer_name">
      <v-select
        id="customer_name"
        :clearable="false"
        v-model="item.customer_name"
        @input="fetchCreditNotesAndShareables($event['@id'])"
        :options="companyItems"
        label="name"
        required
      ></v-select>
    </b-form-group>
    <div>
      <b-form-group
        label="Date"
        label-for="date"
        :invalid-feedback="violations.date"
      >
        <b-input-group class="mb-3">
          <b-form-input
            id="date"
            v-model="item.date"
            type="text"
            placeholder="YYYY-MM-DD"
            autocomplete="off"
            :readonly="isFetchingContent"
            required
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
              :disabled="isFetchingContent"
              :readonly="isFetchingContent"
              v-model="item.date"
              button-only
              right
              locale="en-US"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <!-- <b-form-group label="Amount" label-for="amount">
        <money
          id="amount"
          v-model="item.amount"
          :disabled="isFetchingContent"
          class="font-size form-input form-control form-control-sm"
          />
        </b-form-input>
      </b-form-group> -->

      <CustomTable :values="payments" :fields="fields">
        <template v-slot:total_paid="data">
          <span>
            {{ formatCurrency(data.item.totalAmount - data.item.unpaid) }}
          </span>
        </template>

        <template v-slot:amount_to_pay="data">
          <b-form-checkbox v-model="item.toPay" :value="data.item" switch>
          </b-form-checkbox>
          <!-- <money
          id="amount"
          v-model="data.item.amount_to_pay"
          :disabled="invoiceablesisLoading"
          class="font-size form-input form-control form-control-sm"
          :max="data.item.unpaid_amount"
          /> -->
        </template>
      </CustomTable>

      <b-form-group class="float-right" v-if="payments.length > 0">
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Submit</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import { get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import dayjs from 'dayjs';
import helpers from '@/mixins/helpers';

export default {
  components: {
    CustomTable
  },

  mixins: [helpers],

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', ['getAccessToken']),

    ...mapFields('company/list', {
      error: 'error',
      companyItems: 'items',
      companyIsLoading: 'isLoading',
      view: 'view',
      totalItems: 'totalItems'
    }),

    ...mapFields('creditNotes/list', {
      creditNotes: 'items',
      creditNotesIsLoading: 'isLoading'
    }),

    ...mapFields('shareables/list', {
      shareables: 'items',
      shareablesIsLoading: 'isLoading'
    }),

    violations() {
      return this.errors || {};
    },
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    amountToPay() {
      return this.payments.map((item) => item.amount_to_pay);
    },

    payments() {
      return [...this.creditNotes, ...this.shareables];
    },

    isFetchingContent() {
      return this.shareablesIsLoading || this.creditNotesIsLoading;
    }
  },

  created() {
    this.getCompanyItems({ params: { itemsPerPage: 100000 } });
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            return dayjs(item.createdAt).format('DD/MM/YYYY');
          }
        },
        {
          key: 'documentNumber',
          label: 'Document Number',
          sortable: true
        },
        {
          key: 'totalAmount',
          label: 'Total',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.totalAmount);
          }
        },
        {
          key: 'total_paid',
          label: 'Paid',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.total_paid);
          }
        },
        {
          key: 'unpaid',
          label: 'Unpaid',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.unpaid);
          }
        },
        {
          key: 'amount_to_pay',
          label: 'To Pay',
          sortable: true
        }
      ]
    };
  },

  methods: {
    ...mapActions({
      getCreditNotes: 'creditNotes/list/default',
      getShareables: 'shareables/list/default',
      getCompanyItems: 'company/list/getSelectItems'
    }),

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    fetchCreditNotesAndShareables(id) {
      this.item.amount = '';

      this.getCreditNotes(`${id}/credit-notes`);
      this.getShareables(`${id}/shareables`);
    },

    paySinglePaymentFullAmount(index, amount) {
      //Set amount to pay to total of the selected payment
      this.$set(this.payments[index], 'amount_to_pay', amount);
    },

    resetAmounts() {
      // Reset amounts to pay to zero
      this.payments.forEach((item, index) => {
        this.$set(this.payments[index], 'amount_to_pay', '');
      });
    },

    onFormSend(item) {
      const shareables = [];
      const credit_notes = [];

      this.item.toPay.forEach((payment) => {
        const obj = {
          id: payment['@id'],
          // amount: parseInt(parseFloat(payment.amount_to_pay) * 1000)
          amount: parseInt(parseFloat(payment.unpaid))
        };

        if (payment['@type'] === 'Shareable') {
          shareables.push(obj);
        } else {
          credit_notes.push(obj);
        }
      });
      // this.payments.forEach((payment) => {
      //   const obj = {
      //     id: payment['@id'],
      //     amount: payment.amount_to_pay
      //   };

      //   if (payment['@type'] === 'Shareable') {
      //     shareables.push(obj);
      //   } else {
      //     credit_notes.push(obj);
      //   }
      // });

      const body = {
        date: item.date,
        shareables,
        credit_notes
      };

      this.handleSubmit(body);
    }
  },

  watch: {
    'item.amount'(value) {
      let total = value;
      let i = 0;

      // Reset count if amount changed
      this.resetAmounts();
      // Loop while total is bigger than zero and if array length is bigger than loop index
      while (total > 0 && this.payments.length > i) {
        const amount = this.convertToDecimal(this.payments[i].unpaid);
        const amountToPay = total > amount ? amount : total;

        this.$set(this.payments[i], 'amount_to_pay', amountToPay);

        total -= amount;
        i++;
      }
    }
  }
};
</script>

<!---
<style lang="scss">
.unpaid_amount {
  text-decoration: underline;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    color: #209aec;
  }
}
</style>
-->
