<template>
  <div class="aprova-report__form">
    <div class="aprova-report__form__items">
      <h1 class="aprova-report__form__subtitle">Accountant Verification</h1>
    </div>

    <div class="text-center" v-if="isLoading">
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>

    <div class="accountant-verification" v-else>
      <template v-if="verificationLogs.length > 0">
        <div
          class="accountant-verification__item"
          v-for="(log, index) in verificationLogs"
          :key="index"
        >
          <Status
            class="accountant-verification__item__status"
            :type="log.status"
          />

          <span
            class="accountant-verification__item__text"
            v-html="getInfoText(log)"
          >
          </span>
        </div>
      </template>

      <template v-else>
        <div class="text-center">
          <span class="aprova-report__form__subtitle">No logs available.</span>
        </div>
      </template>
      <div
        class="mt-4"
        v-if="!isRole('ROLE_ACCOUNTANT') && verificationLogs.length > 0"
      >
        <span
          >If all provided CSV Data is correct, <br />
          Validation can be
          requested for this AprovA Report.</span
        >
        <div class="accoutant-request">
          <b-form-group>
            <b-button
              class="mt-3"
              type="button"
              variant="primary"
              @click="requestValidation(companyId, 'AprovaReportHandshake')"
              v-if="!isLoading"
              >Request validation</b-button
            >

            <b-button type="submit" variant="primary" v-else>
              <b-spinner small></b-spinner>
            </b-button>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Status from '@/components/_ui/status/Status';
import { entityService } from '@/utils/fetch';
import handshakes from '@/mixins/handshakes';
import { mapGetters } from 'vuex';
import helpers from '@/mixins/helpers';

export default {
  name: 'AccountantVerification',

  mixins: [helpers, handshakes],

  components: {
    Status
  },

  props: {
    companyId: {
      type: String,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters('auth', ['isRole'])
  },

  data() {
    return {
      isLoading: false,
      verificationLogs: []
    };
  },

  mounted() {
    this.isLoading = true;

    entityService(
      `${this.companyId}/handshakes?type[]=ImpacAnalysisHandshake&type[]=AprovaReportHandshake&type[]=InvoicesHandshake&type[]=ImportedCustomersHandshake`
    )
      .then((response) => response.json())
      .then((data) => {
        this.verificationLogs = data['hydra:member'];
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    getInfoText(log) {
      const text = this.getHandshakeTypeText(log.type);

      if (log.status === 'PENDING') {
        return `${text} is waiting verification`;
      } else if (log.status === 'REJECTED') {
        return `${text} was rejected`;
      } else {
        const date = log.validatedAt
          ? this.formatDate(log.validatedAt)
          : 'N/A  ';

        return `${text} verified on <strong>${date}</strong> by <strong>${log.user.email}</strong>`;
      }
    },

    getHandshakeTypeText(type) {
      switch (type) {
        case 'ImpacAnalysisHandshake':
          return 'Impact Analysis';
        case 'AprovaReportHandshake':
          return 'AprovA+Report';
        case 'InvoicesHandshake':
          return 'Financial Data';
        case 'ImportedCustomersHandshake':
          return 'Relation List ';
        default:
          return 'Creator+';
      }
    }
  }
};
</script>

<style lang="scss">
.accountant-verification {
  &__item {
    display: flex;
    padding: 10px 0px;

    &__text {
      margin-left: 10px;
    }
  }
}
</style>
