<template>
  <b-form @submit.prevent="impersonateUser">
    <b-form-group label="Name" label-for="impersonate_name">
      {{ user.firstName }} {{ user.lastName }}
    </b-form-group>
    <b-form-group label="Email Address" label-for="impersonate_email">
      {{ user.email }}
    </b-form-group>

    <b-form-group label="Role" label-for="impersonate_role">
      <b-form-select
        id="impersonate_role"
        v-model="form.role"
        :options="getRoles"
        text-field="name"
        value-field="id"
      >
      </b-form-select>
    </b-form-group>

    <b-form-group label="Company" label-for="impersonate_company">
      <b-form-select id="impersonate_company" v-model="form.company">
        <!--        <b-form-select-option value="0">No company</b-form-select-option>-->
        <b-form-select-option
          v-for="company in getCompanies"
          :key="company['@id']"
          :value="company.id"
          >{{ company.name }}</b-form-select-option
        >
      </b-form-select>
    </b-form-group>

    <b-form-group class="text-right">
      <b-button
        type="submit"
        variant="danger"
        class="font-weight-bold"
        :disabled="!canImpersonate"
        >IMPERSONATE!</b-button
      >
    </b-form-group>
  </b-form>
</template>

<script>
import { mapActions } from 'vuex';
import { IMPERSONATE_USER } from '../../../store/modules/auth/actions';
import helpers from '@/mixins/helpers';

export default {
  props: {
    user: {
      type: Object,
      required: true
    }
  },

  mixins: [helpers],

  computed: {
    getRoles: function () {
      return this.user.roles.filter((r) => r !== 'ROLE_ADMIN');
    },
    canImpersonate: function () {
      return (
        this.form.role.length > 0 &&
        !(this.form.role != 'ROLE_USER' && this.form.company == 0)
      );
    },
    getCompanies: function () {
      return this.user.companies.map((c) => {
        return {
          '@id': c.company['@id'],
          id: this.parseRelationToId(c.company['@id']),
          name: c.company.name
        };
      });
    }
  },

  created() {
    if (this.getCompanies) {
      this.form.company = this.getCompanies[0].id;
    }
  },

  data() {
    return {
      form: {
        company: 0,
        role: 'ROLE_USER'
      }
    };
  },

  methods: {
    ...mapActions('auth', [IMPERSONATE_USER]),
    impersonateUser() {
      this[IMPERSONATE_USER]({
        user: this.user,
        role: this.form.role,
        company: this.form.company
      }).then(() => {
        // Refresh on success!
        window.location = '/';
      });
    }
  }
};
</script>
