<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Title"
      label-for="learningmaterial_title"
      :invalid-feedback="violations.title"
      :state="!isValid('title')"
    >
      <b-form-input
        v-model="item.title"
        :state="isValid('title')"
        id="learningmaterial_title"
        type="text"
        required="required"
        placeholder=""
      />
    </b-form-group>

    <b-form-row>
      <b-col cols="6">
        <b-form-group
          label="Author"
          label-for="learningmaterial_author"
          :invalid-feedback="violations.author"
          :state="isValid('author')"
        >
          <b-form-input
            v-model="item.author"
            :state="isValid('author')"
            id="learningmaterial_author"
            type="text"
            required="required"
            placeholder=""
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="ISBN"
          label-for="learningmaterial_isbn"
          :invalid-feedback="violations.isbn"
          :state="isValid('isbn')"
        >
          <b-form-input
            v-model="item.isbn"
            :state="isValid('isbn')"
            id="learningmaterial_isbn"
            type="text"
            required="required"
            placeholder=""
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col cols="6">
        <b-form-group
          label="Language"
          label-for="learningmaterial_language"
          :invalid-feedback="violations.language"
          :state="isValid('language')"
        >
          <b-form-select
            v-model="item.language"
            :state="isValid('language')"
            id="learningmaterial_language"
            class="form-control"
          >
            <b-form-select-option
              v-for="selectItem in languageSelectItems"
              :key="selectItem['@id']"
              :value="selectItem['@id']"
              :selected="isSelected('related', selectItem['@id'])"
              >{{ selectItem.name }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="URL"
          label-for="learningmaterial_url"
          :invalid-feedback="violations.url"
          :state="isValid('url')"
        >
          <b-form-input
            v-model="item.url"
            :state="isValid('url')"
            id="learningmaterial_url"
            type="text"
            placeholder=""
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >

      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  created() {
    this.languageGetSelectItems();
  },

  watch: {
    // Select automatically if there's only 1
    languageSelectItems: function (languages) {
      if (languages.length === 1) {
        this.item.language = languages[0]['@id'];
      }
    }
  },

  computed: {
    ...mapFields('language/list', {
      languageSelectItems: 'selectItems'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({
      languageGetSelectItems: 'language/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    closeModal() {
      this.$bvModal.hide('createLearningMaterial');
    }
  }
};
</script>
