<template>
  <i class="fa fa-ellipsis-h status__icon status__icon--warning"></i>
</template>

<script>
export default {
  name: 'Pending'
};
</script>

<style lang="scss">
.status {
  &__icon {
    color: $white;
    border-radius: 15px;
    padding: 5px;
    // font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;

    &--warning {
      background-color: $warning;
    }
  }
}
</style>
