<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="language"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:enabled="data">
        <Status class="text-center" :value="data.item.enabled" />
      </template>

      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
          ><i class="fa fa-edit text-info"></i
        ></a>
      </template>
    </CustomTable>

    <b-modal id="updateLanguage" title="Update Language" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Language</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateLanguage')"
        ></i>
      </template>

      <LanguageUpdate
        @created="refreshData"
        :language="editLanguage"
      ></LanguageUpdate>
    </b-modal>

    <b-modal id="createLanguage" title="Create Language" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Language</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createLanguage')"
        ></i>
      </template>

      <LanguageCreate @updated="refreshData"></LanguageCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Status from '@/components/_ui/tables/Status';
import LanguageCreate from './Create';
import LanguageUpdate from './Update.vue';

export default {
  components: {
    LanguageCreate,
    LanguageUpdate,
    CustomTable,
    Status
  },

  data() {
    return {
      editLanguage: {},
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'code', label: 'Code', sortable: true },
        { key: 'enabled', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapFields('language/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/languages?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },
  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'language/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editLanguage = item;
      this.$bvModal.show('updateLanguage');
    }
  }
};
</script>
