<template>
  <b-container>
    <b-form method="POST" @submit.prevent="doReset">
      <b-row align-h="center">
        <b-col cols="6">
          <b-input-group class="input-icon">
            <i class="fa fa-user"></i>
            <b-form-input
              type="email"
              v-model="form.email"
              placeholder="Email Address"
              :state="emailValidation"
              required
              size="lg"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="6">
          <div>
            <b-button
              variant="primary"
              type="submit"
              v-if="!loading && !message.length"
              size="lg"
              >Reset Password</b-button
            >
            <div v-else-if="message.length">
              <div class="message-success" v-if="message.length">
                {{ message }}
              </div>

              <router-link :to="{ name: 'Login' }" class="text-center">
                Go to Login
              </router-link>
            </div>
            <b-button variant="primary" type="submit" disabled v-else>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
            <b-form-invalid-feedback v-if="errors.length" force-show>
              {{ errors }}
            </b-form-invalid-feedback>
          </div>
          <router-link :to="{ path: '/login' }" class="text-center"
            >Back</router-link
          >
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import validators from '@/mixins/validators';
/* import { entityService as fetch } from '../../utils/fetch'; */

import { ENTITY_SERVICE_ENTRYPOINT } from '@/config/entrypoint';

export default {
  name: 'RecoverView',

  mixins: [validators],
  data() {
    return {
      loading: false,
      errors: [],
      message: '',
      showGotoLogin: null,
      form: {
        email: ''
      }
    };
  },
  computed: {
    hasErrors() {
      return !!this.errors && this.errors.length > 0;
    },
    emailValidation() {
      return this.form.email.length === 0
        ? null
        : this.errors.length || !this.validateEmail(this.form.email)
        ? false
        : true;
    }
  },
  watch: {
    form: {
      deep: true,
      handler: function (form) {
        this.errors = [];
      }
    }
  },
  methods: {
    doReset() {
      this.loading = true;
      let form = new FormData();
      form.append('email', this.form.email);
      fetch(`${ENTITY_SERVICE_ENTRYPOINT}/onboarding/recovery`, {
        method: 'POST',
        body: form
      })
        .then((response) => response.json())
        .then((data) => (this.message = data.message))
        .catch((error) => {
          this.errors = error;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
form {
  margin-top: 35px;
  .form-group {
    margin-bottom: 5px;
  }
}
button {
  width: 100%;
  padding: 10px;
}

a.text-center {
  margin-top: 10px;
  display: inherit;
  color: $gray-500;
}

.invalid-feedback {
  background-color: $danger;
  color: $white;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.message-success {
  background-color: $green;
  color: $white;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.input-icon {
  margin: 10px 0;
  position: relative;
  i {
    color: $dark;
    position: absolute;
    left: 13px;
    top: 15px;
    z-index: 999999;
  }

  input {
    border-radius: 5px !important;
    width: 100%;
    height: 45px;
    background-color: $dark-blue;
    color: $dark;
    padding-left: 37px;
    &:hover,
    &:focus {
      background-color: $gray-200;
      color: $dark-blue;
    }
    border: none;
  }
}

.form-control {
  -webkit-text-fill-color: $gray-200;
  -webkit-box-shadow: 0 0 0 1000px $dark-blue inset;
}
.form-control:focus,
.form-control:hover {
  -webkit-text-fill-color: $dark-blue;
  -webkit-box-shadow: 0 0 0 1000px $gray-200 inset;
}

input,
button {
  font-size: 16px;
}
</style>
