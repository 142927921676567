<template>
  <b-container class="px-4" fluid>
    <b-row v-if="!isLoading">
      <b-col cols="7">
        <div class="d-flex align-items-center">
          <img
            alt=""
            class="mr-2"
            src="../../../../assets/img/icons/menuicons/relations@2x.png"
          />
          <h3 class="font-weight-bold mt-2">My Personal Settings</h3>
        </div>

        <b-form @submit.prevent="onSendForm()">
          <b-row>
            <b-col cols="6">
              <b-form-group label="First Name" label-for="first_name">
                <b-form-input
                  id="first_name"
                  v-model="form.personal.firstName"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Last Name" label-for="last_name">
                <b-form-input
                  id="last_name"
                  v-model="form.personal.lastName"
                  type="text"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-form-group label="Telephone Number" label-for="phone_number">
                <b-form-input
                  id="phone_number"
                  v-model="form.personal.phoneNumber"
                  type="tel"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <AddressForm
          v-if="isAddressReady"
          :errors="addressViolations"
          :handle-submit="onSendForm"
          :is-loading="isSubmitting"
          :values="form.address"
          :skipLastStep="true"
          :showPrevious="false"
        >
          <template v-slot:submitButton>
            <b-button type="submit" variant="primary">Save</b-button>
          </template>
        </AddressForm>
      </b-col>
    </b-row>

    <div class="text-center p-5" v-else>
      <b-col cols="7">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
      </b-col>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { AUTH_REFRESH_TOKEN } from '@/store/modules/auth/actions';
import { find } from 'lodash';
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';
import helpers from '@/mixins/helpers';

import AddressForm from '@/components/common/address/Form';

export default {
  components: {
    AddressForm
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getUser', 'getToken']),

    ...mapFields('address/create', {
      addressIsLoading: 'isLoading',
      addressCreated: 'created',
      addressViolations: 'violations'
    }),

    getRole() {
      return this.parseRoleName(this.getUser.role);
    },

    /**
     * Check if input is different than database
     * @returns {boolean|*}
     */
    isAddressChanged() {
      if (!this.getUser.entity?.address) {
        return true;
      }

      let userAddress = this.getUser.entity?.address;
      // Duh
      return (
        this.form.address.state !== userAddress.state ||
        this.form.address.city !== userAddress.city ||
        this.form.address.street !== userAddress.street ||
        this.form.address.number !== userAddress.number ||
        this.form.address.zip !== userAddress.zip ||
        this.form.address.country !== userAddress.country['@id']
      );
    }
  },

  watch: {
    addressCreated: function (address) {
      // Form new payload
      let item = { ...this.form.personal };
      item.address = address['@id'];
      // Send to final callback
      this.onSendConfirmedForm(item);
    }
  },

  mounted() {
    this.getUserInfo();
  },

  data() {
    return {
      error: '',
      isLoading: false,
      isSubmitting: false,
      form: {
        personal: {
          firstName: '',
          lastName: '',
          phoneNumber: ''
        },
        address: {
          state: '',
          city: '',
          street: '',
          number: '',
          zip: '',
          country: ''
        }
      },
      isAddressReady: false
    };
  },

  methods: {
    ...mapActions('auth', [AUTH_REFRESH_TOKEN]),
    ...mapActions('address/create', ['create', 'reset']),

    isSelected(collection, id) {
      return find(this.form[collection], ['@id', id]) !== undefined;
    },

    onSendConfirmedForm(item) {
      entityService(this.getUser.entity['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(item)
      })
        .then((response) => response.json())
        .then((data) => {
          // Refresh data
          this.getUserInfo();

          // Show alert
          SwalAlert({
            title: 'Account created',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {}
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            description: e,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally((e) => {
          this.isSubmitting = false;
        });
    },

    /**
     * Handles both cases (Address changed or not)
     */
    onSendForm() {
      this.isSubmitting = true;

      if (this.isAddressChanged) {
        this.create(this.form.address);
      } else {
        let item = { ...this.form.personal };
        this.onSendConfirmedForm(item);
      }
    },

    getUserInfo() {
      this.isAddressReady = false;

      this.isLoading = true;
      entityService(this.getUser.entity['@id'])
        .then((response) => response.json())
        .then((data) => {
          // Fill address
          if (data.address) {
            this.form.address = {
              state: data.address.state,
              city: data.address.city,
              street: data.address.street,
              number: data.address.number,
              zip: data.address.zip,
              country: data.address.country
            };
          }

          this.form.personal.firstName = data.firstName;
          this.form.personal.lastName = data.lastName;
          this.form.personal.phoneNumber = data.phoneNumber;
        })
        .finally(() => {
          this.isAddressReady = true;
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $white;
}
</style>
