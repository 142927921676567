import QuestionList from '@/components/admin/question/List';
import QuestionCreate from '@/components/admin/question/Create';
import QuestionUpdate from '@/components/admin/question/Update';

export default [
  { name: 'QuestionList', path: '/questions/', component: QuestionList },
  {
    name: 'QuestionCreate',
    path: '/questions/create',
    component: QuestionCreate
  },
  {
    name: 'QuestionUpdate',
    path: '/questions/edit/:id',
    component: QuestionUpdate
  }
];
