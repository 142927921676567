import Swal from 'sweetalert2';
import Vue from 'vue';

export async function SwalAlert(propsData) {
  let buttons = propsData.buttons ? propsData.buttons : {};

  const swalWithBootstrap = Swal.mixin({
    showCancelButton: buttons.cancel ? true : false,
    showConfirmButton: buttons.confirm ? true : false,
    showDenyButton: buttons.deny ? true : false,
    customClass: {
      confirmButton: `btn btn-${buttons.confirm ? buttons.confirm.type : 'success'
        }`,
      cancelButton: `btn btn-${buttons.cancel ? buttons.cancel.type : 'info'}`
    }
  });
  return new Promise((resolve, reject) => {
    swalWithBootstrap
      .fire({
        html: '<div id="swal-alert"></div>',
        showCloseButton: propsData.showCloseButton,
        focusConfirm: propsData.focusConfirm,
        denyButtonText: buttons.deny ? buttons.deny.label : null,
        confirmButtonText: buttons.confirm ? buttons.confirm.label : null,
        cancelButtonText: buttons.cancel ? buttons.cancel.label : null,

        willOpen: () => {
          let ComponentClass = Vue.extend(Vue.component('swal-alert'));
          let instance = new ComponentClass({
            propsData: propsData
          });
          instance.$mount();
          document.getElementById('swal-alert').appendChild(instance.$el);
        }
      })
      .then((result) => {
        if (result.isConfirmed && buttons.confirm && buttons.confirm.callback)
          buttons.confirm.callback();

        if (
          result.dismiss === Swal.DismissReason.cancel &&
          buttons.cancel &&
          buttons.cancel.callback
        )
          buttons.cancel.callback();

        if (result.isDenied && buttons.deny && buttons.deny.callback)
          buttons.deny.callback();

        resolve(result);
      }).catch((err) => {
        reject(err)
      });
  })
}
