<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <div class="d-flex justify-content-between">
      <table class="table">
        <tr>
          <th>Owner</th>
          <th v-if="isCountry">Percentage (49%)</th>
          <th>Percentage</th>
          <th></th>
        </tr>

        <tr v-for="shareholder in items" :key="shareholder['@id']">
          <td>
            {{ shareholder.owner.name }}
          </td>
          <td v-if="isCountry">
            {{ getHalfPercentage(shareholder.percentage / 10000) }}%
          </td>
          <td>{{ shareholder.percentage / 10000 }}%</td>
          <td>
            <a
              v-if="!deleteIsLoading(shareholder['@id'])"
              href="#"
              @click.prevent="del(shareholder)"
              ><i class="fa fa-trash"></i
            ></a>
            <a v-else href="#"><b-spinner small /></a>
          </td>
        </tr>

        <tr v-if="leftPercentage > 0">
          <td>
            <b-form-select
              v-model="form.owner"
              id="shareholder_owner"
              class="form-control"
            >
              <b-form-select-option
                v-for="selectItem in getRemainCompanies"
                :key="selectItem['@id']"
                :value="selectItem['@id']"
                >{{ selectItem.name }}
              </b-form-select-option>
            </b-form-select>
          </td>
          <td v-if="isCountry">
            <b-form-input type="text" v-model="form.halfPercentage" readonly />
          </td>
          <td>
            <b-form-input
              type="text"
              v-model="form.percentage"
              @keypress="isNumber($event)"
              :max="maxValue"
            />
          </td>

          <td>
            <b-button v-if="isLoading" variant="primary" disabled>
              <b-spinner small></b-spinner>
            </b-button>
            <b-button
              v-else
              @click.prevent="addShareholder"
              class="float-right"
              variant="primary"
              type="button"
              :disabled="!canSubmit"
              ><i class="fas fa-plus position-icon"></i> Add
            </b-button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { SwalAlert } from '@/utils/swal';

export default {
  props: {
    parentId: {
      type: String,
      required: true
    },

    isCountry: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },

  created() {
    this.getItems(`${this.parentId}/shareholders`);
    this.companyGetSelectItems({ params: { 'order[isAmplifica]': 'desc' } });
  },

  data() {
    return {
      deleting: {},

      form: {
        owner: '',
        percentage: 100,
        halfPercentage: 49
      },

      isPercentageLoading: false
    };
  },

  computed: {
    ...mapFields('company/list', {
      companySelectItems: 'selectItems',
      companyIsLoading: 'isLoading'
    }),

    ...mapFields('shareholder/create', [
      'error',
      'created',
      'violations',
      'isLoading'
    ]),

    ...mapFields('shareholder/del', ['deleted']),
    ...mapFields('shareholder/list', ['items']),

    getRemainCompanies() {
      if (!this.companySelectItems || !this.items) {
        return [];
      }
      return this.companySelectItems.filter((c) => {
        return !this.items.find((i) => {
          return i.owner['@id'] === c['@id'];
        });
      });
    },

    sumPercentage() {
      let sum = 0;
      this.items.forEach((item) => {
        sum += parseFloat(item.percentage / 10000);
      });
      return sum;
    },

    leftPercentage() {
      return 100 - this.sumPercentage;
    },

    canSubmit() {
      return (
        this.leftPercentage + parseFloat(this.form.percentage) >= 0 &&
        this.form.owner.length > 0 &&
        !this.isPercentageLoading &&
        parseInt(this.form.percentage) <= parseInt(this.maxValue)
      );
    },

    maxValue() {
      let maxValue = 100;

      this.items.forEach((item) => {
        maxValue -= item.percentage / 10000;
      });

      return maxValue.toFixed(4);
    }
  },

  watch: {
    created: function (created) {
      if (!created) {
        return;
      }
      this.refreshData();
    },

    deleted: function (deleted) {
      if (!deleted) {
        return;
      }
      this.refreshData();
    },

    items: function (items) {
      this.form.percentage = this.leftPercentage.toFixed(4);
    },

    form: {
      deep: true,

      handler() {
        this.isPercentageLoading = true;

        setTimeout(() => {
          this.isPercentageLoading = false;
        }, 1000);
      }
    },

    'form.percentage': function (newVal) {
      if (newVal > 100) {
        this.form.halfPercentage = 49;

        return;
      }

      this.form.halfPercentage = parseFloat((newVal * 49) / 100);
    }
  },

  methods: {
    ...mapActions({
      create: 'shareholder/create/create',
      getItems: 'shareholder/list/default',
      companyGetSelectItems: 'company/list/getSelectItems',
      deleteItem: 'shareholder/del/del'
    }),

    addShareholder() {
      // We need to add multiple!
      if (this.form.percentage <= 0 || this.leftPercentage <= 0) {
        return false;
      }

      // create
      let item = { ...this.form };
      item[this.isCountry ? 'country' : 'company'] = this.parentId;

      const convertedPercentage = item.percentage * 10000;
      item.percentage = parseInt(parseFloat(convertedPercentage).toFixed(2));

      this.create(item);
    },

    del(item) {
      SwalAlert({
        title: 'Delete shareholder',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.deleteItem({ '@id': itemId });
            }
          }
        }
      });
    },

    deleteIsLoading(shareholderId) {
      return this.deleting.hasOwnProperty(shareholderId);
    },

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getItems(`${this.parentId}/shareholders`);
    },

    getHalfPercentage(val) {
      return ((val * 49) / 100).toFixed(2);
    },

    isNumber(evt) {
      evt = evt ? evt : window.event;

      const charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
