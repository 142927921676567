<template>
  <div class="general-information">
    <b-form class="py-2">
      <b-form-group label="Name" label-for="name">
        <b-form-input
          id="name"
          v-model="item.name"
          type="text"
          required="required"
          placeholder=""
        />
      </b-form-group>

      <b-form-group label="VAT Number" label-for="vatNumber">
        <b-form-input
          id="vatNumber"
          v-model="item.fiscalId"
          type="text"
          required="required"
          placeholder=""
        />
      </b-form-group>

      <b-form-group
        label="Governmental Notification"
        label-for="governmentalNotification"
      >
        <b-form-checkbox
          id="governmentalNotification"
          v-model="item.governmentalNotification"
          :checked="true"
          :unchecked-value="false"
        ></b-form-checkbox>
      </b-form-group>

      <b-form-group label="Extra fields" label-for="json">
        <table class="table">
          <tr>
            <th width="60%">Key</th>
            <th>Value</th>
            <th width="100"></th>
          </tr>

          <tr v-for="(item, index) in item.json" :key="index">
            <td>
              {{ Object.keys(item)[0] }}
            </td>
            <td>{{ Object.values(item)[0] }}</td>
            <td>
              <span @click.prevent="del(Object.keys(item)[0])"
                ><i class="fa fa-trash"></i
              ></span>
            </td>
          </tr>

          <tr>
            <td>
              <b-form-input type="text" v-model="data.key" />
            </td>
            <td>
              <b-form-input type="text" v-model="data.value" />
            </td>
            <td>
              <b-button
                @click="addItem"
                class="float-right"
                variant="primary"
                :disabled="!canAddItem"
                ><i class="fas fa-plus position-icon"></i>
              </b-button>
            </td>
          </tr>
        </table>
      </b-form-group>

      <b-form-group label="Type" label-for="type">
        <b-form-select
          v-model="item.type"
          :options="['CREATOR+', 'BENEFICIARY', 'ACCOUNTANT+', 'TEACHER']"
        ></b-form-select>
      </b-form-group>

      <b-form-group label="Origin" label-for="origin">
        <b-form-input
          id="name"
          v-model="item.origin"
          type="text"
          required="required"
          :disabled="disabled"
          placeholder="Origin"
        />
      </b-form-group>

      <b-form-group label="Is Superspreader" label-for="isSuperspreader">
        <b-form-checkbox
          id="isSuperspreader"
          switch
          v-model="item.isSuperspreader"
        >
        </b-form-checkbox>
      </b-form-group>

      <b-form-group
        label="Reason"
        label-for="reason"
        v-if="item.isSuperspreader"
      >
        <b-form-textarea
          id="textarea"
          v-model="item.reason"
          placeholder="Enter reason..."
          rows="3"
          required
        ></b-form-textarea>
      </b-form-group>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Start Date RII" label-for="startDate">
            <b-form-input id="startDate" type="date" v-model="item.riiStart">
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Extended RII deadline with"
            label-for="extendedRii"
          >
            <b-form-input
              id="extendedRii"
              type="number"
              min="0"
              v-model="item.extendedRii"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Deadline" label-for="deadline">
            <b-form-input
              id="deadline"
              type="date"
              v-model="item.deadline"
              readonly
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group>
        <CustomButton
          class="float-right"
          type="Default"
          :content="{ title: 'Next' }"
          :disabled="!canGoToNextStep"
          @click.native="next"
        />
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import CustomButton from '@/components/_ui/CustomButton';
import moment from 'moment';

export default {
  name: 'GeneralInformation',

  components: {
    CustomButton
  },

  props: {
    initialValues: {
      type: Object,
      default: () => {}
    },
    isManual: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    item() {
      return this.initialValues || this.form;
    },

    canAddItem() {
      return this.data.key && this.data.value;
    },

    canGoToNextStep() {
      return this.item.name && this.item.type && this.item.origin;
    }
  },

  data() {
    return {
      form: {
        fiscalId: null,
        governmentalNotification: null,
        name: null,
        type: null,
        origin: null,
        json: []
      },

      data: {
        key: null,
        value: null
      },

      disabled: false
    };
  },

  mounted() {
    if (this.initialValues) {
      this.item.json = JSON.parse(this.item.json);
    }

    if (this.isManual) {
      this.$set(this.item, 'origin', 'MANUAL');
      this.disabled = true;
    }

    if (this.item.riiStart) {
      this.item.riiStart = moment(this.item.riiStart).format('YYYY-MM-DD');
    }

    if (this.item.deadline) {
      this.item.deadline = moment(this.item.deadline).format('YYYY-MM-DD');
    }
  },

  methods: {
    addItem() {
      const obj = {};

      obj[this.data.key] = this.data.value;

      this.item.json.push(obj);
    },

    del(key) {
      const index = this.item.json.findIndex((o) => {
        return o.key === key;
      });

      if (index === -1) this.item.json.splice(index, 1);
    },

    next() {
      if (this.item.isSuperspreader) {
        this.item.date = moment();
      }

      this.item.governmentalNotification = this.item.governmentalNotification
        ? true
        : false;

      this.$emit('updateData', this.item);
      this.$emit('step', 2);
    }
  }
};
</script>

<style lang="scss" scoped>
.fa {
  color: $info;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.form-control[readonly] {
  background-color: #e9ecef !important;
  opacity: 1;
}
</style>
