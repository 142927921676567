import SubmissionError from '@/error/SubmissionError';
import { entityService as fetch } from '@/utils/fetch';
import * as types from './mutation_types';

export const create = ({ commit }, formData) => {
  commit(types.FINANCIAL_CREATE_SET_ERROR, '');
  commit(types.FINANCIAL_CREATE_TOGGLE_LOADING);

  return fetch('/import/invoices', {
    method: 'POST',
    body: formData
  })
    .then((response) => {
      commit(types.FINANCIAL_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {
      commit(types.FINANCIAL_CREATE_SET_CREATED, data);
    })
    .catch((e) => {
      commit(types.FINANCIAL_CREATE_TOGGLE_LOADING);

      if (e instanceof SubmissionError) {
        commit(types.FINANCIAL_CREATE_SET_VIOLATIONS, e.errors);
        // eslint-disable-next-line
        commit(types.FINANCIAL_CREATE_SET_ERROR, e.errors._error);

        return;
      }

      commit(types.FINANCIAL_CREATE_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.FINANCIAL_CREATE_RESET);
};
