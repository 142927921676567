<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <AddressForm
      :is-loading="isLoadingAddressOrCompany"
      :handle-submit="onSendForm"
      :values="initialValues"
      :errors="violations"
      @handlePrevious="handlePrevious"
      :skipLastStep="skipLastStep"
      :showPrevious="showPrevious"
    >
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </AddressForm>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import AddressForm from './Form';

const { mapFields } = createHelpers({
  getterType: 'address/create/getField',
  mutationType: 'address/create/updateField'
});

export default {
  components: {
    AddressForm
  },

  props: {
    skipLastStep: {
      type: Boolean,
      default: false
    },
    showPrevious: {
      type: Boolean,
      default: false
    },

    initialValues: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },

  computed: {
    ...mapFields(['error', 'isLoadingAddress', 'created', 'violations']),

    item() {
      return this.initialValues || {};
    },

    isLoadingAddressOrCompany() {
      return this.isLoading || this.isLoadingAddress;
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
    }
  },

  methods: {
    ...mapActions('address/create', ['create']),

    onSendForm(item) {
      item.status = 1;

      this.create(item);
    },

    handlePrevious() {
      this.$emit('handlePrevious');
    }
  }
};
</script>
