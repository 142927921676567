<template>
  <div>
    <div class="questions">
      <div
        class="questions__item"
        v-for="(item, index) in handshake.companyAnalysisQuestions"
        :key="index"
      >
        <div class="questions__item__content">
          <div>
            <span class="questions__item__numeric">{{ index + 1 }}. </span>
            <span class="questions__item__question">{{
              item.question.question
            }}</span>
          </div>

          <div>
            <span class="questions__item__numeric">R: </span>
            <span class="questions__item__answer">{{ item.answer }}</span>
          </div>
        </div>

        <div v-if="canAcceptOrReject">
          <b-form-checkbox
            :io="`check-${index}`"
            v-model="selectedQuestions"
            :name="`check-${index}`"
            switch
            :value="item['@id']"
            v-if="item.status === 'PENDING' && handshake.status === 'PENDING'"
          >
          </b-form-checkbox>

          <Status
            class="text-center"
            :type="
              handshake.status === 'REJECTED' && item.status === 'PENDING'
                ? 'REJECTED'
                : item.status
            "
            v-else
          />
        </div>
      </div>
    </div>

    <b-button-group class="float-right" v-if="canAcceptOrReject">
      <b-button
        class="ml-2"
        type="button"
        variant="primary"
        v-if="!isSubmitting"
        @click="saveQuestions('to_rejected')"
        :disabled="selectedQuestions.length === 0"
        >Reject</b-button
      >
      <b-button
        class="ml-2"
        type="button"
        variant="primary"
        v-if="!isSubmitting"
        @click="saveQuestions('to_approved')"
        :disabled="selectedQuestions.length === 0"
        >Accept</b-button
      >
      <b-button type="button" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { entityService } from '@/utils/fetch';
import handshakes from '@/mixins/handshakes';
import helpers from '@/mixins/helpers';
import Status from '@/components/_ui/status/Status';
import { mapGetters } from 'vuex';
import { SwalAlert } from '@/utils/swal';

export default {
  mixins: [helpers, handshakes],

  components: {
    Status
  },

  props: {
    handshake: {
      type: Object,
      require: true
    }
  },

  computed: {
    ...mapGetters('auth', ['isRole']),
    canAcceptOrReject() {
      // Admin can do anything
      if (this.isRole('ROLE_ADMIN') || this.isRole('ROLE_ACCOUNTANT')) {
        return true;
      }

      return false;
    }
  },

  data() {
    return {
      selectedQuestions: [],
      isSubmitting: false
    };
  },

  mounted() {
    this.canSubmit();
  },

  methods: {
    saveQuestions(statusTransition) {
      SwalAlert({
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'warning',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning'
          },
          confirm: {
            label: 'Yes, go ahead',
            type: 'success',
            callback: () => {
              this.confirmSaveQuestions(statusTransition);
            }
          }
        }
      });
    },

    async confirmSaveQuestions(statusTransition) {
      const errors = [];
      this.isSubmitting = true;
      for (const question of this.selectedQuestions) {
        try {
          await entityService(question, {
            method: 'PATCH',
            headers: new Headers({
              'Content-Type': 'application/merge-patch+json'
            }),
            body: JSON.stringify({
              id: this.iriToId(question),
              statusTransition: statusTransition
            })
          });
        } catch (e) {
          errors.push(this.iriToId(question));
        }
      }

      if (errors.length > 0) {
        SwalAlert({
          icon: 'user',
          type: 'warning',
          description: `Some questions were not accepted/rejected. ID's: ${errors.join(
            ','
          )}`,
          buttons: {
            confirm: {
              label: 'OK',
              type: 'warning'
            }
          }
        });
      } else {
        SwalAlert({
          title: 'Your questions were accepted!',
          icon: 'user',
          type: 'success',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'success'
            }
          }
        });
      }

      this.$emit('updated');

      this.isSubmitting = false;
    },

    canSubmit() {
      const isAllowed =
        this.handshake.companyAnalysisQuestions.filter(
          (item) => item.status === 'PENDING' || item.status == 'REJECTED'
        ).length === 0;

      this.$emit('isSubmitAllowed', isAllowed);
    }
  }
};
</script>

<style lang="scss">
.questions {
  &__item {
    margin: 10px 0px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__numeric {
      color: $primary;

      // font-size: 14px;
      font-weight: 800;
    }

    &__question {
      color: $primary;

      // font-size: 13px;
      font-weight: bold;
    }

    &__answer {
      // font-size: 12px;
    }

    &__actions {
      // font-size: 16px;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &--error {
        color: $danger;

        &:hover {
          opacity: 0.8;
        }
      }

      &--success {
        color: $success;
        margin: 10px 5px;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
