<template>
  <div>
    <div class="settingsModal">
      <b-tabs>
        <b-tab title="Country Settings" active>
          <CountryEditForm
            v-if="retrieved"
            :is-loading="isLoading"
            :handle-submit="onSendForm"
            :values="retrieved"
            :errors="violations"
            :initial-values="retrieved"
          />
        </b-tab>
        <b-tab title="Articles">
          <CountryArticleForm :country-id="countryId"></CountryArticleForm>
        </b-tab>
        <b-tab title-item-class="font-weight-bold" title="VAT-Rates">
          <CountryVatRateForm
            v-if="retrieved"
            :country-id="countryId"
            :vat-rates="retrieved.countryVats"
            @created="refreshData"
          />
        </b-tab>
        <b-tab title-item-class="font-weight-bold" title="Shareholders">
          <Shareholders
            v-if="retrieved"
            :parent-id="countryId"
            :is-country="true"
          />
        </b-tab>

        <b-tab title-item-class="font-weight-bold" title="Accountant">
          <CountryAccountantOrganizationType
            :country-id="countryId"
          ></CountryAccountantOrganizationType>
        </b-tab>

        <!--          <b-tab title="Consumption">-->
        <!--            <CountryConsumption/>-->
        <!--          </b-tab>-->
        <!--          <b-tab title="Course Rooms">-->
        <!--            <CountryCourseRooms/>-->
        <!--          </b-tab>-->
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { isObject } from 'lodash';
import CountryEditForm from '@/components/admin/country/Settings/Tabs/EditForm';
import CountryVatRateForm from '@/components/admin/country/Settings/Tabs/VatRateForm';
import CountryArticleForm from '@/components/admin/country/Settings/Tabs/ArticleForm';
import CountryAccountantOrganizationType from '@/components/admin/country/Settings/Tabs/Accountant/Main';

import Shareholders from '../../shareholder/Main';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { VAT_RATES_TYPE } from '../../../../config/AmplificA';

export default {
  name: 'CountrySettings',
  components: {
    CountryEditForm,
    CountryVatRateForm,
    CountryArticleForm,
    Shareholders,
    CountryAccountantOrganizationType
  },
  props: {
    country: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapFields('country/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),
    ...mapFields('country/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },
  data() {
    return {
      countryId: this.country['@id'],
      vatRateTypes: VAT_RATES_TYPE
    };
  },
  watch: {
    retrieved: function (retrieved) {
      if (!retrieved) {
        return;
      }

      if (isObject(retrieved.language)) {
        this.retrieved.language = this.retrieved.language['@id'];
      }
    },
    // eslint-disable-next-line object-shorthand,func-names
    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.$router.push({ name: 'CountryList' });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      createReset: 'country/create/reset',
      deleteItem: 'country/del/del',
      delReset: 'country/del/reset',
      retrieve: 'country/update/retrieve',
      updateReset: 'country/update/reset',
      update: 'country/update/update',
      updateRetrieved: 'country/update/updateRetrieved'
    }),

    del() {
      if (window.confirm('Are you sure you want to delete this country ?')) {
        this.deleteItem(this.retrieved);
      }
    },

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm() {
      this.update(this.retrieved);
    },

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.retrieve(this.country['@id']);
    }
  }
};
</script>

<style scoped lang="scss">
.sep {
  margin-top: -2rem;
  margin-bottom: 2rem;
  color: $gray-200;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 698px;
  margin-left: -60px;
}

.subnav {
  a {
    padding-left: 15px;
    // font-size: 12px !important;
  }
}

.country-name {
  color: $primary;
  // font-size: 22px;
  font-weight: 600;
}

ul.nav.flex-column {
  background-color: $gray-200;
  position: relative;
}

.settingsModal ul {
  margin-bottom: 25px !important;
}

li.nav-item {
  border-bottom: 1px solid $gray-400;

  a.nav-link {
    color: black;
    padding: 10px 24px;
    // font-size: 16px;

    &:hover {
      color: black;
    }
  }

  .nav-link.active {
    // font-size: 16px;
    color: $dark;
    font-weight: 800 !important;
    border-bottom: 1px solid $dark;
    padding-left: 50px !important;
    background-color: $white;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    list-style: circle !important;

    &::before {
      content: '\2022';
      color: $primary;
      margin-left: -25px;
      margin-right: 7px;
    }
  }
}

.tab-content.col {
  position: relative;
}

.form-input {
  width: 150px !important;
  height: 30px !important;
}
</style>
