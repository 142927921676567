import ProtocolList from '@/components/admin/protocol/List';
import ProtocolCreate from '@/components/admin/protocol/Create';
import ProtocolUpdate from '@/components/admin/protocol/Update';
import ProtocolShow from '@/components/admin/protocol/Show';

export default [
  { name: 'ProtocolList', path: '/protocols/', component: ProtocolList },
  {
    name: 'ProtocolCreate',
    path: '/protocols/create',
    component: ProtocolCreate
  }
];
