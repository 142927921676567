<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group
      label="Name"
      label-for="protocol_name"
      :invalid-feedback="violations.name"
      :state="!isValid('name')"
    >
      <b-form-input
        id="protocol_name"
        v-model="item.name"
        type="text"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Protocol type"
      label-for="protocol_type"
      :invalid-feedback="violations.type"
      :state="!isValid('type')"
    >
      <b-form-select
        v-model="item.type"
        id="protocol_type"
        :options="protocolTypeOptions"
        required
      >
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Root Article"
      label-for="protocol_article"
      :invalid-feedback="violations.article"
      :state="!isValid('article')"
    >
      <b-form-select v-model="item.article" id="protocol_article" required>
        <b-form-select-option value="0" v-if="articlesIsLoading" disabled>
          <b-spinner small></b-spinner>
        </b-form-select-option>
        <b-form-select-option
          v-else
          v-for="article in availableSplitArticleItems"
          :key="article['@id']"
          :value="article['@id']"
        >
          {{ article.type }} - {{ article.name }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Fee Article"
      label-for="protocol_fee"
      :invalid-feedback="violations.feeArticle"
      :state="!isValid('feeArticle')"
    >
      <b-form-select v-model="item.feeArticle" id="protocol_fee">
        <b-form-select-option value="0" v-if="articlesIsLoading" disabled>
          <b-spinner small></b-spinner>
        </b-form-select-option>
        <b-form-select-option
          v-else
          v-for="article in availableServiceArticleItems"
          :key="article['@id']"
          :value="article['@id']"
        >
          {{ article.name }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Profit Share (%)"
      label-for="profitShare"
      :invalid-feedback="violations.profitShare"
      :state="!isValid('profitShare')"
    >
      <b-form-input
        id="profitShare"
        v-model="item.profitShare"
        type="number"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Invoice Period in Days"
      label-for="invoice_period"
      :invalid-feedback="violations.invoicePeriodInDays"
      :state="!isValid('invoicePeriodInDays')"
    >
      <b-form-input
        id="invoice_period"
        v-model="item.invoicePeriodInDays"
        type="number"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Managing Partner" label-for="companies">
      <b-form-select v-if="companyIsLoading" disabled value="">
        <b-form-select-option selected value=""
          >Loading...</b-form-select-option
        >
      </b-form-select>

      <b-form-select
        v-else
        v-model="item.managingCompany"
        id="companies"
        :options="companyItems"
        text-field="name"
        value-field="@id"
        required
      >
      </b-form-select>
    </b-form-group>

    <b-form-group label="93/13 Guidelines" label-for="directive9313">
      <b-form-select v-if="companyIsLoading" disabled value="">
        <b-form-select-option selected value=""
          >Loading...</b-form-select-option
        >
      </b-form-select>

      <b-form-select
        v-else
        v-model="item.directive9313"
        id="directive9313"
        :options="directive9313Options"
        text-field="name"
        required
      >
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Auto Payment from Creator+ RII Reserve"
      label-for="payment_from_creator_rii_reserve"
      :invalid-feedback="violations.payment_from_creator_rii_reserve"
      :state="!isValid('payment_from_creator_rii_reserve')"
    >
      <b-form-checkbox
        id="payment_from_creator_rii_reserve"
        switch
        v-model="item.paymentFromCreatorRiiReserve"
        :unchecked-value="false"
        :value="true"
      />
    </b-form-group>

    <b-form-group v-if="!articlesIsLoading">
      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th width="80%">Restricted Articles</th>
            <th width="40"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(protocolRule, idx) in item.protocolRules" :key="idx">
            <td>
              <strong>{{ getArticleById(protocolRule.article).type }}</strong>
              {{ getArticleById(protocolRule.article).name }}
            </td>
            <td>
              <a @click.prevent="deleteArticle(key)"
                ><i class="fa fa-trash text-info"></i
              ></a>
            </td>
          </tr>
          <tr v-if="availableRestrictedArticleItems.length > 0">
            <td>
              <b-form-select v-model="newArticle.article">
                <b-form-select-option
                  value="0"
                  v-if="articlesIsLoading"
                  disabled
                >
                  <b-spinner small></b-spinner>
                </b-form-select-option>
                <b-form-select-option
                  v-else
                  v-for="article in availableRestrictedArticleItems"
                  :key="article['@id']"
                  :value="article['@id']"
                >
                  {{ article.type }} - {{ article.name }}
                </b-form-select-option>
              </b-form-select>
            </td>
            <td>
              <a href="#" @click.prevent="addArticle(newArticle)"
                ><i class="fa fa-plus-circle"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel
      </b-button>
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit
      </b-button>
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isString, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data: function () {
    return {
      newArticle: {
        article: ''
      },
      protocolTypeOptions: [
        'AmplificA Certified Solution',
        'Open Education',
        'RII'
      ],
      directive9313Options: ['OPT-OUT', 'OPT-IN']
    };
  },

  watch: {
    'item.profitShare': function (profitShare) {
      this.item.profitShare = parseFloat(profitShare);
    }
  },
  created() {
    if (!this.item.protocolRules) {
      this.$set(this.item, 'protocolRules', []);
    }

    // Fix
    if (this.item.feeArticle) {
      this.$set(this.item, 'feeArticle', this.item.feeArticle['@id']);
    }
    if (this.item.article) {
      this.$set(this.item, 'article', this.item.article['@id']);
    }

    if (this.item.managingCompany) {
      this.$set(this.item, 'managingCompany', this.item.managingCompany['@id']);
    }

    // Retrieve articles data
    this.getArticleSelectItems({
      params: { itemsPerPage: 100000 }
    });

    // Reset form
    this.resetArticle();
  },
  computed: {
    ...mapFields('company/list', {
      companyItems: 'items',
      companyIsLoading: 'isLoading'
    }),
    ...mapFields('article/list', {
      articlesError: 'error',
      articlesItems: 'selectItems',
      articlesIsLoading: 'isLoading'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    /**
     * Which articles are not in use already?
     */
    availableSplitArticleItems() {
      if (!this.articlesItems) {
        return [];
      }
      return this.articlesItems.filter((article) => article.type === 'SPLIT');
    },
    availableServiceArticleItems() {
      if (!this.articlesItems) {
        return [];
      }
      return [
        { '@id': null, name: '' },
        ...this.articlesItems.filter((article) => article.type === 'SERVICE')
      ];
    },
    availableRestrictedArticleItems() {
      if (!this.articlesItems) {
        return [];
      }
      if (!this.item.protocolRules) {
        return this.articlesItems || [];
      }

      let currentIds = this.item.protocolRules.map((rule) => rule.article);
      return this.articlesItems.filter(
        (article) =>
          article.isProtocolRestricted && !currentIds.includes(article['@id'])
      );
    }
  },

  methods: {
    ...mapActions({
      getArticleSelectItems: 'article/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createProtocol');
      this.$bvModal.hide('editProtocol');
    },

    getArticleById(articleId) {
      if (!this.articlesItems) {
        return null;
      }
      let _id = isString(articleId) ? articleId : articleId['@id'];
      return this.articlesItems.find((article) => article['@id'] === _id);
    },

    resetArticle() {
      this.newArticle.article = '';
    },

    addArticle(article) {
      // Add and reset
      this.item.protocolRules.push({
        article: article.article
      });
      this.resetArticle();
    },
    /**
     * Remove from item array
     * @param articleIndex
     */
    deleteArticle(articleIndex) {
      this.item.protocolRules.splice(articleIndex, 1);
    },

    onSendForm(item) {
      item.invoicePeriodInDays = parseInt(item.invoicePeriodInDays);
      this.handleSubmit(item);
    }
  }
};
</script>
