<template>
  <div>
    <!--- 
      TODO: For now this is static but the UI is done. 
      Later we need to work on this
    -->
    <b-card class="dashboard-card">
      <div class="dashboard-card__content" v-if="actions.length">
        <div
          class="dashboard-card__item mt-3"
          v-for="(action, index) in actions"
          :key="index"
        >
          <div class="dashboard-card__progress">
            <span class="dashboard-card__progress__title">
              {{ action.type
              }}<i
                class="fas fa-angle-double-right dashboard-card__progress__icon"
              ></i>
            </span>
            <b-progress
              :value="100"
              :max="100"
              animated
              :variant="getColorVariant(100)"
              class="mt-2"
              vertical
            ></b-progress>
          </div>
          <div class="dashboard-card__completed ml-3">
            <div>
              <span
                class="dashboard-card__completed__text dashboard-card__completed__text--nohover"
                >Complete IA</span
              >
              <i class="fas fa-check dashboard-card__completed__icon"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="dashboard-card__content" v-else>No Actions to Complete</div>
    </b-card>
  </div>
</template>

<script>
import { entityService } from '@/utils/fetch';

export default {
  name: 'Actions',

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      actions: []
    };
  },

  mounted() {
    this.fetchCompanyActions();
  },

  methods: {
    fetchCompanyActions() {
      this.isLoading = true;
      entityService(`${this.company['@id']}/company-actions`)
        .then((response) => response.json())
        .then((data) => {
          this.$set(this, 'actions', data['hydra:member']);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getColorVariant(value) {
      let color = 'danger';

      if (value === 100) {
        color = 'success';
      } else if (value < 50) {
        color = 'danger';
      } else {
        color = 'warning';
      }
      return color;
    }
  }
};
</script>

<style lang="scss">
.dashboard-card {
  &__title {
    // font-size: 32px;
    font-weight: 800;
  }

  &__item {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  &__progress {
    width: 50%;
    color: $gray-800;

    &__title {
      // font-size: 12px;
      margin-bottom: 5px;
    }

    &__icon {
      // font-size: 10px;
      margin-left: 3px;
    }
  }

  &__completed {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // font-size: 12px;

    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__text {
      font-family: $default-font-family;
      font-weight: bold;
      // font-size: 12px;
      color: $black;

      transition: all 0.2s linear;
      cursor: pointer;

      &:hover {
        color: $primary;
      }

      &--nohover {
        &:hover {
          color: $black;
        }
      }
    }

    &__icon {
      color: $success;
    }
  }

  &__audit {
    &__title {
      // font-size: 12px;
      color: $gray-700;
    }

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
    }

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-weight: 800;

      &__value {
        // font-size: 28px;
      }

      &__title {
        // font-size: 12px;
        color: $gray-700;
      }
    }
  }
}
</style>
