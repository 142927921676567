import CreditNotesList from '@/components/dashboard/creditNotes/List';
import CreditNotesCreate from '@/components/dashboard/creditNotes/Create';

export default [
  {
    name: 'CreditNotesList',
    path: '/credit-notes/',
    component: CreditNotesList
  },
  {
    name: 'CreditNotesCreate',
    path: '/credit-notes/create',
    component: CreditNotesCreate
  }
];
