<template>
  <b-card class="chat-container">
    <template v-if="!error">
      <h2 class="title">
        <i class="fa fa-comments"></i>
        Message Center
      </h2>
      <Chat :selectedRoom="getSelectedRoom" v-if="getSelectedRoom" />
    </template>

    <template v-else>
      <b-row>
        <b-col cols="6">
          <div class="mt-4 alert alert-danger" role="alert">
            <span class="fa fa-exclamation-triangle" aria-hidden="true">
              {{ error }}
            </span>
          </div>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Chat from './Messages/Chat.vue';
import { mapFields } from 'vuex-map-fields';
import { CHAT_FETCH_ROOMS } from '../../store/modules/chat/actions';

export default {
  components: {
    Chat
  },

  computed: {
    ...mapFields('chat', {
      error: 'roomError',
      rooms: 'rooms',
      roomIsLoading: 'roomIsLoading',
      view: 'view'
    }),
    ...mapGetters('chat', ['getSelectedRoom'])
  },
  data() {
    return {};
  },

  created() {
    this.refreshData();
  },
  methods: {
    ...mapActions('chat', [CHAT_FETCH_ROOMS]),
    refreshData() {
      localStorage.setItem('currentPage', 1);
      this[CHAT_FETCH_ROOMS]();
    },

    onCreateRoom() {}
  }
};
</script>

<style lang="scss">
.chat-container {
  .title {
    color: $primary;
    margin-bottom: 20px;
  }

  .chat-container {
    background-color: transparent;
    border: 0;
  }

  .card-header {
    background-color: transparent;
    padding: 20px;
    border-bottom: 1px solid $gray-200;
  }

  .messages-search {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    background-color: $gray-200;

    input {
      border: none;
      background-color: $gray-200;
    }
  }

  .contacts-search {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    background-color: $gray-200;

    input {
      border: none;
      background-color: $gray-200;
    }
  }

  .contacts-card {
    background-color: $gray-300;
    border: 0;
    border-radius: 30px 0 0 30px;
    height: 500px;
    display: flex;
    flex-direction: column;
  }

  .contacts-footer {
    display: flex;
    justify-content: flex-end;
  }

  .card-footer {
    border: none;
    background-color: transparent;
  }
}
</style>
