<template>
  <div>
    <b-button class="mt-3 float-right" @click="goBack" variant="primary">
      {{ content.title }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Button',

  props: {
    content: {
      type: Object,
      required: true
    }
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
