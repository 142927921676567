<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <div class="questionary__intro" v-if="!showForm">
          <img
            alt=""
            class="mr-2"
            src="@/assets/img/icons/menuicons/company@2x.png"
          />

          <h1 class="questionary__intro__title">Welcome to your 360 Test</h1>

          <div class="mt-5">
            <p class="questionary__intro__description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
              aperiam aspernatur deserunt ducimus in itaque nostrum odit. Ab
              corporis dolores eveniet, hic id ipsa necessitatibus, nulla
              numquam officiis provident quia!
            </p>
            <p class="questionary__intro__description">
              Any questions? Feel free to use the Chat function on the right...
            </p>
          </div>

          <div class="questionary__intro__button">
            <CustomButton
              @click.native="showForm = !showForm"
              class="button mt-2"
              type="Default"
              :content="{ title: 'Start' }"
            />
          </div>
        </div>

        <b-form class="questionary" @submit.prevent="onSendForm" v-else>
          <div v-if="error" class="alert alert-danger" role="alert">
            <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
              error
            }}</span>
          </div>

          <div class="questionary__header">
            <h2 class="questionary__header__title">
              {{ activeKey.toString() }}
            </h2>
            <span class="questionary__header__pagination"
              >{{ step }}/{{ groupsSize }}</span
            >
          </div>
          <div>
            <div
              class="questionary__content"
              v-for="(question, index) in items[activeKey]"
              :key="index"
            >
              <b-form-group
                class="questionary__input__label"
                :label="question.question"
                :label-for="question.question"
              >
                <hr />
                <b-form-input
                  v-if="question.type === 'Text'"
                  :id="question.question"
                  v-model="answers[question['@id']]"
                  type="text"
                  required="required"
                >
                </b-form-input>

                <b-form-input
                  class="questionary__input__label"
                  v-if="question.type === 'Percentage'"
                  :id="question.question"
                  v-model="answers[question['@id']]"
                  min="0"
                  max="100"
                  type="number"
                  required="required"
                >
                </b-form-input>

                <b-form-select
                  class="questionary__input__label"
                  v-if="question.type === 'Select'"
                  v-model="answers[question['@id']]"
                  :id="question.question"
                  :options="question.possibleValues"
                  required
                >
                </b-form-select>

                <b-form-checkbox-group
                  class="questionary__input__label"
                  v-if="question.type === 'Checkbox'"
                  :id="question.question"
                  v-model="answers[question['@id']]"
                  :options="question.possibleValues"
                  :name="question.question"
                >
                </b-form-checkbox-group>

                <b-form-radio-group
                  v-if="question.type === 'Radio'"
                  :id="question.question"
                  v-model="answers[question['@id']]"
                  :name="question.question"
                  required="required"
                >
                  <b-form-radio
                    v-for="(item, index) in question.possibleValues"
                    :key="index"
                    :value="item"
                    >{{ item }}</b-form-radio
                  >
                </b-form-radio-group>
              </b-form-group>

              <template v-if="question.subQuestions">
                <div
                  v-if="
                    (question.questionOrder === '1.1' &&
                      typeof answers[question['@id']] === 'object' &&
                      answers[question['@id']].includes(
                        'VAT Exempted Education services'
                      )) ||
                    (question.questionOrder === '1.2' &&
                      answers[question['@id']] === 'Yes') ||
                    (question.questionOrder === '1.3' &&
                      answers[question['@id']] === 'Yes') ||
                    (question.questionOrder === '1.4' &&
                      answers[question['@id']] === 'Yes') ||
                    (question.questionOrder === '1.5' &&
                      answers[question['@id']] ===
                        `Yes, there's an establishment inside the EU (ECJ - Morgan Stanley)`) ||
                    (question.questionOrder === '2.2' &&
                      answers[question['@id']] === 'Yes') ||
                    (question.questionOrder === '3.1' &&
                      answers[question['@id']] === 'Always') ||
                    answers[question['@id']] === 'Sometimes' ||
                    (question.questionOrder === '4.1' &&
                      answers[question['@id']] ===
                        'Cancellation was possible with a cancelation-charge')
                  "
                >
                  <div
                    class="questionary__content"
                    v-for="(sub, index) in question.subQuestions"
                    :key="index"
                  >
                    <b-form-group
                      class="questionary__input__label"
                      :label="sub.question"
                      :label-for="sub.question"
                    >
                      <hr />
                      <b-form-input
                        v-if="sub.type === 'Text'"
                        :id="sub.question"
                        v-model="answers[sub['@id']]"
                        type="text"
                        required="required"
                      >
                      </b-form-input>

                      <b-form-input
                        class="questionary__input__label"
                        v-if="sub.type === 'Percentage'"
                        :id="sub.question"
                        v-model="answers[sub['@id']]"
                        min="0"
                        max="100"
                        type="number"
                        required="required"
                      >
                      </b-form-input>

                      <b-form-select
                        class="questionary__input__label"
                        v-if="sub.type === 'Select'"
                        v-model="answers[sub['@id']]"
                        :id="question.sub"
                        :options="sub.possibleValues"
                        required
                      >
                      </b-form-select>

                      <b-form-checkbox-group
                        class="questionary__input__label"
                        v-if="sub.type === 'Checkbox'"
                        :id="sub.question"
                        v-model="answers[sub['@id']]"
                        :options="sub.possibleValues"
                        :name="sub.question"
                      >
                      </b-form-checkbox-group>

                      <b-form-radio-group
                        v-if="sub.type === 'Radio'"
                        :id="sub.question"
                        v-model="answers[sub['@id']]"
                        :name="sub.question"
                        required="required"
                      >
                        <b-form-radio
                          v-for="(item, index) in sub.possibleValues"
                          :key="index"
                          :value="item"
                          >{{ item }}</b-form-radio
                        >
                      </b-form-radio-group>
                    </b-form-group>
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="questionary__buttons">
            <CustomButton
              v-if="!isLastStep"
              @click.native="step++"
              class="float-right button mt-2"
              type="Default"
              :content="{ title: 'Next' }"
              :disabled="missingAnswers"
            />

            <CustomButton
              v-if="step !== 1"
              @click.native="step--"
              class="float-left button mt-2"
              type="Default"
              :content="{ title: 'Back' }"
            />

            <b-form-group class="float-right mt-4" v-if="isLastStep">
              <b-button
                type="submit"
                variant="primary"
                v-if="!isLoading && !creatingImpactAnalysis"
                :disabled="missingAnswers"
                >Submit</b-button
              >
              <b-button type="submit" variant="primary" v-else>
                <b-spinner small></b-spinner>
              </b-button>
            </b-form-group>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import CustomButton from '@/components/_ui/CustomButton';
import { createHelpers } from 'vuex-map-fields';
import { SwalAlert } from '@/utils/swal';
import helpers from '@/mixins/helpers';

const { mapFields } = createHelpers({
  getterType: 'company/answers/create/getField',
  mutationType: 'company/answers/create/updateField'
});

export default {
  components: {
    CustomButton
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getCompanyId']),
    ...mapFields(['isLoading', 'created', 'error']),

    questions() {
      return this.$store.state.questions.list.items;
    },

    items() {
      const questions = {};

      const groupedQuestions = this.questions;

      groupedQuestions.forEach((item) => {
        if (this.regExp.test(item.questionOrder)) {
          const parent = item.questionOrder.slice(0, -1);

          if (questions[parent].subQuestions) {
            questions[parent].subQuestions.push(item);
            return;
          }
          questions[parent].subQuestions = [item];

          return;
        }
        questions[item.questionOrder] = item;
      });

      let ordered = _.orderBy(groupedQuestions, 'questionOrder', ['asc']);

      ordered = ordered.filter((x) => {
        return !this.regExp.test(x.questionOrder);
      });

      return _.groupBy(ordered, 'questionGroup');
    },

    activeKey() {
      return Object.keys(this.items).filter((item, idx) => {
        return idx + 1 === this.step;
      });
    },

    groupsSize() {
      return Object.keys(this.items).length;
    },

    isLastStep() {
      return this.step === this.groupsSize;
    },

    handShakeCreated() {
      return this.$store.state.handshake.create.created;
    },

    missingAnswers() {
      let missing = false;

      this.items[this.activeKey].forEach((item) => {
        if (!this.answers[item['@id']]) {
          missing = true;
        }
      });

      return missing;
    }
  },

  data() {
    return {
      step: 1,
      answers: {},
      showForm: false,
      regExp: /[a-z]/i,
      creatingImpactAnalysis: false
    };
  },

  mounted() {
    this.getPage();
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$bvModal.hide('createImpact');
      this.$bvModal.hide('impactAnalysis');

      this.$emit('created', created);
      SwalAlert({
        title: 'Impact Analysis completed',
        icon: 'user',
        type: 'success',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  methods: {
    ...mapActions('company/answers/create', ['create']),
    ...mapActions({
      getPage: 'questions/list/default'
    }),

    async onSendForm() {
      this.creatingImpactAnalysis = true;

      const body = [];
      Object.keys(this.answers).forEach((key) => {
        body.push({
          question_id: key,
          answer: this.answers[key]
        });
      });

      this.create(body);
      this.creatingImpactAnalysis = false;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/components/questionary.scss"></style>
