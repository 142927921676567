<template>
  <b-form @submit.prevent="onSendForm()">
    <b-form-group label="Email Address" label-for="user_email">
      <UserSearchInput @selected="selectedUser" />

      <div
        role="alert"
        class="d-block invalid-feedback"
        v-if="violations.email"
      >
        {{ violations.email }}
      </div>
    </b-form-group>

    <b-form-group label="First Name" label-for="user_firstName" readonly>
      <b-form-input
        readonly
        id="user_firstName"
        v-model="user.firstName"
        type="text"
        required
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Last Name" label-for="user_lastName" readonly>
      <b-form-input
        readonly
        id="user_lastName"
        v-model="user.lastName"
        type="text"
        required
      >
      </b-form-input>
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        type="submit"
        variant="primary"
        v-if="!isLoading"
        :disabled="!canCreate"
        >Message Center</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import UserSearchInput from '../../_ui/users/SearchInput.vue';

export default {
  components: {
    UserSearchInput
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formMetadata: {
        userSelected: null
      },
      user: {}
    };
  },

  computed: {
    canCreate() {
      return !!this.user;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    onSendForm() {
      // Only registered users
      if (!this.user) {
        return;
      }

      // All good
      this.handleSubmit(this.user);
    },

    closeModal() {
      this.$bvModal.hide('createRoom');
    },

    /**
     * Handle output of typeahead
     * @param userSelected
     */
    selectedUser(userSelected) {
      if (userSelected.fetched) {
        this.$set(this.user, '@id', userSelected.user['@id']);
        this.$set(this.user, 'email', userSelected.user.email);
        this.$set(this.user, 'firstName', userSelected.user.firstName);
        this.$set(this.user, 'lastName', userSelected.user.lastName);
      } else {
        this.user = {};
      }
    }
  }
};
</script>
