<template>
  <div>
    <CustomTable
      :fields="fields"
      list="company"
      @changePage="changePage"
      showSearchInput
      @changeFilter="changeFilter"
      @handleSortChange="handleSortChange"
    >
      <template v-slot:isAmplifica="data">
        <Status class="text-center" :value="data.item.isAmplifica" />
      </template>
      <template v-slot:isAccountant="data">
        <Status class="text-center" :value="data.item.isAccountant" />
      </template>
      <template v-slot:isProvider="data">
        <Status class="text-center" :value="data.item.isProvider" />
      </template>
      <template v-slot:isInstructor="data">
        <Status class="text-center" :value="data.item.isInstructor" />
      </template>

      <template v-slot:semaphoreState="data">
        <StatusChecker
          @click.native="open360Light(data.item)"
          :statusChecker="data.item.semaphoreState"
          class="company__semaphore"
        />
      </template>

      <template v-slot:actions="data">
        <a
          href="#"
          v-b-tooltip.hover
          title="Edit"
          @click.prevent="edit(data.item)"
          class="mr-2"
          ><i class="fa fa-edit text-info"></i
        ></a>
        <a
          href="#"
          v-b-tooltip.hover
          title="Shareholders"
          @click.prevent="editShareholders(data.item)"
          class="mr-2"
          ><i class="fa fa-building text-info"></i
        ></a>

        <a
          href="#"
          v-b-tooltip.hover
          title="Employees"
          @click.prevent="showEmployees(data.item)"
          class="mr-2"
          ><i class="fa fa-users text-info"></i
        ></a>

        <a
          href="#"
          v-b-tooltip.hover
          title="Aprova Report"
          @click.prevent="openAprova(data.item)"
          ><i class="fa fa-chart-line text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createCompany')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Company
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="createCompany" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Company</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createCompany')"
        ></i>
      </template>

      <CompanyCreate
        @created="onCompanyCreate"
        :availableCompanyTypes="availableCompanyTypes"
      ></CompanyCreate>
    </b-modal>

    <b-modal
      @hidden="resetRetrieved"
      id="editCompany"
      title="Edit Company"
      hide-footer
      size="xl"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >Edit Company
            <span class="custom-modal__popup__title--blue">{{
              editItem.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editCompany')"
        ></i>
      </template>
      <CompanyUpdate
        :company-id="editItem['@id']"
        @updated="resetRetrieved"
      ></CompanyUpdate>
    </b-modal>

    <b-modal id="editShareholders" title="Edit Shareholders" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >Shareholders
            <span class="custom-modal__popup__title--blue">{{
              company.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editShareholders')"
        ></i>
      </template>

      <Shareholders
        v-if="editItem"
        :parent-id="editItem['@id']"
        :is-country="false"
      />
    </b-modal>

    <b-modal id="aprovaReport" size="xl" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >AprovA Report
            <span class="custom-modal__popup__title--blue">{{
              company.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('aprovaReport')"
        ></i>
      </template>

      <AprovaReport :companyId="company.id" />
    </b-modal>

    <b-modal id="showEmployees" size="xl" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Employees</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('showEmployees')"
        ></i>
      </template>

      <Employees :company="editItem" />
    </b-modal>

    <b-modal id="360Light" size="xl" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >360 Light status
            <span class="custom-modal__popup__title--blue">{{
              company.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('360Light')"
        ></i>
      </template>

      <LightList :companyId="company.id" />
    </b-modal>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import CompanyUpdate from '@/components/dashboard/company/Update';
import Status from '@/components/_ui/tables/Status';
import CompanyCreate from './Create.vue';
import Shareholders from '@/components/admin/shareholder/Main';
import AprovaReport from '@/components/aprova/Form';
import Employees from '@/components/dashboard/company/employees/List';
import { SwalAlert } from '@/utils/swal';
import LightList from '@/components/dashboard/company/Light/List';
import StatusChecker from '@/components/_ui/svg/StatusChecker';

export default {
  components: {
    CompanyUpdate,
    Status,
    CompanyCreate,
    Shareholders,
    CustomTable,
    AprovaReport,
    Employees,
    LightList,
    StatusChecker
  },

  data() {
    return {
      company: {
        name: null,
        id: null
      },
      editItem: '',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'fiscal_id', label: 'Fiscal', sortable: true },
        { key: 'isAccountant', label: 'Accountant', sortable: true },
        { key: 'isProvider', label: 'Creator', sortable: true },
        { key: 'isInstructor', label: 'Instructor', sortable: true },
        { key: 'isAmplifica', label: 'Amplifica', sortable: true },
        {
          key: 'semaphoreState',
          label: '360 Light',
          sortable: true
        },
        { key: 'actions', label: 'Actions' }
      ],
      availableCompanyTypes: [
        'FISCAL_UNITY',
        'GOVERNMENTAL',
        'PERSON',
        'OTHER'
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapFields('company/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/companies?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'company/list/default',
      reset: 'company/update/reset'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    onCompanyCreate(created) {
      this.$bvModal.hide('createCompany');
      SwalAlert({
        title: 'Company created',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createCompany');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {
              return;
            }
          }
        }
      });

      this.refreshData();
    },

    edit(item) {
      this.editItem = item;
      this.$bvModal.show('editCompany');
    },

    editShareholders(item) {
      this.company.name = item.name;

      this.editItem = item;
      this.$bvModal.show('editShareholders');
    },

    openAprova(company) {
      this.company.name = company.name;
      this.company.id = company['@id'];

      this.$bvModal.show('aprovaReport');
    },

    open360Light(company) {
      this.company.name = company.name;
      this.company.id = company['@id'];

      this.$bvModal.show('360Light');
    },

    showEmployees(item) {
      this.editItem = item;
      this.$bvModal.show('showEmployees');
    },

    resetRetrieved(refresh = false) {
      this.reset();

      this.refreshData();
    }
  }
};
</script>

<style lang="scss" scoped>
.company__semaphore {
  height: 50px;
  width: 50px;

  cursor: pointer;
}
</style>
