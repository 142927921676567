<template>
  <div>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/company@2x.png"
      />
      <h3 class="font-weight-bold mt-2">Companies</h3>
    </div>

    <CustomTable
      :fields="fields"
      list="company"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Show"
          v-b-tooltip.hover
        >
          <i class="fas fa-eye text-info"></i>
        </a>
      </template>
    </CustomTable>

    <b-modal id="updateCompany" title="Edit Company" hide-footer size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >Edit Company
            <span class="custom-modal__popup__title--blue">{{
              editItem.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateCompany')"
        ></i>
      </template>
      <CompanyUpdate :company-id="editItem['@id']"></CompanyUpdate>
    </b-modal>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import CustomTable from '@/components/_ui/tables/CustomTable';
import UpdateProspect from '@/components/dashboard/prospect/Update';
import CompanyUpdate from '@/components/dashboard/company/Update';
import { mapActions } from 'vuex';

export default {
  name: 'GovermentCompanies',

  components: {
    CustomTable,
    CompanyUpdate
  },

  mixins: [helpers],

  data() {
    return {
      editItem: '',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      itemsPerPage: 25,
      page: 1,
      filter: null
    };
  },
  computed: {
    request() {
      let request = '/companies?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'company/list/default',
      reset: 'company/update/reset'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editItem = item;
      this.$bvModal.show('updateCompany');
    }
  }
};
</script>
