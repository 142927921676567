import SettingsList from '@/components/admin/settings/List';
import SettingsCreate from '@/components/admin/settings/Create';
import SettingsUpdate from '@/components/admin/settings/Update';
import SettingsShow from '@/components/admin/settings/Show';

export default [
  { name: 'SettingsList', path: '/settings/', component: SettingsList },
  {
    name: 'SettingsCreate',
    path: '/settings/create',
    component: SettingsCreate
  },
  {
    name: 'SettingsUpdate',
    path: '/settings/edit/:id',
    component: SettingsUpdate
  },
  { name: 'SettingsShow', path: '/settings/show/:id', component: SettingsShow }
];
