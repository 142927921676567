export const COUNTRYACCOUNTANTORGANIZATION_CREATE_RESET =
  'COUNTRYACCOUNTANTORGANIZATION_CREATE_RESET';
export const COUNTRYACCOUNTANTORGANIZATION_CREATE_SET_CREATED =
  'COUNTRYACCOUNTANTORGANIZATION_CREATE_SET_CREATED';
export const COUNTRYACCOUNTANTORGANIZATION_CREATE_SET_ERROR =
  'COUNTRYACCOUNTANTORGANIZATION_CREATE_SET_ERROR';
export const COUNTRYACCOUNTANTORGANIZATION_CREATE_SET_VIOLATIONS =
  'COUNTRYACCOUNTANTORGANIZATION_CREATE_SET_VIOLATIONS';
export const COUNTRYACCOUNTANTORGANIZATION_CREATE_TOGGLE_LOADING =
  'COUNTRYACCOUNTANTORGANIZATION_CREATE_TOGGLE_LOADING';
