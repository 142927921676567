<template>
  <div>
    <Form @created="$emit('created')" />
  </div>
</template>

<script>
import Form from './Form';
import { entityService } from '../../../../../utils/fetch';

export default {
  components: {
    Form
  },

  methods: {
    onSendForm(items) {}
  }
};
</script>
