export const getters = {
  getSelectedRoomId: (state) => state.selectedRoom,
  getSelectedRoom: (state) => {
    if (!state.selectedRoom) {
      return null;
    }
    return state.rooms[state.selectedRoom];
  },

  getRooms: (state) => state.rooms,
  getRoom: (state) => (roomId) => {
    return state.rooms[roomId] ? state.rooms[roomId] : null;
  },

  getRoomByContext: (state) => (context) => {
    if (!state.rooms) {
      return null;
    }
    let roomid = '/rooms/' + btoa(context);
    return state.rooms[roomid] ? state.rooms[roomid] : null;
  },

  getMessages: (state) => (roomId) => {
    return state.messages.filter((m) => m.room === roomId);
  },

  getUnreadRooms: (state) => {
    let unreadRooms = 0;
    if (state.rooms) {
      for (let c in state.rooms) {
        if (state.rooms[c].newMessages) {
          unreadRooms++;
        }
      }
    }
    return unreadRooms;
  }
};
