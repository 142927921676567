<template>
  <div>
    <div v-if="error" class="alert alert-danger mt-3" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/company@2x.png"
      />
      <h3 class="font-weight-bold mt-2">Branches</h3>
    </div>

    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="branches"
      @changePage="changePage"
    >
      <template v-slot:semaphoreState="data">
        <StatusChecker
          :statusChecker="data.item.semaphoreState"
          class="branches__semaphore"
        />
      </template>
      <template v-slot:actions="data">
        <span v-if="!deleteIsLoading(data.item['@id'])">
          <a
            class="mr-2"
            href="#"
            @click.prevent="edit(data.item)"
            v-b-tooltip.hover
            title="Edit"
          >
            <i class="fas fa-edit text-info"></i>
          </a>
          <a
            class="ml-2"
            href="#"
            @click="del(data.item)"
            v-b-tooltip.hover
            title="Delete"
            ><i class="fa fa-trash text-info"></i
          ></a>
        </span>
        <b-spinner v-else small></b-spinner>
      </template>

      <template #buttons>
        <b-button @click="$bvModal.show('createCompany')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Branch
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="createCompany" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Branch</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createCompany')"
        ></i>
      </template>

      <BranchCreate :company="company" @created="refreshData"></BranchCreate>
    </b-modal>

    <b-modal id="editCompany" title="Edit Branch" hide-footer size="lg">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Branch</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editCompany')"
        ></i>
      </template>

      <BranchUpdate
        :company-id="editBranch"
        @updated="refreshData"
        :is-creating-branch="true"
        :skip-company-roles="true"
      ></BranchUpdate>
    </b-modal>

    <!-- <b-modal id="editBranch" title="Edit Branch" hide-footer>
      <CompanyUpdate
        :company-id="editId"
        @updated="refreshData"
      ></CompanyUpdate>
    </b-modal> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { SwalAlert } from '@/utils/swal';

import CustomTable from '@/components/_ui/tables/CustomTable';
import BranchCreate from './Create';
import BranchUpdate from '@/components/dashboard/company/Update';

import { entityService } from '@/utils/fetch';
import StatusChecker from '@/components/_ui/svg/StatusChecker';

export default {
  components: {
    CustomTable,
    BranchCreate,
    BranchUpdate,
    StatusChecker
  },

  props: {
    company: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      error: null,
      // Delete/edit
      editBranch: null,
      deleting: {},
      branch: '',
      fields: [
        {
          key: 'company.name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'company.fiscal_id',
          label: 'Fiscal',
          sortable: true
        },
        {
          key: 'percentage',
          label: 'Percentage',
          sortable: true,
          formatter: (value, key, item) => {
            return `${value || 0}%`;
          }
        },
        {
          key: 'startDate',
          label: 'Start Date',
          sortable: true
        },
        {
          key: 'endDate',
          label: 'End Date',
          sortable: true
        },
        {
          key: 'semaphoreState',
          label: '360 Light',
          sortable: true
        },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser']),

    request() {
      let request = `${this.company['@id']}/branches?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status=ENABLED`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      return request;
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }
      this.refreshData();
    }
  },

  created() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'branches/list/default',
      deleteItem: 'branches/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    deleteConfirmed(item) {
      item.parent = null;
      item.branchOf = null;

      entityService(item['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(item)
      })
        .then((response) => response.json())
        .then((data) => {
          SwalAlert({
            title: 'Branch deleted',
            type: 'success',
            icon: 'user'
          }).then(() => {
            this.refreshData();
          });
        })
        .catch((e) => {
          this.error = 'Something wrong happened';
        });
    },
    del(item) {
      SwalAlert({
        title: 'Delete Branch',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.deleteConfirmed(item);
            }
          }
        }
      });
    },

    deleteIsLoading(branchId) {
      return this.deleting.hasOwnProperty(branchId);
    },

    edit(item) {
      this.editBranch = item['@id'];
      this.$bvModal.show('editCompany');
    }
  }
};
</script>

<style lang="scss" scoped>
.branches__semaphore {
  height: 50px;
  width: 50px;
}
</style>
