<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <template v-if="aprovaReport.length > 0">
      <div class="aprova-report__tabs">
        <TabGroup
          :tabs="tabs"
          :aprovaReport="aprovaReport[0]"
          :companyId="companyId"
        >
          <div class="aprova-report__tabs__absolute mt-3">
            <span class="aprova-report__tabs__title">
              <i class="fas fa-file-download mr-2"></i> AprovA report</span
            >
          </div>
        </TabGroup>
      </div>
    </template>

    <template v-else>
      <div class="text-center">
        <span class="aprova-report__form__subtitle"
          >No aprova report data available</span
        >
      </div>
    </template>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  name: 'Aprova',

  components: {
    TabGroup
  },

  props: {
    companyId: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapFields('company/aprovaReport/list', {
      isLoading: 'isLoading',
      aprovaReport: 'items'
    })
  },

  data() {
    return {
      tabs: [
        {
          active: 'General',
          text: 'General',
          visible: true
        },
        {
          active: 'VatGroup',
          text: 'VAT Group',
          visible: true
        },
        {
          active: 'VatStatus',
          text: 'VAT Status',
          visible: true
        },
        {
          active: 'AccountantVerification',
          text: 'Accountant verification',
          visible: true
        },
        {
          active: 'Light360',
          text: '360 Light',
          visible: true
        },
        {
          active: 'RII',
          text: 'Restitutio in Integrum',
          visible: true
        },
        {
          active: 'VatGroupCalculations',
          text: 'VAT Group Calculations',
          visible: true
        }
      ]
    };
  },

  mounted() {
    this.getAprovaReport(`${this.companyId}/aprova-reports`);
  },

  methods: {
    ...mapActions({
      getAprovaReport: 'company/aprovaReport/list/default'
    })
  }
};
</script>
