<template>
  <div>
    <div v-if="isLoading">
      <b-spinner></b-spinner>
    </div>

    <b-form>
      <div v-if="organizationError" class="alert alert-danger" role="alert">
        <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
          organizationError
        }}</span>
      </div>

      <table class="table table-hover">
        <thead>
          <tr>
            <th>Professional Organisation(s)</th>
            <th>Types</th>
            <th width="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="countryAccountantOrganization in items"
            :key="countryAccountantOrganization['@id']"
          >
            <td>{{ countryAccountantOrganization.name }}</td>
            <td>
              <div
                v-for="(
                  itm, index
                ) in countryAccountantOrganization.countryAccountantType"
                :key="index"
              >
                <span>{{ itm.type }}</span>
              </div>
            </td>

            <td>
              <a
                v-if="!deleteIsLoading(countryAccountantOrganization['@id'])"
                href="#"
                @click.prevent="del(countryAccountantOrganization)"
                ><i class="fa fa-trash"></i
              ></a>
              <a v-else href="#"><b-spinner small /></a>
            </td>
          </tr>
          <tr>
            <td>
              <b-input-group>
                <b-form-input
                  v-model="newOrganization.name"
                  placeholder="Insert Name..."
                  type="text"
                ></b-form-input>
              </b-input-group>
            </td>
            <td>
              <b-input-group>
                <v-select
                  class="organization__select"
                  placeholder="Types"
                  :options="typeList"
                  v-model="newOrganization.countryAccountantType"
                  label="type"
                  :reduce="(c) => c['@id']"
                  multiple
                  required
                >
                </v-select>
              </b-input-group>
            </td>
            <td>
              <b-button v-if="organizationIsLoading" variant="primary" disabled>
                <b-spinner small></b-spinner>
              </b-button>
              <b-button
                v-else
                @click.prevent="addCountryOrganization"
                class="float-right"
                variant="primary"
                type="button"
                :disabled="canSubmit"
                ><i class="fas fa-plus position-icon"></i>
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form>
  </div>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import { SwalAlert } from '@/utils/swal';

export default {
  props: {
    countryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newOrganization: {
        country: this.countryId,
        name: null,
        countryAccountantType: []
      },
      deleting: {}
    };
  },

  created() {
    this.getPage(`${this.countryId}/country-accountant-organizations`);
  },

  computed: {
    ...mapFields('countryaccountantorganization/del', ['deleted']),

    ...mapFields('countryaccountantorganization/list', {
      error: 'error',
      isLoading: 'isLoading',
      items: 'items'
    }),

    ...mapFields('countryaccountantorganization/create', {
      organizationError: 'error',
      organizationIsLoading: 'isLoading',
      organizationCreated: 'created',
      organizationViolations: 'violations'
    }),

    ...mapFields('countryaccountanttype/list', {
      typeList: 'items'
    }),

    canSubmit() {
      return !this.newOrganization.name;
    }
  },

  watch: {
    organizationCreated: function (created) {
      if (!created) {
        return;
      }

      this.resetState();
      this.refreshData();
    },

    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.refreshData();
    }
  },

  methods: {
    dayjs,

    ...mapActions({
      getPage: 'countryaccountantorganization/list/default',
      organizationCreate: 'countryaccountantorganization/create/create',
      organizationReset: 'countryaccountantorganization/create/reset',
      organizationDelete: 'countryaccountantorganization/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(`${this.countryId}/country-accountant-organizations`);
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    addCountryOrganization() {
      this.organizationCreate(this.newOrganization);
    },

    resetState() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },

    del(item) {
      SwalAlert({
        title: 'Delete Organization',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.organizationDelete({ '@id': itemId });
            }
          }
        }
      });
    },

    deleteIsLoading(countryOrganizationId) {
      return this.deleting.hasOwnProperty(countryOrganizationId);
    }
  },

  beforeDestroy() {
    this.organizationReset();
  }
};
</script>

<style lang="scss">
.v-select {
  width: 100%;
}

.organization__select {
  .vs__selected {
    background-color: $primary !important;
    border: 1.5px solid $primary !important;
    border-radius: 10px !important;
    color: $white !important;
    // font-size: 14px !important;
  }

  .vs__deselect {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: red;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
