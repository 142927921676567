<template>
  <div class="processing">
    <div class="processing__header">
      <div class="processing__list">
        <span class="processing__list__item">Process type</span>
        <span class="processing__list__item">Progress</span>
      </div>
    </div>

    <div class="processing__content">
      <div class="processing__content__item">
        <div class="processing__list">
          <div class="processing__list__item">
            <span class="processing__content__item__title"
              >Uploading
              <span
                class="processing__content__item__title processing__content__item__title--strong"
                >file1.csv</span
              >
            </span>
          </div>

          <div class="processing__list__item">
            <b-progress
              class="processing__content__item__progress"
              :max="100"
              :value="100"
              :variant="getColorVariant(100)"
              animated
              vertical
            ></b-progress>
          </div>
        </div>

        <i
          class="fa fa-check processing__content__icon processing__content__icon--success"
        ></i>
      </div>

      <div class="processing__content__item">
        <div class="processing__list">
          <div class="processing__list__item">
            <span class="processing__content__item__title"
              >Uploading
              <span
                class="processing__content__item__title processing__content__item__title--strong"
                >file1.csv</span
              >
            </span>
          </div>

          <div class="processing__list__item">
            <b-progress
              class="processing__content__item__progress"
              :max="100"
              :value="100"
              :variant="getColorVariant(100)"
              animated
              vertical
            ></b-progress>
          </div>
        </div>

        <i
          class="fa fa-check processing__content__icon processing__content__icon--success"
        ></i>
      </div>

      <div class="processing__content__item">
        <div class="processing__list">
          <div class="processing__list__item">
            <span class="processing__content__item__title"
              >Uploading
              <span
                class="processing__content__item__title processing__content__item__title--strong"
                >file1.csv</span
              >
            </span>
          </div>

          <div class="processing__list__item">
            <b-progress
              class="processing__content__item__progress"
              :max="100"
              :value="65"
              :variant="getColorVariant(65)"
              animated
              vertical
            ></b-progress>
          </div>
        </div>

        <i
          class="fa fa-exclamation processing__content__icon processing__content__icon--warning"
        ></i>
      </div>

      <div class="processing__content__item">
        <div class="processing__list">
          <div class="processing__list__item">
            <span class="processing__content__item__title"
              >Uploading
              <span
                class="processing__content__item__title processing__content__item__title--strong"
                >file1.csv</span
              >
            </span>
          </div>

          <div class="processing__list__item">
            <b-progress
              class="processing__content__item__progress"
              :max="100"
              :value="40"
              :variant="getColorVariant(40)"
              animated
              vertical
            ></b-progress>
          </div>
        </div>

        <i
          class="fa fa-times processing__content__icon processing__content__icon--danger"
        ></i>
      </div>
    </div>

    <div class="processing__footer">
      <b-button
        @click="$bvModal.hide('progressList')"
        type="button"
        variant="secondary"
        >Close</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessingList',

  data() {
    return {
      maxProgressValue: 100
    };
  },

  methods: {
    getColorVariant(value) {
      let color = 'danger';

      if (value === 100) {
        color = 'success';
      } else if (value < 50) {
        color = 'danger';
      } else {
        color = 'warning';
      }
      return color;
    }
  }
};
</script>

<style lang="scss">
.processing {
  margin-top: 50px;

  &__header {
    width: 90%;
  }

  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__item {
      width: 50%;
      font-weight: bold;
    }
  }

  &__content {
    margin-top: 10px;

    &__item {
      width: 100%;
      display: flex;
      align-items: center;

      .processing__list {
        background-color: $gray-300;
        border-radius: 5px;
        padding: 10px;
        width: 100%;
      }

      &:not(:first-child) {
        margin-top: 10px;
      }

      &__title {
        &--strong {
          font-weight: bold;
        }
      }
    }

    &__icon {
      margin-left: 20px;
      // font-size: 14px;
      width: 15px;
      text-align: center;

      &--success {
        color: $success;
      }

      &--danger {
        color: $danger;
      }

      &--warning {
        color: $warning;
      }
    }
  }

  &__footer {
    margin-top: 50px;
    text-align: center;
  }
}
</style>
