// -------------- Proposal --------------
import ProposalViews from './proposal';
import ScheduleViews from './schedule';
import ExecuteViews from './execute';
import WaitingViews from './waiting-approval';
import CancelledViews from './cancelled';
import CompletedViews from './completed';

export default [
  ...ProposalViews,
  ...ScheduleViews,
  ...ExecuteViews,
  ...WaitingViews,
  ...CancelledViews,
  ...CompletedViews
];
