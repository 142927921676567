<template>
  <b-container fluid>
    <b-nav pills class="mainMenu mt-4">
      <b-nav-item class="" active-class="active" to="/my/profile/"
        >My Profile
      </b-nav-item>
      <b-nav-item active-class="active" to="/my/company" v-if="hasCompany"
        >My Company</b-nav-item
      >
    </b-nav>

    <b-col cols="12">
      <router-view></router-view>
    </b-col>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('auth', ['hasCompany'])
  }
};
</script>
<style scoped lang="scss"></style>
