export const RESET = 'COUNTRYACCOUNTANTORGANIZATION_UPDATE_RESET';
export const SET_ERROR = 'COUNTRYACCOUNTANTORGANIZATION_UPDATE_SET_ERROR';
export const SET_RETRIEVED =
  'COUNTRYACCOUNTANTORGANIZATION_UPDATE_SET_RETRIEVED';
export const SET_UPDATED = 'COUNTRYACCOUNTANTORGANIZATION_UPDATE_SET_UPDATED';
export const SET_VIOLATIONS =
  'COUNTRYACCOUNTANTORGANIZATION_UPDATE_SET_VIOLATIONS';
export const TOGGLE_LOADING =
  'COUNTRYACCOUNTANTORGANIZATION_UPDATE_TOGGLE_LOADING';
export const UPDATE_RETRIEVED =
  'COUNTRYACCOUNTANTORGANIZATION_UPDATE_UPDATE_RETRIEVED';
