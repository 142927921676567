<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="showError">
      File is required. <strong>Please upload</strong>
    </div>

    <b-form class="py-2" @submit.prevent="submit">
      <template v-if="step === 1">
        <div>
          <b-form-group label="What to do with duplicated?" label-for="type">
            <b-form-select
              v-model="type"
              :options="['SKIP', 'OVERRIDE', 'DELETE']"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Upload File" label-for="doc">
            <vue-dropzone
              ref="myVueDropzone"
              id="doc"
              :options="dropzoneOptions"
              @vdropzone-file-added="uploadedFile"
            ></vue-dropzone>
          </b-form-group>
        </div>
      </template>

      <template v-if="step === 2">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="6">
              <span class="card__title">Your fields:</span>

              <draggable
                tag="ul"
                class="card"
                :list="csvFields"
                :animation="200"
                ghost-class="card__moving-card"
              >
                <li
                  v-for="(field, index) in csvFields"
                  :key="field.id"
                  class="card__item"
                  :class="{
                    'card__item--disabled':
                      index + 1 > templateFields.length || field === ''
                  }"
                >
                  <i class="fas fa-list"></i>
                  {{ field }}
                </li>
              </draggable>
            </b-col>
            <b-col cols="12" md="6">
              <span class="card__title">Required fields:</span>
              <ul class="card">
                <li
                  v-for="field in templateFields"
                  :key="field.id"
                  class="card__item card__item--nomove card__item--noafter"
                >
                  <i class="fas fa-list"></i> {{ field.value }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <div class="buttons__import">
        <CustomButton
          v-if="step !== 2"
          class="float-right"
          type="Default"
          :content="{ title: 'Next' }"
          @click.native="nextStep"
        />

        <CustomButton
          v-if="step !== 1"
          class="float-left"
          type="Default"
          :content="{ title: 'Prev' }"
          @click.native="prevStep"
        />

        <b-form-group class="float-right" v-if="step === 2">
          <b-button type="submit" variant="primary" v-if="!isLoading"
            >Submit</b-button
          >
          <b-button type="submit" variant="primary" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import CustomButton from '@/components/_ui/CustomButton';
import Draggable from 'vuedraggable';
import { SwalAlert } from '@/utils/swal';
import helpers from '@/mixins/helpers';
import { entityService } from '../../../../../utils/fetch';

export default {
  components: {
    vueDropzone: vue2Dropzone,
    CustomButton,
    Draggable
  },

  mixins: [helpers],

  data() {
    return {
      step: 1,
      dropzoneOptions: {
        url: 'localhost',
        autoProcessQueue: false,
        dictDefaultMessage: "<i class='fa fa-plus'></i><p>UPLOAD DOCUMENT</p>",
        maxFiles: 1
      },
      file: null,

      templateFields: [
        {
          key: 'CompanyName',
          value: 'Company Name'
        },
        {
          key: 'CreatorCompany',
          value: 'Creator+ CompanyID'
        },
        {
          key: 'VatNumber',
          value: 'Vat Number'
        },
        {
          key: 'Oin',
          value: 'OIN'
        },
        {
          key: 'Nin',
          value: 'NIN'
        },
        {
          key: 'AddressState',
          value: 'Adress State'
        },
        {
          key: 'AddressCity',
          value: 'Address City'
        },
        {
          key: 'AddressStreet',
          value: 'Address Street'
        },
        {
          key: 'AddressNumber',
          value: 'Address Number'
        },
        {
          key: 'AddressZip',
          value: 'Address Zip'
        },
        {
          key: 'Country',
          value: 'Country'
        },
        {
          key: 'Email',
          value: 'E-mail'
        },
        {
          key: 'Phone',
          value: 'Phone'
        },
        {
          key: 'Iban',
          value: 'Iban'
        },
        {
          key: 'IbanHolder',
          value: 'Iban Holder'
        },
        {
          key: 'Sector',
          value: 'Sector'
        },
        {
          key: 'ContactFirstName',
          value: 'Contact First Name'
        },
        {
          key: 'ContactLastName',
          value: 'Contact Last Name'
        },
        {
          key: 'ContactEmail',
          value: 'Contact E-mail'
        },
        {
          key: 'ChamberOfCommerce',
          value: 'Chamber of Commerce'
        }
      ],

      csvFields: [],

      mappedFields: [],

      showError: false,

      isLoading: false,

      type: 'SKIP'
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('importCustomerData');
    },

    uploadedFile(file) {
      this.file = file;
      this.showError = false;

      this.nextStep();
    },

    nextStep() {
      if (this.step === 1) {
        if (!this.file) {
          this.showError = true;

          return;
        }
      }

      this.step++;

      this.transformCSV();
    },

    prevStep() {
      this.step--;
    },

    async transformCSV() {
      const result = await this.asyncFileReader(this.file);
      const csvFields = this.csvToArray(result);

      const missingFields = this.templateFields.length - csvFields.length;

      for (let i = 0; i < missingFields; i++) {
        csvFields.push('');
      }

      this.csvFields = csvFields;
    },

    submit() {
      const fields = this.mapFinalFields();

      let formData = new FormData();
      formData.append('type', JSON.stringify(this.type));
      formData.append('fields', JSON.stringify(fields));
      formData.append('file', this.file);
      formData.append('separator', this.delimiter);

      this.isLoading = true;

      entityService('/import/customers', {
        method: 'POST',
        body: formData
      })
        .then((response) => {
          this.$bvModal.hide('importCustomerData');
          this.$emit('created');

          SwalAlert({
            title: 'Customer import completed',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'Form another',
                type: 'warning',
                callback: () => {
                  this.$bvModal.show('importCustomerData');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {
                  return;
                }
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error!',
            description: e,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {
                  return;
                }
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    mapFinalFields() {
      const fields = {};

      this.templateFields.forEach((key, index) => {
        fields[key.key] = this.csvFields[index];
      });

      return fields;
    }
  }
};
</script>

<style lang="scss">
.card {
  width: 100%;
  padding: 0;
  border: 0 !important;

  &__title {
    font-style: italic;
    color: $gray-500;
    // font-size: 12px;
  }

  &__item {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    padding: 10px 20px;
    transition: 0.2s background-color ease-in;

    text-transform: uppercase;
    border-radius: 8px;
    font-weight: bold;

    cursor: pointer;

    &:is(:first-child) {
      margin-top: 15px;
    }

    &:hover {
      background-color: rgb(30 47 71 / 71%);
    }

    &:after {
      content: '\2192';
      position: absolute;
      top: 10px;
      right: -27px;
      width: 20px;
      height: 20px;
      color: $primary;
    }

    &--nomove {
      cursor: default;
      background-color: $secondary !important;
    }

    &--disabled {
      opacity: 0.6;
      height: 44px;

      &:hover {
        background-color: $primary;
      }

      &:after {
        display: none;
      }
    }

    &--noafter {
      &:after {
        display: none;
      }
    }
  }

  &__moving-card {
    opacity: 0.8;
    background-color: $gray-100;
    border: 2px solid $secondary;
  }
}
</style>
