<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/company@2x.png"
      />
      <h3 class="font-weight-bold mt-2">
        General Company Settings
        <span class="text-primary text-bold">{{ getCompanyName }}</span>
      </h3>
    </div>

    <div v-if="isLoading" class="text-center">
      <b-spinner></b-spinner>
    </div>

    <div v-else>
      <OverviewMenu :company="retrieved" :read-only="readOnly"></OverviewMenu>

      <b-modal
        id="companyInformationUpdate"
        title="Edit Company Information"
        size="xl"
        hide-footer
      >
        <template #modal-header>
          <div class="custom-modal__popup">
            <span class="custom-modal__popup__title"
              >Edit Company Information</span
            >
          </div>
          <i
            class="fa fa-times custom-modal__popup__close"
            @click="$bvModal.hide('companyInformationUpdate')"
          ></i>
        </template>

        <CompanyInfo :company="retrieved" @updated="refreshCompanyData" />
      </b-modal>

      <b-modal id="updateLogo" title="Update Logo" size="xl" hide-footer>
        <template #modal-header>
          <div class="custom-modal__popup">
            <span class="custom-modal__popup__title">Update Logo</span>
          </div>
          <i
            class="fa fa-times custom-modal__popup__close"
            @click="$bvModal.hide('updateLogo')"
          ></i>
        </template>

        <LogoUpdate @updated="refreshCompanyData" />
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import OverviewMenu from './Menu/Main';
import LogoUpdate from './Menu/Logo';
import CompanyInfo from './Menu/CompanyInfo';

export default {
  name: 'GeneralSettings',

  components: {
    OverviewMenu,
    LogoUpdate,
    CompanyInfo
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapFields('company/show', ['retrieved', 'isLoading']),

    getCompanyId() {
      return this.getUser.company.entity.company['@id'];
    },

    getCompanyName() {
      return this.getUser.company.entity.company.name;
    },

    readOnly() {
      return this.getUser.company.entity.isAdmin;
    }
  },

  created() {
    this.refreshCompanyData();
  },

  methods: {
    ...mapActions({
      retrieve: 'company/show/retrieve'
    }),

    refreshCompanyData() {
      this.retrieve(this.getCompanyId);
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  color: $gray-500;
}

.subtitle {
  // font-size: 16px;
  font-weight: 800;
  text-transform: capitalize;
  color: $gray-500;
}

.text-info {
  // font-size: 13px;
  margin: 6px 0px;
}

.edit-color {
  color: $gray-500 !important;
  font-weight: 300;
}

button {
  // font-size: 14px;
  border-radius: 8px;
  padding: 5px 40px;
}

::v-deep .card-group {
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.09);

  & .card {
    background-color: $gray-200;
    border: hidden !important;

    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &.box {
      .show-card-icon {
        display: none;
      }
    }

    &.box:hover {
      background-color: $gray-400;
      cursor: pointer;

      .show-card-icon {
        display: block;
        color: $gray-500;
      }
    }

    &.box:focus {
      outline: none;
    }

    .img-card {
      color: $gray-500;
      cursor: pointer;
      outline: none;
    }

    .img-background {
      background-color: $white;
      border-radius: 5px;

      .card-body {
        background-color: $white;
      }
    }
  }
}
</style>
