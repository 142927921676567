<template>
  <div>
    <div class="accountant">
      <Organization :country-id="countryId" />
    </div>

    <div class="accountant">
      <Type :country-id="countryId" />
    </div>
  </div>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import { SwalAlert } from '@/utils/swal';
import Organization from './Organization';
import Type from './Type';

export default {
  components: {
    Organization,
    Type
  },

  props: {
    countryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newOrganization: {
        country: this.countryId,
        name: 'Name'
      },
      deleting: {}
    };
  },

  created() {
    this.getPage(`${this.countryId}/country-accountant-organizations`);
  },

  computed: {
    ...mapFields('countryaccountantorganization/del', ['deleted']),

    ...mapFields('countryaccountantorganization/list', {
      error: 'error',
      isLoading: 'isLoading',
      items: 'items'
    }),

    ...mapFields('countryaccountantorganization/create', {
      organizationError: 'error',
      organizationIsLoading: 'isLoading',
      organizationCreated: 'created',
      organizationViolations: 'violations'
    })
  },

  watch: {
    organizationCreated: function (created) {
      this.refreshData();
    },

    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.refreshData();
    }
  },

  methods: {
    dayjs,

    ...mapActions({
      getPage: 'countryaccountantorganization/list/default',
      organizationCreate: 'countryaccountantorganization/create/create',
      organizationReset: 'countryaccountantorganization/create/reset',
      organizationDelete: 'countryaccountantorganization/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(`${this.countryId}/country-accountant-organizations`);
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    addCountryOrganization() {
      this.organizationCreate(this.newOrganization);
    },

    del(item) {
      SwalAlert({
        title: 'Organization Delete',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'danger',
        buttons: {
          cancel: {
            label: 'No',
            type: 'success',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.organizationDelete({ '@id': itemId });
            }
          }
        }
      });
    },

    deleteIsLoading(countryOrganizationId) {
      return this.deleting.hasOwnProperty(countryOrganizationId);
    }
  },

  beforeDestroy() {
    this.organizationReset();
  }
};
</script>

<style lang="scss">
.accountant {
  &:not(:first-child) {
    margin-top: 50px;
  }
}
</style>
