<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <EstablishmentForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import EstablishmentForm from './Form.vue';
import { SwalAlert } from '../../utils/swal';
import { entityService } from '@/utils/fetch';

export default {
  components: {
    EstablishmentForm
  },
  props: {
    establishmentId: {
      type: String,
      default() {
        return decodeURIComponent(this.$route.params.id);
      }
    }
  },
  computed: {
    ...mapFields('establishment/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated', updated);
      this.$bvModal.hide('editEstablishment');

      SwalAlert({
        title: 'Establishment updated',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createEstablishment');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.establishmentId);
  },

  methods: {
    ...mapActions({
      retrieve: 'establishment/update/retrieve',
      updateReset: 'establishment/update/reset',
      update: 'establishment/update/update',
      updateRetrieved: 'establishment/update/updateRetrieved'
    }),

    del() {
      if (
        window.confirm('Are you sure you want to delete this establishment ?')
      ) {
        this.deleteItem(this.retrieved);
      }
    },

    reset() {
      this.updateReset();
    },

    onSendForm(item) {
      // Always create a new address on change
      delete item.address['@id'];
      delete item.address['@context'];
      delete item.address['@type'];

      this.update(item);
    }
  }
};
</script>
