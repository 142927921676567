<template>
  <div class="show__content" v-if="handshake">
    <div class="show__item" v-if="canAcceptOrReject">
      <div class="d-flex">
        <span class="show__item__title show__item__title--black"
          >Is the data of <strong>Aprova Report</strong> correct?
        </span>
        <b-form-checkbox
          class="ml-5"
          id="acceptCompany"
          v-model="acceptCompany"
          name="acceptCompany"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </div>
    </div>

    <div class="show__item mt-4">
      <span class="show__item--title" @click="openAprova"
        >Open Aprova Report</span
      >
    </div>

    <b-modal id="aprovaReport" size="xl" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >AprovA Report
            <span class="custom-modal__popup__title--blue">{{
              company.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('aprovaReport')"
        ></i>
      </template>

      <AprovaReport :companyId="company['@id']" />
    </b-modal>
  </div>
</template>

<script>
import AprovaReport from '@/components/aprova/Form';

export default {
  name: 'CompanyJoin',

  components: {
    AprovaReport
  },

  props: {
    handshake: {
      type: Object,
      required: true
    },
    canAcceptOrReject: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canSubmit() {
      return this.acceptCompany;
    }
  },

  data() {
    return {
      acceptCompany: false,
      company: {}
    };
  },

  methods: {
    openAprova() {
      this.company = this.handshake.company;
      this.$bvModal.show('aprovaReport');
    }
  },

  watch: {
    canSubmit: function (newVal) {
      this.$emit('isSubmitAllowed', newVal);
    }
  }
};
</script>

<style lang="scss">
.show__item--title {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}
</style>
