import CompanyStructure from '@/components/dashboard/my/company/Structure';
import CompanySettings from '@/components/dashboard/my/company/Overview/Main';
import CompanyEmployees from '@/components/dashboard/my/company/Employees';
import CompanyFinancial from '@/components/dashboard/my/company/Financial';

export default [
  {
    name: 'GeneralSettings',
    path: '/my/company',
    component: CompanySettings
  },
  {
    name: 'CompanyEmployees',
    path: '/my/company/employees',
    component: CompanyEmployees
  },
  {
    name: 'CompanyFinancial',
    path: '/my/company/financial',
    component: CompanyFinancial
  },
  {
    name: 'CompanyStructure',
    path: '/my/company/structure',
    component: CompanyStructure
  }
];
