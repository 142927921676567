import Vue from 'vue';
import Vuex from 'vuex';

// -------------- Auth --------------
import { auth } from './modules/auth/index';
import { chat } from './modules/chat/index';
// -------------- Entity Service --------------
import user from './modules/user/index';
import activities from './modules/activities/index';
import company from './modules/company/index';
import sector from './modules/sector/index';
import theme from './modules/theme/index';
import country from './modules/country/index';
import countryvat from './modules/countryvat/index';
import countryarticle from './modules/countryarticle/index';
import language from './modules/language/index';
import relationship from './modules/relationship/index';
import role from './modules/role/index';
import settings from './modules/settings/index';
import companyuser from './modules/companyuser/index';
import address from './modules/address/index';
import establishment from './modules/establishment/index';
import article from './modules/article/index';
import invoicetype from './modules/invoicetype/index';
import protocol from './modules/protocol/index';
import protocolrule from './modules/protocolrule/index';
import shareholder from './modules/shareholder/index';
import bankaccount from './modules/bankaccount/index';
import handshake from './modules/handshake/index';
import countryaccountantorganization from './modules/countryaccountantorganization/index';
import branches from './modules/branches/index';
import financial from './modules/financial/index';
import ledgers from './modules/ledgers/index';

import countryaccountanttype from './modules/countryaccountanttype/index';
import prospects from './modules/prospect/index';
import termsAndConditions from './modules/termsAndConditions/index';
// -------------- Business Logic Service --------------
import program from './modules/program/index';
import learningmaterial from './modules/learningmaterial/index';
import learningmodule from './modules/learningmodule/index';
import learningactivity from './modules/learningactivity/index';
import learningactivitysubcontractor from './modules/learningactivitysubcontractor/index';
import group from './modules/group/index';
import learner from './modules/learner/index';
import teacher from './modules/teacher/index';
import document from './modules/document/index';
import payment from './modules/payment/index';
import template from './modules/template/index';
import collectionNotes from './modules/collectionNotes/index';
import invoices from './modules/invoices/index';
import invoiceables from './modules/invoiceable/index';
import shareables from './modules/shareables/index';
import creditNotes from './modules/creditNotes/index';
import questions from './modules/questions/index';

// -------------- Communication Service --------------
import room from './modules/room/index';
import message from './modules/message/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    chat,
    // -------------- Entity Service --------------
    user,
    activities,
    company,
    sector,
    theme,
    country,
    countryvat,
    countryarticle,
    language,
    relationship,
    settings,
    role,
    companyuser,
    address,
    establishment,
    article,
    invoicetype,
    protocol,
    protocolrule,
    shareholder,
    bankaccount,
    handshake,
    countryaccountantorganization,
    branches,
    financial,
    countryaccountanttype,
    prospects,
    termsAndConditions,
    // -------------- Business Logic Service --------------
    program,
    learningmaterial,
    learningmodule,
    learningactivity,
    learningactivitysubcontractor,
    group,
    teacher,
    learner,
    document,
    payment,
    collectionNotes,
    invoices,
    invoiceables,
    shareables,
    creditNotes,
    questions,
    ledgers,
    // -------------- Communication Service -------------
    room,
    message,
    // -------------- Document Service -------------
    template
  }
});
