<template>
  <div>
    <component :is="type"></component>
  </div>
</template>

<script>
import PENDING from '@/components/_ui/status/type/Pending';
import ACCEPTED from '@/components/_ui/status/type/Accepted';
import COMPLETED from '@/components/_ui/status/type/Completed';
import REJECTED from '@/components/_ui/status/type/Rejected';

export default {
  name: 'Button',

  components: {
    PENDING,
    ACCEPTED,
    COMPLETED,
    REJECTED
  },

  props: {
    type: {
      type: String,
      required: true
    }
  }
};
</script>
