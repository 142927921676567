<template>
  <div>
    <div class="d-flex justify-content-between">
      <table class="table">
        <tr>
          <th colspan="2">Date</th>
          <th>Standard</th>
          <th>Low</th>
          <th>LowLow</th>
        </tr>
        <tr v-for="countryVatRate in getVatMatrix" :key="countryVatRate.id">
          <td colspan="2">
            <strong>{{
              dayjs(countryVatRate.startDate).format('DD/MM/YYYY')
            }}</strong>
            to
            <strong
              :class="{
                'text-success': !countryVatRate.endDate,
                'text-danger': countryVatRate.endDate
              }"
              >{{
                countryVatRate.endDate
                  ? dayjs(countryVatRate.endDate).format('DD/MM/YYYY')
                  : 'N/A'
              }}</strong
            >
          </td>
          <td>
            <span v-if="countryVatRate.HIGH"
              >{{ countryVatRate.HIGH.value }}%
              <a href="#" @click.prevent="edit(countryVatRate.HIGH)"
                ><i class="fa fa-edit text-info"></i></a
            ></span>
          </td>
          <td>
            <span v-if="countryVatRate.LOW"
              >{{ countryVatRate.LOW.value }}%
              <a href="#" @click.prevent="edit(countryVatRate.LOW)"
                ><i class="fa fa-edit text-info"></i></a
            ></span>
          </td>
          <td>
            <span v-if="countryVatRate.LOWLOW"
              >{{ countryVatRate.LOWLOW.value }}%
              <a href="#" @click.prevent="edit(countryVatRate.LOWLOW)"
                ><i class="fa fa-edit text-info"></i></a
            ></span>
          </td>
        </tr>

        <tr>
          <td width="225">
            <b-input-group>
              <b-form-input
                v-model="item.startDate"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-model="item.startDate"
                  button-only
                  right
                  locale="en-US"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </td>
          <td width="250">
            <b-input-group>
              <b-form-checkbox
                v-model="formHasEndDate"
                class="mt-2"
              ></b-form-checkbox>
              <b-form-input
                v-if="formHasEndDate"
                v-model="item.endDate"
                type="text"
                placeholder="YYYY-MM-DD"
                autocomplete="off"
              ></b-form-input>
              <b-input-group-append>
                <b-form-datepicker
                  v-if="formHasEndDate"
                  v-model="item.endDate"
                  button-only
                  right
                  locale="en-US"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </td>
          <td>
            <b-form-input type="number" v-model="form.HIGH" size="xl" />
          </td>
          <td>
            <b-form-input type="number" v-model="form.LOW" size="xl" />
          </td>
          <td>
            <b-input-group>
              <b-form-checkbox
                v-model="form.hasLowLow"
                class="mt-2"
              ></b-form-checkbox>
              <b-form-input
                v-if="form.hasLowLow"
                type="number"
                v-model="form.LOWLOW"
                size="xl"
              />
            </b-input-group>
          </td>

          <td>
            <b-button v-if="isLoading" variant="primary" disabled>
              <b-spinner small></b-spinner>
            </b-button>
            <b-button
              v-else
              @click.prevent="addCountryVat"
              class="float-right"
              variant="primary"
              type="button"
              ><i class="fas fa-plus position-icon"></i> Add
            </b-button>
          </td>
        </tr>
      </table>
    </div>

    <b-modal id="editCountryVat" title="Edit VAT" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Vat</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editCountryVat')"
        ></i>
      </template>
      <CountryVatUpdate
        :country-vat="editCountryVat"
        @updated="refreshData"
        @deleted="refreshData"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import dayjs from 'dayjs';
import _ from 'lodash';
import CountryVatUpdate from '../../../../countryvat/Update.vue';

export default {
  components: {
    CountryVatUpdate
  },
  name: 'VatRates',
  props: {
    countryId: {
      type: String,
      required: true
    },
    vatRates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      formHasEndDate: false,
      formBackupEndDate: dayjs().format('YYYY-MM-DD'),
      editCountryVat: {},
      queue: [],
      form: {
        HIGH: 0,
        LOW: 0,

        hasLowLow: false,
        LOWLOW: 0
      },
      item: {
        country: this.countryId,

        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      }
    };
  },
  computed: {
    ...mapFields('countryvat/create', ['error', 'created', 'violations']),

    /**
     * Since we queue import, loading only stops at the end
     */
    isLoading: function () {
      return this.queue.length > 0;
    },
    getVatMatrix: function () {
      let matrix = {};
      this.vatRates.map((vatRate) => {
        // Check if exists
        let index = `${vatRate.startDate}-${vatRate.endDate}`;
        if (!matrix.hasOwnProperty(index)) {
          matrix[index] = {
            startDate: vatRate.startDate,
            endDate: vatRate.endDate
          };
        }
        matrix[index][vatRate.type] = vatRate;
      });
      return _.orderBy(matrix, ['startDate', 'endDate'], ['desc', 'desc']);
    }
  },
  watch: {
    created: function (created) {
      if (!created) {
        return;
      }

      // Continue queue!
      this.runQueue();
    },
    error: function (error) {
      if (error) {
        // Clear queue
        this.queue = [];
      }
    },
    /**
     * Keep the end date or put it null for PUT
     * @param formHasEndDate
     */
    formHasEndDate: function (formHasEndDate) {
      if (!formHasEndDate) {
        this.formBackupEndDate = this.item.endDate;
        this.item.endDate = null;
      } else {
        this.item.endDate = this.formBackupEndDate;
      }
    }
  },
  methods: {
    dayjs,
    ...mapActions('countryvat/create', ['create']),

    addCountryVat() {
      // We need to add multiple!
      this.queue.push({ ...this.item, type: 'HIGH', value: this.form.HIGH });
      this.queue.push({ ...this.item, type: 'LOW', value: this.form.LOW });
      if (this.form.hasLowLow) {
        this.queue.push({
          ...this.item,
          type: 'LOWLOW',
          value: this.form.LOWLOW
        });
      }
      this.runQueue();
    },

    edit(countryVat) {
      this.editCountryVat = countryVat;
      this.$bvModal.show('editCountryVat');
    },

    runQueue() {
      if (this.queue.length > 0) {
        this.create(this.queue.pop());
      } else {
        this.refreshData();
      }
    },

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.$emit('created');
    }
  }
};
</script>

<style scoped lang="scss">
.labels {
  // font-size: 16px;
  font-weight: 600;
}

li {
  list-style: none;
  // font-size: 14px;
}

.form-control,
.custom-select {
  border-radius: 8px;
  border: 2px solid $gray-200;
  padding: 0px 12px !important;
}

.form-select {
  width: 170px !important;
  height: 30px !important;
}

.form-vat-input {
  width: 100px !important;
  height: 30px !important;
}

.btn-size {
  width: 27px !important;
  height: 30px !important;
  margin-top: 30px;
}

.position-icon {
  position: relative;
  top: -7px;
  left: -7px;
}
</style>
