<template>
  <div>
    <b-container>
      <div v-if="error" class="alert alert-danger" role="alert">
        <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
          error
        }}</span>
      </div>

      <b-row>
        <b-col>
          <h1>Correcting Status</h1>
          <b-form-group label="Date" label-for="date">
            <b-form-input type="text" v-model="correcting.date" readonly>
            </b-form-input>
          </b-form-group>

          <b-form-group label="Current State">
            <div class="correct">
              <b-form-radio
                disabled
                v-model="correcting.colorTo"
                name="correct"
                value="RED"
                class="trigger-bullet"
                :class="{ 'trigger-bullet-red': correcting.colorTo === 'RED' }"
                size="lg"
              ></b-form-radio>
              <b-form-radio
                disabled
                v-model="correcting.colorTo"
                name="correct"
                value="ORANGE"
                class="trigger-bullet"
                :class="{
                  'trigger-bullet-orange': correcting.colorTo === 'ORANGE'
                }"
                size="lg"
              ></b-form-radio>
              <b-form-radio
                disabled
                v-model="correcting.colorTo"
                name="correct"
                value="GREEN"
                class="trigger-bullet"
                :class="{
                  'trigger-bullet-green': correcting.colorTo === 'GREEN'
                }"
                size="lg"
              >
              </b-form-radio>
            </div>
          </b-form-group>

          <b-form-group label="Reason" label-for="reason">
            <b-form-select disabled>
              <b-form-select-option>{{
                correcting.reason
              }}</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Addicional remark(s)" label-for="remarks">
            {{ correcting.note ? correcting.note : 'N/A' }}
          </b-form-group>
        </b-col>
        <b-col>
          <h1>New Status</h1>
          <b-form @submit.prevent="onSendForm">
            <b-form-group label="Date" label-for="date">
              <b-form-input type="date" v-model="item.date"> </b-form-input>
            </b-form-group>

            <b-form-group label="Correction">
              <div class="correct">
                <b-form-radio
                  v-model="item.colorTo"
                  name="correct"
                  value="RED"
                  class="trigger-bullet"
                  :class="{ 'trigger-bullet-red': item.colorTo === 'RED' }"
                  size="lg"
                ></b-form-radio>
                <b-form-radio
                  v-model="item.colorTo"
                  name="correct"
                  value="ORANGE"
                  class="trigger-bullet"
                  :class="{
                    'trigger-bullet-orange': item.colorTo === 'ORANGE'
                  }"
                  size="lg"
                ></b-form-radio>
                <b-form-radio
                  v-model="item.colorTo"
                  name="correct"
                  value="GREEN"
                  class="trigger-bullet"
                  :class="{ 'trigger-bullet-green': item.colorTo === 'GREEN' }"
                  size="lg"
                >
                </b-form-radio>
              </div>
            </b-form-group>

            <b-form-group label="Reason" label-for="reason">
              <b-form-select v-model="item.reason">
                <b-select-option value="MANUAL_RETURN_RII_STATUS" selected
                  >MANUAL OVERRULE</b-select-option
                >
                <b-select-option value="MANUAL_CREATOR_MORGAN_STANLEY"
                  >MANUAL_CREATOR_MORGAN_STANLEY</b-select-option
                >
                <b-select-option value="MANUAL_CREATOR_KNEW_SITUATION"
                  >MANUAL_CREATOR_KNEW_SITUATION</b-select-option
                >
                <b-select-option value="MANUAL_CREATOR_IS_SUPERSPREADER"
                  >MANUAL_CREATOR_IS_SUPERSPREADER</b-select-option
                >
              </b-form-select>
            </b-form-group>

            <b-form-group label="Addicional remark(s)" label-for="remarks">
              <b-form-textarea
                id="textarea"
                v-model="item.note"
                placeholder="Enter reason..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group class="float-right">
              <b-button
                @click="closeModal"
                class="mr-2"
                type="button"
                variant="secondary"
                v-if="!isLoading"
                >Cancel
              </b-button>

              <b-button type="submit" variant="primary" v-if="!isLoading"
                >Save
              </b-button>
              <b-button type="submit" variant="primary" v-else>
                <b-spinner small></b-spinner>
              </b-button>
            </b-form-group>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'company/semaphoreLog/create/getField',
  mutationType: 'company/semaphoreLog/create/updateField'
});

export default {
  name: 'CorrectComponent',

  components: {},

  props: {
    correcting: {
      type: Object,
      required: true
    }
  },

  created() {
    this.reset();
    // set default reason
    this.item.reason = 'MANUAL_RETURN_RII_STATUS';
  },

  data() {
    return {
      item: {}
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    created: function (created) {
      this.$emit('created', created);
      this.$emit('closeCorrectionLight');
    }
  },

  methods: {
    ...mapActions('company/semaphoreLog/create', ['create', 'reset']),

    onSendForm() {
      this.item.company = this.correcting.company;
      this.item.date = new Date(this.item.date).toISOString().split('T')[0];
      this.item.overrules = this.correcting['@id'];
      this.create(this.item);
    },

    closeModal() {
      this.$emit('closeCorrectionLight');
    }
  }
};
</script>

<style lang="scss">
.correct {
  display: flex;
  align-items: center;
}

.trigger {
  &-bullet {
    &-red {
      .custom-control-input:checked ~ .custom-control-label::after {
        background: $danger;
        border-radius: 50%;
      }
    }

    &-orange {
      .custom-control-input:checked ~ .custom-control-label::after {
        background: $warning;
        border-radius: 50%;
      }
    }

    &-green {
      .custom-control-input:checked ~ .custom-control-label::after {
        background: $success;
        border-radius: 50%;
      }
    }
  }
}
</style>
