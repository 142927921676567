import TemplateList from '@/components/admin/template/List';
import TemplateCreate from '@/components/admin/settings/Create';
import TemplateUpdate from '@/components/admin/settings/Update';

export default [
  { name: 'TemplateList', path: '/templates/', component: TemplateList },
  {
    name: 'TemplatesCreate',
    path: '/settings/create',
    component: TemplateCreate
  },
  {
    name: 'TemplateUpdate',
    path: '/templates/edit/:id',
    component: TemplateUpdate
  }
];
