import CompletedList from '@/components/dashboard/program/completed/List.vue';

import Default from '@/views/dashboard/Programs/Default';

export default [
  {
    path: '/programs/completed',
    component: Default,
    children: [
      {
        name: 'CompletedList',
        path: '/programs/completed/',
        component: CompletedList
      }
    ]
  }
];
