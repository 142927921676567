<template>
  <div>
    <b-container class="px-4 pb-4 bg-white" fluid v-if="!isCompanyLoading">
      <div class="d-flex align-items-center">
        <img
          alt=""
          class="mr-2"
          src="@/assets/img/icons/menuicons/company@2x.png"
        />
        <h3 class="font-weight-bold mt-2">Accountant</h3>
      </div>

      <CustomTable
        @handleSortChange="handleSortChange"
        :fields="fields"
        list="relationship"
        @changePage="changePage"
      >
        <template v-slot:actions="data" class="mr-4">
          <a
            class="mr-2"
            href="#"
            @click.prevent="goToDetails(data.item)"
            v-b-tooltip.hover
            title="Edit"
          >
            <i class="fas fa-edit text-info"></i>
          </a>
          <span v-if="!data.item.isDefault">
            <a
              v-if="!deleteIsLoading(data.item['@id'])"
              href="#"
              @click.prevent="deleteItem(data.item)"
              ><i class="fa fa-trash text-info"></i
            ></a>
            <a v-else href="#"><b-spinner small /></a>
          </span>
        </template>

        <template v-slot:isDefault="data">
          <div class="text-center">
            <i class="fa fa-asterisk fa-primary" v-if="data.item.isDefault"></i>
          </div>
        </template>

        <template #buttons>
          <b-button
            @click="$bvModal.show('createRelationship')"
            variant="primary"
          >
            Add Accountant
          </b-button>
        </template>
      </CustomTable>

      <div class="d-flex">
        <b-form-group class="mt-4" v-if="amplificaAccountant">
          <b-form-checkbox
            id="amplificaAccountant"
            @change="onChange"
            v-model="useAmplificaAccountant"
            :checked="true"
            :unchecked-value="false"
          >
            <span
              >A certified Accountant is assigned by <br />AprovA (we don't have
              an Accountant)</span
            >
          </b-form-checkbox>
        </b-form-group>
      </div>

      <b-modal id="createRelationship" hide-footer title="Add New Accountant">
        <template #modal-header>
          <div class="custom-modal__popup">
            <span class="custom-modal__popup__title">Add new Accountant</span>
          </div>
          <i
            class="fa fa-times custom-modal__popup__close"
            @click="$bvModal.hide('createRelationship')"
          ></i>
        </template>

        <RelationshipCreate
          :is-creating-accountant="true"
          @created="refreshData"
          :skip-company-admin="false"
          :is-only-commercial="true"
        />
      </b-modal>

      <b-modal id="showRelationship" hide-footer>
        <template #modal-header>
          <div class="custom-modal__popup">
            <span class="custom-modal__popup__title">Relationship</span>
          </div>
          <i
            class="fa fa-times custom-modal__popup__close"
            @click="$bvModal.hide('showRelationship')"
          ></i>
        </template>

        <AccountantShow
          :item="accountant"
          @setPrimaryAccountant="setPrimaryAccountant"
          :isLoading="isLoading"
        />
      </b-modal>
    </b-container>
    <div v-else>
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import CustomTable from '@/components/_ui/tables/CustomTable';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import RelationshipCreate from '@/components/dashboard/relationship/Create';
import AccountantShow from './AccountantShow';
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'Accountant',

  components: {
    CustomTable,
    RelationshipCreate,
    AccountantShow
  },

  data() {
    return {
      accountant: {},
      fields: [
        {
          key: 'isDefault',
          label: 'Primary',
          sortable: true,
          formatter: (value, key, item) => {
            if (
              item.company.primaryAccountant?.['@id'] === item.related['@id']
            ) {
              this.$set(item, 'isDefault', true);
            } else {
              this.$set(item, 'isDefault', false);
            }

            return item.isDefault;
          }
        },
        { key: 'related.name', label: 'Name', sortable: true },
        { key: 'related.fiscal_id', label: 'VAT', sortable: true },
        {
          key: 'related.address',
          label: 'Address',
          sortable: true,
          formatter: (value, key, item) => {
            if (!value) {
              return '';
            }
            return `${item.related.address.street} ${item.related.address.number}`;
          }
        },
        {
          key: 'related.address',
          label: 'Country',
          sortable: true,
          formatter: (value, key, item) => {
            if (!value) {
              return '';
            }
            return `${item.related.address.country.name}`;
          }
        },
        { key: 'related.email', label: 'Email Address', sortable: true },
        { key: 'related.phone', label: 'Telephone Number', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      deleting: {},
      amplificaAccountant: null,
      useAmplificaAccountant: false,
      isLoading: false,
      company: {},
      isCompanyLoading: false,
      page: 1,
      sort: null,
      filter: null,
      itemsPerPage: 25
    };
  },

  computed: {
    ...mapFields('relationship/del', {
      deleted: 'deleted'
    }),
    ...mapGetters('auth', ['getUser', 'getCompanyId']),

    request() {
      let request = `${this.getCompanyId}/relationships?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&isAccountant=true`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
    this.$root.$on('setPrimaryAccountant', this.setPrimaryAccountant);
  },

  methods: {
    ...mapActions({
      getPage: 'relationship/list/default',
      accountantDelete: 'relationship/del/del'
    }),

    onChange(value) {
      const primaryAccountantCompany = value
        ? `/companies/${this.amplificaAccountant.company.id}`
        : null;

      this.setPrimaryAccountant(primaryAccountantCompany);
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
      this.getCompany();
    },

    deleteItem(item) {
      SwalAlert({
        title: 'Delete Accountant',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;

              this.accountantDelete({ '@id': itemId });
            }
          }
        }
      });
    },

    deleteIsLoading(accountantId) {
      return this.deleting.hasOwnProperty(accountantId);
    },

    goToDetails(accountant) {
      this.accountant = accountant;

      this.$bvModal.show('showRelationship');
    },

    getAllowAmplificaAccountant() {
      entityService('/allow-amplifica-accountant')
        .then((response) => response.json())
        .then((data) => {
          this.amplificaAccountant = data;
          this.checkIfAccountantIsFromAmplifica();
        })
        .catch((e) => {
          this.amplificaAccountant = false;
        });
    },

    setPrimaryAccountant(accountant) {
      this.isLoading = true;
      const body = {
        primaryAccountant: accountant
      };

      entityService(this.getCompanyId, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(body)
      })
        .then((response) => response.json())
        .then((data) => {
          SwalAlert({
            title: 'Linked Accountant updated',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {
                  this.$bvModal.hide('showRelationship');
                  this.refreshData();
                }
              }
            }
          });
        })
        .catch((e) => {
          this.error = 'Something wrong happened';
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getCompany() {
      this.isCompanyLoading = true;
      entityService(this.getCompanyId)
        .then((response) => response.json())
        .then((data) => {
          this.$set(this, 'company', data);
        })
        .finally(() => {
          this.isCompanyLoading = false;
        });
    },

    checkIfAccountantIsFromAmplifica() {
      if (
        `/companies/${this.amplificaAccountant.company.id}` ===
        this.company?.primaryAccountant?.['@id']
      ) {
        this.useAmplificaAccountant = true;
      } else {
        this.useAmplificaAccountant = false;
      }
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.refreshData();
    },

    company: function () {
      this.getAllowAmplificaAccountant();
    }
  }
};
</script>
