<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <CountryForm
      :isLoading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import CountryForm from './Form';
import { SwalAlert } from '../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'country/create/getField',
  mutationType: 'country/create/updateField'
});

export default {
  components: {
    CountryForm
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createCountry');
      SwalAlert({
        title: 'Country added',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createCountry');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {
              return;
            }
          }
        }
      });
    }
  },

  methods: {
    ...mapActions('country/create', ['create']),

    onSendForm() {
      this.create(this.item);
    }
  }
};
</script>
