<template>
  <b-form class="mt-3" @submit.prevent="onSendForm()">
    <b-form-group label="Creator+ CompanyID" label-for="creatorId">
      <b-form-input
        id="creatorId"
        v-model="company.creatorId"
        type="text"
        :disabled="!company.canEditCreatorId"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Company name" label-for="name">
      <b-form-input
        id="name"
        v-model="company.related.name"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Company tradename" label-for="tradename">
      <b-form-input
        id="tradename"
        v-model="company.related.tradename"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="VAT Number" label-for="vat">
      <b-form-input
        id="vat"
        v-model="company.related.fiscal_id"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Chamber of Commerce [optional]"
      label-for="chamberOfCommerce"
    >
      <b-form-input
        id="chamberOfCommerce"
        v-model="company.related.chamberOfCommerce"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Sector"
      label-for="company_sector"
      v-if="company.related.sector"
    >
      <b-form-input
        id="sector"
        v-model="company.related.sector.name"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-row>
      <b-col cols="6">
        <b-form-group label="Email Address" label-for="email">
          <b-form-input
            id="email"
            v-model="company.related.email"
            type="text"
            readonly
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Phone" label-for="phone">
          <b-form-input
            id="phone"
            v-model="company.related.phone"
            type="text"
            readonly
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="float-right mt-5">
      <b-button
        type="button"
        variant="secondary mr-2"
        :disabled="isLoading"
        @click="$bvModal.hide('showRelation')"
        >Cancel</b-button
      >
      <template v-if="company.canEditCreatorId">
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Save</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </template>
    </div>
  </b-form>
</template>

<script>
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'Roles',

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false
    };
  },

  methods: {
    onSendForm() {
      this.isLoading = true;
      entityService(this.company['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(this.company)
      })
        .then(() => {
          this.$emit('updated');
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: e.errors._error,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
