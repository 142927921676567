<template>
  <b-container fluid class="px-4">
    <b-row>
      <b-col cols="7">
        <div class="d-flex align-items-center">
          <img
            class="mr-2"
            src="../../../../assets/img/icons/menuicons/relations@2x.png"
            alt=""
          />
          <h3 class="font-weight-bold mt-2">Accountant Details</h3>
        </div>

        <b-form @submit.prevent="onSendForm">
          <b-container id="role_switcher">
            <div v-if="showError" class="alert alert-danger" role="alert">
              <span class="fa fa-exclamation-triangle" aria-hidden="true">
                All fields are required
              </span>
            </div>

            <b-form-group
              label="Country *"
              label-for="country"
              class="font-weight-bold"
              v-if="showCountries"
            >
              <v-select
                required
                v-model="data.country"
                id="company_fiscal_id_country"
                :options="countrySelectItems"
                label="name"
                @input="fetchTypeAndOrganizations($event['@id'])"
              >
              </v-select>
            </b-form-group>

            <b-form-group
              label="Accountant Registration Number *"
              label-for="accountant_number"
              class="font-weight-bold"
            >
              <b-form-input
                id="article_name"
                v-model="data.accountantRegistrationNumber"
                type="text"
                required="required"
                placeholder=""
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Professional Organization *"
              label-for="accountant_professional_organization"
              class="font-weight-bold"
            >
              <b-form-select
                v-if="countryAccountantOrganizationIsLoading"
                disabled
                value=""
              >
                <b-form-select-option selected value=""
                  >Loading...</b-form-select-option
                >
              </b-form-select>
              <b-form-select
                v-else
                v-model="data.accountantProfessionalOrganization"
                id="accountant_professional_organization"
                :options="countryAccountantOrganization"
                :disabled="countryAccountantOrganization.length === 1"
                text-field="name"
                value-field="@id"
                required
              >
              </b-form-select>
            </b-form-group>

            <b-form-group
              label="Accountant Registration Type *"
              label-for="accountant_Type"
              class="font-weight-bold"
            >
              <b-form-select
                v-if="countryAccountantTypeIsLoading"
                disabled
                value=""
              >
                <b-form-select-option selected value=""
                  >Loading...</b-form-select-option
                >
              </b-form-select>
              <b-form-select
                v-else
                v-model="data.accountantRegistrationType"
                id="accountant_type"
                :options="countryAccountantType"
                text-field="type"
                value-field="@id"
                required
              >
              </b-form-select>
            </b-form-group>
            <b-form-row class="float-right p-3">
              <b-button type="submit" variant="primary" v-if="!isLoading"
                >Save</b-button
              >
              <b-button type="submit" variant="primary" v-else>
                <b-spinner small></b-spinner>
              </b-button>
            </b-form-row>
          </b-container>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { entityService } from '@/utils/fetch.js';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { SwalAlert } from '@/utils/swal';
import { AUTH_REFRESH_TOKEN } from '@/store/modules/auth/actions';

export default {
  name: 'AccountantInfo',

  computed: {
    ...mapGetters('auth', ['getUser', 'getToken']),

    ...mapFields('country/list', {
      countrySelectItems: 'items'
    }),

    ...mapFields('countryaccountantorganization/list', {
      countryAccountantOrganization: 'items',
      countryAccountantOrganizationIsLoading: 'isLoading'
    }),

    ...mapFields('countryaccountanttype/list', {
      countryAccountantType: 'items',
      countryAccountantTypeIsLoading: 'isLoading'
    }),

    canSubmit() {
      return (
        this.data.accountantProfessionalOrganization &&
        this.data.accountantRegistrationType &&
        this.data.accountantRegistrationNumber &&
        this.data.country
      );
    }
  },

  mounted() {
    this.getCountries();

    let userAddress = this.getUser.entity?.address;

    if (userAddress && userAddress.country) {
      this.data.country = userAddress.country['@id'];
      this.showCountries = false;
      this.fetchTypeAndOrganizations(this.data.country);
    }

    if (this.getUser.entity.accountantProfessionalOrganization) {
      this.data.accountantProfessionalOrganization =
        this.getUser.entity.accountantProfessionalOrganization['@id'];
    }

    if (this.getUser.entity.accountantRegistrationType) {
      this.data.accountantRegistrationType =
        this.getUser.entity.accountantRegistrationType['@id'];
    }

    if (this.getUser.entity.accountantRegistrationNumber) {
      this.data.accountantRegistrationNumber =
        this.getUser.entity.accountantRegistrationNumber;
    }
  },

  data() {
    return {
      showCountries: true,
      isLoading: false,
      showError: false,
      data: {
        country: null,
        accountantProfessionalOrganization: null,
        accountantRegistrationType: null,
        accountantRegistrationNumber: null
      }
    };
  },

  methods: {
    ...mapActions('auth', [AUTH_REFRESH_TOKEN]),
    ...mapActions({
      getCountries: 'country/list/default',
      getCountryProfessionalOrganization:
        'countryaccountantorganization/list/default',
      getCountryAccountantTypes: 'countryaccountanttype/list/default'
    }),

    fetchTypeAndOrganizations(id) {
      this.getCountryProfessionalOrganization(
        `${id}/country-accountant-organizations`
      );
      this.getCountryAccountantTypes(`${id}/country-accountant-types`);
    },

    onSendForm() {
      this.isLoading = true;

      entityService(this.getUser.entity['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(this.data)
      })
        .then((response) => response.json())
        .then((data) => {
          // Refresh data
          this.AUTH_REFRESH_TOKEN({
            refresh_token: this.getToken.refresh_token
          });

          // Show alert
          SwalAlert({
            title: 'Account was updated',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error!',
            description: e,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {
                  return;
                }
              }
            }
          });
        })
        .finally((e) => {
          this.isLoading = false;
        });
    }
  },

  watch: {
    countryAccountantOrganization: {
      deep: true,

      handler() {
        if (this.countryAccountantOrganization.length === 1) {
          this.data.accountantProfessionalOrganization =
            this.countryAccountantOrganization[0]['@id'];
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.container-fluid {
  background-color: $white;
}
</style>
