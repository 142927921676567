import list from './list';
import create from './create';
import update from './update';
import show from './show';
import del from './delete';
import employees from './employees';
import reserves from './reserves';
import transactions from './transactions';
import answers from './answers';
import ubos from './ubos';
import importedCustomers from './importedCustomers';
import companyAnalysisQuestions from './companyAnalysisQuestions';
import aprovaReport from './aprovaReport';
import semaphoreLog from './semaphoreLog';

export default {
  namespaced: true,
  modules: {
    list,
    create,
    update,
    show,
    del,
    employees,
    reserves,
    answers,
    ubos,
    importedCustomers,
    companyAnalysisQuestions,
    aprovaReport,
    transactions,
    semaphoreLog
  }
};
