<template>
  <div class="user-roles mt-3">
    <b-form @submit.prevent="onSendForm">
      <div>
        <span>Roles</span>
        <hr />
        <b-form-checkbox-group
          class="user-roles__checkbox"
          v-model="userSelectedRoles"
          :options="roles"
          size="lg"
          switches
        ></b-form-checkbox-group>
        <hr />
      </div>

      <div class="float-right mt-5">
        <b-button
          type="button"
          variant="secondary mr-2"
          :disabled="isLoading"
          @click="$bvModal.hide('editUser')"
          >Cancel</b-button
        >
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Save</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'Roles',

  props: {
    roles: {
      type: Array,
      required: true
    },

    selectedRoles: {
      type: Array,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      userSelectedRoles: []
    };
  },

  mounted() {
    this.userSelectedRoles = this.selectedRoles;
  },

  methods: {
    onSendForm() {
      this.handleSubmit(this.userSelectedRoles, 'roles');
    }
  }
};
</script>

<style lang="scss">
.user-roles {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #c7c7c7;
    background-color: #6fb942;
  }
  .custom-control-input ~ .custom-control-label::before {
    color: #fff;
    border-color: #c7c7c7;
    background-color: #e04a46;
  }
  .custom-switch.b-custom-control-lg .custom-control-label::after,
  .input-group-lg .custom-switch .custom-control-label::after {
    width: calc(1.5rem - 3px);
    height: calc(1.5rem - 3px);
    top: calc(0.2125rem + 2px);
    left: calc(-2.9125rem + 2px);
  }
  .custom-switch .custom-control-label::after {
    background-color: #fff !important;
    border-radius: 1.5rem;
    border: 2px solid #797979;
  }
  &__title {
    // font-size: 14px;
    font-weight: 800;
    color: $black;
  }

  &__checkbox {
    display: grid;
    column-gap: 10%;
    grid-template-columns: 150px 150px;
  }
}
</style>
