<template>
  <b-container class="pb-4 bg-white" fluid>
    <div class="px-3 d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/relations@2x.png"
      />
      <h3 class="font-weight-bold mt-2">All Employees</h3>
    </div>

    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="company/employees"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
      class="px-3"
    >
      <template v-slot:isUbo="data">
        <Status class="text-center" :value="data.item.isUbo" />
      </template>

      <template v-slot:roles="data">
        <Roles :roles="data.item.user.roles" />
      </template>

      <template v-slot:isDefault="data">
        <div class="text-center">
          <i class="fa fa-asterisk fa-primary" v-if="data.item.isDefault"></i>
        </div>
      </template>
      <template v-slot:actions="data">
        <a
          href="#"
          class="mr-2"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Edit"
          ><i class="fa fa-edit text-info"></i
        ></a>
        <a
          v-if="
            !data.item.isDefault &&
            data.item.user['@id'] != getUser.entity['@id']
          "
          href="#"
          @click.prevent="deleteItem(data.item)"
          v-b-tooltip.hover
          title="Delete"
        >
          <i class="fas fa-trash text-info"></i>
        </a>
      </template>

      <template #buttons>
        <b-button @click="$bvModal.show('companyUserCreate')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Employee
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="companyUserCreate" hide-footer title="Add New Employee">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add New Employee</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('companyUserCreate')"
        ></i>
      </template>

      <CompanyUserCreate
        :hide="['company']"
        :initial-item="companyUser"
        @created="refreshData"
        :showAllUsers="false"
      ></CompanyUserCreate>
    </b-modal>

    <b-modal id="editEmployee" hide-footer title="Edit Employee">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Employee</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editEmployee')"
        ></i>
      </template>

      <EmployeeUpdate
        :item="editItem"
        @created="refreshData"
        :handleSubmit="updateDefaultEmployee"
        :isLoading="isLoading"
      ></EmployeeUpdate>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CompanyUserCreate from '@/components/common/companyuser/Create';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Roles from '@/components/_ui/users/Roles';
import EmployeeUpdate from './Update.vue';
import { entityService } from '@/utils/fetch';
import Status from '@/components/_ui/tables/Status';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'Employees',

  components: {
    CompanyUserCreate,
    CustomTable,
    Roles,
    EmployeeUpdate,
    Status
  },

  data() {
    return {
      fields: [
        {
          key: 'isDefault',
          label: 'Primary',
          sortable: true
        },
        {
          key: 'fullName',
          label: 'Name',
          sortable: true,
          formatter: (value, key, item) => {
            return `${item.user.firstName} ${item.user.lastName}`;
          }
        },
        {
          key: 'user.email',
          label: 'Email Address',
          sortable: true
        },
        {
          key: 'reg_date',
          label: 'Registration Date',
          sortable: true
        },
        {
          key: 'exit_date',
          label: 'Exit Date',
          sortable: true
        },
        {
          key: 'isUbo',
          label: 'UBO',
          sortable: true
        },
        { key: 'roles', label: 'Roles', sortable: true },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      editItem: {},
      isLoading: false,
      page: 1,
      itemsPerPage: 25
    };
  },

  computed: {
    ...mapGetters('auth', ['getUser', 'getCompanyId']),

    companyUser() {
      return { company: this.getUser.company.entity.company['@id'] };
    },

    request() {
      let request = `${this.getCompanyId}/employees?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'company/employees/list/default',
      deleteCompanyUser: 'companyuser/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    deleteItem(item) {
      SwalAlert({
        title: 'Delete Company User',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.deleteCompanyUser(item).then(() => {
                SwalAlert({
                  title: 'Company User deleted',
                  type: 'success',
                  icon: 'user'
                }).then(() => {
                  this.refreshData();
                });
              });
            }
          }
        }
      });
    },

    edit(item) {
      this.editItem = item;
      this.$bvModal.show('editEmployee');
    },

    updateDefaultEmployee(item) {
      this.isLoading = true;
      const payload = {
        isDefault: item.isDefault
      };

      entityService(item['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(payload)
      })
        .then((response) => response.json())
        .then((data) => {
          this.$emit('updated', this.form);

          this.$bvModal.hide('updatePrimaryContact');
          this.$bvModal.hide('editEmployee');
          SwalAlert({
            title: 'Primary Contact updated',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'Update again',
                type: 'warning',
                callback: () => {
                  this.$bvModal.show('editEmployee');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });

          this.refreshData();
        })
        .catch((error) => {
          SwalAlert({
            title: 'Error',
            description: error,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
