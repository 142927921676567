import list from './list';
import del from './delete';

export default {
  namespaced: true,
  modules: {
    list,
    del
  }
};
