import 'mutationobserver-shim';
import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import store from './store';
import * as Sentry from '@sentry/vue';
import { SENTRY_DSN } from '@/config/entrypoint';
// 3rd parties
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import SwalAlert from './components/_ui/sweetalert/SwalAlert';
import Money from 'v-money';
import './plugins/axios';
import './plugins/bootstrap-vue';
import './plugins/dayjs';
import './plugins/country-flag';
import './plugins/vue-select';
import './plugins/scroll';
import './plugins/vue-cookies';
import './plugins/vue-wysiwyg';

Vue.config.productionTip = false;
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);

Vue.component('swal-alert', SwalAlert);
Vue.use(Money, {
  precision: 2,
  decimal: ',',
  thousands: '.',
  prefix: '€',
  masked: false
})

Sentry.init({
  Vue,
  dsn: SENTRY_DSN
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
