<template>
  <b-container fluid>
    <h3 class="title">
      <i class="fa fa-euro-sign text-primary mr-2" /> Invoices
    </h3>

    <b-row align-h="end">
      <b-col cols="6">
        <b-input-group class="mb-2">
          <b-input-group-prepend is-text>
            <i class="fa fa-search"></i>
          </b-input-group-prepend>
          <b-form-input
            type="search"
            placeholder="Search..."
            v-model="filter"
            @input="debounceRefresh"
            debounce="500"
          ></b-form-input>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row>
      <b-table
        :fields="fields"
        :items="items"
        :busy="listIsLoading"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        class="customTable mt-3 administrationInvoiceable"
        select-mode="single"
        small
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(name)="row">
          <span
            @click="row.toggleDetails"
            class="d-flex justify-content-between"
          >
            <span>{{ row.item.name }}</span>
            <b-spinner v-if="row.item.openLoading" small></b-spinner>
            <i v-else-if="row.detailsShowing" class="fa fa-caret-down" />
            <i v-else class="fa fa-caret-right" />
          </span>
        </template>

        <template #row-details="row">
          <Details :program="row.item" @selected="onSubrowSelected"></Details>
        </template>
      </b-table>
    </b-row>
    <b-row class="pagination" v-if="page <= totalPages">
      <b-col>
        <p class="pagination__current">Page {{ page }} of {{ totalPages }}</p>
      </b-col>
      <b-col v-if="totalPages > 1">
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="itemsPerPage"
          align="right"
          @change="changePage"
          aria-controls="custom-table"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// TODO: check logic
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';
import Details from './Details';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    Details
  },
  data() {
    return {
      selected: {},
      isLoading: false,
      fields: [
        { key: 'name', label: 'Program', sortable: true },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        }
      ],
      filterOn: [],
      page: 1,
      itemsPerPage: 15,
      sort: {
        sortBy: 'createdAt',
        sortDesc: false
      },
      filter: null,
      debouncedTimeout: null
    };
  },
  computed: {
    ...mapGetters('auth', ['isRole', 'getUser', 'getCompany']),
    ...mapFields('program/list', {
      error: 'error',
      items: 'items',
      view: 'view',
      totalItems: 'totalItems',
      listIsLoading: 'isLoading'
    }),
    selectedIds() {
      let selected = [];
      for (let s in this.selected) {
        if (this.selected[s]) {
          selected.push(s);
        }
      }
      return selected;
    },
    getCompanyId() {
      return this.getUser.company.entity
        ? this.getUser.company.entity.company['@id']
        : '';
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    request() {
      let request = `programs?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status[]=ACCEPTED&status[]=EXECUTED&status[]=INVOICED&status[]=COMPLETED`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },
  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    debounceRefresh() {
      if (this.debouncedTimeout) {
        clearTimeout(this.debouncedTimeout);
      }
      this.debouncedTimeout = setTimeout(() => {
        this.changePage(1);
      }, 1000);
    },
    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },
    refreshData() {
      this.getPage(this.request);
    },
    onRowSelected(item) {
      this.$set(item, 'selected', !item.selected);
    },
    onSubrowSelected({ item, selected }) {
      this.$set(this.selected, item['@id'], selected);
    },
    createInvoices() {
      this.isLoading = true;
      fetch(`${BUSINESS_SERVICE_ENTRYPOINT}/invoiceables/merge`, {
        method: 'POST',
        body: JSON.stringify(this.selectedIds)
      })
        .then((response) => response.json())
        .then((data) => {
          this.refreshData();
          SwalAlert({
            title: 'Invoices created',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'Form another',
                type: 'warning',
                callback: () => {
                  this.$router.push('/administration/invoices');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });
        })
        .catch((error) => {
          this.errors = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    }
  },
  watch: {
    sort: {
      handler() {
        this.handleSortChange(this.sort);
      },
      deep: true
    }
  }
};
</script>

<style lang="scss">
.administrationInvoiceable {
  tbody tr {
    td {
      color: #888888;
    }
    &:hover > td,
    &.b-table-has-details > td,
    &.b-table-details > td {
      color: black;
      background-color: $gray-500;
    }
  }
}
.customTable {
  .input-group-text {
    background-color: $white;
    border-right: 0px solid $white;
  }

  th {
    border-top: none !important;
    height: 55px !important;
    line-height: 55px !important;
  }

  .form-control {
    border-left: 0px solid $white;
  }

  .table,
  .table-sm {
    // font-size: 0.85rem !important;

    th {
      color: $gray-800;
    }

    td {
      color: $gray-500;
    }
  }

  .table.b-table > thead > tr > [aria-sort='ascending'],
  .table.b-table > tfoot > tr > [aria-sort='ascending'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' opacity='0' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
    color: $gray-500;
  }

  .table.b-table > thead > tr > [aria-sort='descending'],
  .table.b-table > tfoot > tr > [aria-sort='descending'],
  .table.b-table > thead > tr > [aria-sort='none'],
  .table.b-table > tfoot > tr > [aria-sort='none'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='black' opacity='0' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='black' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e");
  }

  .page-info {
    color: $gray-500;
  }

  .page-item .page-link {
    background-color: $white;
    color: $gray-500;
    border-color: $gray-200;
  }

  .page-item.disabled .page-link {
    background-color: $white;
    color: $gray-400;
    border-color: $gray-200;
  }

  .page-item.active .page-link {
    background-color: $gray-200;
    color: $gray-500;
    border-color: $gray-200;
  }
}
</style>
