<template>
  <b-container fluid>
    <b-row class="mt-5">
      <b-col cols="12">
        <b-nav tabs class="flex-nowrap navbar">
          <b-nav-item active-class="active" to="/programs/proposals">
            <div class="pl-4 pr-4">
              <h5>Proposals</h5>
            </div>
          </b-nav-item>
          <b-nav-item active-class="active" to="/programs/schedule">
            <div class="pl-4 pr-4">
              <h5>Schedules</h5>
            </div>
          </b-nav-item>
          <b-nav-item active-class="active" to="/programs/execute">
            <div class="pl-4 pr-4">
              <h5>Executions</h5>
            </div>
          </b-nav-item>

          <b-nav-item
            class="ml-auto"
            active-class="active"
            to="/programs/cancelled"
          >
            <h5>Cancelled</h5>
          </b-nav-item>

          <b-nav-item active-class="active" to="/programs/completed">
            <h5>Archived</h5>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <div class="bgContent pt-4">
          <router-view></router-view>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'Goverment'
};
</script>

<style scoped lang="scss">
.navbar {
  overflow-y: hidden;
  overflow-x: auto;
  padding: 0;
}
.nav-tabs .nav-link.active {
  box-shadow: 1px 3px 10px $gray-400;
}

.nav-tabs .nav-link {
  box-shadow: 1px 3px 10px $gray-300;
}

.nav-link {
  padding: 10px 20px;
}

.nav-tabs {
  border-bottom: 0;
  margin-left: -1px;
}
</style>
