<template>
  <div>
    <b-button
      class="mt-3 float-right"
      @click="openModal"
      variant="primary"
      :size="size"
    >
      <i class="fas fa-plus"></i> {{ content.title }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Button',

  props: {
    content: {
      type: Object,
      required: true
    },
    size: {
      type: String,
      required: true
    }
  },

  methods: {
    openModal() {
      this.$bvModal.show(this.content.modalType);
    }
  }
};
</script>
