export const AUTH_CHANGE_CONTEXT = 'AUTH_CHANGE_CONTEXT';
export const AUTH_LOGGED_OUT = 'AUTH_LOGGED_OUT';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const IMPERSONATE_IN = 'IMPERSONATE_IN';
export const IMPERSONATE_OUT = 'IMPERSONATE_OUT';
export const EXPIRED_LOGOUT = 'EXPIRED_LOGOUT';

export const mutations = {
  AUTH_CHANGE_CONTEXT(state, payload) {
    state.user.role = payload.role;
    state.user.company = payload.company;
  },
  AUTH_LOGGED_OUT(state, payload) {
    state.token = '';
    state.user = {};
  },
  AUTH_SUCCESS(state, payload) {
    state.token = payload.token;
    state.user = payload.user;
  },
  IMPERSONATE_IN(state, payload) {
    // Store old
    state.impersonator.token = { ...state.token };
    state.impersonator.user = { ...state.user };

    // Update new
    // state.token = payload.token;
    // state.user = payload.user;
  },
  IMPERSONATE_OUT(state, payload) {
    // Update new
    state.token = { ...state.impersonator.token };
    state.user = { ...state.impersonator.user };

    // Remove old
    state.impersonator.token = {};
    state.impersonator.user = {};
  },
  EXPIRED_LOGOUT(state, payload) {
    state.sessionExpired = payload;
  }
};
