export const LEARNINGACTIVITYSUBCONTRACTOR_CREATE_RESET =
  'LEARNINGACTIVITYSUBCONTRACTOR_CREATE_RESET';
export const LEARNINGACTIVITYSUBCONTRACTOR_CREATE_SET_CREATED =
  'LEARNINGACTIVITYSUBCONTRACTOR_CREATE_SET_CREATED';
export const LEARNINGACTIVITYSUBCONTRACTOR_CREATE_SET_ERROR =
  'LEARNINGACTIVITYSUBCONTRACTOR_CREATE_SET_ERROR';
export const LEARNINGACTIVITYSUBCONTRACTOR_CREATE_SET_VIOLATIONS =
  'LEARNINGACTIVITYSUBCONTRACTOR_CREATE_SET_VIOLATIONS';
export const LEARNINGACTIVITYSUBCONTRACTOR_CREATE_TOGGLE_LOADING =
  'LEARNINGACTIVITYSUBCONTRACTOR_CREATE_TOGGLE_LOADING';
