export const RESET = 'PROSPECT_NOTES_LIST_RESET';
export const SET_ITEMS = 'PROSPECT_NOTES_COLLECTION_NOTES_LIST_SET_ITEMS';
export const SET_ERROR = 'PROSPECT_NOTES_COLLECTION_NOTES_LIST_SET_ERROR';
export const SET_SELECT_ITEMS =
  'PROSPECT_NOTES_COLLECTION_NOTES_LIST_SET_SELECT_ITEMS';
export const SET_VIEW = 'PROSPECT_NOTES_COLLECTION_NOTES_LIST_SET_VIEW';
export const SET_TOTAL_ITEMS =
  'PROSPECT_NOTES_COLLECTION_NOTES_LIST_SET_TOTAL_ITEMS';
export const TOGGLE_LOADING =
  'PROSPECT_NOTES_COLLECTION_NOTES_LIST_TOGGLE_LOADING';
