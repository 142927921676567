<template>
  <div>
    <b-row align-h="end">
      <b-col sm="4" md="6" v-if="showTitle">
        <h3 class="page-title mb-4 mt-1 pl-3">
          <slot name="title"></slot>
        </h3>
      </b-col>
      <b-col col sm="8" md="6" class="d-flex">
        <b-col col>
          <b-input-group class="mb-2" v-if="showSearchInput">
            <b-input-group-prepend is-text>
              <i class="fa fa-search"></i>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search..."
              v-model="filter"
              debounce="500"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="4" v-if="showPerPage" class="per-page pr-2 ml-2">
          <b-form-group>
            <b-form-radio-group
              id="itemsPerPage"
              v-model="perPage"
              :options="optionItemsPerPage"
              buttons
              size="sm"
              button-variant="outline-secondary"
              name="radio-btn-outline"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-col>
    </b-row>
    <b-row align-h="end" class="mb-2 mr-3">
      <slot name="buttons"></slot>
    </b-row>
    <b-container fluid>
      <b-table
        @sort-changed="handleSortChange"
        :fields="fields"
        :items="items"
        :per-page="perPage"
        responsive
        show-empty
        :busy="isLoading"
        @row-clicked="clickedRow"
        :hover="hover"
        no-local-sorting
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
          </div>
        </template>

        <template #head(checked)="data">
          <b-form-checkbox
            id="checkbox"
            v-model="checkAll"
            size="lg"
            :checked="true"
            :unchecked-value="false"
          ></b-form-checkbox>
        </template>

        <template
          v-for="slot in Object.keys($scopedSlots)"
          v-slot:[toCellName(slot)]="props"
        >
          <slot v-bind="props" :name="slot" />
        </template>
        <template #empty>
          <b-col class="text-center"> There are no Records to show yet </b-col>
        </template>
      </b-table>

      <b-row class="pagination" v-if="currentPage <= totalPages">
        <b-col>
          <p class="pagination__current">
            Page {{ currentPage }} of {{ totalPages }}
          </p>
        </b-col>
        <b-col v-if="totalPages > 1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            align="right"
            aria-controls="custom-table"
          ></b-pagination>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'CustomTable',

  props: {
    fields: {
      type: Array,
      default: () => []
    },

    list: {
      type: String,
      default: ''
    },

    values: {
      type: Array,
      default: null
    },

    showSearchInput: {
      type: Boolean,
      default: false
    },

    showTitle: {
      type: Boolean,
      default: false
    },

    showPerPage: {
      type: Boolean,
      default: false
    },

    valuesLoading: {
      type: Boolean,
      default: false
    },

    hover: {
      type: Boolean,
      default: false
    },

    perPage: {
      type: Number,
      default: 25
    },

    currentPage: {
      type: Number,
      default: 1
    }
  },

  computed: {
    dataList() {
      return this.list ? eval(`${this.query}.list`) : null;
    },
    query() {
      let string = 'this.$store.state';
      this.list.split('/').forEach((s) => {
        string += `['${s}']`;
      });

      return string;
    },

    items() {
      return this.values || this.dataList.items;
    },

    isLoading() {
      return !this.list ? false : this.dataList.isLoading;
    },

    totalPages() {
      return Math.ceil(this.totalItems / this.perPage) || 0;
    },

    totalItems() {
      return !this.list ? this.values.length : this.dataList.totalItems;
    },

    tableIsSorted() {
      const sort = localStorage.getItem('currentTable');

      if (sort) {
        return JSON.parse(sort);
      }

      return '';
    }
  },

  data() {
    return {
      filter: '',
      checkAll: false,
      optionItemsPerPage: [10, 20, 50]
    };
  },

  methods: {
    toCellName(slot) {
      return `cell(${slot})`;
    },

    handleSortChange(sort) {
      this.$emit('handleSortChange', sort);
    },

    clickedRow(item) {
      this.$emit('rowClicked', item);
    }
  },

  watch: {
    currentPage: function () {
      this.$emit('changePage', this.currentPage);
    },

    filter: function () {
      this.$emit('changeFilter', this.filter);
    },

    checkAll: function () {
      this.$emit('checkAll', this.checkAll);
    }
  }
};
</script>

<style lang="scss">
.pagination {
  padding: 30px 0px;

  &__current {
    color: #abadb6;
    font-weight: lighter;
  }
}

.table-hover {
  cursor: pointer;
}

.per-page {
  .btn {
    color: rgba($color: gray, $alpha: 0.7);
    border-color: rgba($color: gray, $alpha: 0.4);
    font-size: smaller !important;
    padding: 6px 10px !important;
    border-radius: 0 !important;
    &.active {
      border-color: rgba($color: gray, $alpha: 0.4) !important;
      color: gray !important;
      background-color: rgba($color: gray, $alpha: 0.4) !important;
    }
    &:hover {
      border-color: rgba($color: gray, $alpha: 0.5) !important;
      background-color: rgba($color: gray, $alpha: 0.5) !important;
    }
    &:nth-child(1) {
      border-top-left-radius: 8px !important;
      border-bottom-left-radius: 8px !important;
    }
    &:nth-last-child(1) {
      border-top-right-radius: 8px !important;
      border-bottom-right-radius: 8px !important;
    }
  }
}
</style>
