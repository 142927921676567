<template>
  <b-container fluid>
    <h3 class="title">
      <i class="fa fa-euro-sign text-primary mr-2" /> Profit Shares
    </h3>

    <b-row align-h="end">
      <b-button
        v-if="!isLoading"
        class="text-right m-3"
        variant="primary"
        @click="createPayments"
        :disabled="selectedIds.length === 0"
        >Emburse Selected Shares</b-button
      >
      <b-button v-else class="text-right m-3" variant="primary" disabled>
        <b-spinner small />
      </b-button>
    </b-row>

    <b-row>
      <b-table
        :fields="fields"
        :items="items"
        :busy="listIsLoading"
        :sort-by.sync="sort.sortBy"
        :sort-desc.sync="sort.sortDesc"
        class="customTable mt-3 administrationInvoiceable"
        select-mode="single"
        small
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>

        <template #cell(name)="row">
          <span
            @click="row.toggleDetails"
            class="d-flex justify-content-between"
          >
            <span>{{ row.item.name }}</span>
            <b-spinner v-if="row.item.openLoading" small></b-spinner>
            <i v-else-if="row.detailsShowing" class="fa fa-caret-down" />
            <i v-else class="fa fa-caret-right" />
          </span>
        </template>

        <template #row-details="row">
          <Details :program="row.item" @selected="onSubrowSelected"></Details>
        </template>
      </b-table>
    </b-row>
    <b-row class="pagination" v-if="page <= totalPages">
      <b-col>
        <p class="pagination__current">Page {{ page }} of {{ totalPages }}</p>
      </b-col>
      <b-col v-if="totalPages > 1">
        <b-pagination
          v-model="page"
          :total-rows="totalItems"
          :per-page="itemsPerPage"
          align="right"
          @change="changePage"
          aria-controls="custom-table"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// TODO: check logic
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';
import Details from './Details';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    Details
  },
  data() {
    return {
      selected: {},
      isLoading: false,
      fields: [
        { key: 'name', label: 'Program', sortable: true },
        { key: 'program', label: '' },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'actions',
          label: ''
        }
      ],
      page: 1,
      itemsPerPage: 15,
      filterOn: [],
      sort: {
        sortBy: 'createdAt',
        sortDesc: false
      }
    };
  },
  computed: {
    ...mapGetters('auth', ['isRole', 'getUser', 'getCompany', 'getCompanyId']),
    ...mapFields('program/list', {
      error: 'error',
      items: 'items',
      view: 'view',
      totalItems: 'totalItems',
      listIsLoading: 'isLoading'
    }),
    selectedIds() {
      let selected = [];
      for (let s in this.selected) {
        if (this.selected[s]) {
          selected.push(s);
        }
      }
      return selected;
    },
    request() {
      let request = `programs?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status[]=INVOICED&status[]=COMPLETED`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    }
  },
  created() {
    this.refreshData();
  },
  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),
    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },
    onRowSelected(item) {
      this.$set(item, 'selected', !item.selected);
    },
    onSubrowSelected({ item, selected }) {
      this.$set(this.selected, item['@id'], selected);
    },
    createPayments() {
      this.isLoading = true;
      fetch(`${BUSINESS_SERVICE_ENTRYPOINT}/payment/pay`, {
        method: 'POST',
        body: JSON.stringify({
          amount: 1,
          shareables: this.selectedIds,
          collection_notes: []
        })
      })
        .then((response) => response.json())
        .then((data) => {
          this.refreshData();
          SwalAlert({
            title: 'Payments created',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'View payments',
                type: 'warning',
                callback: () => {
                  this.$router.push('/payments/incoming');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {
                  return;
                }
              }
            }
          });
        })
        .catch((error) => {
          this.errors = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    }
  },
  watch: {
    sort: {
      handler() {
        this.handleSortChange(this.sort);
      },
      deep: true
    }
  }
};
</script>
