<template>
  <div>
    <Transition name="slide-fade">
      <div v-show="isEditing">
        <h4>Unshow items</h4>
        <draggable
          v-model="backlist"
          group="items"
          @start="drag = true"
          @end="drag = false"
          :class="{ [`cursor-grabbing`]: drag === true }"
          class="unshow-items d-flex flex-wrap mt-2"
          @change="changeBacklist"
        >
          <div
            v-for="(item, index) in backlist"
            :key="index"
            class="content backlist"
            :class="{ editing: isEditing }"
          >
            {{ item.title }}
          </div>
        </draggable>
      </div>
    </Transition>
    <draggable
      :class="{ [`cursor-grabbing`]: drag === true }"
      v-model="orderComponents"
      group="items"
      @start="drag = true"
      @end="drag = false"
      class="show-items d-md-flex flex-wrap mt-4"
      :disabled="!isEditing"
    >
      <component
        v-for="(component, index) in orderComponents"
        :key="index"
        :is="isEditing ? 'dash-editing' : component.component"
        :class="computedClass(component)"
        :company="company"
        :companyIsLoading="companyIsLoading"
        :refreshData="refreshData"
        :isEditing="isEditing"
        :emitRemove="remove"
        :title="component.title"
        :permanent="component.permanent"
        :position="component.position"
      >
      </component>
    </draggable>
  </div>
</template>
<script>
import DashStatusChecker from '@/components/dashboard/dash-components/Status';
import DashActions from '@/components/dashboard/dash-components/Actions';
import DashHandshakes from '@/components/dashboard/dash-components/Handshakes';
import DashEditing from '@/components/dashboard/dash-components/Editing';
import { entityService } from '@/utils/fetch';
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable';

export default {
  name: 'Dashboard',

  components: {
    draggable,
    DashStatusChecker,
    DashActions,
    DashEditing,
    DashHandshakes
  },
  props: {
    isEditing: {
      type: Boolean
    }
  },
  data() {
    return {
      companyIsLoading: true,
      company: null,
      drag: false,
      backlist: [],
      listComponents: [
        {
          originalPos: 0,
          position: 0,
          component: 'dash-status-checker',
          permanent: true,
          title: 'Your Status Checker'
        },
        {
          originalPos: 1,
          position: 1,
          component: 'dash-actions',
          title: 'Company Actions'
        },
        {
          originalPos: 2,
          position: 2,
          component: 'dash-handshakes',
          title: 'Handshakes'
        }
      ]
    };
  },
  created() {
    const list = localStorage.getItem('dashboard-components');
    const backlist = localStorage.getItem('dashboard-backlist');

    if (list) {
      this.listComponents = JSON.parse(list);
      this.backlist = JSON.parse(backlist);
    }

    this.refreshData();
  },
  computed: {
    ...mapGetters('auth', ['hasCompany', 'getCompanyId']),

    orderComponents: {
      get: function () {
        return this.listComponents;
      },
      set: function (value) {
        // If the item have the prop permenant put it on its original position and then the rest of the items
        for (let index = 0; index < value.length; index++) {
          if (value[index].permanent) {
            // insert the item on the original position
            value.splice(
              value[index].originalPos,
              0,
              // take the item on position
              value.splice(index, 1)[0]
            );
          }
        }

        // populate each position to each item
        for (let index = 0; index < value.length; index++) {
          value[index].position = index;
        }

        this.listComponents = value;
      }
    }
  },
  watch: {
    isEditing: function () {
      if (this.isEditing == false)
        localStorage.setItem(
          'dashboard-components',
          JSON.stringify(this.listComponents)
        );
      localStorage.setItem('dashboard-backlist', JSON.stringify(this.backlist));
    }
  },
  methods: {
    computedClass(component) {
      return `col-md-6 ${this.isEditing ? 'editing' : ''}`; // @TODO: Change dynamically width from components
    },
    changeBacklist(event) {
      let item = event.added ? event.added.element : event.remove.element;
      if (event.added && item.permanent) {
        this.backlist.splice(event.added.newIndex, 1)[0];
        this.orderComponents = [...this.listComponents, item];
      }
    },
    remove(permanent, position) {
      if (permanent) return;
      let removed = this.listComponents.splice(position, 1)[0];
      this.backlist.push(removed);
      this.orderComponents = this.listComponents;
    },
    refreshData() {
      localStorage.setItem('currentPage', 1);

      if (!this.hasCompany) {
        this.companyIsLoading = false;
        return;
      }

      // Don't use stores for this one
      this.companyIsLoading = true;

      // Company data
      entityService(this.getCompanyId)
        .then((response) => response.json())
        .then((data) => {
          this.$set(this, 'company', data);
        })
        .finally(() => {
          this.companyIsLoading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.unshow-items {
  padding: 4px 4px 0 4px;
  min-height: 60px;
  border-radius: 15px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.4);
  .content {
    border-radius: 15px;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 10px 25px;
    background: #fff;
    border: 2px dashed rgba(0, 0, 0, 0.4);
  }
}

.show-items {
  .component {
    margin-bottom: 1%;
    .content {
      padding: 10px 25px;
      border-radius: 15px;
      background: #fff;
      min-height: auto;
      &.editing {
        border: 2px dashed rgba(0, 0, 0, 0.4);
      }
    }
    @media screen and (min-width: 768px) {
      &:nth-child(odd) {
        padding-left: 0;
      }
      &:nth-child(even) {
        padding-right: 0;
      }
    }
  }
}
.slide-fade-enter {
  opacity: 0 !important;
  height: 0 !important;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.6s ease-in;
  height: 100px;
  overflow: auto;
}

.slide-fade-leave-active {
  transition: all 0.6s ease-out;
  height: 100px;
  overflow: auto;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  height: 0;
  overflow: hidden;
}
</style>
