<template>
  <div class="component" v-if="company">
    <div class="content shadow-sm">
      <div class="d-flex">
        <StatusChecker
          v-if="isRole(['ROLE_PROVIDER'])"
          :statusChecker="getSemaphoreState"
          class="svg"
        />
        <div
          class="header"
          :class="{
            provider: isRole(['ROLE_PROVIDER']),
            'line-bottom': toggleCollapse
          }"
        >
          <div class="title mt-2 pb-4 d-flex justify-content-between">
            <div>
              <h3 class="title">Your Status Checker</h3>
              <span class="sub-title">
                Why is this your's status ? <a href="#">check here</a>
                <!--@TODO: Link to view details about this status checker-->
              </span>
            </div>
            <div class="actions">
              <button
                class="btn btn-transparent"
                @click="toggleCollapse = !toggleCollapse"
              >
                <i v-if="toggleCollapse" class="fa fa-chevron-up" />
                <i v-else class="fa fa-chevron-down" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <b-collapse v-model="toggleCollapse">
        <Status
          v-if="!companyIsLoading"
          :company="company"
          @refreshData="refreshData"
        />
      </b-collapse>
    </div>
  </div>
</template>
<script>
import Status from '@/components/dashboard/widgets/Status';
import StatusChecker from '@/components/_ui/svg/StatusChecker';
import { mapGetters } from 'vuex';

export default {
  name: 'DashStatusChecker',
  components: {
    Status,
    StatusChecker
  },
  data() {
    return {
      toggleCollapse: true
    };
  },

  computed: {
    ...mapGetters('auth', ['isRole']),

    getSemaphoreState() {
      return this.company?.semaphoreState;
    }
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    companyIsLoading: {
      type: Boolean,
      default: true
    },
    refreshData: {
      type: Function
    }
  }
};
</script>
<style lang="scss">
.component {
  .header {
    width: 100%;
    &.line-bottom {
      border-bottom: 2px solid rgb(57, 57, 57);
    }

    &.provider {
      padding-left: 60px;
    }
  }
  .svg {
    width: 50px;
    position: absolute;
    top: -10px;
    z-index: 10;
  }
}
</style>
