<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="handshake"
      @changePage="changePage"
    >
      <template v-slot:metadata="data">
        <div v-if="data.item.metadata">
          <p v-if="data.item.metadata.accountant">
            <strong>Type: </strong>{{ data.item.metadata.accountant.type }}
          </p>
          <p v-if="data.item.metadata.accountant">
            <strong>Number: </strong>{{ data.item.metadata.accountant.number }}
          </p>
        </div>
      </template>

      <template v-slot:actions="data">
        <div>
          <span
            v-if="
              data.item['status'] === 'PENDING' ||
              (data.item['type'] !== 'ImportedCustomersHandshake' &&
                disableActions)
            "
            class="handshake__check"
            @click="goToView(data.item)"
          >
            <a
              @click.prevent="goToView(data.item)"
              v-b-tooltip.hover
              title="Edit"
            >
              <i class="fas fa-eye text-info"></i>
            </a>
          </span>
        </div>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';

export default {
  name: 'Handshakes',

  components: {
    CustomTable
  },

  mixins: [helpers],

  props: {
    handshakeUrl: {
      type: String,
      default: ''
    },
    activeFields: {
      type: Array,
      default: () => []
    },
    disableActions: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId', 'getRole', 'getCompany']),

    fields() {
      const array = [];

      this.fieldsList.forEach((field) => {
        if (this.activeFields.includes(field.key)) {
          array.push(field);
        }
      });

      return array;
    },
    request() {
      let request = this.handshakeUrl;
      request += `?page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      fieldsList: [
        {
          key: 'question.question',
          label: 'Question',
          sortable: true
        },
        {
          key: 'answer',
          label: 'Answer',
          sortable: true
        },
        {
          key: 'user.firstName',
          label: 'First Name',
          sortable: true
        },
        {
          key: 'user.lastName',
          label: 'Last Name',
          sortable: true
        },
        {
          key: 'user.email',
          label: 'Email Address',
          sortable: true
        },
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'company.name',
          label: 'Company',
          sortable: true
        },
        {
          key: 'user.email',
          label: 'User',
          sortable: true
        },
        {
          key: 'metadata',
          label: 'Metadata',
          sortable: false
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      sort: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  methods: {
    ...mapActions({
      getHandshakes: `handshake/list/default`
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    handshakeShow(id) {
      this.$router.push({
        name: 'HandshakesList',
        params: { disableActions: this.disableActions, id: this.iriToId(id) }
      });
    },

    goToImportedCustomersView(handshake) {
      this.$router.push({
        name: 'ToolboxImportedRelations',
        params: {
          companyId: handshake.company['@id'],
          handshakeId: handshake['@id'],
          handshakeStatus: handshake.status
        }
      });
    },

    goToInvoicesView(handshake) {
      this.$router.push({
        name: 'ToolboxImportedInvoices',
        params: {
          companyId: handshake.company['@id'],
          handshakeId: handshake['@id'],
          handshakeStatus: handshake.status
        }
      });
    },

    goToView(item) {
      if (item['type'] === 'ImportedCustomersHandshake') {
        this.goToImportedCustomersView(item);
      } else if (item['type'] === 'InvoicesHandshake') {
        this.goToInvoicesView(item);
      } else {
        this.handshakeShow(item['@id']);
      }
    }
  }
};
</script>

<style lang="scss">
.handshake {
  &__accept {
    cursor: pointer;

    .fas {
      color: $success;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__reject {
    cursor: pointer;

    .fas {
      color: $danger;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__check {
    cursor: pointer;

    .fas {
      color: $primary;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
