<template>
  <div class="general-information">
    <b-form class="py-2">
      <b-form-group label="Duplicates" label-for="duplicates">
        <table class="table">
          <tr>
            <th width="60%">Name</th>
            <th width="40%">Origin</th>
          </tr>

          <tr
            v-for="(duplicate, indexDuplicate) in item.duplicates"
            :key="indexDuplicate"
          >
            <td>
              {{ duplicate.name }}
            </td>
            <td>
              {{ duplicate.origin }}
            </td>
          </tr>
        </table>
      </b-form-group>

      <b-form-group label="Add duplicate" label-for="duplicates">
        <v-select
          :options="prospectCleanupList"
          v-model="prospectSearch"
          label="name"
          multiple
          @search="onSearch"
          :class="{ spinner: prospectIsLoading }"
          required
        >
          <template slot="no-options"> Type to search... </template>
        </v-select>
      </b-form-group>

      <b-form-group>
        <CustomButton
          class="float-left"
          type="Default"
          :content="{ title: 'Prev' }"
          @click.native="$emit('step', 1)"
        />

        <template v-if="!isCreating">
          <CustomButton
            class="float-right"
            type="Default"
            :content="{ title: 'Next' }"
            @click.native="next"
          />
        </template>

        <template v-else>
          <b-button
            class="float-right mt-3"
            type="button"
            variant="primary"
            @click="onSendForm"
            v-if="!isLoading"
            >Submit</b-button
          >
          <b-button class="float-right mt-3" type="button" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </template>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { debounce } from 'lodash';
import { entityService } from '@/utils/fetch';
import CustomButton from '@/components/_ui/CustomButton';

export default {
  name: 'Duplicates',

  components: {
    CustomButton
  },

  props: {
    initialValues: {
      type: Object,
      default: () => {}
    },

    isCreating: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields('prospects/list', {
      prospectList: 'items',
      prospectIsLoading: 'isLoading'
    }),

    item() {
      return this.initialValues || this.form;
    },

    prospectCleanupList() {
      return this.availableProspectItems.filter((item) => {
        return item['@id'] !== this.item['@id'];
      });
    }
  },

  data() {
    return {
      form: {
        duplicates: []
      },
      prospectSearch: [],
      availableProspectItems: [],
      prospectOptionsIsLoading: false
    };
  },

  methods: {
    ...mapActions({
      getProspects: 'prospects/list/default'
    }),

    onSearch: debounce(function (name) {
      this.prospectOptionsIsLoading = true;
      entityService(`/prospects?exists[duplicated]=false&search=${name}`)
        .then((response) => response.json())
        .then((data) => {
          this.availableProspectItems = data['hydra:member'];
        })
        .finally(() => {
          this.prospectOptionsIsLoading = false;
        });
    }, 500),

    next() {
      this.item.duplicates = this.prospectSearch.map((prospect) => {
        return prospect['@id'];
      });

      this.$emit('updateData', this.item);
      this.$emit('step', 3);
    },

    onSendForm() {
      this.$emit('updateData', this.item);
      this.$emit('onSendForm');
    }
  }
};
</script>
