<template>
  <b-form class="mt-3" @submit.prevent="onSendForm()">
    <b-form-group label="Company name" label-for="name">
      <b-form-input
        id="name"
        v-model="computedCompany.name"
        type="text"
        :disabled="disabled"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Company tradename" label-for="tradename">
      <b-form-input
        id="tradename"
        v-model="computedCompany.tradename"
        type="text"
        :disabled="disabled"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="VAT Number" label-for="vat">
      <b-form-input
        id="vat"
        v-model="computedCompany.fiscal_id"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Chamber of Commerce [optional]"
      label-for="chamberOfCommerce"
    >
      <b-form-input
        id="chamberOfCommerce"
        v-model="computedCompany.chamberOfCommerce"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="RII Reference"
      label-for="riiReference"
      v-if="isRole('ROLE_BAILIFF') || isRole('ROLE_ADMIN')"
    >
      <b-form-input
        id="riiReference"
        v-model="computedCompany.RIIReference"
        type="text"
        readonly
      >
      </b-form-input>
    </b-form-group>

    <b-form-group label="Sector *" label-for="company_sector">
      <b-form-select v-if="sectorIsLoading" disabled value="">
        <b-form-select-option selected value=""
          >Loading...</b-form-select-option
        >
      </b-form-select>

      <b-form-select
        :disabled="disabled"
        v-else
        id="company_sector"
        v-model="item.sector"
        :options="sectorItems"
        text-field="name"
        value-field="@id"
      >
      </b-form-select>
    </b-form-group>

    <b-row>
      <b-col cols="6">
        <b-form-group label="Email Address" label-for="email">
          <b-form-input
            id="email"
            v-model="computedCompany.email"
            type="text"
            :disabled="disabled"
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Phone" label-for="phone">
          <b-form-input
            id="phone"
            v-model="computedCompany.phone"
            type="text"
            :disabled="disabled"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group
      label="Pays VAT"
      label-for="requiresVATPayment"
      v-if="computedCompany.isAmplifica && isRole('ROLE_ADMIN')"
    >
      <b-form-checkbox
        id="requiresVatPayment"
        switch
        v-model="item.requiresVATPayment"
        :unchecked-value="false"
        :value="true"
      />
    </b-form-group>

    <b-form-group
      label="Pays Income Taxes"
      label-for="profitTaxPercentage"
      v-if="computedCompany.isAmplifica"
    >
      <b-form-input
        id="profitTaxPercentage"
        v-model="computedCompany.profitTaxPercentage"
        type="text"
      >
      </b-form-input>
    </b-form-group>

    <template v-if="isCreatingBranch">
      <b-form-group label="Branches Percentage" label-for="profitTaxPercentage">
        <b-form-input
          id="profitTaxPercentage"
          v-model="item.percentage"
          type="text"
        >
        </b-form-input>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Branch Start Date" label-for="branch_start">
              <b-form-datepicker
                id="branch_start"
                v-model="item.startDate"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Branch End Date" label-for="branch_end">
              <b-form-datepicker
                id="branch_end"
                v-model="item.endDate"
                branchStartDate
                required
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>
    </template>

    <div class="float-right mt-5" v-if="!disabled">
      <b-button
        type="button"
        variant="secondary mr-2"
        :disabled="isLoading"
        @click="$bvModal.hide('editCompany')"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Save</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </div>

    <div class="float-right mt-5" v-else>
      <b-button
        type="button"
        variant="secondary mr-2"
        @click="$bvModal.hide('editCompany')"
        >Close</b-button
      >
    </div>
  </b-form>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'Roles',

  props: {
    company: {
      type: Object,
      required: true
    },

    shareholder: {
      type: Object,
      required: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isCreatingBranch: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    ...mapGetters('auth', ['isRole']),

    ...mapFields('company/update', {
      isLoading: 'isLoading',
      updated: 'updated'
    }),
    ...mapFields('sector/list', {
      sectorItems: 'selectItems',
      sectorIsLoading: 'isLoading'
    }),

    ...mapFields('branches/update', {
      isLoading: 'isLoading',
      updated: 'updated',
      error: 'error'
    }),

    computedCompany() {
      return this.isCreatingBranch ? this.shareholder.company : this.company;
    }
  },

  data() {
    return {
      item: {
        name: null,
        tradename: null,
        email: null,
        phone: null,
        sector: null,
        percentage: null,
        startDate: null,
        endDate: null
      }
    };
  },

  created() {
    this.item.name = this.company.name;
    this.item.tradename = this.company.tradename;
    this.item.email = this.company.email;
    this.item.phone = this.company.phone;
    this.item.requiresVATPayment = this.company.requiresVATPayment;
    this.item.profitTaxPercentage = parseFloat(
      this.company.profitTaxPercentage
    );

    if (this.computedCompany.sector) {
      this.item.sector = this.computedCompany.sector['@id'];
    }

    if (this.isCreatingBranch) {
      this.item.percentage = this.shareholder.percentage;
      this.item.startDate = this.shareholder.startDate;
      this.item.endDate = this.shareholder.endDate;
    }
  },

  methods: {
    ...mapActions({
      updateCompany: 'company/update/update',
      updateShareholder: 'branches/update/update'
    }),

    onSendForm() {
      this.item.profitTaxPercentage = parseFloat(this.item.profitTaxPercentage);
      if (this.isCreatingBranch) {
        let shareholder = this.shareholder;
        shareholder.percentage = parseInt(this.item.percentage);
        shareholder.startDate = this.item.startDate;
        shareholder.endDate = this.item.endDate;
        shareholder.company = this.computedCompany;
        this.updateShareholder(shareholder);
      } else {
        this.updateCompany(this.computedCompany);
      }
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated');
    },
    // eslint-disable-next-line object-shorthand,func-names
    error: function (error) {
      if (!error) {
        return;
      }
      SwalAlert({
        title: 'Error',
        description: error,
        icon: 'error',
        type: 'danger',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'danger',
            callback: () => {}
          }
        }
      });
    }
  }
};
</script>
