<template>
  <div>
    <b-row class="mt-4">
      <b-nav tabs>
        <b-nav-item class="mr-2" active-class="active" exact to="/my/company">
          Overview
        </b-nav-item>
        <b-nav-item
          class="mr-2"
          active-class="active"
          to="/my/company/employees"
        >
          Employees
        </b-nav-item>
        <b-nav-item
          class="mr-2"
          active-class="active"
          to="/my/company/financial"
        >
          Financial
        </b-nav-item>
        <b-nav-item
          class="mr-2"
          active-class="active"
          to="/my/company/structure"
        >
          Company Structure
        </b-nav-item>
      </b-nav>

      <router-view />
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Company',

  computed: {
    ...mapGetters('auth', ['isRole'])
  }
};
</script>
<style scoped lang="scss">
.nav-tabs .nav-link.active {
  box-shadow: 1px 3px 10px $gray-400;
}

.nav-tabs .nav-link {
  box-shadow: 1px 3px 10px $gray-300;
}

.nav-link {
  padding: 10px 20px;
}

.nav-tabs {
  border-bottom: 0;
  margin-left: -1px;
}
</style>
