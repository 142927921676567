import ArticleList from '@/components/admin/article/List';
import ArticleCreate from '@/components/admin/article/Create';
import ArticleUpdate from '@/components/admin/article/Update';
import ArticleShow from '@/components/admin/article/Show';

export default [
  { name: 'ArticleList', path: '/articles/', component: ArticleList },
  { name: 'ArticleCreate', path: '/articles/create', component: ArticleCreate },
  {
    name: 'ArticleUpdate',
    path: '/articles/edit/:id',
    component: ArticleUpdate
  },
  { name: 'ArticleShow', path: '/articles/show/:id', component: ArticleShow }
];
