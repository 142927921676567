<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <TeacherForm
      :activity="activity"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import TeacherForm from './Form';
import helpers from '../../../../mixins/helpers';

const { mapFields } = createHelpers({
  getterType: 'teacher/create/getField',
  mutationType: 'teacher/create/updateField'
});

export default {
  components: {
    TeacherForm
  },

  props: {
    activity: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      item: {}
    };
  },

  mixins: [helpers],

  mounted() {
    this.reset();
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId']),
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createTeacher');
    }
  },

  methods: {
    ...mapActions('teacher/create', ['create', 'reset']),

    onSendForm() {
      this.item.learningActivity = this.activity['@id'];
      // send company
      if (!this.item.company) {
        this.item.company = this.getCompanyId;
      }
      //clean
      this.create(this.item);
    }
  }
};
</script>
