<template>
  <b-row>
    <b-col cols="7">
      <div v-if="errors" class="alert alert-danger">{{ errors }}</div>
      <h1 class="mb-4 text-primary">Modules & Activities</h1>
      <hr class="mb-4" />

      <b-form>
        <!--- step 3 --->
        <b-form-row>
          <div class="form-group row col-sm-12">
            <label class="col-sm-6 col-form-label">
              Now we can create Learning modules
            </label>

            <div class="col-sm-6">
              <b-button
                variant="primary"
                v-b-modal.createLearningModule
                class="float-right"
              >
                <i class="fas fa-plus"></i>
                Add Learning module
              </b-button>
            </div>
          </div>
        </b-form-row>

        <!--- end step 3 --->

        <!--- Program structure --->
        <h1 class="mb-3 mt-3 text-primary">
          Program structure, modules & activities
        </h1>
        <hr class="mb-3" />

        <b-form-row v-if="items.length > 0">
          <b-card
            bg-variant="light"
            v-for="(item, idx) in items"
            :key="idx"
            style="max-width: 20rem"
            class="m-2"
          >
            <template #header>
              {{ item.name }}
            </template>
            <b-card-text>
              <ul>
                <li class="font-weight-bold">Learning Activities</li>
                <ul v-for="(activity, idx) in item.activities" :key="idx">
                  <li>{{ activity.title }} ({{ activity.type }})</li>
                </ul>
              </ul>
              <ul>
                <li class="font-weight-bold">Learning Materials</li>
                <ul v-for="(material, idx) in item.materials" :key="idx">
                  <li>
                    {{ material.title }} (<i>by</i> {{ material.author }})
                  </li>
                </ul>
              </ul>
              <ul>
                <li class="font-weight-bold">
                  Pricing Type: {{ item.pricingType }}
                </li>

                <li class="font-weight-bold" v-if="item.price">
                  Price: {{ formatCurrency(item.price) }}
                </li>

                <ul v-if="item.pricingType === 'GROUP'">
                  <li class="font-weight-bold">
                    Learners Per Group: {{ item.learnersPerGroup }}
                  </li>

                  <li class="font-weight-bold">
                    Max Learners: {{ item.maxLearners }}
                  </li>
                </ul>

                <ul v-if="item.pricingType === 'LEARNER'">
                  <li class="font-weight-bold">
                    Max Learners: {{ item.maxLearners }}
                  </li>
                </ul>
              </ul>
            </b-card-text>

            <span class="float-right">
              <span v-if="!deleteIsLoading(item)">
                <b-button
                  @click="edit(item)"
                  href="#"
                  class="mr-2"
                  variant="primary"
                  >Edit</b-button
                >
                <b-button @click="del(item)" href="#" variant="danger"
                  ><i class="fa fa-trash text-info"></i
                ></b-button>
              </span>
              <b-spinner v-else small></b-spinner>
            </span>
          </b-card>
        </b-form-row>
        <b-form-row v-else>
          <b-card
            bg-variant="light"
            style="max-width: 20rem; opacity: 0.4"
            class="m-2"
          >
            <template #header> Create your first module </template>
            <b-card-text>
              Your program structure will show in here
            </b-card-text>
          </b-card>
        </b-form-row>

        <!--- end Program structure --->

        <b-form-row class="mb-4 float-right">
          <b-button
            variant="secondary"
            @click.prevent="handlePrevious"
            class="mr-1"
            :disabled="isLoading"
          >
            Previous
          </b-button>
          <b-button
            variant="primary"
            @click.prevent="handleSaveDraft"
            class="mr-1"
            :disabled="isLoading"
          >
            Save Draft
          </b-button>
          <b-button
            v-if="!isLoading"
            :disabled="items.length === 0"
            variant="success"
            @click.prevent="handleNext"
            class="mr-1"
          >
            Send Proposal
          </b-button>
          <b-button type="submit" variant="success" v-else>
            <b-spinner small></b-spinner>
          </b-button>
          <!--          <button class="btn btn-primary float-right mr-1">Previous</button>-->
        </b-form-row>

        <!-- Add learning module modal -->
        <b-modal
          id="createLearningModule"
          title="Add Learning Module"
          hide-footer
        >
          <template #modal-header>
            <div class="custom-modal__popup">
              <span class="custom-modal__popup__title"
                >Add Learning Module</span
              >
            </div>
            <i
              class="fa fa-times custom-modal__popup__close"
              @click="$bvModal.hide('createLearningModule')"
            ></i>
          </template>

          <LearningModuleCreate
            @created="refreshData"
            :program-id="programId"
          ></LearningModuleCreate>
        </b-modal>
        <!-- end Add learning module modal -->

        <!-- Edit learning module modal -->
        <b-modal
          id="editLearningModule"
          title="Edit Learning Module"
          hide-footer
        >
          <template #modal-header>
            <div class="custom-modal__popup">
              <span class="custom-modal__popup__title"
                >Edit Learning Module</span
              >
            </div>
            <i
              class="fa fa-times custom-modal__popup__close"
              @click="$bvModal.hide('editLearningModule')"
            ></i>
          </template>

          <LearningModuleUpdate
            @updated="refreshData"
            :program-id="programId"
            :learning-module-id="editId"
          ></LearningModuleUpdate>
        </b-modal>
        <!-- end Add learning module modal -->
      </b-form>
    </b-col>

    <b-col cols="5">
      <Pricing
        v-if="programRetrieved"
        :program="programRetrieved"
        :customer="getCustomer"
        :pricing-by-id="true"
        @loading="onPricingLoading"
      ></Pricing>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import LearningModuleCreate from './Create.vue';
import LearningModuleUpdate from './Update.vue';
import { SwalAlert } from '../../../../utils/swal';
import { businessLogicService, entityService } from '../../../../utils/fetch';
import helpers from '@/mixins/helpers';
import Pricing from '../Pricing.vue';

export default {
  components: {
    LearningModuleCreate,
    LearningModuleUpdate,
    Pricing
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getCompany', 'getCompanyType']),

    ...mapFields('program/show', {
      programRetrieved: 'retrieved',
      programIsLoading: 'isLoading'
    }),

    ...mapFields('learningmodule/del', {
      deleted: 'deleted'
    }),
    ...mapFields('learningmodule/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view',
      totalItems: 'totalItems'
    }),

    programId() {
      return `/programs/${this.$route.params.id}`;
    },

    getCustomer() {
      if (this.programRetrieved) {
        return this.programRetrieved.customer;
      }
      return null;
    }
  },

  data() {
    return {
      editId: 0,
      deleting: {},
      pricing: {
        isLoading: false,
        data: {}
      },
      errors: null
    };
  },

  created() {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'ProposalList' });
    } else {
      let id = this.$route.params.id;
      this.getPage(`/programs/${id}/modules`); // Load program too
      this.getProgram(this.programId);
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }
      this.refreshData();
    }
  },

  methods: {
    ...mapActions({
      getProgram: 'program/show/retrieve',
      getPage: 'learningmodule/list/default',
      deleteItem: 'learningmodule/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      let id = this.$route.params.id;
      this.getPage(`/programs/${id}/modules`);
    },

    onPricingLoading(isPricingLoading) {
      this.pricing.isLoading = isPricingLoading;
    },

    handlePrevious() {
      this.$router.push({
        name: 'ProposalUpdate',
        params: { id: this.$route.params.id }
      });
    },
    handleSaveDraft() {
      this.$router.push({ name: 'ProposalList' });
    },
    handleNext() {
      // @TODO(trepatudo): hacky VERIFIED since PROPOSED is skipped for now, this should be done on server-side
      this.isLoading = true;

      businessLogicService(this.programId, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({ statusTransition: 'to_proposed' })
      })
        .then((response) => response.json())
        .then((_data) => {
          if (this.getCompanyType !== 'PERSON') {
            this.autoAcceptProposal();
          } else {
            this.isLoading = false;
            this.$router.push({ name: 'WaitingList' });
            this.proposalSendPopup();
          }
        })
        .catch((error) => {
          this.errors = error.errors._error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    autoAcceptProposal() {
      businessLogicService(this.programId, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({ statusTransition: 'to_accepted' })
      })
        .then((response) => response.json())
        .then((data) => {
          this.isLoading = false;
          this.$router.push({ name: 'ScheduleList' });
          this.proposalSendPopup();
        });
    },

    proposalSendPopup() {
      SwalAlert({
        title: 'Proposal sent',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$router.push({ name: 'ProposalCreate' });
            }
          },
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {}
          }
        }
      });
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this module?')) {
        let itemId = item['@id'];
        this.deleting[itemId] = true;
        this.deleteItem({ '@id': itemId });
      }
    },
    deleteIsLoading(item) {
      return this.deleting.hasOwnProperty(item['@id']);
    },
    edit(item) {
      this.editId = item['@id'];
      this.$bvModal.show('editLearningModule');
    }
    /**
     * Update pricing!
     * @returns {boolean}
     */
  },

  beforeDestroy() {
    this.errors = null;
  }
};
</script>
