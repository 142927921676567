<template>
  <div class="pt-5">
    <b-row class="mb-4 pt-4">
      <b-col cols="12">
        <b-alert
          v-if="handshakeStatus === 'PENDING' && !isRole('ROLE_PROVIDER')"
          variant="danger"
          show
        >
          <h4 class="font-weight-bold">You are reviewing this handshake.</h4>
          <p>
            Once you ACCEPT it, the CREATOR is not able to correct any Financial
            data
          </p>
          <br />

          <b-button
            variant="danger"
            @click="handshakeAcceptOrReject(handshakeId, 'to_rejected')"
            >Reject
          </b-button>

          <b-button
            class="ml-2"
            variant="success"
            @click="handshakeAcceptOrReject(handshakeId, 'to_approved')"
            >Accept
          </b-button>
        </b-alert>
        <div v-else>
          <b-alert
            v-if="importStatus.customer === 'REJECTED'"
            variant="danger"
            show
          >
            <i class="fa fa-exclamation"></i> Please, update your customers data
          </b-alert>
          <b-alert
            v-else-if="importStatus.invoice === 'PENDING'"
            variant="warning"
            show
          >
            <i class="fa fa-clock"></i> Accountant is reviewing your financial
            data
          </b-alert>
          <b-alert
            v-else-if="importStatus.invoice === 'REJECTED'"
            variant="danger"
            show
          >
            <i class="fa fa-exclamation mr-2"></i> Accountant has rejected your
            financial data, please upload the corrected data.
          </b-alert>
          <b-alert
            v-else-if="importStatus.invoice === 'HAS_INVALID_INVOICES'"
            variant="danger"
            show
          >
            <i class="fa fa-exclamation mr-2"></i> There are
            <strong>INVALID</strong> invoices. Please, correct them before
            accountant can review.
          </b-alert>
          <b-alert
            v-else-if="importStatus.invoice === 'ACCEPTED'"
            variant="success"
            show
          >
            <i class="fa fa-check"></i> Accountant has approved your financial
            data.
          </b-alert>
        </div>
      </b-col>
      <b-col
        offset="6"
        cols="6"
        v-if="isRole('ROLE_PROVIDER') && importStatus.invoice !== 'ACCEPTED'"
      >
        <CustomButton
          type="Modal"
          size="md"
          :content="{
            title: 'Import Financial Data',
            modalType: 'importFinancialData'
          }"
        />
        <a
          href="/downloads/templates/financial.csv"
          download="financial.csv"
          class="btn mt-3 float-right btn-secondary mr-2"
        >
          <i aria-hidden="true" class="fa fa-download"></i>
          Download CSV Template
        </a>
      </b-col>
    </b-row>

    <div class="filters mb-4">
      <b-row>
        <b-col cols="5">
          <div class="filters__item">
            <b-form-checkbox id="showAllInvoices" v-model="showAllInvoices">
            </b-form-checkbox>
            <label for="showAllInvoices">Show all Invoices</label>
          </div>
        </b-col>
        <b-col cols="7">
          <b-form-group
            class="filters__flex d-flex align-items-center float-right"
            label="Period Selection"
            label-for="date"
          >
            <b-form-input
              id="startDate"
              type="date"
              v-model="filters['invoiceDate[after]']"
            >
            </b-form-input>
            <b-form-input
              id="endDate"
              type="date"
              v-model="filters['invoiceDate[before]']"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />

      <b-row>
        <b-col cols="4" class="filters__column">
          <div class="filters__item">
            <b-form-checkbox
              name="vatLiable"
              id="vatLiable"
              v-model="filters['practicaVATAmount[gt]']"
              value="0"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="vatLiable">VAT Liable Invoices</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="vatExempted"
              id="vatExempted"
              v-model="filters['practicaVATAmount']"
              value="0"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="vatExempted">VAT Exempted Invoices</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="vatLiableBeneficiary"
              id="vatLiableBeneficiary"
              v-model="filters['creatorCustomer.isVatLiable']"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="vatLiableBeneficiary">to VAT Liable Beneficiary</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              id="vatExemptedBeneficiary"
              v-model="filters['creatorCustomer.isVatLiable']"
              value="false"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="vatExemptedBeneficiary"
              >to VAT Exempted Beneficiary</label
            >
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="nonEducationalBeneficiary"
              id="nonEducationalBeneficiary"
              v-model="filters['creatorCustomer.isNonEducational']"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="nonEducationalBeneficiary"
              >to non-educational Beneficiary</label
            >
          </div>

          <div class="filters__item">
            <b-form-checkbox
              id="wowLearner"
              v-model="filters['creatorCustomer.isWOWLearner']"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="wowLearner">to WOW Learner</label>
          </div>
        </b-col>

        <b-col cols="4" class="filters__column">
          <div class="filters__item">
            <b-form-checkbox
              name="withLearningMaterials"
              id="withLearningMaterials"
              v-model="filters['learningMaterialsAmountExcludingVAT[gt]']"
              value="0"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="withLearningMaterials">With Learning Materials</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="lessLearningMaterials"
              id="lessLearningMaterials"
              v-model="filters['learningMaterialsCheck.status']"
              value="WARNING"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="lessLearningMaterials"
              >Less 5% Learning Materials</label
            >
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="appliedAmplificaFee"
              id="appliedAmplificaFee"
              v-model="filters['amplificaFeeCheck.isOk']"
              value="false"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="appliedAmplificaFee">Applied AmplificA Fee</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="notAppliedAmplificaFee"
              id="notAppliedAmplificaFee"
              v-model="filters['amplificaFeeCheck.isOk']"
              value="true"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="notAppliedAmplificaFee"
              >Not applied AmplificA Fee</label
            >
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="reverseCharge"
              id="reverseCharge"
              v-model="filters['reverseChargeCheck.status']"
              value="SKIP"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="reverseCharge">Reverse Charge</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="nonReverseCharge"
              id="nonReverseCharge"
              v-model="filters['reverseChargeCheck.status']"
              value="ERROR"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="nonReverseCharge"> Non Reverse Charge</label>
          </div>
        </b-col>

        <b-col cols="4" class="filters__column">
          <div class="filters__item">
            <b-form-checkbox
              name="with4Surcharge"
              id="with4Surcharge"
              v-model="filters['surchargeCheck.status']"
              value="OK"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="with4Surcharge">Surcharge &lt; 4% </label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="with410Surcharge"
              id="with410Surcharge"
              v-model="filters['surchargeCheck.status']"
              value="WARNING"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="with410Surcharge">4% &lt; Surcharge &lt; 10% </label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="with10Surcharge"
              id="with10Surcharge"
              v-model="filters['surchargeCheck.status']"
              value="ERROR"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="with10Surcharge">Surcharge > 10% </label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="passed93/10Check"
              id="passed93/10Check"
              v-model="filters['correction9313Check.status']"
              value="OK"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="passed93/10Check">Passed 93/10 Check</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="noDataFor93/10Check"
              id="noDataFor93/10Check"
              v-model="filters['correction9313Check.status']"
              value="PENDING"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="noDataFor93/10Check">No data for 93/13 Check</label>
          </div>

          <div class="filters__item">
            <b-form-checkbox
              name="notPassed93/10Check"
              id="notPassed93/10Check"
              v-model="filters['correction9313Check.status']"
              value="ERROR"
              unchecked-value=""
            >
            </b-form-checkbox>
            <label for="notPassed93/10Check">Not passed 93/13 Check</label>
          </div>
        </b-col>
      </b-row>
    </div>

    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="financial"
      showSearchInput
      @changeFilter="changeFilter"
      @changePage="changePage"
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="show(data.item)"
          v-b-tooltip.hover
          title="Show"
          ><i class="fa fa-eye text-info"></i
        ></a>
      </template>
    </CustomTable>

    <b-modal id="showInvoice" size="xl" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/menuicons/payments.png" />
          <span class="custom-modal__popup__title">
            Invoice Number:
            <span class="custom-modal__popup__title--blue">{{
              invoiceItem.creatorInvoiceNumber
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('showInvoice')"
        ></i>
      </template>

      <InvoiceShow :invoiceItem="invoiceItem"></InvoiceShow>
    </b-modal>

    <b-modal
      class="ml-4"
      size="xl"
      id="importFinancialData"
      title="Import Financial Data"
      hide-footer
    >
      <FinancialImport @created="created" />
    </b-modal>
  </div>
</template>

<script>
import CustomButton from '@/components/_ui/CustomButton';
import CustomTable from '@/components/_ui/tables/CustomTable';
import InvoiceShow from './Show';
import FinancialImport from '../import/Form';
import { SwalAlert } from '@/utils/swal';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import { entityService } from '@/utils/fetch';
import helpers from '@/mixins/helpers';
import _ from 'lodash';
import handshakes from '@/mixins/handshakes';

export default {
  components: {
    CustomButton,
    CustomTable,
    FinancialImport,
    InvoiceShow
  },

  mixins: [helpers, handshakes],

  props: {
    companyId: {
      type: String,
      default: null
    },
    handshakeId: {
      type: String,
      default: null
    },
    handshakeStatus: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapFields('financial/list/', {
      financialImportItems: 'items'
    }),
    ...mapGetters('auth', ['getUser', 'isRole']),

    getInvoicesUrl() {
      return this.companyId &&
        (this.isRole('ROLE_ACCOUNTANT') || this.isRole('ROLE_ADMIN'))
        ? `${this.companyId}/import-invoices?isArchived=false`
        : '/import/invoices?isArchived=false';
    },

    getCompanyId() {
      return this.companyId ?? this.getUser.company.entity.company['@id'];
    },

    handshakes() {
      return this.$store.state.handshake.list.items;
    },
    request() {
      let request = this.getInvoicesUrl;
      request += `&page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      importStatus: {},
      invoiceItem: {},
      filters: {},
      financialImportUrl: '/import/invoices?isArchived=false',
      fields: [
        {
          key: 'invoiceDate',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatDate(item.invoiceDate);
          }
        },
        {
          key: 'creatorInvoiceNumber',
          label: 'Invoice Number',
          sortable: true
        },
        {
          key: 'creatorCustomer.name',
          label: 'Company Name',
          sortable: true
        },
        {
          key: 'practicaAmountExcludingVAT',
          label: 'Total Practica',
          sortable: true,
          formatter: (v, k, item) => {
            this.formatCurrency(item.practicaAmountExcludingVAT);
          }
        },
        {
          key: 'learningMaterialsAmountExcludingVAT',
          label: 'Total Learning Materials',
          sortable: true,
          formatter: (v, k, item) => {
            this.formatCurrency(item.learningMaterialsAmountExcludingVAT);
          }
        },
        {
          key: 'optIn',
          label: 'Opt-in/Out',
          sortable: true,
          formatter: (value, key, item) => {
            return item?.creatorCustomer?.sector.isVatLiable
              ? 'Opt-In'
              : 'Opt-Out';
          }
        },
        { key: 'status', label: 'Status', sortable: true },
        {
          key: 'RIICalculation.totalAmountCorrection',
          label: 'RII Amount',
          sortable: true,
          formatter: (v, k, item) => {
            this.formatCurrency(item.RIICalculation?.totalAmountCorrection);
          }
        },
        { key: 'actions', label: 'Actions', sortable: true }
      ],
      items: [],
      search: {},
      showAllInvoices: true,
      isLoading: false,
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.financialImportUrl = this.getInvoicesUrl;

    // Get status data
    // @TODO(trepatudo): turn this into store
    entityService('/import/status')
      .then((response) => response.json())
      .then((data) => {
        this.importStatus = data;
      });

    this.refreshData();

    this.getHandshakes(
      `${this.getCompanyId}/handshakes?type=InvoicesHandshake&status=ACCEPTED`
    );
  },

  methods: {
    ...mapActions({
      getPage: 'financial/list/default',
      getHandshakes: `handshake/list/default`
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    show(item) {
      // Copy object with no reference
      this.invoiceItem = { ...item };
      this.invoiceItem.invoiceDate = this.formatDate(item.invoiceDate);

      this.$bvModal.show('showInvoice');
    },

    created() {
      this.$bvModal.hide('importFinancialData');

      SwalAlert({
        title: 'Financial import completed',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('importFinancialData');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {
              this.refreshData();
              return;
            }
          }
        }
      });
    },

    async mapFinancialData() {
      this.isLoading = true;
      var items = [];

      for (const item of this.financialImportItems) {
        if (item.isMerged) {
          item.creatorInvoiceNumber = 'Merged';
        }

        items.push(item);
      }

      this.items = items;
    },

    resetUrl() {
      this.financialImportUrl = this.getInvoicesUrl;
    }
  },

  watch: {
    financialImportItems: function () {
      this.mapFinancialData();
    },

    filters: {
      deep: true,

      handler(newVal, oldVal) {
        this.resetUrl();
        let parameters = '';

        Object.keys(newVal).forEach((filter, index) => {
          if (filter === 'order') {
            const order = this.sort.sortDesc ? 'asc' : 'desc';
            parameters += `&order[${this.sort.sortBy}]=${order}`;
          } else {
            if (newVal[filter] !== '' && newVal[filter] !== null) {
              parameters += `&${filter}=${newVal[filter]}`;
            }
          }
        });

        if (parameters) {
          this.showAllInvoices = false;
          this.financialImportUrl = `${this.financialImportUrl}${parameters}`;

          this.getFinancialData(this.financialImportUrl);
        } else {
          this.showAllInvoices = true;

          this.getFinancialData(this.financialImportUrl);
        }
      }
    },

    showAllInvoices: function (newVal) {
      if (newVal) this.filters = {};
    }
  }
};
</script>

<style lang="scss">
.import {
  &__buttons {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__icon {
    color: $white;
    border-radius: 15px;
    padding: 5px;
    // font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;

    &--danger {
      background-color: $danger;
    }

    &--success {
      background-color: $success;
    }

    &--warning {
      background-color: $warning;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
  }
}
</style>
