<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Company Name" label-for="name">
          <b-form-input id="name" :value="company.name" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Email Address" label-for="email">
          <b-form-input id="email" :value="company.email" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="VAT Number" label-for="fiscal_id">
          <b-form-input id="fiscal_id" :value="company.fiscal_id" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Sector" label-for="sector">
          <b-form-input
            id="sector"
            :value="company.sector ? company.sector.name : ''"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group label="Trade Name" label-for="tradename">
          <b-form-input id="tradename" :value="company.tradename" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Phone Number" label-for="phone">
          <b-form-input id="phone" :value="company.phone" disabled>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Chamber of Commerce" label-for="chamberOfCommerce">
          <b-form-input
            id="chamberOfCommerce"
            :value="company.chamberOfCommerce"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group
          label="RII Reference"
          label-for="riiReference"
          v-if="company.RIIReference"
        >
          <b-form-input
            id="riiReference"
            :value="company.RIIReference"
            disabled
          >
          </b-form-input>
        </b-form-group>

        <b-form-group class="company-info-edit">
          <b-button
            class="float-right"
            type="button"
            variant="primary"
            @click="$bvModal.show('companyInformationUpdate')"
            >Edit</b-button
          >
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group label="Company Logo" label-for="company logo">
          <div class="mt-2" v-b-modal.updateLogo>
            <div class="company__logo">
              <b-card
                class="company__logo__holder"
                v-if="company.logo"
                alt="Image"
                :img-src="companyLogo"
              >
                <div class="company__logo__icon">
                  <i class="fa fa-upload"></i>
                </div>
              </b-card>

              <div v-else alt="Image" class="company__logo__holder text-center">
                <img
                  class="company__logo__upload mr-2"
                  src="@/assets/img/upload.png"
                />
                <div class="mt-2">
                  <span>Upload your logo</span>
                </div>
              </div>
            </div>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: 'GeneralSettings',

  props: {
    company: {
      type: Object,
      required: true
    },
    readOnly: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser', 'isRole']),

    companyLogo() {
      //Math random becau se browser caches images
      return `${this.company.logo}?${Math.random()}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.fa-edit {
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.company__logo {
  &__holder {
    border: 1px solid $gray-300 !important;
    padding: 10px;
    border-radius: 10px;
  }

  &__icon {
    color: white;
    position: absolute;
    bottom: 55px;
    right: 15px;
  }

  &__upload {
    width: 100px;
    height: 100px;
  }
}

.company-info-edit {
  margin-top: 3rem;
}
</style>
