<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true"
        >Something went wrong. Try again.</span
      >
    </div>

    <b-form @submit.prevent="onSendForm">
      <b-form-group label="Start Date" label-for="date">
        <b-form-datepicker
          id="date"
          v-model="date"
          type="text"
          required="required"
          placeholder="Date"
        >
        </b-form-datepicker>
      </b-form-group>

      <b-button
        class="float-right"
        type="submit"
        variant="primary"
        v-if="!isLoading"
        >Submit
      </b-button>

      <b-button class="float-right" v-else type="submit">
        <b-spinner small></b-spinner>
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { businessLogicService, entityService } from '@/utils/fetch';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ScheduleGroup',

  props: {
    activity: {
      type: Object,
      require: true
    },

    group: {
      type: Object,
      require: true
    },

    schedule: {
      type: Object,
      default: () => {}
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields('learningmodule/list', {
      moduleItems: 'items'
    })
  },

  data() {
    return {
      isLoading: false,
      date: new Date(),
      error: null
    };
  },

  mounted() {},

  methods: {
    editForm() {
      const data = {
        startDateTime: this.date
      };
      return businessLogicService(this.schedule['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(data)
      });
    },
    postForm() {
      const data = {
        learningActivity: this.activity['@id'],
        learnersGroup: this.group['@id'],
        startDateTime: this.date
      };
      return businessLogicService('/schedules', {
        method: 'POST',
        body: JSON.stringify(data)
      });
    },
    onSendForm() {
      this.isLoading = true;

      const request =
        this.schedule && this.schedule['@id']
          ? this.editForm()
          : this.postForm();

      request
        .then(this.onSuccess)
        .catch(this.beforeDestroy)
        .finally(() => {
          this.isLoading = false;
        });
    },

    onSuccess() {
      this.$emit('created');
      this.$bvModal.hide('editSchedule');
    },

    beforeDestroy() {
      this.error = null;
    }
  }
};
</script>
