import { entityService } from '@/utils/fetch';
import { patterns } from '@/config/patterns';

const validators = {
  methods: {
    validateVat(countryCode, vatNumber) {
      return entityService(
        `/vat/validator?countryCode=${countryCode}&vatNumber=${vatNumber}`
      );
    },
    isPatterValid(countryCode, vatNumber) {
      const expression = patterns[countryCode]?.expression;

      if (expression) {
        const pattern = new RegExp(expression);
        return pattern.test(vatNumber);
      }

      return false;
    },
    validateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    }
  }
};

export default validators;
