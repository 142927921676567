<template>
  <div>
    <CompanyCreate
      :skip-company-roles="true"
      :skip-company-admin="skipCompanyAdmin"
      :return-if-exists="true"
      :is-creating-relation="true"
      @created="onCompanyForm"
      :show-type-person="showTypePerson"
      :is-creating-accountant="isCreatingAccountant"
      :is-creating-sub-contractor="isCreatingSubContractor"
      :availableCompanyTypes="availableCompanyTypes"
      :is-only-commercial="isOnlyCommercial"
      :block-branch="blockBranch"
    />

    <b-form @submit.prevent="handleSubmit(item)" v-if="showAddRelation">
      <b-form-group class="float-right" v-if="showNextButton">
        <b-button
          type="button"
          class="mr-2"
          variant="secondary"
          @click="closeModal"
          v-if="!isLoading"
          >Close</b-button
        >
        <b-button type="button" variant="primary" disabled v-if="isLoading">
          <b-spinner small></b-spinner>
        </b-button>
        <b-button
          type="button"
          variant="primary"
          @click="handleSubmit(item)"
          v-else
          :disabled="errors"
          >Add Relation</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import CompanyCreate from '../company/Create';

export default {
  components: {
    CompanyCreate
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    skipCompanyAdmin: {
      type: Boolean,
      default: true
    },

    blockBranch: {
      type: Boolean,
      default: () => true
    },

    showTypePerson: {
      type: Boolean,
      default: false
    },

    isCreatingAccountant: {
      type: Boolean,
      default: false
    },

    isCreatingSubContractor: {
      type: Boolean,
      default: false
    },

    isCreatingUbo: {
      type: Boolean,
      default: false
    },

    isOnlyCommercial: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('auth', ['getCompany', 'isRole']),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    showNextButton() {
      return this.retrievedCompany.hasOwnProperty('@id');
    }
  },

  data() {
    return {
      retrievedCompany: {},
      showAddRelation: false,
      availableCompanyTypes: []
    };
  },

  mounted() {
    if (this.isCreatingSubContractor) {
      this.availableCompanyTypes = ['OTHER'];
    } else if (this.isCreatingAccountant) {
      this.availableCompanyTypes = ['OTHER', 'PERSON'];
    } else if (this.isRole('ROLE_PROVIDER')) {
      this.availableCompanyTypes = ['OTHER', 'PERSON', 'GOVERNMENTAL'];
    } else {
      this.availableCompanyTypes = ['OTHER'];
    }
  },

  methods: {
    // ...mapActions({
    //   companyGetSelectItems: 'company/list/getSelectItems',
    //   relatedGetSelectItems: 'company/list/getSelectItems',
    // }),

    closeModal() {
      this.$bvModal.hide('createRelationship');
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    onCompanyForm(company, retrieved = false) {
      this.retrievedCompany = company;
      this.item.type = 'CUSTOMER';
      this.item.company = this.getCompany.entity.company['@id'];
      this.item.related = this.retrievedCompany['@id'];
      this.item.isAccountant = false;
      this.item.isProvider = false;
      this.item.isInstructor = false;

      if (!retrieved) {
        this.handleSubmit(this.item);
      } else {
        this.showAddRelation = true;
      }
    }
  }
};
</script>
