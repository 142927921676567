import CancelledList from '@/components/dashboard/program/cancelled/List.vue';

import Default from '@/views/dashboard/Programs/Default';

export default [
  {
    path: '/programs/cancelled',
    component: Default,
    children: [
      {
        name: 'CancelledList',
        path: '/programs/cancelled/',
        component: CancelledList
      }
    ]
  }
];
