<template>
  <div class="component">
    <div class="content shadow-sm" :class="{ editing: isEditing }">
      <div class="title mt-2 pb-4 d-flex justify-content-between">
        <h3>{{ title }}</h3>
        <div class="actions">
          <button
            @click="emitRemove(permanent, position)"
            class="btn btn-danger"
            :disabled="permanent"
          >
            <i class="fa fa-trash text-info"></i>
          </button>
        </div>
      </div>
      <div class="icon d-flex justify-content-center align-items-center pb-5">
        <img
          alt=""
          height="32px"
          src="@/assets/img/icons/arrows-up-down-left-right-solid.svg"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashEditing',
  props: {
    emitRemove: {
      type: Function
    },
    title: {
      type: String
    },
    permanent: {
      type: Boolean
    },
    position: {
      type: Number
    },
    isEditing: {
      type: Boolean
    }
  }
};
</script>
