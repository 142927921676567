export const patterns = {
  AT: {
    expression: '^(AT)?U[0-9]{8}$'
  },
  BE: {
    expression: '^(BE)?0[0-9]{9}$'
  },
  BG: {
    expression: '^(BG)?[0-9]{9 },10}$'
  },
  CY: {
    expression: '^(CY)?[0-9]{8}L$'
  },
  CZ: {
    expression: '^(CZ)?[0-9]{8,10}$'
  },
  DE: {
    expression: '^(DE)?[0-9]{9}$'
  },
  DK: {
    expression: '^(DK)?[0-9]{8}$'
  },
  EE: {
    expression: '^(EE)?[0-9]{9}$'
  },
  GR: {
    expression: '^(EL|GR)?[0-9]{9}$'
  },
  ES: {
    expression: '^(ES)?[0-9A-Z][0-9]{7}[0-9A-Z]$'
  },
  FI: {
    expression: '^(FI)?[0-9]{8}$'
  },
  FR: {
    expression: '^(FR)?[0-9A-Z]{2}[0-9]{9}$'
  },
  GB: {
    expression: '^(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})$'
  },
  HU: {
    expression: '^(HU)?[0-9]{8}$'
  },
  IE: {
    expression: '^(IE)?[0-9]S[0-9]{5}L$'
  },
  IT: {
    expression: '^(IT)?[0-9]{11}$'
  },
  LT: {
    expression: '^(LT)?([0-9]{9}|[0-9]{12})$'
  },
  LU: {
    expression: '^(LU)?[0-9]{8}$'
  },
  LV: {
    expression: '^(LV)?[0-9]{11}$'
  },
  MT: {
    expression: '^(MT)?[0-9]{8}$'
  },
  NL: {
    expression: '^(NL)?[0-9]{9}B[0-9]{2}$'
  },
  PL: {
    expression: '^(PL)?[0-9]{10}$'
  },
  PT: {
    expression: '^(PT)?[0-9]{9}$'
  },
  RO: {
    expression: '^(RO)?[0-9]{2,10}$'
  },
  SE: {
    expression: '^(SE)?[0-9]{12}$'
  },
  SI: {
    expression: '^(SI)?[0-9]{8}$'
  },
  SK: {
    expression: '^(SK)?[0-9]{10}$'
  }
};
