<template>
  <div class="show__content" v-if="handshake">
    <div class="show__item" v-if="canAcceptOrReject">
      <div class="d-flex">
        <span class="show__item__title show__item__title--black"
          >Is the following data input correct?
        </span>
        <b-form-checkbox
          class="ml-5"
          id="acceptCompany"
          v-model="acceptCompany"
          name="acceptCompany"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </div>
    </div>

    <div class="show__item" v-if="handshake.company.name">
      <span class="show__item__title">Name: </span>
      <span class="show__item__value">{{ handshake.company.name }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.tradename">
      <span class="show__item__title">Trade Name: </span>
      <span class="show__item__value">{{ handshake.company.tradename }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.fiscal_id">
      <span class="show__item__title">Fiscal ID: </span>
      <span class="show__item__value">{{ handshake.company.fiscal_id }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.email">
      <span class="show__item__title">Email: </span>
      <span class="show__item__value">{{ handshake.company.email }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.phone">
      <span class="show__item__title">Phone: </span>
      <span class="show__item__value">{{ handshake.company.phone }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProviderSignupHand',

  props: {
    handshake: {
      type: Object,
      required: true
    },
    canAcceptOrReject: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canSubmit() {
      return this.acceptCompany;
    }
  },

  data() {
    return {
      acceptCompany: false
    };
  },
  watch: {
    canSubmit: function (newVal) {
      this.$emit('isSubmitAllowed', newVal);
    }
  }
};
</script>
