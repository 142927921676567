<template>
  <div>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/relations@2x.png"
      />

      <h3 class="mr-2 mt-5 mb-5">
        All
        <span class="font-weight-bold text-primary">{{ companyName }}</span>
        employees
      </h3>
    </div>

    <CustomTable
      :fields="fields"
      list="company/employees"
      @changePage="changePage"
    >
      <template v-slot:isUbo="data">
        <Status class="text-center" :value="data.item.isUbo" />
      </template>
      <template v-slot:roles="data">
        <Roles :roles="data.item.user.roles" />
      </template>
      <template v-slot:isAdmin="data">
        <b-form-checkbox
          :id="`checkbox-${data.item['@id']}`"
          @change="addOrRemoveCompanyAdmin(data.item)"
          v-model="data.item.isAdmin"
          :checked="true"
          :unchecked-value="false"
          switch
        ></b-form-checkbox>
      </template>
      <template v-slot:actions="data">
        <a
          href="#"
          @click.prevent="deleteItem(data.item)"
          v-b-tooltip.hover
          title="Delete"
        >
          <i class="fas fa-trash text-info"></i>
        </a>
      </template>
    </CustomTable>

    <b-modal id="companyUserCreate" title="Add Employee" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Employee</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('companyUserCreate')"
        ></i>
      </template>

      <CompanyUserCreate @created="refreshData"></CompanyUserCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import helpers from '@/mixins/helpers';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Roles from '@/components/_ui/users/Roles';
import Status from '@/components/_ui/tables/Status';
import CompanyUserCreate from '../Create';
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  mixins: [helpers],

  components: {
    CustomTable,
    Roles,
    CompanyUserCreate,
    Status
  },

  props: {
    company: {
      type: Object,
      required: false
    },
    reload: {
      type: Number,
      required: false
    }
  },

  data() {
    return {
      deleted: 0,
      fields: [
        {
          key: 'user',
          label: 'Name',
          formatter: this.getfullName,
          sortable: true
        },
        { key: 'user.email', label: 'Email Address', sortable: true },
        { key: 'roles', label: 'Roles', sortable: true },
        { key: 'isUbo', label: 'UBO', sortable: true },
        { key: 'isAdmin', label: 'Admin', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapFields('companyuser/del', {
      deleteError: 'error'
    }),

    companyName() {
      return this.company.name;
    },

    request() {
      let request = `${this.company['@id']}/employees?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  watch: {
    reload: function () {
      this.refreshData();
    },
    deleted: function () {
      this.refreshData();
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'company/employees/list/default',
      del: 'companyuser/del/del',
      deleteCompanyUser: 'companyuser/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },
    deleteItem(item) {
      SwalAlert({
        title: 'Delete Company User',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.deleteCompanyUser(item).then(() => {
                SwalAlert({
                  title: 'Company User deleted',
                  type: 'success',
                  icon: 'user'
                }).then(() => {
                  this.refreshData();
                });
              });
            }
          }
        }
      });
    },

    getfullName(value) {
      return `${value.firstName} ${value.lastName}`;
    },

    addOrRemoveCompanyAdmin(item) {
      const body = {
        company: item.company['@id'],
        user: item.user['@id'],
        isAdmin: item.isAdmin
      };

      entityService(item['@id'], {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/json'
        }),
        body: JSON.stringify(body)
      }).catch((e) => {
        SwalAlert({
          title: 'Error',
          description: e,
          icon: 'user',
          type: 'success',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'success',
              callback: () => {}
            }
          }
        });
      });
    }
  }
};
</script>
