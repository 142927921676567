<template>
  <b-row>
    <b-col offset="1" cols="11" class="pr-1">
      <table
        class="adminDetailsTable w-100 border-0"
        v-if="this.program.loaded"
      >
        <tr class="customerRow">
          <th colspan="5">
            <i
              v-if="items.length > 0"
              :class="{ fas: program.selected, far: !program.selected }"
              class="fa-square text-primary mr-2"
              @click="program.selected = !program.selected"
            />
            <span v-if="this.program.customer">{{
              this.program.customer.name
            }}</span>
            <i v-else>(Open Enrollment)</i>
          </th>
          <th>
            <!--            <i-->
            <!--              :class="[this.program.select ? 'fas' : 'far']"-->
            <!--              class="fa-square text-primary float-right"-->
            <!--            />-->
          </th>
        </tr>
        <tr
          class="customerDetails"
          v-for="item in this.items"
          :key="item['@id']"
        >
          <td colspan="2" class="pl-4">
            <span v-if="item.isFee">(FEE)</span>
            <i
              v-else
              :class="[item.selected ? 'fas' : 'far']"
              @click="onRowSelected(item)"
              class="fa-square text-primary mr-2"
            />
            {{ item.article.name }}
          </td>
          <td>{{ parseDate(item.startDate) }}</td>
          <td>&euro;{{ item.grossAmount.formatted }}</td>
          <td></td>
          <td></td>
        </tr>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';

export default {
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      //  items
      items: [],
      // B-table
      fields: [
        { key: 'article.name', label: 'Article' },
        {
          key: 'startDate',
          label: 'Start Date',
          formatter: (value, key, item) => {
            return this.parseDate(item.startDate);
          }
        },
        {
          key: 'grossAmount',
          label: 'Amount',
          formatter: (value, key, item) => {
            return `${item.grossAmount}€`;
          }
        },

        { key: 'selected2', label: '' }
      ]
    };
  },
  watch: {
    selected: {
      handler: function (newValue) {
        let bAllSelected = true;
        for (let i in this.items) {
          let selected = this.items[i].selected;
          if (!selected) {
            bAllSelected = false;
          }
          this.selectRespectiveArticle(this.items[i]);
          this.$emit('selected', {
            item: this.items[i],
            selected: selected
          });
        }

        // If some are not selected, unmark program
        this.$set(this.program, 'selected', bAllSelected);
      }
    },
    'program.selected': {
      deep: true,
      handler: function (newValue) {
        for (let i in this.items) {
          this.$set(this.items[i], 'selected', newValue);
        }
      }
    }
  },
  computed: {
    selected() {
      return this.items.map((item) => item.selected);
    }
  },
  created() {
    this.$set(this.program, 'openLoading', true);

    // Get details
    fetch(
      `${BUSINESS_SERVICE_ENTRYPOINT}${this.program['@id']}/invoiceables?status=PENDING`
    )
      .then((response) => response.json())
      .then((data) => {
        this.items = data['hydra:member'];
      })
      .catch((error) => {
        this.errors = error;
      })
      .finally(() => {
        this.$set(this.program, 'loaded', true);
        this.$set(this.program, 'openLoading', false);
      });
  },
  methods: {
    selectRespectiveArticle(item) {
      for (let i in this.items) {
        if (
          this.items[i].isFee &&
          this.items[i].article['@id'] == item.article['@id']
        ) {
          this.$set(this.items[i], 'selected', item.selected);
        }
      }
    },
    onRowSelected(item) {
      this.$set(item, 'selected', !item.selected);
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    }
  }
};
</script>
<style lang="scss">
.adminDetailsTable {
  color: black !important;
  td,
  th {
    border-top: 0 !important;
  }
  .customerRow {
    background: #e8eaec;
    th {
      height: 45px !important;
      line-height: 45px !important;
      padding: 0 10px;
      border-bottom: 1px solid white;
    }
  }
  .customerDetails {
    background: #f3f5f8;
    td {
      color: black;
      border-bottom: 1px solid white;
    }
  }
}
</style>
