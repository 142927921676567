<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="article"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:isMergeable="data">
        <Status class="text-center" :value="data.item.isMergeable" />
      </template>

      <template v-slot:actions="data">
        <a
          href="#"
          class="mr-2"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Edit"
          ><i class="fa fa-edit text-info"></i
        ></a>
        <a
          v-b-tooltip.hover
          title="Delete"
          href="#"
          v-if="
            data.item.protocolRules.length === 0 &&
            data.item.articleChildrenSplits.length === 0
          "
          @click.prevent="del(data.item)"
          ><i class="fa fa-trash text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createArticle')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Article
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="editArticle" title="Edit Article" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Article</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editArticle')"
        ></i>
      </template>
      <ArticleUpdate
        :article="editArticle"
        @updated="refreshData"
      ></ArticleUpdate>
    </b-modal>

    <b-modal id="createArticle" title="Create Article" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Article</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createArticle')"
        ></i>
      </template>
      <ArticleCreate @created="refreshData"></ArticleCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ArticleUpdate from './Update.vue';
import ArticleCreate from './Create.vue';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Status from '@/components/_ui/tables/Status';

export default {
  components: {
    ArticleUpdate,
    ArticleCreate,
    CustomTable,
    Status
  },

  computed: {
    ...mapFields('article/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    request() {
      let request = '/articles?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      editArticle: null,
      deleting: {},
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'vatRateSource', label: 'Vat Rate Resource', sortable: true },
        { key: 'origin', label: 'From', sortable: true },
        { key: 'destination', label: 'To', sortable: true },
        { key: 'isMergeable', label: 'Mergeable', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }

      this.refreshData();
    }
  },

  methods: {
    ...mapActions({
      getPage: 'article/list/default',
      deleteItem: 'article/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this article ?')) {
        let itemId = item['@id'];
        this.deleting[itemId] = true;
        this.deleteItem({ '@id': itemId });
      }
    },

    deleteIsLoading(articleId) {
      return this.deleting.hasOwnProperty(articleId);
    },

    edit(item) {
      this.editArticle = item;
      this.$bvModal.show('editArticle');
    }
  }
};
</script>
