<template>
  <div>
    <div
      class="alert alert-danger"
      role="alert"
      v-if="showError"
      v-html="errorText"
    ></div>

    <b-form class="py-2" @submit.prevent="onSendForm">
      <template v-if="step === 1">
        <div>
          <b-form-group label="Type" label-for="type">
            <b-form-select
              v-model="form.type"
              :options="['CREATOR+', 'BENEFICIARY', 'ACCOUNTANT+', 'TEACHER']"
            ></b-form-select>
          </b-form-group>

          <b-form-group label="Origin" label-for="origin">
            <b-form-input
              type="text"
              placeholder="Origin"
              v-model="form.origin"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Upload File" label-for="doc">
            <vue-dropzone
              ref="myVueDropzone"
              id="doc"
              :options="dropzoneOptions"
              @vdropzone-file-added="uploadedFile"
            ></vue-dropzone>
          </b-form-group>
        </div>
      </template>

      <template v-if="step === 2">
        <b-container fluid>
          <b-row>
            <b-col cols="12" md="6">
              <span class="card__title">Your fields:</span>

              <draggable
                tag="ul"
                class="card"
                :list="csvFields"
                :animation="200"
                ghost-class="card__moving-card"
              >
                <li
                  v-for="(field, index) in csvFields"
                  :key="field.id"
                  class="card__item"
                  :class="{
                    'card__item--disabled': index + 1 > templateFields.length
                  }"
                >
                  <i class="fas fa-list"></i>
                  {{ field }}
                </li>
              </draggable>
            </b-col>
            <b-col cols="12" md="6">
              <span class="card__title">Required fields:</span>
              <ul class="card">
                <li
                  v-for="field in templateFields"
                  :key="field.id"
                  class="card__item card__item--nomove card__item--noafter"
                >
                  <i class="fas fa-list"></i> {{ field.value }}
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <div class="buttons">
        <CustomButton
          v-if="step !== 2"
          class="float-right"
          type="Default"
          :content="{ title: 'Next' }"
          @click.native="nextStep"
        />

        <CustomButton
          v-if="step !== 1"
          class="float-left"
          type="Default"
          :content="{ title: 'Prev' }"
          @click.native="prevStep"
        />

        <b-form-group class="float-right" v-if="step === 2">
          <b-button type="submit" variant="primary" v-if="!isLoading"
            >Submit</b-button
          >
          <b-button type="submit" variant="primary" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </b-form-group>
      </div>
    </b-form>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import helpers from '@/mixins/helpers';
import { entityService } from '@/utils/fetch';
import CustomButton from '@/components/_ui/CustomButton';
import Draggable from 'vuedraggable';

export default {
  components: {
    vueDropzone: vue2Dropzone,
    CustomButton,
    Draggable
  },

  mixins: [helpers],

  data() {
    return {
      step: 1,
      isLoading: false,
      form: {
        type: null,
        origin: null
      },
      dropzoneOptions: {
        url: 'localhost',
        autoProcessQueue: false,
        dictDefaultMessage: "<i class='fa fa-plus'></i><p>UPLOAD DOCUMENT</p>",
        maxFiles: 1
      },
      file: null,

      showError: false,
      errorText: 'File is required. <strong>Please upload</strong>',

      templateFields: [
        {
          key: 'VATNumber',
          value: 'VAT Number'
        },
        {
          key: 'Name',
          value: 'Name'
        }
      ],

      csvFields: [],

      mappedFields: []
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('importProspect');
    },

    uploadedFile(file) {
      this.file = file;
      this.showError = false;
    },

    onSendForm() {
      const fields = this.mapFinalFields();

      let formData = new FormData();
      formData.append('form', JSON.stringify(this.form));
      formData.append('fields', JSON.stringify(fields));
      formData.append('file', this.file);
      formData.append('separator', this.delimiter);

      this.isLoading = true;
      entityService('/prospects/import', {
        method: 'POST',
        body: formData
      }).then((response) => {
        this.isLoading = false;
        this.$emit('created', response);
      });
    },

    mapFinalFields() {
      const fields = {};

      this.templateFields.forEach((key, index) => {
        fields[key.key] = this.csvFields[index];
      });

      return fields;
    },

    async nextStep() {
      if (this.step === 1) {
        if (!this.file) {
          this.showError = true;

          return;
        }
      }

      const response = await this.transformCSV();

      if (!response) {
        this.errorText =
          'Your file doenst match <strong>your required fields length</strong>';
        this.showError = true;

        return;
      }
      this.step++;
    },

    async transformCSV() {
      const result = await this.asyncFileReader(this.file);
      this.csvFields = this.csvToArray(result);

      if (this.csvFields.length < this.templateFields.length) {
        return false;
      }

      return true;
    },

    prevStep() {
      this.step--;
    }
  }
};
</script>

<style lang="scss">
.card {
  width: 100%;
  padding: 0;
  border: 0 !important;

  &__title {
    font-style: italic;
    color: $gray-500;
    // font-size: 12px;
  }

  &__item {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    padding: 10px 20px;
    transition: 0.2s background-color ease-in;

    text-transform: uppercase;
    border-radius: 8px;
    font-weight: bold;

    cursor: pointer;

    &:is(:first-child) {
      margin-top: 15px;
    }

    &:hover {
      background-color: rgb(30 47 71 / 71%);
    }

    &:after {
      content: '\2192';
      position: absolute;
      top: 10px;
      right: -27px;
      width: 20px;
      height: 20px;
      color: $primary;
    }

    &--nomove {
      cursor: default;
      background-color: $secondary !important;
    }

    &--disabled {
      opacity: 0.6;
      &:hover {
        background-color: $primary;
      }

      &:after {
        display: none;
      }
    }

    &--noafter {
      &:after {
        display: none;
      }
    }
  }

  &__moving-card {
    opacity: 0.8;
    background-color: $gray-100;
    border: 2px solid $secondary;
  }
}
</style>
