import qs from 'querystring';
import { parseJwt } from '../../../utils/jwt';
import { entityService } from '../../../utils/fetch';
import {
  AUTH_LOGGED_OUT,
  AUTH_SUCCESS,
  IMPERSONATE_IN,
  IMPERSONATE_OUT
} from './mutations';
import { AUTH_SERVICE_ENTRYPOINT } from '../../../config/entrypoint';

export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGIN_CONTEXT = 'AUTH_LOGIN_CONTEXT';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const IMPERSONATE_USER = 'IMPERSONATE_USER';
export const IMPERSONATE_LOGOUT = 'IMPERSONATE_LOGOUT';

let parseUserLogin = (data, commit, resolve, reject) => {
  // Parse the token
  let token = data.access_token;
  let tokenObject = parseJwt(token);

  // Query entity service
  let headers = new Headers();
  headers.set('Authorization', `Bearer ${token}`);
  let userData = entityService(`users/${tokenObject.amp_id}`, {
    headers: headers
  })
    .then((response) => response.json())
    .then((_userData) => {
      // -------------- Fetch user --------------
      let user = {
        id: tokenObject.amp_id,
        email: tokenObject.email,
        role: tokenObject.current_role || '',
        company: { id: tokenObject.current_company || 0, entity: {} },
        entity: _userData
      };

      // -------------- Fetch company --------------
      let company = _userData.companies.find((_company) => {
        return _company.company['@id'] === `/companies/${user.company.id}`;
      });
      if (company) {
        user.company.entity = company;
      }

      // Commit
      localStorage.setItem('token', JSON.stringify(data));
      localStorage.setItem('user', JSON.stringify(user));

      commit(AUTH_SUCCESS, {
        user: user,
        token: data
      });

      resolve(data);
    })
    .catch((err) => {
      throw err;
    })
    .catch((err) => reject(err));
};
export const actions = {
  /**
   * Perform login to auth server
   * @param commit
   * @param dispatch
   * @param payload
   * @returns {Promise<unknown>}
   */
  [AUTH_LOGIN]: ({ commit, dispatch }, payload) => {
    // Call auth server
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      window.axios
        .post(
          `${AUTH_SERVICE_ENTRYPOINT}/realms/AmplificA/protocol/openid-connect/token`,
          qs.stringify({
            client_id: 'authentication-service',
            scope: 'openid',
            grant_type: 'password',
            username: payload.username,
            password: payload.password
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: false
          }
        )
        .then(({ data }) => parseUserLogin(data, commit, resolve, reject))
        .catch((err) => {
          localStorage.removeItem('token');
          err.response.status !== 500
            ? reject('E-mail or Password is invalid, please try again!')
            : reject('Login unavailable. Try again later');
        });
    });
  },

  [AUTH_REFRESH_TOKEN]: ({ commit, dispatch }, payload) => {
    // Call auth server
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      window.axios
        .post(
          `${AUTH_SERVICE_ENTRYPOINT}/realms/AmplificA/protocol/openid-connect/token`,
          qs.stringify({
            client_id: 'authentication-service',
            scope: 'openid',
            grant_type: 'refresh_token',
            refresh_token: payload.refresh_token
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            },
            withCredentials: false
          }
        )
        .then(({ data }) => parseUserLogin(data, commit, resolve, reject))
        .catch(() => {
          localStorage.removeItem('token');
          reject('Email and/or password are incorrect.');
        });
    });
  },

  /**
   * Logout!
   * @param commit
   * @param dispatch
   * @param payload
   */
  [AUTH_LOGOUT]: ({ commit, dispatch }, payload) => {
    localStorage.removeItem('token');
    localStorage.removeItem('STORE_CHAT_TOKEN');
    localStorage.removeItem('user');
    commit(AUTH_LOGGED_OUT);
  },

  /**
   * Pick a context of the user (role and company)
   * @param commit
   * @param dispatch
   * @param payload
   */
  [AUTH_LOGIN_CONTEXT]: ({ commit, dispatch }, payload) => {
    // Call entity server
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      entityService('login', {
        method: 'POST',
        body: JSON.stringify({
          role: payload.role,
          company: payload.company
        })
      })
        .then((data) => {
          resolve(data);
        })
        .catch(() => {
          localStorage.removeItem('token');
          reject('Email and/or password are incorrect.');
        });
    });
  },

  [IMPERSONATE_USER]: (
    { commit, dispatch, state },
    { user, role, company }
  ) => {
    // Call entity server
    return new Promise((resolve, reject) => {
      // The Promise used for router redirect in login
      entityService(`${user['@id']}/impersonate`, {
        method: 'POST',
        body: JSON.stringify({
          role: role,
          company: company
        })
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          // Commit
          localStorage.removeItem('STORE_CHAT_TOKEN');
          localStorage.setItem(
            'impersonator_token',
            JSON.stringify(state.token)
          );
          localStorage.setItem('impersonator_user', JSON.stringify(state.user));
          commit(IMPERSONATE_IN);
          // Login as new user
          parseUserLogin(data, commit, resolve, reject);
        })
        .catch((e) => {
          console.log(e);
          reject('Unable to impersonate user');
        });
    });
  },

  /**
   * Logout!
   * @param commit
   * @param dispatch
   * @param payload
   */
  [IMPERSONATE_LOGOUT]: ({ commit, dispatch, state }, payload) => {
    return new Promise((resolve, reject) => {
      // Store
      let _user = { ...state.impersonator.user };
      let _token = { ...state.impersonator.token };

      // Commit!
      localStorage.removeItem('impersonator_token');
      localStorage.removeItem('impersonator_user');
      commit(IMPERSONATE_OUT);

      // Login again with the old user
      parseUserLogin(_token, commit, resolve, reject);
    });
  }
};
