import LanguageList from '@/components/admin/language/List';
import LanguageCreate from '@/components/admin/language/Create';
import LanguageUpdate from '@/components/admin/language/Update';
import LanguageShow from '@/components/admin/language/Show';

export default [
  { name: 'LanguageList', path: '/languages/', component: LanguageList },
  {
    name: 'LanguageCreate',
    path: '/languages/create',
    component: LanguageCreate
  },
  {
    name: 'LanguageUpdate',
    path: '/languages/edit/:id',
    component: LanguageUpdate
  },
  { name: 'LanguageShow', path: '/languages/show/:id', component: LanguageShow }
];
