<template>
  <div v-if="company" class="component">
    <div class="content shadow-sm">
      <div
        class="title mt-2 pb-4 d-flex justify-content-between align-items-center"
      >
        <div>
          <h3>Company Actions</h3>
          <span>Why is this your's status ? <a href="#">check here</a></span>
          <!--@TODO: Link to view details about this actions-->
        </div>
        <div class="actions">
          <button
            class="btn btn-transparent"
            @click="toggleCollapse = !toggleCollapse"
          >
            <i v-if="toggleCollapse" class="fa fa-chevron-up" />
            <i v-else class="fa fa-chevron-down" />
          </button>
        </div>
      </div>
      <b-collapse v-model="toggleCollapse">
        <Actions
          v-if="!companyIsLoading"
          :company="company"
          @refreshData="refreshData"
        />
      </b-collapse>
    </div>
  </div>
</template>
<script>
import Actions from '@/components/dashboard/widgets/Actions';
export default {
  name: 'DashActions',
  components: {
    Actions
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    companyIsLoading: {
      type: Boolean,
      default: true
    },
    refreshData: {
      type: Function
    }
  },
  data() {
    return {
      toggleCollapse: true
    };
  }
};
</script>
