<template>
  <nav id="sidebar">
    <div class="sidebar-header">
      <router-link to="/dashboard" class="logo"></router-link>
    </div>

    <ul>
      <template v-for="(item, index) in menuComputed">
        <li
          v-if="
            isRole(item.roles) ||
            item.roles.includes('All') ||
            canAdminView(item)
          "
          :key="index"
        >
          <a
            v-if="item.modal"
            href="#"
            v-b-modal="item.modal"
            class="programs"
            v-b-tooltip.hover.topright="{
              customClass: 'custom-tooltip',
              disabled: !isMenuOpen
            }"
            :title="item.title"
          >
            {{ item.title }}
          </a>

          <template>
            <router-link
              v-b-tooltip.hover.topright="{
                customClass: 'custom-tooltip',
                disabled: !isMenuOpen
              }"
              :title="item.title"
              v-if="!item.submenu"
              :to="item.path"
              :class="item.class"
              @click.prevent="openMenu(index)"
            >
              {{ item.title }}
            </router-link>
            <a
              v-else
              href="#"
              :class="item.class"
              @click.prevent="openMenu(index)"
              v-b-tooltip.hover.topright="{
                customClass: 'custom-tooltip',
                disabled: !isMenuOpen
              }"
              :title="item.title"
            >
              {{ item.title }}
              <i
                :class="{ 'fa-angle-right__open': open.includes(index) }"
                class="fas fa-angle-right"
              ></i>
            </a>

            <hr v-if="index === 0" class="bg-white mx-4" />
          </template>

          <ul
            class="dropdown submenu"
            :class="{ submenu__open: open.includes(index) }"
            v-if="item.submenu"
          >
            <template v-for="(subItem, subIndex) in item.submenu">
              <template
                v-if="
                  canAdminView(subItem) ||
                  (subItem.roles &&
                    (isRole(subItem.roles) || subItem.roles.includes('All')))
                "
              >
                <div v-if="subItem.getCompanyType" :key="subIndex">
                  <li v-if="getCompanyType === 'PERSON'">
                    <router-link
                      :to="subItem.path"
                      v-b-tooltip.hover.topright="{
                        customClass: 'custom-tooltip',
                        disabled: !isMenuOpen
                      }"
                      :title="subItem.title"
                    >
                      {{ subItem.title }}
                    </router-link>
                  </li>
                </div>
                <div v-else :key="`1-${subIndex}`">
                  <li
                    v-if="
                      canAdminView(subItem) ||
                      (subItem.roles &&
                        (isRole(item.roles) || item.roles.includes('All')))
                    "
                    :key="subIndex"
                  >
                    <router-link
                      :to="subItem.path"
                      v-b-tooltip.hover.topright="{
                        customClass: 'custom-tooltip',
                        disabled: !isMenuOpen
                      }"
                      :title="subItem.title"
                    >
                      {{ subItem.title }}
                    </router-link>
                  </li>
                </div>
              </template>
            </template>
          </ul>
        </li>
      </template>
      <li v-if="messageOnSideBar" :class="{ active: stateMessage }">
        <a
          v-b-tooltip.hover.topright="{
            customClass: 'custom-tooltip',
            disabled: !isMenuOpen
          }"
          title="Messages"
          href="#"
          class="contacts"
          @click.prevent="toggleMessage()"
          :class="{ active: stateMessage }"
        >
          Messages
          <i
            :class="{ 'fa-angle-right__open': this.stateMessage }"
            class="fas fa-angle-right"
          ></i>
        </a>
        <ul class="dropdown submenu" :class="{ submenu__open: stateMessage }">
          <Contacts
            :rooms="rooms"
            :is-loading="roomIsLoading"
            :refresh-data="refreshData"
            :isMenuOpen="isMenuOpen"
          >
          </Contacts>
        </ul>
      </li>
    </ul>

    <b-modal id="qrScanner" title="Scan QR Code" hide-footer>
      <QrCodeForm></QrCodeForm>
    </b-modal>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Contacts from '../chat/Rooms/List.vue';
import QrCodeForm from './user/activities/QrCodeForm';
import { mapFields } from 'vuex-map-fields';
import { CHAT_FETCH_ROOMS } from '../../store/modules/chat/actions';

export default {
  name: 'Sidemenu',
  components: {
    QrCodeForm,
    Contacts
  },
  props: {
    isMenuOpen: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', [
      'isRole',
      'getUser',
      'isAdmin',
      'getCompanyType',
      'getCompany'
    ]),
    ...mapFields('chat', {
      rooms: 'rooms',
      roomIsLoading: 'roomIsLoading'
    }),
    menuComputed() {
      if (this.messageOnSideBar) return [this.menu[0]];
      else return this.menu;
    }
  },

  data() {
    return {
      menu: [
        {
          title: 'Dashboard',
          path: '/dashboard',
          class: 'dashboard',
          roles: ['All']
        },
        {
          title: 'Company',
          class: 'companies',
          path: '/government/companies',
          roles: ['ROLE_GOVERNMENT']
        },
        {
          title: 'Companies',
          class: 'companies',
          path: '/bailiff/companies',
          roles: ['ROLE_BAILIFF']
        },
        {
          title: 'Companies',
          class: 'companies',
          path: '/companies',
          roles: ['ROLE_ADMIN']
        },
        {
          title: 'Users',
          path: '/users',
          class: 'users',
          roles: ['ROLE_ADMIN']
        },
        {
          title: 'Prospects',
          class: 'prospect',
          path: '/prospect',
          roles: ['ROLE_ADMIN']
        },
        {
          title: 'Relations',
          class: 'relations',
          roles: ['ROLE_PROVIDER', 'ROLE_ACCOUNTANT'],
          submenu: [
            {
              title: 'Beneficiaries',
              path: '/relationships/beneficiaries/overview',
              roles: ['All']
            },
            {
              title: 'Subcreators',
              path: '/relationships/subcreators/overview',
              roles: ['ROLE_PROVIDER']
            }
          ]
        },
        {
          title: 'Programs',
          class: 'programs',
          roles: ['ROLE_PROVIDER'],
          submenu: [
            {
              title: 'Proposal',
              path: '/programs/proposals',
              roles: ['All']
            },
            {
              title: 'Cancelled',
              path: '/programs/cancelled',
              roles: ['ROLE_PROVIDER'],
              getCompanyType: true
            },
            {
              title: 'Waiting Approval',
              path: '/programs/approval',
              getCompanyType: true
            },
            {
              title: 'Schedule',
              path: '/programs/schedule',
              roles: ['All']
            },
            {
              title: 'Execute',
              path: '/programs/execute',
              roles: ['All']
            }
          ]
        },
        {
          title: 'Administration',
          class: 'administration',
          roles: ['ROLE_PROVIDER'],
          isCompanyAdmin: true,
          submenu: [
            {
              title: 'To be invoiced',
              path: '/administration/invoiceables',
              roles: ['ROLE_PROVIDER']
            },
            {
              title: 'Invoices',
              path: '/administration/invoices',
              roles: ['ROLE_PROVIDER']
            },
            {
              title: 'Profit Shares',
              path: '/administration/shareables',
              roles: ['ROLE_PROVIDER']
            },
            {
              title: 'Credit Notes',
              path: '/credit-notes',
              isCompanyAdmin: true
            },

            {
              title: 'Reserves',
              path: '/reserves/',
              isCompanyAdmin: true
            }
          ]
        },
        {
          title: 'Payments',
          class: 'payments',
          roles: ['ROLE_BAILIFF'],
          path: '/payments/incoming'
        },
        {
          title: 'Impact Analysis',
          path: '/impact-analysis',
          roles: ['ROLE_ACCOUNTANT']
        },
        {
          title: 'Scan QR code',
          class: 'programs',
          roles: ['ROLE_TRAINER'],
          modal: 'qrScanner'
        },
        {
          title: 'Requests',
          class: 'requests',
          path: '/requests',
          roles: ['ROLE_PROVIDER', 'ROLE_ACCOUNTANT', 'ROLE_ADMIN', 'ROLE_USER']
        }
      ],
      open: [],
      messageOnSideBar: false,
      stateMessage: false
    };
  },

  methods: {
    ...mapActions('chat', [CHAT_FETCH_ROOMS]),
    refreshData() {
      localStorage.setItem('currentPage', 1);
      this[CHAT_FETCH_ROOMS]();
    },
    openMenu(itemIndex, menuCanBeClosed = true) {
      if (
        this.open.length > 0 &&
        this.open.includes(itemIndex) &&
        menuCanBeClosed
      ) {
        const index = this.open.indexOf(itemIndex);

        if (index !== -1) {
          this.open.splice(index, 1);
        }

        return;
      }

      this.open.push(itemIndex);
    },
    toggleMessage() {
      this.stateMessage = !this.stateMessage;
    },

    checkIfMenuNeedsToBeOpen() {
      const CLOSE_MENU = false;
      let index;

      this.menu.forEach((menuItem, indexItem) => {
        if (this.$route.path === menuItem.path) {
          index = indexItem;
        } else {
          if (menuItem.submenu) {
            menuItem.submenu.forEach((subMenu) => {
              if (this.$route.path === subMenu.path) {
                index = indexItem;
              }
            });
          }
        }
      });

      if (index) {
        this.openMenu(index, CLOSE_MENU);
      }
    },

    canAdminView(item) {
      if (item.isCompanyAdmin && this.getCompany.entity.isAdmin) {
        return true;
      }

      return false;
    }
  },

  watch: {
    $route(to, from) {
      this.checkIfMenuNeedsToBeOpen();
      this.messageOnSideBar = to.path == '/chat';
    }
  }
};
</script>

<style scoped lang="scss">
.custom-tooltip {
  left: 105px !important;
}

#sidebar {
  width: 285px;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  background: $secondary;
  color: $white;
  transition: all 0.3s ease-out;

  &::-webkit-scrollbar {
    display: none;
  }

  .submenu {
    max-height: 0;
    opacity: 0;
    transition: all 0.2s linear;

    &__open {
      max-height: 100%;
      opacity: 1;
    }
  }

  .fa-angle-right {
    &__open {
      transform: rotate(90deg);
    }
  }

  .sidebar-header {
    padding: 20px 20px 0 20px;
    background: $secondary;

    a.logo {
      transition: all 0.2s ease-out;
      background-image: url('../../assets/img/icons/logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 155px;
      height: 53px;
      display: block;
      text-indent: -9999px;
      margin: 20px auto;
    }
  }

  &.active {
    margin-left: -209px;

    .fa-angle-right {
      display: none;
    }

    .sidebar-header {
      a.logo {
        background-image: url('../../assets/img/logo-mob.png');
        background-repeat: no-repeat;
        background-size: contain;
        width: 45px;
        height: 46px;
        display: block;
        text-indent: -9999px;
        text-align: right;
        margin-right: -5px;
      }
    }
  }

  ul {
    padding-left: 0px;

    li {
      list-style: none;
      //min-height: 20px;
      line-height: 40px;
      margin: 0;
      padding: 0;

      a {
        text-decoration: none;
        position: relative;
        width: 100%;
        display: block;
        color: $white;
        //// font-size: 16px;
        padding-left: 65px;
        transition: all 0.2s ease-out;

        // White SVG
        &:hover {
          &:before {
            filter: brightness(0) invert(1);
          }
        }

        &:before {
          transition: all 0.2s ease-out;
          position: absolute;
          left: 35px;
          background-image: url('../../assets/img/icons/menu/dashboard.svg');
          content: '';
          background-size: 15px;
          background-repeat: no-repeat;
          width: 20px;
          height: 25px;
          display: block;
          margin-top: 9px;
        }

        &:hover {
          background-color: $primary;
        }

        i {
          position: absolute;
          right: 25px;
          line-height: inherit;
          transition: all 0.2s ease-out;
        }

        &.collapsed {
          i.fa-chevron-down {
            transform: rotate(180deg);
          }
        }
      }

      &.active {
        height: 84vh;
        overflow: hidden;
        background-color: $primary;
      }

      // -------------- SUBMENU --------------
      .dropdown {
        li {
          height: 40px;
          line-height: 40px;

          a {
            text-indent: 20px;
            background-color: $secondary;

            &:before {
              margin-left: 25px;
              background-image: url('../../assets/img/icons/menuicons/submenu.png');
              width: 15px;
              height: 40px;
              margin-top: -1px;
            }

            &:hover,
            &.router-link-active {
              background-color: $primary;

              &:before {
                background-image: url('../../assets/img/icons/menuicons/submenu-active.png');
              }
            }
          }

          &.active {
            a {
              background-color: $primary;

              &:before {
                background-image: url('../../assets/img/icons/menuicons/submenu-active.png');
              }
            }
          }
        }
      }

      // -------------- EO SUBMENU --------------

      a.companies:before {
        background-image: url('../../assets/img/icons/menu/company.svg');
      }

      a.administration:before {
        background-image: url('../../assets/img/icons/menu/adminstration.svg');
      }

      a.programs:before {
        background-image: url('../../assets/img/icons/menu/program.svg');
      }

      a.proposals:before,
      a.reports:before {
        background-image: url('../../assets/img/icons/menu/program.svg');
      }

      a.dashboard:before {
        background-image: url('../../assets/img/icons/menu/dashboard.svg');
      }

      a.new-companies:before {
        background-image: url('../../assets/img/icons/menuicons/newcompany.png');
      }

      a.relations:before {
        background-image: url('../../assets/img/icons/menu/relations.svg');
      }

      a.prospect:before {
        background-image: url('../../assets/img/icons/menu/users.svg');
      }

      a.users:before {
        background-image: url('../../assets/img/icons/menu/users.svg');
      }

      a.payments:before {
        background-image: url('../../assets/img/icons/menu/requests.svg');
      }

      a.requests:before {
        background-image: url('../../assets/img/icons/menu/requests.svg');
      }

      a.contacts {
        &:before {
          filter: none;
        }
      }

      a.contacts:hover,
      a.contacts.active {
        &:before {
          filter: brightness(0) invert(1);
        }
      }

      a.contacts:before {
        background-image: url('../../assets/img/icons/menu/users.svg');
      }
    }
  }

  &.active {
    ul {
      li {
        a {
          &:before {
            right: 25px;
            left: auto;
          }
        }
      }
    }
  }
}
</style>
