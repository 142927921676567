<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group
      label="What is the name of the activity?"
      label-for="learningActivityName"
      :invalid-feedback="violations.title"
      :state="isValid('title')"
    >
      <b-form-input
        v-model="item.title"
        :state="isValid('title')"
        type="text"
        id="learningActivityName"
        placeholder="Type the name of the Activity..."
      />
    </b-form-group>

    <b-form-row>
      <b-col cols="6">
        <b-form-group
          label="Max number of learners"
          label-for="maxNumberLearners"
          :invalid-feedback="violations.maxLearners"
          :state="isValid('maxLearners')"
        >
          <b-form-input
            v-model="item.maxLearners"
            :state="isValid('maxLearners')"
            type="number"
            id="maxNumberLearners"
          />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Type of activity"
          label-for="typeActivity"
          :invalid-feedback="violations.type"
          :state="isValid('type')"
        >
          <b-form-select
            v-model="item.type"
            :state="isValid('type')"
            id="typeActivity"
          >
            <b-form-select-option value="ONLINE"
              >Online Activity</b-form-select-option
            >
            <b-form-select-option value="OFFLINE"
              >Offline Activity</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-row>
      <b-col cols="6">
        <b-form-group
          label="Duration (minutes)"
          label-for="duration"
          :invalid-feedback="violations.duration"
          :state="isValid('duration')"
        >
          <b-form-input
            v-model="item.duration"
            :state="isValid('duration')"
            type="number"
            id="duration"
          />
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-group
      label-cols="10"
      content-cols="2"
      label="Do you want to add Learning Materials?"
      label-for="isAddingMaterial2"
    >
      <b-form-checkbox
        class="float-right"
        id="isAddingMaterial2"
        v-model="isAddingMaterial"
        size="lg"
        switch
      ></b-form-checkbox>
    </b-form-group>
    <b-form-group
      label=""
      label-for="learningModuleLearningMaterial"
      v-if="isAddingMaterial"
    >
      <b-form-row>
        <b-col cols="9">
          <b-form-select v-if="materialsIsLoading" disabled value>
            <b-form-select-option selected value
              >Loading...
            </b-form-select-option>
          </b-form-select>
          <b-form-select
            v-else
            @change="addMaterial"
            v-model="newMaterial"
            id="learningModuleLearningMaterial"
            value
          >
            <b-form-select-option value disabled>
              {{ getMaterialSelectText }}
            </b-form-select-option>
            <b-form-select-option
              v-for="material in availableLearningMaterials"
              :key="material['@id']"
              :value="material['@id']"
              >{{ material.title }} ({{ material.author }})
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="3">
          <b-button v-b-modal.createLearningMaterial variant="primary" block
            >+ New
          </b-button>
        </b-col>
      </b-form-row>
    </b-form-group>

    <div class="mb-3" v-if="isAddingMaterial">
      <div
        class="alert alert-primary"
        v-for="(material, idx) in item.materials"
        :key="idx"
      >
        {{ getMaterialById(material).title }}
        <i>({{ getMaterialById(material).author }})</i>
        <a href="#" class="close" @click.prevent="delMaterial(idx)">×</a>
      </div>
    </div>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >

      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>

    <!-- Add learning activity modal -->
    <b-modal
      id="createLearningMaterialForActivity"
      title="Add Learning Material"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Learning Material</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createLearningMaterialForActivity')"
        ></i>
      </template>

      <LearningMaterialCreate
        :show-alert="false"
        @created="addMaterialFromCreate"
      ></LearningMaterialCreate>
    </b-modal>
  </b-form>
</template>

<script>
import { find, get, isUndefined, isString } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import LearningMaterialCreate from '../../../common/learningmaterial/Create.vue';

export default {
  components: {
    LearningMaterialCreate
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      newMaterial: '',
      isAddingMaterial: false
    };
  },

  mounted() {
    if (!this.item.materials) {
      this.$set(this.item, 'materials', []);
    }
    if (!this.item.type) {
      this.$set(this.item, 'type', 'ONLINE');
    }

    // Requests
    if (!this.materialsSelectItems) {
      this.materialsGetSelectItems();
    }
  },

  computed: {
    ...mapFields('learningmaterial/list', {
      materialsIsLoading: 'isLoading',
      materialsSelectItems: 'selectItems'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    availableLearningMaterials() {
      if (!this.materialsSelectItems) {
        return [];
      }
      if (!this.item.materials) {
        return this.materialsSelectItems || [];
      }

      let currentIds = this.item.materials;
      return this.materialsSelectItems.filter(
        (material) => !currentIds.includes(material['@id'])
      );
    }
  },

  methods: {
    ...mapActions({
      materialsGetSelectItems: 'learningmaterial/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    closeModal() {
      this.$bvModal.hide('createLearningActivity');
    },

    onSendForm(item) {
      // clean up materials if not using
      if (!this.isAddingMaterial) {
        item.materials = [];
      }

      // Casts
      item.maxLearners = parseInt(item.maxLearners);
      item.duration = parseInt(item.duration);

      this.handleSubmit(item);
    },

    // -------------- Materials --------------
    getMaterialById(materialId) {
      if (!this.materialsSelectItems) {
        return false;
      }
      let _id = isString(materialId) ? materialId : materialId['@id'];
      let ret = this.materialsSelectItems.find(
        (material) => material['@id'] === _id
      );
      // @TODO(trepatudo): ugh, ugly hack, we should push the created back and not refresh the data.
      if (!ret) {
        return { title: '', name: '' };
      }
      return ret;
    },

    addMaterial() {
      this.item.materials.push(this.newMaterial);
      this.newMaterial = '';
    },
    addMaterialFromCreate(createdMaterial) {
      // Refresh data
      this.materialsGetSelectItems();
      // Add new one automatically
      this.item.materials.push(createdMaterial['@id']);
    },
    delMaterial(materialIndex) {
      this.item.materials.splice(materialIndex, 1);
    }
  }
};
</script>
