<template>
  <div style="position: relative">
    <vue-bootstrap-typeahead
      ref="searchUserInput"
      :data="users"
      v-model="userSearch"
      :serializer="(s) => s.email"
      @hit="selectedUser = $event"
      size="sm"
      required
      placeholder="Enter email..."
    />
    <b-spinner
      v-if="isLoading"
      small
      style="
        position: absolute;
        right: 15px;
        top: 8px;
        z-index: 99999;
        color: #209aed;
      "
    ></b-spinner>
  </div>
</template>

<script>
import _ from 'lodash';
import { entityService } from '../../../utils/fetch';
import validators from '@/mixins/validators';

export default {
  name: 'UserSearchInput',

  props: {
    canInviteUser: {
      type: Boolean,
      default: false
    }
  },

  mixins: [validators],

  computed: {
    isEmailValid() {
      return this.validateEmail(this.userSearch);
    }
  },

  data() {
    return {
      isLoading: false,
      users: [],
      userSearch: null,
      userSearchDebounce: '',
      selectedUser: null,
      user: {}
    };
  },

  mounted() {
    this.$on('clear', this.clearUser);
  },

  methods: {
    clearUser() {
      this.$set(this, 'userSearch', '');
      this.$set(this, 'userSearchDebounce', '');
      this.$set(this, 'selectedUser', null);
      this.$set(this, 'user', {});
      this.$set(this, 'isLoading', false);
      // trepatudo: Horrible hack but this plugin sucks.
      this.$refs['searchUserInput'].$data['inputValue'] = '';
    },

    getUsers(query) {
      if (query.length === 0) {
        return;
      }
      entityService('/users?email=' + query)
        .then((response) => response.json())
        .then((data) => {
          this.users = data['hydra:member'];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    createUser() {
      this.isCreatingUser = true;

      this.user = {
        ...this.user,
        email: this.userSearch
      };

      entityService('/users', {
        method: 'POST',
        body: JSON.stringify(this.user)
      })
        .then((response) => response.json())
        .then((data) => {
          this.$emit('selected', { user: data, fetched: true });
        })
        .finally(() => {
          this.$bvModal.hide('inviteUser');
          this.clearUser();
        });
    }
  },

  watch: {
    users: function (users) {
      // Unique, is it perfect match:
      // If so, simulate pick event
      if (users.length === 1 && this.userSearch === users[0].email) {
        this.selectedUser = users[0];
      }
    },

    userSearch: function (userSearch) {
      this.isLoading = true;
      this.userSearchDebounce = userSearch;

      this.$emit('input', userSearch);

      if (userSearch !== this.selectedUser?.email) {
        this.$emit('selected', { user: userSearch, fetched: false });
      }
    },

    userSearchDebounce: _.debounce(function (userSearchDebounce) {
      this.getUsers(userSearchDebounce);
    }, 500),

    selectedUser: function (selectedUser) {
      this.isLoading = false;

      this.$emit('selected', { user: selectedUser, fetched: true });
    }
  }
};
</script>
