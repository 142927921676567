<template>
  <b-form-row>
    <b-col cols="12">
      <h3>The following schedules have been added:</h3>

      <b-form-row v-if="scheduleIsLoading">
        <b-spinner></b-spinner>
      </b-form-row>
      <b-form-row v-else>
        <b-form-row
          v-for="module in moduleItems"
          :key="module['@id']"
          class="bgGroup p-4 mb-2 font-weight-bolder"
        >
          <b-col cols="12">
            <h2 class="page-title">
              <b-icon class="mr-2" icon="calendar2" variant="primary"></b-icon>
              <strong>Module 1:</strong> {{ module.name }}
            </h2>
            <div
              v-for="(learningActivity, idx) in module.activities"
              :key="idx"
            >
              <h4 class="page-title mt-3 mb-3">
                <b-icon
                  class="mr-2"
                  icon="calendar3"
                  variant="primary"
                ></b-icon>
                <strong>Activity:</strong> {{ learningActivity.title }}

                <b-icon
                  class="ml-3 mr-2"
                  icon="person-badge"
                  variant="primary"
                ></b-icon>
                <strong>Teacher:</strong>&nbsp;{{
                  learningActivity.teacher.user.firstName
                }}
                {{ learningActivity.teacher.user.lastName }}
              </h4>

              <b-form-row>
                <b-col cols="6">
                  <b-card
                    border-variant="dark"
                    align="left"
                    style="background: #e9ebef !important"
                    v-for="(group, idx) in program.learnerGroups"
                    :key="group"
                  >
                    <b-card-title>
                      <span class="mr-2">
                        <b-icon
                          v-if="
                            getScheduleData(learningActivity['@id'], group)
                              .status === 'PENDING'
                          "
                          icon="check-circle-fill"
                          variant="warning"
                        ></b-icon>
                        <b-icon
                          v-else
                          icon="check-circle-fill"
                          variant="success"
                        ></b-icon>
                      </span>
                      <strong
                        >Group {{ idx + 1 }}: {{ getGroupData(group).name }}
                      </strong>
                    </b-card-title>
                    <b-card-text>
                      <div class="activity-data">
                        <p class="font-weight-normal activity-data">
                          <span>Date: </span>
                          {{
                            parseDate(
                              getScheduleData(learningActivity['@id'], group)
                                .startDateTime
                            )
                          }}
                          <br />
                          <span>Learners: </span>
                        </p>

                        <p
                          v-for="learner in getScheduleData(
                            learningActivity['@id'],
                            group
                          ).learners"
                          :key="learner['@id']"
                        >
                          <b-icon
                            v-if="learner.status === 'INVITED'"
                            icon="clock"
                            variant="warning"
                          ></b-icon>
                          <b-icon
                            v-else-if="learner.status === 'ATTENDED'"
                            icon="check-circle-fill"
                            variant="success"
                          ></b-icon>
                          <b-icon
                            v-else
                            icon="check-circle-fill"
                            variant="warning"
                          ></b-icon>
                          &nbsp;
                          <span
                            v-if="
                              learner.learner &&
                              learner.learner.user &&
                              learner.learner.user.firstName &&
                              learner.learner.user.lastName
                            "
                            >{{ learner.learner.user.firstName }}
                            {{ learner.learner.user.lastName }}</span
                          >
                          <span v-else><i>(unknown)</i></span>
                        </p>
                      </div>

                      <b-row
                        v-if="
                          getScheduleData(learningActivity['@id'], group)
                            .status === 'PENDING'
                        "
                      >
                        <b-col cols="12">
                          <b-button
                            v-if="
                              !getScheduleData(learningActivity['@id'], group)
                                .loadingExecute
                            "
                            block
                            size="sm"
                            variant="primary"
                            @click="
                              executeSchedule(
                                getScheduleData(learningActivity['@id'], group)
                              )
                            "
                            ><i class="fa fa-check"></i> Execute
                          </b-button>
                          <b-button v-else block disabled variant="primary">
                            <b-spinner small></b-spinner>
                          </b-button>
                        </b-col>
                        <b-col cols="12" class="mt-1">
                          <b-button
                            v-if="
                              !getScheduleData(learningActivity['@id'], group)
                                .loadingCancel
                            "
                            block
                            size="sm"
                            variant="danger"
                            @click="
                              cancelSchedule(
                                getScheduleData(learningActivity['@id'], group)
                              )
                            "
                            ><i class="fa fa-times"></i> Cancel
                          </b-button>
                          <b-button v-else block disabled variant="primary">
                            <b-spinner small></b-spinner>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card>
                </b-col>
              </b-form-row>
            </div>
          </b-col>
        </b-form-row>
        <!--        <div-->
        <!--          v-for="schedule in schedules"-->
        <!--          :key="schedule['@id']"-->
        <!--          class="col-md-12 viewModules"-->
        <!--        >-->
        <!--          <hr />-->
        <!--          <div class="content">-->
        <!--            <b-row>-->
        <!--              <b-col cols="6">-->
        <!--                <h6-->
        <!--                  v-html="-->
        <!--                    '<strong>Group:</strong> ' + schedule.learnersGroup.name-->
        <!--                  "-->
        <!--                ></h6>-->

        <!--                <h6-->
        <!--                  v-html="-->
        <!--                    '<strong>Learning Activity:</strong> ' +-->
        <!--                    schedule.learningActivity.title-->
        <!--                  "-->
        <!--                ></h6>-->
        <!--              </b-col>-->
      </b-form-row>
    </b-col>
  </b-form-row>
</template>

<script>
import { businessLogicService } from '@/utils/fetch';
import _ from 'lodash';
import helpers from '@/mixins/helpers';
import { SwalAlert } from '@/utils/swal';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  props: {
    program: {
      type: Object,
      required: true
    }
  },

  mixins: [helpers],

  data() {
    return {
      scheduleIsLoading: false,
      isMounted: false,
      formMetadata: {
        generalCosts: 0
      },
      schedules: []
    };
  },

  created() {
    this.getModules(`${this.program['@id']}/modules`);
    this.getSchedules();
  },

  computed: {
    ...mapFields('learningmodule/list', {
      moduleError: 'error',
      moduleItems: 'items',
      moduleIsLoading: 'isLoading'
    })
  },

  methods: {
    ...mapActions({
      getModules: 'learningmodule/list/default'
    }),

    getGroupData(groupId) {
      return this.schedules.filter((s) => s.learnersGroup['@id'] === groupId)[0]
        .learnersGroup;
    },

    getCombinationData(groupId, activityId) {},
    getScheduleData(activityId, groupId) {
      return this.schedules.filter(
        (s) =>
          s.learnersGroup['@id'] === groupId &&
          s.learningActivity['@id'] === activityId
      )[0];
    },
    async getSchedules() {
      const groups = this.program.learnerGroups;
      this.schedules = [];

      this.scheduleIsLoading = true;

      for (const group of groups) {
        const request = await businessLogicService(
          `${this.program['@id']}/learner-groups/${this.iriToId(
            group
          )}/schedules`
        );

        const response = await request.json();

        this.schedules = [...this.schedules, response['hydra:member']];
      }

      this.scheduleIsLoading = false;

      this.schedules = _.flatten(this.schedules);
    },
    confirmedSchedule(schedule) {
      this.$set(schedule, 'loadingExecute', true);
      businessLogicService(schedule['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({
          statusTransition: 'to_executed'
        })
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            this.$set(schedule, 'status', data.status);
            SwalAlert({
              title: 'Schedule executed',
              type: 'success',
              icon: 'user'
            });
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.$set(schedule, 'loadingExecute', false);
        });
    },
    canceledSchedule(schedule) {
      this.$set(schedule, 'loadingCancel', true);
      businessLogicService(schedule['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({
          statusTransition: 'to_cancelled'
        })
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            this.$set(schedule, 'status', data.status);
            SwalAlert({
              title: 'Schedule canceled',
              type: 'success',
              icon: 'user'
            });
          }
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.$set(schedule, 'loadingCancel', false);
        });
    },

    executeSchedule(schedule) {
      SwalAlert({
        title: 'Execute schedule',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.confirmedSchedule(schedule);
            }
          }
        }
      });
    },

    cancelSchedule(schedule) {
      SwalAlert({
        title: 'Execute schedule',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.canceledSchedule(schedule);
            }
          }
        }
      });
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD');
    }

    // }
  }
};
</script>
