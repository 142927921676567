<template>
  <div class="financial-analysis">
    <div class="financial-analysis__item">
      <div class="financial-analysis__item__content">
        <label
          class="financial-analysis__item__content__label"
          for="beneficiaryName"
          >Beneficiary name</label
        >
        <span
          class="financial-analysis__item__content__span"
          id="beneficiaryName"
          >{{ invoiceItem.creatorCustomer.name }}</span
        >
      </div>
    </div>

    <div class="financial-analysis__item">
      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.vatCheck"
      >
        <label class="financial-analysis__item__content__label" for="vatCheck"
          >VAT-Check</label
        >
        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.vatCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.vatCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.vatCheck.status}`"
          id="vatCheck"
        >
          {{ invoiceItem.vatCheck.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.employabilityCheck"
      >
        <label
          class="financial-analysis__item__content__label"
          for="educationVatCheck"
          >Employability Check</label
        >
        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.employabilityCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.employabilityCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.employabilityCheck.status}`"
          id="educationVatCheck"
          >{{ invoiceItem.employabilityCheck.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.correction9313Check"
      >
        <label
          class="financial-analysis__item__content__label"
          for="93Correction"
          >93/13 Check</label
        >

        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.correction9313Check.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.correction9313Check.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.correction9313Check.status}`"
          id="93Correction"
          >{{ invoiceItem.correction9313Check.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.surchargeCheck"
      >
        <label class="financial-analysis__item__content__label" for="surcharge"
          >Surcharge</label
        >

        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.surchargeCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.surchargeCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.surchargeCheck.status}`"
          id="surcharge"
          >{{ invoiceItem.surchargeCheck.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.learningMaterialCheck"
      >
        <label
          class="financial-analysis__item__content__label"
          for="permissionCheck"
          >Learning Material Check</label
        >

        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.learningMaterialCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.learningMaterialCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.learningMaterialCheck.status}`"
          id="learningMaterialCheck"
          >{{ invoiceItem.learningMaterialCheck.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.permissionCheck"
      >
        <label
          class="financial-analysis__item__content__label"
          for="permissionCheck"
          >Permission Check</label
        >
        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.permissionCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.permissionCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.permissionCheck.status}`"
          id="permissionCheck"
        >
          {{ invoiceItem.permissionCheck.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.amplificaFeeCheck"
      >
        <label
          class="financial-analysis__item__content__label"
          for="amplificaFeeCheck"
          >Amplifica Fee Check</label
        >

        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.amplificaFeeCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.amplificaFeeCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.amplificaFeeCheck.status}`"
          id="amplificaFeeCheck"
          >{{ invoiceItem.amplificaFeeCheck.reason }}</span
        >
      </div>

      <div
        class="financial-analysis__item__content"
        v-if="invoiceItem.practicaVATCheck"
      >
        <label
          class="financial-analysis__item__content__label"
          for="amplificaFeeCheck"
          >Practica VAT Check</label
        >

        <i
          class="fa financial-analysis__item__content__icon"
          :class="`fa-${getCheckIcon(
            invoiceItem.practicaVATCheck.status
          )} financial-analysis__item__content__icon--${
            invoiceItem.practicaVATCheck.status
          }`"
        ></i>

        <span
          class="financial-analysis__item__content__span"
          :class="`financial-analysis__item__content__span--${invoiceItem.practicaVATCheck.status}`"
          id="amplificaFeeCheck"
          >{{ invoiceItem.practicaVATCheck.reason }}</span
        >
      </div>
    </div>

    <div class="financial-analysis__item" v-if="invoiceItem.RIICalculation">
      <h1 class="financial-analysis__title">Charges</h1>

      <div class="financial-analysis__item__content">
        <label
          class="financial-analysis__item__content__label"
          for="correction9313"
          >Correction 93/13</label
        >
        <span
          class="financial-analysis__item__content__span"
          id="correction9313"
        >
          {{
            this.formatCurrency(invoiceItem.RIICalculation.correction9313Amount)
          }}
        </span>
      </div>

      <div class="financial-analysis__item__content">
        <label class="financial-analysis__item__content__label" for="surcharge"
          >Surcharge</label
        >
        <span class="financial-analysis__item__content__span" id="surcharge">
          {{ this.formatCurrency(invoiceItem.RIICalculation.surchargeAmount) }}
        </span>
      </div>

      <div class="financial-analysis__item__content">
        <label
          class="financial-analysis__item__content__label"
          for="amplificaFee"
          >Amplifica Fee</label
        >
        <span class="financial-analysis__item__content__span" id="amplificaFee">
          {{
            this.formatCurrency(invoiceItem.RIICalculation.amplificaFeeAmount)
          }}
        </span>
      </div>

      <div class="financial-analysis__item__content">
        <label class="financial-analysis__item__content__label" for="riiAmount">
          Practica VAT
        </label>
        <span class="financial-analysis__item__content__span" id="riiAmount">
          {{ this.formatCurrency(invoiceItem.RIICalculation.unt) }}
          />
        </span>
      </div>

      <div class="financial-analysis__item__content">
        <label class="financial-analysis__item__content__label" for="riiAmount">
          RII Amount
        </label>
        <span class="financial-analysis__item__content__span" id="riiAmount">
          {{
            this.formatCurrency(
              invoiceItem.RIICalculation.totalAmountCorrection
            )
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';

export default {
  name: 'FinancialAnalysis',

  mixins: [helpers],
  props: {
    invoiceItem: {
      type: Object,
      required: true
    }
  },

  methods: {
    getCheckIcon(status) {
      let icon;

      switch (status) {
        case 'ERROR':
          icon = 'times';
          break;
        case 'WARNING':
          icon = 'exclamation';
          break;
        case 'PENDING':
          icon = 'clock';
          break;
        case 'SKIP':
          icon = 'angle-double-right';
          break;
        default:
          icon = 'check';
      }

      return icon;
    }
  }
};
</script>

<style lang="scss">
.financial-analysis {
  margin-top: 25px;

  &__item {
    margin-top: 20px;

    border-top: 1px solid $black;

    padding: 30px 0px 30px 0px;

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      &__label {
        max-width: 150px;
        width: 20%;
      }

      &__span {
        background: $gray-200;
        border-radius: 10px;
        padding: 10px;
        width: 80%;

        &--PENDING {
          background: rgba($gray-500, 0.3);
        }

        &--SKIP {
          background: rgba($primary, 0.3);
        }

        &--OK {
          background: rgba($success, 0.3);
        }

        &--ERROR {
          background: rgba($danger, 0.3);
        }

        &--WARNING {
          background: rgba($warning, 0.3);
        }
      }

      &__icon {
        margin-right: 15px;
        padding: 15px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-size: 14px;

        &--SKIP {
          color: $primary;
          background: rgba($primary, 0.3);
        }

        &--PENDING {
          color: $gray-500;
          background: rgba($gray-500, 0.3);
        }

        &--OK {
          color: $success;
          background: rgba($success, 0.3);
        }

        &--ERROR {
          color: $danger;
          background: rgba($danger, 0.3);
        }

        &--WARNING {
          color: $warning;
          background: rgba($warning, 0.3);
        }
      }
    }
  }

  &__title {
    // font-size: 24px;
    color: $black;
    font-weight: 600;
  }
}
</style>
