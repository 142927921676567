<template>
  <div class="headquarters mt-5">
    <span class="headquarters__title">Headquarters Address</span>

    <div class="text-center text-danger my-2" v-if="isLoading">
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>

    <AddressForm
      class="mt-3"
      v-else
      :handle-submit="onSendForm"
      :is-loading="isLoading"
      :values="item"
      :skipLastStep="true"
      :showPrevious="false"
      :isDisabled="disabled"
    >
      <template class="mt-5" v-slot:submitButton>
        <b-button
          type="button"
          variant="secondary mr-2"
          :disabled="isLoading"
          @click="$bvModal.hide('editCompany')"
          >Cancel</b-button
        >
        <b-button type="submit" variant="primary" v-if="!isSubmitting"
          >Save</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </template>
    </AddressForm>
  </div>
</template>

<script>
import AddressForm from '@/components/common/address/Form';

import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Address',

  components: {
    AddressForm
  },

  props: {
    company: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields('establishment/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    }),

    item() {
      return this.retrieved || {};
    }
  },

  data() {
    return {
      isSubmitting: false
    };
  },

  created() {
    const establishmentId = this.company.address['@id'];
    this.retrieve(establishmentId);
  },

  methods: {
    ...mapActions({
      retrieve: 'establishment/update/retrieve',
      updateReset: 'establishment/update/reset',
      update: 'establishment/update/update',
      updateRetrieved: 'establishment/update/updateRetrieved'
    }),

    onSendForm() {
      this.update(this.retrieved);
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated');
    }
  }
};
</script>

<style lang="scss">
.headquarters {
  &__title {
    padding: 20px 0px;
    font-weight: 800;
    // font-size: 14px;
    color: $black;
  }
}
</style>
