<template>
  <form @submit.prevent="onSendForm(item)">
    <b-row>
      <b-col cols="12">
        <b-form-group label="VAT Number" label-for="vat">
          <b-form-input id="vat" :value="item.related.fiscal_id" disabled>
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group label="Company Name" label-for="company">
          <b-form-input id="company" :value="item.related.name" disabled>
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Tradename" label-for="trandename">
          <b-form-input
            id="trandename"
            :value="item.related.tradename"
            disabled
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div v-if="item.related.address">
      <b-row>
        <b-col cols="6">
          <b-form-group label="Street" label-for="street">
            <b-form-input
              id="street"
              :value="item.related.address.street"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Number" label-for="number">
            <b-form-input
              id="street"
              :value="item.related.address.number"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="Postal Code" label-for="postalCode">
            <b-form-input
              id="poscalCode"
              :value="item.related.address.zip"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="City" label-for="city">
            <b-form-input id="city" :value="item.related.address.city" disabled>
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group label="State" label-for="state">
            <b-form-input
              id="state"
              :value="item.related.address.state"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Country" label-for="country">
            <b-form-input
              id="country"
              :value="item.related.address.country.name"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-form-group label="Primary Accountant" label-for="primaryAccountant">
      <b-form-checkbox
        id="primaryAccountant"
        v-model="isDefault"
        :disabled="isSelectDisabled"
        :checked="true"
        :unchecked-value="false"
      ></b-form-checkbox>
    </b-form-group>

    <b-button-group class="float-right">
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button v-else disabled type="submit" variant="primary">
        <b-spinner small />
      </b-button>
    </b-button-group>
  </form>
</template>

<script>
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isSelectDisabled: false,
      isDefault: false
    };
  },

  mounted() {
    this.isSelectDisabled = this.item.isDefault;
    this.isDefault = this.item.isDefault;
  },

  methods: {
    onSendForm() {
      const primaryAccountant = this.isDefault
        ? this.item.related['@id']
        : null;

      this.$emit('setPrimaryAccountant', primaryAccountant);
    }
  }
};
</script>
