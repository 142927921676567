<template>
  <div class="aprova-report__form">
    <div v-if="!isLoading">
      <div class="aprova-report__form__items">
        <!-- <div class="aprova-report__form__item">
        <label for="documentStatus">Document status:</label>
        <span id="documentStatus">{{ item.status }}</span>
      </div> -->

        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.amplificaGeneralTermsVersion"
        >
          <label for="termsVersion">AmplificA General Terms version:</label>
          <span id="termsVersion">{{
            iriToId(
              aprovaReport.creatorInformation.amplificaGeneralTermsVersion
            )
          }}</span>
        </div>
      </div>

      <hr class="aprova-report__form__hr" />

      <div class="aprova-report__form__items">
        <h1 class="aprova-report__form__subtitle">Creator Information</h1>
        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.name"
        >
          <label for="documentStatus">Name:</label>
          <span id="documentStatus">{{
            aprovaReport.creatorInformation.name
          }}</span>
        </div>

        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.vatNumber"
        >
          <label for="creationDate">VAT Number:</label>
          <span id="creationDate">{{
            aprovaReport.creatorInformation.vatNumber
          }}</span>
        </div>

        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.fullAddress"
        >
          <label for="version">Address:</label>
          <span id="version">{{
            aprovaReport.creatorInformation.fullAddress
          }}</span>
        </div>
      </div>

      <div class="aprova-report__form__items">
        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.governingAuthorityCountryName"
        >
          <label for="documentStatus">Governing Authority:</label>
          <span id="documentStatus">{{
            aprovaReport.creatorInformation.governingAuthorityCountryName
          }}</span>
        </div>

        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.primaryContactName"
        >
          <label for="version">Primary Contact:</label>
          <span id="version">{{
            aprovaReport.creatorInformation.primaryContactName
          }}</span>
        </div>

        <div
          class="aprova-report__form__item"
          v-if="aprovaReport.creatorInformation.email"
        >
          <label for="version">Email Address:</label>
          <span id="version">{{ aprovaReport.creatorInformation.email }}</span>
        </div>
      </div>

      <template>
        <div class="aprova-report__form__items">
          <div
            class="aprova-report__form__item"
            v-if="aprovaReport.creatorInformation.accountantFirmName"
          >
            <label for="documentStatus">Responsible Accountancy Firm:</label>
            <span id="documentStatus">{{
              aprovaReport.creatorInformation.accountantFirmName
            }}</span>
          </div>

          <div
            class="aprova-report__form__item"
            v-if="aprovaReport.creatorInformation.accountantPersonName"
          >
            <label for="creationDate">Responsible Accountant:</label>
            <span id="creationDate">{{
              aprovaReport.creatorInformation.accountantPersonName
            }}</span>
          </div>

          <div
            class="aprova-report__form__item"
            v-if="
              aprovaReport.creatorInformation.accountantPersonRegistrationNumber
            "
          >
            <label for="nbaRegistrationNumber">NBA Registration Number:</label>
            <span id="nbaRegistrationNumber">{{
              aprovaReport.creatorInformation.accountantPersonRegistrationNumber
            }}</span>
          </div>

          <div
            class="aprova-report__form__item"
            v-if="aprovaReport.creatorInformation.accountantPersonEmail"
          >
            <label for="accountantEmail">Email Address:</label>
            <span id="accountantEmail">{{
              aprovaReport.creatorInformation.accountantPersonEmail
            }}</span>
          </div>

          <div
            class="aprova-report__form__item"
            v-if="aprovaReport.creatorInformation.amplificaGeneralTermsVersion"
          >
            <label for="termsVersion">AmplificA General Terms version:</label>
            <span id="termsVersion">{{
              iriToId(
                aprovaReport.creatorInformation.amplificaGeneralTermsVersion
              )
            }}</span>
          </div>

          <div
            class="aprova-report__form__item"
            v-if="
              aprovaReport.creatorInformation.amplificaGeneralTermsApprovedDate
            "
          >
            <label for="termsApprovedDate"
              >AmplificA General Terms approved date:</label
            >
            <span id="termsApprovedDate">{{
              formatDate(
                aprovaReport.creatorInformation
                  .amplificaGeneralTermsApprovedDate
              )
            }}</span>
          </div>

          <div
            class="aprova-report__form__item"
            v-if="
              aprovaReport.creatorInformation.amplificaGeneralTermsApprovedBy
            "
          >
            <label for="termsApprovedBy"
              >Amplifica General Terms approved by:</label
            >
            <span id="termsApprovedBy">{{
              aprovaReport.creatorInformation.amplificaGeneralTermsApprovedBy
                .email
            }}</span>
          </div>
        </div>
      </template>

      <hr class="aprova-report__form__hr" />
    </div>

    <div class="text-center" v-else>
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';

export default {
  name: 'General',

  mixins: [helpers],

  props: {
    aprovaReport: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
