<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <CompanyUserForm
      :showAllUsers="showAllUsers"
      :hide="hide"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
      :is-loading="isLoading"
      :user="user"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import CompanyUserForm from './Form';
import { SwalAlert } from '../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'companyuser/create/getField',
  mutationType: 'companyuser/create/updateField'
});

export default {
  components: {
    CompanyUserForm
  },

  data() {
    return {
      item: this.initialItem || {}
    };
  },

  props: {
    hide: {
      type: Array,
      required: false
    },
    initialItem: {
      type: Object,
      required: false
    },
    showAllUsers: {
      type: Boolean,
      default: true
    },
    user: {
      type: String
    }
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    created: function (created) {
      if (!created) {
        return;
      }
      this.$emit('created', created);
      this.$bvModal.hide('companyUserCreate');
      SwalAlert({
        title: 'Employee added',
        icon: 'user',
        type: 'success',
        description:
          'We added the user e-mail to your employee-list, for new users we sended an e-mail for registration',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  methods: {
    ...mapActions('companyuser/create', ['create']),

    onSendForm(item) {
      this.create(item);
    }
  }
};
</script>
