import HandshakeShow from '@/components/handshake/Show';

export default [
  {
    name: 'HandshakesList',
    path: '/handshakes/:id',
    component: HandshakeShow,
    props: true
  }
];
