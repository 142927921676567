export const RESET = 'LEARNINGACTIVITYSUBCONTRACTOR_LIST_RESET';
export const SET_ITEMS = 'LEARNINGACTIVITYSUBCONTRACTOR_LIST_SET_ITEMS';
export const SET_ERROR = 'LEARNINGACTIVITYSUBCONTRACTOR_LIST_SET_ERROR';
export const SET_SELECT_ITEMS =
  'LEARNINGACTIVITYSUBCONTRACTOR_LIST_SET_SELECT_ITEMS';
export const SET_VIEW = 'LEARNINGACTIVITYSUBCONTRACTOR_LIST_SET_VIEW';
export const SET_TOTAL_ITEMS =
  'LEARNINGACTIVITYSUBCONTRACTOR_LIST_SET_TOTAL_ITEMS';
export const TOGGLE_LOADING =
  'LEARNINGACTIVITYSUBCONTRACTOR_LIST_TOGGLE_LOADING';
