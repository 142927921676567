import WaitingList from '@/components/dashboard/program/waiting/List.vue';

import Default from '@/views/dashboard/Programs/Default';

export default [
  {
    path: '/programs/approval',
    component: Default,
    children: [
      {
        name: 'WaitingList',
        path: '/programs/approval/',
        component: WaitingList
      }
    ]
  }
];
