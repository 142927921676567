<template>
  <div class="show__content" v-if="handshake">
    <div class="show__item" v-if="canAcceptOrReject">
      <div class="d-flex">
        <span class="show__item__title show__item__title--black"
          >Is the following data input correct?
        </span>
        <b-form-checkbox
          class="ml-5"
          id="acceptCompany"
          v-model="acceptCompany"
          name="acceptCompany"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </div>
    </div>

    <div class="show__item" v-if="handshake.company.name">
      <span class="show__item__title">Name: </span>
      <span class="show__item__value">{{ handshake.company.name }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.tradename">
      <span class="show__item__title">Trade Name: </span>
      <span class="show__item__value">{{ handshake.company.tradename }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.fiscal_id">
      <span class="show__item__title">Fiscal ID: </span>
      <span class="show__item__value">{{ handshake.company.fiscal_id }}</span>
    </div>

    <div class="show__item" v-if="handshake.company.email">
      <span class="show__item__title">Email: </span>
      <span class="show__item__value">{{ handshake.company.email }}</span>
    </div>

    <div class="show__item" v-if="handshake.metadata.accountant">
      <span class="show__item__title">Accountant Number: </span>
      <span class="show__item__value"
        >{{ handshake.metadata.accountant.number }}
      </span>
    </div>

    <div class="show__item" v-if="handshake.metadata.accountant">
      <span class="show__item__title"
        >Accountant Professional Organization:
      </span>
      <span class="show__item__value"
        >{{ handshake.metadata.accountant.professionalOrganization }}
      </span>
    </div>

    <div class="show__item" v-if="handshake.metadata.accountant">
      <span class="show__item__title">Accountant Type: </span>
      <span class="show__item__value"
        >{{ handshake.metadata.accountant.type }}
      </span>
    </div>

    <div class="show__item" v-if="handshake.company.phone">
      <span class="show__item__title">Phone: </span>
      <span class="show__item__value">{{ handshake.company.phone }}</span>
    </div>

    <div class="show__item" v-if="canAcceptOrReject">
      <div class="d-flex">
        <span class="show__item__title show__item__title--black"
          >Are the company administrators allowed to act on behalf of the
          company?
        </span>
        <b-form-checkbox
          class="ml-5"
          id="admins"
          v-model="areAdminsAccepted"
          name="admins"
          :value="true"
          :unchecked-value="false"
        >
        </b-form-checkbox>
      </div>
    </div>

    <div class="show__item">
      <span class="show__item__title">Admins: </span>
      <div class="show__list">
        <ul>
          <li v-for="(employee, index) in admins" :key="index">
            <span class="show__item__value"
              >{{ employee.user.firstName }} {{ employee.user.lastName }} (
              {{ employee.user.email }} )</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountantSignup',

  props: {
    handshake: {
      type: Object,
      required: true
    },
    admins: {
      type: [Array, Boolean]
    },
    canAcceptOrReject: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    canSubmit() {
      return this.areAdminsAccepted && this.acceptCompany;
    }
  },

  data() {
    return {
      areAdminsAccepted: false,
      acceptCompany: false
    };
  },
  watch: {
    canSubmit: function (newVal) {
      this.$emit('isSubmitAllowed', newVal);
    }
  }
};
</script>
