// -------------- Company --------------
import CompanyViews from './company';
import Default from '@/views/layout/Default';
import Request from '@/views/layout/tabbed/Request';
import Government from '@/views/layout/tabbed/Government';
import Toolbox from '@/views/layout/tabbed/Toolbox';

// -------------- User --------------
import UserViews from './user';

// -------------- Relationship --------------
import RelationshipViews from './relationship/index';

//  -------------- MyCompany --------------
import CompanyUserViews from './company';

//  -------------- Chat --------------
import Chat from '../../views/Chat';

//  -------------- My --------------
import My from '@/views/dashboard/My/Main';
import MyViews from './my/index';

// -------------- Program Subtree --------------
import ProgramViews from './program/index';

// -------------- Administration --------------
import AdministrationViews from './administration';

// -------------- Payment --------------
import PaymentViews from './payment';

// -------------- Credit Notes --------------
import CreditNotesViews from './creditNotes';

// -------------- Prospect --------------
import ProspectViews from './prospect';

// -------------- Impact Analysis --------------

import ImpactAnalysisViews from './impactAnalysis';

// -------------- Handshake Analysis --------------

import HandshakesViews from './handshakes';

import RequestViews from './request';
import ReservesViews from './reserves';

import ToolboxViews from './toolbox';

import GovernmentViews from './goverment/goverment';
import BailiffViews from './bailiff/bailiff';

export default [
  // -------------- ADMIN --------------
  { path: '/companies', component: Default, children: [...CompanyViews] },
  { path: '/users', component: Default, children: [...UserViews] },
  { path: '/prospect', component: Default, children: [...ProspectViews] },
  {
    path: '/impact-analysis',
    component: Default,
    children: [...ImpactAnalysisViews]
  },

  // -------------- USER MANAGEMENT --------------
  {
    path: '/my',
    component: My,
    children: [...MyViews]
  },

  // -------------- Providers --------------
  {
    path: '/relationships',
    component: Default,
    children: [...RelationshipViews]
  },
  {
    path: '/administration',
    component: Default,
    children: [...AdministrationViews]
  },
  {
    path: '/credit-notes',
    component: Default,
    children: [...CreditNotesViews]
  },
  {
    path: '/handshakes',
    component: Default,
    children: [...HandshakesViews]
  },
  {
    path: '/requests',
    component: Request,
    children: [...RequestViews]
  },

  {
    path: '/reserves',
    component: Default,
    children: [...ReservesViews]
  },
  {
    path: '/government',
    component: Government,
    children: [...GovernmentViews]
  },
  {
    path: '/bailiff',
    component: Default,
    children: [...BailiffViews]
  },

  {
    path: '/toolbox',
    component: Toolbox,
    children: [...ToolboxViews]
  },

  ...CompanyUserViews,
  ...ProgramViews,
  ...PaymentViews
];
