<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <BankAccountForm
      v-if="retrieved"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import BankAccountForm from './Form.vue';
import { SwalAlert } from '../../utils/swal';

export default {
  components: {
    BankAccountForm
  },

  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapFields('bankaccount/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),
    ...mapFields('bankaccount/create', {
      created: 'created'
    }),
    ...mapFields('bankaccount/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated', updated);
      this.$bvModal.hide('updateFinancialInformation');

      SwalAlert({
        title: 'Financial Information updated',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('updateFinancialInformation');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.item['@id']);
  },

  methods: {
    ...mapActions({
      createReset: 'bankaccount/create/reset',
      deleteItem: 'bankaccount/del/del',
      delReset: 'bankaccount/del/reset',
      retrieve: 'bankaccount/update/retrieve',
      updateReset: 'bankaccount/update/reset',
      update: 'bankaccount/update/update',
      updateRetrieved: 'bankaccount/update/updateRetrieved'
    }),

    del() {
      if (
        window.confirm('Are you sure you want to delete this bankaccount ?')
      ) {
        this.deleteItem(this.retrieved);
      }
    },

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm() {
      this.update(this.retrieved);
    }
  }
};
</script>
