<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="../../assets/img/icons/menuicons/relations@2x.png"
      />
      <h3 class="font-weight-bold mt-5">
        Financial
        <p class="financial__description">Bankaccount(s)</p>
      </h3>
    </div>

    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="bankaccount"
      @changePage="changePage"
    >
      <template v-slot:isDefault="data">
        <Status class="text-center" :value="data.item.isDefault" />
      </template>

      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
        >
          <i class="fas fa-edit text-info"></i>
        </a>
      </template>
      <template #buttons>
        <b-button
          @click="$bvModal.show('createFinancialInformation')"
          variant="primary"
        >
          <i class="fas fa-plus"></i>
          Add Bank Account
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      id="createFinancialInformation"
      hide-footer
      title="Add New Financial Information"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title"
            >Add new Financial Information</span
          >
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createFinancialInformation')"
        ></i>
      </template>

      <FinancialInformationCreate @created="refreshData" />
    </b-modal>

    <b-modal
      id="updateFinancialInformation"
      hide-footer
      title="Update Financial Information"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title"
            >Update Financial Information</span
          >
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateFinancialInformation')"
        ></i>
      </template>

      <FinancialInformationUpdate
        @updated="refreshData"
        :item="financialToEdit"
      />
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import FinancialInformationCreate from './Create.vue';
import FinancialInformationUpdate from './Update.vue';
import Status from '@/components/_ui/tables/Status';

export default {
  components: {
    CustomTable,
    FinancialInformationCreate,
    FinancialInformationUpdate,
    Status
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId']),
    ...mapFields('bankaccount/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = `${this.getCompanyId}/bank-accounts?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      return request;
    }
  },

  data() {
    return {
      financialToEdit: {},
      fields: [
        { key: 'company.name', label: 'Company Name', sortable: true },
        { key: 'holderName', label: 'Holder Name', sortable: true },
        { key: 'IBAN', label: 'IBAN', sortable: true },
        { key: 'isDefault', label: 'Default', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'bankaccount/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.financialToEdit = item;
      this.$bvModal.show('updateFinancialInformation');
    }
  }
};
</script>

<style lang="scss">
.financial {
  &__description {
    // font-size: 12px;
    color: $gray-400;
    margin-top: 5px;
  }
}
</style>
