import Account from '@/components/dashboard/my/profile/Account';
import Personal from '@/components/dashboard/my/profile/Personal';
import AccountantInfo from '@/components/dashboard/my/profile/AccountantInfo';
import PrivacyAndSecurity from '@/components/dashboard/my/profile/PrivacyAndSecurity';

export default [
  { name: 'Profile', path: '/my/profile', redirect: '/my/profile/account' },

  { name: 'Account', path: '/my/profile/account', component: Account },
  { name: 'Personal', path: '/my/profile/personal', component: Personal },
  {
    name: 'AprovaSettings',
    path: '/my/profile/privacy-security',
    component: PrivacyAndSecurity
  },

  {
    name: 'AccountantInfo',
    path: '/my/profile/accountant-info',
    component: AccountantInfo
  }
];
