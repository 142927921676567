import UserList from '@/components/dashboard/user/List';
import UserCreate from '@/components/dashboard/user/Create';
import UserUpdate from '@/components/dashboard/user/Update';
import UserShow from '@/components/dashboard/user/Show';

export default [
  { name: 'UserList', path: '/users', component: UserList },
  { name: 'UserCreate', path: '/users/create', component: UserCreate },
  { name: 'UserUpdate', path: '/users/edit/:id', component: UserUpdate },
  { name: 'UserShow', path: '/users/show/:id', component: UserShow }
];
