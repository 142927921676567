<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Creator+ CompanyID"
          label-for="creatorCompany"
          :invalid-feedback="violations.creatorCompany"
          :state="!isValid('creatorCompany')"
        >
          <b-form-input
            id="creatorCompany"
            v-model="item.creatorCompany"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          label="Fiscal ID"
          label-for="fiscalId"
          :invalid-feedback="violations.fiscalId"
          :state="!isValid('fiscalId')"
        >
          <b-form-input
            id="fiscal_id"
            v-model="item.fiscalId"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Type"
          label-for="type"
          :invalid-feedback="violations.type"
          :state="!isValid('type')"
        >
          <b-form-select
            id="type"
            v-model="item.type"
            :options="companyType"
            :disabled="!canEdit"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Country"
          label-for="country"
          class="font-weight-bold"
        >
          <v-select
            required
            v-model="item.country"
            id="company_fiscal_id_country"
            :options="countrySelectItems"
            label="name"
            :disabled="!canEdit"
          >
            <template v-slot:option="option">
              {{ option.name }}
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Email Address"
          label-for="email"
          :invalid-feedback="violations.email"
          :state="!isValid('email')"
        >
          <b-form-input
            id="email"
            v-model="item.email"
            type="text"
            placeholder="Email..."
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Name"
          label-for="name"
          :invalid-feedback="violations.name"
          :state="!isValid('name')"
          :disabled="!canEdit"
        >
          <b-form-input
            id="name"
            v-model="item.name"
            type="text"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Phone"
          label-for="phone"
          :invalid-feedback="violations.phone"
          :state="!isValid('phone')"
        >
          <b-form-input
            id="email"
            v-model="item.phone"
            type="text"
            placeholder="Phone..."
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="IBAN"
          label-for="iban"
          :invalid-feedback="violations.iban"
          :state="!isValid('iban')"
        >
          <b-form-input
            id="iban"
            v-model="item.iban"
            type="text"
            placeholder="Iban..."
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Iban Holder"
          label-for="ibanHolder"
          :invalid-feedback="violations.ibanHolder"
          :state="!isValid('ibanHolder')"
        >
          <b-form-input
            id="ibanHolder"
            v-model="item.ibanHolder"
            type="text"
            placeholder="Iban Holder..."
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Contact First Name"
          label-for="contactFirstName"
          :invalid-feedback="violations.contactFirstName"
          :state="!isValid('contactFirstName')"
        >
          <b-form-input
            id="contactFirstName"
            v-model="item.contactFirstName"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Contact Last Name"
          label-for="contactLastName"
          :invalid-feedback="violations.contactLastName"
          :state="!isValid('contactLastName')"
        >
          <b-form-input
            id="contactLastName"
            v-model="item.contactLastName"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Contact Email"
          label-for="contactEmail"
          :invalid-feedback="violations.contactEmail"
          :state="!isValid('contactEmail')"
        >
          <b-form-input
            id="contactEmail"
            v-model="item.contactEmail"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="State"
          label-for="addressState"
          :invalid-feedback="violations.addressState"
          :state="!isValid('addressState')"
        >
          <b-form-input
            id="addressState"
            v-model="item.addressState"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Street"
          label-for="addressStreet"
          :invalid-feedback="violations.addressStreet"
          :state="!isValid('addressStreet')"
        >
          <b-form-input
            id="addressStreet"
            v-model="item.addressStreet"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group
          label="Postal Code"
          label-for="addressZip"
          :invalid-feedback="violations.addressZip"
          :state="!isValid('addressZip')"
        >
          <b-form-input
            id="addressState"
            v-model="item.addressZip"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group
          label="Number"
          label-for="addressNumber"
          :invalid-feedback="violations.addressNumber"
          :state="!isValid('addressNumber')"
        >
          <b-form-input
            id="addressNumber"
            v-model="item.addressNumber"
            type="text"
            required="required"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Sector" label-for="company_sector">
          <b-form-select
            id="company_sector"
            :options="sectorItems"
            v-model="item.sector"
            text-field="name"
            value-field="@id"
            type="text"
            :disabled="!canEdit"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Chamber of Commerce"
          label-for="chamberOfCommerce"
          :invalid-feedback="violations.chamberOfCommerce"
          :state="!isValid('chamberOfCommerce')"
        >
          <b-form-input
            id="chamberOfCommerce"
            v-model="item.chamberOfCommerce"
            type="text"
            placeholder=""
            :disabled="!canEdit"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group class="float-right" v-if="canEdit">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>

    <div class="show__actions" v-else>
      <CustomButton
        class="show__actions--reject"
        type="Default"
        :content="{ title: 'Reject' }"
        @click.native="handshakeAcceptOrReject(handshakeId, 'to_rejected')"
      />
      <CustomButton
        v-if="acceptCompany && acceptAdmins"
        class="show__actions--accept"
        type="Default"
        :content="{ title: 'Accept' }"
        @click.native="handshakeAcceptOrReject(handshakeId, 'to_approved')"
      />
    </div>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import handshakes from '@/mixins/handshakes';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    canEdit: {
      type: Boolean,
      default: true
    },

    handshakeId: {
      type: String,
      default: null
    }
  },

  mixins: [handshakes],

  mounted() {
    this.getSectorItems();
    this.getCountries();

    if (this.values.sector) {
      this.item.sector = this.values.sector['@id'];
    }
  },

  computed: {
    ...mapFields('sector/list', {
      items: 'selectItems'
    }),
    ...mapFields('country/list', {
      countrySelectItems: 'items'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    sectorItems() {
      return this.sectors || this.items;
    }
  },

  data() {
    return {
      sectors: null,
      companyType: [
        'COMMERCIAL',
        'GOVERNMENTAL',
        'PERSON',
        'FISCAL_UNITY',
        'OTHER'
      ]
    };
  },

  methods: {
    ...mapActions({}),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('editImport');
    },
    ...mapActions({
      getCountries: 'country/list/default',
      getSectorItems: 'sector/list/getSelectItems'
    })
  },

  watch: {
    'item.type': {
      handler: function (newVal) {
        if (newVal === 'COMMERCIAL') {
          this.sectors = this.items.filter((item) => {
            return (
              item.name !== 'Vocational Educatee' && item.name !== 'Government'
            );
          });
        } else if (newVal === 'PERSON') {
          this.sectors = this.items.filter((item) => {
            return item.name === 'Vocational Educatee';
          });
        } else if (newVal === 'GOVERNMENTAL') {
          this.sectors = this.items.filter((item) => {
            return item.name === 'Government';
          });
        } else {
          this.sectors = null;
        }
      },
      immediate: true
    }
  }
};
</script>
