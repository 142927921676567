<template>
  <div>
    <TabGroup :tabs="tabs" :invoiceItem="invoiceItem" :merged="merged" />
  </div>
</template>

<script>
import TabGroup from '@/components/_ui/tabs/TabGroup';
import { entityService } from '@/utils/fetch';

export default {
  name: 'UpdateFinancial',

  components: {
    TabGroup
  },

  props: {
    invoiceItem: {
      type: Object,
      required: true
    }
  },

  mounted() {
    if (this.invoiceItem.status === 'VALID') {
      this.tabs.splice(2, 1);
    }

    if (this.invoiceItem.isMerged) {
      this.fetchMerged();
    } else {
      this.findTab();
    }
  },

  data() {
    return {
      tabs: [
        {
          active: 'FinancialGeneral',
          text: 'General',
          visible: true
        },
        {
          active: 'FinancialAnalysis',
          text: 'Analysis',
          visible: true
        },

        {
          active: 'NewInvoices',
          text: 'New Invoices',
          visible: true
        },

        {
          active: 'Merged',
          text: 'Merged',
          visible: true
        }
      ],
      merged: []
    };
  },

  methods: {
    fetchMerged() {
      entityService(`${this.invoiceItem['@id']}/merged-invoices`)
        .then((response) => response.json())
        .then((data) => {
          this.merged = data['hydra:member'];
        });
    },

    findTab() {
      const tab = this.tabs.find((tab) => tab.active === 'Merged');
      tab.visible = false;
    }
  }
};
</script>
