import list from './list';
import create from './create';
import del from './delete';

export default {
  namespaced: true,
  modules: {
    list,
    del,
    create
  }
};
