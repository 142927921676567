<template>
  <b-form @submit.prevent="onFormSend">
    <div>
      <!--
      <b-form-group
          label="Date"
          label-for="date"
          :invalid-feedback="violations.date"
      >
        <b-input-group class="mb-3">
          <b-form-input
              id="date"
              v-model="item.date"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="off"
              :readonly="invoiceablesisLoading"
              required
          ></b-form-input>
          <b-input-group-append>
            <b-form-datepicker
                :disabled="invoiceablesisLoading"
                :readonly="invoiceablesisLoading"
                v-model="item.date"
                button-only
                right
                locale="en-US"
            ></b-form-datepicker>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
-->

      <!-- <b-form-group label="Amount" label-for="amount">
        <money
          id="amount"
          v-model="item.amount"
          :disabled="invoiceablesisLoading"
          class="font-size form-input form-control form-control-sm"
          />
          </b-form-input>
        </b-form-group> -->

      <CustomTable
        :values="invoiceablesItems"
        :fields="fields"
        :perPage="itemsPerPage"
        @changePage="changePage"
        @handleSortChange="handleSortChange"
      >
        <template slot="documentNumber" slot-scope="data">
          <span>{{ data.item.invoice.documentNumber }}</span>
        </template>

        <template #empty="scope">
          <h4>No invoices.</h4>
        </template>

        <template slot="amount_to_pay" slot-scope="data">
          <!-- <b-form-input
            type="number"
            v-model="data.item.amount_to_pay"
            min="0"
            :max="data.item.unpaid"
          /> -->
          <b-form-checkbox v-model="item.toPay" :value="data.item" switch>
          </b-form-checkbox>
        </template>
      </CustomTable>

      <b-form-group class="float-right">
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Submit</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import { get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import CustomTable from '../../_ui/tables/CustomTable';
import dayjs from 'dayjs';
import helpers from '../../../mixins/helpers';

export default {
  components: {
    CustomTable
  },

  mixins: [helpers],

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapFields('collectionNotes/list', {
      collectionNotes: 'items',
      collectionNotesIsLoading: 'isLoading'
    }),
    ...mapFields('invoiceables/list', {
      invoiceablesError: 'error',
      invoiceablesItems: 'items',
      invoiceablesisLoading: 'isLoading',
      invoiceablesView: 'view',
      invoiceablesTotalItems: 'totalItems'
    }),
    ...mapGetters('auth', ['getUser']),

    getCompanyId() {
      return this.getUser.company.entity
        ? this.getUser.company.entity.company['@id']
        : '';
    },

    violations() {
      return this.errors || {};
    },
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    amountToPay() {
      return this.invoiceablesItems.map((item) => item.amount_to_pay);
    },
    request() {
      let request = `${this.getCompanyId}/invoiceables?`;
      request += `page=${this.subPage}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      fields: [
        {
          key: 'index',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'startDate',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            if (!value) {
              return '';
            }

            return dayjs(item.startDate).format('DD/MM/YYYY');
          }
        },
        {
          key: 'documentNumber',
          label: 'Document Number',
          sortable: true
        },
        {
          key: 'unpaid',
          label: 'Unpaid',
          sortable: true,
          formatter: (v, k, item) => {
            this.formatCurrency(item.unpaid);
          }
        },
        {
          key: 'paid',
          label: 'Amount paid',
          sortable: true,
          formatter: (v, k, item) => {
            this.formatCurrency(item.unpaid);
          }
        },
        {
          key: 'amount_to_pay',
          label: 'To pay',
          sortable: true
        }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      subPage: 1
    };
  },
  methods: {
    ...mapActions({
      getPage: 'invoiceables/list/default',
      create: 'creditNotes/create'
    }),

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    paySinglePaymentFullAmount(index, amount) {
      //Set amount to pay to total of the selected payment
      this.$set(this.invoiceablesItems[index], 'amount_to_pay', amount);
    },

    resetAmounts() {
      // Reset amounts to pay to zero
      this.invoiceablesItems.forEach((item, index) => {
        this.$set(this.invoiceablesItems[index], 'amount_to_pay', '');
      });
    },

    onFormSend() {
      const body = this.item.toPay.map((item) => {
        return {
          id: item['@id'],
          amount: parseInt(parseFloat(item.grossAmount))
        };
      });

      this.handleSubmit(body);
    },

    changePage(page) {
      this.subPage = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    }
  },

  watch: {
    'item.amount'(value) {
      let total = value;
      let i = 0;

      // Reset count if amount changed
      this.resetAmounts();

      // Loop while total is bigger than zero and if array length is bigger than loop index
      while (total > 0 && this.invoiceablesItems.length > i) {
        const amount = this.invoiceablesItems[i].unpaid;
        const amountToPay = total > amount ? amount : total;

        this.$set(this.invoiceablesItems[i], 'amount_to_pay', amountToPay);

        total -= amount;
        i++;
      }
    }
  }
};
</script>

<!---
<style lang="scss">
.unpaid_amount {
  text-decoration: underline;
  font-weight: bold;

  cursor: pointer;

  &:hover {
    color: #209aec;
  }
}
</style>
-->
