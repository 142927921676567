<template>
  <div>
    <div class="alert alert-danger" role="alert" v-if="showError">
      File is required. <strong>Please upload</strong>
    </div>
    <b-form @submit.prevent="handleSubmit">
      <b-form-group
        label="Choose .png or .jpeg"
        label-for="update_logo"
        class="font-weight-bold"
      >
        <vue-dropzone
          ref="myVueDropzone"
          id="doc"
          :options="dropzoneOptions"
          @vdropzone-file-added="addedFile"
        ></vue-dropzone>
      </b-form-group>

      <b-form-group class="float-right">
        <b-button
          @click="closeModal"
          class="mr-2"
          type="button"
          variant="secondary"
          :disabled="isLoading"
          >Cancel</b-button
        >
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Submit</b-button
        >
        <b-button type="submit" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { docService, entityService } from '@/utils/fetch';
import { mapGetters } from 'vuex';
import helpers from '@/mixins/helpers';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'Logo',

  components: {
    vueDropzone: vue2Dropzone
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getCompanyId', 'getCompanyLogo'])
  },

  data() {
    return {
      dropzoneOptions: {
        url: 'localhost',
        dictDefaultMessage: "<i class='fa fa-plus'></i><p>UPLOAD DOCUMENT</p>",
        maxFiles: 1,
        addRemoveLinks: true,
        autoProcessQueue: false,
        thumbnailWidth: 500,
        thumbnailHeight: 500,
        acceptedFiles: '.jpeg,.jpg,.png'
      },
      item: {
        file: null
      },
      isLoading: false,
      showError: false,
      uploadFolder: 'logo'
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('updateLogo');
    },

    addedFile(file) {
      this.item.file = file;
    },

    async handleSubmit() {
      if (!this.item.file) {
        this.showError = true;
        return;
      }

      this.isLoading = true;

      let formData = new FormData();
      formData.append('file', this.item.file);
      formData.append('companyId', this.getCompanyId);
      formData.append('folder', this.uploadFolder);

      try {
        const response = await docService('/uploads', {
          method: 'POST',
          body: formData
        });

        const data = await response.json();

        this.updateCompanyLogo(data);
      } catch (e) {
        SwalAlert({
          title: 'Error',
          icon: 'error',
          description: e.message,
          type: 'danger',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'danger'
            }
          }
        });
      } finally {
        this.isLoading = false;
      }
    },

    updateCompanyLogo(id) {
      entityService(this.getCompanyId, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({
          logo: `/uploads/${id}`
        })
      })
        .then((response) => response.json())
        .then(() => {
          this.$bvModal.hide('updateLogo');

          this.$emit('updated', this.form);
          SwalAlert({
            title: 'Logo updated',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'Form another',
                type: 'warning',
                callback: () => {
                  this.$bvModal.show('updateLogo');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {
                  return;
                }
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            description: e,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.showError = false;
        });
    }
  },

  watch: {
    'item.file': function () {
      this.showError = false;
    }
  }
};
</script>

<style lang="scss">
.data-dz-thumbnail {
  object-fit: dynamic !important;
}

.dropzone .dz-preview.dz-image-preview {
  height: 100%;
  max-height: 100%;
}
</style>
