<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <LearningMaterialForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import LearningMaterialForm from './Form';
import { SwalAlert } from '../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'learningmaterial/create/getField',
  mutationType: 'learningmaterial/create/updateField'
});

export default {
  components: {
    LearningMaterialForm
  },

  props: {
    showAlert: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createLearningMaterial');
      this.$bvModal.hide('createLearningMaterialForActivity');

      if (this.showAlert) {
        SwalAlert({
          title: 'Learning Material added',
          icon: 'user',
          type: 'success',
          buttons: {
            cancel: {
              label: 'Form another',
              type: 'warning',
              callback: () => {
                this.$bvModal.show('createLearningMaterial');
                this.$bvModal.show('createLearningMaterialForActivity');
              }
            },
            confirm: {
              label: 'OK',
              type: 'success'
            }
          }
        });
      }

      // Clear store
      this.reset();
    }
  },

  methods: {
    ...mapActions('learningmaterial/create', ['create', 'reset']),

    onSendForm() {
      this.create(this.item);
    }
  }
};
</script>
