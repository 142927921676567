<template>
  <b-container fluid class="px-4">
    <b-row v-if="!isLoading">
      <b-col cols="7">
        <div class="d-flex align-items-center">
          <img
            class="mr-2"
            src="@/assets/img/icons/menuicons/relations@2x.png"
            alt=""
          />
          <h3 class="font-weight-bold mt-2">My Account Settings</h3>
        </div>

        <b-form @submit.prevent="onSendConfirmedForm">
          <b-form-group label="Email Address" label-for="profile_address">
            <b-form-input
              id="profile_address"
              v-model="getUser.entity.email"
              type="email"
              readonly
              placeholder=""
            >
            </b-form-input>
          </b-form-group>

          <b-form-group label="Nickname" label-for="nickname">
            <b-form-input id="nickname" v-model="form.nickname" type="text">
            </b-form-input>
          </b-form-group>

          <b-form-group label="Language" label-for="country_language">
            <b-form-select
              v-model="form.language"
              id="country_language"
              class="form-control"
            >
              <b-form-select-option
                v-for="selectItem in languageSelectItems"
                :key="selectItem['@id']"
                :value="selectItem['@id']"
                :selected="isSelected('language', selectItem['@id'])"
              >
                {{ selectItem.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group class="float-right">
            <b-button type="submit" variant="primary" v-if="!isSubmitting"
              >Save Settings</b-button
            >
            <b-button type="button" v-else>
              <b-spinner small></b-spinner>
            </b-button>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>

    <div class="text-center p-5" v-else>
      <b-col cols="7">
        <b-spinner class="align-middle" variant="primary"></b-spinner>
      </b-col>
    </div>
  </b-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';
import { AUTH_REFRESH_TOKEN } from '@/store/modules/auth/actions';

export default {
  props: {
    user: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser', 'getToken']),
    ...mapFields('language/list', {
      languageSelectItems: 'selectItems'
    })
  },

  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      form: {
        language: ''
      }
    };
  },

  created() {
    this.languageGetSelectItems();
  },

  mounted() {
    this.getUserInfo();
  },

  methods: {
    ...mapActions('auth', [AUTH_REFRESH_TOKEN]),
    ...mapActions({
      languageGetSelectItems: 'language/list/getSelectItems'
    }),

    getUserInfo() {
      this.isLoading = true;
      entityService(this.getUser.entity['@id'])
        .then((response) => response.json())
        .then((data) => {
          this.form.nickname = data.nickname;
          this.form.language = data.language['@id'];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    isSelected(collection, id) {
      return find(this.form[collection], ['@id', id]) !== undefined;
    },

    onSendConfirmedForm() {
      this.isSubmitting = true;

      const body = {
        nickname: this.form.nickname,
        language: this.form.language
      };

      entityService(this.getUser.entity['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(body)
      })
        .then((response) => response.json())
        .then((data) => {
          // Refresh data
          this.AUTH_REFRESH_TOKEN({
            refresh_token: this.getToken.refresh_token
          });

          // Show alert
          SwalAlert({
            title: 'Account was updated',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {}
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            description: e,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally((e) => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.container-fluid {
  background-color: $white;
}
</style>
