export const TERMSANDCONDITIONS_CREATE_RESET =
  'TERMSANDCONDITIONS_CREATE_RESET';
export const TERMSANDCONDITIONS_CREATE_SET_CREATED =
  'TERMSANDCONDITIONS_CREATE_SET_CREATED';
export const TERMSANDCONDITIONS_CREATE_SET_ERROR =
  'TERMSANDCONDITIONS_CREATE_SET_ERROR';
export const TERMSANDCONDITIONS_CREATE_SET_VIOLATIONS =
  'TERMSANDCONDITIONS_CREATE_SET_VIOLATIONS';
export const TERMSANDCONDITIONS_CREATE_TOGGLE_LOADING =
  'TERMSANDCONDITIONS_CREATE_TOGGLE_LOADING';
