<template>
  <div>
    <b-button
      class="p-2 mt-3 float-right"
      :to="content.link"
      variant="primary"
      size="sm"
    >
      {{ content.title }} <b-icon icon="plus"></b-icon>
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Button',

  props: {
    content: {
      type: Object,
      required: true
    }
  }
};
</script>
