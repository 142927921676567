<template>
  <b-form>
    <strong>Learners</strong>
    <b-card bg-variant="light" class="mt-2 mb-3">
      <b-form-group
        v-if="program.isOpenEnrollment"
        :invalid-feedback="violations.company"
        :state="isValid('company')"
        label="Beneficiary"
        label-for="company"
        label-size="sm"
      >
        <div v-if="getBeneficiaryRelationships.length > -2">
          <b-form-select
            v-if="relationshipIsLoading"
            disabled
            size="sm"
            value=""
          >
            <b-form-select-option selected value=""
              >Loading...
            </b-form-select-option>
          </b-form-select>
          <b-form-select
            v-else
            id="company"
            v-model="formMetadata.company"
            :options="getBeneficiaryRelationships"
            required
            size="sm"
            text-field="name"
            value-field="@id"
          />
        </div>
        <template v-else>
          <span
            >You don't have any Beneficiaries,
            <router-link to="/relationships/beneficiaries/overview"
              >add one</router-link
            ></span
          >
        </template>
      </b-form-group>
      <b-form-group label="Email Address" label-for="user_email">
        <UserSearchInput
          v-if="!isWowLearnerSelected"
          ref="userSearch"
          @input="inputUser"
          @selected="selectedUser"
        />
        <b-form-input
          v-else
          id="user_email"
          v-model="getSelectedCompany.email"
          readonly
          required
          type="text"
        />
        <div
          v-if="violations.email"
          class="d-block invalid-feedback"
          role="alert"
        >
          {{ violations.email }}
        </div>
        <div
          v-if="isLearnerAlreadyAdded"
          class="d-block invalid-feedback"
          role="alert"
        >
          A learner with this e-mail already exists
        </div>
      </b-form-group>

      <b-form-row v-if="!isWowLearnerSelected">
        <b-col cols="6">
          <b-form-group
            :invalid-feedback="violations.firstName"
            :state="!isValid('firstName')"
            label="First Name"
            label-for="user_firstName"
          >
            <b-form-input
              id="user_firstName"
              v-model="user.firstName"
              :readonly="!!formMetadata.userSelected || isWowLearnerSelected"
              required
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            :invalid-feedback="violations.lastName"
            :state="!isValid('lastName')"
            label="Last Name"
            label-for="user_lastName"
          >
            <b-form-input
              id="user_lastName"
              v-model="user.lastName"
              :readonly="!!formMetadata.userSelected || isWowLearnerSelected"
              required
              type="text"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-group class="float-right">
        <div v-if="!isCreatingUser">
          <b-button
            class="mr-2"
            type="button"
            variant="secondary"
            @click="cleanupUser"
            >Clear
          </b-button>

          <b-button
            :disabled="!canAddLearner"
            class="float-right"
            type="button"
            variant="primary"
            @click="addLearner"
            >Add Learner
          </b-button>
        </div>
        <b-button v-else type="button">
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </b-card>
    <b-table :fields="fields" :items="learners" borderless hover small striped>
      <template #cell(actions)="data">
        <a href="#" @click="deleteLearner(data.index)">
          <b-icon icon="trash"></b-icon>
        </a>
      </template>
    </b-table>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import UserSearchInput from '@/components/_ui/users/SearchInput.vue';
import { mapFields } from 'vuex-map-fields';
import { SwalAlert } from '@/utils/swal';

import _ from 'lodash';

export default {
  components: {
    UserSearchInput
  },

  props: {
    program: {
      type: Object,
      required: true
    },

    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      formMetadata: {
        userSelected: null,
        company: null
      },
      user: {},
      isUpdating: false,

      isCreatingUser: false,
      fields: [
        { key: 'user.email', label: 'Email Address' },
        { key: 'user.firstName', label: 'First Name' },
        { key: 'user.lastName', label: 'Last Name' },
        { key: 'actions', label: ' ' }
      ]
    };
  },

  created() {
    // Get data
    this.getRelationSelectItems({
      page: `${this.getCompany.entity.company['@id']}/relationships`,
      params: {
        type: 'CUSTOMER'
      }
    });
  },

  watch: {
    'formMetadata.userSelected': function (userSelected) {
      this.item.user = userSelected ? userSelected['@id'] : null;
    },
    'formMetadata.company': function (company) {
      // this.formMetadata.userSelected = {
      //   email:
      //
      // }
      // this.item.user = userSelected ? userSelected['@id'] : null;
    },
    userCreated: function (userCreated) {
      if (!userCreated) {
        return;
      }

      // Move it, move it!
      // businessLogicService('POST', 'learners',
      this.addLearnerWithUser(userCreated);
    },
    learnerCreated: function (learnerCreated) {
      if (learnerCreated) this.learners.push(learnerCreated);
      this.isCreatingUser = false;
      this.cleanupUser();
    },
    violations: function (violations) {
      this.isCreatingUser = false;
      this.isLoading = false;
    }
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),
    ...mapFields('user/create', {
      userErrors: 'errors',
      userIsLoading: 'isLoading',
      userCreated: 'created',
      userViolations: 'violations'
    }),
    ...mapFields('learner/create', {
      learnerErrors: 'errors',
      learnerIsLoading: 'isLoading',
      learnerCreated: 'created',
      learnerViolations: 'violations'
    }),

    ...mapFields('relationship/list', {
      relationshipError: 'error',
      relationshipSelectItems: 'selectItems',
      relationshipIsLoading: 'isLoading'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    learners() {
      return this.item.learners;
    },

    violations() {
      let errors = {};
      if (this.errors) {
        errors = { ...errors, ...this.errors };
      }
      if (this.userViolations) {
        errors = { ...errors, ...this.userViolations };
      }
      return errors;
    },

    canAddGroup() {
      return this.item.name && this.learners.length !== 0;
    },

    getBeneficiaryRelationships() {
      if (!this.relationshipSelectItems) return [];

      // Cache emails
      let emails = [];
      this.learners.forEach((l) => {
        emails.push(l.user.email);
      });

      // Get all current emails
      return this.relationshipSelectItems
        .map((item) => {
          let _item = item.related;
          if (_item.type === 'PERSON') {
            _item.name = `(WOW+Learner) ${_item.name}`;
          }
          return { ..._item };
        })
        .filter((item) => {
          return emails.indexOf(item.email) === -1;
        });
    },
    isLearnerAlreadyAdded() {
      // Same email?
      let emails = [];
      this.learners.forEach((l) => {
        emails.push(l.user.email);
      });

      // Wow learners only show if not added
      if (this.isWowLearnerSelected) {
        return false;
      }

      if (emails.indexOf(this.user.email) !== -1) {
        return true;
      }

      return false;
    },

    canAddLearner() {
      // OR needs company
      if (this.program.isOpenEnrollment && !this.formMetadata.company) {
        return false;
      }
      if (this.program.isOpenEnrollment && this.isWowLearnerSelected) {
        return true;
      }
      // User?
      if (!this.user.email || !this.user.firstName || !this.user.lastName) {
        return false;
      }

      if (this.isLearnerAlreadyAdded) {
        return false;
      }

      return true;
    },

    getSelectedCompany() {
      let _company = this.getBeneficiaryRelationships.filter(
        (i) => i['@id'] == this.formMetadata.company
      );
      if (!_company) {
        return false;
      }
      return _company[0];
    },

    isWowLearnerSelected() {
      return (
        this.getSelectedCompany && this.getSelectedCompany.type === 'PERSON'
      );
    }
  },

  methods: {
    ...mapActions({
      programGetSelectItems: 'program/list/getSelectItems',
      learnersGetSelectItems: 'learners/list/getSelectItems',
      userCreate: 'user/create/create',
      userReset: 'user/create/reset',
      learnerCreate: 'learner/create/create',
      learnerRemove: 'learner/del/del',
      learnerReset: 'learner/create/reset',
      getRelationSelectItems: 'relationship/list/getSelectItems'
    }),

    closeModal() {
      this.$bvModal.hide('createGroup');
      this.$bvModal.hide('editGroup');
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    addLearnerWithUser(user) {
      // Editing?
      if (this.item['@id']) {
        let learner = {
          group: this.item['@id'],
          user: user['@id']
        };

        // Must have company
        if (this.program.isOpenEnrollment) {
          learner.customer = { company: this.formMetadata.company };
        }

        this.learnerCreate(learner);
      }
      // Creating, we don't have Group ID
      else {
        this.learners.push({
          user: user
        });
      }
    },

    addLearner() {
      this.isCreatingUser = true;
      // Wow learner?
      if (this.isWowLearnerSelected) {
        // Just send the correct user
        let _id = this.getSelectedCompany.employees[0]
          ? this.getSelectedCompany.employees[0].user
          : false;
        if (!_id) {
          SwalAlert({
            title: 'Error!',
            description:
              'Unable to add this WOW+Learner. Please report this issue to administrators of platform.',
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });

          this.isCreatingUser = false;
          return;
        }

        this.addLearnerWithUser({
          '@id': _id
        });
        return;
      }
      // Do we have user? if not, register
      if (this.formMetadata.userSelected) {
        return this.addLearnerWithUser(this.formMetadata.userSelected);
      }
      // No user yet, create him
      return this.userCreate(this.user);
    },

    deleteLearner(index) {
      let learner = this.learners[index];
      this.learners.splice(index, 1);
      this.learnerRemove(learner);
    },

    selectedUser(userSelected) {
      if (userSelected.fetched) {
        this.$set(this.formMetadata, 'userSelected', userSelected.user);
        this.$set(this.user, 'email', userSelected.user.email);
        this.$set(this.user, 'firstName', userSelected.user.firstName);
        this.$set(this.user, 'lastName', userSelected.user.lastName);
      } else {
        this.$set(this.formMetadata, 'userSelected', null);
        this.$set(this.user, 'email', userSelected.user);
      }
    },
    inputUser() {
      this.userReset();
      this.learnerReset();
    },
    cleanupUser() {
      this.user = {};
      this.$set(this.formMetadata, 'userSelected', null);
      this.$refs['userSearch'].$emit('clear', true);
    },

    addUser() {
      this.false = true;
      // if (userSelected.fetched) {
      //   const userAlreadyAdded = this.learners.filter((user) => user.email === userSelected.user.email);
      //
      //   if (userAlreadyAdded.length === 0) {
      //     this.learners = [...this.learners, userSelected.user];
      //   }
      // }
    }
  }
};
</script>
