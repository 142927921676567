<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <CustomTable
      :fields="fields"
      list="template"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:status="data">
        <Status class="text-center" :value="data.item.isActive" />
      </template>

      <template v-slot:actions="data">
        <a
          :href="data.item.file.url"
          target="_blank"
          title="Download"
          v-b-tooltip.hover
        >
          <i aria-hidden="true" class="fa fa-download text-info"></i>
        </a>
        <a
          class="ml-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
          ><i class="fa fa-edit text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('templateCreate')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Template
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="templateCreate" hide-footer title="Add New Template">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Template</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('templateCreate')"
        ></i>
      </template>

      <TemplateCreate @created="refreshData"></TemplateCreate>
    </b-modal>

    <b-modal id="templateUpdate" title="Edit Template" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Template</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('templateUpdate')"
        ></i>
      </template>

      <TemplateUpdate
        @updated="refreshData"
        :template="editTemplate"
      ></TemplateUpdate>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Status from '@/components/_ui/tables/Status';
import TemplateCreate from './Create.vue';
import TemplateUpdate from './Update.vue';

export default {
  name: 'TemplateList',

  components: {
    CustomTable,
    Status,
    TemplateCreate,
    TemplateUpdate
  },

  computed: {
    ...mapFields('template/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/templates?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      editTemplate: {},
      fields: [
        { key: 'name', label: 'Template Name', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'date', label: 'Upload Date', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'template/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editTemplate = item;
      this.$bvModal.show('templateUpdate');
    }
  }
};
</script>
