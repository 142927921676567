<template>
  <b-form @submit.prevent="onSendForm">
    <b-form-group
      v-if="companySelectItems"
      v-show="!isHidden('company')"
      label="Company"
      label-for="companyuser_company"
      :invalid-feedback="violations.company"
      :state="!isValid('company')"
    >
      <v-select
        v-model="item.company"
        id="company"
        :options="companySelectItems"
        label="name"
        :reduce="(company) => company['@id']"
      >
      </v-select>
    </b-form-group>

    <b-form-group
      v-if="!isHidden('user')"
      label="First Name"
      label-for="companyuser_firstName"
      :invalid-feedback="violations.firstName"
      :state="!isValid('user')"
    >
      <b-form-input
        id="companyuser_firstName"
        type="text"
        placeholder="Enter First Name..."
        v-model="item.firstName"
        class="form-control"
        required
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      v-if="!isHidden('user')"
      label="Last Name"
      label-for="companyuser_lastName"
      :invalid-feedback="violations.lastName"
      :state="!isValid('user.lastName')"
    >
      <b-form-input
        id="companyuser_lastName"
        type="text"
        placeholder="Enter Last Name..."
        v-model="item.lastName"
        class="form-control"
        required
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      v-if="!isHidden('user')"
      label="Email Address"
      label-for="companyuser_email"
      :invalid-feedback="violations.email"
      :state="!isValid('user.email')"
    >
      <b-form-input
        id="companyuser_email"
        type="email"
        placeholder="Enter E-mail..."
        v-model="item.email"
        class="form-control"
        required
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Make user admin of the company?"
      :invalid-feedback="violations.isAdmin"
      :state="!isValid('isAdmin')"
    >
      <b-form-checkbox
        switch
        id="companyuser_isAdmin"
        v-model="item.isAdmin"
        selected="false"
        size="lg"
      />
    </b-form-group>

    <b-form-group label="This user will be UBO ?">
      <b-form-checkbox
        switch
        id="companyuser_isUbo"
        v-model="item.isUbo"
        selected="false"
        size="lg"
      />
    </b-form-group>
    <template v-if="item.isUbo">
      <b-form-group label="NIN Number" label-for="companyuser_id">
        <b-form-row>
          <b-col cols="3">
            <v-select
              v-if="countrySelectItems"
              required
              :clearable="false"
              v-model="item.vatCode"
              id="companyuser_id_country"
              :options="countrySelectItems"
              :reduce="(c) => c.code"
              label="code"
            >
              <template v-slot:option="option">
                <country-flag
                  :country="option.code"
                  size="small"
                ></country-flag>
                {{ option.name }}
              </template>
            </v-select>
          </b-col>
          <b-col cols="9">
            <b-form-input
              id="companyuser_id"
              v-model="item.fiscalId"
              type="text"
              :state="item.vatValid"
              required
            />

            <b-spinner
              v-if="item.vatLoading"
              small
              style="position: absolute; right: 16px; top: 10px; z-index: 99999"
            ></b-spinner>
          </b-col>
        </b-form-row>
      </b-form-group>
      <b-form-group
        label="Telephone Number"
        label-for="companyuser_phone"
        :invalid-feedback="violations.telephone"
        :state="!isValid('user.phoneNumber')"
      >
        <b-form-input
          id="companyuser_phone"
          type="tel"
          placeholder="Enter telephone number..."
          v-model="item.phoneNumber"
          class="form-control"
          required
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Branch Percentage"
        label-for="company_fiscal_percentage"
      >
        <b-form-input
          id="company_fiscal_percentage"
          v-model="item.percentage"
          :state="getPercentageUbo"
          type="text"
          required
        />
      </b-form-group>

      <b-form-row>
        <b-col cols="6">
          <b-form-group label="UBO Start" label-for="ubo_start">
            <b-form-datepicker
              id="ubo_start"
              v-model="item.startDate"
              required
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="UBO End" label-for="ubo_end"
            ><b-form-datepicker id="ubo_end" v-model="item.endDate" required />
          </b-form-group>
        </b-col>
      </b-form-row>
    </template>

    <b-form-group class="float-right">
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';

export default {
  props: {
    hide: {
      type: Array,
      required: false
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      required: true
    },

    showAllUsers: {
      type: Boolean,
      default: true
    },
    user: {
      type: String
    }
  },

  mounted() {
    this.companyGetSelectItems({ params: { itemsPerPage: 10000 } });
    this.userGetSelectItems();
    this.countryGetSelectItems();
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId', 'getUser']),

    ...mapFields('country/list', {
      countrySelectItems: 'selectItems'
    }),

    ...mapFields('company/list', {
      companySelectItems: 'selectItems'
    }),
    ...mapFields('user/list', {
      userSelectItems: 'selectItems'
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },
    getPercentageUbo() {
      if (!this.item.percentage) {
        return null;
      }
      let percentage = this.item.percentage;
      if (
        percentage < 0 ||
        percentage > 100 ||
        (percentage != null && isNaN(parseInt(percentage)))
      ) {
        return false;
      }
      return true;
    }
  },

  methods: {
    ...mapActions({
      countryGetSelectItems: 'country/list/getSelectItems',
      companyGetSelectItems: 'company/list/getSelectItems',
      userGetSelectItems: 'user/list/getSelectItems'
    }),

    isHidden(field) {
      if (!this.hide) {
        return false;
      }
      return this.hide.includes(field);
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    onSendForm() {
      let payload = {
        isAdmin: this.item.isAdmin
      };

      if (this.item.isUbo) {
        payload.fiscalId = this.item.vatCode + this.item.fiscalId;
        payload.percentage = parseInt(this.item.percentage * 10000);
        payload.startDate = this.item.startDate;
        payload.endData = this.item.endDate;
        payload.isUbo = true;
      }

      if (this.isHidden('user')) {
        payload.company = this.item.company;
        payload.user = this.user ? this.user : `/users/${this.getUser.id}`;
      } else {
        payload.user = {
          firstName: this.item.firstName,
          lastName: this.item.lastName,
          email: this.item.email,
          phoneNumber: this.item.phoneNumber
        };
        payload.isAddingEmployee = true;
        payload.company = this.getCompanyId;
      }

      this.handleSubmit(payload);
    }
  }
};
</script>
