<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <IncomingForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import IncomingForm from './Form';
import { SwalAlert } from '../../../../utils/swal';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';

const { mapFields } = createHelpers({
  getterType: 'payment/incoming/create/getField',
  mutationType: 'payment/incoming/create/updateField'
});

export default {
  components: {
    IncomingForm
  },
  props: {
    showAlert: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },
  data() {
    return {
      item: {
        customer_name: null,
        date: dayjs().format('YYYY-MM-DD'),
        toPay: []
      }
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  methods: {
    ...mapActions('payment/incoming/create', ['create', 'reset']),

    onSendForm(item) {
      this.create(item);
    }
  },

  mounted() {
    this.reset();
  },

  watch: {
    created: function (created) {
      if (!created) {
        return;
      }
      this.$emit('created', created);
      this.$bvModal.hide('createPayment');

      SwalAlert({
        title: 'Payment added',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createPayment');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  }
};
</script>
