<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="questions"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
          ><i class="fa fa-edit text-info"></i
        ></a>
        <span class="handshake__reject ml-3" @click="deleteQuestion(data.item)">
          <i class="fas fa-times"></i>
        </span>
      </template>
    </CustomTable>

    <b-button
      class="mt-3 float-right"
      v-b-modal.createQuestion
      variant="primary"
    >
      <i class="fas fa-plus"></i> Add Question
    </b-button>

    <b-modal id="createQuestion" title="Create Question" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Question</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createQuestion')"
        ></i>
      </template>

      <QuestionCreate @created="refreshData"></QuestionCreate>
    </b-modal>

    <b-modal id="updateQuestion" title="Edit Question" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Update Question</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateQuestion')"
        ></i>
      </template>

      <QuestionUpdate
        :question="editQuestion"
        @updated="refreshData"
      ></QuestionUpdate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import QuestionCreate from './Create';
import QuestionUpdate from './Update';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    CustomTable,
    QuestionCreate,
    QuestionUpdate
  },

  data() {
    return {
      editQuestion: {},
      fields: [
        { key: 'questionGroup', label: 'Group', sortable: true },
        { key: 'question', label: 'Question', sortable: true },
        { key: 'type', label: 'Type', sortable: true },
        { key: 'questionOrder', label: 'Order', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapFields('questions/del', {
      deletedItem: 'deleted'
    }),
    request() {
      let request = '/analysis-questions?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'questions/list/default',
      deleteItem: 'questions/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editQuestion = item;
      this.$bvModal.show('updateQuestion');
    },

    deleteQuestion(item) {
      SwalAlert({
        title: 'Question Delete',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.deleteItem(item);
              SwalAlert({
                title: 'Question deleted',
                type: 'success',
                icon: 'user'
              }).then(() => {
                this.refreshData();
              });
            }
          }
        }
      });
    }
  }
};
</script>
