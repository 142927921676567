<template>
  <div class="aprova-report__form">
    <div class="text-center" v-if="isLoading">
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>

    <CustomTable
      :fields="fields"
      :values="values"
      @changePage="changePage"
      v-else
    >
    </CustomTable>
  </div>
</template>

<script>
import { entityService } from '@/utils/fetch';

import CustomTable from '@/components/_ui/tables/CustomTable';

export default {
  name: 'RII',

  components: {
    CustomTable
  },

  props: {
    aprovaReport: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.fetchRii();
  },

  data() {
    return {
      isLoading: false,
      values: [],
      fields: [
        {
          key: 'year',
          label: 'Year',
          sortable: false
        },
        {
          key: 'revenueAmount',
          label: 'Revenue',
          sortable: false
        },
        {
          key: 'surchargeCorrectionAmount',
          label: 'Surcharge Correction',
          sortable: false
        },
        {
          key: 'learningMaterialsCorrectionAmount',
          label: 'Learning Material Corrected',
          sortable: false
        },
        {
          key: 'correction9313Amount',
          label: 'Correction due to the 93/13 directive',
          sortable: false
        },

        {
          key: 'amplificaFeeAmount',
          label: 'AmplificA fee',
          sortable: false
        },
        {
          key: 'totalAmount',
          label: 'Total Amount',
          sortable: false
        },
        {
          key: 'stateAidAmount',
          label: 'StateAid',
          sortable: false
        },
        {
          key: 'PIFFraudAmount',
          label: 'PIF-fraud',
          sortable: false
        },
        {
          key: 'practicaVATCorrectionAmount',
          label: 'Practica VAT Correction Amount',
          sortable: false
        }
      ],
      page: 1
    };
  },

  methods: {
    fetchRii(page = 1) {
      this.isLoading = true;

      entityService(`${this.aprovaReport['@id']}/yearly-riis?page=${page}`)
        .then((response) => response.json())
        .then((data) => {
          this.values = data['hydra:member'];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    changePage(page) {
      this.fetchRii(page);
    }
  }
};
</script>
