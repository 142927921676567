<template>
  <div id="role_switcher">
    <div v-if="errors.length > 0" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        errors[0]
      }}</span>
    </div>

    <!--      <b-button-group class="mx-1 text-center" size="sm">
    <b-card-group>
      <b-card
        class="card-button"
        v-for="company in getAvailableUserCompanies"
        :key="company['@id']"
        :class="{ active: company.id == form.company }"
        @click.prevent="setCompany(company.id)"
      >
        <i class="fa fa-building"></i><br />
        {{ company.name }}
      </b-card>
    </b-card-group> -->
    <!--      </b-button-group>-->
    <div v-if="!isLoadingCompanies && !isLoadingBailiff">
      <b-form-group class="role-dropdown">
        <div class="d-flex align-items-center justify-content-between">
          <h1 class="role-dropdown__title">Your Company</h1>
          <b-form-select
            class="form-control"
            v-model="form.company"
            name="role"
            @change="setCompany"
            :options="availableUserCompanies"
            text-field="name"
            value-field="@id"
          >
          </b-form-select>
        </div>
      </b-form-group>

      <b-form-group class="role-dropdown" v-model="form.role">
        <div class="d-flex align-items-center justify-content-between">
          <h1 class="role-dropdown__title">Your Role for this Company</h1>
          <b-form-select v-model="form.role" class="form-control" name="role">
            <b-form-select-option value="" disabled
              >Choose your Role
            </b-form-select-option>
            <b-form-select-option
              v-for="(role, idx) in roles"
              :key="idx"
              :value="role.id"
            >
              {{ role.name }}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div class="d-flex mt-5 float-right">
          <div>
            <WelcomeView
              :isRoleRequest="true"
              @close="requestRole"
              v-if="canRequestAnotherRole"
            />

            <CustomButton
              type="Default"
              :content="{ title: 'Request another Role' }"
              @click.native="canRequestAnotherRole = true"
              :disabled="isButtonDisabled"
            />
          </div>
          <div>
            <b-button
              class="mt-3 ml-3"
              v-if="!isLoading"
              type="button"
              @click.prevent="doRolePick"
              variant="success"
              :disabled="isButtonDisabled"
              >Let’s Go
            </b-button>
            <b-button class="mt-3 ml-3" v-else>
              <b-spinner small></b-spinner>
            </b-button>
          </div>
        </div>
      </b-form-group>
    </div>

    <div v-else>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  AUTH_LOGIN_CONTEXT,
  AUTH_REFRESH_TOKEN
} from '../../store/modules/auth/actions';
import helpers from '@/mixins/helpers';
import WelcomeView from '@/views/welcome/Main';
import CustomButton from '@/components/_ui/CustomButton';
import { entityService } from '@/utils/fetch';
import { BIconArrowLeftRight } from 'bootstrap-vue';

export default {
  name: 'Role',

  components: {
    WelcomeView,
    CustomButton
  },

  mixins: [helpers],

  data() {
    return {
      isLoading: false,
      isLoadingCompanies: false,
      isLoadingBailiff: false,
      errors: [],
      form: {
        role: '',
        company: ''
      },
      canRequestAnotherRole: false,
      availableUserCompanies: [],
      roles: [],
      bailiffCompany: null
    };
  },

  computed: {
    ...mapGetters('auth', [
      'getUser',
      'getToken',
      'getCompanyId',
      'getRole',
      'isRole'
    ]),

    getSelectedCompany: function () {
      return this.availableUserCompanies.find(
        (company) => company['@id'] === this.form.company
      );
    },

    isButtonDisabled() {
      return !this.form.role || !this.form.company;
    }
  },

  mounted() {
    this.getAvailableUserCompanies();
    this.getBailiffCompany();
  },

  methods: {
    ...mapActions('auth', [AUTH_LOGIN_CONTEXT, AUTH_REFRESH_TOKEN]),
    ...mapActions({
      retrieve: 'user/show/retrieve'
    }),

    requestRole() {
      this.canRequestAnotherRole = false;

      const newAccess = JSON.parse(localStorage.getItem('newAccess'));

      this.form.role = newAccess.role;
      this.form.company = newAccess.company;

      this.doRolePick();
    },

    setCompany(id) {
      this.form.company = id;
    },

    doRolePick() {
      // Loading!
      this.isLoading = true;

      // Send to server
      this.AUTH_LOGIN_CONTEXT({
        role: this.form.role,
        company: this.form.company.replace('/companies/', '')
      })
        .then(() => {
          // Refresh token so we get the new attributes
          this.AUTH_REFRESH_TOKEN({
            refresh_token: this.getToken.refresh_token
          })
            .then(() => {
              if (this.$router.currentRoute.path == '/dashboard') {
                // Force refresh
                this.$router.go(0);
              } else {
                // Redirect
                this.$router.push('/dashboard');
              }

              // All good!
              this.$bvModal.hide('switchRole');
            })
            .catch((err) => {
              this.errors.push(err);
            })
            .finally(() => {
              // After all is done, remove loading
              this.isLoading = false;
            });
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    /**
     * Call the User entity
     * @returns {{name: string, id: number}[]}
     */
    getAvailableUserCompanies() {
      this.isLoadingCompanies = true;
      entityService(`/users/${this.getUser.id}`)
        .then((response) => response.json())
        .then((data) => {
          this.availableUserCompanies = data.companies.map((company) => {
            let id = this.parseRelationToId(company.company['@id']);

            return {
              '@id': company.company['@id'],
              id: id,
              name: company.company.name,
              isAdmin: company.isAdmin,
              isCurrent: id === this.getCompanyId,
              isAccountant: company.company.isAccountant,
              isAmplifica: company.company.isAmplifica,
              isInstructor: company.company.isInstructor,
              isProvider: company.company.isProvider,
              type: company.company.type
            };
          });
        })
        .finally(() => {
          if (this.getCompanyId) {
            this.form.company = this.getCompanyId;
          }

          if (this.getRole) {
            this.form.role = this.getRole;
          }

          this.isLoadingCompanies = false;
        });
    },

    getAvailableUserRoles() {
      let roles = [];

      this.getUser.entity.roles.forEach((role) => {
        if (
          (!this.getSelectedCompany.isAccountant &&
            role === 'ROLE_ACCOUNTANT') ||
          (!this.getSelectedCompany.isProvider && role === 'ROLE_PROVIDER') ||
          (this.getSelectedCompany.type !== 'GOVERNMENTAL' &&
            role === 'ROLE_GOVERNMENT')
        ) {
          return;
        }

        if (
          role === 'ROLE_BAILIFF' &&
          this.form.company !== this.bailiffCompany['@id']
        ) {
          return;
        }

        roles.push({
          id: role,
          name: this.parseRoleName(role)
        });
      });

      this.roles = roles;

      const hasRole = this.roles.filter((item) => item.id === this.getRole);

      if (hasRole.length > 0) {
        this.form.role = this.getRole;
      } else {
        this.form.role = this.roles[0].id;
      }
    },

    getBailiffCompany() {
      this.isLoadingBailiff = true;
      entityService('/companies/bailiff')
        .then((response) => response.json())
        .then((data) => (this.bailiffCompany = data))
        .catch((error) => console.log(error))
        .finally(() => {
          this.isLoadingBailiff = false;
        });
    }
  },

  watch: {
    'form.company': function () {
      this.getAvailableUserRoles();
    }
  }
};
</script>

<style lang="scss">
#role_switcher {
  .card-button {
    text-align: center;
    margin: 5px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;

    :hover,
    &.active {
      background-color: $gray-200;
    }

    // don't show cursor
    &.active {
      cursor: default !important;
    }
  }

  .card-button i {
    color: $primary;
  }

  .role-dropdown {
    margin-top: 20px;
    text-align: center;

    &__title {
      //// font-size: 14px;
      text-align: left;
      color: $black;
      font-weight: bold;
    }
  }

  .role-dropdown select {
    width: 50%;
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .custom-select {
    text-overflow: ellipsis;
  }
}
</style>
