<template>
  <div class="general-information">
    <div class="text-" v-if="isNotesLoading">
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>
    <div class="notes" v-else>
      <ul class="notes__list">
        <li class="notes__item" v-for="(item, index) in notes" :key="index">
          <span v-html="item.note"></span>
          <a @click.prevent="deleteNote(item)"
            ><i class="fa fa-trash text-info"></i
          ></a>
        </li>
      </ul>
    </div>

    <b-form @submit.prevent="createNote">
      <b-form-group class="mt-5" label="Notes" label-for="notes">
        <b-form-textarea
          id="textarea"
          v-model="form.note"
          placeholder="Type here..."
          rows="6"
          required
        ></b-form-textarea>

        <div class="mt-2">
          <b-button type="submit" variant="primary" v-if="!createIsLoading"
            >Add note</b-button
          >
          <b-button type="submit" variant="primary" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </div>
      </b-form-group>
    </b-form>

    <!-- <div class="text-center" v-if="notesAreLoading">
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>
    <b-form class="py-2" v-else>
      <div></div>
      <b-button v-b-toggle.collapse-1 variant="primary"
        >Toggle Collapse</b-button
      >
      <b-collapse id="collapse-1" class="mt-2">
        <VueEditor v-model="item.notes" />
      </b-collapse> -->

    <b-form-group class="mt-5">
      <CustomButton
        class="float-left"
        type="Default"
        :content="{ title: 'Prev' }"
        @click.native="$emit('step', 2)"
      />
      <b-button
        class="float-right mt-3"
        type="button"
        variant="primary"
        @click="onSendForm"
        v-if="!isLoading"
        >Submit</b-button
      >
      <b-button class="float-right mt-3" type="button" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </div>
</template>

<script>
import CustomButton from '@/components/_ui/CustomButton';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'Notes',

  components: {
    CustomButton
  },

  props: {
    initialValues: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields('prospects/notes/list', {
      error: 'error',
      isNotesLoading: 'isLoading',
      notes: 'items'
    }),
    ...mapFields('prospects/notes/create', {
      createIsLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    }),
    ...mapFields('prospects/notes/del', {
      deleted: 'deleted'
    }),

    item() {
      return this.initialValues || this.form;
    }
  },

  data() {
    return {
      form: {
        note: null
      }
    };
  },

  mounted() {
    this.getNotes(`${this.item['@id']}/prospect-notes`);
  },

  methods: {
    ...mapActions({
      getNotes: 'prospects/notes/list/default',
      notesDelete: 'prospects/notes/del/del',
      create: 'prospects/notes/create/create'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getNotes(`${this.item['@id']}/prospect-notes`);
    },

    createNote() {
      this.create({
        note: this.form.note,
        prospect: this.item['@id']
      });
    },

    deleteNote(item) {
      this.notesDelete({ '@id': item['@id'] });
    },

    onSendForm() {
      this.$emit('updateData', this.item);
      this.$emit('onSendForm');
    }
  },

  watch: {
    created: function () {
      this.form.note = null;

      this.refreshData();
    },
    deleted: function () {
      this.refreshData();
    }
  }
};
</script>

<style lang="scss">
.notes__item {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
</style>
