<template>
  <div>
    <b-navbar
      id="amplifica_header"
      :class="[{ impersonated: hasImpersonator }, 'shadow-sm']"
      variant="light"
    >
      <b-nav-item class="menuBurger" @click="toggleSideMenu">
        <div id="burgerIcon">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </b-nav-item>
      <b-nav-item class="menuItem" to="/market">
        <span>
          <i class="fa fa-store"></i>
        </span>
        <p>Market</p>
      </b-nav-item>
      <b-nav-item
        href="https://www.linkedin.com/company/fusely/"
        class="menuItem"
        target="_blank"
      >
        <span>
          <i class="fa fa-circle-nodes"></i>
        </span>
        <p>Network</p>
      </b-nav-item>
      <b-nav-item class="menuItem" to="/chat">
        <span>
          <i class="fa fa-comment"></i>
        </span>
        <p>Chatroom</p>
      </b-nav-item>

      <b-navbar-nav class="ml-auto h-100">
        <b-nav-text v-if="hasImpersonator" class="d-block impersionate-content">
          <div class="impersionate-content__body">
            <span class="mr-2">Stop impersonating user ? </span>
            <div v-if="impersonateLoading" class="text-center text-dark">
              <b-spinner small></b-spinner>
            </div>
            <a
              v-else
              href="#"
              @click.prevent="logoutImpersonator"
              class="impersionate-content__danger-icon"
            >
              <i class="fa fa-times-circle"></i>
            </a>
          </div>
        </b-nav-text>
        <b-nav-text class="d-block company-name p-0">
          <div @click="chooseRole">
            <span
              v-if="
                getUser.entity.companies.length > 0 && getUser.company.id !== 0
              "
              class="font-weight-bold"
            >
              {{ getCompanyName }}
            </span>
            <span v-else class="font-weight-bold">
              <i>No Company</i>
            </span>
            <p class="text-right">{{ getRole }}</p>
          </div>
        </b-nav-text>
        <b-nav-item-dropdown
          class="account"
          menu-class="w-100 bg-primary rounded-0 border-0 m-0"
          no-caret
          no-flip
          right
          toggle-class="p-0 w-100 bt-dropdown rounded-0"
        >
          <template #button-content>
            <span class="profile-button-wrapper">
              <img class="profileImg" src="https://via.placeholder.com/300" />
              <div class="profileName">
                {{ getUser.entity.firstName }}
                <span>{{ getUser.entity.lastName }}</span>
              </div>
            </span>
          </template>

          <b-dropdown-item
            link-class="dropdown-user-header"
            to="/my/profile/account"
            variant="light"
            >My Profile
          </b-dropdown-item>
          <b-dropdown-item
            class="header"
            to="/my/company"
            variant="light"
            v-if="hasCompany"
            >My Company
          </b-dropdown-item>

          <b-dropdown-item
            class="header"
            @click="openAprova"
            variant="light"
            v-if="isRole(['ROLE_PROVIDER'])"
            >Aprova Report
          </b-dropdown-item>

          <b-modal id="aprovaReportTopMenu" size="xl" hide-footer>
            <template #modal-header>
              <div class="custom-modal__popup">
                <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
                <span class="custom-modal__popup__title"
                  >AprovA Report
                  <span class="custom-modal__popup__title--blue">{{
                    company.name
                  }}</span>
                </span>
              </div>
              <i
                class="fa fa-times custom-modal__popup__close"
                @click="$bvModal.hide('aprovaReportTopMenu')"
              ></i>
            </template>

            <AprovaReport :companyId="company['@id']" />
          </b-modal>

          <div v-if="isRole(['ROLE_PROVIDER'])">
            <b-dropdown-item
              class="header"
              to="/toolbox/imported/relations"
              variant="light"
              >Toolbox
            </b-dropdown-item>
          </div>

          <div v-if="isRole(['ROLE_ADMIN'])">
            <b-dropdown-divider />
            <b-dropdown-item class="header" to="/admin" variant="light">
              System Settings <i class="fa fa-cog"></i>
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item
              link-class="font-weight-normal"
              to="/payments/incoming"
              variant="light"
              >Payments
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item class="header" to="/users" variant="light"
              >Impersonate
            </b-dropdown-item>
          </div>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item class="header" to="/logout" variant="light"
            >Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <div class="process" @click="openProcessList">
        <div class="process-bars" v-if="false"></div>
      </div>

      <b-modal
        id="switchRole"
        hide-footer
        size="lg"
        title="Choose Company and / or Role"
      >
        <template #modal-header>
          <div class="custom-modal__popup">
            <span class="custom-modal__popup__title"
              >Choose Company and / or Role
            </span>
          </div>
          <i
            class="fa fa-times custom-modal__popup__close"
            @click="$bvModal.hide('switchRole')"
          ></i>
        </template>
        <div v-if="hasImpersonator" class="font-weight-bold">
          <b-alert show variant="danger"
            >You can't switch roles/companies as an impersonator
          </b-alert>
        </div>
        <Role v-else />
      </b-modal>

      <WelcomeView
        :isRoleRequest="true"
        @close="canRequestAnotherRole = false"
        v-if="canRequestAnotherRole"
      />
    </b-navbar>

    <b-modal id="processList" hide-footer title="Processing" size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Processing</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('processList')"
        ></i>
      </template>

      <ProcessingList />
    </b-modal>

    <div class="expired" v-if="getExpired">
      <div class="expired__content">
        <h2>Session has expired.</h2>
        <span>Please login again.</span>
        <CustomButton
          type="Default"
          :content="{ title: 'Logout' }"
          @click.native="logout"
        />
      </div>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';
import { mapActions, mapGetters } from 'vuex';
import Role from './Role';
import { IMPERSONATE_LOGOUT } from '../../store/modules/auth/actions';
import CustomButton from '@/components/_ui/CustomButton';
import WelcomeView from '@/views/welcome/Main';
import AprovaReport from '@/components/aprova/Form';
import ProcessingList from '@/components/dashboard/processing/List';
export default {
  name: 'Topmenu',

  mixins: [helpers],
  components: {
    Role,
    CustomButton,
    WelcomeView,
    AprovaReport,
    ProcessingList
  },

  emits: ['toggle'],

  data() {
    return {
      company: {},
      userMenuOpen: false,
      userRoleMenuOpen: false,
      impersonateLoading: false,
      canRequestAnotherRole: false
    };
  },

  computed: {
    ...mapGetters('auth', [
      'isRole',
      'getUser',
      'hasImpersonator',
      'getExpired',
      'hasCompany',
      'getCompany'
    ]),
    ...mapGetters('chat', ['getUnreadConversations']),
    isAdminOpen() {
      return this.$route.matched.some(({ path }) => path === '/admin');
    },
    getRole() {
      return this.parseRoleName(this.getUser.role);
    },
    getRoles() {
      return this.getUser.entity.roles;
    },
    getRemainRoles() {
      return this.getUser.entity.roles
        .map((item) => this.parseRoleName(item))
        .filter((item) => item !== this.getRole);
    },
    getRemainCompanies() {
      return this.getUser.entity.companies
        .map((item) => item.company.name)
        .filter((item) => item === this.getCompanyAdmin);
    },

    getCompanyName() {
      if (this.getCompany) {
        return (
          this.getCompany.entity.company.tradename ||
          this.getCompany.entity.company.name
        );
      }

      return '';
    },
    getCompanies() {
      return this.getUser.entity.companies;
    },
    getCompanyAdmin() {
      return this.getUser.company.id
        ? this.getUser.company.entity.isAdmin
        : false;
    }
  },
  methods: {
    ...mapActions('auth', [IMPERSONATE_LOGOUT]),
    toggleUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
    },

    toggleUserRoleMenu() {
      this.userRoleMenuOpen = !this.userRoleMenuOpen;
    },

    toggleSideMenu() {
      this.$emit('toggle', 'hey');
    },

    logoutImpersonator() {
      this.impersonateLoading = true;
      this[IMPERSONATE_LOGOUT]()
        .then(() => {
          window.location = '/';
        })
        .catch(() => {
          this.impersonateLoading = false;
        });
    },

    logout() {
      this.$router.push('/logout');
    },

    chooseRole() {
      if (this.getUser.entity.companies.length > 0) {
        this.$bvModal.show('switchRole');
      } else {
        this.canRequestAnotherRole = true;
      }
    },

    openAprova() {
      this.company = this.getCompany.entity.company;
      this.$bvModal.show('aprovaReportTopMenu');
    },

    openProcessList() {
      this.$bvModal.show('processList');
    }
  }
};
</script>

<style lang="scss">
.expired {
  width: 100%;
  height: 100vh;
  background: rgb(0 0 0 / 30%);
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  &__content {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    background: white;
    padding: 50px;
    z-index: 10;
    width: 500px;
    height: 250px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 8px;
  }
}

#amplifica_header {
  padding: 0;
  margin: 0;
  height: 50px;

  // -------------- Left Burger Menu --------------
  .menuBurger {
    //padding: 15px 20px;
    border-right: 1px solid $gray-200;
    box-shadow: none;
    border-bottom: 0;
    list-style: none;

    #burgerIcon {
      width: 20px;
      height: 20px;
      position: relative;
      margin: 0;
      transform: rotate(0deg);
      transition: all 0.5s ease-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: $secondary;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: all 0.25s ease-out;

        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 8px;
        }

        &:nth-child(4) {
          top: 16px;
        }
      }
    }
  }

  // ---------------Menu item----------------------

  .menuItem {
    color: #219aed;
    background: #219bed0a;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 50px;
    padding-top: 16px;
    span {
      display: flex;
      justify-content: flex-end;
    }
  }
  .menuItem:hover,
  .router-link-active {
    background: #219bed3a;
  }
  // ----------- Impersionate Content ---------
  .impersionate-content {
    position: relative;
    background: $success;
    color: $white;
    text-align: center;
    padding: 14px;
    margin-right: 35px;
    font-size: 12px;
    height: 45px;
    cursor: pointer;

    &__content {
      display: flex;
    }

    &__danger-icon {
      color: $danger;
    }
  }
  .impersionate-content::after {
    content: '';
    width: 0;
    height: 0;
    right: -35px;
    top: 0;
    background-color: transparent;
    position: absolute;
    border-top: 22.5px solid $primary;
    border-bottom: 22.5px solid $primary;
    border-left: 18px solid $success;
    border-right: 22.5px solid $primary;
  }
  // -------------- Company Name --------------
  .company-name {
    background: $primary;
    color: $white;
    font-size: 12px;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-top: 7px !important;
    cursor: pointer;
  }

  .bg-primary {
    background-color: $primary;
  }

  // -------------- User Menu color --------------
  .account {
    display: block;
    cursor: pointer;
    background: $secondary;
    color: $white;
    min-width: 185px;

    .dropdown-divider {
      width: 90%;
      margin: 0.5rem auto;
      border-top: 1px solid black;
    }

    .dropdown-item {
      font-size: 14px;
      font-weight: bold;

      &:hover {
        color: $dark !important;
        background-color: inherit;
      }
    }

    .profile-button-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;

      .profileImg {
        width: 30px;
        border-radius: 50px;
        margin-right: 10px;
      }

      .profileName {
        //margin-top: 5px;
        color: white;
        font-size: 14px;
        font-weight: 600;
        margin-top: 0px;

        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
      }

      span {
        margin-top: -3px;
        font-weight: normal;
        font-size: 12px;
      }
    }
  }

  .status {
    margin-right: 20px;
    padding-top: 17px;
    float: left;

    &.dropdown-status {
      margin-right: 0;
    }

    .help,
    .notifications,
    .adminSettings {
      float: left;
      background-color: $gray-200;
      height: 35px;
      width: 35px;
      display: block;
      border-radius: 35px;
      transition: all 0.2s ease-out;

      i {
        // font-size: 22px;
        margin-left: auto;
        margin-right: auto;
      }

      &:hover,
      &.router-link-active {
        background-color: $secondary;
        color: $white;

        i {
          color: $white;
        }
      }
    }

    .adminSettings {
      background-image: url('../../assets/img/icons/settings-icon.png');
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 5px;

      &:hover,
      &.router-link-active {
        transform: rotate(360deg);
        background-image: url('../../assets/img/icons/settings-icon-hover.png');
      }
    }

    .help {
      background-image: url('../../assets/img/icons/help-icon.png');
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 5px;
    }

    .notifications {
      position: relative;
      background-image: url('../../assets/img/icons/notifications-icon.png');
      background-repeat: no-repeat;
      background-position: center center;

      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: $pink;
        color: $white;
        border-radius: 50%;
      }
    }
  }

  // -------------- Remove random colors --------------
  .nav-item {
    border-bottom: 0 !important;
  }

  .nav-item:hover,
  .nav-item:focus,
  button:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .dropdown-item:active,
  .dropdown-item:focus {
    outline: none !important;
    background: none !important;
    box-shadow: none !important;
  }

  a,
  a:focus,
  a:focus-visible,
  a:focus-within {
    outline: none !important;
  }
}

.chat {
  position: absolute;
  top: 50px;
  right: 50px;
  background-color: $primary !important;

  .chat-icon {
    z-index: 1;
  }

  .chat-badge {
    position: absolute;
    z-index: 2;
    background-color: $pink;
    // font-size: 0.5em;
    border-radius: 999px;
    right: 8px;
    top: 3px;
  }
}

.impersonated {
  border-bottom: 5px solid $success;
}

.process {
  background-color: $white;
  width: 50px;
  height: 100%;
  overflow: hidden;

  cursor: pointer;

  &-bars {
    position: relative;

    top: 0;

    width: 25px;
    height: 100%;

    background-image: linear-gradient(
      180deg,
      rgba($danger, 0) 0%,
      rgba($danger, 0.1) 10%,
      rgba($danger, 0) 15%,
      rgba($danger, 0.2) 20%,
      rgba($danger, 0) 25%,
      rgba($danger, 0.3) 30%,
      rgba($danger, 0) 35%,
      rgba($danger, 0.4) 40%,
      rgba($danger, 0) 45%,
      rgba($danger, 0.5) 50%,
      rgba($danger, 0.6) 55%,
      rgba($danger, 0.5) 60%,
      rgba($danger, 0) 65%,
      rgba($danger, 0.4) 70%,
      rgba($danger, 0) 75%,
      rgba($danger, 0.3) 80%,
      rgba($danger, 0) 85%,
      rgba($danger, 0.2) 90%,
      rgba($danger, 0) 95%,
      rgba($danger, 0) 100%
    );
    background-size: 100% 100%;
    animation: bg-slide 2s linear infinite;
  }
}

@keyframes bg-slide {
  0%,
  100% {
    top: -50%;
  }

  50% {
    top: 50%;
  }
}
</style>
