<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div class="mt-3">
      <TabGroup :tabs="tabs" />
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  name: 'FinancialButtonGroup',

  components: {
    TabGroup
  },

  computed: {
    ...mapGetters('auth', ['getUser']),

    getCompany() {
      return this.getUser.company.entity.company;
    }
  },

  data() {
    return {
      tabs: [
        {
          active: 'Accountant',
          text: 'Accountant',
          visible: true
        },
        {
          active: 'Financial',
          text: 'Bank',
          visible: true
        },
        {
          active: 'Ledgers',
          text: 'Ledgers',
          visible: true
        }
      ]
    };
  },

  created() {
    if (!this.getCompany.isProvider) {
      this.tabs.shift();
    }
  }
};
</script>
