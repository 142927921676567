<template>
  <div class="aprova-report__form">
    <div class="aprova-report__form__items">
      <h1 class="aprova-report__form__subtitle">360 Light</h1>
    </div>

    <div class="text-center" v-if="isLoading">
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>

    <div class="light" v-else>
      <template v-if="lightLogs.length > 0">
        <table
          border="1"
          class="aprova-report__form__table"
          v-if="lightLogs.length > 0"
        >
          <tr class="text-center">
            <th>From</th>
            <th>To</th>
            <th>Date</th>
            <th>Reason</th>
            <th>Note</th>
          </tr>

          <tr
            class="text-center"
            v-for="(log, index) in lightLogs"
            :key="index"
          >
            <td class="aprova-report__form__table__td">
              <i class="fa fa-circle" :class="[`light__${log.colorFrom}`]"></i>
            </td>
            <td class="aprova-report__form__table__td">
              <i class="fa fa-circle" :class="[`light__${log.colorTo}`]"></i>
            </td>
            <td class="aprova-report__form__table__td">
              {{ formatDate(log.date) }}
            </td>
            <td class="aprova-report__form__table__td">{{ log.reason }}</td>
            <td class="aprova-report__form__table__td">{{ log.note }}</td>
          </tr>
        </table>
      </template>

      <template v-else>
        <div class="text-center">
          <span class="aprova-report__form__subtitle">No logs available.</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Status from '@/components/_ui/status/Status';
import { entityService } from '@/utils/fetch';
import handshakes from '@/mixins/handshakes';
import helpers from '@/mixins/helpers';

export default {
  name: 'AccountantVerification',

  mixins: [helpers, handshakes],

  props: {
    companyId: {
      type: String,
      default: () => {}
    }
  },

  data() {
    return {
      teste: [
        {
          date: '12/05/1993',
          type: 'System Entry',
          text: 'Origin: <LISTNAME>'
        },
        {
          date: '12/05/1993',
          type: '360 Light',
          text: 'Status assigned to ORANGE based on initial inventory'
        },
        {
          date: '12/05/1993',
          type: 'System Entry',
          text: 'Origin: <LISTNAME>'
        },
        {
          date: '12/05/1993',
          type: '360 Light',
          text: 'Status assigned to ORANGE based on initial inventory'
        }
      ],
      lightLogs: [],
      isLoading: false
    };
  },

  mounted() {
    this.fetchSemaphoreState();
  },

  methods: {
    fetchSemaphoreState() {
      this.isLoading = true;
      entityService(`${this.companyId}/company-semaphore-logs`)
        .then((response) => response.json())
        .then((data) => {
          this.lightLogs = data['hydra:member'];
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.light {
  &__item {
    display: flex;
    padding: 10px 0px;

    &__text {
      margin-left: 10px;
    }
  }

  &__GREEN {
    color: $success;
  }

  &__RED {
    color: $danger;
  }

  &__ORANGE {
    color: $warning;
  }
}
</style>
