import Company from '@/views/dashboard/My/Company';
import CompanyViews from './company';

import Profile from '@/views/dashboard/My/Profile';
import ProfileViews from './profile';

export default [
  { path: '/my/company', component: Company, children: [...CompanyViews] },
  {
    path: '/my/profile',
    redirect: '/my/profile/account',
    component: Profile,
    children: [...ProfileViews]
  }
];
