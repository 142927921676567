<template>
  <b-container fluid class="px-4">
    <b-row>
      <b-col cols="7">
        <div class="d-flex align-items-center">
          <img
            class="mr-2"
            src="@/assets/img/icons/menuicons/relations@2x.png"
            alt=""
          />
          <h3 class="font-weight-bold mt-2">Privacy & Security</h3>
        </div>

        <b-button
          v-if="!isChangingPassword"
          @click="changePassword"
          class="mb-4"
          type="button"
          variant="primary"
          >Change Password</b-button
        >

        <div v-if="isChangingPassword">
          <PasswordChange
            :changePassword="submitNewPassword"
            :isLoading="isSubmitting"
          />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import PasswordChange from './PasswordChange.vue';
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';
import { mapGetters } from 'vuex';

export default {
  components: {
    PasswordChange
  },

  computed: {
    ...mapGetters('auth', ['getUser'])
  },

  data() {
    return {
      isChangingPassword: false,
      isSubmitting: false
    };
  },

  methods: {
    changePassword() {
      this.isChangingPassword = true;
    },

    submitNewPassword(item) {
      this.isSubmitting = true;
      entityService(this.getUser.entity['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(item)
      })
        .then((response) => response.json())
        .then((data) => {
          // Show alert
          SwalAlert({
            title: 'Password updated',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {
                  this.isChangingPassword = false;
                }
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error!',
            description: e,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {
                  return;
                }
              }
            }
          });
        })
        .finally((e) => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.container-fluid {
  background-color: $white;
}
</style>
