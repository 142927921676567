<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Email Address"
      label-for="user_email"
      :invalid-feedback="violations.email"
      :state="!isValid('email')"
    >
      <b-form-input
        id="user_email"
        v-model="item.email"
        type="text"
        :disabled="!showSubmit"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="First Name"
      label-for="user_firstName"
      :invalid-feedback="violations.firstName"
      :state="!isValid('firstName')"
    >
      <b-form-input
        id="user_firstName"
        v-model="item.firstName"
        type="text"
        :disabled="!showSubmit"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Last Name"
      label-for="user_lastName"
      :invalid-feedback="violations.lastName"
      :state="!isValid('lastName')"
    >
      <b-form-input
        id="user_lastName"
        v-model="item.lastName"
        type="text"
        :disabled="!showSubmit"
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Roles"
      label-class="font-weight-bold"
      v-if="skipCompanyRoles"
    >
      <b-form-checkbox-group
        v-model="item.roles"
        :options="getAvailableRoles"
        size="sm"
        switches
      ></b-form-checkbox-group>
    </b-form-group>

    <b-form-group v-if="showSubmit" class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    showSubmit: {
      type: Boolean,
      default: true
    },

    skipCompanyRoles: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },

  mounted() {
    this.getRoles();
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    ...mapFields('role/list', {
      roles: 'items'
    }),

    getAvailableRoles() {
      return [
        { text: 'Accountant', value: 'ROLE_ACCOUNTANT' },
        { text: 'Admin', value: 'ROLE_ADMIN' },
        { text: 'Creator', value: 'ROLE_PROVIDER' },
        { text: 'Trainer', value: 'ROLE_TRAINER' },
        // { text: 'Translator', value: 'ROLE_TRANSLATOR' },
        { text: 'User', value: 'ROLE_USER' }
      ];
    }
  },

  methods: {
    ...mapActions({
      getRoles: 'role/list/default'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },
    closeModal() {
      this.$bvModal.hide('createUser');
      this.$bvModal.hide('editUser');
    }
  }
};
</script>
