<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group
      label="Question Group"
      label-for="question_group"
      :invalid-feedback="violations.name"
      :state="!isValid('question_group')"
    >
      <b-form-input
        id="question_group"
        v-model="item.questionGroup"
        type="text"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Question"
      label-for="question"
      :invalid-feedback="violations.question"
      :state="!isValid('question')"
    >
      <b-form-input
        id="question"
        v-model="item.question"
        type="text"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Type"
      label-for="type"
      :invalid-feedback="violations.type"
      :state="!isValid('type')"
    >
      <b-form-select id="type" v-model="item.type" :options="types">
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Order"
      label-for="questionOrder"
      :invalid-feedback="violations.questionOrder"
      :state="!isValid('questionOrder')"
    >
      <b-form-input
        id="questionOrder"
        v-model="item.questionOrder"
        type="text"
        placeholder=""
        :disabled="canChangeOrder"
      >
      </b-form-input>
    </b-form-group>

    <div class="multiple_input" v-if="showInputsCreation">
      <b-form-group
        label="Value"
        label-for="possibleValues"
        :invalid-feedback="violations.possibleValues"
        :state="!isValid('possibleValues')"
      >
        <div
          class="multiple__input__item"
          v-for="(item, index) in inputs"
          :key="index"
        >
          <b-form-input
            id="possibleValues"
            class="mt-3"
            placeholder="Add value"
            v-model="item.value"
            type="text"
          >
          </b-form-input>
          <a
            class="multiple__input__item__icon"
            href="#"
            @click.prevent="deleteAnswer(index)"
            ><i class="fa fa-times"></i
          ></a>
        </div>

        <b-button
          @click.prevent="addPossibleValue"
          class="mt-4"
          variant="primary"
          type="button"
          ><i class="fas fa-plus position-icon"></i> Add
        </b-button>
      </b-form-group>
    </div>

    <b-form-group class="float-right mt-5">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      types: ['Text', 'Percentage', 'Radio', 'Checkbox', 'Select'],
      inputs: [
        {
          value: ''
        }
      ]
    };
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    showInputsCreation() {
      return (
        this.item.type === 'Select' ||
        this.item.type === 'Checkbox' ||
        this.item.type === 'Radio'
      );
    },

    canChangeOrder() {
      return this.initialValues;
    }
  },

  mounted() {
    this.inputs = this.item.possibleValues.map((obj) => {
      return {
        value: obj
      };
    });
  },
  methods: {
    ...mapActions({}),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },
    closeModal() {
      this.$bvModal.hide('createQuestion');
      this.$bvModal.hide('updateQuestion');
    },

    addPossibleValue() {
      this.inputs.push({
        value: ''
      });
    },

    onSendForm() {
      this.item.possibleValues = this.inputs.map((obj) => {
        return obj.value;
      });

      this.handleSubmit(this.item);
    },

    deleteOption(index) {
      this.inputs.splice(index, -1);
    },

    deleteAnswer(index) {
      this.inputs = this.inputs.filter((item, itemIndex) => {
        return index !== itemIndex;
      });
    }
  }
};
</script>

<style lang="scss">
.multiple__input {
  &__item {
    position: relative;

    &__icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(-50%, -50%);
      color: $danger;

      &:hover {
        color: rgba($danger, 0.8);
      }
    }
  }
}
</style>
