<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="country"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:actions="data">
        <a
          href="#"
          @click.prevent="openSettings(data.item)"
          title="Settings"
          v-b-tooltip.hover
          ><i class="fa fa-cog text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createCountry')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Country
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      id="settingsCountry"
      :title="getSettingsModalTitle"
      hide-footer
      size="xl"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Country</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('settingsCountry')"
        ></i>
      </template>
      <CountrySettings :country="editCountry"></CountrySettings>
    </b-modal>

    <b-modal id="createCountry" title="Create Country" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Country</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createCountry')"
        ></i>
      </template>
      <CountryCreate @created="refreshData"></CountryCreate>
    </b-modal>
  </div>
</template>

<script>
import CountrySettings from './Settings/Modal';
import CountryCreate from './Create.vue';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';

export default {
  components: {
    CountrySettings,
    CustomTable,
    CountryCreate
  },

  data() {
    return {
      editCountry: '',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'code', label: 'Code', sortable: true },
        { key: 'actions', label: 'Action', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapFields('country/del', {
      deletedItem: 'deleted'
    }),

    getSettingsModalTitle() {
      return this.editCountry.name || '';
    },

    request() {
      let request = '/countries?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'country/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    openSettings(country) {
      this.editCountry = country;
      this.$bvModal.show('settingsCountry');
    }
  }
};
</script>
