<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">
        {{ error }}
      </span>
    </div>

    <LearningActivityForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import LearningActivityForm from './Form';
import { SwalAlert } from '../../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'learningactivity/create/getField',
  mutationType: 'learningactivity/create/updateField'
});

export default {
  components: {
    LearningActivityForm
  },

  props: {
    showAlert: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },

  data() {
    return {
      item: {}
    };
  },

  beforeDestroy() {
    this.reset();
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId']),
    ...mapFields(['error', 'isLoading', 'created', 'violations']),
    getProgramIri() {
      return `/programs/${this.$route.params.id}`;
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }
      this.$emit('created', created);
      this.$bvModal.hide('createLearningActivity');

      if (this.showAlert) {
        SwalAlert({
          title: 'Learning Activity added',
          icon: 'user',
          type: 'success',
          buttons: {
            cancel: {
              label: 'Form another',
              type: 'warning',
              callback: () => {
                this.$bvModal.show('createLearningActivity');
              }
            },
            confirm: {
              label: 'OK',
              type: 'success',
              callback: () => {}
            }
          }
        });
      }

      // Clear store
      this.reset();
    }
  },

  methods: {
    ...mapActions('learningactivity/create', ['create', 'reset']),

    onSendForm() {
      this.item.createdByCompany = this.getCompanyId;
      this.item.program = this.getProgramIri;
      this.create(this.item);
    }
  }
};
</script>
