<template>
  <div class="aprova-report__form">
    <!-- <div class="aprova-report__form__items">
      <h1 class="aprova-report__form__subtitle">360º Test</h1>
      <div class="aprova-report__form__item">
        <label for="documentStatus">Creator+ name:</label>
        <span id="documentStatus">{{ item.creatorName }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="creationDate">VAT Number:</label>
        <span id="creationDate">{{ item.vatNumber }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">Company Address:</label>
        <span id="version">{{ item.companyAddress }}</span>
      </div>
    </div>

    <div class="aprova-report__form__items">
      <div class="aprova-report__form__item">
        <label for="documentStatus">Governing authority:</label>
        <span id="documentStatus">{{ item.governingAuthority }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="creationDate">CRKBO Number:</label>
        <span id="creationDate">{{ item.crkbo }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">Company primary contact:</label>
        <span id="version">{{ item.primaryContact }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">Email address:</label>
        <span id="version">{{ item.email }}</span>
      </div>
    </div>

    <div class="aprova-report__form__items">
      <div class="aprova-report__form__item">
        <label for="documentStatus">Responsible Accountancy firm:</label>
        <span id="documentStatus">{{ item.responsibleAccountacy }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="creationDate">Responsible Accountant:</label>
        <span id="creationDate">{{ item.responsibleAccountant }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">NBA Registration Number:</label>
        <span id="version">{{ item.nabNumber }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">Email address:</label>
        <span id="version">{{ item.email2 }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">AmplificA General Terms version:</label>
        <span id="version">{{ item.amplificaTerms }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">Amplifica General Terms approved on:</label>
        <span id="version">{{ item.amplificaTermsApproved }}</span>
      </div>

      <div class="aprova-report__form__item">
        <label for="version">Amplifica General Terms approved by:</label>
        <span id="version">{{ item.amplificaTermsApprovedBy }}</span>
      </div>
    </div>

    <hr class="aprova-report__form__hr" /> -->
  </div>
</template>

<script>
export default {
  name: 'IA',

  props: ['company']
};
</script>
