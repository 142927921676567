// Access store
import store from '../store/index';

export const parseRoomName = (conversation) => {
  let userId = store.getters['auth/getUser'].entity['@id'];
  let memberString = '';
  conversation.users.forEach((m) => {
    if (!m.user) {
      return;
    }
    if (m.user['@id'] !== userId) {
      memberString += `${m.user.firstName} ${m.user.lastName}`;
    }
  });

  return memberString;
};
