<template>
  <b-row>
    <b-col cols="12">
      <table
        class="adminDetailsTable w-100 border-0"
        v-if="this.program.loaded"
      >
        <tr class="customerRow">
          <th colspan="3">
            {{
              this.program.isOpenEnrollment
                ? 'Open Enrollment'
                : this.program.customer.name
            }}
          </th>
          <th>Reference</th>
          <th colspan="3">Date</th>
          <th colspan="3">Amount</th>
          <th colspan="3">Actions</th>
        </tr>
        <tr
          class="customerDetails"
          v-for="(item, index) in itemsToShow"
          :key="item['@id']"
          :class="{ first: isFirstSection(index) }"
        >
          <td
            colspan="3"
            class="pl-4"
            :class="{ 'pl-5': item.type === 'Invoice' }"
          >
            {{ getInvoiceTitle(item) }}
          </td>
          <td>{{ item.documentNumber }}</td>
          <td colspan="3">{{ parseDate(item.createdAt) }}</td>
          <template>
            <td colspan="3" v-if="item.type == 'Fee' || item.type == 'Invoice'">
              {{ formatCurrency(item.totalAmount.value) }}
            </td>
            <td colspan="3" v-else>
              <span class="text-success">{{ formatCurrency(item.paid) }} </span>
              /
              {{ formatCurrency(item.totalAmount.value) }}
            </td>
          </template>
          <td colspan="3" class="text-center">
            <span v-if="!item.document">N/A</span>
            <a
              v-else
              :href="item.document.url"
              target="_blank"
              title="Download"
              v-b-tooltip.hover
            >
              <i aria-hidden="true" class="fa fa-download text-info"></i>
            </a>
          </td>
        </tr>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';
import helpers from '@/mixins/helpers';

export default {
  props: {
    program: {
      type: Object,
      required: true
    }
  },

  mixins: [helpers],

  data() {
    return {
      //  items
      items: [],
      itemsSumma: []
    };
  },

  watch: {
    selected: {
      handler: function (newValue) {
        let bAllSelected = true;
        for (let i in this.items) {
          let selected = this.items[i].selected;
          if (!selected) {
            bAllSelected = false;
          }
          this.$emit('selected', {
            item: this.items[i],
            selected: selected
          });
        }

        // If some are not selected, unmark program
        this.$set(this.program, 'selected', bAllSelected);
      }
    },
    'program.selected': {
      deep: true,
      handler: function (newValue) {
        for (let i in this.items) {
          this.$set(this.items[i], 'selected', newValue);
        }
      }
    }
  },

  computed: {
    getInvoices() {
      return this.items.filter((i) => i.type === 'Invoice');
    },
    getFees() {
      return this.items.filter((i) => i.type === 'Fee');
    },
    selected() {
      return this.items.map((item) => item.selected);
    },
    itemsToShow() {
      return [...this.itemsSumma, ...this.getInvoices, ...this.getFees];
    },
    firstInvoice() {
      return this.itemsSumma.length;
    },
    firstFee() {
      return this.firstInvoice + this.getInvoices.length;
    }
  },
  created() {
    this.$set(this.program, 'openLoading', true);

    // Get details
    fetch(`${BUSINESS_SERVICE_ENTRYPOINT}${this.program['@id']}/invoices`)
      .then((response) => response.json())
      .then((data) => {
        this.items = data['hydra:member'];
      })
      .catch((error) => {
        this.errors = error;
      })
      .finally(() => {
        this.$set(this.program, 'loaded', true);
        this.$set(this.program, 'openLoading', false);
      });

    // TEMP: Get details for summa
    fetch(
      `${BUSINESS_SERVICE_ENTRYPOINT}${this.program['@id']}/collection-notes`
    )
      .then((response) => response.json())
      .then((data) => {
        this.itemsSumma = data['hydra:member'];
      });
  },
  methods: {
    onRowSelected(item) {
      this.$set(item, 'selected', !item.selected);
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    },
    isFirstSection(index) {
      return index === this.firstFee || index === this.firstInvoice;
    },

    getInvoiceTitle(item) {
      let title;
      if (item.type && item.type === 'Invoice') {
        title =
          item.invoiceables.length > 0
            ? item.invoiceables.map((invoice) => invoice.article.name).join(' ')
            : 'N/A';
      } else if (item.type === 'Fee') {
        title = 'AmplificA Fee';
      }

      return title || 'SUMMA';
    }
  }
};
</script>
<style lang="scss">
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.adminDetailsTable {
  color: black !important;
  td,
  th {
    border-top: 0 !important;
  }
  .customerRow {
    background: #e8eaec;
    th {
      height: 45px !important;
      line-height: 45px !important;
      padding: 0 10px;
      border-bottom: 1px solid white;
    }
  }
  .customerDetails {
    background: #f3f5f8;
    td {
      border-bottom: 1px solid white;
    }
    &.first {
      border-top: 2px solid black;
    }
  }

  .firstFee {
    border-top: 3px solid black !important;
  }

  .customerDetailsFee td {
    color: #888888 !important;
  }
}
</style>
