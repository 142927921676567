import * as types from './mutation_types';
import { parseRoomName } from '../../../utils/chat';
import Vue from 'vue';

// -------------- ACTIONS --------------
export const CHAT_SELECT = 'CHAT_SELECT';
export const CHAT_MARK_READ = 'CHAT_MARK_READ';
export const CHAT_GET_ROOMS = 'CHAT_GET_ROOMS';
// -------------- EVENTS --------------
export const CHAT_ON_NEW_ROOM = 'CHAT_ON_NEW_ROOM';
export const CHAT_ON_NEW_MESSAGE = 'CHAT_ON_NEW_MESSAGE';

export const mutations = {
  // -------------- ACTIONS --------------
  CHAT_SELECT(state, payload) {
    state.selectedRoom = payload;
  },
  CHAT_MARK_READ(state, payload) {
    state.rooms[payload].newMessages = 0;
  },
  // -------------- EVENTS --------------
  CHAT_ON_NEW_ROOM(state, payload) {
    let newMessages = 0;

    for (let user in payload.users) {
      newMessages += payload.users[user].newMessages || 0;
    }

    // Update data
    if (state.rooms[payload['@id']]) {
      state.rooms[payload['@id']].context = payload.context;
      state.rooms[payload['@id']].users = payload.users;
      state.rooms[payload['@id']].newMessages = newMessages;
    }
    // Insert it
    else {
      let name = parseRoomName(payload);
      Vue.set(state.rooms, payload['@id'], {
        ...payload,
        messages: {},
        name,
        newMessages: newMessages
      });
    }
  },
  CHAT_ON_NEW_MESSAGE(state, payload) {
    let cId =
      payload.type === 'Notification' ? 'notifications' : `${payload.room}`;

    if (state.rooms[cId]) {
      // Add
      Vue.set(state.rooms[cId].messages, payload['id'], payload);
      // Mark new message
      if (!payload.isMe && state.selectedRoom !== cId) {
        state.rooms[cId].newMessages++;
      }
    }
  },
  // -------------- ENTITY STORE: Room --------------
  [types.ROOM_TOGGLE_LOADING](state) {
    Object.assign(state, {
      roomIsLoading: !state.roomIsLoading
    });
  },

  // -------------- ENTITY STORE: Message --------------
  [types.MESSAGE_TOGGLE_LOADING](state) {
    Object.assign(state, {
      messageIsLoading: !state.messageIsLoading
    });
  },

  [types.ROOM_SET_ERROR](state, payload) {
    Object.assign(state, {
      roomError: payload
    });
  }
};
