<template>
  <div class="aprova-report__form">
    <div>
      <div class="aprova-report__form__items">
        <h1 class="aprova-report__form__subtitle">Applicable years</h1>
        <p class="aprova-report__form__subtitle__description">
          {{ company.name }} is subjected for Restitution In Integrum over the
          following years:
        </p>
      </div>

      <div class="text-center" v-if="hasCompletedImpactAnalysis">
        <table
          border="1"
          class="aprova-report__form__table"
          v-if="years.length > 0"
        >
          <tr v-for="(year, index) in years" :key="index">
            <td width="10" class="aprova-report__form__table__td">
              <b-form-checkbox
                size="lg"
                :checked="year.checked"
                disabled
              ></b-form-checkbox>
            </td>
            <td class="aprova-report__form__table__td">{{ year.year }}</td>
          </tr>
        </table>
        <div class="text-center" v-else>
          <span class="aprova-report__form__subtitle">No years available</span>
        </div>
      </div>

      <div class="text-center" v-else>
        <span class="aprova-report__form__subtitle"
          >Impact Analysis is not completed</span
        >
      </div>

      <hr class="aprova-report__form__hr" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import helpers from '@/mixins/helpers';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'VatStatus',

  props: {
    companyId: {
      type: String,
      required: true
    }
  },

  mixins: [helpers],

  computed: {
    ...mapFields('company/show', {
      company: 'retrieved'
    }),

    ...mapFields('company/companyAnalysisQuestions/list', {
      isLoading: 'isLoading',
      items: 'items'
    }),

    years() {
      const availableYears = [
        {
          year: 2011,
          checked: false
        },
        {
          year: 2012,
          checked: false
        },
        {
          year: 2013,
          checked: false
        },
        {
          year: 2014,
          checked: false
        },
        {
          year: 2015,
          checked: false
        },
        {
          year: 2016,
          checked: false
        },
        {
          year: 2017,
          checked: false
        },
        {
          year: 2018,
          checked: false
        },
        {
          year: 2019,
          checked: false
        },
        {
          year: 2020,
          checked: false
        },
        {
          year: 2021,
          checked: false
        }
      ];

      const companyIntegrumYears = this.items.filter((item) => {
        return this.iriToId(item.question['@id']) === '3';
      })[0];

      if (companyIntegrumYears) {
        companyIntegrumYears.answer.forEach((element) => {
          const year = availableYears.filter((itm) => {
            return itm.year === Number(element);
          })[0];

          if (year) {
            year.checked = true;
          }
        });

        return availableYears;
      }

      return [];
    },

    hasCompletedImpactAnalysis() {
      return Object.keys(this.items).length > 0;
    }
  },

  mounted() {
    this.retrieve(this.companyId);
  },

  methods: {
    ...mapActions({
      retrieve: 'company/show/retrieve',
      getCompanyAnalysisQuestions:
        'company/companyAnalysisQuestions/list/default'
    })
  },

  watch: {
    company: function () {
      this.getCompanyAnalysisQuestions(
        `${this.company['@id']}/company-analysis-questions`
      );
    }
  }
};
</script>
