<template>
  <div>
    <b-alert
      v-for="(alert, alertIdx) in alerts"
      :key="alertIdx"
      variant="success"
      dismissible
      show=""
    >
      {{ alert }}
    </b-alert>
    <b-alert
      v-for="(alert, alertIdx) in errors"
      :key="alertIdx"
      variant="danger"
      dismissible
      show=""
    >
      {{ alert }}
    </b-alert>

    <qrcode-stream @decode="onDecode"></qrcode-stream>
  </div>
</template>
<script>
// -------------- COMMENTS   --------------

// -------------- EO COMMENTS   --------------
// Deps
import { QrcodeStream } from 'vue-qrcode-reader';
import { businessLogicService } from '../../../../utils/fetch';

export default {
  components: {
    QrcodeStream
  },
  props: {
    // Token
    token: String
  },
  data() {
    return {
      // Keep track of modal open to request WebCam permissions
      alerts: [],
      errors: []
    };
  },
  computed: {},
  mounted() {},
  methods: {
    onDecode(decodedString) {
      let decoded = JSON.parse(decodedString);
      businessLogicService(`/learning-activity-learners/${decoded.id}`, {
        method: 'PATCH',
        body: JSON.stringify({ keycode: decoded.keycode })
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.alerts.push(`Scanned code: ${decodedString}`);
        })
        .catch(() => {
          let _smallString = decodedString.substr(0, 5);
          this.errors.push(`Error with code: ${_smallString}`);
        });
    }
  }
};
</script>
