<template>
  <div>
    <CustomTable
      :fields="fields"
      list="program"
      @changePage="changePage"
      :perPage="itemsPerPage"
      @handleSortChange="handleSortChange"
      showTitle
    >
      <template v-slot:title>
        <img src="@/assets/img/icons/menu/programs.svg" />
        Proposals overview
      </template>
      <template v-slot:customerName="data">
        <i v-if="data.item.isOpenEnrollment">(Open Enrollment)</i>
        <span v-else>{{ data.item.customer.name }}</span>
      </template>
      <template v-slot:actions="data">
        <div class="program__icon">
          <router-link
            v-b-tooltip.hover
            :to="{
              name: 'ProposalUpdate',
              params: { id: iriToId(data.item['@id']) }
            }"
            title="Edit"
          >
            <i class="fa fa-edit text-info"></i>
          </router-link>
          <a
            v-b-tooltip.hover
            class="ml-2"
            href="#"
            title="Cancel"
            @click="proposalAcceptOrReject(data.item['@id'], 'to_cancelled')"
          >
            <i class="fa fa-ban text-info"></i>
          </a>
          <a
            v-if="data.item.status === 'DRAFTED'"
            v-b-tooltip.hover
            class="ml-2"
            href="#"
            title="Remove"
            @click="removeProposal(data.item)"
          >
            <i class="fa fa-trash text-danger"></i>
          </a>
        </div>
      </template>
      <template #buttons>
        <router-link
          to="/programs/proposals/create"
          class="btn btn-primary btn-sm"
        >
          Create Proposal
        </router-link>
      </template>
    </CustomTable>

    <b-modal
      id="greenLight"
      hide-footer
      no-close-on-backdrop
      @hide="closeGreenLightModal"
    >
      <div class="text-center text-primary">
        You must have green light before creating any program
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';
import programs from '@/mixins/programs';
import Cancel from '@/components/_ui/svg/Cancel';
import { businessLogicService, entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  name: 'ProposalList',

  components: { CustomTable },

  mixins: [helpers, programs],

  data() {
    return {
      editArticle: null,
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        {
          key: 'customerName',
          label: 'Beneficiary',
          sortable: true
        },
        { key: 'accountant.name', label: 'Accountant', sortable: true },
        { key: 'theme.name', label: 'Theme', sortable: true },
        {
          key: 'totalPrice',
          label: 'Price',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.price);
          }
        },
        { key: 'actions', label: 'Action', sortable: false }
      ],
      sort: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapGetters('auth', ['getCompany', 'getCompanyId']),
    ...mapFields('program/del', {
      deletedItem: 'deleted'
    }),
    request() {
      let request = '/programs?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += '&status[]=DRAFTED&status[]=PROPOSED';

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      return request;
    }
  },

  mounted() {
    this.fetchCompany();
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    async fetchCompany() {
      await entityService(this.getCompanyId)
        .then((response) => response.json())
        .then((data) => {
          if (data.semaphoreState !== 'GREEN') {
            this.$bvModal.show('greenLight');
          }
        });
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editArticle = item;
      this.$bvModal.show('editArticle');
    },

    closeGreenLightModal() {
      this.$router.push('/');
    },
    async removeProposal(proposal) {
      SwalAlert({
        title: 'Invitation',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'danger',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning'
          },
          confirm: {
            label: 'Yes, delete it',
            type: 'success',
            callback: () => {
              businessLogicService(proposal['@id'], {
                method: 'DELETE'
              }).then((data) => {
                this.refreshData();
              });
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="scss">
.program {
  &__icon {
    display: flex;
    align-items: center;

    cursor: pointer;

    &--reject {
      height: 20px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
