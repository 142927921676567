<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <GroupForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
      @deleted="onDeleted"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import GroupForm from './Form';

const { mapFields } = createHelpers({
  getterType: 'group/create/getField',
  mutationType: 'group/create/updateField'
});

export default {
  components: {
    GroupForm
  },

  props: {
    programId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createGroup');
    }
  },

  methods: {
    ...mapActions('group/create', ['create']),

    onSendForm() {
      this.item.program = this.programId;
      this.create(this.item);
    },

    onDeleted(group) {
      this.$emit('deleted', group);
    }
  }
};
</script>
