import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  token: JSON.parse(localStorage.getItem('token') || '""'),
  user: JSON.parse(localStorage.getItem('user') || '{}'),
  impersonator: {
    token: JSON.parse(localStorage.getItem('impersonator_token') || '{}'),
    user: JSON.parse(localStorage.getItem('impersonator_user') || '{}')
  },
  sessionExpired: false
};

const namespaced = true;

export const auth = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
