import { render, staticRenderFns } from "./Recover.vue?vue&type=template&id=7fd1bbd0&scoped=true"
import script from "./Recover.vue?vue&type=script&lang=js"
export * from "./Recover.vue?vue&type=script&lang=js"
import style0 from "./Recover.vue?vue&type=style&index=0&id=7fd1bbd0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fd1bbd0",
  null
  
)

export default component.exports