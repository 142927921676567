<template>
  <b-container class="mt-4" fluid>
    <CustomTable :fields="fields" list="payment" @changePage="changePage">
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="showPaymentInfo(data.item)"
          v-b-tooltip.hover
          title="Show"
          ><i class="fa fa-eye text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createPayment')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Payment
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      id="createPayment"
      hide-footer
      title="Add New Payment"
      size="xl"
      @hide="closeModal"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Payment</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createPayment')"
        ></i>
      </template>

      <PaymentCreate @created="refreshData"></PaymentCreate>
    </b-modal>

    <b-modal id="showPayment" hide-footer title="Show Payment" size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Show Payment</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('showPayment')"
        ></i>
      </template>

      <PaymentShow :payment="payment"></PaymentShow>
    </b-modal>
  </b-container>
</template>

<script>
import dayjs from 'dayjs';
import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';
import PaymentCreate from './Create.vue';
import PaymentShow from './Show.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    CustomTable,
    PaymentCreate,
    PaymentShow
  },

  mixins: [helpers],

  data() {
    return {
      fields: [
        {
          key: 'parent',
          label: 'Payment #',
          sortable: true,
          formatter: (value, key, item) => {
            return `#${this.iriToId(item['@id'])}`;
          }
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            return dayjs(item.date).format('DD/MM/YYYY');
          }
        },
        {
          key: 'fromName',
          label: 'From Name',
          sortable: true
        },
        {
          key: 'fromIban',
          label: 'From Iban',
          sortable: true
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          formatter: (v, k, item) => {
            return this.formatCurrency(item.amount);
          }
        },
        {
          key: 'reference',
          label: 'Reference',
          sortable: true
        },

        {
          key: 'actions',
          label: 'Actions',
          sortable: true
        }
      ],
      payment: {},
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },
  computed: {
    request() {
      let request = '/payments?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&type=DEPOSIT&exists[parent]=true`;
      return request;
    }
  },

  methods: {
    ...mapActions({
      getPage: 'payment/list/default',
      resetCollectionNotes: 'collectionNotes/list/resetState',
      resetFees: 'invoices/list/resetState'
    }),

    showPaymentInfo(item) {
      this.payment = { ...item };
      this.$bvModal.show('showPayment');
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    closeModal() {
      this.resetCollectionNotes();
      this.resetFees();
    }
  }
};
</script>
´
