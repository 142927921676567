<template>
  <div>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/company@2x.png"
      />
      <h3 class="font-weight-bold mt-2">Establishments</h3>
    </div>

    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="establishment"
      @changePage="changePage"
    >
      <template v-slot:isDefault="data">
        <div class="text-center">
          <i class="fa fa-asterisk fa-primary" v-if="data.item.isDefault"></i>
        </div>
      </template>

      <template v-slot:actions="data">
        <span v-if="!deleteIsLoading(data.item['@id'])">
          <a
            class="mr-2"
            href="#"
            @click.prevent="edit(data.item)"
            v-b-tooltip.hover
            title="Edit"
          >
            <i class="fas fa-edit text-info"></i>
          </a>
          <a
            v-b-tooltip.hover
            title="Delete"
            class="ml-2"
            href="#"
            @click="del(data.item)"
            v-if="!data.item.isDefault"
            ><i class="fa fa-trash text-info"></i
          ></a>
        </span>
        <b-spinner v-else small></b-spinner>
      </template>
      <template #buttons>
        <b-button
          @click="$bvModal.show('createEstablishment')"
          variant="primary"
        >
          <i class="fas fa-plus"></i>
          Add Establishment
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="createEstablishment" hide-footer title="Create Establishment">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Establishment</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createEstablishment')"
        ></i>
      </template>

      <EstablishmentCreate :company-id="getCompanyId" @created="refreshData" />
    </b-modal>

    <b-modal id="editEstablishment" hide-footer title="Edit Establishment">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Establishment</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editEstablishment')"
        ></i>
      </template>

      <EstablishmentUpdate
        :establishment-id="editEstablishment"
        @updated="refreshData"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import CustomTable from '@/components/_ui/tables/CustomTable';
import EstablishmentCreate from './Create';
import EstablishmentUpdate from './Update';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    CustomTable,
    EstablishmentCreate,
    EstablishmentUpdate
  },

  props: {
    readOnly: {
      type: Boolean,
      required: true
    },
    company: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data() {
    return {
      // Delete/edit
      editEstablishment: null,
      deleting: {},
      establishment: '',
      fields: [
        {
          key: 'isDefault',
          label: 'Headquarter',
          sortable: true
        },
        {
          key: 'description',
          label: 'Description',
          sortable: true
        },
        {
          key: 'fiscalId',
          label: 'VAT',
          sortable: true
        },
        {
          key: 'address.street',
          label: 'Address',
          sortable: true
        },
        {
          key: 'address.number',
          label: 'Number',
          sortable: true
        },
        {
          key: 'address.zip',
          label: 'Postal Code',
          sortable: true
        },
        {
          key: 'address.city',
          label: 'City',
          sortable: true
        },
        {
          key: 'address.state',
          label: 'State',
          sortable: true
        },
        {
          key: 'address.country.name',
          label: 'Country',
          sortable: true
        },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser', 'getCompanyId']),

    ...mapFields('establishment/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),

    selectedCompanyId() {
      return this.company ? this.company['@id'] : this.getCompanyId;
    },

    request() {
      let request = `${this.selectedCompanyId}/establishments?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      return request;
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }
      this.refreshData();
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'establishment/list/default',
      deleteItem: 'establishment/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },
    del(item) {
      SwalAlert({
        title: 'Delete Establishment',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'danger',
        buttons: {
          cancel: {
            label: 'Oeps, cancel!',
            type: 'warning',
            callback: () => {
              return;
            }
          },
          confirm: {
            label: 'Yes, Delete!',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.deleteItem({ '@id': itemId });

              SwalAlert({
                title: 'Establishment deleted',
                icon: 'user',
                type: 'success',
                buttons: {
                  confirm: {
                    label: 'OK',
                    type: 'success'
                  }
                }
              }).then(() => {
                this.refreshData();
              });
            }
          }
        }
      });
    },

    deleteIsLoading(establishmentId) {
      return this.deleting.hasOwnProperty(establishmentId);
    },

    edit(item) {
      this.editEstablishment = item['@id'];
      this.$bvModal.show('editEstablishment');
    }
  }
};
</script>
