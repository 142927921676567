<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 57 99"
  >
    <defs>
      <filter
        id="Union_47"
        x="0"
        y="0"
        width="100%"
        height="100%"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dx="3" dy="2" input="SourceAlpha" />
        <feGaussianBlur stdDeviation="2.5" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="Component_186_1"
      data-name="Component 186 – 1"
      transform="translate(4.5 5.5)"
    >
      <g id="Component_185_1" data-name="Component 185 – 1">
        <g transform="matrix(1, 0, 0, 1, -4.5, -5.5)" filter="url(#Union_47)">
          <path
            id="Union_47-2"
            data-name="Union 47"
            d="M0,64.642V0H38.427V64.2c0,.145,0,.292,0,.438,0,11.025-8.606,19.969-19.216,19.969S0,75.667,0,64.642ZM38.427,4.228V0l.547.6,3.294,3.631Z"
            transform="translate(4.5 5.5)"
            fill="#1e2f47"
          />
        </g>
      </g>
      <ellipse
        :class="{ 'ellipse--green': statusChecker === 'GREEN' }"
        class="ellipse"
        id="Ellipse_91"
        data-name="Ellipse 91"
        cx="7.47"
        cy="7.47"
        rx="7.47"
        ry="7.47"
        transform="translate(12.168 54.818)"
        fill="#6fb942"
      />
      <ellipse
        :class="{ 'ellipse--yellow': statusChecker === 'ORANGE' }"
        class="ellipse"
        id="Ellipse_94"
        data-name="Ellipse 94"
        cx="7.47"
        cy="7.47"
        rx="7.47"
        ry="7.47"
        transform="translate(12.168 37.387)"
        fill="#fd9200"
      />
      <ellipse
        :class="{ 'ellipse--red': statusChecker === 'RED' }"
        class="ellipse"
        id="Ellipse_93"
        data-name="Ellipse 93"
        cx="7.47"
        cy="7.47"
        rx="7.47"
        ry="7.47"
        transform="translate(12.168 19.957)"
        fill="#e04a46"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'StatusChecker',

  props: {
    statusChecker: {
      status: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.ellipse {
  fill: $gray-500;

  &--red {
    fill: $danger;
  }

  &--yellow {
    fill: $warning;
  }

  &--green {
    fill: $success;
  }
}
</style>
