<template>
  <b-form @submit.prevent="onFormSend">
    <template
      v-if="
        (bankAccountsItems.length > 0 &&
          !isLoadingBankAccounts &&
          !isLoadingCompanies) ||
        initialValues
      "
    >
      <b-row>
        <b-col cols="6">
          <div class="mb-4">
            <b-card bg-variant="light" border-variant="dark">
              <h3 class="mb-2">Identify Recipient Bank Account</h3>
              <p class="small mb-2">{{ bailiffCompany.name }}</p>
              <b-form-row>
                <b-col cols="6">
                  <b-form-group label="To IBAN *" label-for="toIban">
                    <template v-if="initialValues">
                      <b-form-input
                        v-model="item.toIban"
                        class="font-size form-input"
                        disabled
                        required
                        type="text"
                      ></b-form-input>
                    </template>
                    <template v-else>
                      <b-form-select
                        v-if="isLoadingBankAccounts"
                        disabled
                        value=""
                      >
                        <b-form-select-option selected value=""
                          >Loading...
                        </b-form-select-option>
                      </b-form-select>

                      <b-form-select
                        v-else
                        v-model="selectedToBankAccount"
                        :disabled="isDisabled"
                        class="form-control"
                        name="toIban"
                        required
                      >
                        <b-form-select-option disabled value=""
                          >Choose one...
                        </b-form-select-option>
                        <b-form-select-option
                          v-for="(bankAccount, idx) in bankAccountsItems"
                          :key="idx"
                          :value="bankAccount"
                        >
                          {{ bankAccount.IBAN }}
                        </b-form-select-option>
                      </b-form-select>
                    </template>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="To Holdername *"
                    label-for="toHoldername"
                  >
                    <b-form-input
                      v-model="item.toName"
                      class="font-size form-input"
                      disabled
                      required
                      type="text"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Date" label-for="date">
                    <b-input-group class="mb-3">
                      <b-form-input
                        id="date"
                        v-model="item.date"
                        :disabled="isDisabled"
                        autocomplete="off"
                        placeholder="YYYY-MM-DD"
                        required
                        type="text"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="item.date"
                          :disabled="isDisabled"
                          button-only
                          locale="en-US"
                          right
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group label="Payment Amount *" label-for="payment">
                    <money
                      v-model="item.amount"
                      :disabled="isDisabled"
                      class="font-size form-input form-control form-control-sm"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-card>
          </div>
        </b-col>

        <b-col cols="6">
          <b-card bg-variant="light" border-variant="dark">
            <h3 class="mb-2">Identify Sender Bank Account</h3>
            <p class="small mb-2">
              <i
                >Find a reference and the respective Customer will show in
                here</i
              >
            </p>

            <b-form-group label="Customer" label-for="Company">
              <b-form-input
                v-if="filteringCustomer === null"
                readonly
                value="Select a reference first"
              ></b-form-input>
              <b-form-input
                v-else-if="
                  filteringCustomer !== null && filteringCustomerData === null
                "
                readonly
                value="Loading..."
              ></b-form-input>
              <b-form-input
                v-else
                :value="filteringCustomerData.name"
                readonly
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Sender Bank Accounts" label-for="Company">
              <table
                class="table table-sm table-borderless"
                v-if="filteringCustomerData"
              >
                <tr v-if="companyBankAccountsLoading">
                  <td colspan="2">
                    <b-spinner small></b-spinner>
                  </td>
                </tr>
                <tr
                  v-for="(companyBankAccount, idx) in companyBankAccounts"
                  v-else
                  :key="idx"
                >
                  <td>
                    <b-form-radio
                      name="iban"
                      v-model="selectedFromBankAccount"
                      :value="companyBankAccount"
                      >{{ companyBankAccount.holderName }}
                    </b-form-radio>
                  </td>
                  <td>{{ companyBankAccount.IBAN }}</td>
                  <td width="100"></td>
                </tr>

                <tr>
                  <td>
                    <input
                      v-model="bankAccount.holderName"
                      class="d-block w-100"
                      placeholder="Name"
                    />
                  </td>
                  <td>
                    <input
                      v-model="bankAccount.IBAN"
                      class="d-block w-100"
                      placeholder="IBAN"
                    />
                  </td>
                  <td>
                    <b-button
                      v-if="!creatingBankAccountLoading"
                      class="float-right"
                      size="sm"
                      variant="primary"
                      :disabled="!bankAccount.holderName || !bankAccount.IBAN"
                      @click="createBankAccount(bankAccount)"
                      >Add
                    </b-button>
                    <b-button
                      v-else
                      class="float-right"
                      size="sm"
                      variant="primary"
                      disabled
                      ><b-spinner small></b-spinner>
                    </b-button>
                  </td>
                </tr>
              </table>
            </b-form-group>

            <!--            <b-form-group label="IBAN list" label-for="fromIBAN" label-cols="3" content-cols="9">-->
            <!--              <b-table>-->
            <!--                <               </tr>-->
            <!--              </b-table>-->
            <!--              <template v-if="initialValues">-->
            <!--                <b-form-input-->
            <!--                v-model="item.fromIban"-->
            <!--                class="font-size form-input"-->
            <!--                disabled-->
            <!--                required-->
            <!--                type="text"-->
            <!--                ></b-form-input>-->
            <!--              </template>-->
            <!--              <template v-else>-->
            <!--                <b-form-select-->
            <!--                v-model="item.fromIban"-->
            <!--                :disabled="isDisabled"-->
            <!--                class="form-control"-->
            <!--                name="fromIBAN"-->
            <!--                >-->
            <!--                  <b-form-select-option disabled value=""-->
            <!--                  >Choose one...-->
            <!--                  </b-form-select-option>-->
            <!--                  <b-form-select-option-->
            <!--                  v-for="(bankAccount, idx) in companyBankAccounts"-->
            <!--                  :key="idx"-->
            <!--                  :value="bankAccount"-->
            <!--                  >-->
            <!--                    {{ bankAccount.IBAN }}-->
            <!--                  </b-form-select-option>-->
            <!--                </b-form-select>-->
            <!--              </template>-->
            <!--            </b-form-group>-->

            <!--            <div v-if="!initialValues" class="add-bankAccount">-->
            <!--              <span> Add BankAccount </span>-->

            <!--              <div-->
            <!--              v-if="creatingBankAccountError"-->
            <!--              class="alert alert-danger"-->
            <!--              role="alert"-->
            <!--              >-->
            <!--                <span aria-hidden="true" class="fa fa-exclamation-triangle">{{-->
            <!--                    creatingBankAccountError-->
            <!--                  }}</span>-->
            <!--              </div>-->
            <!--              <b-form @submit.prevent="createBankAccount(bankAccount)">-->
            <!--                <b-form-input-->
            <!--                id="holderName"-->
            <!--                v-model="bankAccount.holderName"-->
            <!--                :disabled="isDisabled"-->
            <!--                class="ml-2"-->
            <!--                placeholder="Holder name"-->
            <!--                required-->
            <!--                type="text"-->
            <!--                >-->
            <!--                </b-form-input>-->

            <!--                <b-form-input-->
            <!--                id="IBAN"-->
            <!--                v-model="bankAccount.IBAN"-->
            <!--                :disabled="isDisabled"-->
            <!--                class="ml-2"-->
            <!--                placeholder="Iban"-->
            <!--                required-->
            <!--                type="text"-->
            <!--                >-->
            <!--                </b-form-input>-->

            <!--                <b-button-->
            <!--                v-if="!isCreatingBankAccount"-->
            <!--                class="ml-2 mt-2 mb-2"-->
            <!--                type="submit"-->
            <!--                variant="primary"-->
            <!--                ><i class="fas fa-plus position-icon"></i>-->
            <!--                </b-button>-->
            <!--                <b-button v-else class="ml-2 mt-2 mb-2" type="submit">-->
            <!--                  <b-spinner small></b-spinner>-->
            <!--                </b-button>-->
            <!--              </b-form>-->
            <!--            </div>-->

            <!--            <b-form-group label="From Holdername *" label-for="fromHoldername">-->
            <!--              <b-form-input-->
            <!--              v-model="item.fromName"-->
            <!--              class="font-size form-input"-->
            <!--              disabled-->
            <!--              required-->
            <!--              type="text"-->
            <!--              ></b-form-input>-->
            <!--            </b-form-group>-->
            <!--          </div>-->

            <!--          <div v-else>-->
            <!--            <b-form-group label="From IBAN" label-for="fromIban">-->
            <!--              <b-form-input-->
            <!--              id="fromIban"-->
            <!--              v-model="item.fromIban"-->
            <!--              :disabled="isDisabled"-->
            <!--              required-->
            <!--              type="text"-->
            <!--              >-->
            <!--              </b-form-input>-->
            <!--            </b-form-group>-->

            <!--            <b-form-group label="from Holdername" label-for="fromHoldername">-->
            <!--              <b-form-input-->
            <!--              id="fromHoldername"-->
            <!--              v-model="item.fromName"-->
            <!--              :disabled="isDisabled"-->
            <!--              required-->
            <!--              type="text"-->
            <!--              >-->
            <!--              </b-form-input>-->
            <!--            </b-form-group>-->
            <!--          </div>-->
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-card bg-variant="light" border-variant="dark">
          <h3 class="mb-2">Find reference</h3>
          <p class="small mb-2">
            Pick a Creator to get their RII, SUMMA and Credit-Notes references
          </p>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Creator" label-for="Company">
                <b-form-select v-if="isLoadingCompanies" disabled value="">
                  <b-form-select-option selected value=""
                    >Loading...
                  </b-form-select-option>
                </b-form-select>
                <b-form-select
                  v-else
                  v-model="item.company"
                  :disabled="isDisabled"
                  class="form-control"
                  name="Company"
                  size="md"
                >
                  <b-form-select-option disabled value=""
                    >Choose one...
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="(company, idx) in companyItems"
                    :key="idx"
                    :value="company['@id']"
                  >
                    {{ company.name }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="References" label-for="reference">
                <b-form-tags
                  v-model="item.references"
                  :disabled="isDisabled"
                  class="form-input"
                  input-id="reference"
                  size="md"
                ></b-form-tags>
              </b-form-group>
            </b-col>
          </b-row>

          <CustomTable
            v-if="payments.length > 0"
            :fields="fields"
            :hover="true"
            :values="payments"
            @rowClicked="rowClicked"
            @changePage="changePage"
          >
            <template v-slot:documentNumber="data">
              <div
                class="text-left py-2 px-4 d-flex justify-content-between"
                :class="{
                  'bg-primary': item.references.indexOf(data.value) !== -1,
                  'bg-info': item.references.indexOf(data.value) === -1
                }"
              >
                {{ data.value }}
                <b-badge variant="light">
                  <template>
                    <b-icon
                      icon="plus"
                      v-if="item.references.indexOf(data.value) === -1"
                    ></b-icon>
                    <b-icon icon="dash" v-else></b-icon>
                  </template>
                </b-badge>
              </div>
            </template>
          </CustomTable>
        </b-card>
      </b-row>

      <b-form-group class="float-right">
        <b-button
          v-if="!isLoading"
          class="mr-2"
          type="button"
          variant="secondary"
          @click="closeModal"
          >Close
        </b-button>
        <template v-if="!initialValues">
          <b-button v-if="!isLoading" type="submit" variant="primary"
            >Submit
          </b-button>
          <b-button v-else type="submit">
            <b-spinner small></b-spinner>
          </b-button>
        </template>
      </b-form-group>
    </template>

    <template v-else-if="!isLoadingBankAccounts && !isLoadingCompanies">
      <span
        >You have no bank accounts added. <br />
        <router-link to="/my/company/financial"
          >Please add at least one</router-link
        >
      </span>
    </template>

    <template v-else>
      <div class="text-center text-danger my-2">
        <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
      </div>
    </template>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { entityService } from '@/utils/fetch';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { debounce, isString } from 'lodash';
import { SwalAlert } from '@/utils/swal';
import moment from 'moment';
import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';

export default {
  components: {
    CustomTable
  },

  props: {
    handleSubmit: {
      type: Function,
      required: false
    },

    isDisabled: {
      type: Boolean,
      default: false
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  mixins: [helpers],

  computed: {
    ...mapFields('bankaccount/list', {
      bankAccountsItems: 'items',
      isLoadingBankAccounts: 'isLoading'
    }),
    ...mapFields('company/list', {
      companyItems: 'items',
      isLoadingCompanies: 'isLoading'
    }),
    ...mapFields('collectionNotes/list', {
      collectionNotes: 'items'
    }),

    item() {
      return this.initialValues || this.paymentItem;
    },

    payments() {
      const allPayments = [...this.collectionNotes];
      if (this.companyRIIReference) {
        allPayments.unshift({
          documentNumber: this.companyRIIReference,
          isRiiReference: true
        });
      }

      // If have a reference selected, just keep same customer
      if (this.filteringCustomer) {
        return allPayments.filter((p) => {
          return p.partyToInvoice === this.filteringCustomer;
        });
      }

      return allPayments;
    },
    requestCollectionNotes() {
      let request = `${this.companySelected}/collection-notes?`;
      request += `page=${this.collectionPage.page}`;
      request += `&itemsPerPage=${this.collectionPage.itemsPerPage}`;
      request += `&isPaid=false`;
      return request;
    }
  },

  data() {
    return {
      bailiffCompany: null,

      companyBankAccounts: [],
      companyBankAccountsLoading: false,
      companySelected: {},

      selectedToBankAccount: null,
      selectedFromBankAccount: null,

      bankAccount: {},
      creatingBankAccountLoading: false,
      creatingBankAccountError: null,

      paymentItem: {
        toIban: '',
        toName: '',
        fromIban: '',
        fromName: '',
        references: [],
        date: '',
        amount: ''
      },

      fields: [
        {
          key: 'index',
          thClass: 'd-none',
          tdClass: 'd-none'
        },
        {
          key: 'documentNumber',
          label: 'Document Number',
          sortable: true
        },
        {
          key: 'paid',
          label: 'Paid',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.paid);
          }
        },
        {
          key: 'unpaid',
          label: 'Unpaid',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.unpaid);
          }
        }
      ],

      companyRIIReference: null,
      filteringCustomer: null,
      filteringCustomerData: null,
      collectionPage: {
        page: 1,
        itemsPerPage: 25
      }
    };
  },

  mounted() {
    if (this.initialValues) {
      this.item.company = this.item.companyId;
      this.item.date = moment(this.item.date).format('YYYY-MM-DD');
    }

    this.getBailiffCompany();
    this.getCompanies('/companies?itemsPerPage=10000');
  },

  methods: {
    ...mapActions({
      getCollectionNotes: 'collectionNotes/list/default',
      resetCollectionNotes: 'collectionNotes/list/resetState',
      getBankAccounts: 'bankaccount/list/default',
      getCompanies: 'company/list/default',
      resetFees: 'invoices/list/resetState'
    }),

    onFormSend() {
      this.handleSubmit(this.item);
    },

    closeModal() {
      this.resetCollectionNotes();
      this.resetFees();

      this.$bvModal.hide('showPayment');
      this.$bvModal.hide('createPayment');
    },

    getBailiffCompany() {
      entityService('/companies/bailiff')
        .then((response) => response.json())
        .then((data) => {
          this.bailiffCompany = data;
          this.getBankAccounts(`${data['@id']}/bank-accounts?`);
        })
        .catch((error) => console.log(error));
    },

    /**
     * O M G hacky
     * @param val
     * @returns {*}
     */
    removeCommasFromString(val) {
      const regex = /[.€\s]/g;
      const result = val.replace(regex, '');
      return result.replace(',', '.');
    },

    createBankAccount(bankAccount) {
      const payload = {
        company: this.filteringCustomer,
        IBAN: bankAccount.IBAN,
        holderName: bankAccount.holderName
      };

      this.creatingBankAccountLoading = true;
      entityService('/bank-accounts', {
        method: 'POST',
        body: JSON.stringify(payload)
      })
        .catch((error) => {
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: error.errors._error,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        })
        .then((response) => response.json())
        .then((data) => {
          this.companyBankAccounts.push(data);
          this.bankAccount = {};
        })
        .finally(() => {
          this.creatingBankAccountLoading = false;
        });
    },

    fetchBankAccountsFromCompany(company) {
      this.companyBankAccountsLoading = true;
      entityService(`${company}/bank-accounts?itemsPerPage=100000`)
        .then((response) => response.json())
        .then((data) => {
          this.companyBankAccounts = data['hydra:member'];
        })
        .finally(() => {
          this.companyBankAccountsLoading = false;
        });
    },

    fetchCollectionNotes() {
      this.getCollectionNotes(this.requestCollectionNotes);
    },

    rowClicked(item) {
      // If ref exists, remove it
      const refIndex = this.item.references.indexOf(item.documentNumber);
      if (refIndex > -1) {
        this.item.references.splice(refIndex, 1);
        return;
      }

      // Mark the customer
      if (!this.filteringCustomer) {
        this.filteringCustomer = item.partyToInvoice;
        // Fetch company
        entityService(item.partyToInvoice)
          .then((response) => response.json())
          .then((data) => {
            this.filteringCustomerData = data;
          });
      }

      this.item.references.push(item.documentNumber);
      this.item.amount = item.totalAmount.value;
    },

    changePage(page) {
      this.collectionPage.page = page;
      this.fetchCollectionNotes();
    }
  },

  watch: {
    'item.company': function (company) {
      this.companyRIIReference = this.companyItems.filter((item) => {
        return item['@id'] === company;
      })[0].RIIReference;
      this.companySelected = company;
      this.fetchCollectionNotes();
    },

    /**
     * Make sure we clean up customer data
     * @param references
     */
    'item.references': function (references) {
      if (references.length === 0) {
        this.filteringCustomer = null;
        this.filteringCustomerData = null;
      }
    },

    /**
     * Show the bank accounts of this customer
     * @param filteringCustomer
     */
    filteringCustomer: function (filteringCustomer) {
      this.selectedBankAccount = null;
      if (filteringCustomer) {
        this.fetchBankAccountsFromCompany(filteringCustomer);
      }
    },

    /**
     * Automatically select first, if only one bank account from bailiff
     * @param bankAccountsItems
     */
    bankAccountsItems: function (bankAccountsItems) {
      if (bankAccountsItems.length === 1) {
        this.selectedToBankAccount = bankAccountsItems[0];
      }
    },
    selectedFromBankAccount: function (selectedBankAccount) {
      this.item.fromIban = selectedBankAccount
        ? selectedBankAccount.IBAN
        : null;
      this.item.fromName = selectedBankAccount
        ? selectedBankAccount.holderName
        : null;
    },
    selectedToBankAccount: function (selectedBankAccount) {
      this.item.toIban = selectedBankAccount ? selectedBankAccount.IBAN : null;
      this.item.toName = selectedBankAccount
        ? selectedBankAccount.holderName
        : null;
    },

    creatingBankAccountError: debounce(function () {
      this.creatingBankAccountError = null;
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>
.add-bankAccount {
  form {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $gray-300;
    padding: 10px;
  }
}
</style>
