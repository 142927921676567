<template>
  <div class="swal-container">
    <div class="swal-container__header" :class="headerClasses">
      <template v-if="icon">
        <img
          v-if="icon == 'error'"
          src="../../../assets/icons/Icons Web/alerts/Alert_Icon_Cancel_stop_general.svg"
          aria-label="icon stop"
        />
        <img
          v-else-if="icon == 'delete'"
          src="../../../assets/icons/Icons Web/alerts/Alert_Icon_Delete_general.svg"
          aria-label="icon delete"
        />
        <img
          v-else-if="icon == 'invoices'"
          src="../../../assets/icons/Icons Web/alerts/Alert_Icon_Invoices_Summa.svg"
          aria-label="icon invoices"
        />
        <img
          v-else-if="icon == 'upload'"
          src="../../../assets/icons/Icons Web/alerts/Alert_Icon_Upload_file.svg"
          aria-label="icon upload"
        />
        <img
          v-else-if="icon == 'user'"
          src="../../../assets/icons/Icons Web/alerts/Alert_Icon_User.svg"
          aria-label="icon user"
        />
        <img
          v-else-if="icon == 'validation'"
          src="../../../assets/icons/Icons Web/alerts/Alert_Icon_Validation.svg"
          aria-label="icon validation"
        />
      </template>
      <h2 v-if="title">{{ title }}</h2>
      <div class="swal-container__header__progress" v-if="progress">
        <span>{{ progress.value }}%</span>
        <b-progress
          :value="progress.value"
          :max="progress.max"
          :variant="progress.variant"
          animated
        ></b-progress>
      </div>
    </div>
    <div class="swal-container__content" v-if="showContent">
      <h3 v-if="subTitle">
        {{ subTitle }}
      </h3>
      <span v-if="description"> {{ description }} </span>
    </div>
    <!-- <div class="swal-container__actions" v-if="showActions">
      <button
        v-for="(action, index) in actions"
        :key="index"
        @click="choseCaller(action)"
        :class="'btn btn-' + action.type"
      >
        {{ action.label }}
      </button>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'SwalAlert',
  data() {
    return {
      result: 'dismiss'
    };
  },
  props: {
    title: {
      type: String,
      default: null
    },
    subTitle: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    type: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: null
    },
    progress: {
      type: Object,
      default: null
    }
  },
  computed: {
    showContent() {
      return this.progress == null && (this.description || this.subTitle);
    },
    headerClasses() {
      return `${this.type} ${this.showContent ? '' : 'full'}`;
    }
  }
};
</script>
<style lang="scss">
.swal2-popup {
  border-radius: 16px;
  padding: 0;
  width: 28rem;
  .swal2-html-container {
    margin: 0;
  }

  .swal2-content {
    padding: 0;
  }

  .swal2-actions {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding: 0 1.6rem 1rem 1.6rem;

    .btn-success {
      background-color: $success;
    }
    .btn-info {
      background-color: $info;
    }
    .btn-primary {
      background-color: $primary;
    }
    .btn-secondary {
      background-color: $secondary;
    }
    .btn-danger {
      background-color: $danger;
    }
    .btn-warning {
      background-color: $warning;
    }
  }
}
.swal-container {
  &__header {
    border-radius: 0 !important;
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
    padding-top: 3.25rem;

    img {
      padding-bottom: 1rem;
    }
    h2 {
      font-size: 18px;
      padding-bottom: 1rem;
      font-weight: 500;
      color: white;
      margin: 0;
    }
    span {
      vertical-align: top;
    }
    &__progress {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: white;
      font-size: 60px;
      width: 60%;
      margin: auto;
      padding-bottom: 4rem;

      span {
        padding-bottom: 0.5rem;
      }
    }

    &.success {
      background-color: $success;
    }
    &.warning {
      background-color: $warning;
    }
    &.info {
      background-color: $info;
    }
    &.danger {
      background-color: $danger;
    }
    &.full {
      border-radius: 15px;
    }
  }
  &__content {
    padding-top: 2.2rem;
    padding-bottom: 2rem;

    h3 {
      font-weight: 700;
      font-size: 24px !important;
      padding-bottom: 0.5rem;
      margin: 0;
    }

    span {
      text-align: justify;
      padding-bottom: 2rem;
    }
  }
}
</style>
