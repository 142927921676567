<template>
  <div class="user-update">
    <div class="user-update__tabs">
      <TabGroup
        :tabs="tabs"
        :user="user"
        :roles="roles"
        :selectedRoles="selectedRoles"
        :handle-submit="handleSubmit"
        :isLoading="isLoading"
      />
    </div>
  </div>
</template>

<script>
import { SwalAlert } from '@/utils/swal';
import { entityService } from '@/utils/fetch';
import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  name: 'UpdateUser',

  props: {
    user: {
      type: Object,
      required: true
    }
  },

  components: {
    TabGroup
  },

  data() {
    return {
      tabs: [
        {
          active: 'UserGeneral',
          text: 'General',
          visible: true
        },
        {
          active: 'UserAddress',
          text: 'Address',
          visible: true
        },
        {
          active: 'UserRoles',
          text: 'Roles',
          visible: true
        }
      ],

      updatedUser: null,

      roles: [],
      selectedRoles: [],

      isLoading: false
    };
  },

  created() {
    this.getRoles();
  },

  methods: {
    formatRole(role) {
      let formatted = role.replace('ROLE_', '');
      return formatted[0] + formatted.slice(1).toLowerCase();
    },

    getRoles() {
      this.isLoading = true;

      entityService('/roles')
        .then((response) => response.json())
        .then((data) => {
          this.roles = data['hydra:member'].map((role) => {
            return {
              text: this.formatRole(role.name),
              value: role.name
            };
          });
        })
        .finally(() => {
          this.isLoading = false;
          this.selectedRoles = this.user.roles;
        });
    },

    updated() {
      this.$bvModal.hide('editUser');
      SwalAlert({
        title: 'User updated',
        icon: 'user',
        type: 'success',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    },

    handleSubmit(data) {
      this.isLoading = true;
      entityService(this.user['@id'], {
        method: 'PUT',
        headers: new Headers({
          'Content-Type': 'application/ld+json'
        }),
        body: JSON.stringify(data)
      })
        .then((response) => response.json())
        .then((data) => {
          SwalAlert({
            title: 'User updated',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {
                  this.$bvModal.hide('editUser');
                }
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: e,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        })
        .finally((e) => {
          this.isLoading = false;
        });
    }
  }
};
</script>
