<template>
  <b-form @submit.prevent="onSendForm(item)">
    <b-form-group
      label="Name"
      label-for="group_name"
      :invalid-feedback="violations.name"
      :state="!isValid('name')"
    >
      <b-form-input
        v-model="item.name"
        :state="isValid('name')"
        id="group_name"
        type="text"
        required="required"
        placeholder="Enter name..."
      />
    </b-form-group>

    <b-form-row class="float-right">
      <b-col>
        <b-button
          @click="closeModal"
          class="mr-2"
          type="button"
          variant="secondary"
          :disabled="isLoading"
          >Cancel</b-button
        >
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Submit</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapActions } from 'vuex';
import UserSearchInput from '@/components/_ui/users/SearchInput.vue';
import { mapFields } from 'vuex-map-fields';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {};
  },

  created() {},

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      let errors = {};
      if (this.errors) {
        errors = { ...errors, ...this.errors };
      }

      return errors;
    }
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('createGroup');
      this.$bvModal.hide('editGroup');
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    onSendForm(item) {
      // we handle learners directly now, no longer on PUT/POST
      delete item.learners;

      this.handleSubmit(item);
    }
  }
};
</script>
