<template>
  <div>
    <b-container>
      <b-row align-h="end">
        <b-col cols="9">
          <h4 class="text-left text-light mt-4 mb-4">
            <strong>YES!</strong>
          </h4>
          <p class="text-left text-light">
            <strong>
              Thank you for your registration in AmplificA.<br />
              Together we make Life Long Learning possible!
            </strong>
          </p>
          <small class="text-light"
            >Check your e-mail to enter AmplificA<br />
            [first time e-mail often ends in your spam please check
            closely]</small
          >
        </b-col>
      </b-row>
      <b-row align-h="end" class="mt-4">
        <router-link v-if="!loading" :to="{ name: 'Login' }">
          <b-button variant="primary" type="submit"> Back to login</b-button>
        </router-link>
        <b-button variant="primary" type="submit" disabled v-else>
          <b-spinner small></b-spinner>
          <span class="sr-only">Loading...</span>
        </b-button>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'RegisterView',
  props: {},
  data() {
    return {
      loading: false,
      errors: [],
      showErr: false,
      registered: false
    };
  },
  methods: {
    isRegistered(value) {
      this.registered = value;
    }
  }
};
</script>
<style scoped lang="scss"></style>
