<template>
  <div>
    <div v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <b-form>
      <div v-if="error" class="alert alert-danger" role="alert">
        <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
          error
        }}</span>
      </div>

      <table class="table table-striped table-hover">
        <thead>
          <tr>
            <th>Article</th>
            <th width="150">VAT</th>
            <th width="225">Start Date</th>
            <th width="250">End Date</th>
            <th width="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="countryArticle in items" :key="countryArticle['@id']">
            <td>
              <strong>{{ countryArticle.article.type }}</strong>
              {{ countryArticle.article.name }}
            </td>
            <td>{{ countryArticle.vatType }}</td>
            <td>{{ dayjs(countryArticle.startDate).format('DD/MM/YYYY') }}</td>
            <td>
              {{
                countryArticle.endDate
                  ? dayjs(countryArticle.endDate).format('DD/MM/YYYY')
                  : 'N/A'
              }}
            </td>
            <td>
              <b-spinner
                small
                v-if="deleteIsLoading(countryArticle['@id'])"
              ></b-spinner>
              <a
                v-else
                href="#"
                @click.prevent="deleteCountryArticle(countryArticle['@id'])"
                ><i class="fa fa-trash text-info"></i
              ></a>
            </td>
          </tr>
          <tr v-if="availableArticleItems.length === 0">
            <td colspan="5">
              <i>No more articles available</i>
            </td>
          </tr>
          <tr v-else>
            <td>
              <b-form-select
                :disable="articleIsLoading"
                v-model="newArticle.article"
                required
              >
                <b-form-select-option
                  value="0"
                  v-if="articlesIsLoading"
                  disabled
                >
                  <b-spinner small></b-spinner>
                </b-form-select-option>
                <b-form-select-option
                  v-else
                  v-for="article in availableArticleItems"
                  :key="article['@id']"
                  :value="article['@id']"
                >
                  {{ article.type }} - {{ article.name }}
                </b-form-select-option>
              </b-form-select>
            </td>
            <td>
              <b-form-select
                :disable="articleIsLoading"
                v-model="newArticle.vatType"
                required="required"
              >
                <b-form-select-option value="HIGH">HIGH</b-form-select-option>
                <b-form-select-option value="LOW">LOW</b-form-select-option>
                <b-form-select-option value="LOWLOW"
                  >LOWLOW</b-form-select-option
                >
                <b-form-select-option value="EXEMPT"
                  >EXEMPT</b-form-select-option
                >
              </b-form-select>
            </td>
            <td>
              <b-input-group>
                <b-form-input
                  v-model="newArticle.startDate"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="newArticle.startDate"
                    button-only
                    right
                    locale="en-US"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </td>

            <td>
              <b-input-group>
                <b-form-checkbox v-model="formHasEndDate"></b-form-checkbox>
                <b-form-input
                  v-if="formHasEndDate"
                  v-model="newArticle.endDate"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  autocomplete="off"
                ></b-form-input>
                <b-input-group-append>
                  <b-form-datepicker
                    v-if="formHasEndDate"
                    v-model="newArticle.endDate"
                    button-only
                    right
                    locale="en-US"
                  ></b-form-datepicker>
                </b-input-group-append>
              </b-input-group>
            </td>

            <td>
              <b-spinner small v-if="articleIsLoading"></b-spinner>
              <a v-else href="#" @click.prevent="addCountryArticle"
                ><i class="fa fa-plus-circle"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form>
  </div>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import { SwalAlert } from '@/utils/swal';

export default {
  props: {
    countryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      formHasEndDate: false,
      formBackupEndDate: dayjs().format('YYYY-MM-DD'),
      newArticle: {
        country: this.countryId,
        article: '',
        vatType: 'HIGH',
        grossCost: 0,
        startDate: dayjs().format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
      },
      deleting: {}
    };
  },

  created() {
    // Retrieve item data
    this.getPage(`${this.countryId}/country-articles`, {
      params: { itemsPerPage: 100000 }
    });
    // Retrieve articles that require to be set on COUNTRY
    this.getArticleSelectItems({
      params: { itemsPerPage: 9999, vatRateSource: 'COUNTRY' }
    });
  },

  computed: {
    ...mapFields('countryarticle/list', {
      error: 'error',
      isLoading: 'isLoading',
      items: 'items'
    }),

    ...mapFields('article/list', {
      articlesError: 'error',
      articlesItems: 'selectItems',
      articlesIsLoading: 'isLoading'
    }),

    ...mapFields('countryarticle/del', {
      articleDeleteError: 'error',
      articleDeleteLoading: 'isLoading',
      articleDeleted: 'deleted'
    }),

    ...mapFields('countryarticle/create', {
      articleError: 'error',
      articleIsLoading: 'isLoading',
      articleCreated: 'created',
      articleViolations: 'violations'
    }),

    /**
     * Which articles are not in use already?
     */
    availableArticleItems() {
      if (!this.articlesItems) return [];
      let currentIds = this.items.map((item) => item.article['@id']);
      return this.articlesItems.filter(
        (article) => !currentIds.includes(article['@id'])
      );
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    articleCreated: function (created) {
      // Retrieve item data
      this.refreshData();
    },
    articleDeleted: function (deleted) {
      // Retrieve item data
      this.refreshData();
    },
    /**
     * Keep the end date or put it null for PUT
     * @param formHasEndDate
     */
    formHasEndDate: function (formHasEndDate) {
      if (!formHasEndDate) {
        this.formBackupEndDate = this.newArticle.endDate;
        this.newArticle.endDate = null;
      } else {
        this.newArticle.endDate = this.formBackupEndDate;
      }
    }
  },

  methods: {
    dayjs,
    ...mapActions({
      getPage: 'countryarticle/list/default',

      getArticleSelectItems: 'article/list/getSelectItems',

      articleCreate: 'countryarticle/create/create',
      articleReset: 'countryarticle/create/reset',

      articleDelete: 'countryarticle/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(`${this.countryId}/country-articles`, {
        params: { itemsPerPage: 100000 }
      });
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    /**
     * Fill in the Country Article article form
     */
    addCountryArticle() {
      // Send
      // Make sure we clean end date
      if (!this.formHasEndDate) {
        this.newArticle.endDate = null;
      }
      this.articleCreate(this.newArticle);
    },

    /**
     * Reset the form
     */
    resetProtocolRuleForm() {},

    deleteCountryArticle(countryArticleId) {
      SwalAlert({
        title: 'Article Delete',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'warning',
        buttons: {
          cancel: {
            label: 'No',
            type: 'success',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              this.deleting[countryArticleId] = true;
              this.articleDelete({ '@id': countryArticleId });
            }
          }
        }
      });
    },

    deleteIsLoading(countryArticleId) {
      return this.deleting.hasOwnProperty(countryArticleId);
    }
  }
};
</script>
