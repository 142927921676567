import { communicationService as fetch } from '../../../utils/fetch';
import * as types from './mutation_types';
import { CHAT_ON_NEW_ROOM, CHAT_ON_NEW_MESSAGE } from './mutations';

export const CHAT_FETCH_ROOM = 'CHAT_FETCH_ROOM';
export const CHAT_FETCH_ROOM_BY_CONTEXT = 'CHAT_FETCH_ROOM_BY_CONTEXT';
export const CHAT_FETCH_ROOMS = 'CHAT_FETCH_ROOMS';
export const CHAT_FETCH_MESSAGES = 'CHAT_FETCH_MESSAGES';
export const CHAT_SELECT = 'CHAT_SELECT';
export const CHAT_MARK_READ = 'CHAT_MARK_READ';
export const CHAT_SEND = 'CHAT_SEND';
export const CHAT_PARSE_MESSAGE = 'CHAT_PARSE_MESSAGE';

export const actions = {
  [CHAT_SELECT]: ({ commit, dispatch }, payload) => {
    commit(CHAT_SELECT, payload['@id']);
    // Mark as read!
    dispatch(CHAT_MARK_READ, payload);
  },
  [CHAT_MARK_READ]: ({ commit, dispatch }, payload) => {
    // Mark as read!
    commit(CHAT_MARK_READ, payload['@id']);
    // send to server
    if (payload['@id'] !== 'notifications') {
      // fetch(`${payload['@id']}/signal/read`, {
      //   method: 'POST',
      //   body: JSON.stringify({})
      // });
    }
  },
  [CHAT_SEND]: ({ commit }, payload) => {},
  [CHAT_PARSE_MESSAGE]: ({ commit }, payload) => {
    // We have room?
    commit(CHAT_ON_NEW_MESSAGE, payload);
  },
  [CHAT_FETCH_ROOMS]: ({ commit }, page = 'rooms') => {
    commit(types.ROOM_TOGGLE_LOADING);

    fetch(page)
      .then((response) => response.json())
      .then((data) => {
        commit(types.ROOM_TOGGLE_LOADING);
        // Parse it
        data['hydra:member'].forEach((c) => {
          commit(CHAT_ON_NEW_ROOM, c);
        });
      })
      .catch((e) => {
        commit(types.ROOM_TOGGLE_LOADING);
        commit(types.ROOM_SET_ERROR, e.message);
      });
  },
  [CHAT_FETCH_ROOM_BY_CONTEXT]: ({ commit }, context) => {
    commit(types.ROOM_TOGGLE_LOADING);

    fetch(context)
      .then((response) => response.json())
      .then((data) => {
        // Parse it
        commit(CHAT_ON_NEW_ROOM, data);
      })
      .catch((e) => {});
  },
  [CHAT_FETCH_ROOM]: ({ commit }, roomId) => {
    commit(types.ROOM_TOGGLE_LOADING);

    fetch(roomId)
      .then((response) => response.json())
      .then((data) => {
        // Parse it
        commit(CHAT_ON_NEW_ROOM, data);
      })
      .catch((e) => {});
  },
  [CHAT_FETCH_MESSAGES]: ({ commit, dispatch }, roomIri) => {
    commit(types.MESSAGE_TOGGLE_LOADING);

    fetch(`${roomIri}/messages`)
      .then((response) => response.json())
      .then((data) => {
        commit(types.MESSAGE_TOGGLE_LOADING);
        // Parse it
        data['hydra:member'].forEach((m) => {
          dispatch(CHAT_PARSE_MESSAGE, m);
        });
        // commit(types.MESSAGE_SET_ITEMS, data['hydra:member']);
      })
      .catch((e) => {
        commit(types.MESSAGE_TOGGLE_LOADING);
        //commit(types.MESSAGE_SET_ERROR, e.message);
      });
  }
};
