<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <TemplateForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import TemplateForm from './Form.vue';
import { SwalAlert } from '../../../utils/swal';
import { DOC_SERVICE_ENTRYPOINT } from '@/config/entrypoint';

export default {
  components: {
    TemplateForm
  },

  props: {
    template: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapFields('template/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    }),
    ...mapGetters('auth', ['getAccessToken'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated', updated);
      this.$bvModal.hide('templateUpdate');
      SwalAlert({
        title: 'Template updated',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('templateUpdate');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.template['@id']);
  },

  methods: {
    ...mapActions({
      retrieve: 'template/update/retrieve',
      updateReset: 'template/update/reset',
      update: 'template/update/update',
      updateRetrieved: 'template/update/updateRetrieved'
    }),

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm(item) {
      let formData = new FormData();
      formData.append('file', item.file);

      const headers = new Headers({
        Authorization: `Bearer ${this.getAccessToken}`
      });

      fetch(`${DOC_SERVICE_ENTRYPOINT}/files/documents`, {
        method: 'POST',
        body: formData,
        headers
      })
        .then((response) => response.json())
        .then((data) => {
          // Send just the file ID on request body (PUT Method)
          item.file = data['@id'];
          this.update(item);
        })
        .catch((error) => {
          this.errors = error;
        });
    }
  }
};
</script>
