<template>
  <b-form @submit.prevent="onFormSend(item)">
    <b-form-group label="Company Name" label-for="company">
      <b-form-input id="company" :value="userCompany" type="text" readonly>
      </b-form-input>
    </b-form-group>
    <b-form-group
      label="Holder Name"
      label-for="holderName"
      :invalid-feedback="violations.holderName"
      :state="!isValid('holderName')"
    >
      <b-form-input
        id="holderName"
        v-model="item.holderName"
        type="text"
        required
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Iban"
      label-for="IBAN"
      :invalid-feedback="violations.IBAN"
      :state="!isValid('IBAN')"
    >
      <b-form-input id="IBAN" v-model="item.IBAN" type="text" required>
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Swift"
      label-for="SWIFT"
      :invalid-feedback="violations.SWIFT"
      :state="!isValid('SWIFT')"
    >
      <b-form-input id="SWIFT" v-model="item.SWIFT" type="text" maxlength="11">
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Default"
      label-for="isDefault"
      :invalid-feedback="violations.isDefault"
      :state="!isValid('isDefault')"
    >
      <b-form-checkbox
        id="isDefault"
        v-model="item.isDefault"
        name="isDefault"
        :value="true"
        :unchecked-value="false"
      >
      </b-form-checkbox>
    </b-form-group>

    <b-form-group
      label="Third Party Acccount"
      label-for="isThirdPartyAccount"
      :invalid-feedback="violations.isThirdPartyAccount"
      :state="!isValid('isThirdPartyAccount')"
      v-if="isRole('ROLE_BAILIFF')"
    >
      <b-form-checkbox
        id="isThirdPartyAccount"
        v-model="item.isThirdPartyAccount"
        name="isThirdPartyAccount"
        :value="true"
        :unchecked-value="false"
      >
      </b-form-checkbox>
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  mounted() {
    this.companyGetSelectItems();
  },

  computed: {
    ...mapGetters('auth', ['getCompany', 'getCompanyId', 'isRole']),

    ...mapFields('company/list', {
      companySelectItems: 'selectItems'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    userCompany() {
      return this.getCompany.entity.company.name;
    }
  },

  methods: {
    ...mapActions({
      companyGetSelectItems: 'company/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    onFormSend(item) {
      item.company = this.getCompanyId;
      this.handleSubmit(item);
    },
    closeModal() {
      this.$bvModal.hide('createFinancialInformation');
      this.$bvModal.hide('updateFinancialInformation');
    }
  }
};
</script>
