<template>
  <div>
    <b-form-row v-if="moduleIsLoading">
      <b-spinner></b-spinner>
    </b-form-row>
    <div v-else>
      <b-form-row
        v-for="(module, moduleIdx) in moduleItems"
        :key="module['@id']"
        class="bgGroup p-4 mb-2 font-weight-bolder"
      >
        <b-col cols="12">
          <h2 class="page-title">
            <b-icon icon="calendar2" class="mr-2" variant="primary"></b-icon>
            <strong>Module {{ moduleIdx + 1 }}:</strong> {{ module.name }}
          </h2>
          <div
            v-for="(learningActivity, activityIdx) in module.activities"
            :key="learningActivity['@id']"
          >
            <h4 class="page-title mt-3 mb-3">
              <b-icon icon="calendar3" class="mr-2" variant="primary"></b-icon>
              <strong>Activity {{ activityIdx + 1 }}:</strong>
              {{ learningActivity.title }}

              <b-icon
                icon="person-badge"
                class="ml-3 mr-2"
                variant="primary"
              ></b-icon>
              <strong>Teacher:</strong>&nbsp;
              <a
                v-if="!learningActivity.teacher"
                @click.prevent="editTeacher(learningActivity)"
                href="#"
                ><i>Click to add teacher <b-icon icon="pencil"></b-icon> </i
              ></a>
              <span v-else>
                {{ learningActivity.teacher.user.firstName }}
                {{ learningActivity.teacher.user.lastName }}
                <a @click.prevent="editTeacher(learningActivity)" href="#"
                  ><b-icon icon="pencil"></b-icon
                ></a>
              </span>
            </h4>

            <Activity
              page="SCHEDULE"
              :groups="groups"
              :activity="learningActivity"
              @editSchedule="editSchedule"
            ></Activity>
          </div>
        </b-col>
        <!--        <b-col cols='3'>-->
        <!--          <b-icon v-if="isScheduled(item['@id'])" icon="check-circle-fill" variant="success" ></b-icon>-->
        <!--          <b-icon v-else icon="check-circle-fill" variant="warning"></b-icon>-->
        <!--          <span class="ml-2">Group {{ idx + 1}}: {{ item.name }}</span>-->
        <!--        </b-col>-->
        <!--        <b-col cols='4'>Learners in group: {{ item.learners.length }}</b-col>-->
        <!--        <b-col cols='5' class="text-right">-->
        <!--          <b-spinner v-if="deleteIsLoading(item)" small></b-spinner>-->
        <!--          <span v-else-if="!readOnly">-->
        <!--        <b-button @click="edit(item)" variant='success' class="mr-2">Edit Group</b-button>-->
        <!--        <b-button @click="del(item)" variant='danger'>-->
        <!--          <span class="fa fa-trash text-info"></span>-->
        <!--        </b-button>-->
        <!--        </span>-->

        <!--        </b-col>-->
      </b-form-row>
    </div>

    <!-- Add Teacher modal -->
    <b-modal id="createTeacher" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title"
            >Manage Teacher For {{ teacherActivity.title }}</span
          >
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createTeacher')"
        ></i>
      </template>

      <TeacherCreate
        :activity="teacherActivity"
        @created="onAddTeacher"
      ></TeacherCreate>
    </b-modal>

    <!-- Schedule modal -->
    <b-modal id="editSchedule" title="Schedule" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Schedule</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editSchedule')"
        ></i>
      </template>
      <ScheduleGroup
        :activity="schedule.activity"
        :group="schedule.group"
        :schedule="schedule.schedule"
        @created="onScheduleAdded"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import Activity from './Activity.vue';
import TeacherCreate from '../../teacher/Create.vue';
import ScheduleGroup from '../groups/Schedule.vue';

export default {
  components: {
    Activity,
    TeacherCreate,
    ScheduleGroup
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      teacherActivity: null,
      schedule: {
        activity: null,
        group: null,
        schedule: null
      }
    };
  },

  computed: {
    ...mapFields('learningmodule/list', {
      moduleError: 'error',
      moduleItems: 'items',
      moduleIsLoading: 'isLoading'
    }),

    getTeachersAmount() {
      if (!this.moduleItems) {
        return 0;
      }

      let buf = 0;

      for (let m in this.moduleItems) {
        if (this.moduleItems[m].activities) {
          for (let a in this.moduleItems[m].activities) {
            if (this.moduleItems[m].activities[a].teacher) {
              buf++;
            }
          }
        }
      }

      return buf;
    },

    getActivitiesAmount() {
      if (!this.moduleItems) {
        return 0;
      }
      let buf = 0;

      for (let m in this.moduleItems) {
        buf += this.moduleItems[m].activities
          ? this.moduleItems[m].activities.length
          : 0;
      }

      return buf;
    }
  },

  created() {
    // Load dependencies
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getModules: 'learningmodule/list/default'
    }),

    onAddTeacher(activity) {
      this.refreshData();
    },

    editTeacher(activity) {
      this.teacherActivity = activity;
      this.$bvModal.show('createTeacher');
    },

    refreshData() {
      this.getModules(`${this.program['@id']}/modules`);
    },

    editSchedule(activity, group, schedule) {
      this.schedule.activity = activity;
      this.schedule.group = group;
      this.schedule.schedule = schedule;
      this.$bvModal.show('editSchedule');
    },

    onScheduleAdded(schedule) {
      this.refreshData();
    }
  }
};
</script>
<style scoped lang="scss">
//.page-title {
//  display: block !important;
//}
</style>
