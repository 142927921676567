<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <LearningModuleForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
      :program-id="programId"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import LearningModuleForm from './Form.vue';

export default {
  components: {
    LearningModuleForm
  },

  props: {
    programId: {
      type: String,
      required: true
    },

    learningModuleId: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapFields('learningmodule/create', {
      created: 'created'
    }),
    ...mapFields('learningmodule/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated', updated);
      this.$bvModal.hide('editLearningModule');
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.learningModuleId);
  },

  methods: {
    ...mapActions({
      createReset: 'learningmodule/create/reset',
      retrieve: 'learningmodule/update/retrieve',
      updateReset: 'learningmodule/update/reset',
      update: 'learningmodule/update/update',
      updateRetrieved: 'learningmodule/update/updateRetrieved'
    }),

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm(item) {
      // hack... change this for better (it is required on backend but it does not exists)

      if (!item.maxLearners) {
        delete item['maxLearners'];
      }

      if (!item.minLearners) {
        delete item['minLearners'];
      }

      // end hack

      this.update(item);
    }
  }
};
</script>
