import ProposalList from '@/components/dashboard/program/proposal/List.vue';
import ProposalCreate from '@/components/dashboard/program/proposal/Create.vue';
import ProposalUpdate from '@/components/dashboard/program/proposal/Update.vue';
import LearningModulesList from '@/components/dashboard/program/learningmodule/List.vue';

import Default from '@/views/dashboard/Programs/Default';

export default [
  {
    path: '/programs/proposals',
    component: Default,
    children: [
      {
        name: 'ProposalList',
        path: '/programs/proposals/',
        component: ProposalList
      },
      {
        name: 'ProposalCreate',
        path: '/programs/proposals/create',
        component: ProposalCreate
      },
      {
        name: 'ProposalUpdate',
        path: '/programs/proposals/:id/edit',
        component: ProposalUpdate
      },
      {
        name: 'ProposalLearningModules',
        path: '/programs/proposals/:id/learning-modules',
        component: LearningModulesList
      }
    ]
  }
];
