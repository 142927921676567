<template>
  <b-form @submit.prevent="onSendForm">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Title" label-for="title">
          <b-form-input id="title" v-model="item.title" type="text" required>
          </b-form-input>
        </b-form-group>

        <b-form-group label="Terms" label-for="terms">
          <wysiwyg v-model="item.terms" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    isLoading: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),

    item() {
      return this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createTermsAndConditions');
    },

    onSendForm() {
      const body = {
        user: this.getUser.id,
        ...this.item
      };

      this.handleSubmit(body);
    }
  }
};
</script>
