<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="learningmaterial"
      @changePage="changePage"
    >
      <template v-slot:actions="data">
        <a
          href="#"
          class="mr-2"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
          ><i class="fa fa-edit text-info"></i
        ></a>
        <a
          href="#"
          v-if="
            data.item.protocolRules.length === 0 &&
            data.item.articleChildrenSplits.length === 0
          "
          @click.prevent="del(data.item)"
          ><i class="fa fa-trash text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button
          @click="$bvModal.show('createLearningMaterial')"
          variant="primary"
        >
          <i class="fas fa-plus"></i>
          Add Learning Material
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      id="createLearningMaterial"
      title="Create LearningMaterial"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title"
            >Create Learning Material</span
          >
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createLearningMaterial')"
        ></i>
      </template>

      <LearningMaterialCreate @created="refreshData"></LearningMaterialCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import LearningMaterialCreate from './Create';

export default {
  components: {
    CustomTable,
    LearningMaterialCreate
  },

  data() {
    return {
      fields: [
        { key: 'title', label: 'Title', sortable: true },
        { key: 'isbn', label: 'ISBN', sortable: true },
        { key: 'url', label: 'URL', sortable: true },
        { key: 'author', label: 'Author', sortable: true },
        { key: 'filepath', label: 'Filepath', sortable: true },
        { key: 'language.name', label: 'Language', sortable: true }
      ],
      sort: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapFields('learningmaterial/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/learning-materials?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'learningmaterial/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    }
  }
};
</script>
