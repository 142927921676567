<template>
  <div>
    <CompanyCreate
      @created="onCompanyForm"
      :parentId="company['@id']"
      :is-creating-branch="true"
      :skip-company-admin="true"
      :skip-company-roles="true"
      :skipLastStep="true"
      :showPrevious="false"
      :availableCompanyTypes="availableCompanyTypes"
      :return-if-exists="true"
    />

    <b-form @submit.prevent="onSendForm(item)" v-if="showAddBranch">
      <b-form-group class="float-right">
        <b-button
          type="button"
          class="mr-2"
          variant="secondary"
          @click="closeModal"
          v-if="!isLoading"
          >Close</b-button
        >
        <b-button type="submit" variant="primary" disabled v-if="isLoading">
          <b-spinner small></b-spinner>
        </b-button>
        <b-button type="submit" variant="primary" v-else>Add Branch</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import CompanyCreate from '@/components/dashboard/company/Create.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    CompanyCreate
  },

  props: {
    company: {
      type: Object,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('auth', ['getCompany'])
  },

  data() {
    return {
      item: {},
      showAddBranch: false,
      availableCompanyTypes: ['OTHER', 'FISCAL_UNITY']
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide('createCompany');
    },

    onCompanyForm(company, retrieved = false) {
      this.item = company;
      this.item.parent = this.getCompany.entity.company['@id'];

      if (!retrieved) {
        this.handleSubmit(this.item);
      } else {
        this.showAddBranch = true;
      }
    },

    onSendForm(item) {
      item.parent = this.getCompany.entity.company['@id'];
      this.handleSubmit(item);
    }
  }
};
</script>
