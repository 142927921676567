<template>
  <div>
    <IncomingForm :isDisabled="true" :initial-values="payment" />
  </div>
</template>

<script>
import IncomingForm from './Form';

export default {
  components: {
    IncomingForm
  },

  props: {
    payment: {
      type: Object,
      required: true
    }
  }
};
</script>
