import { entityService as fetch } from '../../../../../utils/fetch';
import * as types from './mutation_types';

const getItems = ({ commit }, page) => {
  commit(types.UBOS_LIST_TOGGLE_LOADING);

  fetch(page)
    .then((response) => response.json())
    .then((data) => {
      commit(types.UBOS_LIST_TOGGLE_LOADING);
      commit(types.UBOS_LIST_SET_ITEMS, data['hydra:member']);
      commit(types.UBOS_LIST_SET_VIEW, data['hydra:view']);
      commit(types.UBOS_LIST_SET_TOTAL_ITEMS, data['hydra:totalItems']);
    })
    .catch((e) => {
      commit(types.UBOS_LIST_TOGGLE_LOADING);
      commit(types.UBOS_LIST_SET_ERROR, e.message);
    });
};

export default getItems;
