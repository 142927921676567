<template>
  <div>
    <Welcome
      v-if="!finishWelcome"
      :is-loading="userIsLoading"
      :handle-submit="handleSubmit"
      :values="item"
      :errors="userError"
    />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import Welcome from './Welcome.vue';
import { entityService, entityService as fetch } from '../../utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    Welcome
  },

  data() {
    return {
      item: {},
      finishWelcome: false,
      finishChooseRole: false,
      finishUserTerms: false,
      finishCompanyInformation: false
    };
  },

  computed: {
    ...mapFields('user/create', {
      userError: 'error',
      userIsLoading: 'isLoading',
      userCreated: 'created',
      userViolations: 'violations'
    })
  },

  watch: {
    userCreated: function (created) {
      if (!created) {
        this.$bvModal.hide('register');
        return;
      }
      SwalAlert({
        title: 'Welcome to AmplificA',
        icon: 'user',
        type: 'success',
        html: 'Thank you for your registration in AmplificA.<br /> Together we make Life Long Learning possible!<br><br>Check your e-mail to enter AmplificA',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      })
        .then(() => {
          this.$bvModal.hide('register');
        })
        .catch((e) => {
          // show error
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description:
              'Something went wrong with your request, try again later',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        });
    }
  },

  methods: {
    ...mapActions({
      userCreate: 'user/create/create'
    }),

    handleSubmit(item, allowRegistration) {
      //If registration is closed but user selects a role different of "Creator", we register the user
      if (!allowRegistration && (item.role === 'Creator' || !item.role)) {
        this.welcomeSubmit(item);
      } else {
        const payload = {
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName
        };

        this.userCreate(payload);
      }
    },

    welcomeSubmit(item) {
      this.userIsLoading = true;
      const payload = new FormData();
      payload.set('email', item.email);
      payload.set('firstName', item.firstName);
      payload.set('lastName', item.lastName);
      payload.set('fiscalId', item.fiscalId ? item.fiscalId : 0);
      payload.set('company', item.company);

      entityService('/onboarding/register', { method: 'POST', body: payload })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.userIsLoading = false;
          // Close modal
          this.$bvModal.hide('register');

          const description = data
            ? 'Check your e-mail to enter AmplificA'
            : "You've been added to the queue list.<br>We will e-mail you when the platform is open!";

          let title = 'Welcome to AmplificA';
          let html =
            'Thank you for your registration in AmplificA.<br /> Together we make Life Long Learning possible!<br><br>' +
            description;

          if (item.role === 'Creator' && !this.allowRegistration) {
            title = 'Thank you for signing up to AmplificA';
            html =
              'We will invite you by e-mail when we are ready to register your company as Creator+';
          }

          // if we sent fiscalId, user is likely registered (check your mail)
          SwalAlert({
            title,
            html,
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          }).then(() => {
            this.$bvModal.hide('register');
          });
        })
        .catch((e) => {
          // show error
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description:
              'Something went wrong with your request, try again later',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        });
    }
  }
};
</script>
