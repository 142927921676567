<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div class="mt-4" v-if="reserve">
      <h3 class="font-weight-bold text-primary">
        Balance: {{ formatCurrency(reserve.balance) }}
      </h3>
    </div>

    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/relations@2x.png"
      />
      <h3 class="font-weight-bold mt-2">General Reserves</h3>
    </div>

    <CustomTable
      :fields="fields"
      list="company/transactions"
      @changePage="changePage"
    >
      <template v-slot:isDefault="data">
        <Status class="text-center" :value="data.item.isDefault" />
      </template>

      <template slot="amount.formatted" slot-scope="data">
        <span :class="getClass(data.item)"
          >{{ data.item.payment.type === 'DEPOSIT' ? '+' : '-' }}
          {{ formatCurrency(data.item.amount.value) }}</span
        >
      </template>
    </CustomTable>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';
import { entityService } from '@/utils/fetch';

export default {
  components: {
    CustomTable
  },

  mixins: [helpers],
  computed: {
    ...mapGetters('auth', ['getCompanyId']),

    ...mapFields('company/transactions/list', {
      error: 'error',
      items: 'items',
      isLoading: 'isLoading',
      view: 'view',
      totalItems: 'totalItems'
    }),

    request() {
      let request = 'transactions?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&reserve=${this.reserve['@id']}`;
      return request;
    }
  },

  data() {
    return {
      fields: [
        {
          key: 'payment.date',
          label: 'Transaction Date',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatDate(item.payment.date);
          }
        },
        {
          key: 'amount.formatted',
          label: 'Amount',
          sortable: true
        },
        {
          key: 'payment.description',
          label: 'Description',
          sortable: true
        }
      ],
      reserve: [],
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.getReverses();
  },

  methods: {
    ...mapActions({
      getPage: 'company/transactions/list/default'
    }),

    async getReverses() {
      const response = await entityService(
        `${this.getCompanyId}/reserves?type=General%20Reserve`
      );

      const data = await response.json();

      this.reserve = data['hydra:member'][0];

      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    getClass(item) {
      return item.payment.type === 'DEPOSIT' ? 'text-success' : 'text-danger';
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    }
  }
};
</script>
