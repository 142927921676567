<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <UpdateForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
      :initial-values="retrieved"
      :canEdit="canEdit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import UpdateForm from './UpdateForm.vue';
import { SwalAlert } from '@/utils/swal';
import helpers from '@/mixins/helpers';

export default {
  components: {
    UpdateForm
  },

  mixins: [helpers],

  props: {
    item: {
      type: Object,
      required: true
    },
    canEdit: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapFields('relationship/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated', updated);
      this.$bvModal.hide('editImport');

      SwalAlert({
        title: 'Import updated',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('editImport');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.retrieve(this.item['@id']);
  },

  methods: {
    ...mapActions({
      retrieve: 'relationship/update/retrieve',
      updateReset: 'relationship/update/reset',
      update: 'relationship/update/update',
      updateRetrieved: 'relationship/update/updateRetrieved'
    }),

    reset() {
      this.updateReset();
    },

    onSendForm() {
      this.update(this.cleanPatchPayload(this.retrieved));
    }
  }
};
</script>
