<template>
  <div class="aprova-report__form">
    <div class="aprova-report__form__items">
      <h1 class="aprova-report__form__subtitle">Vat Group Calculations</h1>
    </div>

    <CustomTable :fields="fields" :values="item"> </CustomTable>
  </div>
</template>

<script>
import CustomTable from '@/components/_ui/tables/CustomTable';

export default {
  name: 'VatGroupCalculations',

  components: {
    CustomTable
  },

  props: {
    aprovaReport: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    item() {
      return this.aprovaReport?.vatGroupCalculations || [];
    }
  },

  data() {
    return {
      fields: [
        { key: 'year', label: 'Year' },
        { key: 'PIFFraud', label: 'PIF Fraud' },
        { key: 'stateAid', label: 'State Aid' }
      ]
    };
  }
};
</script>
