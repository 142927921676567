<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(room, roomIdx) in getRooms"
        :key="room['@id']"
        class="d-flex align-items-center contacts-list-item"
        :class="{
          active: getSelectedRoomId == room['@id'],
          'flex-row-reverse': isMenuOpen
        }"
        @click="onSelectRoom(room)"
      >
        <b-avatar
          v-if="roomIdx === 'notifications'"
          :class="{
            'mr-3': !isMenuOpen,
            'ml-4': isMenuOpen
          }"
          variant="primary"
          v-b-tooltip="{
            title: namedChat(room.name || room.context),
            placement: 'topright',
            boundary: 'viewport',
            disabled: !isMenuOpen
          }"
        >
          <i class="fa fa-cogs"></i>
        </b-avatar>

        <b-avatar
          v-else
          :class="{
            'mr-3': !isMenuOpen,
            'ml-4': isMenuOpen
          }"
          v-b-tooltip="{
            title: namedChat(room.name || room.context),
            boundary: 'viewport',
            placement: 'topright',
            disabled: !isMenuOpen
          }"
        ></b-avatar>

        <span class="d-flex flex-column contacts-content">
          <span class="font-weight-bold">
            {{ namedChat(room.name || room.context) }}
          </span>
          {{ 'Always yours' }}
          <!-- @TODO: Add user last seen for chat status -->
        </span>
        <b-badge v-if="room.newMessages" style="position: absolute; right: 5px">
          {{ room.newMessages }}
        </b-badge>
      </b-list-group-item>
    </b-list-group>

    <b-modal id="createRoom" hide-footer title="New Room">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">New Room</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createRoom')"
        ></i>
      </template>

      <RoomCreate @created="onCreateRoom"></RoomCreate>
    </b-modal>
  </div>
</template>
<script>
import RoomCreate from './Create.vue';
import { mapActions, mapGetters } from 'vuex';
import {
  CHAT_FETCH_ROOMS,
  CHAT_SELECT
} from '../../../store/modules/chat/actions';
import helpers from '@/mixins/helpers';

export default {
  created() {},
  components: {
    RoomCreate
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    isMenuOpen: {
      type: Boolean,
      required: true
    }
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getUser', 'getCompanyId']),
    ...mapGetters('chat', ['getSelectedRoomId', 'getRooms']),
    handshakes() {
      return this.$store.state.handshake.list.items;
    }
  },

  data() {
    return {
      isLoadingContext: false
    };
  },

  mounted() {
    this.getHandshakes(`${this.getCompanyId}/handshakes`);
  },
  methods: {
    ...mapActions({
      getHandshakes: `handshake/list/default`
    }),

    ...mapActions('chat', [CHAT_SELECT, CHAT_FETCH_ROOMS]),
    onCreateRoom(room) {
      this[CHAT_FETCH_ROOMS]();
    },
    onSelectRoom(room) {
      this[CHAT_SELECT](room);
    },
    namedChat(name) {
      return name.includes('/handshakes/')
        ? 'Handshakes #' + name.replace('/handshakes/', '')
        : name;
    }
  }
};
</script>

<style lang="scss">
.chat-modal {
  z-index: 9999;
}
.list-group {
  border-color: transparent;
}
.list-group-item {
  color: black;
  background-color: transparent;
  border-bottom-color: 1px solid white;
  border: 1px;
  line-height: normal;

  &.active {
    background-color: $secondary;
    color: white;
  }
}

.contacts-list-item {
  border: none;
  cursor: pointer;
  border-bottom: 1px solid $gray-200;
  border-radius: 0;
}
</style>
