<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <EstablishmentForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import EstablishmentForm from './Form';

const { mapFields } = createHelpers({
  getterType: 'establishment/create/getField',
  mutationType: 'establishment/create/updateField'
});

export default {
  components: {
    EstablishmentForm
  },
  props: {
    companyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      item: {
        address: {}
      }
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$bvModal.hide('createEstablishment');
      this.$emit('created', created);
    }
  },

  methods: {
    ...mapActions('establishment/create', ['create', 'reset']),

    onSendForm(item) {
      item.company = this.companyId;
      this.create(item);
    }
  },

  beforeDestroy() {
    this.reset();
  }
};
</script>

<style scoped>
.title-position {
  position: relative;
  top: -40px;
}
</style>
