var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-container',[(_vm.error)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_c('span',{staticClass:"fa fa-exclamation-triangle",attrs:{"aria-hidden":"true"}},[_vm._v(_vm._s(_vm.error))])]):_vm._e(),_c('b-row',[_c('b-col',[_c('h1',[_vm._v("Correcting Status")]),_c('b-form-group',{attrs:{"label":"Date","label-for":"date"}},[_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(_vm.correcting.date),callback:function ($$v) {_vm.$set(_vm.correcting, "date", $$v)},expression:"correcting.date"}})],1),_c('b-form-group',{attrs:{"label":"Current State"}},[_c('div',{staticClass:"correct"},[_c('b-form-radio',{staticClass:"trigger-bullet",class:{ 'trigger-bullet-red': _vm.correcting.colorTo === 'RED' },attrs:{"disabled":"","name":"correct","value":"RED","size":"lg"},model:{value:(_vm.correcting.colorTo),callback:function ($$v) {_vm.$set(_vm.correcting, "colorTo", $$v)},expression:"correcting.colorTo"}}),_c('b-form-radio',{staticClass:"trigger-bullet",class:{
                'trigger-bullet-orange': _vm.correcting.colorTo === 'ORANGE'
              },attrs:{"disabled":"","name":"correct","value":"ORANGE","size":"lg"},model:{value:(_vm.correcting.colorTo),callback:function ($$v) {_vm.$set(_vm.correcting, "colorTo", $$v)},expression:"correcting.colorTo"}}),_c('b-form-radio',{staticClass:"trigger-bullet",class:{
                'trigger-bullet-green': _vm.correcting.colorTo === 'GREEN'
              },attrs:{"disabled":"","name":"correct","value":"GREEN","size":"lg"},model:{value:(_vm.correcting.colorTo),callback:function ($$v) {_vm.$set(_vm.correcting, "colorTo", $$v)},expression:"correcting.colorTo"}})],1)]),_c('b-form-group',{attrs:{"label":"Reason","label-for":"reason"}},[_c('b-form-select',{attrs:{"disabled":""}},[_c('b-form-select-option',[_vm._v(_vm._s(_vm.correcting.reason))])],1)],1),_c('b-form-group',{attrs:{"label":"Addicional remark(s)","label-for":"remarks"}},[_vm._v(" "+_vm._s(_vm.correcting.note ? _vm.correcting.note : 'N/A')+" ")])],1),_c('b-col',[_c('h1',[_vm._v("New Status")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSendForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Date","label-for":"date"}},[_c('b-form-input',{attrs:{"type":"date"},model:{value:(_vm.item.date),callback:function ($$v) {_vm.$set(_vm.item, "date", $$v)},expression:"item.date"}})],1),_c('b-form-group',{attrs:{"label":"Correction"}},[_c('div',{staticClass:"correct"},[_c('b-form-radio',{staticClass:"trigger-bullet",class:{ 'trigger-bullet-red': _vm.item.colorTo === 'RED' },attrs:{"name":"correct","value":"RED","size":"lg"},model:{value:(_vm.item.colorTo),callback:function ($$v) {_vm.$set(_vm.item, "colorTo", $$v)},expression:"item.colorTo"}}),_c('b-form-radio',{staticClass:"trigger-bullet",class:{
                  'trigger-bullet-orange': _vm.item.colorTo === 'ORANGE'
                },attrs:{"name":"correct","value":"ORANGE","size":"lg"},model:{value:(_vm.item.colorTo),callback:function ($$v) {_vm.$set(_vm.item, "colorTo", $$v)},expression:"item.colorTo"}}),_c('b-form-radio',{staticClass:"trigger-bullet",class:{ 'trigger-bullet-green': _vm.item.colorTo === 'GREEN' },attrs:{"name":"correct","value":"GREEN","size":"lg"},model:{value:(_vm.item.colorTo),callback:function ($$v) {_vm.$set(_vm.item, "colorTo", $$v)},expression:"item.colorTo"}})],1)]),_c('b-form-group',{attrs:{"label":"Reason","label-for":"reason"}},[_c('b-form-select',{model:{value:(_vm.item.reason),callback:function ($$v) {_vm.$set(_vm.item, "reason", $$v)},expression:"item.reason"}},[_c('b-select-option',{attrs:{"value":"MANUAL_RETURN_RII_STATUS","selected":""}},[_vm._v("MANUAL OVERRULE")]),_c('b-select-option',{attrs:{"value":"MANUAL_CREATOR_MORGAN_STANLEY"}},[_vm._v("MANUAL_CREATOR_MORGAN_STANLEY")]),_c('b-select-option',{attrs:{"value":"MANUAL_CREATOR_KNEW_SITUATION"}},[_vm._v("MANUAL_CREATOR_KNEW_SITUATION")]),_c('b-select-option',{attrs:{"value":"MANUAL_CREATOR_IS_SUPERSPREADER"}},[_vm._v("MANUAL_CREATOR_IS_SUPERSPREADER")])],1)],1),_c('b-form-group',{attrs:{"label":"Addicional remark(s)","label-for":"remarks"}},[_c('b-form-textarea',{attrs:{"id":"textarea","placeholder":"Enter reason...","rows":"3","max-rows":"6"},model:{value:(_vm.item.note),callback:function ($$v) {_vm.$set(_vm.item, "note", $$v)},expression:"item.note"}})],1),_c('b-form-group',{staticClass:"float-right"},[(!_vm.isLoading)?_c('b-button',{staticClass:"mr-2",attrs:{"type":"button","variant":"secondary"},on:{"click":_vm.closeModal}},[_vm._v("Cancel ")]):_vm._e(),(!_vm.isLoading)?_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_vm._v("Save ")]):_c('b-button',{attrs:{"type":"submit","variant":"primary"}},[_c('b-spinner',{attrs:{"small":""}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }