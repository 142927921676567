<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Code"
      label-for="language_code"
      :invalid-feedback="violations.code"
      :state="!isValid('code')"
    >
      <b-form-input
        id="language_code"
        v-model="item.code"
        type="text"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Name"
      label-for="language_name"
      :invalid-feedback="violations.name"
      :state="!isValid('name')"
    >
      <b-form-input
        id="language_name"
        v-model="item.name"
        type="text"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Enabled"
      label-for="language_enabled"
      :invalid-feedback="violations.enabled"
      :state="!isValid('enabled')"
    >
      <b-form-checkbox
        id="language_enabled"
        switch
        v-model="item.enabled"
        size="lg"
      >
      </b-form-checkbox>
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  created() {},

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({}),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createLanguage');
      this.$bvModal.hide('updateLanguage');
    }
  }
};
</script>
