<template>
  <div class="chat-modal">
    <div
      class="chat-modal__icon d-flex justify-content-between pr-4 align-items-center"
      @click="isChatOpen = !isChatOpen"
    >
      <span class="">
        {{ namedChat }}
      </span>
      <i class="fa fa-comments"></i>
    </div>

    <div
      class="chat-modal__content"
      :class="{
        'chat-modal__content__open': isChatOpen,
        'chat-modal__content__close': !isChatOpen
      }"
    >
      <Chat
        :context="context"
        :hide-header="true"
        @close="isChatOpen = !isChatOpen"
      />
    </div>
  </div>
</template>

<script>
import Chat from '../../../components/chat/Messages/Chat.vue';
import helpers from '@/mixins/helpers';

export default {
  name: 'Sidechat',

  components: {
    Chat
  },

  mixins: [helpers],

  props: {
    context: {
      type: String
    }
  },
  computed: {
    namedChat() {
      if (this.context.includes('/handshakes/'))
        return 'Handshakes #' + this.context.replace('/handshakes/', '');
      return this.context;
    }
  },
  data() {
    return {
      isChatOpen: false
    };
  }
};
</script>

<style lang="scss">
.chat-modal {
  &__icon {
    position: fixed;
    right: 10px;
    bottom: 0;
    background-color: $primary;
    padding: 10px;
    width: 35vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: $white;

    cursor: pointer;
  }

  &__content {
    position: fixed;
    right: -1000px;

    animation: linear ease-in-out;
    animation-name: exit;
    animation-duration: 0.5s;

    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    z-index: 10;
    width: 35vh;
    height: 60vh;

    &__close {
      right: 10px;
      bottom: -1000px;

      animation: linear ease-in-out;
      animation-name: exit;
      animation-duration: 0.5s;
    }

    &__open {
      right: 10px;
      bottom: 0;

      animation: linear ease-in-out;
      animation-name: enter;
      animation-duration: 0.5s;
    }

    .messages-card {
      .card-header {
        background: $primary;
        color: white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }
      .card-body {
        padding: 0;
        .messages-list {
          height: 48vh;
        }
      }
    }
  }
}

@keyframes enter {
  0% {
    bottom: -1000px;
  }
  100% {
    bottom: 0;
  }
}

@keyframes exit {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -1000px;
  }
}
</style>
