<template>
  <b-form id="formProposal">
    <div>
      <div class="container-fluid mb-5">
        <div class="row">
          <div class="col-md-12">
            <div class="content new">
              <h1 class="mb-4">{{ item.name }}</h1>
              <hr class="mb-4" />
              <!--                <div class='form-row'>-->
              <!--                  <div class='col-md-12 bgSummary'>-->
              <!--                    <div class='summary'>-->
              <!--                      <div class='container'>-->
              <!--                        <div class='col-md-3'>-->
              <!--                          <i class='fas fa-users'></i>-->
              <!--                          {{ getLearnersAmount }} learners-->
              <!--                        </div>-->
              <!--                        <div class='col-md-3'>-->
              <!--                          <i class='fas fa-chalkboard-teacher'></i>-->
              <!--                          {{ getTeachersAmount }} teachers-->
              <!--                        </div>-->
              <!--                        <div class='col-md-3'>-->
              <!--                          <i class='far fa-file-alt'></i>-->
              <!--                          {{ item.modules.length }} modules-->
              <!--                        </div>-->
              <!--                        <div class='col-md-3'>-->
              <!--                          <i class='far fa-clock'></i> - -->
              <!--                        </div>-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </div>-->

              <ScheduleList ref="scheduleList" :program="item"></ScheduleList>

              <!--- buttons --->
              <div class="form-row mt-5">
                <div class="col-sm-12">
                  <b-button
                    v-if="isLoading"
                    class="float-right"
                    type="button"
                    variant="primary"
                  >
                    <b-spinner small></b-spinner>
                  </b-button>
                  <div v-else>
                    <b-button
                      v-if="item.status === 'STARTED'"
                      class="float-right"
                      variant="primary"
                      @click.prevent="doCompleteProgram"
                      >Complete Program
                    </b-button>

                    <b-button
                      v-else-if="item.status === 'EXECUTED'"
                      class="float-right"
                      variant="primary"
                      disabled
                      >Already executed
                    </b-button>
                  </div>

                  <template>
                    <b-button
                      v-if="!isCancelingProgram"
                      class="float-right"
                      variant="danger mr-2"
                      @click.prevent="cancelProgram"
                      >Cancel Program
                    </b-button>
                    <b-button
                      v-else-if="isCancelingProgram"
                      class="float-right"
                      type="submit"
                    >
                      <b-spinner small></b-spinner>
                    </b-button>
                  </template>

                  <!--          <button class="btn btn-primary float-right mr-1">Previous</button>-->
                  <b-button
                    v-if="!isLoading"
                    class="float-right mr-2"
                    variant="secondary"
                    @click="cancel"
                    >Cancel
                  </b-button>
                </div>
              </div>
              <!--- end buttons --->
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { find, get, isUndefined } from 'lodash';
import ScheduleList from './Schedules/List.vue';
import { businessLogicService } from '../../../../utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    ScheduleList
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isLoading: false,
      isCancelingProgram: false,
      isMounted: false
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    getLearnersAmount() {
      return 0;
    },

    getTeachersAmount() {
      return 0;
    }
  },

  created() {
    // Load dependencies
    this.getModules(`${this.item['@id']}/modules`);
  },
  mounted() {
    // zz, hacky
    this.isMounted = true;
  },

  methods: {
    ...mapActions({
      getModules: 'learningmodule/list/default'
    }),

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    doCompleteProgram() {
      this.isLoading = true;
      businessLogicService(this.item['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({ statusTransition: 'to_executed' })
      })
        .then((response) => response.json())
        .then((data) => {
          // refresh data
          this.$emit('refreshData');
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error!',
            description: e.errors
              ? e.errors._error.replace('\n', '<br>')
              : e.message,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    cancelProgram() {
      alert('this is disabled for now.');
    },

    cancel() {
      this.$router.push({ name: 'ExecuteList' });
    }
  }
};
</script>

<style lang="scss" scoped>
#formProposal {
  .form-row {
    h3 {
      // font-size: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    label {
      // font-size: 16px;
      font-weight: 700;
      padding-bottom: 5px;
    }

    input,
    select {
      //position: relative;
      height: 45px;
      border-color: $gray-200;
    }

    .custom-checkbox {
      margin-left: 5px;
    }

    i {
      position: absolute;
      right: 15px;
      bottom: 15px;
      color: $gray-300;
      cursor: pointer;
    }

    a.showLearningmaterials {
      //.at2x('../../img/icons/learningmaterials-grey.png', 14px, 19px);
      //background: url("../../../")
      background-repeat: no-repeat;
      width: 45px;
      height: 45px;
      display: block;
      background-color: $gray-100;
      border-radius: 50%;
      background-position: center center;
    }

    .btn {
      i {
        position: relative;
        right: auto;
        bottom: auto;
        color: $white;
      }
    }

    .bgSummary {
      .summary {
        background-color: $gray-200;
        height: 64px;
        border-radius: 5px;

        .container {
          padding: 20px;
        }

        div {
          float: left;
          font-weight: 700;

          i {
            margin-right: 10px;
            margin-top: 5px;
            right: auto;
            bottom: auto;
            float: left;
            position: relative;
          }
        }
      }
    }
  }

  .materialsUL,
  .activitiesUL {
    padding-left: 0px;

    li {
      margin-top: 10px;
      list-style: none;
      padding: 0.6rem 1rem;
      border: 1px solid $gray-300;
      border-radius: 0.25rem;
      background-color: $gray-200;
      color: $gray-500;

      .deletebutton {
        float: right;

        i {
          position: relative;
          right: auto;
          bottom: auto;
        }
      }
    }
  }

  #collapseAddModule {
    .content {
      background-color: $gray-100;
      padding: 20px;
      border-radius: 5px;
    }
  }

  .viewModules {
    .col-md-6 {
      margin-top: 20px;

      .content {
        border: 1px solid $gray-300;
        padding: 40px;
        border-radius: 5px;

        h3 {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        .learningMaterials,
        .learningActivity {
          ul {
            margin-top: 20px;
            min-height: 30px;
            background-image: url('../../../../assets/img/icons/learningModules.png');
            background-repeat: no-repeat;
            background-position: left top 10px;

            li {
              margin-left: 20px;
              // font-size: 14px;

              span {
                font-style: italic;
                opacity: 0.8;
              }
            }
          }
        }

        .learningMaterials {
          ul {
            background-image: url('../../../../assets/img/icons/learningMaterials.png');
          }
        }
      }

      .actions {
        padding-top: 20px;

        a {
          .far {
            // font-size: 20px;
            color: $gray-300;
            position: relative;
            right: auto;
            bottom: auto;
          }

          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .audit {
    .custom-checkbox {
      margin-left: 0px;

      .custom-control-label {
        // font-size: 14px;
      }
    }
  }
}
</style>
