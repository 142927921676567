<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Name"
      label-for="country_name"
      :invalid-feedback="violations.name"
      :state="!isValid('name')"
    >
      <b-form-input
        id="country_name"
        v-model="item.name"
        type="text"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Code"
      label-for="country_name"
      :invalid-feedback="violations.code"
      :state="!isValid('code')"
    >
      <b-form-input
        id="country_code"
        v-model="item.code"
        type="text"
        required="required"
        maxlength="2"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-form-group
      label="Language"
      label-for="country_language"
      :invalid-feedback="violations.code"
      :state="!isValid('language')"
    >
      <b-form-select
        v-model="item.language"
        id="country_language"
        class="form-control"
      >
        <b-form-select-option
          v-for="selectItem in languageSelectItems"
          :key="selectItem['@id']"
          :value="selectItem['@id']"
          :selected="isSelected('related', selectItem['@id'])"
          >{{ selectItem.name }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Currency"
      label-for="currency"
      class="font-weight-bold"
    >
      <b-form-select v-if="!currencyItems" disabled value="">
        <b-form-select-option selected value=""
          >Loading...</b-form-select-option
        >
      </b-form-select>
      <b-form-select
        v-else
        v-model="item.currency"
        id="currency"
        :options="currencyItems"
        text-field="code"
        value-field="code"
        required
      >
      </b-form-select>
    </b-form-group>

    <b-form-group
      label="Enabled"
      label-for="country_enabled"
      :invalid-feedback="violations.isEnabled"
      :state="!isValid('isEnabled')"
    >
      <b-form-checkbox
        id="country_enabled"
        switch
        v-model="item.isEnabled"
        :unchecked-value="false"
        :value="true"
      />
    </b-form-group>

    <b-form-group class="float-right">
      <b-button
        @click="closeModal"
        class="mr-2"
        type="button"
        variant="secondary"
        :disabled="isLoading"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Submit</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { entityService } from '@/utils/fetch';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      currencyItems: []
    };
  },

  created() {
    this.languageGetSelectItems();
    this.getCurrencies();

    // Make sure it's set!
    this.item.isEnabled = !!this.item.isEnabled;
  },

  computed: {
    ...mapFields('language/list', {
      languageSelectItems: 'selectItems'
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({
      languageGetSelectItems: 'language/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createCountry');
    },

    getCurrencies() {
      entityService('/currencies')
        .then((response) => response.json())
        .then((data) => {
          this.currencyItems = data['hydra:member'];
        });
    }
  }
};
</script>
