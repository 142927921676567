<template>
  <div class="company-update">
    <div class="company-update__tabs">
      <TabGroup :tabs="tabs" :company="relation" @updated="updated" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SwalAlert } from '@/utils/swal';

import TabGroup from '@/components/_ui/tabs/TabGroup';

export default {
  name: 'RelationUpdate',

  props: {
    relation: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    TabGroup
  },

  data() {
    return {
      tabs: [
        {
          active: 'CustomersGeneral',
          text: 'General Information',
          visible: true
        },
        {
          active: 'CustomersAddress',
          text: 'Address',
          visible: true
        }
      ]
    };
  },

  created() {
    this.getSectorItems();

    if (!this.relation.related.hasOwnProperty('address')) {
      this.tabs.pop();
    }
  },

  methods: {
    ...mapActions({
      getSectorItems: 'sector/list/getSelectItems'
    }),

    updated() {
      this.$bvModal.hide('showRelation');

      SwalAlert({
        title: 'Relation updated',
        icon: 'user',
        type: 'success',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {
              return;
            }
          }
        }
      });
    }
  }
};
</script>
