<template>
  <div>
    <CustomTable :fields="fields" list="document" @changePage="changePage">
      <template v-slot:actions="data">
        <a
          :href="data.item.url"
          target="_blank"
          v-b-tooltip.hover
          title="Download"
        >
          <i aria-hidden="true" class="fa fa-download text-info"></i>
        </a>
      </template>
    </CustomTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';

export default {
  components: { CustomTable },

  props: {
    viewDocument: {
      type: Object
    }
  },

  data() {
    return {
      editId: '',
      deleting: {},
      fields: [
        {
          key: 'type',
          label: 'Type',
          sortable: true
        },
        {
          key: 'program.name',
          label: 'Program',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            return this.parseDate(item.createdAt);
          }
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapFields('document/del', {
      deletedItem: 'deleted'
    }),
    ...mapGetters('auth', ['getCompanyId']),

    request() {
      let request = this.viewDocument
        ? this.viewDocument['@id']
        : this.getCompanyId;

      request += `/documents?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'document/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    }
  }
};
</script>
