<template>
  <div>
    <ScheduleForm
      v-if="retrieved"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="retrieved"
      :errors="violations"
      :initial-values="retrieved"
    />
    <b-container v-else class="text-center p-5">
      <b-spinner></b-spinner>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ScheduleForm from './Form.vue';
import { mapFields } from 'vuex-map-fields';

export default {
  components: {
    ScheduleForm
  },

  computed: {
    ...mapFields('program/update', {
      isLoading: 'isLoading',
      error: 'error',
      retrieved: 'retrieved',
      updated: 'updated',
      violations: 'violations'
    })
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$router.push({ name: 'ProposalList' });
    }
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    if (!this.$route.params.id) {
      this.$router.push({ name: 'ProposalList' });
    } else {
      this.refreshData();
    }
  },

  methods: {
    ...mapActions({
      createReset: 'program/create/reset',
      retrieve: 'program/update/retrieve',
      updateReset: 'program/update/reset',
      update: 'program/update/update',
      updateRetrieved: 'program/update/updateRetrieved'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      let id = this.$route.params.id;
      this.retrieve(`/programs/${id}`);
    },

    reset() {
      this.updateReset();
      this.createReset();
    },

    onSendForm() {
      this.update(this.retrieved);
    }
  }
};
</script>

<style lang="scss">
h1 {
  color: $primary;
}

label {
  font-weight: bold;
}
</style>
