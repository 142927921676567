<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="program"
      @changePage="changePage"
      showTitle
    >
      <template v-slot:title>
        <img src="@/assets/img/icons/menu/programs.svg" />
        Cancelled overview
      </template>
    </CustomTable>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';
import { businessLogicService, entityService } from '@/utils/fetch';

export default {
  name: 'ProposalList',

  components: { CustomTable },

  mixins: [helpers],

  data() {
    return {
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'customer.name', label: 'Beneficiary', sortable: true },
        { key: 'accountant.name', label: 'Accountant', sortable: true },
        { key: 'theme.name', label: 'Theme', sortable: true },
        {
          key: 'price',
          label: 'Price',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.price);
          }
        },
        { key: 'reasonToCancel', label: 'Reason to Cancel', sortable: true }
      ],
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    request() {
      let request = '/programs?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status=CANCELLED`;

      return request;
    }
  },

  created() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    }
  }
};
</script>
