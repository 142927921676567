export const ENTITY_SERVICE_ENTRYPOINT =
  process.env.VUE_APP_ENTITY_SERVICE_ENTRYPOINT;
export const BUSINESS_SERVICE_ENTRYPOINT =
  process.env.VUE_APP_BUSINESS_SERVICE_ENTRYPOINT;
export const COMMUNICATION_SERVICE_ENTRYPOINT =
  process.env.VUE_APP_COMMUNICATION_SERVICE_ENTRYPOINT;
export const COMMUNICATION_SERVICE_REALTIME_ENTRYPOINT =
  process.env.VUE_APP_COMMUNICATION_SERVICE_REALTIME_ENTRYPOINT;
export const AUTH_SERVICE_ENTRYPOINT =
  process.env.VUE_APP_AUTH_SERVICE_ENTRYPOINT;
export const DOC_SERVICE_ENTRYPOINT =
  process.env.VUE_APP_DOC_SERVICE_ENTRYPOINT;
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
