<template>
  <b-container fluid>
    <CustomTable :fields="fields" list="payment" @changePage="changePage">
      <template v-slot:amount="data">
        {{
          formatCurrency(
            data.item.transactions.reduce((acc, item) => acc + item.amount, 0)
          )
        }}
      </template>
      <template v-slot:document_number="data">
        <div v-for="(item, index) in data.item.transactions" :key="index">
          <span v-if="item.creditNote">
            {{ item.creditNote.documentNumber }}
          </span>
          <span v-else-if="item.shareable && item.shareable.invoice"
            >{{ item.shareable.invoice.documentNumber }}
          </span>
          <span v-else>N/A</span>
          <strong> ({{ formatCurrency(item.amount.value) }})</strong>
        </div>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createPayment')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Payment
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      id="createPayment"
      hide-footer
      title="Add New Payment"
      size="xl"
      @hide="closeModal"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add New Payment</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createPayment')"
        ></i>
      </template>

      <PaymentCreate @created="refreshData" />
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import CustomTable from '@/components/_ui/tables/CustomTable';
import PaymentCreate from './Create';
import helpers from '@/mixins/helpers';

export default {
  components: {
    CustomTable,
    PaymentCreate
  },

  mixins: [helpers],

  data() {
    return {
      fields: [
        {
          key: 'date',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            return dayjs(item.date).format('DD/MM/YYYY');
          }
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true
        },
        {
          key: 'document_number',
          label: 'Document Number / Amount',
          sortable: true
        }
      ],
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    request() {
      let request = '/payments?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&isIncoming=false`;
      return request;
    }
  },

  mounted() {
    this.refreshData();
    this.getCompanies('/companies?itemsPerPage=10000');
  },

  methods: {
    ...mapActions({
      getPage: 'payment/list/default',
      getCompanies: 'company/list/default',
      resetCreditNotes: 'creditNotes/list/resetState',
      resetShareables: 'shareables/list/resetState'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    closeModal() {
      this.resetCreditNotes();
      this.resetShareables();
    }
  }
};
</script>
´
