<template>
  <b-form @submit.prevent="onFormSubmit(item)">
    <b-spinner v-if="articlesIsLoading" small></b-spinner>
    <div v-else>
      <b-tabs>
        <b-tab title="General">
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="article_name"
              :invalid-feedback="violations.name"
              :state="!isValid('name')"
            >
              <b-form-input
                id="article_name"
                v-model="item.name"
                type="text"
                required="required"
                placeholder=""
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Type"
              label-for="article_type"
              :invalid-feedback="violations.type"
              :state="!isValid('type')"
            >
              <b-form-select
                id="article_type"
                v-model="item.type"
                required="required"
              >
                <b-form-select-option value="PRODUCT"
                  >PRODUCT</b-form-select-option
                >
                <b-form-select-option value="SERVICE"
                  >SERVICE</b-form-select-option
                >
                <b-form-select-option value="SPLIT">SPLIT</b-form-select-option>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12" v-if="item.type !== 'SPLIT'">
            <b-form-group
              label="Profit Type"
              label-for="profit_type"
              :invalid-feedback="violations.profitType"
              :state="!isValid('profitType')"
            >
              <b-form-select id="profit_type" v-model="item.profitType">
                <b-form-select-option value="None"></b-form-select-option>
                <b-form-select-option value="Shared Profit"
                  >Shared Profit</b-form-select-option
                >
                <b-form-select-option value="Invoiced Fee"
                  >Invoiced Fee</b-form-select-option
                >
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <table
                v-if="item.type === 'SPLIT'"
                class="table table-striped table-hover"
              >
                <thead>
                  <tr>
                    <th width="60%">Article</th>
                    <th>Split</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(articleChildren, idx) in item.articleSplits"
                    :key="idx"
                  >
                    <td>
                      <strong>{{
                        getArticleById(articleChildren.childArticle).type
                      }}</strong>
                      {{ getArticleById(articleChildren.childArticle).name }}
                    </td>
                    <td>{{ articleChildren.splitValue }}%</td>
                    <td>
                      <a @click.prevent="deleteArticle(key)"
                        ><i class="fa fa-trash text-info"></i
                      ></a>
                    </td>
                  </tr>
                  <tr
                    v-if="
                      availableArticleItems.length > 0 &&
                      this.leftSplitArticles > 0
                    "
                  >
                    <td>
                      <b-form-select v-model="newArticle.childArticle" required>
                        <b-form-select-option
                          value="0"
                          v-if="articlesIsLoading"
                          disabled
                        >
                          <b-spinner small></b-spinner>
                        </b-form-select-option>
                        <b-form-select-option
                          v-else
                          v-for="article in availableArticleItems"
                          :key="article['@id']"
                          :value="article['@id']"
                        >
                          {{ article.type }} - {{ article.name }}
                        </b-form-select-option>
                      </b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        id=""
                        v-model="newArticle.splitValue"
                        type="number"
                      ></b-form-input>
                    </td>
                    <td>
                      <i
                        v-if="!canAddArticle"
                        class="fa fa-plus-circle disabled"
                      ></i>
                      <a v-else href="#" @click.prevent="addArticle(newArticle)"
                        ><i class="fa fa-plus-circle"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-form-group>
          </b-col>
        </b-tab>
        <b-tab title="Financial" v-if="item.type !== 'SPLIT'">
          <b-form-row>
            <b-col cols="6">
              <b-form-group
                label-for="article_from"
                label="From"
                :invalid-feedback="violations.origin"
                :state="!isValid('origin')"
              >
                <b-form-select
                  id="article_from"
                  v-model="item.origin"
                  required="required"
                >
                  <b-form-select-option value="AprovA"
                    >AprovA</b-form-select-option
                  >
                  <b-form-select-option value="AmplificA"
                    >AmplificA</b-form-select-option
                  >
                  <b-form-select-option value="Provider through Cession"
                    >Creator through Cession</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="To"
                label-for="article_to"
                :invalid-feedback="violations.destination"
                :state="!isValid('destination')"
              >
                <b-form-select
                  id="article_to"
                  v-model="item.destination"
                  required="required"
                >
                  <b-form-select-option value="Customer"
                    >Beneficiary</b-form-select-option
                  >
                  <b-form-select-option value="Provider"
                    >Creator</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Title"
                label-for="article_title"
                :invalid-feedback="violations.title"
                :state="!isValid('title')"
              >
                <b-form-input
                  id="article_title"
                  v-model="item.title"
                  type="text"
                  placeholder=""
                  required="required"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Vat Origin"
                label-for="vat_origin"
                :invalid-feedback="violations.vatOrigin"
                :state="!isValid('vatOrigin')"
              >
                <b-form-select
                  id="vat_origin"
                  v-model="item.vatOrigin"
                  required="required"
                >
                  <b-form-select-option value="AmplificA AI+Domus"
                    >AmplificA AI+Domus</b-form-select-option
                  >
                  <b-form-select-option value="Origin"
                    >Origin</b-form-select-option
                  >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-row>
                <b-col cols="6">
                  <b-form-group
                    label="Benchmark"
                    label-for="article_isProgramBenchmark"
                    :invalid-feedback="violations.isProgramBenchmark"
                    :state="!isValid('isProgramBenchmark')"
                  >
                    <b-form-checkbox
                      v-if="item.type === 'PRODUCT'"
                      id="article_isProgramBenchmark"
                      v-model="item.isProgramBenchmark"
                    >
                    </b-form-checkbox>
                    <span v-else class="text-black-50">N/A</span>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Mergeable"
                    label-for="article_mergeable"
                    :invalid-feedback="violations.isMergeable"
                    :state="!isValid('isMergeable')"
                  >
                    <b-form-checkbox
                      id="article_mergeable"
                      v-model="item.isMergeable"
                    >
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-form-row>

              <b-form-row>
                <b-col cols="6">
                  <b-form-group
                    label="VAT Rate Source"
                    label-for="article_vatRateSource"
                    :invalid-feedback="violations.vatRateSource"
                    :state="!isValid('vatRateSource')"
                  >
                    <span v-if="item.type === 'SPLIT'" class="text-black-50"
                      >N/A</span
                    >
                    <b-form-select
                      v-else
                      id="article_vatRateSource"
                      v-model="item.vatRateSource"
                      required="required"
                    >
                      <option value="EU">EU</option>
                      <option value="COUNTRY">COUNTRY</option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="VAT Rate"
                    label-for="article_vatType"
                    :invalid-feedback="violations.vatType"
                    :state="!isValid('vatType')"
                  >
                    <span v-if="item.type === 'SPLIT'" class="text-black-50"
                      >N/A</span
                    >
                    <b-form-select
                      id="article_vatType"
                      v-model="item.vatType"
                      required="required"
                      v-else-if="item.vatRateSource === 'EU'"
                    >
                      <b-form-select-option value="HIGH"
                        >HIGH</b-form-select-option
                      >
                      <b-form-select-option value="LOW"
                        >LOW</b-form-select-option
                      >
                      <b-form-select-option value="LOWLOW"
                        >LOWLOW</b-form-select-option
                      >
                      <b-form-select-option value="EXEMPT"
                        >EXEMPT</b-form-select-option
                      >
                    </b-form-select>
                    <span v-else class="text-black-50">N/A</span>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-col>
          </b-form-row>
        </b-tab>
        <b-tab title="Protocols">
          <b-row>
            <b-col cols="6">
              <b-form-group
                label="Protocol Restriction"
                label-for="article_isProtocolRestricted"
                :invalid-feedback="violations.isProtocolRestricted"
                :state="!isValid('isProtocolRestricted')"
              >
                <b-form-checkbox
                  id="article_isProtocolRestricted"
                  v-model="item.isProtocolRestricted"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                label="Beneficiary Sector Needed"
                label-for="article_isSectorRequired"
                :invalid-feedback="violations.isSectorRequired"
                :state="!isValid('isSectorRequired')"
              >
                <b-form-checkbox
                  id="article_isSectorRequired"
                  v-model="item.isSectorRequired"
                >
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
      <b-form-group class="float-right">
        <b-button
          @click="closeModal"
          class="mr-2"
          type="button"
          variant="secondary"
          :disabled="isLoading"
          >Cancel</b-button
        >

        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Submit</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </b-form-group>
    </div>
  </b-form>
</template>

<script>
import { find, get, isUndefined, isString } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data: function () {
    return {
      backupArticleSplits: [],
      newArticle: {
        childArticle: '',
        splitValue: 0
      }
    };
  },

  created() {
    // Define sane defaults
    if (!this.item.type) {
      this.$set(this.item, 'type', 'PRODUCT');
    }
    if (!this.item.profitType) {
      this.$set(this.item, 'profitType', 'Shared Profit');
    }
    if (!this.item.vatOrigin) {
      this.$set(this.item, 'vatOrigin', 'Origin');
    }
    if (!this.item.vatRateSource) {
      this.$set(this.item, 'vatRateSource', 'COUNTRY');
    }
    if (!this.item.vatType) {
      this.$set(this.item, 'vatType', 'HIGH');
    }
    if (!this.item.articleSplits) {
      this.$set(this.item, 'articleSplits', []);
    }
    if (!this.item.origin) {
      this.$set(this.item, 'origin', 'AprovA');
    }
    if (!this.item.destination) {
      this.$set(this.item, 'destination', 'Customer');
    }
    // Store in case, we're not using SPLIT type
    else {
      this.backupArticleSplits = this.item.articleSplits;
    }

    // Retrieve articles data
    this.getArticleSelectItems({ params: { itemsPerPage: 100000 } });
    // Reset form
    this.resetArticle();
  },

  watch: {
    articlesIsLoading: function (newValue) {
      this.newArticle.splitValue = this.leftSplitArticles;
    },
    /**
     * If type doesn't have child, remove it
     * Otherwise, restore from backup
     */
    'item.type': function (type) {
      if (type === 'SPLIT' && this.backupArticleSplits.length > 0) {
        this.$set(this.item, 'articleSplits', this.backupArticleSplits);
      } else {
        this.$set(this.item, 'articleSplits', []);
      }
    }
  },

  computed: {
    ...mapFields('article/list', {
      articlesError: 'error',
      articlesItems: 'selectItems',
      articlesIsLoading: 'isLoading'
    }),

    sumSplitArticles() {
      let sum = 0;
      this.item.articleSplits.forEach((rule) => {
        sum += parseFloat(rule.splitValue);
      });
      return sum;
    },

    leftSplitArticles() {
      return 100 - this.sumSplitArticles;
    },

    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    /**
     * Which articles are not in use already?
     */
    availableArticleItems() {
      if (!this.item.articleSplits) {
        return this.articlesItems || [];
      }

      let currentIds = this.item.articleSplits.map(
        (children) => children.article
      );
      return this.articlesItems.filter(
        (article) => !currentIds.includes(article['@id'])
      );
    },
    // Disable button
    canAddArticle() {
      return (
        this.newArticle.splitValue >= 0 &&
        this.newArticle.splitValue <= this.leftSplitArticles &&
        this.newArticle.childArticle.length > 0
      );
    }
  },

  methods: {
    ...mapActions({
      getArticleSelectItems: 'article/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createArticle');
      this.$bvModal.hide('editArticle');
    },

    getArticleById(articleId) {
      if (!this.articlesItems) {
        return null;
      }
      let _id = isString(articleId) ? articleId : articleId['@id'];
      return this.articlesItems.find((article) => article['@id'] === _id);
    },

    resetArticle() {
      this.newArticle.childArticle = '';
      this.newArticle.splitValue = this.leftSplitArticles;
    },

    addArticle(article) {
      // Add and reset
      this.item.articleSplits.push({
        childArticle: article.childArticle,
        splitValue: parseFloat(article.splitValue)
      });
      this.resetArticle();
    },
    /**
     * Remove from item array
     * @param articleIndex
     */
    deleteArticle(articleIndex) {
      this.item.articleSplits.splice(articleIndex, 1);
    },

    onFormSubmit(item) {
      // False instead of nullable
      if (!this.item.isProtocolRestricted) {
        this.item.isProtocolRestricted = false;
      }
      if (this.item.type === 'SPLIT') {
        this.item.vatRateSource = null;
        this.item.vatType = null;
      }

      this.handleSubmit(item);
    }
  }
};
</script>
