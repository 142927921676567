<template>
  <div class="financial-invoices mt-4">
    <b-form-group>
      <table
        class="table table-striped table-hover"
        v-if="documents.length > 0"
      >
        <thead>
          <tr>
            <th width="90">Type</th>
            <th width="90">Document Number</th>
            <th width="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(document, index) in documents" :key="index">
            <td>
              {{ document.type }}
            </td>
            <td>{{ document.documentNumber }}</td>
            <td>
              <a :href="document.url" target="_blank">
                <i aria-hidden="true" class="fa fa-download"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <p class="font-weight-bold text-center" v-else>No invoices.</p>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'NewInvoices',

  props: {
    invoiceItem: {
      type: Object,
      required: true
    }
  },

  computed: {
    documents() {
      const invoices = this.invoiceItem.invoices ?? [];
      const collectionNotes = this.invoiceItem.collectionNote ?? [];

      const documents = [
        ...invoices.map((item) => {
          return {
            type: 'Invoice',
            documentNumber: item?.documentNumber,
            url: item?.document?.url
          };
        }),
        ...collectionNotes.map((item) => {
          return {
            type: 'Collection Note',
            documentNumber: item?.documentNumber,
            url: item?.document?.url
          };
        })
      ];

      return documents.filter((item) => item.url && item.documentNumber) || [];
    }
  }
};
</script>
