<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="protocol"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:protocolRules="data">
        <p v-for="(rule, idx) in data.item.protocolRules" :key="idx">
          <strong>{{ rule.article.type }}</strong> - {{ rule.article.name }}
        </p>
      </template>

      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
          ><i class="fa fa-edit text-info"></i
        ></a>
        <a
          href="#"
          @click.prevent="del(data.item)"
          title="Delete"
          v-b-tooltip.hover
          ><i class="fa fa-trash text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createProtocol')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Protocol
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="editProtocol" title="Edit Protocol" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Protocol</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editProtocol')"
        ></i>
      </template>

      <ProtocolUpdate
        :protocol="editProtocol"
        @updated="refreshData"
      ></ProtocolUpdate>
    </b-modal>

    <b-modal id="createProtocol" title="Create Protocol" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Protocol</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createProtocol')"
        ></i>
      </template>

      <ProtocolCreate @created="refreshData"></ProtocolCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ProtocolUpdate from './Update.vue';
import ProtocolCreate from './Create.vue';
import CustomTable from '@/components/_ui/tables/CustomTable';

export default {
  components: {
    ProtocolUpdate,
    ProtocolCreate,
    CustomTable
  },

  computed: {
    ...mapFields('protocol/del', {
      deleteError: 'error',
      deleteLoading: 'isLoading',
      deleted: 'deleted'
    }),
    request() {
      let request = '/protocols?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      editProtocol: 0,
      deleting: {},
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        {
          key: 'article.name',
          label: 'Root Article',
          sortable: true
        },
        {
          key: 'protocolRules',
          label: 'Rules (Restricted)',
          sortable: true
        },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }
      this.refreshData();
    }
  },

  methods: {
    ...mapActions({
      getPage: 'protocol/list/default',
      deleteItem: 'protocol/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this article ?')) {
        let itemId = item['@id'];
        this.deleting[itemId] = true;
        this.deleting[itemId] = true;
        this.deleteItem({ '@id': itemId });
      }
    },

    deleteIsLoading(articleId) {
      return this.deleting.hasOwnProperty(articleId);
    },

    edit(item) {
      this.editProtocol = item;
      this.$bvModal.show('editProtocol');
    }
  }
};
</script>
