<template>
  <div>
    <CompanyType
      :item="item"
      :errors="violations"
      :handle-submit="handleCompanyTypeSubmit"
      :show-off-mode="showOffMode"
      v-if="step === 1 || showOffMode"
      :handle-previous="goPreviousStep"
      :show-type-person="showTypePerson"
      :role="role"
      :is-only-commercial="isOnlyCommercial"
      :is-creating-relation="isCreatingRelation"
      :is-onboarding="isOnboarding"
      :availableCompanyTypes="availableCompanyTypes"
      :is-creating-ubo="isCreatingUbo"
      :is-creating-branch="isCreatingBranch"
      :block-branch="blockBranch"
    ></CompanyType>

    <!-- STEP 2 COMPANY INFORMATION (name, status, flags) -->
    <CompanyInformation
      :step="step"
      :item="item"
      :errors="violations"
      :is-loading="isLoading"
      :companyRoles="companyRoles"
      :handle-submit="handleCompanyInformationSubmit"
      :skip-company-roles="skipCompanyRoles"
      :handle-previous="goPreviousStep"
      :show-off-mode="showOffMode"
      :is-creating-sub-contractor="isCreatingSubContractor"
      :is-creating-accountant="isCreatingAccountant"
      :is-onboarding="isOnboarding"
      :is-creating-relation="isCreatingRelation"
      :is-creating-ubo="isCreatingUbo"
      :is-creating-branch="isCreatingBranch"
      :companyType="companyType"
      v-if="step === 2 || showOffMode"
    ></CompanyInformation>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { find, get, isUndefined } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import AddressForm from '../../common/address/Form';
import UserForm from '../user/Form';
import CompanyType from './create/CompanyType';
import CompanyInformation from './create/CompanyInformation';

const { mapFields } = createHelpers({
  getterType: 'address/create/getField',
  mutationType: 'address/create/updateField'
});

export default {
  components: {
    CompanyInformation,
    CompanyType
  },

  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },
    handleRetrieve: {
      type: Function,
      required: false
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },
    // -------------- PARENT PROPS --------------
    returnIfExists: {
      type: Boolean,
      required: true
    },
    skipCompanyRoles: {
      type: Boolean,
      required: true
    },
    showOffMode: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    showTypePerson: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    isCreatingAccountant: {
      type: Boolean,
      default: false
    },

    companyRoles: {
      type: String,
      default: null
    },

    isCreatingSubContractor: {
      type: Boolean,
      default: false
    },

    isOnboarding: {
      type: Boolean,
      default: false
    },

    role: {
      type: String,
      default: null
    },

    isOnlyCommercial: {
      type: Boolean,
      default: false
    },

    isCreatingBranch: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    blockBranch: {
      type: Boolean,
      default: () => {
        return true;
      }
    },

    isCreatingRelation: {
      type: Boolean,
      default: false
    },

    isCreatingUbo: {
      type: Boolean,
      default: false
    },

    goToSecondStep: {
      type: Boolean,
      default: false
    },

    availableCompanyTypes: {
      type: Array,
      required: true
    },

    isRoleRequest: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formErrors: {},
      step: 1,
      companyType: null
    };
  },

  mounted() {
    if (this.goToSecondStep) {
      this.step = 2;
    }
  },

  computed: {
    isUpdating() {
      return this.initialValues && this.initialValues['@id'];
    },

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      let errors = {};
      if (this.errors) {
        errors = { ...errors, ...this.errors };
      }
      if (this.formErrors) {
        errors = { ...errors, ...this.formErrors };
      }
      return errors;
    }
  },

  methods: {
    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createCompany');
    },

    /**
     * Check if company exists
     * @param data
     * @param retrieved
     */
    handleCompanyTypeSubmit({ item, retrieved, vatRetrieved }) {
      this.companyType = item.type;
      this.$emit('type', this.companyType);
      // If retrieved
      if (retrieved && !this.isUpdating) {
        if (!this.returnIfExists) {
          this.$set(this.formErrors, 'fiscal_id', 'Company already exists');
        } else if (this.handleRetrieve) {
          this.handleRetrieve(item);
        }
      } else {
        // fill if we have filled data
        if (vatRetrieved && vatRetrieved.name) {
          this.item.name = vatRetrieved.name;

          this.setAddressInfo(vatRetrieved.address);
        }

        // next step
        this.step++;
      }
    },

    handleCompanyInformationSubmit(item) {
      item.isRequestingRole = this.isRoleRequest;
      if (this.isCreatingAccountant) {
        item.isAccountant = true;
      }

      this.handleSubmit(item);
    },

    goPreviousStep() {
      this.step--;
    },

    setAddressInfo(address) {
      // Remove new line from beginning and end of string then split into array on \n
      const splitedAddress = address.trim().split('\n');

      // First element of array contains the street and number of the address
      const streetAndNumber = splitedAddress[0].split(' ');
      // We use shift to remove the first element of the array and store it on a variable because the first value is ALWAYS the streeet name
      const street = streetAndNumber.shift();
      // Right now we only have the number left on the array, so we join it to make a number like ex: 2-2 and we remove the leading zeros from the string (ex: 000002 6 becomes 2-6)
      const number = streetAndNumber.join('-').replace(/^0+/, '');

      // Second element of array contains the zip code and city of the address
      const zipAndCity = splitedAddress[1].split(' ');
      // We use shift to remove the first element of the array and store it on a variable because the first value is ALWAYS the zip code
      const zip = zipAndCity.shift();
      // We join the string because whats left is the city name (ex: ['STRING1','STRING2'] becomes 'STRING1 STRING2')
      const city = zipAndCity.join(' ');

      this.$set(this.item, 'address', {
        street,
        zip,
        number,
        city
      });
    }
  }
};
</script>
