export const getters = {
  isAuthenticated: (state) => !!state.token,
  getUser: (state) => state.user,
  // -------------- Common accessed auth data --------------
  getToken: (state) => state.token,
  getAccessToken: (state) =>
    state.token ? state.token.access_token || '' : '',

  // -------------- Common accessed data on User object --------------
  getRole: (state) => state.user.role || '',
  isAdmin: (state) => state.user.role === 'ROLE_ADMIN',
  isRole: (state) => (role) => {
    // Check for one or multiple arguments
    if (typeof role === 'string') role = [role];
    return role.includes(state.user.role);
  },
  getCompany: (state) => state.user.company || 0,
  getCompanyId: (state) =>
    state.user.company.id ? state.user.company.entity.company['@id'] : '',
  getCompanyLogo: (state) =>
    state.user.company.id ? state.user.company.entity.company.logoUrl : null,
  getCompanyType: (state) =>
    state.user.company.id ? state.user.company.entity.company.type : '',
  isWelcomed: (state) => state.user.entity.isWelcomed || false,
  hasCompany: (state) => state.user.company.id !== 0,

  // -------------- Impersonator --------------
  hasImpersonator: (state) => !!state.impersonator.user.id,
  getImpersonator: (state) => state.impersonator.user,
  getImpersonatorToken: (state) => state.impersonator.token,
  getExpired: (state) => state.showExpired
};
