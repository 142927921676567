<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 62.001 80"
  >
    <g
      id="Group_1838"
      data-name="Group 1838"
      transform="translate(-12803.999 12782.867)"
    >
      <g
        id="Vector_Smart_Object_copy"
        data-name="Vector Smart Object copy"
        transform="translate(12110 -12987.867)"
      >
        <path
          id="Color_Overlay"
          data-name="Color Overlay"
          d="M705.094,483A7.054,7.054,0,0,1,698,475.99V419.124a7.134,7.134,0,0,1,7.115-7.052h7.448v-1.546a3.075,3.075,0,0,1,3.017-2.99h3.433v-1.65A2.891,2.891,0,0,1,721.905,403h14.168a2.891,2.891,0,0,1,2.893,2.886v1.65H742.4a3.075,3.075,0,0,1,3.017,2.99v1.546h7.469A7.121,7.121,0,0,1,760,419.124V475.99a7.1,7.1,0,0,1-7.115,7.01Zm-1.29-63.877V475.99a1.282,1.282,0,0,0,1.29,1.237h47.769a1.282,1.282,0,0,0,1.29-1.237V419.124a1.318,1.318,0,0,0-1.29-1.278h-7.448v4.185a2.949,2.949,0,0,1-3.017,2.825H715.559a2.935,2.935,0,0,1-3.017-2.825v-4.185h-7.448A1.318,1.318,0,0,0,703.8,419.124Zm14.564-.041h21.221V413.31h-3.516a2.828,2.828,0,0,1-2.933-2.784v-1.752h-8.322v1.752a2.84,2.84,0,0,1-2.933,2.784h-3.516Zm-7.49,43.3v-5.773h36.2v5.773Zm0-10.928v-5.773h36.2v5.773Zm0-11.546v-5.773h23.51v5.773Z"
          transform="translate(-4 -198)"
          fill="#ced4da"
        />
      </g>
      <circle
        id="Ellipse_100"
        data-name="Ellipse 100"
        cx="16"
        cy="16"
        r="16"
        transform="translate(12819 -12753)"
        fill="#e04a46"
      />
      <line
        id="Line_47"
        data-name="Line 47"
        x2="14.586"
        transform="translate(12827.508 -12736.801)"
        fill="none"
        stroke="#fbfbfb"
        stroke-linecap="round"
        stroke-width="6"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Cancel'
};
</script>
