var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-list-group',_vm._l((_vm.getRooms),function(room,roomIdx){return _c('b-list-group-item',{key:room['@id'],staticClass:"d-flex align-items-center contacts-list-item",class:{
        active: _vm.getSelectedRoomId == room['@id'],
        'flex-row-reverse': _vm.isMenuOpen
      },on:{"click":function($event){return _vm.onSelectRoom(room)}}},[(roomIdx === 'notifications')?_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
          title: _vm.namedChat(room.name || room.context),
          placement: 'topright',
          boundary: 'viewport',
          disabled: !_vm.isMenuOpen
        }),expression:"{\n          title: namedChat(room.name || room.context),\n          placement: 'topright',\n          boundary: 'viewport',\n          disabled: !isMenuOpen\n        }"}],class:{
          'mr-3': !_vm.isMenuOpen,
          'ml-4': _vm.isMenuOpen
        },attrs:{"variant":"primary"}},[_c('i',{staticClass:"fa fa-cogs"})]):_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
          title: _vm.namedChat(room.name || room.context),
          boundary: 'viewport',
          placement: 'topright',
          disabled: !_vm.isMenuOpen
        }),expression:"{\n          title: namedChat(room.name || room.context),\n          boundary: 'viewport',\n          placement: 'topright',\n          disabled: !isMenuOpen\n        }"}],class:{
          'mr-3': !_vm.isMenuOpen,
          'ml-4': _vm.isMenuOpen
        }}),_c('span',{staticClass:"d-flex flex-column contacts-content"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.namedChat(room.name || room.context))+" ")]),_vm._v(" "+_vm._s('Always yours')+" ")]),(room.newMessages)?_c('b-badge',{staticStyle:{"position":"absolute","right":"5px"}},[_vm._v(" "+_vm._s(room.newMessages)+" ")]):_vm._e()],1)}),1),_c('b-modal',{attrs:{"id":"createRoom","hide-footer":"","title":"New Room"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('div',{staticClass:"custom-modal__popup"},[_c('span',{staticClass:"custom-modal__popup__title"},[_vm._v("New Room")])]),_c('i',{staticClass:"fa fa-times custom-modal__popup__close",on:{"click":function($event){return _vm.$bvModal.hide('createRoom')}}})]},proxy:true}])},[_c('RoomCreate',{on:{"created":_vm.onCreateRoom}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }