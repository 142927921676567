<template>
  <div>
    <b-container>
      <b-modal
        id="chooseRole"
        hide-footer
        :hide-header="!isRoleRequest"
        :no-close-on-esc="!isRoleRequest"
        :no-close-on-backdrop="!isRoleRequest"
        @hide="$emit('close')"
        size="xl"
      >
        <template #modal-header>
          <div class="custom-modal__popup">
            <span class="custom-modal__popup__title"></span>
          </div>
          <i
            class="fa fa-times custom-modal__popup__close"
            @click="$bvModal.hide('chooseRole')"
          ></i>
        </template>

        <ChooseRole
          v-if="step === 1"
          @next="onRolePick"
          :isRoleRequest="isRoleRequest"
        />
        <ChooseCompany
          v-if="step === 2"
          :companyRoles="role"
          :isRoleRequest="isRoleRequest"
          @previous="step--"
          @next="onCompanyPick"
          @close="$emit('close')"
          :role="role"
        />
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import ChooseRole from '../../components/welcome/ChooseRole/Main.vue';
import ChooseCompany from '../../components/welcome/ChooseCompany/Main.vue';
import { mapActions } from 'vuex';

export default {
  name: 'RegisterView',

  components: {
    ChooseRole,
    ChooseCompany
  },

  props: {
    isRoleRequest: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.$bvModal.show('chooseRole');
  },

  data() {
    return {
      step: 1,
      errors: [],
      role: null
    };
  },
  methods: {
    ...mapActions('handshake/create', ['create']),

    onRolePick(role) {
      this.role = role;
      this.step++;
    },

    onCompanyPick(company, retrieved) {
      this.step++;
    }
  }
};
</script>
<style scoped lang="scss"></style>
