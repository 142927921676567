<template>
  <div class="aprova-report__form">
    <div class="aprova-report__form__items">
      <h1 class="aprova-report__form__subtitle">VAT Group</h1>
    </div>

    <div class="text-center" v-if="isLoading">
      <b-spinner class="align-middle" variant="primary"></b-spinner>
    </div>

    <CustomTable
      :fields="fields"
      list="company/aprovaReport"
      :values="vatGroups"
      v-else
    >
      <template v-slot:isPartOfVatGroup="data">
        <Status class="text-center" :value="data.item.isPartOfVatGroup" />
      </template>
    </CustomTable>
  </div>
</template>

<script>
import CustomTable from '@/components/_ui/tables/CustomTable';
import Status from '@/components/_ui/tables/Status';
import { entityService } from '@/utils/fetch';

export default {
  name: 'VatStatus',

  components: {
    CustomTable,
    Status
  },

  props: {
    aprovaReport: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      isLoading: false,
      vatGroups: [],
      fields: [
        { key: 'type', label: 'Type' },
        { key: 'semaphoreState', label: '360' },
        { key: 'vatStatus', label: 'VAT Status' },
        { key: 'isPartOfVatGroup', label: 'Part of VAT Group' },
        { key: 'name', label: 'Name' },
        { key: 'fiscalId', label: 'VAT/NIN' }
      ]
    };
  },

  mounted() {
    this.isLoading = true;

    entityService(`${this.aprovaReport['@id']}/vat-groups`)
      .then((response) => response.json())
      .then((data) => {
        this.vatGroups = data['hydra:member'];
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
};
</script>
