<template>
  <b-container>
    <b-form method="POST" @submit.prevent="doConfirm">
      <b-row align-h="center">
        <b-col cols="6">
          <b-input-group class="input-icon">
            <i class="fa fa-user"></i>
            <b-form-input type="text" disabled :value="email" />
          </b-input-group>
          <b-input-group class="input-icon">
            <i class="fa fa-lock"></i>
            <b-form-input
              type="password"
              v-model="form.password"
              placeholder="New password"
              required
              autocomplete="new-password"
            />
          </b-input-group>
          <b-input-group class="input-icon">
            <i class="fa fa-lock"></i>
            <b-form-input
              type="password"
              v-model="form.confirm"
              placeholder="Confirm password"
              :state="passwordValidation"
              required
              autocomplete="new-password"
            />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6">
          <div>
            <b-button
              variant="primary"
              type="submit"
              v-if="!loading && !message.length"
              >Confirm</b-button
            >

            <div v-else-if="message.length">
              <div class="message-success" v-if="message.length">
                {{ message }}
              </div>
              <router-link :to="{ name: 'Login' }" class="text-center">
                Go to Login
              </router-link>
            </div>
            <b-button variant="primary" type="submit" disabled v-else>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
            <b-form-invalid-feedback v-if="errors.length" force-show>
              {{ errors }}
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import { ENTITY_SERVICE_ENTRYPOINT } from '@/config/entrypoint';

export default {
  name: 'NewPasswordView',
  data() {
    return {
      loading: false,
      errors: [],
      message: '',
      showGotoLogin: null,
      email: '',
      token: '',
      form: {
        password: '',
        confirm: ''
      }
    };
  },
  computed: {
    passwordValidation() {
      return this.form.password === this.form.confirm;
    }
  },
  created() {
    this.email = this.$route.query.email;
    this.token = this.$route.query.valToken;
  },
  watch: {
    form: {
      deep: true,
      handler: function (form) {
        this.errors = [];
      }
    }
  },
  methods: {
    doConfirm() {
      this.loading = true;
      let form = new FormData();
      form.append('email', this.email);
      form.append('validation_token', this.token);
      form.append('password', this.form.password);
      form.append('confirm', this.form.confirm);
      fetch(`${ENTITY_SERVICE_ENTRYPOINT}/onboarding/validation`, {
        method: 'POST',
        body: form
      })
        .then((response) => response.json())
        .then((data) => {
          this.message = data.message ? data.message : '';
          // TODO: I am getting the error here because the catch is not catching it
          this.errors = data.error ? data.error : '';
        })
        .catch((error) => {
          this.errors = error;
        });
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
form {
  margin-top: 35px;
  .form-group {
    margin-bottom: 5px;
  }
}
button {
  width: 100%;
  padding: 10px;
}

a.text-center {
  margin-top: 10px;
  display: inherit;
  color: $gray-500;
}

.invalid-feedback {
  background-color: $danger;
  color: $white;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.message-success {
  background-color: $green;
  color: $white;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.input-icon {
  margin: 10px 0;
  position: relative;
  i {
    color: $dark;
    position: absolute;
    left: 13px;
    top: 15px;
    z-index: 999999;
  }

  input {
    border-radius: 5px !important;
    width: 100%;
    height: 45px;
    background-color: $dark-blue;
    color: $dark;
    padding-left: 37px;
    &:hover,
    &:focus {
      &:not([disabled]) {
        background-color: $gray-200;
        color: $dark-blue;
      }
    }
    border: none;
  }
}

.form-control {
  -webkit-text-fill-color: $gray-200;
  -webkit-box-shadow: 0 0 0 1000px $dark-blue inset;
}
.form-control:focus,
.form-control:hover {
  &:not([disabled]) {
    -webkit-text-fill-color: $dark-blue;
    -webkit-box-shadow: 0 0 0 1000px $gray-200 inset;
  }
}
</style>
