/**
 * Chat module
 * trepatudo: I've merged both entities in single store in order to have a reliable way of reaching
 * chat as a whole component in any part of the site.
 * I think it's a good call, but only future will say how well it can scale.
 */
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { getField, updateField } from 'vuex-map-fields';

const state = {
  selectedRoom: 'notifications',

  // -------------- Room --------------
  roomError: '',
  roomIsLoading: false,
  rooms: {
    // @TODO: define this somewhere else, but we must start with a notification system line
    notifications: {
      '@id': 'notifications',
      name: 'Notifications',
      messages: [],
      users: []
    }
  },
  roomView: []

  // -------------- Messages --------------
};

const namespaced = true;

export const chat = {
  namespaced,
  state,
  getters: {
    getField,
    ...getters
  },
  actions,
  mutations: {
    updateField,
    ...mutations
  }
};
