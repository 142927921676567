<template>
  <div></div>
</template>

<script>
import { COMMUNICATION_SERVICE_REALTIME_ENTRYPOINT } from '@/config/entrypoint';
import store from '@/store/index';
import { mapActions, mapGetters } from 'vuex';
import {
  CHAT_FETCH_ROOM,
  CHAT_FETCH_ROOM_BY_CONTEXT,
  CHAT_FETCH_ROOMS,
  CHAT_SELECT
} from '../../store/modules/chat/actions';
import { communicationService } from '../../utils/fetch';

import { EventSourcePolyfill } from 'event-source-polyfill';

const STORE_CHAT_TOKEN = 'STORE_CHAT_TOKEN';
/**
 * @trepatudo: I'm handling authentication here so we can get the impersionation working for this users too
 * we must fix it to be cached too @todo
 */
export default {
  computed: {
    ...mapGetters('auth', ['getUser', 'isAuthenticated', 'getCompanyId']),
    ...mapGetters('chat', ['getRoom'])
  },
  data() {
    return {
      eventSource: null
    };
  },
  mounted() {
    if (this.isAuthenticated) {
      if (this.eventSource === null) {
        this.connect();
      }
      this[CHAT_FETCH_ROOMS]();
    }
  },
  methods: {
    // ...mapActions('chat', ['CHAT_PARSE_MESSAGE'])
    ...mapActions('chat', [CHAT_FETCH_ROOMS, CHAT_FETCH_ROOM]),
    parseMessage(message) {
      if (!this.getRoom(message.room)) {
        this[CHAT_FETCH_ROOM](message.room);
      } else {
        message.isMe =
          message && message.createdBy['@id'] === this.getUser.entity['@id'];
        store.dispatch('chat/CHAT_PARSE_MESSAGE', message);
      }
    },

    fetchNewToken() {
      communicationService('/login', {
        method: 'POST',
        body: ''
      })
        .then((response) => response.json())
        .then((parsedResponse) => {
          let token = parsedResponse.token;
          if (token) {
            localStorage.setItem(STORE_CHAT_TOKEN, token);
            this.connectWithToken(token);
          }
        });
    },

    connect() {
      // we have token?
      if (this.getChatToken()) {
        this.connectWithToken(this.getChatToken());
      }
      // Fetch new one
      else {
        this.fetchNewToken();
      }
    },

    getChatToken() {
      return localStorage.getItem(STORE_CHAT_TOKEN) || false;
    },

    connectWithToken(token) {
      // Create realtime handler
      const url = new URL(
        `${COMMUNICATION_SERVICE_REALTIME_ENTRYPOINT}/.well-known/mercure`
      );

      // Subscribe user and company messages
      url.searchParams.append('topic', '*');
      // if (this.getCompanyId) {
      //   url.searchParams.append('topic', this.getCompanyId);
      // }

      this.eventSource = new EventSourcePolyfill(url.toString(), {
        headers: {
          Authorization: 'Bearer ' + token
        }
      });

      this.eventSource.onmessage = (event) => {
        let data = JSON.parse(event.data);
        // if (data['@type'] === 'Message') {
        this.parseMessage(data);

        // }
      };
    }
  }
};
</script>
