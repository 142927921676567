<template>
  <div>
    <b-button-group class="tab__group">
      <template v-for="(tab, index) in tabs">
        <b-button
          :key="index"
          :class="`${
            active === tab.active
              ? 'tab__group__button--active'
              : 'tab__group__button'
          }`"
          @click="setActive(tab)"
          v-if="tab.visible"
          >{{ tab.text }}</b-button
        >
      </template>
    </b-button-group>

    <slot></slot>

    <component
      :is="active"
      :invoiceItem="invoiceItem"
      :aprovaReport="aprovaReport"
      :companyId="companyId"
      :company="company"
      :shareholder="company"
      :read-only="readOnly"
      :user="user"
      :roles="roles"
      :selectedRoles="selectedRoles"
      :handle-submit="onSendForm"
      :isLoading="isLoading"
      :requestType="requestType"
      @updated="$emit('updated')"
      :disabled="disabled"
      :merged="merged"
      :is-creating-branch="isCreatingBranch"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import General from '@/components/aprova/tabs/General';
import IA from '@/components/aprova/tabs/IA';
import RII from '@/components/aprova/tabs/RII';
import VatStatus from '@/components/aprova/tabs/VatStatus';
import VatGroup from '@/components/aprova/tabs/VatGroup';
import AccountantVerification from '@/components/aprova/tabs/AccountantVerification';
import Light360 from '@/components/aprova/tabs/Light360';
import VatGroupCalculations from '@/components/aprova/tabs/VatGroupCalculations';

import CompanyGeneral from '@/components/dashboard/company/update/General';
import CompanyAddress from '@/components/dashboard/company/update/Address';
import CompanyRoles from '@/components/dashboard/company/update/Roles';

import Employees from '@/components/dashboard/my/company/tabs/employees/Main';

import Accountant from '@/components/dashboard/my/company/tabs/financial/accountant/Main';

import Ledgers from '@/components/dashboard/my/company/ledgers/List';
import Financial from '@/components/bankaccount/List.vue';

import Branches from '@/components/dashboard/my/company/tabs/structure/branches/List';
import Establishments from '@/components/establishment/List';

import CustomersGeneral from '@/components/dashboard/relationship/customers/update/General.vue';
import CustomersAddress from '@/components/dashboard/relationship/customers/update/Address';

import SubcontractorsGeneral from '@/components/dashboard/relationship/subcontractors/update/General.vue';
import SubcontractorsAddress from '@/components/dashboard/relationship/subcontractors/update/Address';

import UserGeneral from '@/components/dashboard/user/update/General';
import UserRoles from '@/components/dashboard/user/update/Roles';
import UserAddress from '@/components/dashboard/user/update/Address';

import FinancialGeneral from '@/components/dashboard/financial-import/imported/Tabs/General';
import FinancialAnalysis from '@/components/dashboard/financial-import/imported/Tabs/Analysis';
import NewInvoices from '@/components/dashboard/financial-import/imported/Tabs/NewInvoices';
import Merged from '@/components/dashboard/financial-import/imported/Tabs/Merged';

import RIIReserves from '@/components/dashboard/my/company/tabs/reserves/Rii';
import GeneralReserves from '@/components/dashboard/my/company/tabs/reserves/General';
import TaxReserves from '@/components/dashboard/my/company/tabs/reserves/Tax';
import PaymentDeposits from '@/components/dashboard/my/company/tabs/reserves/Deposit';

import UserPendingRequests from '@/components/dashboard/requests/user/List';
import UserRejectedRequests from '@/components/dashboard/requests/user/List';
import UserAcceptedRequests from '@/components/dashboard/requests/user/List';

import CompanyPendingRequests from '@/components/dashboard/requests/company/List';
import CompanyRejectedRequests from '@/components/dashboard/requests/company/List';
import CompanyAcceptedRequests from '@/components/dashboard/requests/company/List';

export default {
  name: 'TabGroup',

  components: {
    UserGeneral,
    UserRoles,
    General,
    UserAddress,
    IA,
    RII,
    VatStatus,
    VatGroup,
    AccountantVerification,
    Light360,
    VatGroupCalculations,
    CompanyAddress,
    Accountant,
    Branches,
    Establishments,
    CustomersGeneral,
    CustomersAddress,
    FinancialGeneral,
    FinancialAnalysis,
    GeneralReserves,
    TaxReserves,
    RIIReserves,
    SubcontractorsAddress,
    SubcontractorsGeneral,
    PaymentDeposits,
    Ledgers,
    UserPendingRequests,
    UserRejectedRequests,
    UserAcceptedRequests,
    CompanyPendingRequests,
    CompanyRejectedRequests,
    CompanyAcceptedRequests,
    NewInvoices,
    Merged,
    Employees,
    Financial,
    CompanyGeneral,
    CompanyRoles
  },

  props: {
    tabs: {
      type: Array,
      required: true
    },

    aprovaReport: {
      type: Object,
      default: () => {}
    },

    companyId: {
      type: String
    },

    company: {
      type: Object,
      default: () => {}
    },

    user: {
      type: Object,
      default: () => {}
    },

    roles: {
      type: Array,
      default: () => []
    },

    selectedRoles: {
      type: Array,
      default: () => []
    },

    handleSubmit: {
      type: Function,
      required: false
    },

    isLoading: {
      type: Boolean,
      default: false
    },

    invoiceItem: {
      type: Object,
      default: () => {}
    },

    disabled: {
      type: Boolean,
      default: false
    },

    merged: {
      type: Array,
      default: () => []
    },

    isCreatingBranch: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    ...mapGetters('auth', ['getUser']),

    readOnly() {
      return this.getUser.company.entity.isAdmin;
    }
  },

  data() {
    return {
      active: this.tabs[0].active,
      requestType: null,
      submittedData: {}
    };
  },

  methods: {
    setActive(tab) {
      this.active = tab.active;
      this.requestType = tab.text;
    },

    onSendForm(data, type = null) {
      if (type === 'address') {
        this.submittedData.address = data;
      } else if (type === 'roles') {
        this.submittedData.roles = data;
      } else {
        this.submittedData = data;
      }

      this.handleSubmit(this.submittedData);
    }
  }
};
</script>
