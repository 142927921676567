<template>
  <div>
    <div class="text-center" v-if="isFetchingFields">
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>

    <div v-else>
      <div v-if="error" id="error" class="alert alert-danger" role="alert">
        <span
          class="fa fa-exclamation-triangle"
          aria-hidden="true"
          v-html="error"
        ></span>
      </div>

      <b-form class="py-2" @submit.prevent="onSendForm">
        <template v-if="step === 1">
          <div>
            <b-form-group label="Upload File" label-for="doc">
              <vue-dropzone
                ref="myVueDropzone"
                id="doc"
                :options="dropzoneOptions"
                @vdropzone-file-added="uploadedFile"
              ></vue-dropzone>
            </b-form-group>
          </div>
        </template>

        <template v-if="step === 2">
          <b-container fluid>
            <b-row>
              <b-col cols="12" md="6">
                <span class="card__title">Your fields:</span>

                <draggable
                  tag="ul"
                  class="card"
                  :list="csvFields"
                  :animation="200"
                  ghost-class="card__moving-card"
                >
                  <li
                    v-for="(field, index) in csvFields"
                    :key="field.id"
                    class="card__item"
                    :class="{
                      'card__item--disabled':
                        index + 1 > templateFields.length || field === ''
                    }"
                  >
                    <i class="fas fa-list"></i>
                    {{ field }}
                  </li>
                </draggable>
              </b-col>
              <b-col cols="12" md="6">
                <span class="card__title">Required fields:</span>
                <ul class="card">
                  <li
                    v-for="field in templateFields"
                    :key="field.field"
                    class="card__item card__item--nomove card__item--noafter"
                  >
                    <i class="fas fa-list"></i> {{ field.name }}
                  </li>
                </ul>
              </b-col>
            </b-row>
          </b-container>
        </template>

        <div class="buttons__import">
          <CustomButton
            v-if="step !== 2"
            class="float-right"
            type="Default"
            :content="{ title: 'Next' }"
            @click.native="nextStep"
          />

          <CustomButton
            v-if="step !== 1"
            class="float-left"
            type="Default"
            :content="{ title: 'Prev' }"
            @click.native="prevStep"
          />

          <b-form-group class="float-right mt-3" v-if="step === 2">
            <b-button type="submit" variant="primary" v-if="!isLoading"
              >Submit</b-button
            >
            <b-button type="submit" variant="primary" v-else>
              <b-spinner small></b-spinner>
            </b-button>
          </b-form-group>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';

import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import helpers from '@/mixins/helpers';
import CustomButton from '@/components/_ui/CustomButton';
import Draggable from 'vuedraggable';
import { entityService } from '@/utils/fetch';

const { mapFields } = createHelpers({
  getterType: 'financial/create/getField',
  mutationType: 'financial/create/updateField'
});

export default {
  components: {
    vueDropzone: vue2Dropzone,
    CustomButton,
    Draggable
  },

  mixins: [helpers],

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  data() {
    return {
      step: 1,
      dropzoneOptions: {
        url: 'localhost',
        autoProcessQueue: false,
        dictDefaultMessage: "<i class='fa fa-plus'></i><p>UPLOAD DOCUMENT</p>",
        maxFiles: 1
      },

      file: null,

      templateFields: [],

      csvFields: [],

      mappedFields: [],

      isFetchingFields: false
    };
  },

  mounted() {
    this.getFields();
  },

  methods: {
    ...mapActions('financial/create', ['create']),

    getFields() {
      this.isFetchingFields = true;

      entityService('/import/invoices/fields')
        .then((response) => response.json())
        .then((data) => {
          this.templateFields = data;
        })
        .finally(() => {
          this.isFetchingFields = false;
        });
    },

    uploadedFile(file) {
      this.file = file;
      this.error = false;
    },

    onSendForm() {
      const fields = this.mapFinalFields();

      let formData = new FormData();
      formData.append('mapper', JSON.stringify(fields));
      formData.append('file', this.file);
      formData.append('separator', this.delimiter);

      this.create(formData);
    },

    mapFinalFields() {
      const fields = {};

      this.templateFields.forEach((key, index) => {
        fields[this.csvFields[index]] = key.field;
      });

      return fields;
    },

    async nextStep() {
      if (this.step === 1) {
        if (!this.file) {
          this.error = 'File is required. <strong>Please upload</strong>';

          return;
        }
      }

      const response = await this.transformCSV();

      if (!response) {
        this.error =
          'Your file doenst match <strong>your required fields length</strong>';

        return;
      }
      this.step++;
    },

    async transformCSV() {
      const result = await this.asyncFileReader(this.file);
      const csvFields = this.csvToArray(result);

      const missingFields = this.templateFields.length - csvFields.length;

      for (let i = 0; i < missingFields; i++) {
        csvFields.push('');
      }

      this.csvFields = csvFields;

      if (
        this.csvFields.length <
        this.templateFields.filter((item) => {
          return item.mandatory;
        }).length
      ) {
        return false;
      }

      return true;
    },

    prevStep() {
      this.step--;
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }
      this.$emit('created', created);
    },

    step: function () {
      this.error = null;
    },

    csvFields: function () {
      this.error = null;
    }
  }
};
</script>

<style lang="scss">
.card {
  width: 100%;
  padding: 0;
  border: 0 !important;

  &__title {
    font-style: italic;
    color: $gray-500;
    // font-size: 12px;
  }

  &__item {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primary;
    color: #ffffff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    padding: 10px 20px;
    transition: 0.2s background-color ease-in;

    text-transform: uppercase;
    border-radius: 8px;
    font-weight: bold;

    cursor: pointer;

    &:is(:first-child) {
      margin-top: 15px;
    }

    &:hover {
      background-color: rgb(30 47 71 / 71%);
    }

    &:after {
      content: '\2192';
      position: absolute;
      top: 10px;
      right: -27px;
      width: 20px;
      height: 20px;
      color: $primary;
    }

    &--nomove {
      cursor: default;
      background-color: $secondary !important;
    }

    &--disabled {
      opacity: 0.6;
      height: 44px;

      &:hover {
        background-color: $primary;
      }

      &:after {
        display: none;
      }
    }

    &--noafter {
      &:after {
        display: none;
      }
    }
  }

  &__moving-card {
    opacity: 0.8;
    background-color: $gray-100;
    border: 2px solid $secondary;
  }
}
</style>
