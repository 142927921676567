<template>
  <b-container fluid>
    <b-nav pills class='mainMenu mt-4'>
      <b-nav-item class='' active-class='active' to='/payments/incoming'>Incoming</b-nav-item>
      <b-nav-item class='' active-class='active' to='/payments/outgoing'>Outgoing</b-nav-item>
    </b-nav>

    <b-row class='mt-4'>
      <b-col cols='12'>
        <b-nav tabs>
          <b-nav-item class='mr-5' active-class='active' :active='true'>
            <div class='template__tab active pl-3 pr-3'>
              <h5>Overview</h5>
            </div>
          </b-nav-item>
        </b-nav>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols='12'>
        <div class='bgContent pt-4'>
          <router-view></router-view>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PaymentsView'
};
</script>

<style scoped lang='scss'>
.template {
  &__tab {
    h5 {
      //font-size: 16px;
    }
  }
}

.nav-tabs .nav-link.active {
  box-shadow: 1px 3px 10px $gray-400;
}

.nav-tabs .nav-link {
  box-shadow: 1px 3px 10px $gray-300;
}

.nav-link {
  padding: 10px 20px;
}

.nav-tabs {
  border-bottom: 0;
  margin-left: -1px;
}
</style>
