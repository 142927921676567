<template>
  <div>
    <component
      :is="type"
      :content="content"
      :disabled="disabled"
      :color="color"
      :size="size"
    ></component>
  </div>
</template>

<script>
import Modal from '@/components/_ui/buttons/Modal';
import Default from '@/components/_ui/buttons/Default';
import Link from '@/components/_ui/buttons/Link';
import Back from '@/components/_ui/buttons/Back';

export default {
  name: 'Button',

  components: {
    Modal,
    Default,
    Link,
    Back
  },

  props: {
    type: {
      type: String,
      required: true
    },

    content: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      default: 'primary'
    },

    size: {
      type: String,
      default: 'sm'
    }
  }
};
</script>
