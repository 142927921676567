<template>
  <div>
    <b-container fluid>
      <b-row>
        <div class="col-md-12 pt-5">
          <h3 class="text-center">
            Hello {{ getUser.entity.firstName }},
            <span>what do you want to do today?</span>
          </h3>
        </div>
      </b-row>
      <b-row>
        <div class="col-md-12 d-flex justify-content-end">
          <button class="btn btn-success mr-sm-3 mr-md-0" @click="startEdit">
            {{ isEditing ? 'Done editing' : 'Edit layout' }}
          </button>
        </div>
        <div class="col-md-12">
          <Main :isEditing="isEditing" />
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Main from '../components/dashboard/Main';

export default {
  name: 'Home',
  components: {
    Main
  },
  data() {
    return {
      isEditing: false
    };
  },
  computed: {
    ...mapGetters('auth', ['getUser'])
  },
  methods: {
    startEdit() {
      this.isEditing = !this.isEditing;
    }
  }
};
</script>
