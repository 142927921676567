<template>
  <div>
    <div v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <div v-else>
      <CustomTable
        class="ia-list"
        :fields="fields"
        :values="logs"
        v-if="!correctionLight"
        @changePage="changePage"
      >
        <template v-slot:trigger="data">
          <p>{{ data.item.trigger }}</p>
          <p v-if="data.item.reason">Reason: {{ data.item.reason }}</p>
        </template>

        <template v-slot:colorFrom="data">
          <Light :type="data.item.colorFrom" />
        </template>

        <template v-slot:colorTo="data">
          <Light :type="data.item.colorTo" />
        </template>

        <template v-slot:actions="data">
          <a
            v-if="!data.item.overruledBy"
            href="#"
            v-b-tooltip.hover
            title="Edit"
            @click.prevent="edit(data.item)"
            class="mr-2"
            ><i class="fa fa-edit text-info"></i
          ></a>
        </template>
      </CustomTable>

      <CorrectLight
        :correcting="correcting"
        @created="refreshData"
        @closeCorrectionLight="toggleCorrectionLight"
        v-else-if="correctionLight"
      />
    </div>
  </div>
</template>
<style lang="scss">
.ia-list {
  .trigger {
    width: 65px;
    margin: 0 !important;
  }
}
</style>

<script>
import CustomTable from '@/components/_ui/tables/CustomTable';
import Light from '@/components/_ui/trigger/Trigger';
import CorrectLight from '@/components/dashboard/company/Light/Correct';
import { entityService } from '@/utils/fetch';

export default {
  name: 'LightComponent',

  components: {
    CustomTable,
    Light,
    CorrectLight
  },

  props: {
    companyId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      fields: [
        {
          key: 'id',
          label: '#',
          formatter: (value, key, item) => {
            let string = item['@id'].replace('/company-semaphore-logs/', '#');
            if (item.overruledBy) {
              let overrules = item.overruledBy['@id'].replace(
                '/company-semaphore-logs/',
                '#'
              );
              string += ` (overruled by ${overrules})`;
            }
            return string;
          }
        },
        {
          key: 'date',
          label: 'Date',
          formatter: (value, key, item) => {
            let date = new Date(item.date);
            if (date) {
              return date.toISOString().split('T')[0];
            }
          }
        },
        { key: 'reason', label: 'Reason', sortable: true },
        { key: 'colorFrom', label: 'From', sortable: true },
        { key: 'colorTo', label: 'To', sortable: true },
        { key: 'note', label: 'Notes', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],

      correcting: {},
      correctionLight: false,

      isLoading: true,
      logs: [],
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    refreshData() {
      // fetch
      entityService(`${this.companyId}/company-semaphore-logs`)
        .then((response) => response.json())
        .then((data) => {
          this.logs = data['hydra:member'];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    edit(item) {
      this.correcting = item;
      this.correctionLight = true;
    },
    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    toggleCorrectionLight() {
      this.correctionLight = !this.correctionLight;
      // Reset light
      if (!this.correctionLight) {
        this.item = {};
      }
    }
  }
};
</script>
