<template>
  <div id="app">
    <ChatDaemon />
    <router-view />
  </div>
</template>

<script>
import ChatDaemon from './components/chat/Daemon.vue';
export default {
  components: {
    ChatDaemon
  }
};
</script>

<style lang="scss">
@import '~bootstrap/scss/bootstrap.scss';
@import '~bootstrap-vue/src/index.scss';
@import '@/assets/styles/app.scss';
</style>
