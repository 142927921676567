<template>
  <div>
    <b-form>
      <b-form-group label="Email Address" label-for="user_email">
        <b-form-input id="user_email" v-model="item.email" type="text">
        </b-form-input>
      </b-form-group>

      <b-form-group label="First Name" label-for="user_firstName">
        <b-form-input id="user_firstName" v-model="item.firstName" type="text">
        </b-form-input>
      </b-form-group>

      <b-form-group label="Last Name" label-for="user_lastName">
        <b-form-input id="user_lastName" v-model="item.lastName" type="text">
        </b-form-input>
      </b-form-group>

      <b-form-group class="float-right">
        <b-button
          type="button"
          variant="success"
          @click="onSendForm"
          :disabled="canSubmit"
          >Next</b-button
        >
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',

  data() {
    return {
      item: {
        email: null,
        firstName: null,
        lastName: null
      }
    };
  },

  computed: {
    canSubmit() {
      return !this.item.email && !this.item.firstName && !this.item.lastName;
    }
  },

  methods: {
    onSendForm() {
      this.$emit('onUserInfo', this.item);
    }
  }
};
</script>
