<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="termsAndConditions"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="showTerms(data.item)"
          v-b-tooltip.hover
          title="Show"
          ><i class="fa fa-eye text-info"></i
        ></a>
      </template>
      <template #buttons>
        <b-button
          @click="$bvModal.show('createTermsAndConditions')"
          variant="primary"
        >
          <i class="fas fa-plus"></i>
          Add Terms and Conditions
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      id="userTerms"
      title="User Terms"
      size="xl"
      hide-footer
      hide-header-close
      class="terms"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">User Terms</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('userTerms')"
        ></i>
      </template>

      <UserTerms
        :item="termsAndConditions"
        :canAcceptOrReject="false"
      ></UserTerms>
    </b-modal>

    <b-modal
      id="createTermsAndConditions"
      title="Terms and Conditions"
      size="xl"
      hide-footer
      hide-header-close
      class="terms"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Terms and Conditions </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createTermsAndConditions')"
        ></i>
      </template>

      <CreateTerms @created="refreshData" />
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import UserTerms from '@/components/user-terms/Main';
import CreateTerms from './Create.vue';
import helpers from '@/mixins/helpers';

export default {
  name: 'TermsAndConditionsList',

  components: {
    CustomTable,
    UserTerms,
    CreateTerms
  },

  mixins: [helpers],

  data() {
    return {
      editTemplate: {},
      fields: [
        {
          key: 'id',
          label: 'Version',
          formatter: (value, key, item) => {
            return `Version ${this.iriToId(item['@id'])}`;
          },
          sortable: true
        },
        { key: 'title', label: 'Title', sortable: true },
        { key: 'actions', label: 'Actions', sortable: true }
      ],
      termsAndConditions: null,
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    request() {
      let request = '/terms-and-conditions?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'termsAndConditions/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    showTerms(item) {
      this.termsAndConditions = item;

      this.$bvModal.show('userTerms');
    }
  }
};
</script>
