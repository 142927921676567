<template>
  <div class="user">
    <span
      v-for="(role, idx) in roles"
      :key="idx"
      class="badge badge-secondary w-auto user__roles"
    >
      <span v-if="role !== 'ROLE_USER'">
        {{ parseRoleName(role) }}
      </span>
    </span>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';

export default {
  name: 'Roles',

  mixins: [helpers],

  props: {
    roles: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
.user__roles {
  margin-left: 5px;
}
</style>
