<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="relationship"
      @changePage="changePage"
      showSearchInput
      @changeFilter="changeFilter"
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Edit"
        >
          <i class="fas fa-eye text-info"></i>
        </a>
        <a
          href="#"
          @click.prevent="del(data.item)"
          v-if="!isDashboard"
          v-b-tooltip.hover
          title="Delete"
        >
          <img src="@/assets/img/icons/Unlink.svg" class="unlink-icon" />
        </a>
        <router-link to="/relationships" v-else>
          <i class="fa fa-eye text-info"></i>
        </router-link>
      </template>
      <template #buttons>
        <b-button
          @click="$bvModal.show('createRelationship')"
          variant="primary"
        >
          <i class="fas fa-plus"></i>
          Add Relation
        </b-button>
      </template>
    </CustomTable>

    <b-modal id="createRelationship" title="Add Relation" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Relation</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createRelationship')"
        ></i>
      </template>

      <RelationCreate
        @created="refreshData"
        :is-creating-sub-contractor="true"
        :skip-company-admin="false"
      ></RelationCreate>
    </b-modal>

    <b-modal id="showRelation" title="Edit Company" hide-footer size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title">
            Details
            <span class="custom-modal__popup__title--blue">
              {{ editItem.related.name }}</span
            >
          </span>
        </div>

        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('showRelation')"
        ></i>
      </template>
      <RelationshipUpdate :relation="editItem"></RelationshipUpdate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import RelationCreate from '../Create';
import RelationshipUpdate from '@/components/dashboard/relationship/subcontractors/Update';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    CustomTable,
    RelationCreate,
    RelationshipUpdate
  },

  props: {
    isDashboard: Boolean
  },

  data() {
    return {
      deleting: {},
      fields: [
        { key: 'related.name', label: 'Name', sortable: true },
        { key: 'related.fiscal_id', label: '', sortable: true },
        {
          key: 'related.address',
          label: 'Address',
          sortable: true,
          formatter: (value, key, item) => {
            if (!value) {
              return '';
            }
            return `${item.related.address.street} ${item.related.address.number}`;
          }
        },
        {
          key: 'related.address',
          label: 'Country',
          sortable: true,
          formatter: (value, key, item) => {
            if (!value) {
              return '';
            }
            return `${item.related.address.country.name}`;
          }
        },
        { key: 'related.email', label: 'Email Address', sortable: true },
        { key: 'related.phone', label: 'Telephone Number', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      editItem: {},
      company: {},
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),

    ...mapFields('relationship/del', {
      deleted: 'deleted'
    }),

    request() {
      let request = `${this.getCompany.entity.company['@id']}/relationships?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&type=SUBCONTRACTOR`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  watch: {
    deleted: function (deleted) {
      if (!deleted) {
        return false;
      }
      this.refreshData();
    }
  },

  mounted() {
    this.refreshData();

    this.$root.$on('relationship-refresh', this.refreshData);
    if (this.isDashboard) {
      this.fields = [
        { key: 'related.name', label: 'Name', sortable: true },
        {
          key: 'related.address',
          label: 'Address',
          sortable: true,
          formatter: (value, key, item) => {
            if (!value) {
              return '';
            }
            return `${item.related.address.street} ${item.related.address.number}`;
          }
        },
        { key: 'related.email', label: 'E-mail', sortable: true },
        { key: 'action', label: 'Action', sortable: false }
      ];
    }
  },

  methods: {
    ...mapActions({
      getPage: 'relationship/list/default',
      deleteItem: 'relationship/del/del'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.company.name = item.related.name;
      this.editItem = item;
      this.$bvModal.show('showRelation');
    },

    del(item) {
      SwalAlert({
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'danger',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning'
          },
          confirm: {
            label: 'Yes, go ahead',
            type: 'success',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.deleteItem({ '@id': itemId });
            }
          }
        }
      });
    },

    deleteIsLoading(item) {
      return this.deleting.hasOwnProperty(item['@id']);
    }
  }
};
</script>
<style scoped lang="scss">
.unlink-icon {
  filter: invert(94%) sepia(10%) saturate(143%) hue-rotate(170deg)
    brightness(87%) contrast(97%);
  width: 1em;

  &:hover {
    filter: invert(54%) sepia(60%) saturate(3974%) hue-rotate(180deg)
      brightness(99%) contrast(87%);
  }
}
</style>
