import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import { AUTH_LOGOUT, IMPERSONATE_LOGOUT } from '../store/modules/auth/actions';

// -------------- Layouts --------------
import LoginLayout from '../layouts/Login';
import RecoverLayout from '../layouts/Recover';
import RegisterLayout from '../layouts/Register';
import DashboardLayout from '../layouts/Dashboard';

// -------------- Pages --------------
import LoginView from '../views/login/Login';
import RecoverView from '../views/recover/Recover';
import NewPasswordView from '../views/recover/NewPassword';
import RegisterView from '../views/register/Register';
import WelcomeView from '../views/welcome/Main';
import SetupView from '../views/login/Setup';
import RoleView from '../views/login/Role';

import Home from '../views/Home';

// -------------- Routes --------------
import AdminRoutes from './admin';
import DashboardRoutes from './dashboard';
import Chat from '../views/Chat';

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: LoginLayout,
      children: [
        // -------------- Login procedures (guest user) --------------
        { name: 'Login', path: '/login', component: LoginView },
        { path: '/setup', component: SetupView },
        // -------------- Define Role and Company (authed user)  --------------
        { path: '/role', component: RoleView },
        { path: '/logout' }
      ]
    },
    {
      path: '/recover',
      component: RecoverLayout,
      children: [{ name: 'Recover', path: '/recover', component: RecoverView }]
    },
    {
      path: '/signup/validation*',
      component: RecoverLayout,
      children: [
        {
          name: 'NewPassword',
          path: '/signup/validation*',
          component: NewPasswordView
        }
      ]
    },
    {
      path: '/register/success',
      component: RegisterLayout,
      children: [
        { name: 'Register', path: '/register/success', component: RegisterView }
      ]
    },
    {
      path: '/welcome',
      component: RegisterLayout,
      children: [{ name: 'Welcome', path: '/welcome', component: WelcomeView }]
    },
    {
      path: '/dashboard',
      component: DashboardLayout,
      children: [
        { path: '/dashboard', component: Home },
        { path: '/chat', component: Chat },
        ...AdminRoutes,
        ...DashboardRoutes
      ]
    },

    // otherwise redirect to home
    { path: '*', redirect: '/dashboard' }
  ]
});

router.beforeEach((to, from, next) => {
  // Logout can always execute
  if (to.path === '/logout') {
    if (store.getters['auth/hasImpersonator']) {
      store.dispatch(`auth/${IMPERSONATE_LOGOUT}`);
    }

    store.dispatch(`auth/${AUTH_LOGOUT}`);

    next('/login');
    return next();
  }

  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = [
    '/login',
    '/setup',
    '/register/success',
    '/recover',
    '/signup/validation'
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = store.getters['auth/isAuthenticated'];

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  // Check if we need to force role select
  if (loggedIn) {
    const loggedRole = store.getters['auth/getRole'];
    const loggedCompany = store.getters['auth/getCompany'];
    if (to.path !== '/role' && (!loggedRole || !loggedCompany)) {
      return next('/role');
    }

    // Check if we must go back to wizard or force out of welcome
    const isWelcomed = store.getters['auth/isWelcomed'];
    if (!isWelcomed && to.path !== '/welcome') {
      return next('/welcome');
    } else if (isWelcomed && to.path === '/welcome') {
      return next('/');
    }
  }

  next();
});
