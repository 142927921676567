<template>
  <div>
    <b-button class="float-right mt-3" :variant="color" :disabled="disabled">
      {{ content.title }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'Button',

  props: {
    content: {
      type: Object,
      required: true
    },

    disabled: {
      type: Boolean,
      default: false
    },

    color: {
      type: String,
      required: true
    }
  }
};
</script>
