<template>
  <b-form class="mt-3" @submit.prevent="onSendForm">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Email Address" label-for="email">
          <b-form-input
            id="email"
            v-model="currentData.email"
            type="text"
            readonly
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <b-form-group label="First Name" label-for="first_name">
          <b-form-input
            id="first_name"
            v-model="currentData.firstName"
            type="text"
          >
          </b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group label="Last Name" label-for="last_name">
          <b-form-input
            id="last_name"
            v-model="currentData.lastName"
            type="text"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Nickname" label-for="nickname">
          <b-form-input
            id="nickname"
            v-model="currentData.nickname"
            type="text"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label="Phone Number" label-for="phone_number">
          <b-form-input
            id="phone_number"
            v-model="currentData.phoneNumber"
            type="tel"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <div class="float-right mt-5">
      <b-button
        type="button"
        variant="secondary mr-2"
        :disabled="isLoading"
        @click="$bvModal.hide('editUser')"
        >Cancel</b-button
      >
      <b-button type="submit" variant="primary" v-if="!isLoading"
        >Save</b-button
      >
      <b-button type="submit" variant="primary" v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </div>
  </b-form>
</template>

<script>
export default {
  name: 'General',

  props: {
    user: {
      type: Object,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    changedFormData() {
      return Object.keys(this.currentData).reduce((formData, field) => {
        if (this.currentData[field] !== this.originalData[field]) {
          formData[field] = this.currentData[field];
        }

        return formData;
      }, {});
    }
  },

  data() {
    return {
      currentData: { ...this.user },
      originalData: { ...this.user }
    };
  },

  methods: {
    onSendForm() {
      this.handleSubmit(this.changedFormData, 'user');
    }
  }
};
</script>
