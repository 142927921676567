<template>
  <b-form id="formProposal">
    <h2 class="page-title mt-2 mb-4">
      <b-icon
      class="border rounded mr-2"
      icon="calendar"
      variant="primary"
      ></b-icon>
      <strong
      >Schedule <span class="text-primary">{{ item.name }}</span></strong
      >
    </h2>

    <b-form-group
    v-if="item.customer"
    content-cols="4"
    label="Proposal/Program for"
    label-cols="2"
    label-for="customer"
    label-size="sm"
    >
      <b-form-input
      v-model="item.customer.name"
      :readonly="true"
      class="bgGroup"
      >
      </b-form-input>
    </b-form-group>

    <!--    <b-form-row v-if='showDetails'>-->
    <!--      <b-col cols='6'>-->
    <!--        <b-form-group-->
    <!--        label-cols='5'-->
    <!--        content-cols='7'-->
    <!--        label='Theme goal'-->
    <!--        label-size='sm'-->
    <!--        >-->
    <!--          <b-form-input-->
    <!--          v-model='item.theme.goal'-->
    <!--          :readonly='true'-->
    <!--          class='bgGroup'>-->
    <!--          </b-form-input>-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->

    <!--      <b-col cols='6'>-->
    <!--        <b-form-group-->
    <!--        label-cols='5'-->
    <!--        content-cols='7'-->
    <!--        label='Learning goal details'-->
    <!--        label-size='sm'-->
    <!--        >-->
    <!--          <b-form-input-->
    <!--          v-model='item.goal'-->
    <!--          :readonly='true'-->
    <!--          class='bgGroup'>-->
    <!--          </b-form-input>-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->

    <!--      <b-col cols='6'>-->
    <!--        <b-form-group-->
    <!--        label-cols='5'-->
    <!--        content-cols='7'-->
    <!--        label='Learning goal details'-->
    <!--        label-size='sm'-->
    <!--        >-->
    <!--          <b-form-input-->
    <!--          v-model='item.goal'-->
    <!--          :readonly='true'-->
    <!--          class='bgGroup'>-->
    <!--          </b-form-input>-->
    <!--        </b-form-group>-->
    <!--      </b-col>-->
    <!--    </b-form-row>-->
    <b-form-row class="bgGroup p-3 text-center">
      <b-col cols="4" md="2">
        <h5 class="font-weight-bolder">Groups:</h5>
        <h2 class="font-weight-bolder">
          <span class="number text-danger">{{ getGroupsAmount }}</span>
        </h2>
        <a v-b-modal.createGroup class="text-black-50" href="#"
        >Add
          <b-icon class="border rounded" icon="plus"></b-icon>
        </a>
      </b-col>
      <b-col cols="4" md="2">
        <h5 class="font-weight-bolder">Learners:</h5>
        <h2 class="font-weight-bolder">
          <span class="number text-danger">{{ getLearnersAmount }}</span
          ><span v-if="item.maxLearners">/{{ item.maxLearners }}</span>
        </h2>
        <!--        <a href='#' v-b-modal.createGroup class='text-black-50'>Add-->
        <!--          <b-icon icon='plus' class='border rounded'></b-icon>-->
        <!--        </a>-->
      </b-col>
      <b-col cols="4" md="2">
        <h5 class="font-weight-bolder">Teachers:</h5>
        <h2 class="font-weight-bolder">
          <span class="number text-danger">{{ getTeachersAmount }}</span>
        </h2>
      </b-col>
      <b-col cols="4" md="2">
        <h5 class="font-weight-bolder">Modules:</h5>
        <h2 class="font-weight-bolder">
          <span class="number text-danger">{{ item.modules.length }}</span>
        </h2>
      </b-col>
      <b-col cols="4" md="2">
        <h5 class="font-weight-bolder">Activities:</h5>
        <h2 class="font-weight-bolder">
          <span class="number text-danger">{{ getActivitiesAmount }}</span>
        </h2>
      </b-col>
      <b-col cols="4" md="2">
        <h5 class="font-weight-bolder">Learning Materials:</h5>
        <h2 class="font-weight-bolder">
          <span class="number text-danger">0</span>
        </h2>
      </b-col>
    </b-form-row>

    <h2 class="page-title mt-5 mb-4">
      <b-icon class="mr-2" icon="person-plus-fill" variant="primary"></b-icon>
      <strong>Groups & Learners</strong>
    </h2>
    <GroupList ref="groups" :program="item" @deleted="delGroup"></GroupList>

    <h2 class="page-title mt-5 mb-4">
      <b-icon class="mr-2" icon="calendar3" variant="primary"></b-icon>
      <strong>Program structure, modules & activities</strong>
    </h2>
    <ModuleList ref="modules" :groups="getGroups" :program="item"></ModuleList>

    <div class="form-row mt-5">
      <div class="col-sm-12">
        <b-button
        v-if="!isLoading"
        id="tooltip-target-1"
        class="float-right"
        variant="primary"
        @click.prevent="handleNext"
        >Start Program
        </b-button>

        <b-button v-else class="float-right" type="submit">
          <b-spinner small></b-spinner>
        </b-button>

        <!--          <button class="btn btn-primary float-right mr-1">Previous</button>-->

        <b-button
        v-if="!isLoading"
        class="float-right mr-2"
        variant="secondary"
        @click="cancel"
        >
          Cancel
        </b-button>
      </div>
    </div>

    <!--- end buttons --->

    <!-- Schedule modal -->
    <!--    <b-modal id='editSchedule' title='Schedule' hide-footer>-->
    <!--      <template #modal-header>-->
    <!--        <div class='custom-modal__popup'>-->
    <!--          <span class='custom-modal__popup__title'>Schedule</span>-->
    <!--        </div>-->
    <!--        <i-->
    <!--        class='fa fa-times custom-modal__popup__close'-->
    <!--        @click="$bvModal.hide('editSchedule')"-->
    <!--        ></i>-->
    <!--      </template>-->
    <!--      <ScheduleGroup :item='scheduleEdit' :disabled='true'/>-->
    <!--    </b-modal>-->
    <!-- Add Group modal -->
    <b-modal id="createGroup" hide-footer title="Add Group">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Group</span>
        </div>
        <i
        class="fa fa-times custom-modal__popup__close"
        @click="$bvModal.hide('createGroup')"
        ></i>
      </template>

      <GroupCreate :program-id="item['@id']" @created="addGroup"></GroupCreate>
    </b-modal>
    <!--    &lt;!&ndash; Add Subcontractor modal &ndash;&gt;-->
    <!--    <b-modal id='createSubcontractor' title='Add Subcontractor' hide-footer>-->
    <!--      <template #modal-header>-->
    <!--        <div class='custom-modal__popup'>-->
    <!--          <span class='custom-modal__popup__title'>Add Subcontractor</span>-->
    <!--        </div>-->
    <!--        <i-->
    <!--        class='fa fa-times custom-modal__popup__close'-->
    <!--        @click="$bvModal.hide('createSubcontractor')"-->
    <!--        ></i>-->
    <!--      </template>-->

    <!--      <SubcontractorCreate-->
    <!--      :companyItems='companyItems'-->
    <!--      :program-id="item['@id']"-->
    <!--      @created="addTeacher('subcontractor')"-->
    <!--      ></SubcontractorCreate>-->
    <!--    </b-modal>-->
  </b-form>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import {find, get, isUndefined} from 'lodash';

import GroupList from '../group/List.vue';
import GroupCreate from '../group/Create.vue';
import TeacherList from '../teacher/List.vue';
import SubcontractorCreate from '../subcontractor/Create.vue';
import ModuleList from './Module/List.vue';

import {businessLogicService} from '@/utils/fetch';
import {SwalAlert} from '@/utils/swal';
import ScheduleGroup from './groups/Schedule.vue';

export default {
  components: {
    GroupCreate,
    ModuleList,
    GroupList
    // ScheduleGroup,
  },

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {
      }
    },

    initialValues: {
      type: Object,
      default: () => {
      }
    }
  },

  data() {
    return {
      showDetails: false, // ui control
      isLoading: false,
      isMounted: false,
      formMetadata: {
        generalCosts: 0
      },
      id: null,
      groups: 0
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),

    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    },

    getGroups() {
      if (!this.isMounted) {
        return [];
      }

      return this.$refs.groups.getGroups;
    },

    getGroupsAmount() {
      return this.groups;
    },

    getLearnersAmount() {
      if (!this.isMounted) {
        return 0;
      }

      return this.$refs.groups.getLearners;
    },

    getTeachersAmount() {
      if (!this.isMounted) {
        return 0;
      }

      return this.$refs.modules.getTeachersAmount;
    },

    getActivitiesAmount() {
      if (!this.isMounted) {
        return 0;
      }

      return this.$refs.modules.getTeachersAmount;
    },

    canAddTeacher() {
      if (!this.isMounted) {
        return 0;
      }

      return 0;
      // return this.$refs.groups.numberGroups > 0 || false;
    },

    canAddSubcontractor() {
      if (!this.isMounted) {
        return 0;
      }

      return false;

      // return this.$refs.groups.numberGroups > 0 && this.companyItems.length > 0;
    }
  },

  created() {
    // If item is not PENDING or SCHEDULING or ACCEPTED, please move away
    if (
    this.item &&
    ['ACCEPTED', 'SCHEDULING', 'PENDING'].indexOf(this.item.status) === -1
    ) {
      this.$router.push({name: 'ScheduleList'});
    }
  },

  mounted() {
    // zz, hacky
    this.$set(this, 'isMounted', true);
    this.$set(this, 'groups', this.item.learnerGroups.length);
  },

  methods: {
    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    delGroup(group) {
      this.groups--;
    },

    addGroup(group) {
      // Refresh data
      this.$refs.groups.refreshData();
      this.groups++;
    },

    addTeacher(type) {
      this.$refs.teachers.refreshData();
    },

    addLearner(learner) {
      // Refresh data
      this.$refs.groups.refreshData();
    },

    cancel() {
      this.$router.push({name: 'ScheduleList'});
    },

    handleNext() {
      this.isLoading = true;
      // Pending goes to execute
      if (this.item['status'] === 'PENDING') {
        return this.handleExecute();
      }

      // @TODO(trepatudo): hacky VERIFIED since PROPOSED is skipped for now, this should be done on server-side

      businessLogicService(this.item['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({statusTransition: 'to_pending'})
      })
      .then((response) => response.json())
      .then((data) => {
        // @TODO(trepatudo): duh, double step again
        if (this.item['status'] === 'PENDING') {
          this.handleExecute();
        } else {
          this.handleSuccess();
        }
      })
      .catch((e) => {
        console.log(e);

        SwalAlert({
          title: 'Error',
          icon: 'error',
          description: e.errors
          ? e.errors._error.replace('\n', '<br>')
          : e.message,
          type: 'danger',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'danger'
            }
          }
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

    handleSuccess() {
      SwalAlert({
        title: 'Program started',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Create another',
            type: 'warning',
            callback: () => {
              this.$router.push({name: 'ProposalCreate'});
            }
          },
          confirm: {
            label: 'OK',
            type: 'success',
            callback: () => {
              this.$router.push({name: 'ExecuteList'});
            }
          },
        }
      });
    },

    handleExecute() {
      this.isLoading = true;
      businessLogicService(this.item['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({statusTransition: 'to_started'})
      })
      .then((response) => response.json())
      .then((data) => {
        this.handleSuccess();
      })
      .catch((e) => {
        SwalAlert({
          title: 'Error',
          icon: 'error',
          description: e.errors
          ? e.errors._error.replace('\n', '<br>')
          : e.message,
          type: 'danger',
          buttons: {
            confirm: {
              label: 'OK',
              type: 'danger'
            }
          }
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
    },

    openEdit(item) {
      this.id = item['@id'];
      this.$bvModal.show('editSchedule');
    }
  }
};
</script>

<style lang="scss" scoped>
.bgGroup {
  background: #f4f5f7;
  border: 2px solid #e3e5ea;
  border-radius: 8px;
}

#formProposal {
  .form-row {
    h3 {
      // font-size: 20px;
      padding-top: 30px;
      padding-bottom: 30px;
    }

    label {
      // font-size: 16px;
      font-weight: 700;
      padding-bottom: 5px;
    }

    input,
    select {
      //position: relative;
      height: 45px;
      border-color: $gray-200;
    }

    .custom-checkbox {
      margin-left: 5px;
    }

    i {
      position: absolute;
      right: 15px;
      bottom: 15px;
      color: $gray-300;
      cursor: pointer;
    }

    a.showLearningmaterials {
      //.at2x('../../img/icons/learningmaterials-grey.png', 14px, 19px);
      //background: url("../../../")
      background-repeat: no-repeat;
      width: 45px;
      height: 45px;
      display: block;
      background-color: $gray-100;
      border-radius: 50%;
      background-position: center center;
    }

    .btn {
      i {
        position: relative;
        right: auto;
        bottom: auto;
        color: $white;
      }
    }

    .bgSummary {
      .summary {
        background-color: $gray-200;
        height: 64px;
        border-radius: 5px;

        .container {
          padding: 20px;
        }

        div {
          float: left;
          font-weight: 700;

          i {
            margin-right: 10px;
            margin-top: 5px;
            right: auto;
            bottom: auto;
            float: left;
            position: relative;
          }
        }
      }
    }
  }

  .materialsUL,
  .activitiesUL {
    padding-left: 0px;

    li {
      margin-top: 10px;
      list-style: none;
      padding: 0.6rem 1rem;
      border: 1px solid $gray-300;
      border-radius: 0.25rem;
      background-color: $gray-200;
      color: $gray-500;

      .deletebutton {
        float: right;

        i {
          position: relative;
          right: auto;
          bottom: auto;
        }
      }
    }
  }

  #collapseAddModule {
    .content {
      background-color: $gray-100;

      padding: 20px;

      border-radius: 5px;
    }
  }

  .viewModules {
    .col-md-6 {
      margin-top: 20px;

      .content {
        border: 1px solid $gray-300;
        padding: 40px;
        border-radius: 5px;

        h3 {
          padding-top: 0px;
          padding-bottom: 0px;
        }

        .learningMaterials,
        .learningActivity {
          ul {
            margin-top: 20px;
            min-height: 30px;
            background-image: url('../../../../assets/img/icons/learningModules.png');
            background-repeat: no-repeat;
            background-position: left top 10px;

            li {
              margin-left: 20px;
              // font-size: 14px;

              span {
                font-style: italic;
                opacity: 0.8;
              }
            }
          }
        }

        .learningMaterials {
          ul {
            background-image: url('../../../../assets/img/icons/learningMaterials.png');
          }
        }
      }

      .actions {
        padding-top: 20px;

        a {
          .far {
            // font-size: 20px;
            color: $gray-300;
            position: relative;
            right: auto;
            bottom: auto;
          }

          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .audit {
    .custom-checkbox {
      margin-left: 0px;

      .custom-control-label {
        // font-size: 14px;
      }
    }
  }
}
</style>
