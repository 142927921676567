import RelationshipCustomersList from '@/components/dashboard/relationship/customers/List';
import RelationshipImportedCustomersList from '@/components/dashboard/relationship/customers/import/List';
import RelationshipSubcontractorsList from '@/components/dashboard/relationship/subcontractors/List';

import RelationshipCreate from '@/components/dashboard/relationship/Create';

export default [
  {
    name: 'RelationshipCustomersList',
    path: '/relationships/beneficiaries/overview',
    component: RelationshipCustomersList
  },
  {
    name: 'RelationshipSubcreatorsList',
    path: '/relationships/subcreators/overview',
    component: RelationshipSubcontractorsList
  },
  {
    name: 'RelationshipCreate',
    path: '/relationships/create',
    component: RelationshipCreate
  },
  {
    name: 'RelationshipImportedCustomersList',
    path: '/relationships/beneficiaries/import/overview',
    component: RelationshipImportedCustomersList
  }
];
