export const TRANSACTIONS_LIST_RESET = 'TRANSACTIONS_LIST_RESET';
export const TRANSACTIONS_LIST_SET_ITEMS = 'TRANSACTIONS_LIST_SET_ITEMS';
export const TRANSACTIONS_LIST_SET_ERROR = 'TRANSACTIONS_LIST_SET_ERROR';
export const TRANSACTIONS_LIST_SET_SELECT_ITEMS =
  'TRANSACTIONS_LIST_SET_SELECT_ITEMS';
export const TRANSACTIONS_LIST_SET_VIEW = 'TRANSACTIONS_LIST_SET_VIEW';
export const TRANSACTIONS_LIST_TOGGLE_LOADING =
  'TRANSACTIONS_LIST_TOGGLE_LOADING';
export const TRANSACTIONS_LIST_SET_TOTAL_ITEMS =
  'TRANSACTIONS_LIST_TOTAL_ITEMS';
