import list from './list';
import create from './create';
import update from './update';

export default {
  namespaced: true,
  modules: {
    list,
    create,
    update
  }
};
