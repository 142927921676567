<template>
  <b-container id="register-page" fluid class="vh-100">
    <b-row>
      <b-col md="7">
        <router-link to="/login" class="logo"></router-link>
        <router-view></router-view>
      </b-col>
      <b-col md="5"></b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'RegisterLayout',
  props: {
    msg: String
  }
};
</script>

<style scoped lang="scss">
#register-page {
  background-color: $secondary;
  background-image: url('../assets/img/bg.jpg');
  background-position: top right;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: $success;
}

a.logo {
  margin-top: 25%;
  height: 75px;
  display: block;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-image: url('../assets/img/logo.png');
}

.btn,
button {
  border-radius: 5px;
  height: 45px;
  //line-height: 45px;
  padding: 0 20px;
  line-height: 45px;
  //font-size: 16px;
  border: 0 solid;
  font-weight: 400;
  /* font-family: $default-font-family; */
  color: $white;

  i {
    padding-right: 20px;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-group {
  min-width: 140px;

  .dropdown-toggle {
    &:after {
      margin-left: 15px;
    }

    &:focus {
      box-shadow: none !important;
    }

    &[aria-expanded='true'] {
      border-radius: 5px 0 0 5px;
    }
  }

  .dropdown-menu {
    margin-top: 0px;
    border: 0px solid;
    background-color: $secondary;
    min-width: 140px;
    border-radius: 0 5px 5px 0;
    cursor: pointer;

    &:hover {
      background-color: $primary;
    }

    a {
      text-decoration: none;
      color: $white;
      padding-left: 20px;
    }
  }
}
</style>
