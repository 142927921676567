import { businessLogicService } from '@/utils/fetch';
import Swal from 'sweetalert2';
import { SwalAlert } from '@/utils/swal';
import { mapGetters } from 'vuex';

const programs = {
  computed: {
    ...mapGetters('auth', ['isRole'])
  },

  data() {
    return {
      reasonToCancel: null
    };
  },

  methods: {
    async proposalAcceptOrReject(id, type) {
      if (this.isRole('ROLE_PROVIDER') && type !== 'to_accepted') {
        const { value: reasonToCancel } = await Swal.fire({
          input: 'textarea',
          inputLabel: 'Reason to cancel program',
          inputPlaceholder: 'Type your reason here...',
          inputAttributes: {
            'aria-label': 'Type your message here'
          },
          showCancelButton: true,
          onOpen: () => {
            Swal.getConfirmButton().disabled = true;

            const input = Swal.getInput();
            input.oninput = () => {
              if (input) {
                Swal.getConfirmButton().disabled = false;
              }
            };
          }
        });

        if (reasonToCancel) {
          this.reasonToCancel = reasonToCancel;
          SwalAlert({
            subTitle: 'Are you sure?',
            description: 'This action will be definite',
            icon: 'delete',
            type: 'warning',
            buttons: {
              cancel: {
                label: 'Cancel',
                type: 'warning'
              },
              confirm: {
                label: 'Confirm',
                type: 'success',
                callback: () => {
                  this.sendRequest(type, id);
                }
              }
            }
          });
        }
      } else {
        this.sendRequest(type, id);
      }
    },

    sendRequest(type, id) {
      this.isLoading = true;
      businessLogicService(id, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({
          statusTransition: type,
          reasonToCancel: this.reasonToCancel
        })
      })
        .then((response) => response.json())
        .then((data) => {
          this.handleResult(type);
        })
        .catch((e) => {
          this.isLoading = false;
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: e.errors._error,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        });
    },

    handleResult(type) {
      this.isLoading = false;
      if (type === 'to_customer_rejected' || type === 'to_cancelled') {
        SwalAlert({
          title: 'Success',
          icon: 'user',
          type: 'success'
        }).then(() => {
          this.$router.push({ name: 'CancelledList' });
        });
      } else {
        SwalAlert({
          title: 'Success',
          icon: 'user',
          description: 'Your request was rejected!',
          type: 'success'
        }).then(() => {
          this.$router.push({ name: 'ScheduleList' });
        });
      }
    }
  }
};

export default programs;
