<template>
  <b-container fluid>
    <h6>
      <i class="fa fa-user-plus text-primary"></i>
      <strong class="ml-2">{{ title }}</strong>
    </h6>
    <hr />
    <b-form>
      <b-container id="role_switcher">
        <div v-if="showError" class="alert alert-danger" role="alert">
          <span class="fa fa-exclamation-triangle" aria-hidden="true">
            Role is required
          </span>
        </div>

        <b-card-group v-if="!canCreateUserIsLoading">
          <b-card
            class="card-button"
            v-for="(role, index) in roles"
            :key="index.type"
            @click.prevent="doRolePick(role.type)"
            :class="{ 'card-button__selected': form.role === role.type }"
          >
            <i class="fa fa-building"></i>
            <br />
            <span>{{ role.label }}</span>
            <p class="card-button__description" v-html="role.description"></p>
          </b-card>
        </b-card-group>
      </b-container>
    </b-form>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { entityService } from '@/utils/fetch';

export default {
  props: {
    title: {
      type: String,
      default: 'Onboarding | Choose your Role*'
    },

    isRoleRequest: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters('auth', ['isRole'])
  },

  data() {
    return {
      isLoading: false,
      showError: false,
      form: {
        role: ''
      },
      roles: [
        {
          type: 'Learner',
          label: 'Learner',
          description:
            'You learn to work smarter through vocational education. You work for an organization that finances your education or training. Or you finance your education yourself.'
        },
        {
          type: 'Accountant',
          label: 'Accountant',
          description:
            'You are an accountant of a Creator+. You teach your Creator+ to work compliantly.'
        },
        {
          type: 'Provider',
          label: 'Creator',
          description:
            'You provide L&D and/or tailored Instructional+Material (input) with which the Learner+ learns to work smarter.'
        }
      ],
      allowRegistration: null,
      canCreateUserIsLoading: false
    };
  },

  mounted() {
    this.getCountries();
    this.canCreateUser();

    if (this.isRoleRequest && this.isRole('ROLE_USER')) {
      this.roles.shift();
    }
  },

  methods: {
    ...mapActions({
      getCountries: 'country/list/default'
    }),

    doRolePick(role) {
      this.form.role = role;
    },

    nextStep() {
      if (this.form.role) {
        this.$emit('next', this.form.role);
      }

      this.showError = true;
    },

    async canCreateUser() {
      this.canCreateUserIsLoading = true;

      const response = await entityService('/can-create-user');
      this.allowRegistration = await response.json();

      this.canCreateUserIsLoading = false;
    }
  },

  watch: {
    form: {
      handler() {
        this.nextStep();
      },
      deep: true
    },

    allowRegistration: function (newVal) {
      if (!newVal) {
        this.roles.pop();
      }
    }
  }
};
</script>
<style lang="scss">
#role_switcher {
  .card-button {
    text-align: center;
    margin: 5px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.125);
    opacity: 0.8;
    filter: grayscale(1);
    transition: 0.2s all ease-in;

    &__selected {
      opacity: 1;
      color: $primary;
      border: 1px solid $primary;
      filter: grayscale(0) !important;
    }

    &__description {
      // font-size: 12px;
      color: $info;
      margin-top: 8px;
    }

    &:hover {
      opacity: 1;
      color: $primary;
      border: 1px solid $primary;
      filter: grayscale(0) !important;
    }

    // don't show cursor
    &.active {
      cursor: default !important;
    }
  }

  .card-button i {
    color: $primary;
  }

  .role-dropdown {
    margin-top: 20px;
    text-align: center;
  }

  .role-dropdown select {
    width: 50%;
  }

  .button-container {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
