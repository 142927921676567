<template>
  <b-form @submit.prevent="onSendForm">
    <b-form-row>
      <b-col>
        <b-form-group
          label="Street *"
          label-for="address_street"
          class="font-weight-bold"
          :invalid-feedback="violations.street"
          :state="!isValid('street')"
        >
          <b-form-input
            required
            id="address_street"
            v-model="item.street"
            type="text"
            class="form-input"
            :disabled="isDisabled"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Number *"
          label-for="address_number"
          class="font-weight-bold"
          :invalid-feedback="violations.number"
          :state="!isValid('number')"
        >
          <b-form-input
            required
            id="address_number"
            v-model="item.number"
            type="text"
            class="form-input"
            :disabled="isDisabled"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group
          label="Postal Code *"
          label-for="address_zip"
          class="font-weight-bold"
          :invalid-feedback="violations.zip"
          :state="!isValid('zip')"
        >
          <b-form-input
            required
            id="address_zip"
            v-model="item.zip"
            type="text"
            class="form-input"
            :disabled="isDisabled"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="City *"
          label-for="address_city"
          class="font-weight-bold"
          :invalid-feedback="violations.city"
          :state="!isValid('city')"
        >
          <b-form-input
            required
            id="address_city"
            v-model="item.city"
            type="text"
            placeholder=""
            class="form-input"
            :disabled="isDisabled"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col>
        <b-form-group
          label="State *"
          label-for="address_state"
          class="font-weight-bold"
          :invalid-feedback="violations.state"
          :state="!isValid('state')"
        >
          <b-form-input
            required
            id="address_state"
            v-model="item.state"
            type="text"
            placeholder=""
            class="form-input"
            :disabled="isDisabled"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Country *"
          label-for="address_country"
          class="font-weight-bold"
          :invalid-feedback="violations.country"
          :state="!isValid('country')"
        >
          <b-form-select
            required
            v-model="item.country"
            id="address_country"
            class="form-input"
            :options="countrySelectItems"
            text-field="name"
            value-field="@id"
            :disabled="isDisabled"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-group v-if="showNextButton && !isDisabled" class="float-right">
      <b-button
        class="mr-2"
        variant="secondary"
        type="button"
        :disabled="isLoading"
        @click="handlePrevious"
        v-if="showPrevious"
        >Previous</b-button
      >
      <b-button
        type="submit"
        :variant="skipLastStep ? 'primary' : 'success'"
        v-if="!isLoading"
      >
        {{ skipLastStep ? 'Submit' : 'Next' }}</b-button
      >
      <b-button type="button" variant="success" disabled v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>

    <b-form-group class="float-right" v-else>
      <b-button type="button" variant="secondary mr-2" @click="closeModal"
        >Close</b-button
      >
    </b-form-group>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },

    showSubmit: {
      type: Boolean,
      default: () => {
        return true;
      }
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    },

    showNextButton: {
      type: Boolean,
      default: true,
      required: false
    },

    skipLastStep: {
      type: Boolean,
      default: false
    },

    showPrevious: {
      type: Boolean,
      default: true
    },

    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      countryDisabled: false
    };
  },

  mounted() {
    this.countryGetSelectItems();

    if (this.item.country) {
      this.item.country = this.item.country['@id'];
    }

    if (localStorage.getItem('country')) {
      const country = JSON.parse(localStorage.getItem('country'));
      this.item.country = country['@id'];

      localStorage.removeItem('country');
      this.countryDisabled = true;
    }
  },

  computed: {
    ...mapFields('country/list', {
      countrySelectItems: 'selectItems'
    }),

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values || {};
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({
      countryGetSelectItems: 'country/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    handlePrevious() {
      this.$emit('handlePrevious');
    },

    onSendForm() {
      this.handleSubmit(this.item);
    },

    closeModal() {
      this.$bvModal.hide('editCompany');
      this.$bvModal.hide('showRelation');
    }
  }
};
</script>
