<template>
  <div>
    <b-container fluid>
      <b-row class="mt-5">
        <b-col cols="12">
          <b-nav tabs>
            <b-nav-item class="mr-2" active-class="active" to="/requests/user">
              <div class="template__tab">
                <h5>User</h5>
              </div>
            </b-nav-item>
            <b-nav-item
              class="mr-2"
              active-class="active"
              to="/requests/company"
            >
              <div class="template__tab">
                <h5>Company</h5>
              </div>
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="bgContent">
            <router-view></router-view>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Request'
};
</script>

<style scoped lang="scss">
.template {
  &__tab {
    h5 {
      //font-size: 16px;
    }
  }
}

.nav-tabs .nav-link.active {
  box-shadow: 1px 3px 10px $gray-400;
}

.nav-tabs .nav-link {
  box-shadow: 1px 3px 10px $gray-300;
}

.nav-link {
  padding: 10px 20px;
}

.nav-tabs {
  border-bottom: 0;
  margin-left: -1px;
}
</style>
