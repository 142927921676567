import ExecuteList from '@/components/dashboard/program/execute/List.vue';
import ExecuteUpdate from '@/components/dashboard/program/execute/Update.vue';

import Default from '@/views/dashboard/Programs/Default';

export default [
  {
    path: '/programs/execute',
    component: Default,
    children: [
      {
        name: 'ExecuteList',
        path: '/programs/execute/',
        component: ExecuteList
      },
      {
        name: 'ExecuteUpdate',
        path: '/programs/execute/:id/edit',
        component: ExecuteUpdate
      }
    ]
  }
];
