<template>
  <b-form-row>
    <b-col cols="4" v-for="(group, idx) in groups" :key="group['@id']">
      <b-card
        border-variant="dark"
        align="left"
        style="background: #e9ebef !important"
      >
        <b-card-title>
          <span class="mr-2" v-if="!scheduleIsLoading">
            <b-icon
              v-if="!hasSchedule(group['@id'])"
              icon="check-circle-fill"
              variant="warning"
            ></b-icon>
            <b-icon v-else icon="check-circle-fill" variant="success"></b-icon>
          </span>
          <strong>Group {{ idx + 1 }}: {{ group.name }} </strong>
        </b-card-title>
        <b-card-text>
          <div v-if="scheduleIsLoading">
            <b-spinner size="lg"></b-spinner>
          </div>
          <div v-else>
            <div class="activity-data">
              <p class="font-weight-normal activity-data">
                <span class="spacing">Date: </span>

                <a
                  v-if="!hasSchedule(group['@id'])"
                  href="#"
                  @click.prevent="setSchedule(group)"
                  ><i>Click here to schedule</i>
                  <b-icon icon="calendar"></b-icon>
                </a>
                <span v-else>
                  {{ parseDate(getScheduleData(group['@id']).startDateTime) }}
                  <a href="#" @click.prevent="setSchedule(group)"
                    ><b-icon icon="pencil"></b-icon
                  ></a>
                </span>
                <br />
                <span class="spacing">Learners: </span>
                <span v-if="page === 'EXECUTE'">
                  <span
                    v-if="
                      hasSchedule(group['@id']) &&
                      getScheduleData(group['@id']).learners.length > 0
                    "
                  >
                    <span
                      v-for="learner in getScheduleData(group['@id']).learners"
                      :key="learner['@id']"
                    >
                      <b-icon
                        v-if="learner.status === 'INVITED'"
                        icon="clock"
                        variant="warning"
                      ></b-icon>
                      <b-icon
                        v-else-if="learner.status === 'ATTENDED'"
                        icon="check-circle-fill"
                        variant="success"
                      ></b-icon>
                      <b-icon
                        v-else
                        icon="check-circle-fill"
                        variant="warning"
                      ></b-icon>
                      &nbsp; {{ learner.learner }} <br />
                    </span>
                  </span>
                  <span v-else>
                    <i>No learners yet</i>
                  </span>
                </span>
                <span v-else>
                  {{ group.learners.length }}
                </span>
              </p>
            </div>
          </div>

          <!--          <b-row v-if="page === 'EXECUTE' && getScheduleData(activity['@id'], group).status === 'PENDING'">-->
          <!--            <b-col cols='6'>-->
          <!--              <b-button-->
          <!--              v-if="!getScheduleData(activity['@id'], group).loadingExecute"-->
          <!--              block-->
          <!--              size='sm'-->
          <!--              variant='primary'-->
          <!--              @click="executeSchedule(getScheduleData(activity['@id'], group))"-->
          <!--              ><i class='fa fa-check'></i> Execute-->
          <!--              </b-button>-->
          <!--              <b-button v-else block disabled variant='primary'>-->
          <!--                <b-spinner small></b-spinner>-->
          <!--              </b-button>-->

          <!--            </b-col>-->
          <!--            <b-col cols='6'>-->
          <!--              <b-button-->
          <!--              v-if="!getScheduleData(activity['@id'], group).loadingCancel"-->
          <!--              block-->
          <!--              size='sm'-->
          <!--              variant='danger'-->
          <!--              @click="cancelSchedule(getScheduleData(activity['@id'], group))"-->
          <!--              ><i class='fa fa-times'></i> Cancel-->
          <!--              </b-button>-->
          <!--              <b-button v-else block disabled variant='primary'>-->
          <!--                <b-spinner small></b-spinner>-->
          <!--              </b-button>-->
          <!--            </b-col>-->
          <!--          </b-row>-->
        </b-card-text>
      </b-card>
    </b-col>
  </b-form-row>
</template>

<script>
import { businessLogicService } from '@/utils/fetch';
import helpers from '@/mixins/helpers';
import _ from 'lodash';

export default {
  components: {},

  props: {
    activity: {
      type: Object,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    page: {
      type: String,
      required: true
    }
  },

  mixins: [helpers],

  data() {
    return {
      editGroup: null,

      scheduleIsLoading: false,
      isMounted: false,
      formMetadata: {
        generalCosts: 0
      },
      schedules: []
    };
  },

  computed: {},

  created() {
    // Read the schedule data
    this.$on('updatedSchedule', this.syncSchedule);
    this.getSchedules();
  },

  methods: {
    async getSchedules() {
      this.scheduleIsLoading = true;
      const request = await businessLogicService(
        `${this.activity['@id']}/schedules?itemsPerPage=100000`
      );
      const response = await request.json();
      this.schedules = response['hydra:member'];

      this.scheduleIsLoading = false;

      this.schedules = _.flatten(this.schedules);
    },
    hasSchedule(groupId) {
      return !!this.getScheduleData(groupId);
    },
    getCombinationData(groupId, activityId) {},
    getScheduleData(groupId) {
      let filter = this.schedules.filter(
        (s) => s.learnersGroup['@id'] === groupId
      );
      if (filter) {
        return filter[0];
      }
      return null;
    },

    syncSchedule(data) {
      1;
      for (let s in this.schedules) {
        if (this.schedules[s]['@id'] === data['@id']) {
          // Replace it
          this.$set(this.schedules, s, data);
        }
      }
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD');
    },

    edit(item) {
      this.$emit('edit', item);
    },

    del(item) {
      if (window.confirm('Are you sure you want to delete this group?')) {
        this.isDeleting = false;
        businessLogicService(item['@id'], {
          method: 'DELETE'
        }).finally(() => {
          this.isDeleting = false;
          this.getSchedules();
        });
      }
    },

    setSchedule(group) {
      let schedule = this.getScheduleData(group['@id']);
      this.$emit('editSchedule', this.activity, group, schedule);
    }
  }
};
</script>
<style lang="scss">
.activity-data {
  span.spacing {
    display: inline-block;
    width: 100px;
  }
}
</style>
