import { entityService as fetch } from '../../../../utils/fetch';
import * as types from './mutation_types';

export const retrieve = ({ commit }, id) => {
  commit(types.ROLE_SHOW_TOGGLE_LOADING);

  return fetch(id)
    .then((response) => response.json())
    .then((data) => {
      commit(types.ROLE_SHOW_TOGGLE_LOADING);
      commit(types.ROLE_SHOW_SET_RETRIEVED, data);
    })
    .catch((e) => {
      commit(types.ROLE_SHOW_TOGGLE_LOADING);
      commit(types.ROLE_SHOW_SET_ERROR, e.message);
    });
};

export const reset = ({ commit }) => {
  commit(types.ROLE_SHOW_RESET);
};
