<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="user"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:companies="data">
        <span
          class="companies__show"
          v-for="company in data.item.companies"
          :key="company.company['@id']"
          @click="openCompanyModal(company.company)"
        >
          <div class="companies__info">
            <span>{{ company.company.name }}</span>

            <i class="fas fa-user-cog text-info" v-if="company.isAdmin"></i>
          </div>
        </span>

        <b-button
          class="mt-5"
          variant="outline-success"
          size="sm"
          @click="openAddCompanyToUser(data.item)"
          >+ Company
        </b-button>
      </template>

      <template v-slot:impersonation="data">
        <a href="#" @click.prevent="onImpersonateUser(data.item)">
          <i class="fas fa-people-arrows"></i>
        </a>
      </template>

      <template v-slot:roles="data">
        <Roles :roles="data.item.roles" />
      </template>

      <template v-slot:actions="data">
        <a
          href="#"
          class="mr-2"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Edit"
          ><i class="fa fa-edit text-info"></i
        ></a>

        <a
          href="#"
          class="mr-2"
          @click.prevent="showUserCompanies(data.item)"
          v-b-tooltip.hover
          title="User Companies"
        >
          <i class="fas fa-building text-info"></i>
        </a>
        <a
          href="#"
          class="mr-2"
          @click="openAddCompanyToUser(data.item)"
          v-b-tooltip.hover
          title="Add user to company"
        >
          <i class="fas fa-user-plus text-info"></i>
        </a>
      </template>
      <template #buttons>
        <b-button @click="$bvModal.show('createUser')" variant="primary">
          <i class="fas fa-plus"></i>
          Add User
        </b-button>
      </template>
    </CustomTable>

    <b-modal
      @hidden="closeUserCompanyModal"
      id="companyUserCreate"
      title="Add User to Company"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >Add Company to
            <span class="custom-modal__popup__title--blue">{{
              user.name
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('companyUserCreate')"
        ></i>
      </template>

      <CompanyUserCreate
        :hide="['user']"
        :user="popupAddUserToCompanyData"
      ></CompanyUserCreate>
    </b-modal>

    <b-modal id="impersonateUser" title="Impersonate user" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Impersonate User</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('impersonateUser')"
        ></i>
      </template>

      <Impersonate :user="impersonateUser" />
    </b-modal>

    <b-modal id="createUser" title="Add User" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add User</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createUser')"
        ></i>
      </template>

      <UserCreate @created="refreshData"></UserCreate>
    </b-modal>

    <b-modal
      @hidden="refreshData"
      id="editUser"
      title="Edit User"
      size="xl"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/menuicons/user.png" />
          <span class="custom-modal__popup__title"
            >Edit User
            <span class="custom-modal__popup__title--blue">{{
              editUser.email
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('editUser')"
        ></i>
      </template>
      <UserUpdate :user="editUser" @updated="refreshData"></UserUpdate>
    </b-modal>

    <b-modal id="companyShow" title="Company" hide-footer size="xl">
      <CompanyShow :company="showCompany"></CompanyShow>
    </b-modal>

    <b-modal id="userCompanies" title="User Companies" hide-footer size="xl">
      <template #modal-header>
        <div class="custom-modal__popup">
          <img class="mr-2" src="@/assets/img/icons/Icon_Report.png" />
          <span class="custom-modal__popup__title"
            >Companies of
            <span class="custom-modal__popup__title--blue">{{
              editUser.email
            }}</span>
          </span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('userCompanies')"
        ></i>
      </template>
      <div
        v-if="editUser && editUser.companies && editUser.companies.length > 0"
      >
        <div
          class="user-companies"
          v-for="(company, index) in editUser.companies"
          :key="index"
        >
          <p>
            <span class="user-companies__text"
              >{{ company.company.name }}
              <span class="user-companies__vat">
                [{{ company.company.fiscal_id }}]
              </span>
            </span>
          </p>
        </div>
      </div>

      <div v-else>
        <div class="user-companies">
          <p>User is not linked to any company.</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CompanyUserCreate from '@/components/common/companyuser/Create';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Roles from '@/components/_ui/users/Roles';
import { IMPERSONATE_USER } from '@/store/modules/auth/actions';
import Impersonate from './Impersonate';
import UserCreate from './Create';
import UserUpdate from './Update';
import CompanyShow from './Show';

export default {
  components: {
    Impersonate,
    CompanyUserCreate,
    CustomTable,
    Roles,
    UserCreate,
    UserUpdate,
    CompanyShow
  },
  computed: {
    ...mapFields('user/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/users?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      user: {},
      editUser: {},
      popupAddUserToCompanyData: {},
      showCompany: {},
      impersonateUser: '',
      fields: [
        {
          key: 'email',
          label: 'Email Address',
          sortable: true
        },
        {
          key: 'firstName',
          label: 'Firstname',
          sortable: true
        },
        {
          key: 'lastName',
          label: 'Lastname',
          sortable: true
        },
        {
          key: 'roles',
          label: 'Roles',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'impersonation',
          label: 'Impersonation',
          thClass: 'text-center',
          tdClass: 'text-center'
        },
        {
          key: 'actions',
          label: 'Actions',
          sortable: false
        }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },

  created() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'user/list/default'
    }),

    ...mapActions('auth', [IMPERSONATE_USER]),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },
    closeUserCompanyModal() {
      this.popupAddUserToCompanyData = '';

      this.refreshData();
    },

    openAddCompanyToUser(user) {
      this.user.name = user.firstName;

      this.popupAddUserToCompanyData = user['@id'];
      this.$bvModal.show('companyUserCreate');
    },

    onImpersonateUser(user) {
      this.impersonateUser = user;
      this.$bvModal.show('impersonateUser');
    },

    edit(item) {
      this.editUser = item;
      this.$bvModal.show('editUser');
    },

    openCompanyModal(company) {
      this.showCompany = company;
      this.$bvModal.show('companyShow');
    },

    showUserCompanies(user) {
      this.editUser = user;
      this.$bvModal.show('userCompanies');
    }
  }
};
</script>

<style lang="scss">
.companies {
  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__show {
    color: $primary;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

.user {
  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    padding-bottom: 100px;
    margin-left: 5px;
  }
}

.user-companies {
  // font-size: 14px;

  p {
    color: $black;
    margin-top: 10px;
  }

  &__text {
    color: $primary;
  }

  &__vat {
    color: $black;
  }
}
</style>
