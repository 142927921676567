<template>
  <b-form @submit.prevent="checkCompany()">
    <b-form-group
      label="VAT Number"
      label-for="company_fiscal_id"
      :invalid-feedback="violations.fiscal_id"
      :state="getFiscal1State"
      v-if="!isCreatingUbo"
    >
      <b-form-row>
        <b-col cols="3">
          <v-select
            v-if="countrySelectItems"
            required
            :clearable="false"
            :disabled="!formMetadata.hasVat || showOffMode"
            v-model="formMetadata.vatCode1"
            id="company_fiscal_id_country"
            :options="countrySelectItems"
            :reduce="(c) => c.code"
            label="code"
          >
            <template v-slot:option="option">
              <country-flag :country="option.code" size="small"></country-flag>
              {{ option.name }}
            </template>
          </v-select>
        </b-col>
        <b-col cols="9">
          <b-form-input
            :disabled="!formMetadata.hasVat || showOffMode"
            id="company_fiscal_id"
            v-model="formMetadata.fiscalId1"
            type="text"
            :state="showOffMode ? null : formMetadata.vatValid1"
            required
            placeholder=""
          />

          <b-spinner
            v-if="formMetadata.vatLoading1"
            small
            style="position: absolute; right: 16px; top: 10px; z-index: 99999"
          ></b-spinner>
        </b-col>
      </b-form-row>
    </b-form-group>

    <b-form-checkbox
      v-if="
        (!showOffMode || !formMetadata.hasVat) &&
        !isOnlyCommercial &&
        !isCreatingUbo
      "
      :disabled="showOffMode || showTypePerson"
      class="mt-3 mb-3"
      v-model="formMetadata.hasVat"
      :value="false"
      :unchecked-value="true"
    >
      <span class="font-weight-normal">We don't have a VAT number</span>
    </b-form-checkbox>

    <b-form-checkbox
      v-if="!isCreatingBranch && !blockBranch"
      class="mt-3 mb-3"
      :disabled="showOffMode"
      v-model="formMetadata.isBranch"
    >
      <span class="font-weight-normal">We're a branch of another company</span>
    </b-form-checkbox>

    <!--    Non-VAT cases -->
    <div v-if="!formMetadata.hasVat">
      <b-form-group
        label="Because"
        label-for="company_type"
        :invalid-feedback="violations.type"
        :state="!isValid('type')"
        v-if="!isCreatingUbo"
      >
        <b-form-select
          id="company_type"
          :disabled="showOffMode || showTypePerson || hasOneOption"
          v-model="formMetadata.reasonNoVat"
          required
          :options="companyTypes"
          text-field="text"
          value-field="type"
        />
      </b-form-group>

      <b-form-group
        v-if="getFiscalIdLabel && !formMetadata.isBranch"
        :label="getFiscalIdLabel"
        label-for="company_fiscal_id"
        :invalid-feedback="violations.fiscal_id"
        :state="getFiscal2State"
      >
        <b-form-row>
          <b-col cols="3">
            <v-select
              v-if="countrySelectItems"
              required
              :clearable="false"
              :disabled="showOffMode"
              v-model="formMetadata.vatCode2"
              id="company_fiscal_id2_country"
              :options="countrySelectItems"
              :reduce="(c) => c.code"
              label="code"
            >
              <template v-slot:option="option">
                <country-flag
                  :country="option.code"
                  size="small"
                ></country-flag>
                {{ option.name }}
              </template>
            </v-select>
          </b-col>
          <b-col cols="9">
            <b-form-input
              :disabled="showOffMode"
              id="company_fiscal_id"
              v-model="formMetadata.fiscalId2"
              type="text"
              :state="getFiscal2State"
              required
            />
          </b-col>
        </b-form-row>
      </b-form-group>
    </div>
    <!-- EO Non-VAT cases -->
    <!-- Branch Case -->

    <div v-if="formMetadata.isBranch || isCreatingBranch">
      <b-form-group
        v-if="formMetadata.isBranch"
        label="Company Information"
        label-for="company_branch_vat"
        :invalid-feedback="violations.branch_vat"
        :state="getFiscal2State"
      >
        <b-form-row>
          <b-col cols="3">
            <v-select
              v-if="countrySelectItems"
              required
              :clearable="false"
              :disabled="showOffMode"
              v-model="formMetadata.branchCode"
              id="company_branch_country"
              :options="countrySelectItems"
              :reduce="(c) => c.code"
              label="code"
            >
              <template v-slot:option="option">
                <country-flag
                  :country="option.code"
                  size="small"
                ></country-flag>
                {{ option.name }}
              </template>
            </v-select>
          </b-col>
          <b-col cols="9">
            <b-form-input
              :disabled="showOffMode"
              id="company_branch_vat"
              v-model="formMetadata.branchVAT"
              type="text"
              required
              :state="formMetadata.validBranch"
            />
          </b-col>
          <span
            v-if="formMetadata.validBranch == false"
            class="text-danger pl-1 pt-2"
            >{{
              `Company with VAT number ${
                formMetadata.branchCode + formMetadata.branchVAT
              } does not exist`
            }}</span
          >
        </b-form-row>
      </b-form-group>
      <b-form-group
        v-if="formMetadata.validBranch && formMetadata.isBranch"
        label-for="company_branch_name"
        label="Company name"
      >
        <b-form-input
          id="company_branch_name"
          v-model="formMetadata.branchName"
          disabled
        />
      </b-form-group>
      <b-form-group
        label="Branch Percentage"
        label-for="company_fiscal_percentage"
      >
        <b-form-row>
          <b-col cols="12">
            <b-form-input
              :disabled="showOffMode"
              id="company_fiscal_percentage"
              v-model="formMetadata.branchPercentage"
              :state="getPercentageBranches"
              type="text"
              required
            />
          </b-col>
        </b-form-row>
      </b-form-group>
      <b-form-group>
        <b-form-row>
          <b-col cols="12">
            <b-form-group label="Branch Start" label-for="branch_start">
              <b-form-datepicker
                :disabled="showOffMode"
                id="branch_start"
                v-model="formMetadata.branchStartDate"
                required
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Branch End" label-for="branch_end"
              ><b-form-datepicker
                :disabled="showOffMode"
                id="branch_end"
                v-model="formMetadata.branchEndDate"
                branchStartDate
                required
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form-group>
    </div>

    <!-- End Branch cases-->

    <b-form-group v-if="!showOffMode" class="float-right">
      <b-button
        type="submit"
        variant="success"
        :disabled="isDisabled()"
        v-if="!isLoading"
        >Next
      </b-button>
      <b-button type="button" variant="success" disabled v-else>
        <b-spinner small></b-spinner>
      </b-button>
    </b-form-group>
  </b-form>
</template>

<script>
import { get, isUndefined, debounce } from 'lodash';
import { entityService } from '../../../../utils/fetch';
import * as types from '../../../../store/modules/address/list/mutation_types';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { SwalAlert } from '@/utils/swal';
import validators from '@/mixins/validators';
import helpers from '@/mixins/helpers';
import { mapGetters } from 'vuex';

export default {
  mixins: [validators, helpers],

  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    item: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    showOffMode: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    isRelatedValidate: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    showTypePerson: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    blockBranch: {
      type: Boolean,
      default: () => true
    },

    role: {
      type: String,
      default: null
    },

    isOnlyCommercial: {
      type: Boolean,
      default: false
    },

    isCreatingBranch: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    isCreatingRelation: {
      type: Boolean,
      default: false
    },

    isOnboarding: {
      type: Boolean,
      default: false
    },

    availableCompanyTypes: {
      type: Array,
      required: true
    },
    isCreatingUbo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      vatRetrieved: {},
      original: {},
      maxBranchPercentage: 100,
      formMetadata: {
        vatLoading1: false,
        fiscalId1: '',
        vatCode1: 'NL',
        vatValid1: null,

        vatLoading2: false,
        vatCode2: 'NL',
        fiscalId2: '',

        isBranch: false,
        branchVAT: '',
        branchCode: 'NL',
        validBranch: null,
        branchPercentage: '',
        branchStartDate: new Date(),
        branchEndDate: '',

        hasVat: true,
        reasonNoVat: this.role === 'Learner' ? 'GOVERNMENTAL' : 'FISCAL_UNITY',
        fiscalCompany: ''
      },
      hasOneOption: false,
      types: [
        {
          type: 'GOVERNMENTAL',
          text: 'The company is a Governmental Organization'
        },
        {
          type: 'PERSON',
          text: 'The company is an Individual'
        },
        {
          type: 'FISCAL_UNITY',
          text: 'The company is part of a Fiscal Unity in VAT'
        },
        {
          type: 'OTHER',
          text: 'Other'
        }
      ]
    };
  },

  mounted() {
    this.countryGetSelectItems();
    this.companyGetSelectItems();

    // Fill for show off data
    if (this.item.type) {
      this.original = { ...this.item };
      if (this.item.type === 'COMMERCIAL') {
        this.formMetadata.hasVat = true;
        this.formMetadata.vatCode1 = this.item.fiscal_id.substr(0, 2);
        this.formMetadata.fiscalId1 = this.item.fiscal_id.substr(2);
      } else {
        this.formMetadata.hasVat = false;
        this.formMetadata.reasonNoVat = this.item.type;
        if (this.item.fiscal_id) {
          this.formMetadata.vatCode2 = this.item.fiscal_id.substr(0, 2);
          this.formMetadata.fiscalId2 = this.item.fiscal_id.substr(2);
        }
        if (this.item.parent) {
          this.formMetadata.fiscalCompany = this.item.parent;
        }
      }
    }

    if (this.showTypePerson) {
      this.formMetadata.hasVat = false;
      this.formMetadata.reasonNoVat = 'PERSON';
    } else if (this.isCreatingRelation || this.isOnboarding) {
      this.formMetadata.reasonNoVat = 'OTHER';
    }
  },

  watch: {
    formMetadata: {
      handler(formMetadata) {
        // Update item
        this.updateItem();
      },
      deep: true
    },
    'formMetadata.vatLoading1': function (isLoading) {
      this.isLoading = isLoading;
    },
    'formMetadata.vatCode1': debounce(function (vatCode1) {
      this.checkVatInput(
        this.formMetadata.vatCode1,
        this.removeSpecialCharacters(this.formMetadata.fiscalId1)
      );
    }, 500),

    'formMetadata.fiscalId1': debounce(function (fiscalId1) {
      this.checkVatInput(
        this.formMetadata.vatCode1,
        this.removeSpecialCharacters(this.formMetadata.fiscalId1)
      );
    }, 500),

    'formMetadata.branchVAT': debounce(function (branchVAT) {
      this.item.branchOf = this.getCompanyOnFiscalID(
        this.formMetadata.branchCode + branchVAT
      );
    }, 1000),

    'formMetadata.hasVat': debounce(function (fiscalId1) {
      if (this.companyTypes.length === 1) {
        this.formMetadata.reasonNoVat = this.companyTypes[0].type;
        this.hasOneOption = true;
      }
    }, 1)
  },

  computed: {
    ...mapGetters('auth', ['isRole', 'getCompanyId']),

    ...mapFields('country/list', {
      countrySelectItems: 'selectItems'
    }),
    ...mapFields('company/list', {
      companySelectItems: 'selectItems'
    }),

    violations() {
      return this.errors || {};
    },

    getFiscalIdLabel() {
      switch (this.formMetadata.reasonNoVat) {
        case 'GOVERNMENTAL':
          return 'OIN Number';
        case 'PERSON':
          return 'National Identification Number';
      }

      return '';
    },

    getFiscal1State() {
      if (
        this.violations.fiscal_id &&
        this.formMetadata.hasVat &&
        this.formMetadata.fiscalId1.length > 0
      ) {
        return false;
      }
      return null;
    },

    getFiscal2State() {
      if (
        this.violations.fiscal_id &&
        !this.formMetadata.hasVat &&
        this.formMetadata.fiscalId2.length > 0
      ) {
        return false;
      }
      return null;
    },

    getPercentageBranches() {
      if (this.formMetadata.branchPercentage?.length == 0) {
        return null;
      }
      let branchPercentage = this.formMetadata.branchPercentage;
      if (
        branchPercentage < 0 ||
        branchPercentage > this.maxBranchPercentage ||
        (branchPercentage != null && isNaN(parseInt(branchPercentage)))
      ) {
        return false;
      }
      return true;
    },

    companyTypes() {
      return this.types.filter(
        (item) =>
          this.canISeeThisType(item.type) &&
          this.availableCompanyTypes.includes(item.type)
      );
    }
  },

  methods: {
    ...mapActions({
      countryGetSelectItems: 'country/list/getSelectItems',
      companyGetSelectItems: 'company/list/getSelectItems'
    }),
    fillDataFromCompany(retrieved, current) {
      if (this.formMetadata.isBranch || this.isCreatingBranch) {
        retrieved.branchPercentage = parseFloat(current.branchPercentage);
        retrieved.branchStartDate = current.branchStartDate;
        retrieved.branchEndDate = current.branchEndDate;
      }

      if (this.isCreatingBranch) {
        retrieved.branchOf = this.getCompanyId;
      }
      return retrieved;
    },

    canISeeThisType(type) {
      if (this.formMetadata.isBranch || this.isCreatingBranch) {
        return type === 'FISCAL_UNITY' || type === 'OTHER';
      } else if (!this.formMetadata.hasVat) {
        return type === 'GOVERNMENTAL' || type === 'PERSON';
      } else {
        return true;
      }
    },

    getCompanyOnFiscalID(branchVAT) {
      if (branchVAT.lengt <= 7) {
        return;
      }
      let result = null;
      this.isLoading = false;
      entityService('companies', {
        params: {
          fiscal_id: branchVAT
        }
      })
        .then((response) => response.json())
        .then((data) => {
          this.isLoading = false;
          let member = data['hydra:member'];

          if (member.length) {
            let companyBranch = member[0];
            this.item.branchOf = companyBranch['@id'];
            this.formMetadata.branchName = companyBranch.name;
            this.maxBranchPercentage = companyBranch.maxBranchPercentage || 100;
            this.formMetadata.validBranch = true;
          } else {
            this.formMetadata.validBranch = false;
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },

    checkVatInput(countryCode, vatNumber) {
      this.vatRetrieved = {};

      // Don't check on updates
      if (
        this.original.fiscal_id &&
        vatNumber == this.original.fiscal_id.substr(2)
      ) {
        return;
      }

      // Ignore
      if (vatNumber.length <= 7) {
        this.formMetadata.vatLoading1 = false;
        this.formMetadata.vatValid1 = vatNumber.length === 0 ? null : false;
        return;
      }

      this.formMetadata.vatLoading1 = true;
      this.formMetadata.vatValid1 = null;

      if (this.formMetadata.hasVat) {
        const regex = new RegExp(countryCode, 'i');
        vatNumber = vatNumber.replace(regex, '');

        vatNumber = this.removeSpecialCharacters(vatNumber);

        this.validateVat(countryCode, vatNumber)
          .then((response) => response.json())
          .then((data) => {
            this.formMetadata.vatValid1 = true;
            this.vatRetrieved = data;
          })
          .catch((error) => {
            this.formMetadata.vatValid1 = false;
            this.vatRetrieved = null;
          })
          .finally(() => {
            this.formMetadata.vatLoading1 = false;
          });
      } else {
        const isPatterValid = this.isPatterValid(countryCode, vatNumber);

        if (isPatterValid) {
          this.formMetadata.vatValid1 = true;
        } else {
          this.formMetadata.vatValid1 = false;
        }

        this.formMetadata.vatLoading1 = false;
      }

      /**
       * We need to check this because is not working the way it should.
       */
      // this.validateVat(countryCode, vatNumber)
      //   .then((response) => response.json())
      //   .then((data) => {
      //       this.formMetadata.vatValid1 = true;
      //       this.vatRetrieved = data;
      //   })
      //   .catch((error) => {
      //     this.vatRetrieved = null;
      //   })
      //   .finally(() => {
      //     this.formMetadata.vatLoading1 = false;
      //   });
    },

    updateItem() {
      this.item.type = this.formMetadata.hasVat
        ? 'COMMERCIAL'
        : this.formMetadata.reasonNoVat;
      this.item.parent = null;
      this.item.fiscal_id = null;

      // Typical VAT
      if (this.formMetadata.hasVat) {
        this.item.fiscal_id = `${this.formMetadata.vatCode1}${this.formMetadata.fiscalId1}`;
      }
      // Commercial
      else if (
        ['GOVERNMENTAL', 'PERSON'].indexOf(this.formMetadata.reasonNoVat) !== -1
      ) {
        this.item.fiscal_id = `${this.formMetadata.vatCode2}${this.formMetadata.fiscalId2}`;
      }
      // Unity or other
      else if (this.formMetadata.reasonNoVat === 'FISCAL_UNITY') {
        this.item.parent = this.formMetadata.fiscalCompany;
      }

      // Remove if unused
      if (!this.item.parent) {
        this.$delete(this.item, 'parent');
      }
    },

    checkCompany() {
      this.isLoading = true;

      let ret = {
        item: this.item,
        retrieved: false,
        vatRetrieved: this.vatRetrieved
      };

      if (this.formMetadata.fiscalId1) {
        this.formMetadata.fiscalId1 = this.formMetadata.fiscalId1.replace(
          this.formMetadata.vatCode1,
          ''
        );

        this.formMetadata.fiscalId1 = this.removeSpecialCharacters(
          this.formMetadata.fiscalId1
        );
      }

      if (ret.item.fiscal_id) {
        ret.item.fiscal_id = this.removeSpecialCharacters(ret.item.fiscal_id);
      }

      // Handle Fiscal Unity
      // Don't check if exists
      if (
        !this.formMetadata.hasVat &&
        this.formMetadata.reasonNoVat === 'FISCAL_UNITY'
      ) {
        this.isLoading = false;
        return this.handleSubmit(ret);
      }

      if (this.formMetadata.isBranch || this.isCreatingBranch) {
        this.item.branchPercentage = parseFloat(
          this.formMetadata.branchPercentage
        );
        this.item.branchStartDate = this.formMetadata.branchStartDate;
        this.item.branchEndDate = this.formMetadata.branchEndDate;
      }

      if (this.isCreatingBranch) {
        this.item.branchOf = this.getCompanyId;
      }

      if (this.item.type === 'COMMERCIAL' && !this.formMetadata.vatValid1) {
        SwalAlert({
          title: 'Are you sure?',
          subTitle: 'Filled company VAT is not valid!',
          icon: 'user',
          type: 'warning',
          buttons: {
            cancel: {
              label: 'No',
              type: 'warning'
            },
            confirm: {
              label: 'Yes, go ahead',
              type: 'success',
              callback: () => {
                this.checkValidatedCompany(ret);
              }
            }
          }
        }).finally((e) => {
          this.isLoading = false;
        });
        return true;
      }

      this.checkValidatedCompany(ret);
    },

    checkValidatedCompany(ret) {
      // Do we have it already?
      entityService('/companies', {
        params: {
          fiscal_id: this.formMetadata.vatCode1 + this.formMetadata.fiscalId1
        }
      })
        .then((response) => response.json())
        .then((data) => {
          // Company found!
          if (data['hydra:member'].length > 0) {
            ret.item = this.fillDataFromCompany(
              data['hydra:member'][0],
              ret.item
            );
            ret.retrieved = true;
          }
        })
        .finally(() => {
          this.isLoading = false;

          if (this.isCreatingUbo && ret.retrieved) {
            SwalAlert({
              title: 'Invitation',
              subTitle: 'Do you want to invite this user to be admin?',
              icon: 'user',
              type: 'success',
              buttons: {
                cancel: {
                  label: 'No',
                  type: 'warning',
                  callback: () => {
                    this.handleSubmit(ret);
                  }
                },
                confirm: {
                  label: 'Yes, go ahead',
                  type: 'success',
                  callback: () => {
                    ret.item.inviteUser = true;
                    this.handleSubmit(ret);
                  }
                }
              }
            }).finally((e) => {
              this.isLoading = false;
            });
          } else {
            this.handleSubmit(ret);
          }
        });
    },
    isValid(key) {
      return isUndefined(get(this.violations, key)) === true ? null : false;
    },

    isDisabled() {
      if (this.formMetadata.isBranch) {
        if (!this.formMetadata.validBranch || !this.getPercentageBranches)
          return true;
      }
      if (this.isCreatingBranch && !this.getPercentageBranches) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss">
.vs__dropdown-toggle {
  height: auto;
}
</style>
