<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12">
        <div class="questionary__intro" v-if="!showForm">
          <img
            alt=""
            class="mr-2"
            src="@/assets/img/icons/menuicons/company@2x.png"
          />

          <h1 class="questionary__intro__title">
            Impact Analysis - Annual Specifics Questionnaire
          </h1>

          <div class="mt-5">
            <p class="questionary__intro__description">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias
              aperiam aspernatur deserunt ducimus in itaque nostrum odit. Ab
              corporis dolores eveniet, hic id ipsa necessitatibus, nulla
              numquam officiis provident quia!
            </p>
            <p class="questionary__intro__description">
              Any questions? Feel free to use the Chat function on the right...
            </p>
          </div>

          <div class="questionary__intro__button">
            <CustomButton
              @click.native="showForm = !showForm"
              class="button mt-2"
              type="Default"
              :content="{ title: 'Start' }"
            />
          </div>
        </div>

        <b-form class="questionary" @submit.prevent="onSendForm" v-else>
          <div class="questionary__header">
            <h2 class="questionary__header__title">{{ items[step - 1] }}</h2>
            <span class="questionary__header__pagination"
              >{{ step }}/{{ groupsSize }}</span
            >
          </div>

          <div class="questionary__content">
            <b-form-group
              class="questionary__input__label"
              label="What was the CRKBO Registration in this year?"
              :label-for="`crkboRegistration${items[step - 1]}`"
            >
              <b-form-radio-group
                :id="`crkboRegistration${items[step - 1]}`"
                :name="`crkboRegistration${items[step - 1]}`"
                required="required"
              >
                <b-form-radio-group
                  :id="`crkboRegistration${items[step - 1]}`"
                  v-model="form.crkboRegistration"
                  :options="['Teacher', 'Institution']"
                  :name="`crkboRegistration${items[step - 1]}`"
                ></b-form-radio-group>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              class="questionary__input__label"
              label="What was the amount of VAT-Deduction for this year?"
              :label-for="`vatDeductionAmount${items[step - 1]}`"
            >
              <b-form-radio-group
                :id="`vatDeductionAmount${items[step - 1]}`"
                :name="`vatDeductionAmount${items[step - 1]}`"
                required="required"
              >
                <b-form-input
                  :id="`vatDeductionAmount${items[step - 1]}`"
                  v-model="form.vatDeductionAmount"
                  :name="`vatDeductionAmount${items[step - 1]}`"
                  type="number"
                ></b-form-input>
              </b-form-radio-group>
            </b-form-group>

            <b-form-group
              class="questionary__input__label"
              label="What was the amount of RENT (VAT-taxed) for this year?"
              :label-for="`rentAmount${items[step - 1]}`"
            >
              <b-form-radio-group
                :id="`rentAmount${items[step - 1]}`"
                :name="`rentAmount${items[step - 1]}`"
                required="required"
              >
                <money
                  :id="`rentAmount${items[step - 1]}`"
                  v-model="form.rentAmount"
                  :name="`rentAmount${items[step - 1]}`"
                />
              </b-form-radio-group>
            </b-form-group>
          </div>

          <div class="questionary__buttons">
            <CustomButton
              v-if="!isLastStep"
              @click.native="next"
              class="float-right button mt-2"
              type="Default"
              :disabled="canProceed"
              :content="{ title: 'Next' }"
            />

            <CustomButton
              v-if="step !== 1"
              @click.native="prev"
              class="float-left button mt-2"
              type="Default"
              :content="{ title: 'Back' }"
            />

            <b-form-group class="float-right mt-4" v-if="isLastStep">
              <b-button
                type="submit"
                variant="primary"
                v-if="!isLoading"
                :disabled="canProceed"
                >Submit</b-button
              >
              <b-button type="submit" variant="primary" v-else>
                <b-spinner small></b-spinner>
              </b-button>
            </b-form-group>
          </div>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CustomButton from '@/components/_ui/CustomButton';
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    CustomButton
  },

  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    groupsSize() {
      return this.items.length;
    },

    isLastStep() {
      return this.step === this.groupsSize;
    },

    canProceed() {
      return (
        !this.form.crkboRegistration ||
        !this.form.vatDeductionAmount ||
        !this.form.rentAmount
      );
    }
  },

  data() {
    return {
      step: 1,
      showForm: false,
      form: {
        crkboRegistration: null,
        vatDeductionAmount: null,
        rentAmount: null,
        year: null
      },
      years: [],
      isLoading: false
    };
  },

  methods: {
    next() {
      this.addYearToArray();
      this.step++;
    },

    prev() {
      this.step--;
    },

    addYearToArray() {
      this.years.push({
        ...this.form,
        year: this.items[this.step - 1]
      });
    },

    onSendForm() {
      this.addYearToArray();

      this.isLoading = true;

      entityService('/pro-ratas', {
        method: 'POST',
        body: JSON.stringify(this.years)
      })
        .then((response) => response.json())
        .then((data) => {
          SwalAlert({
            title: 'Impact Analysis - Annual Specifics Questionnaire completed',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });

          this.$bvModal.hide('proRata');
          this.$emit('created');

          this.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },

  watch: {
    step: function () {
      this.form = {
        crkboRegistration: null,
        vatDeductionAmount: null,
        rentAmount: 0
      };
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/components/questionary.scss"></style>
