<template>
  <div class="wrapper">
    <ChatDaemon />
    <Sidemenu
      :class="[sidemenuOpen ? 'active' : '']"
      :isMenuOpen="sidemenuOpen"
    ></Sidemenu>
    <div id="content" :class="[sidemenuOpen ? 'active' : '']">
      <Topmenu @toggle="toggleSidemenu"></Topmenu>
      <div class="amplificaContainer">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Sidemenu from '@/components/dashboard/Sidemenu';
import Topmenu from '@/components/dashboard/Topmenu';
import ChatDaemon from '@/components/chat/Daemon.vue';

const STORAGE_MENU_OPEN = 'UI_STORAGE_MENU_OPEN';
export default {
  name: 'DashboardLayout',
  components: {
    ChatDaemon,
    Sidemenu,
    Topmenu
  },
  props: {},
  data() {
    return {
      sidemenuOpen: (localStorage.getItem(STORAGE_MENU_OPEN) || 0) == 1 || false
    };
  },
  methods: {
    /**
     * Save and store the status of the sidemenu
     */
    toggleSidemenu() {
      this.sidemenuOpen = !this.sidemenuOpen;

      // Keep it on refresh
      if (this.sidemenuOpen) {
        localStorage.setItem(STORAGE_MENU_OPEN, '1');
      } else {
        localStorage.removeItem(STORAGE_MENU_OPEN);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
//content
#content {
  width: calc(100% - 286px);
  padding: 0px;
  min-height: 100vh;
  position: absolute;
  transition: all 0.3s ease;
  top: 0;
  right: 0;

  &.active {
    width: calc(100% - 77px);
  }

  &.activeSupport {
    width: calc(100% - 5px);
    left: 66px;
    right: auto;
  }
}
</style>
