<template>
  <div class="mt-3">
    <div class="text-center text-danger my-2" v-if="isLoading">
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>

    <b-form @submit.prevent="onSendForm">
      <b-form-row>
        <b-col>
          <b-form-group
            label="Street *"
            label-for="address_street"
            class="font-weight-bold"
          >
            <b-form-input
              required
              id="address_street"
              v-model="currentData.street"
              type="text"
              class="form-input"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Number *"
            label-for="address_number"
            class="font-weight-bold"
          >
            <b-form-input
              required
              id="address_number"
              v-model="currentData.number"
              type="text"
              class="form-input"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="Postal Code *"
            label-for="address_zip"
            class="font-weight-bold"
          >
            <b-form-input
              required
              id="address_zip"
              v-model="currentData.zip"
              type="text"
              class="form-input"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="City *"
            label-for="address_city"
            class="font-weight-bold"
          >
            <b-form-input
              required
              id="address_city"
              v-model="currentData.city"
              type="text"
              placeholder=""
              class="form-input"
            />
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group
            label="State *"
            label-for="address_state"
            class="font-weight-bold"
          >
            <b-form-input
              required
              id="address_state"
              v-model="currentData.state"
              type="text"
              placeholder=""
              class="form-input"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Country *"
            label-for="address_country"
            class="font-weight-bold"
          >
            <b-form-select
              required
              v-model="currentData.country"
              id="address_country"
              class="form-input"
              :options="countrySelectItems"
              text-field="name"
              value-field="@id"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-form-row>

      <div class="float-right mt-5">
        <b-button
          type="button"
          variant="secondary mr-2"
          :disabled="isLoading"
          @click="$bvModal.hide('editUser')"
          >Cancel</b-button
        >
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Save</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  name: 'Address',

  props: {
    user: {
      type: Object,
      required: true
    },

    handleSubmit: {
      type: Function,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapFields('country/list', {
      countrySelectItems: 'selectItems'
    }),

    changedFormData() {
      return Object.keys(this.currentData).reduce((formData, field) => {
        if (this.currentData[field] !== this.originalData[field]) {
          formData[field] = this.currentData[field];
        }

        return formData;
      }, {});
    }
  },

  data() {
    return {
      currentData: { ...this.user.address },
      originalData: { ...this.user.address }
    };
  },

  mounted() {
    this.countryGetSelectItems();

    if (this.currentData.country) {
      this.currentData.country = this.currentData.country['@id'];
    }
  },

  methods: {
    ...mapActions({
      countryGetSelectItems: 'country/list/getSelectItems'
    }),

    onSendForm() {
      const data = {
        '@id': this.originalData['@id'],
        ...this.changedFormData
      };
      this.handleSubmit(data, 'address');
    }
  }
};
</script>
