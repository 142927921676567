<template>
  <b-badge v-if="value" variant="success"><i class="fas fa-check"></i></b-badge>
  <b-badge v-else variant="danger"><i class="fas fa-times"></i></b-badge>
</template>

<script>
export default {
  name: 'Status',
  props: {
    value: {
      type: [Boolean, String],
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
.badge {
  padding: 0.5em 0.5em !important;
  border-radius: 100%;
  margin: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2em;
  width: 2em;
}
.badge-success {
  background-color: $light-green;
  color: $success;
}

.badge-danger {
  background-color: $light-red;
  color: $danger;
}
</style>
