var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-header"},[_c('router-link',{staticClass:"logo",attrs:{"to":"/dashboard"}})],1),_c('ul',[_vm._l((_vm.menuComputed),function(item,index){return [(
          _vm.isRole(item.roles) ||
          item.roles.includes('All') ||
          _vm.canAdminView(item)
        )?_c('li',{key:index},[(item.modal)?_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(item.modal),expression:"item.modal"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:({
            customClass: 'custom-tooltip',
            disabled: !_vm.isMenuOpen
          }),expression:"{\n            customClass: 'custom-tooltip',\n            disabled: !isMenuOpen\n          }",modifiers:{"hover":true,"topright":true}}],staticClass:"programs",attrs:{"href":"#","title":item.title}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),[(!item.submenu)?_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:({
              customClass: 'custom-tooltip',
              disabled: !_vm.isMenuOpen
            }),expression:"{\n              customClass: 'custom-tooltip',\n              disabled: !isMenuOpen\n            }",modifiers:{"hover":true,"topright":true}}],class:item.class,attrs:{"title":item.title,"to":item.path},on:{"click":function($event){$event.preventDefault();return _vm.openMenu(index)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:({
              customClass: 'custom-tooltip',
              disabled: !_vm.isMenuOpen
            }),expression:"{\n              customClass: 'custom-tooltip',\n              disabled: !isMenuOpen\n            }",modifiers:{"hover":true,"topright":true}}],class:item.class,attrs:{"href":"#","title":item.title},on:{"click":function($event){$event.preventDefault();return _vm.openMenu(index)}}},[_vm._v(" "+_vm._s(item.title)+" "),_c('i',{staticClass:"fas fa-angle-right",class:{ 'fa-angle-right__open': _vm.open.includes(index) }})]),(index === 0)?_c('hr',{staticClass:"bg-white mx-4"}):_vm._e()],(item.submenu)?_c('ul',{staticClass:"dropdown submenu",class:{ submenu__open: _vm.open.includes(index) }},[_vm._l((item.submenu),function(subItem,subIndex){return [(
                _vm.canAdminView(subItem) ||
                (subItem.roles &&
                  (_vm.isRole(subItem.roles) || subItem.roles.includes('All')))
              )?[(subItem.getCompanyType)?_c('div',{key:subIndex},[(_vm.getCompanyType === 'PERSON')?_c('li',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:({
                      customClass: 'custom-tooltip',
                      disabled: !_vm.isMenuOpen
                    }),expression:"{\n                      customClass: 'custom-tooltip',\n                      disabled: !isMenuOpen\n                    }",modifiers:{"hover":true,"topright":true}}],attrs:{"to":subItem.path,"title":subItem.title}},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1):_vm._e()]):_c('div',{key:`1-${subIndex}`},[(
                    _vm.canAdminView(subItem) ||
                    (subItem.roles &&
                      (_vm.isRole(item.roles) || item.roles.includes('All')))
                  )?_c('li',{key:subIndex},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:({
                      customClass: 'custom-tooltip',
                      disabled: !_vm.isMenuOpen
                    }),expression:"{\n                      customClass: 'custom-tooltip',\n                      disabled: !isMenuOpen\n                    }",modifiers:{"hover":true,"topright":true}}],attrs:{"to":subItem.path,"title":subItem.title}},[_vm._v(" "+_vm._s(subItem.title)+" ")])],1):_vm._e()])]:_vm._e()]})],2):_vm._e()],2):_vm._e()]}),(_vm.messageOnSideBar)?_c('li',{class:{ active: _vm.stateMessage }},[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.topright",value:({
          customClass: 'custom-tooltip',
          disabled: !_vm.isMenuOpen
        }),expression:"{\n          customClass: 'custom-tooltip',\n          disabled: !isMenuOpen\n        }",modifiers:{"hover":true,"topright":true}}],staticClass:"contacts",class:{ active: _vm.stateMessage },attrs:{"title":"Messages","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleMessage()}}},[_vm._v(" Messages "),_c('i',{staticClass:"fas fa-angle-right",class:{ 'fa-angle-right__open': this.stateMessage }})]),_c('ul',{staticClass:"dropdown submenu",class:{ submenu__open: _vm.stateMessage }},[_c('Contacts',{attrs:{"rooms":_vm.rooms,"is-loading":_vm.roomIsLoading,"refresh-data":_vm.refreshData,"isMenuOpen":_vm.isMenuOpen}})],1)]):_vm._e()],2),_c('b-modal',{attrs:{"id":"qrScanner","title":"Scan QR Code","hide-footer":""}},[_c('QrCodeForm')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }