<template>
  <div>
    <HandshakeView :handshakeUrl="handshakeUrl" :activeFields="fields" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HandshakeView from '@/components/handshake/List';

export default {
  components: {
    HandshakeView
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId']),

    handshakeUrl() {
      return `${this.getCompanyId}/handshakes?type=${this.handshakeType}`;
    }
  },

  data() {
    return {
      editItem: {},
      handshakeType: 'CompanyAnalysisQuestionsHandshake',
      fields: ['company.name', 'type', 'status', 'actions']
    };
  }
};
</script>
