<template>
  <div>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/company@2x.png"
      />
      <h3 class="font-weight-bold mt-2">Prospects</h3>
    </div>

    <CustomTable
      :fields="fields"
      :values="getUserProspects"
      @changePage="changePage"
    >
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          v-b-tooltip.hover
          title="Show"
        >
          <i class="fas fa-eye text-info"></i>
        </a>
      </template>
    </CustomTable>

    <b-modal
      class="ml-4"
      size="xl"
      id="updateProspect"
      title="Update Prospect"
      hide-footer
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Prospect</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateProspect')"
        ></i>
      </template>

      <UpdateProspect :prospect="editItem"></UpdateProspect>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import helpers from '@/mixins/helpers';
import CustomTable from '@/components/_ui/tables/CustomTable';
import UpdateProspect from '@/components/dashboard/prospect/Update';

export default {
  name: 'GovermentCompanies',

  components: {
    CustomTable,
    UpdateProspect
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getUser']),

    getUserProspects() {
      let prospects = this.$store.state.prospects.list.items;

      return prospects.map((prospect) => {
        return {
          '@id': prospect['@id'],
          name: prospect.name,
          type: 'PROSPECT'
        };
      });
    },

    request() {
      let request = '/prospects?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      editItem: '',
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      page: 1,
      itemsPerPage: 25
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getProspects: 'prospects/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },
    edit(item) {
      this.editItem = item;
      this.$bvModal.show('updateProspect');
    }
  }
};
</script>
