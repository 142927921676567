<template>
  <div class="user-terms">
    <div class="user-terms__content">
      <div class="user-terms__item">
        <h3 class="user-terms__item__title">{{ item.title }}</h3>
        <p class="user-terms__item__text" v-html="item.terms"></p>
      </div>
    </div>

    <div class="user-terms__footer" v-if="canAcceptOrReject">
      <b-form-group
        label="By continuing I agree with the User Terms of AmplificA"
        label-for="userTerms"
      >
        <div class="user-terms__buttons">
          <template v-if="!isLoading">
            <b-button
              class="mr-2"
              type="button"
              variant="danger"
              @click="submit(false)"
              >Reject</b-button
            >
            <b-button
              class="mr-2"
              type="button"
              variant="success"
              @click="submit(true)"
              >Accept</b-button
            >
          </template>

          <b-button type="submit" v-else>
            <b-spinner small></b-spinner>
          </b-button>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserTerms',

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    canAcceptOrReject: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    submit(accepted) {
      this.$emit('userTermsSubmition', {
        accepted,
        id: this.item['@id']
      });
    }
  }
};
</script>

<style lang="scss">
.user-terms {
  &__content {
    background-color: $gray-300;
    border-radius: 15px;

    max-height: 500px;
    overflow-y: scroll;

    padding: 15px;
  }

  &__item {
    margin-top: 25px;

    &__title {
      // font-size: 14px;
    }

    &__text {
      // font-size: 12px;
    }
  }

  &__footer {
    margin-top: 25px;
    text-align: right;
  }

  &__buttons {
    margin-top: 25px;
  }
}
</style>
