<template>
  <b-container>
    <b-form method="POST" @submit.prevent="doLogin">
      <b-row align-h="center">
        <b-col cols="6">
          <b-input-group class="input-icon">
            <i class="fa fa-user"></i>
            <b-form-input
              type="email"
              v-model="form.email"
              placeholder="Email Address"
              :state="emailValidation"
              size="lg"
              required
            />
          </b-input-group>

          <b-input-group class="input-icon">
            <i class="fa fa-lock"></i>
            <b-form-input
              type="password"
              v-model="form.password"
              placeholder="Password"
              :state="passValidation"
              size="lg"
              required
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="6">
          <b-form-invalid-feedback
            :state="emailValidation || passValidation"
            v-if="errors.length"
          >
            {{
              errors.length
                ? errors
                : 'E-mail or Password is invalid, please try again!'
            }}
          </b-form-invalid-feedback>
          <div v-else>
            <b-button variant="primary" type="submit" v-if="!loading" size="lg"
              >Next</b-button
            >
            <b-button variant="primary" type="submit" size="lg" disabled v-else>
              <b-spinner small></b-spinner>
              <span class="sr-only">Loading...</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row align-h="center">
        <b-col cols="12">
          <router-link :to="{ name: 'Recover' }" class="text-center"
            >Forgot Password?</router-link
          >
        </b-col>
        <b-col cols="12">
          <a href="#" class="text-center" @click="register">Register</a>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="userTerms"
      title="User Terms"
      size="xl"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      class="terms"
    >
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">User Terms</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('userTerms')"
        ></i>
      </template>

      <UserTerms
        @userTermsSubmition="userTermsSubmition"
        :item="termsAndConditions"
        :isLoading="loading"
      ></UserTerms>
    </b-modal>

    <b-modal id="register" title="Register" hide-footer class="terms">
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Register</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('register')"
        ></i>
      </template>

      <RegisterCreate />
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { AUTH_LOGIN, AUTH_LOGOUT } from '../../store/modules/auth/actions';
import validators from '@/mixins/validators';
import UserTerms from '@/components/user-terms/Main';
import { entityService } from '@/utils/fetch';
import helpers from '@/mixins/helpers';
import RegisterCreate from '@/components/register/Main';

export default {
  name: 'LoginView',

  components: {
    UserTerms,
    RegisterCreate
  },

  mixins: [validators, helpers],

  data() {
    return {
      loading: false,
      errors: [],
      form: {
        email: '',
        password: ''
      },
      termsAndConditions: []
    };
  },

  computed: {
    ...mapGetters('auth', [
      'getUser',
      'getToken',
      'getCompanyId',
      'getRole',
      'isRole'
    ]),

    hasErrors() {
      return !!this.errors && this.errors.length > 0;
    },

    emailValidation() {
      return this.form.email.length === 0
        ? null
        : this.errors.length || !this.validateEmail(this.form.email)
        ? false
        : true;
    },
    passValidation() {
      return this.form.password.length === 0 ? null : !this.errors.length;
    }
  },

  watch: {
    form: {
      deep: true,
      handler: function (form) {
        this.errors = [];
      }
    }
  },

  methods: {
    ...mapActions('auth', [AUTH_LOGIN, AUTH_LOGOUT]),

    async doLogin() {
      // Loading!
      this.loading = true;

      // Send to server
      await this.AUTH_LOGIN({
        username: this.form.email,
        password: this.form.password
      })
        .then(() => {
          this.checkUserTerms();
        })
        .catch((err) => {
          this.errors = err;
          this.showErr = true;
        })
        .finally(() => {
          // After all is done, remove loading
          this.loading = false;
        });
    },

    async checkUserTerms() {
      const hasAcceptedTerms = await this.fetchTermsAndConditions();

      if (!hasAcceptedTerms) {
        this.$bvModal.show('userTerms');

        return;
      }

      this.$router.push('/dashboard');
    },

    async fetchTermsAndConditions() {
      const acceptedTermsId = this.getUser.entity.termsAndConditions;

      await entityService('/terms-and-conditions?page=1&itemsPerPage=1')
        .then((response) => response.json())
        .then((data) => {
          const terms = data['hydra:member'];
          this.termsAndConditions = terms ? terms[0] : null;
        });

      if (!this.termsAndConditions) {
        return true;
      }

      if (
        this.iriToId(acceptedTermsId) ===
        this.iriToId(this.termsAndConditions['@id'])
      ) {
        return true;
      }

      return false;
    },

    userTermsSubmition(item) {
      if (item.accepted) {
        entityService(this.getUser.entity['@id'], {
          method: 'PATCH',
          headers: new Headers({
            'Content-Type': 'application/merge-patch+json'
          }),
          body: JSON.stringify({
            termsAndConditions: item.id
          })
        });

        this.$bvModal.hide('userTerms');
        this.$router.push('/dashboard');

        return;
      }

      this.AUTH_LOGOUT().then(() => {
        this.$bvModal.hide('userTerms');
      });
    },

    register() {
      this.$bvModal.show('register');
    }
  }
};
</script>

<style scoped lang="scss">
form {
  margin-top: 35px;
  .form-group {
    margin-bottom: 5px;
  }
}
button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
}

a.text-center {
  margin-top: 10px;
  display: inherit;
  color: $gray-500;
}

.invalid-feedback {
  background-color: $danger;
  color: $white;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.input-icon {
  margin: 10px 0;
  position: relative;
  i {
    color: $dark;
    position: absolute;
    left: 13px;
    top: 15px;
    z-index: 999999;
  }

  input {
    font-size: 16px;
    border-radius: 5px !important;
    width: 100%;
    height: 45px;
    background-color: $dark-blue;
    color: $dark;
    padding-left: 37px;
    &:hover,
    &:focus {
      background-color: $gray-200;
      color: $dark-blue;
    }
    border: none;
  }
}

.form-control {
  -webkit-text-fill-color: $gray-200;
  -webkit-box-shadow: 0 0 0 1000px $dark-blue inset;
}
.form-control:focus,
.form-control:hover {
  -webkit-text-fill-color: $dark-blue;
  -webkit-box-shadow: 0 0 0 1000px $gray-200 inset;
}

.fa-lock,
.fa-user {
  z-index: 10 !important;
}
</style>
