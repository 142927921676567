<template>
  <div class="trigger">
    <div
      class="trigger-bullet"
      :class="{ 'trigger-bullet-red': type === 'RED' }"
    ></div>
    <div
      class="trigger-bullet"
      :class="{ 'trigger-bullet-orange': type === 'ORANGE' }"
    ></div>
    <div
      class="trigger-bullet"
      :class="{ 'trigger-bullet-green': type === 'GREEN' }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'Trigger',

  props: {
    type: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.trigger {
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin-top: 8px;
  margin-left: -20px;

  &-bullet {
    width: 15px;
    height: 15px;
    background: $gray-400;
    border-radius: 50%;

    &-red {
      background: $danger;
    }

    &-orange {
      background: $warning;
    }

    &-green {
      background: $success;
    }
  }
}
</style>
