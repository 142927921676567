import CompaniesList from '@/components/dashboard/goverment/Companies';
import ProspectList from '@/components/dashboard/goverment/Prospects';

export default [
  {
    name: 'RequestPending',
    path: '/government/companies',
    component: CompaniesList
  },
  {
    name: 'RequestHistory',
    path: '/government/prospects',
    component: ProspectList
  }
];
