<template>
  <b-form @submit.prevent="handleSubmit(item)">
    <b-form-group
      label="Start Date"
      label-for="countryvat_startDate"
      :invalid-feedback="violations.startDate"
      :state="!isValid('startDate')"
    >
      <b-form-datepicker
        id="countryvat_startDate"
        v-model="item.startDate"
        type="text"
        required="required"
        placeholder=""
      >
      </b-form-datepicker>
    </b-form-group>

    <b-form-row>
      <b-col cols="3">
        <b-form-group label="End Date">
          <b-form-checkbox
            v-model="formHasEndDate"
            class="mt-2"
          ></b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col cols="9">
        <b-form-group
          v-if="formHasEndDate"
          :invalid-feedback="violations.endDate"
          :state="!isValid('endDate')"
        >
          <b-form-datepicker
            id="countryvat_endDate"
            v-model="item.endDate"
            type="text"
            required="required"
            class="mt-4"
            placeholder=""
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-form-row>

    <b-form-group
      label="Vat Rate"
      label-for="countryvat_value"
      :invalid-feedback="violations.value"
      :state="!isValid('value')"
    >
      <b-form-input
        id="countryvat_value"
        v-model="item.value"
        type="number"
        required="required"
        placeholder=""
      >
      </b-form-input>
    </b-form-group>

    <b-button type="submit">Submit </b-button>
  </b-form>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  data: function () {
    return {
      formHasEndDate: false,
      formBackupEndDate: ''
    };
  },
  mounted() {
    this.formHasEndDate = !!this.item.endDate;
    this.formBackupEndDate = this.item.endDate || '';
  },

  watch: {
    formHasEndDate: function (formHasEndDate) {
      if (this.formHasEndDate) {
        this.item.endDate = this.formBackupEndDate;
      } else {
        this.item.endDate = null;
      }
    }
  },

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({}),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    }
  }
};
</script>
