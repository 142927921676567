var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("The following schedules have been added:")]),(_vm.scheduleIsLoading)?_c('b-form-row',[_c('b-spinner')],1):_c('b-form-row',_vm._l((_vm.moduleItems),function(module){return _c('b-form-row',{key:module['@id'],staticClass:"bgGroup p-4 mb-2 font-weight-bolder"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"page-title"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"calendar2","variant":"primary"}}),_c('strong',[_vm._v("Module 1:")]),_vm._v(" "+_vm._s(module.name)+" ")],1),_vm._l((module.activities),function(learningActivity,idx){return _c('div',{key:idx},[_c('h4',{staticClass:"page-title mt-3 mb-3"},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"calendar3","variant":"primary"}}),_c('strong',[_vm._v("Activity:")]),_vm._v(" "+_vm._s(learningActivity.title)+" "),_c('b-icon',{staticClass:"ml-3 mr-2",attrs:{"icon":"person-badge","variant":"primary"}}),_c('strong',[_vm._v("Teacher:")]),_vm._v(" "+_vm._s(learningActivity.teacher.user.firstName)+" "+_vm._s(learningActivity.teacher.user.lastName)+" ")],1),_c('b-form-row',[_c('b-col',{attrs:{"cols":"6"}},_vm._l((_vm.program.learnerGroups),function(group,idx){return _c('b-card',{key:group,staticStyle:{"background":"#e9ebef !important"},attrs:{"border-variant":"dark","align":"left"}},[_c('b-card-title',[_c('span',{staticClass:"mr-2"},[(
                          _vm.getScheduleData(learningActivity['@id'], group)
                            .status === 'PENDING'
                        )?_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"warning"}}):_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"success"}})],1),_c('strong',[_vm._v("Group "+_vm._s(idx + 1)+": "+_vm._s(_vm.getGroupData(group).name)+" ")])]),_c('b-card-text',[_c('div',{staticClass:"activity-data"},[_c('p',{staticClass:"font-weight-normal activity-data"},[_c('span',[_vm._v("Date: ")]),_vm._v(" "+_vm._s(_vm.parseDate( _vm.getScheduleData(learningActivity['@id'], group) .startDateTime ))+" "),_c('br'),_c('span',[_vm._v("Learners: ")])]),_vm._l((_vm.getScheduleData(
                          learningActivity['@id'],
                          group
                        ).learners),function(learner){return _c('p',{key:learner['@id']},[(learner.status === 'INVITED')?_c('b-icon',{attrs:{"icon":"clock","variant":"warning"}}):(learner.status === 'ATTENDED')?_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"success"}}):_c('b-icon',{attrs:{"icon":"check-circle-fill","variant":"warning"}}),_vm._v("   "),(
                            learner.learner &&
                            learner.learner.user &&
                            learner.learner.user.firstName &&
                            learner.learner.user.lastName
                          )?_c('span',[_vm._v(_vm._s(learner.learner.user.firstName)+" "+_vm._s(learner.learner.user.lastName))]):_c('span',[_c('i',[_vm._v("(unknown)")])])],1)})],2),(
                        _vm.getScheduleData(learningActivity['@id'], group)
                          .status === 'PENDING'
                      )?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(
                            !_vm.getScheduleData(learningActivity['@id'], group)
                              .loadingExecute
                          )?_c('b-button',{attrs:{"block":"","size":"sm","variant":"primary"},on:{"click":function($event){_vm.executeSchedule(
                              _vm.getScheduleData(learningActivity['@id'], group)
                            )}}},[_c('i',{staticClass:"fa fa-check"}),_vm._v(" Execute ")]):_c('b-button',{attrs:{"block":"","disabled":"","variant":"primary"}},[_c('b-spinner',{attrs:{"small":""}})],1)],1),_c('b-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[(
                            !_vm.getScheduleData(learningActivity['@id'], group)
                              .loadingCancel
                          )?_c('b-button',{attrs:{"block":"","size":"sm","variant":"danger"},on:{"click":function($event){_vm.cancelSchedule(
                              _vm.getScheduleData(learningActivity['@id'], group)
                            )}}},[_c('i',{staticClass:"fa fa-times"}),_vm._v(" Cancel ")]):_c('b-button',{attrs:{"block":"","disabled":"","variant":"primary"}},[_c('b-spinner',{attrs:{"small":""}})],1)],1)],1):_vm._e()],1)],1)}),1)],1)],1)})],2)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }