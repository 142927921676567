import list from './list';
import show from './show';
import incoming from './incoming';
import outgoing from './outgoing';

export default {
  namespaced: true,
  modules: {
    list,
    show,
    incoming,
    outgoing
  }
};
