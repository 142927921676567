<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <BranchForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :company="company"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import BranchForm from './Form';
import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

const { mapFields } = createHelpers({
  getterType: 'company/create/getField',
  mutationType: 'company/create/updateField'
});

export default {
  components: {
    BranchForm
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  methods: {
    ...mapActions('company/create', ['create']),

    onSendForm(item) {
      this.update(item);
    },

    update(item) {
      entityService(item['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(item)
      })
        .then((data) => {
          this.$bvModal.hide('createCompany');

          SwalAlert({
            title: 'Branch added',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'Form another',
                type: 'warning',
                callback: () => {
                  this.$bvModal.show('createCompany');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success',
                callback: () => {}
              }
            }
          });

          this.$emit('created', data);
        })
        .catch((error) => {
          SwalAlert({
            title: 'Error',
            description: error,
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        });
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);

      this.$bvModal.hide('createCompany');
      SwalAlert({
        title: 'Branch added',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createCompany');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  }
};
</script>

<style scoped>
.title-position {
  position: relative;
  top: -40px;
}
</style>
