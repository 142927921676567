import CountryList from '@/components/admin/country/List';
import CountryCreate from '@/components/admin/country/Create';
import CountryUpdate from '@/components/admin/country/Update';
import CountryShow from '@/components/admin/country/Show';

export default [
  { name: 'CountryList', path: '/countries/', component: CountryList },
  {
    name: 'CountryCreate',
    path: '/countries/create',
    component: CountryCreate
  },
  {
    name: 'CountryUpdate',
    path: '/countries/edit/:id',
    component: CountryUpdate
  },
  { name: 'CountryShow', path: '/countries/show/:id', component: CountryShow }
];
