<template>
  <div>
    <div v-if="createErrors" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">
        {{ createErrors }}</span
      >
    </div>

    <h1 class="mb-4 text-primary">Create a Proposal</h1>
    <hr class="mb-4" />

    <ProposalForm
      :is-loading="createIsLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="createViolations"
    ></ProposalForm>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ProposalForm from './Form.vue';
import helpers from '@/mixins/helpers';

export default {
  components: {
    ProposalForm
  },

  mixins: [helpers],

  data() {
    return {
      item: {
        pricingType: 'LUMPSUM'
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),

    ...mapFields('program/create', {
      createErrors: 'error',
      createIsLoading: 'isLoading',
      created: 'created',
      createViolations: 'violations'
    })
  },

  watch: {
    created: function (created) {
      if (created) {
        if (this.item.savingDraft) {
          this.$router.push({
            name: 'ProposalList'
          });
        } else {
          let id = this.iriToId(created['@id']);
          this.$router.push({
            name: 'ProposalLearningModules',
            params: { id: id }
          });
        }
      }
    },
    item: {
      handler(val) {
        // if (this.createErrors) {
        //   this.reset();
        // }
      },
      deep: true
    }
  },

  mounted() {
    // Default
    this.item.providerId = this.getCompany.entity.company['@id'];
  },

  methods: {
    ...mapActions({
      getProtocolSelectItems: 'protocol/list/getSelectItems',
      getRelationSelectItems: 'relationship/list/getSelectItems',
      getThemeSelectItems: 'theme/list/getSelectItems'
    }),
    ...mapActions('program/create', ['create', 'reset']),

    onSendForm() {
      // Cast objects
      this.item.numberLearners = 0;

      this.item.subcontractorFee = this.item.subcontractorFee
        ? parseFloat(this.item.subcontractorFee)
        : 0;

      this.create(this.item);
    }
  },

  beforeDestroy() {
    this.reset();
  }
};
</script>

<style lang="scss"></style>
