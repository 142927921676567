<template>
  <b-card class="messages-card" footer-tag="footer" header-tag="header">
    <template #header v-if="!hideHeader">
      <div class="d-flex flex-row justify-content-center">
        <div class="d-flex flex-column text-right bg-info w-25 pt-3 pb-5 pr-2">
          Chat »
          <br />
          Subject »
        </div>
        <div class="pl-3 w-75 pt-3 pb-5">
          <span class="font-italic">
            {{ room.name }}
          </span>
          <br />
          <template v-if="isHandshake">
            <router-link
              :to="{ name: 'HandshakesList', params: { id: getHandshakeID() } }"
            >
              {{ namedChat }}
            </router-link>
          </template>
          <template v-else>
            {{ room.context }}
          </template>
        </div>
      </div>
      <!--      <b-input-group class="messages-search">-->
      <!--        <b-form-input placeholder="Search terms" type="search"></b-form-input>-->
      <!--        <i class="fa fa-search"></i>-->
      <!--      </b-input-group>-->
    </template>
    <template #header v-else>
      <div class="d-flex justify-content-between align-items-center">
        <span>
          {{ namedChat }}
        </span>
        <div class="p-1" @click="$emit('close')">
          <i class="fa fa-times" />
        </div>
      </div>
    </template>
    <template>
      <ul
        v-chat-scroll="{ always: true, smooth: true }"
        class="messages-list"
        v-if="room"
      >
        <li
          v-for="message in room.messages"
          :key="message['@id']"
          class="messages-list-item"
          :class="{ mine: isMineMessage(message) }"
        >
          <div class="arrow"></div>
          <span>
            {{ message['message'] }}
          </span>
        </li>
      </ul>
      <ul class="messages-list" v-else></ul>
    </template>

    <template #footer>
      <b-form
        v-if="getRoomId !== 'notifications'"
        @submit.prevent="sendMessage()"
      >
        <b-input-group>
          <b-form-input v-model="messageInput" />
          <b-input-group-append>
            <b-button type="submit" variant="success">Send</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
    </template>
  </b-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  CHAT_FETCH_MESSAGES,
  CHAT_FETCH_ROOM_BY_CONTEXT,
  CHAT_PARSE_MESSAGE
} from '../../../store/modules/chat/actions';
import _ from 'lodash';

export default {
  name: 'Chat',
  props: {
    selectedRoom: {
      type: Object,
      default: () => null
    },
    context: {
      type: String,
      default: () => null
    },
    hideHeader: {
      type: Boolean,
      default: () => false
    },
    showWithoutRoom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapGetters('chat', ['getRooms', 'getMessages', 'getRoomByContext']),
    ...mapFields('message/create', ['isLoading', 'created', 'error']),
    getRoomId() {
      return this.room && this.room['@id'] ? this.room['@id'] : '';
    },
    computedRoomName() {
      return (this.room && this.room.name) || this.context || '';
    },
    isHandshake() {
      return this.computedRoomName.includes('/handshakes/');
    },
    namedChat() {
      if (this.isHandshake)
        return (
          'Handshakes #' + this.computedRoomName.replace('/handshakes/', '')
        );
      return this.context;
    }
  },
  mounted() {
    if (this.selectedRoom !== null) {
      this.room = this.selectedRoom;
    }
    if (this.context !== null) {
      this.room = this.getRoomByContext(this.context);
    }
  },
  data() {
    return {
      messageInput: '',
      room: null
    };
  },

  watch: {
    /**
     * Associate brand-new room to current chat
     * @param rooms
     */
    getRooms: function (rooms) {
      if (!this.room) {
        this.room = this.getRoomByContext(this.context);
      }
    },
    selectedRoom: function (room) {
      this.room = room;
    },
    room: function (room) {
      this.fetchMessages();
    },
    created: function (created) {
      this.messageInput = '';
      // Fetch room first
      if (!this.room) {
        this[CHAT_FETCH_ROOM_BY_CONTEXT](this.contextß);
      }

      // Send it to store
      this[CHAT_PARSE_MESSAGE](created);
    }
  },

  methods: {
    ...mapActions('chat', [
      CHAT_FETCH_MESSAGES,
      CHAT_PARSE_MESSAGE,
      CHAT_FETCH_ROOM_BY_CONTEXT
    ]),
    ...mapActions('message/create', ['create']),

    isMineMessage(message) {
      return message.createdBy['@id'] === this.getUser.entity['@id'];
    },
    fetchMessages() {
      // Ignore for notifications
      if (
        this.room &&
        this.room['@id'] &&
        _.isEmpty(this.room.messages) &&
        this.room['@id'] !== 'notifications'
      ) {
        this[CHAT_FETCH_MESSAGES](this.room['@id']);
      }
    },
    getHandshakeID() {
      if (this.isHandshake) {
        return this.computedRoomName.replace('/handshakes/', '');
      }
      return 0;
    },
    sendMessage() {
      this.create({
        room: { id: (this.room && this.room.context) || this.context },
        message: this.messageInput
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.messages-card {
  background-color: $white;
  border: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
}

.messages-list {
  margin: 0;
  width: 100%;
  height: 58vh;
  padding: 0;
  display: block;
  overflow-y: auto;

  .messages-list-item {
    list-style-type: none;
    color: white;
    display: block;
    float: left;
    clear: both;
    margin: 5px;
    padding: 10px;
    border-radius: 10px;
    background-color: $primary;
    text-align: left;
    position: relative;

    &.mine {
      background: $secondary;
      margin-right: 23px;
      float: right;

      .arrow {
        left: calc(100% + -8px);
        top: 0px;
        border-top: 0px solid transparent;
        border-bottom: 15px solid transparent;
        border-left: 15px solid $secondary;
        border-right: 15px solid transparent;
      }
    }
    .arrow {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: -7px;
      top: 0px;
      border-top: 0px solid transparent;
      border-bottom: 15px solid transparent;
      border-right: 15px solid $primary;
    }
  }
}
</style>
