<template>
  <div>
    <b-form>
      <b-row align-h="center">
        <b-col cols="6">
          <b-form-group label="Name" label-for="name">
            <b-form-input id="name" v-model="company.name" type="text" disabled>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="VAT" label-for="vat">
            <b-form-input
              id="name"
              v-model="company.fiscal_id"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6">
          <b-form-group label="Type" label-for="type">
            <b-form-input id="type" v-model="company.type" type="text" disabled>
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Status" label-for="status">
            <b-form-input
              id="status"
              v-model="company.status"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6">
          <b-form-group label="isAmplifica" label-for="isAmplifica">
            <b-form-input
              id="isAmplifica"
              v-model="company.isAmplifica"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="isAccountant" label-for="isAccountant">
            <b-form-input
              id="isAccountant"
              v-model="company.isAccountant"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6">
          <b-form-group label="isCreator" label-for="isProvider">
            <b-form-input
              id="isProvider"
              v-model="company.isProvider"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="isInstructor" label-for="isInstructor">
            <b-form-input
              id="isInstructor"
              v-model="company.isInstructor"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6">
          <b-form-group label="Email Address" label-for="email">
            <b-form-input
              id="email"
              v-model="company.email"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Phone" label-for="phone">
            <b-form-input
              id="phone"
              v-model="company.phone"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row align-h="center">
        <b-col cols="6">
          <b-form-group label="Email Address" label-for="email">
            <b-form-input
              id="email"
              v-model="company.email"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Phone" label-for="phone">
            <b-form-input
              id="phone"
              v-model="company.phone"
              type="text"
              disabled
            >
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <CustomButton
      type="Default"
      :content="{ title: 'Back' }"
      @click.native="close"
    />
  </div>
</template>

<script>
import CustomButton from '@/components/_ui/CustomButton';

export default {
  name: 'CompanyShow',

  components: {
    CustomButton
  },

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  methods: {
    close() {
      this.$bvModal.hide('companyShow');
    }
  }
};
</script>
