import { isArray, isObject, isUndefined, forEach } from 'lodash';
import {
  AUTH_SERVICE_ENTRYPOINT,
  BUSINESS_SERVICE_ENTRYPOINT,
  COMMUNICATION_SERVICE_ENTRYPOINT,
  DOC_SERVICE_ENTRYPOINT,
  ENTITY_SERVICE_ENTRYPOINT
} from '../config/entrypoint';
import SubmissionError from '../error/SubmissionError';
import store from '../store/index';

const MIME_TYPE = 'application/ld+json';

const transformRelationToIri = (payload) => {
  forEach(payload, (value, property) => {
    if (isObject(value) && !isUndefined(value['@id'])) {
      payload[property] = value['@id'];
    }

    if (isArray(value)) payload[property] = transformRelationToIri(value);
  });

  return payload;
};

let service = function (id, options = {}, serviceUrl) {
  if ('undefined' === typeof options.headers) options.headers = new Headers();

  if (null === options.headers.get('Accept'))
    options.headers.set('Accept', MIME_TYPE);

  if (
    'undefined' !== options.body &&
    !(options.body instanceof FormData) &&
    null === options.headers.get('Content-Type')
  )
    options.headers.set('Content-Type', MIME_TYPE);

  // Add token
  let token = store.getters['auth/getAccessToken'];
  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
  }

  if (!(options.body instanceof FormData)) {
    const payload = options.body && JSON.parse(options.body);
    if (isObject(payload) && payload['@id'])
      options.body = JSON.stringify(transformRelationToIri(payload));
  }

  const url = new URL(id, serviceUrl);
  if (options.params) {
    let searchParams = new URLSearchParams(options.params).toString();
    url.search = searchParams;
  }

  return global.fetch(url, options).then((response) => {
    if (response.ok) return response;

    if (response.status === 403) {
      window.location.replace('/logout');
    }

    return response.json().then((json) => {
      const error = json['hydra:description'] || response.statusText;
      if (!json.violations) throw Error(error);

      let errors = { _error: error };
      json.violations.map(
        (violation) => (errors[violation.propertyPath] = violation.message)
      );

      throw new SubmissionError(errors);
    });
  });
};

// -------------- Start for each service --------------
export const entityService = (id, options = {}) => {
  return service(id, options, ENTITY_SERVICE_ENTRYPOINT);
};
export const authService = (id, options = {}) => {
  return service(id, options, AUTH_SERVICE_ENTRYPOINT);
};
export const businessLogicService = (id, options = {}) => {
  return service(id, options, BUSINESS_SERVICE_ENTRYPOINT);
};
export const communicationService = (id, options = {}) => {
  return service(id, options, COMMUNICATION_SERVICE_ENTRYPOINT);
};
export const docService = (id, options = {}) => {
  return service(id, options, DOC_SERVICE_ENTRYPOINT);
};
