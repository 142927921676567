<template>
  <div>
    <div v-if="isLoading">
      <b-spinner></b-spinner>
    </div>
    <b-form>
      <div v-if="typeError" class="alert alert-danger" role="alert">
        <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
          typeError
        }}</span>
      </div>

      <table class="table table-hover">
        <thead>
          <tr>
            <th>Accountant Types</th>
            <th width="10"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="countryAccountantType in items"
            :key="countryAccountantType['@id']"
          >
            <td>{{ countryAccountantType.type }}</td>
            <td>
              <a
                v-if="!deleteIsLoading(countryAccountantType['@id'])"
                href="#"
                @click.prevent="del(countryAccountantType)"
                ><i class="fa fa-trash"></i
              ></a>
              <a v-else href="#"><b-spinner small /></a>
            </td>
          </tr>
          <tr>
            <td>
              <b-input-group>
                <b-form-input
                  v-model="newType.type"
                  type="text"
                  placeholder="Insert Type..."
                ></b-form-input>
              </b-input-group>
            </td>

            <td>
              <b-button v-if="typeIsLoading" variant="primary" disabled>
                <b-spinner small></b-spinner>
              </b-button>
              <b-button
                v-else
                @click.prevent="addCountryType"
                :disabled="canSubmit"
                class="float-right"
                variant="primary"
                type="button"
                ><i class="fas fa-plus position-icon"></i> Add
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-form>
  </div>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import dayjs from 'dayjs';
import { SwalAlert } from '@/utils/swal';

export default {
  props: {
    countryId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      newType: {
        country: this.countryId,
        type: null
      },
      deleting: {}
    };
  },

  created() {
    this.getPage(`${this.countryId}/country-accountant-types`);
  },

  computed: {
    ...mapFields('countryaccountanttype/del', ['deleted']),

    ...mapFields('countryaccountanttype/list', {
      error: 'error',
      isLoading: 'isLoading',
      items: 'items'
    }),

    ...mapFields('countryaccountanttype/create', {
      typeError: 'error',
      typeIsLoading: 'isLoading',
      typeCreated: 'created',
      typeReset: 'reset',
      typeViolations: 'violations'
    }),

    canSubmit() {
      return !this.newType.type;
    }
  },

  watch: {
    typeCreated: function (created) {
      if (!created) {
        return;
      }

      this.resetState();
      this.refreshData();
    },

    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.refreshData();
    }
  },

  methods: {
    dayjs,

    ...mapActions({
      getPage: 'countryaccountanttype/list/default',
      typeCreate: 'countryaccountanttype/create/create',
      typeDelete: 'countryaccountanttype/del/del'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(`${this.countryId}/country-accountant-types`);
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    addCountryType() {
      this.typeCreate(this.newType);
    },

    resetState() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },

    del(item) {
      SwalAlert({
        title: 'Delete type',
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        type: 'danger',
        icon: 'delete',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning',
            callback: () => {}
          },
          confirm: {
            label: 'Yes',
            type: 'danger',
            callback: () => {
              let itemId = item['@id'];
              this.deleting[itemId] = true;
              this.typeDelete({ '@id': itemId });
            }
          }
        }
      });
    },

    deleteIsLoading(countryTypeId) {
      return this.deleting.hasOwnProperty(countryTypeId);
    }
  },

  beforeDestroy() {
    this.typeReset();
  }
};
</script>
