<template>
  <b-container fluid>
    <h3 class="title">
      <i class="fa fa-euro-sign text-primary mr-2" /> Ready for invoicing
    </h3>

    <b-row>
      <b-table
        :fields="fields"
        :items="items"
        :busy="listIsLoading"
        class="customTable mt-3 administrationInvoiceable"
        select-mode="single"
        small
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(program)="row">
          <span @click="row.toggleDetails">
            <span>{{ row.item.program }}</span>
            <b-spinner v-if="row.item.openLoading" small></b-spinner>
            <i v-else-if="row.detailsShowing" class="fa fa-caret-down" />
            <i v-else class="fa fa-caret-right" />
          </span>
        </template>

        <template #row-details="row">
          <Details :program="row.item" @selected="onSubrowSelected"></Details>
        </template>
      </b-table>
    </b-row>
    <b-row align-h="end">
      <b-button
        v-if="!isLoading"
        class="text-right m-3"
        variant="primary"
        @click="createInvoices"
        :disabled="selectedIds.length === 0"
        >Create Invoices >
      </b-button>
      <b-button v-else class="text-right m-3" variant="primary" disabled
        ><b-spinner small
      /></b-button>
    </b-row>
  </b-container>
</template>

<script>
// TODO: check logic
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';
import Details from './Details';
import { SwalAlert } from '@/utils/swal';

export default {
  components: {
    Details
  },
  data() {
    return {
      selected: {},
      isLoading: false,
      fields: [
        { key: 'name', label: 'Program', sortable: true },
        { key: 'program', label: '' },
        {
          key: 'status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'date',
          label: 'Date',
          sortable: true
        },
        {
          key: 'actions',
          label: ''
        }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      filterOn: []
    };
  },
  computed: {
    ...mapGetters('auth', ['isRole', 'getUser', 'getCompany']),
    ...mapFields('program/list', {
      error: 'error',
      items: 'items',
      view: 'view',
      totalItems: 'totalItems',
      listIsLoading: 'isLoading'
    }),
    selectedIds() {
      let selected = [];
      for (let s in this.selected) {
        if (this.selected[s].selected) {
          selected.push(s);
        }
      }
      return selected;
    },

    hasOnHold() {
      let onHold = false;
      for (let s in this.selected) {
        if (this.selected[s].isOnHold) {
          onHold = true;
          break;
        }
      }
      return onHold;
    },
    getCompanyId() {
      return this.getUser.company.entity
        ? this.getUser.company.entity.company['@id']
        : '';
    }
  },

  mounted() {
    this.getPage(
      'programs?status[]=PENDING&status[]=EXECUTED&status[]=INVOICED&status[]=COMPLETED'
    );
  },
  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    refreshData() {
      localStorage.setItem('currentPage', 1);

      this.getPage(
        'programs?status[]=PENDING&status[]=EXECUTED&status[]=INVOICED&status[]=COMPLETED'
      );
    },

    onRowSelected(item) {
      this.$set(item, 'selected', !item.selected);
    },
    onSubrowSelected({ item, selected }) {
      this.$set(this.selected, item['@id'], item);
    },
    processInvoices(forceMerge = false) {
      // Forcing invoiceables?
      let url = `${BUSINESS_SERVICE_ENTRYPOINT}/invoiceables/merge`;
      if (forceMerge) {
        url += '?force=1';
      }

      fetch(url, {
        method: 'POST',
        body: JSON.stringify(this.selectedIds)
      })
        .then((response) => response.json())
        .then((data) => {
          this.refreshData();

          SwalAlert({
            title: 'Invoices created',
            icon: 'user',
            type: 'success',
            buttons: {
              cancel: {
                label: 'View invoices',
                type: 'warning',
                callback: () => {
                  this.$router.push('/administration/invoices');
                }
              },
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });
        })
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            description: 'Could not create document for invoice',
            icon: 'error',
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger',
                callback: () => {}
              }
            }
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    createInvoices() {
      this.isLoading = true;

      // Check for onHold
      if (this.hasOnHold) {
        SwalAlert({
          title: 'Alert',
          description:
            'Some of the invoiceables are <b>on hold</b>, are you sure you want to continue?',
          icon: 'user',
          type: 'success',
          buttons: {
            cancel: {
              label: 'Cancel',
              type: 'warning',
              callback: () => {
                this.isLoading = false;
                return;
              }
            },
            confirm: {
              label: 'Yes, please invoice them!',
              type: 'success',
              callback: () => {
                this.processInvoices(true);
              }
            }
          }
        });
      } else {
        this.processInvoices();
      }
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    }
  }
};
</script>