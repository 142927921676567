<template>
  <div>
    <b-form @submit.prevent="handleSubmit(item)">
      <b-form-group
        label="Name"
        label-for="country_name"
        :invalid-feedback="violations.name"
        :state="!isValid('name')"
      >
        <b-form-input
          :disabled="!editMode"
          id="country_name"
          v-model="item.name"
          type="text"
          required="required"
          placeholder=""
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Code"
        label-for="country_name"
        :invalid-feedback="violations.code"
        :state="!isValid('code')"
      >
        <b-form-input
          :disabled="!editMode"
          id="country_code"
          v-model="item.code"
          type="text"
          required="required"
          maxlength="2"
          placeholder=""
        >
        </b-form-input>
      </b-form-group>

      <b-form-group
        label="Language"
        label-for="country_language"
        :invalid-feedback="violations.code"
        :state="!isValid('language')"
      >
        <b-form-select
          :disabled="!editMode"
          v-model="item.language"
          id="country_language"
          class="form-control"
        >
          <b-form-select-option
            v-for="selectItem in languageSelectItems"
            :key="selectItem['@id']"
            :value="selectItem['@id']"
            :selected="isSelected('language', selectItem['@id'])"
            >{{ selectItem.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Currency"
        label-for="currency"
        class="font-weight-bold"
      >
        <b-form-select v-if="!currencyItems" disabled value="">
          <b-form-select-option selected value=""
            >Loading...</b-form-select-option
          >
        </b-form-select>
        <b-form-select
          v-else
          :disabled="!editMode"
          v-model="item.currency"
          id="currency"
          :options="currencyItems"
          text-field="code"
          value-field="code"
          required
        >
        </b-form-select>
      </b-form-group>

      <b-form-group class="float-right">
        <b-button
          variant="danger"
          class="mr-2"
          @click="toggleEditMode"
          :disabled="editMode && !isLoading"
          >Cancel</b-button
        >

        <b-button
          variant="primary"
          type="button"
          @click.prevent="toggleEditMode"
          v-if="!editMode"
          >Edit Country</b-button
        >
        <b-button variant="primary" type="button" disabled v-else-if="isLoading"
          ><b-spinner small></b-spinner
        ></b-button>
        <b-button variant="primary" type="submit" v-else>Save Country</b-button>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { find, get, isUndefined } from 'lodash';
import { entityService } from '@/utils/fetch';

export default {
  name: 'CountryName',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    handleSubmit: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      editMode: false,
      currencyItems: []
    };
  },

  watch: {
    /**
     * Enter edit mode after an update
     * @param isLoading
     */
    isLoading: function (isLoading) {
      if (!isLoading) {
        this.editMode = false;
      }
    }
  },

  created() {
    this.languageGetSelectItems();
    this.getCurrencies();

    if (this.item.currency) {
      this.item.currency = this.item.currency['code'];
    }
  },

  computed: {
    ...mapFields('language/list', {
      languageSelectItems: 'selectItems'
    }),
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    violations() {
      return this.errors || {};
    }
  },

  methods: {
    ...mapActions({
      languageGetSelectItems: 'language/list/getSelectItems'
    }),

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createCountry');
    },

    toggleEditMode() {
      this.editMode = !this.editMode;
    },

    getCurrencies() {
      entityService('/currencies')
        .then((response) => response.json())
        .then((data) => {
          this.currencyItems = data['hydra:member'];
        });
    }
  }
};
</script>

<style scoped lang="scss">
.font-size {
  // font-size: 14px;
}

.form-control,
.custom-select {
  border-radius: 8px;
  border: 2px solid $gray-200;
  padding: 0px 12px !important;
}

.label-input {
  margin-left: 10px !important;
}
</style>
