<template>
  <div class="show">
    <h2 class="show__title">Check Details</h2>

    <div v-if="!handshakeIsLoading">
      <b-row>
        <b-col cols="12">
          <component
            :is="handshakeItem.type"
            @isSubmitAllowed="isSubmitAllowed"
            :handshake="handshakeItem"
            :admins="admins"
            :canAcceptOrReject="canAcceptOrReject"
            @updated="getContent"
          />
        </b-col>

        <Sidechat :context="handshakeItem['@id']" />

        <!-- <b-col cols="6">
          <b-spinner
            v-if="conversationIsLoading"
            class="align-middle mt-5"
            variant="primary"
          ></b-spinner>
          <div v-else-if="conversation" class="chat">
            <Chat :conversation="conversation" :hide-header="true" />
          </div>
        </b-col> -->
      </b-row>

      <div class="show__buttons">
        <div class="show__back">
          <CustomButton
            type="Default"
            :content="{ title: 'Back' }"
            @click.native="$router.go(-1)"
          />
        </div>

        <div class="show__actions" v-if="canAcceptOrReject">
          <b-button
            variant="danger"
            class="show__actions--reject"
            type="Default"
            @click.prevent="
              handshakeAcceptOrReject(handshakeItem['@id'], 'to_rejected')
            "
            >Reject
          </b-button>
          <b-button
            variant="success"
            :disabled="!canSubmit"
            class="show__actions--accept"
            type="Default"
            @click.prevent="
              handshakeAcceptOrReject(handshakeItem['@id'], 'to_approved')
            "
            >Accept
          </b-button>
        </div>
      </div>
    </div>

    <div class="text-center text-danger my-2" v-else>
      <b-spinner class="align-middle mt-5" variant="primary"></b-spinner>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomButton from '@/components/_ui/CustomButton';
import helpers from '@/mixins/helpers';
import handshakes from '@/mixins/handshakes';
import CompanyAnalysisQuestionsHandshake from './types/CompanyAnalysisQuestionsHandshake';
import AccountantSignupHandshake from './types/AccountantSignupHandshake';
import ProviderSignupHandshake from './types/ProviderSignupHandshake';
import CompanyJoinHandshake from './types/CompanyJoinHandshake';
import AprovaReportHandshake from './types/AprovaReportHandshake';
import ProRataHandshake from './types/ProRataHandshake';
import Sidechat from '../chat/Widget/Sidechat';
export default {
  name: 'HandshakeShow',

  components: {
    CustomButton,
    AccountantSignupHandshake,
    CompanyAnalysisQuestionsHandshake,
    AprovaReportHandshake,
    ProviderSignupHandshake,
    CompanyJoinHandshake,
    ProRataHandshake,
    Sidechat
  },

  mixins: [helpers, handshakes],

  computed: {
    ...mapGetters('auth', ['getUser', 'getCompanyId', 'isRole']),
    ...mapFields('company/show', {
      error: 'error',
      isLoading: 'isLoading',
      item: 'retrieved'
    }),

    ...mapFields('handshake/show', {
      handshakeError: 'error',
      handshakeIsLoading: 'isLoading',
      handshakeItem: 'retrieved'
    }),

    admins() {
      if (!this.item) {
        return false;
      }
      return this.item.employees.filter((employee) => {
        return employee.isAdmin === true;
      });
    },

    conversation() {
      let handshakeIri = `/handshakes/${decodeURIComponent(
        this.$route.params.id
      )}`;
      return this.getConversationByContext(handshakeIri) || {};
    },

    canAcceptOrReject() {
      // Some route metadata that forceDisable things
      if (
        this.$route.params.disableActions ||
        this.handshakeItem.status !== 'PENDING'
      ) {
        return false;
      }

      // Admin can do anything
      if (this.isRole('ROLE_ADMIN')) {
        return true;
      }

      if (
        this.handshakeItem.requesteeCompany &&
        this.getCompanyId === this.handshakeItem.requesteeCompany['@id']
      ) {
        return true;
      }

      if (
        this.handshakeItem.requesteeUser &&
        this.getUser.id ===
          parseInt(this.iriToId(this.handshakeItem.requesteeUser['@id']))
      ) {
        return true;
      }

      return false;
    }
  },

  data() {
    return {
      canSubmit: false,
      isChatOpen: false
    };
  },

  mounted() {
    this.getContent();
  },

  methods: {
    ...mapActions({
      reset: 'company/show/reset',
      retrieve: 'company/show/retrieve',
      handshakeRetrieve: 'handshake/show/retrieve'
    }),

    isSubmitAllowed(val) {
      this.canSubmit = val;
    },

    getContent() {
      let handshakeIri = `/handshakes/${decodeURIComponent(
        this.$route.params.id
      )}`;
      this.handshakeRetrieve(handshakeIri);

      // Retrieve conversation
    }
  },

  beforeDestroy() {
    this.reset();
  },

  watch: {
    handshakeItem: function (handshake) {
      this.retrieve(handshake.company['@id']);
    }
  }
};
</script>

<style lang="scss" scoped>
.show {
  position: relative;

  &__title {
    // font-size: 20px;
    font-family: $default-font-family;
    color: $primary;
    margin-bottom: 50px;
  }

  &__content {
    margin-top: 25px;
  }

  &__item {
    margin-top: 20px;

    &__title {
      font-family: $default-font-family;
      color: $primary;
      font-weight: bold;

      &--black {
        color: $black;
      }
    }
  }

  &__buttons {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 100px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;

    &--accept {
      button {
        background: $success;
        border: 1px solid $success;
      }
    }

    &--reject {
      margin-right: 10px;

      button {
        background: $danger;
        border: 1px solid $danger;
      }
    }
  }

  .chat__holder {
    position: absolute;
    top: -50px;
    right: 0;

    background-color: $primary;
    padding: 10px;
    color: $white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    cursor: pointer;
  }
}

.chat {
  height: 500px;
  position: relative;

  .messages-card {
    height: 100%;
  }
}
</style>
