<template>
  <b-container fluid>
    <h6>
      <i class="fa fa-user-plus text-primary"></i>
      <strong class="ml-2">Onboarding | Company Information</strong>
    </h6>

    <div v-if="!isCompanyRequired">
      <b-form-checkbox
        id="complete_later"
        class="mt-4"
        v-model="completeCompanyRegistration"
        name="complete_later"
        :value="true"
        :unchecked-value="false"
        >I want to complete the Company registration now.</b-form-checkbox
      >
      <hr />
    </div>

    <AccountantData v-else-if="step === 1" @next="onAccountantData" />

    <CompanyCreate
      v-if="(isCompanyRequired && step === 2) || completeCompanyRegistration"
      :skipCompanyRoles="true"
      :skip-last-step="true"
      :returnIfExists="true"
      @created="onCompanyCreated"
      :companyRoles="companyRoles"
      :skipCompanyAdmin="true"
      :is-onboarding="true"
      :role="role"
      :is-creating-accountant="role === 'Accountant'"
      :availableCompanyTypes="availableCompanyTypes"
      :is-only-commercial="role === 'Accountant'"
      :isRoleRequest="isRoleRequest"
    />

    <b-form-row class="float-left">
      <b-button type="button" variant="secondary" @click="goBack"
        >Back</b-button
      >
    </b-form-row>

    <b-form-row class="float-right">
      <div v-if="companyRoles === 'Learner' && !completeCompanyRegistration">
        <b-button
          v-if="!asyncLoading"
          type="button"
          variant="primary"
          @click="doCompleteLearnerWithoutCompany()"
          >Complete registration</b-button
        >
        <b-button v-else disabled type="button" variant="primary">
          <b-spinner small />
        </b-button>
      </div>

      <div v-else-if="isCompanyRetrieved">
        <b-button
          type="button"
          variant="primary"
          v-if="!isLoadingHandshake"
          @click="doRequestCompanyAccess()"
          >Request access to company</b-button
        >
        <b-button v-else disabled type="button" variant="primary">
          <b-spinner small />
        </b-button>
      </div>
    </b-form-row>
  </b-container>
</template>

<script>
import { find, get, isUndefined } from 'lodash';
import { mapFields } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import CompanyCreate from '@/components/dashboard/company/Create.vue';
import AccountantData from '@/components/welcome/AccountantData/Main.vue';
import { SwalAlert } from '@/utils/swal';
import { entityService } from '@/utils/fetch.js';
import { AUTH_REFRESH_TOKEN } from '@/store/modules/auth/actions';

export default {
  components: {
    CompanyCreate,
    AccountantData
  },

  props: {
    companyRoles: {
      type: String,
      default: null
    },

    isRoleRequest: {
      type: Boolean,
      default: false
    },

    role: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapFields('country/list', {
      countrySelectItems: 'selectItems'
    }),
    ...mapGetters('auth', ['getUser', 'getToken', 'getRole']),
    ...mapFields('handshake/create', {
      isLoadingHandshake: 'isLoading',
      createdHandshake: 'created',
      errorHandshake: 'error'
    }),
    isCompanyRetrieved: function () {
      return !!this.retrievedCompany;
    },
    isCompanyRequired: function () {
      return ['Accountant', 'Provider'].indexOf(this.companyRoles) !== -1;
    }
  },

  watch: {
    errorHandshake: function (error) {
      if (!error) {
        return;
      }
      this.showError(error);
    },
    createdHandshake: function (created) {
      if (!created) {
        return;
      }
      // Swal, invitation sent now must wait
      this.showSuccess(
        `Your request to join <b>${this.retrievedCompany.name}</b> was sent. Once approved, you will be able to fully use AmplificA`
      );
    }
  },

  data() {
    return {
      step: 1,
      asyncLoading: false,
      retrievedCompany: null,
      completeCompanyRegistration: false,
      metadata: {},
      availableCompanyTypes: [],
      newAccess: null
    };
  },

  created() {
    this.setAvailableCompanyTypes();
    // Skip step 1 for non-accountant
    if (this.companyRoles !== 'Accountant') {
      this.step = 2;
    }
  },

  methods: {
    ...mapActions('handshake/create', { createHandshake: 'create' }),
    ...mapActions('auth', [AUTH_REFRESH_TOKEN]),

    goBack() {
      // Internal go back
      let newStep = this.step - 1;

      // Skip always 1 extra step for non-accountant
      if (this.companyRoles !== 'Accountant') {
        newStep--;
      }

      if (newStep > 0) {
        return (this.step = newStep);
      }

      // External go back
      this.retrievedCompany = null;
      this.$emit('previous', true);
    },

    isSelected(collection, id) {
      return find(this.item[collection], ['@id', id]) !== undefined;
    },

    isValid(key) {
      return isUndefined(get(this.violations, key));
    },

    closeModal() {
      this.$bvModal.hide('createUser');
    },

    showSuccess(htmlMessage) {
      SwalAlert({
        title: 'Completed!',
        icon: 'user',
        type: 'success',
        buttons: {
          confirm: {
            label: 'Go to Dashboard',
            type: 'success'
          }
        }
      }).then((result) => {
        // Refresh user token and go to /
        this.AUTH_REFRESH_TOKEN({
          refresh_token: this.getToken.refresh_token
        }).then(() => {
          // All good!
          if (this.isRoleRequest) {
            this.$emit('close');
          } else {
            this.$router.push('/');
          }
        });
      });
    },

    showError(error) {
      SwalAlert({
        title: 'Error',
        icon: 'error',
        description: error,
        type: 'danger',
        buttons: {
          confirm: {
            label: 'OK',
            type: 'danger'
          }
        }
      });
    },

    doRequestCompanyAccess() {
      // Send handshake
      this.createHandshake({
        type: 'CompanyJoinHandshake',
        requesteeCompany: this.retrievedCompany['@id'],
        metadata: this.metadata || {}
      });
    },

    doCompleteLearnerWithoutCompany() {
      // Mark loadinmg
      this.asyncLoading = true;
      // Update user to welcomed
      entityService(this.getUser.entity['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({ isWelcomed: true })
      })
        .then((response) => response.json())
        .then((data) => {
          this.showSuccess();
        })
        .catch((e) => {
          this.showError(e);
        })
        .finally((e) => {
          this.asyncLoading = false;
        });
    },

    onAccountantData(accountantData) {
      this.step++;
    },

    onCompanyCreated(item, retrieved = false) {
      localStorage.setItem(
        'newAccess',
        JSON.stringify({
          role: this.getRole,
          company: item['@id']
        })
      );
      // Send handshake
      if (retrieved) {
        this.retrievedCompany = item;
      }
      // Company created, all good.
      else {
        this.$bvModal.hide('chooseRole');
        this.$bvModal.hide('switchRole');

        const message =
          item.status === 'ENABLED' || this.isRoleRequest
            ? `Your company <b>${item.name}</b> was created with you as administrator. <br>Happy Learning!`
            : `Your company <b>${item.name}</b> requires approval, once it's approved you can fully enjoy AmplificA.`;
        this.showSuccess(message);
      }
    },

    setAvailableCompanyTypes() {
      if (this.companyRoles === 'Provider') {
        this.availableCompanyTypes = ['OTHER'];
      } else if (this.companyRoles === 'Learner') {
        this.availableCompanyTypes = ['OTHER', 'PERSON', 'GOVERNMENTAL'];
      }
    }
  }
};
</script>
