<template>
  <b-container fluid>
    <b-form>
      <b-container id="role_switcher">
        <div v-if="showError" class="alert alert-danger" role="alert">
          <span class="fa fa-exclamation-triangle" aria-hidden="true">
            All fields are required
          </span>
        </div>

        <b-form-group
          label="Country *"
          label-for="country"
          class="font-weight-bold"
        >
          <v-select
            required
            v-model="data.country"
            id="company_fiscal_id_country"
            :options="countrySelectItems"
            label="name"
            @input="fetchTypeAndOrganizations($event['@id'])"
          >
            <template v-slot:option="option">
              {{ option.name }}
            </template>
          </v-select>
        </b-form-group>

        <b-form-group
          label="Accountant Registration Number *"
          label-for="accountant_number"
          class="font-weight-bold"
        >
          <b-form-input
            id="article_name"
            v-model="data.accountantRegistrationNumber"
            type="text"
            required="required"
            placeholder=""
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Professional Organization *"
          label-for="accountant_professional_organization"
          class="font-weight-bold"
        >
          <b-form-select
            v-if="countryAccountantOrganizationIsLoading"
            disabled
            value=""
          >
            <b-form-select-option selected value=""
              >Loading...</b-form-select-option
            >
          </b-form-select>
          <b-form-select
            v-else
            v-model="data.accountantProfessionalOrganization"
            id="accountant_professional_organization"
            :options="countryAccountantOrganization"
            :disabled="countryAccountantOrganization.length === 1"
            text-field="name"
            value-field="@id"
            required
          >
          </b-form-select>
        </b-form-group>

        <b-form-group
          label="Accountant Registration Type *"
          label-for="accountant_Type"
          class="font-weight-bold"
        >
          <b-form-select
            v-if="countryAccountantTypeIsLoading"
            disabled
            value=""
          >
            <b-form-select-option selected value=""
              >Loading...</b-form-select-option
            >
          </b-form-select>
          <b-form-select
            v-else
            v-model="data.accountantRegistrationType"
            id="accountant_type"
            :options="countryAccountantType"
            text-field="type"
            value-field="@id"
            required
          >
          </b-form-select>
        </b-form-group>
        <b-form-row class="float-right">
          <b-button
            v-if="!isLoading"
            type="button"
            variant="primary"
            :disabled="!canSubmit"
            @click="handleSubmit"
            >Next</b-button
          >
          <b-button v-else disabled type="button" variant="primary">
            <b-spinner small />
          </b-button>
        </b-form-row>
      </b-container>
    </b-form>
  </b-container>
</template>

<script>
import { SwalAlert } from '@/utils/swal';
import { entityService } from '@/utils/fetch.js';
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapGetters('auth', ['getUser']),

    ...mapFields('country/list', {
      countrySelectItems: 'items'
    }),

    ...mapFields('countryaccountantorganization/list', {
      countryAccountantOrganization: 'items',
      countryAccountantOrganizationIsLoading: 'isLoading'
    }),

    ...mapFields('countryaccountanttype/list', {
      countryAccountantType: 'items',
      countryAccountantTypeIsLoading: 'isLoading'
    }),

    canSubmit() {
      return (
        this.data.accountantProfessionalOrganization.length > 0 &&
        this.data.accountantRegistrationType.length > 0 &&
        this.data.accountantRegistrationNumber !== ''
      );
    }
  },

  mounted() {
    this.getCountries();
  },

  data() {
    return {
      isLoading: false,
      showError: false,
      data: {
        accountantProfessionalOrganization: '',
        accountantRegistrationType: '',
        accountantRegistrationNumber: ''
      }
    };
  },

  methods: {
    ...mapActions({
      getCountries: 'country/list/default',
      getCountryProfessionalOrganization:
        'countryaccountantorganization/list/default',
      getCountryAccountantTypes: 'countryaccountanttype/list/default'
    }),

    fetchTypeAndOrganizations(id) {
      this.getCountryProfessionalOrganization(
        `${id}/country-accountant-organizations`
      );
      this.getCountryAccountantTypes(`${id}/country-accountant-types`);
    },

    handleSubmit() {
      this.isLoading = true;
      localStorage.setItem('country', JSON.stringify(this.data.country));

      entityService(this.getUser.entity['@id'], {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify(this.data)
      })
        .then((response) => response.json())
        .then((data) => {})
        .catch((e) => {
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: e,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        })
        .finally((e) => {
          this.isLoading = false;
        });

      localStorage.setItem('metadata', JSON.stringify(this.data));
      this.$emit('next', { ...this.data });
    }
  },

  watch: {
    countryAccountantOrganization: {
      deep: true,

      handler() {
        if (this.countryAccountantOrganization.length === 1) {
          this.data.accountantProfessionalOrganization =
            this.countryAccountantOrganization[0]['@id'];
        }
      }
    }
  }
};
</script>
