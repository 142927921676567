import ImportedRelations from '@/components/dashboard/relationship/customers/import/List';
import FinancialImportImportedList from '@/components/dashboard/financial-import/imported/List';

export default [
  {
    name: 'ToolboxImportedRelations',
    path: '/toolbox/imported/relations',
    component: ImportedRelations,
    props: true
  },
  {
    name: 'ToolboxImportedInvoices',
    path: '/toolbox/imported/invoices',
    component: FinancialImportImportedList,
    props: true
  }
];
