<template>
  <b-card id="pricingTable" class="pl-4">
    <b-card-header class="bg-secondary text-white">
      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title"></b-col>
        <b-col class="values"
          ><h4 class="text-white mt-3 mb-3 font-weight-bolder">
            Calculation
          </h4></b-col
        >
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">Name:</b-col>
        <b-col class="values">{{ program.name }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1" v-if="!program.isOpenEnrollment">
        <b-col cols="4" class="title">Protocol:</b-col>
        <b-col class="values"
          ><span v-if="pricing.data.protocol">{{
            pricing.data.protocol.name
          }}</span></b-col
        >
      </b-form-row>

      <b-form-row class="pl-1 pr-1" v-if="!program.isOpenEnrollment">
        <b-col cols="4" class="title">Proposal for:</b-col>
        <b-col class="values">{{ getCustomerName }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">Pricing Type:</b-col>
        <b-col class="values">{{
          program.calculateGeneralCosts ? 'Per Program' : 'Per Module'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title pb-3">Calculation Method:</b-col>
        <b-col class="values pb-3"> {{ program.pricingType }}</b-col>
      </b-form-row>
    </b-card-header>

    <div v-if="!pricing.isLoading">
      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title pt-3">Pratica price:</b-col>
        <b-col class="values pt-3">{{
          formatCurrency(getPricing.practica) || '...'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">Learning Material:</b-col>
        <b-col class="values">{{
          formatCurrency(getPricing.learningMaterial) || '...'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">Other:</b-col>
        <b-col class="values">{{
          formatCurrency(getPricing.other) || '...'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title"></b-col>
        <b-col class="values">
          <hr class="bg-secondary" />
        </b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">Total:</b-col>
        <b-col class="values">{{
          formatCurrency(getPricing.total, true) || '...'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1" v-if="program.downpaymentPercentage">
        <b-col cols="4" class="title">Pay Now:</b-col>
        <b-col class="values">
          {{
            formatCurrency(
              getPricing.total * (program.downpaymentPercentage / 100)
            ) || '...'
          }}
        </b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title pt-4"></b-col>
        <b-col class="values pt-4"
          ><h4 class="font-weight-bolder">Cashflow</h4></b-col
        >
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">{{
          getCompany.entity.company.name
        }}</b-col>
        <b-col class="values">{{
          formatCurrency(getPricing.provider) || '...'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title">Taxes</b-col>
        <b-col class="values">{{
          formatCurrency(getPricing.taxes) || '...'
        }}</b-col>
      </b-form-row>

      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title pb-3"
          >{{
            getPricing.protocol ? getPricing.protocol.feeBrandname : 'AmplificA'
          }}
        </b-col>
        <b-col class="values pb-3">{{
          formatCurrency(getPricing.amplifica) || '...'
        }}</b-col>
      </b-form-row>
    </div>
    <div v-else>
      <b-form-row class="pl-1 pr-1">
        <b-col cols="4" class="title pt-5 pb-5"></b-col>
        <b-col class="values pt-5 pb-5">
          <b-spinner variant="primary"></b-spinner>
        </b-col>
      </b-form-row>
    </div>
  </b-card>
</template>

<script>
import _ from 'lodash';
import { businessLogicService } from '@/utils/fetch';
import helpers from '@/mixins/helpers';
import { mapGetters } from 'vuex';

export default {
  props: {
    program: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: false
    },
    pricingById: {
      type: Boolean,
      required: false
    }
  },

  mixins: [helpers],

  data() {
    return {
      pricing: {
        isLoading: false,
        data: {}
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getCompany']),

    getCustomerName() {
      if (!this.customer) {
        return '';
      }

      // Coming from /program/ or form (pre-post)
      return this.customer.related
        ? this.customer.related.name
        : this.customer.name;
    },

    getPricing() {
      if (this.pricing.isLoading) {
        return {};
      }

      return this.pricing.data;
    }
  },

  watch: {
    'pricing.isLoading': function (isLoading) {
      this.$emit('loading', isLoading);
    },

    'program.downpaymentPercentage': _.debounce(function (newValue) {
      let number = parseFloat(newValue);
      this.program.downpaymentPercentage = Math.min(100, Math.max(0, number));
    }, 300),
    'program.vocationEducation': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.providerId': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.customer': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.price': _.debounce(function (newVal) {
      this.refreshPricing();
    }, 500),
    'program.pricingType': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.numberGroups': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.maxLearners': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.learnersPerGroup': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    'program.calculateGeneralCosts': _.debounce(function () {
      this.refreshPricing();
    }, 500),
    customer: function () {
      this.refreshPricing();
    }
  },

  mounted() {
    // Load data
    this.refreshPricing();
  },

  methods: {
    /**
     * Update pricing!
     * @returns {boolean}
     */
    refreshPricing() {
      // Don't refresh too often
      if (this.pricing.isLoading) {
        return false;
      }

      let payload = {};
      if (this.program['@id'] && this.pricingById) {
        payload.programId = this.iriToId(this.program['@id']);
      } else {
        // }
        // Required data!
        if (
          !this.program.providerId ||
          !this.program.price ||
          !this.program.pricingType ||
          (!this.program.customer && !this.program.isOpenEnrollment)
        ) {
          return false;
        }

        const sectorId = this.program.isOpenEnrollment
          ? '/sectors/10'
          : this.customer.related.sector['@id'];

        const price = this.removeCommasFromString(this.program.price);

        payload = {
          providerId: this.program.providerId,
          sectorId: sectorId,
          price: price,
          pricingType: this.program.pricingType,
          priceFrom: this.program.calculateGeneralCosts
            ? 'PER_PROGRAM'
            : 'PER_MODULE',
          isNotVocationEducation: !this.program.vocationEducation
        };

        if (this.program.pricingType === 'GROUP') {
          (payload.learnersPerGroup = this.program.learnersPerGroup),
            (payload.maxLearners = this.program.maxLearners);
        }

        if (this.program.pricingType === 'LEARNER') {
          payload.maxLearners = this.program.maxLearners;
        }
      }
      this.pricing.isLoading = true;
      businessLogicService(
        '/programs/show-pricing?' + new URLSearchParams(payload),
        {
          method: 'GET'
        }
      )
        .then((response) => response.json())
        .then((data) => {
          this.pricing.data = data;
        })
        .finally(() => {
          this.pricing.isLoading = false;
        });
    },

    /**
     * O M G hacky
     * @param val
     * @returns {*}
     */
    removeCommasFromString(val) {
      const regex = /[.€\s]/g;

      if (typeof val === 'string') {
        const result = val.replace(regex, '');
        return result.replace(',', '.');
      }

      return val;
    }
  }
};
</script>

<style lang="scss">
#pricingTable {
  .values {
    text-align: left;
    padding-left: 15px;
  }

  & > .card-body {
    padding: 10px !important;
    //background: pink;
    background: #ffffff;
    box-shadow: 0px 6px 12px #0000004d;
    border: 1px solid #dbdddf;
    border-radius: 8px;
  }

  .card-header {
    padding: 0 !important;
    border-radius: 0;

    .title {
      text-align: right;
      padding-right: 10px;
      background: #1d344e !important;
    }
  }

  .card-body {
    .title {
      text-align: right;
      padding-right: 10px;
      background: #e4ecf1;
    }
  }

  .show-pricing {
    &__spinner {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
