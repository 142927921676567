<template>
  <div class="financial-invoices mt-4">
    <p class="font-weight-bold">Merged Invoices</p>

    <div class="mt-4" v-if="merged.length > 0">
      <div class="details__header">
        <span>Date</span>
        <span>Invoice Number</span>
      </div>
      <div class="mt-2" v-for="(item, index) in merged" :key="index">
        <div class="details__header" v-b-toggle="'collapse' + index">
          <span>{{ formatDate(item.invoiceDate) }}</span>
          <span class="details__header__title">
            {{ item.creatorInvoiceNumber }}
          </span>
        </div>

        <b-collapse :id="'collapse' + index">
          <General :invoiceItem="item" />
        </b-collapse>
      </div>
    </div>
    <p class="font-weight-bold text-center" v-else>No merged invoices.</p>
  </div>
</template>

<script>
import General from './General.vue';
import helpers from '@/mixins/helpers';

export default {
  name: 'Merges',

  mixins: [helpers],

  components: {
    General
  },

  props: {
    merged: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss">
.details {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
    }
  }
}
</style>
