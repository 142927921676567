<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <LearningModuleForm
      :program-id="programId"
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :values="item"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import LearningModuleForm from './Form';
import { SwalAlert } from '../../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'learningmodule/create/getField',
  mutationType: 'learningmodule/create/updateField'
});

export default {
  components: {
    LearningModuleForm
  },

  props: {
    programId: {
      type: String,
      required: true
    },

    showAlert: {
      type: Boolean,
      default() {
        return true;
      }
    }
  },

  mounted() {
    this.item.program = this.programId;
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createLearningModule');

      if (this.showAlert) {
        SwalAlert({
          title: 'Learning Module added',
          icon: 'user',
          type: 'success',
          buttons: {
            cancel: {
              label: 'Form another',
              type: 'warning',
              callback: () => {
                this.$bvModal.show('createLearningModule');
              }
            },
            confirm: {
              label: 'OK',
              type: 'success'
            }
          }
        });
      }

      // Clear store
      this.reset();
    }
  },

  methods: {
    ...mapActions('learningmodule/create', ['create', 'reset']),

    onSendForm(item) {
      this.create(item);
    }
  }
};
</script>
