<template>
  <div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <ContactsForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :errors="violations"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions, mapGetters } from 'vuex';
import ContactsForm from './Form';

const { mapFields } = createHelpers({
  getterType: 'room/create/getField',
  mutationType: 'room/create/updateField'
});

export default {
  components: {
    ContactsForm
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    ...mapFields(['error', 'isLoading', 'created', 'violations'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$bvModal.hide('createRoom');
    }
  },

  methods: {
    ...mapActions('room/create', ['create']),

    onSendForm(item) {
      let form = {
        context: 'chat',
        users: [{ user: item['@id'] }, { user: this.getUser.entity['@id'] }]
      };
      this.create(form);
    }
  }
};
</script>
