<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="creditNotes"
      @changePage="changePage"
    >
      <template #buttons>
        <b-button @click="$bvModal.show('createCreditNote')" variant="primary">
          <i class="fas fa-plus"></i>
          Add Credit Note
        </b-button>
      </template>
    </CustomTable>

    <b-modal size="xl" id="createCreditNote" title="Add Credit" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Add Credit</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createCreditNote')"
        ></i>
      </template>

      <Create />
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CustomTable from '@/components/_ui/tables/CustomTable';
import dayjs from 'dayjs';
import helpers from '@/mixins/helpers';
import Create from './Create';

export default {
  components: {
    CustomTable,
    Create
  },

  mixins: [helpers],

  computed: {
    ...mapGetters('auth', ['getUser']),

    getCompanyId() {
      return this.getUser.company.entity
        ? this.getUser.company.entity.company['@id']
        : '';
    },

    request() {
      let request = `${this.getCompanyId}/credit-notes?`;
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  data() {
    return {
      fields: [
        {
          key: 'createdAt',
          label: 'Date',
          sortable: true,
          formatter: (value, key, item) => {
            return dayjs(item.createdAt).format('DD/MM/YYYY');
          }
        },
        {
          key: 'amount',
          label: 'Amount',
          sortable: true,
          formatter: (v, k, item) => {
            this.formatCurrency(item.amount);
          }
        }
      ],
      itemsPerPage: 25,
      page: 1
    };
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'creditNotes/list/default',
      getInvoiceables: 'invoiceables/list/default'
    }),

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
      this.getInvoiceables(
        `${this.getCompanyId}/invoiceables?page=${this.page}&itemsPerPage=${this.itemsPerPage}`
      );
    }
  }
};
</script>
