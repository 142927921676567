<template>
  <div>
    <b-container fluid>
      <b-row class="mt-5">
        <b-col cols="12">
          <b-nav tabs>
            <b-nav-item
              class="mr-2"
              active-class="active"
              to="/toolbox/imported/relations"
              v-if="!hideInvoices"
            >
              <div class="template__tab">
                <h5>Relations</h5>
              </div>
            </b-nav-item>
            <b-nav-item
              class="mr-2"
              active-class="active"
              to="/toolbox/imported/invoices"
              v-if="!hideRelations"
            >
              <div class="template__tab">
                <h5>Invoices</h5>
              </div>
            </b-nav-item>
          </b-nav>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="bgContent">
            <router-view></router-view>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Toolbox',

  computed: {
    ...mapGetters('auth', ['isRole'])
  },

  data() {
    return {
      hideInvoices: false,
      hideRelations: false
    };
  },

  mounted() {
    if (this.isRole('ROLE_ADMIN') || this.isRole('ROLE_ACCOUNTANT')) {
      if (this.$route.name === 'ToolboxImportedRelations') {
        this.hideRelations = true;
      } else {
        this.hideInvoices = true;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.template {
  &__tab {
    h5 {
      //font-size: 16px;
    }
  }
}

.nav-tabs .nav-link.active {
  box-shadow: 1px 3px 10px $gray-400;
}

.nav-tabs .nav-link {
  box-shadow: 1px 3px 10px $gray-300;
}

.nav-link {
  padding: 10px 20px;
}

.nav-tabs {
  border-bottom: 0;
  margin-left: -1px;
}
</style>
