import LearningMaterialList from '@/components/common/learningmaterial/List';
import LearningMaterialCreate from '@/components/common/learningmaterial/Create';
import LearningMaterialUpdate from '@/components/common/learningmaterial/Update';
import LearningMaterialShow from '@/components/common/learningmaterial/Show';

export default [
  {
    name: 'LearningMaterialList',
    path: '/learning-materials/',
    component: LearningMaterialList
  },
  {
    name: 'LearningMaterialCreate',
    path: '/learning-materials/create',
    component: LearningMaterialCreate
  },
  {
    name: 'LearningMaterialUpdate',
    path: '/learning-materials/edit/:id',
    component: LearningMaterialUpdate
  },
  {
    name: 'LearningMaterialShow',
    path: '/learning-materials/show/:id',
    component: LearningMaterialShow
  }
];
