<template>
  <div>
    <div v-if="isLoading" class="alert alert-info" role="status">
      Loading...
    </div>
    <div v-if="error" class="alert alert-danger" role="alert">
      <span class="fa fa-exclamation-triangle" aria-hidden="true">{{
        error
      }}</span>
    </div>

    <RelationshipForm
      :is-loading="isLoading"
      :handle-submit="onSendForm"
      :skip-company-admin="skipCompanyAdmin"
      :values="item"
      :errors="violations"
      :show-type-person="showTypePerson"
      :is-creating-accountant="isCreatingAccountant"
      :is-creating-sub-contractor="isCreatingSubContractor"
      :is-creating-ubo="isCreatingUbo"
      :is-only-commercial="isOnlyCommercial"
      :block-branch="blockBranch"
    />
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import RelationshipForm from './Form';
import { SwalAlert } from '../../../utils/swal';

const { mapFields } = createHelpers({
  getterType: 'relationship/create/getField',
  mutationType: 'relationship/create/updateField'
});

export default {
  components: {
    RelationshipForm
  },

  props: {
    isCreatingAccountant: {
      type: Boolean,
      default: false
    },

    isCreatingSubContractor: {
      type: Boolean,
      default: false
    },

    skipCompanyAdmin: {
      type: Boolean,
      default: true
    },

    blockBranch: {
      type: Boolean,
      default: () => true
    },

    showTypePerson: {
      type: Boolean,
      default: false
    },

    isCreatingUbo: {
      type: Boolean,
      default: false
    },

    isOnlyCommercial: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.reset();
  },

  data() {
    return {
      item: {}
    };
  },

  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'violations']),
    ...mapFields('relationship/del', ['deleted'])
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return;
      }

      this.$emit('created', created);
      this.$root.$emit('relationship-refresh', created);

      this.$bvModal.hide('createRelationship');
      SwalAlert({
        title: 'Make it primary accountant ?',
        description:
          'Should this accountant be the your company primary accountant ?',
        icon: 'user',
        type: 'warning',
        buttons: {
          cancel: {
            label: 'No',
            type: 'warning',
            callback: () => {
              this.modalCreated(created);
            }
          },
          confirm: {
            label: 'Yes',
            type: 'success'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.$root.$emit('setPrimaryAccountant', created.related['@id']);
        }
      });
    },

    deleted: function (deleted) {
      if (!deleted) {
        return;
      }

      this.$emit('deleted', deleted);
    }
  },

  methods: {
    ...mapActions('relationship/create', ['create', 'reset']),

    onSendForm() {
      let body = this.item;

      if (this.isCreatingAccountant) {
        this.item.type = 'COMPANY';
        this.item.isAccountant = true;
      }

      if (this.isCreatingSubContractor) {
        this.item.type = 'SUBCONTRACTOR';
        this.item.isProvider = true;
      }

      this.create(body);
    },
    modalCreated() {
      SwalAlert({
        title: 'Relation added',
        icon: 'user',
        type: 'success',
        buttons: {
          cancel: {
            label: 'Form another',
            type: 'warning',
            callback: () => {
              this.$bvModal.show('createRelationship');
            }
          },
          confirm: {
            label: 'OK',
            type: 'success'
          }
        }
      });
    }
  }
};
</script>
