import PaymentIncomingList from '@/components/dashboard/payments/Incoming/List';
import PaymentOutgoingList from '@/components/dashboard/payments/Outgoing/List';
import Default from "@/views/dashboard/Payments/Default";

export default [
  {
    path: '/payments/incoming',
    component: Default,
    children: [
      {
        name: 'PaymentIncomingList',
        path: '/payments/incoming',
        component: PaymentIncomingList
      },
      {
        name: 'PaymentOutgoingList',
        path: '/payments/outgoing',
        component: PaymentOutgoingList
      }
    ]
  }
];

