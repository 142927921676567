<template>
  <b-row>
    <b-col cols="12" v-if="this.program.loaded">
      <table class="adminDetailsTable w-100 border-0">
        <tr class="customerRow">
          <th colspan="5">
            {{ program.customer.name }}
          </th>
        </tr>
      </table>
      <table
        class="adminDetailsTable w-100 border-0"
        v-for="(article, articleId) in getDataPerArticle"
        :key="articleId"
      >
        <tr class="customerDetails">
          <th
            class="pl-4 text-dark d-flex justify-content-between"
            colspan="3"
            v-b-toggle="`collapse_${program['@id']}_${articleId}`"
          >
            {{ article.entity.name }} <i class="fa fa-caret-down pt-3 pr-3" />
          </th>
        </tr>
        <tr>
          <b-collapse visible :id="`collapse_${program['@id']}_${articleId}`">
            <table class="adminDetailsTable w-100">
              <tr class="customerRow">
                <th colspan="1"></th>
                <th colspan="3">Company</th>
                <th colspan="3">Date</th>
                <th colspan="3">Status</th>
                <th colspan="2">Amount</th>
              </tr>
              <tr
                class="customerDetails"
                v-for="(shareable, shareableIdx) in article.shareables"
                :key="shareableIdx['@id']"
              >
                <td colspan="1" class="text-center">
                  <i
                    v-if="shareable.isAvailable && !shareable.isPaid"
                    :class="[shareable.selected ? 'fas' : 'far']"
                    @click="onRowSelected(shareable)"
                    class="fa-square text-primary"
                  />
                </td>
                <td colspan="3">
                  <span v-if="shareable.entity['@type'] === 'Shareholder'">{{
                    shareable.entity.owner.name
                  }}</span>
                  <span v-else>{{ shareable.entity.name }}</span>
                </td>
                <td colspan="3">{{ parseDate(shareable.createdAt) }}</td>
                <td colspan="3">
                  <span v-if="shareable.isPaid" class="text-green">PAID</span>
                  <span v-else class="text-muted">PENDING</span>
                </td>
                <td colspan="2">
                  {{ formatCurrency(shareable.amount.value) }}
                </td>
              </tr>
            </table>
          </b-collapse>
        </tr>
      </table>
    </b-col>
  </b-row>
</template>

<script>
import { businessLogicService as fetch } from '@/utils/fetch';
import { BUSINESS_SERVICE_ENTRYPOINT } from '@/config/entrypoint';
import helpers from '@/mixins/helpers';
import { mapGetters } from 'vuex';

export default {
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  mixins: [helpers],
  data() {
    return {
      //  items
      items: [],
      // B-table
      fields: [
        { key: 'article.name', label: 'Article' },
        {
          key: 'startDate',
          label: 'Start Date',
          formatter: (value, key, item) => {
            return this.parseDate(item.startDate);
          }
        },
        {
          key: 'grossAmount',
          label: 'Amount',
          formatter: (value, key, item) => {
            return `${item.grossAmount}€`;
          }
        },

        { key: 'selected2', label: '' }
      ]
    };
  },
  watch: {
    selected: {
      handler: function (newValue) {
        let bAllSelected = true;
        for (let i in this.items) {
          let selected = this.items[i].selected;
          if (!selected) {
            bAllSelected = false;
          }
          this.$emit('selected', {
            item: this.items[i],
            selected: selected
          });
        }

        // If some are not selected, unmark program
        this.$set(this.program, 'selected', bAllSelected);
      }
    },
    'program.selected': {
      deep: true,
      handler: function (newValue) {
        for (let i in this.items) {
          this.$set(this.items[i], 'selected', newValue);
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['getCompanyId']),
    selected() {
      return this.items.map((item) => item.selected);
    },
    getDataPerArticle() {
      // Owners
      let buffer = {};
      for (let i in this.items) {
        // Skip shareables not owned by us
        let owner = this.items[i].entity;
        // if (
        //   !(
        //     (owner['@type'] === 'Shareholder' &&
        //       owner.owner['@id'] === this.getCompanyId) ||
        //     (owner['@type'] === 'Company' && owner['@id'] === this.getCompanyId)
        //   )
        // ) {
        //   continue;
        // }
        //
        let article = this.items[i].invoiceable.article;
        if (!article) {
          continue;
        }

        // Create object
        if (!buffer[article['@id']]) {
          buffer[article['@id']] = {
            id: article['@id'],
            entity: article,
            shareables: {}
          };
        }

        // // Run each invoiceable
        // let invoiceable = this.items[i].invoiceable;
        // if (!buffer[article['@id']].invoiceables[invoiceable['@id']]) {
        //   buffer[article['@id']].invoiceables[invoiceable['@id']] = {
        //     entity: invoiceable,
        //     shareables: {}
        //   };
        // }

        buffer[article['@id']].shareables[this.items[i]['@id']] = this.items[i];
      }

      return buffer;
    }
  },
  created() {
    this.$set(this.program, 'openLoading', true);

    // Get details
    fetch(`${BUSINESS_SERVICE_ENTRYPOINT}${this.program['@id']}/shareables`)
      .then((response) => response.json())
      .then((data) => {
        this.items = data['hydra:member'];
      })
      .catch((error) => {
        this.errors = error;
      })
      .finally(() => {
        this.$set(this.program, 'loaded', true);
        this.$set(this.program, 'openLoading', false);
      });
  },
  methods: {
    onRowSelected(item) {
      this.$set(item, 'selected', !item.selected);
    },

    parseDate(date) {
      return this.$date.utc(date).format('YYYY-MM-DD HH:mm');
    }
  }
};
</script>
<style lang="scss">
.adminDetailsTable {
  color: black !important;
  td,
  th {
    border-top: 0 !important;
  }
  .customerRow {
    background: #e8eaec;
    th {
      height: 45px !important;
      line-height: 45px !important;
      padding: 0 10px;
      border-bottom: 1px solid white;
    }
  }
  .customerDetails {
    background: #f3f5f8;
    td {
      color: black;
      border-bottom: 1px solid white;
    }
  }
}
</style>
