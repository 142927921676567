const CSV = require('csv-string');
import moment from 'moment';

const helpers = {
  data() {
    return {
      delimiter: ','
    };
  },

  methods: {
    formatCurrency(number = 0, precision = 2, locale = 'nl-NL', currency = 'EUR') {
      if (typeof number != 'number') {
        number = parseFloat(number);
      }
      return Intl.NumberFormat(locale, {
        style: 'currency',
        minimumFractionDigits: precision,
        currency
      }).format(number);
    },

    iriToId(iri) {
      try {
        return iri.split('/').pop();
      } catch {
        return '';
      }
    },

    toUpperFirst(word) {
      let new_word = word.toLowerCase();

      return new_word.charAt(0).toUpperCase() + new_word.slice(1);
    },

    parseRelationToId(relation) {
      let ret = relation.split('/');
      return ret.pop();
    },

    parseRoleName(role) {
      let ret = role.replaceAll(/_|ROLE/gi, '');

      /*
       * THIS IS A HACK FOR NOW!! NEEDS TO BE CHANGED
       */
      if (ret === 'PROVIDER') ret = 'CREATOR';

      return this.capitalize(ret.toLowerCase().trim());
    },

    capitalize(s, multipleString = false, toLowerCase = false) {
      if (typeof s !== 'string') return '';

      if (multipleString) {
        return s
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
          .join(' ');
      }

      if (toLowerCase) {
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
      }

      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    csvToArray(str) {
      const delimiter = CSV.detect(str);
      this.delimiter = delimiter;
      const headers = str.slice(0, str.indexOf('\n')).replace('\r', '');

      return headers.split(delimiter);
    },

    asyncFileReader(file) {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve, reject) => {
        temporaryFileReader.onload = (e) => {
          resolve(e.target.result);
        };

        temporaryFileReader.onerror = (e) => {
          reject('error');
        };

        temporaryFileReader.readAsText(file);
      });
    },

    removeIndices(array, indices) {
      return array.filter((item, current_index) => {
        return indices.indexOf(current_index) === -1;
      });
    },

    htmlEntities(str) {
      return str.replace(/[^a-zA-Z ]/g, '');
    },
    /**
     * Cleanup the payload from the relations
     * @param payload
     */
    cleanPatchPayload(payload) {
      for (let i in payload) {
        if (typeof payload[i] === 'object' && payload[i] !== null) {
          if (payload[i].hasOwnProperty('@id')) {
            payload[i] = payload[i]['@id'];
          }
        }
      }

      return payload;
    },

    ellipsisJs(input, size = 5) {
      if (input.length > size) {
        return input.substring(0, size) + '...';
      }
      return input;
    },

    removeSpecialCharacters(string) {
      return string.replace(/[^a-zA-Z0-9]/g, '');
    },

    cleanStr(str) {
      if (str) {
        return str.replace(/\t/g, '');
      }

      return str;
    },

    getRoomSubject(context) {
      let string = 'Chat';

      if (context?.includes('/handshakes')) {
        const handshake = this.handshakes.filter(
          (item) => item['@id'] === context
        )[0];

        string = handshake.type;
      }
      return string;
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY');
    }
  }
};

export default helpers;
