// import LanguageList from '../../views/admin/Main';
import Main from '@/views/admin/Main';
import Default from '@/views/layout/Default';

// -------------- Sector --------------
import SectorRoutes from './sector';

// -------------- Theme --------------
import ThemeRoutes from './theme';

// -------------- Country --------------
import CountryRoutes from './country';

// -------------- Language --------------
import LanguageRoutes from './language';

// -------------- Settings --------------
import SettingsRoutes from './settings';

// -------------- Article --------------
import ArticleRoutes from './article';

// -------------- Protocol --------------
import ProtocolRoutes from './protocol';

// -------------- Learning Materials --------------
import LearningMaterialRoutes from './learningmaterial';

// -------------- Templates --------------
import TemplateRoutes from './templates';

import QuestionRoutes from './questions';

import TermsAndConditionsRoutes from './terms-and-conditions';
export default [
  {
    name: 'Admin',
    path: '/admin',
    component: Main,
    children: [
      { path: '/sectors', component: Default, children: [...SectorRoutes] },
      { path: '/themes', component: Default, children: [...ThemeRoutes] },
      { path: '/countries', component: Default, children: [...CountryRoutes] },
      {
        path: '/languages',
        component: Default,
        children: [...LanguageRoutes]
      },
      { path: '/settings', component: Default, children: [...SettingsRoutes] },
      { path: '/articles', component: Default, children: [...ArticleRoutes] },
      {
        path: '/protocols',
        component: Default,
        children: [...ProtocolRoutes]
      },
      {
        path: '/learning-materials',
        component: Default,
        children: [...LearningMaterialRoutes]
      },
      {
        path: '/templates',
        component: Default,
        children: [...TemplateRoutes]
      },
      {
        path: '/questions',
        component: Default,
        children: [...QuestionRoutes]
      },
      {
        path: '/terms-and-conditions',
        component: Default,
        children: [...TermsAndConditionsRoutes]
      }
    ]
  }
];
