<template>
  <b-container class="px-4 pb-4 bg-white" fluid>
    <div class="d-flex align-items-center">
      <img
        alt=""
        class="mr-2"
        src="@/assets/img/icons/menuicons/company@2x.png"
      />
      <h3 class="font-weight-bold mt-2">{{ content.title }}</h3>
    </div>

    <HandshakeView
      :handshakeUrl="handshakeUrl"
      :activeFields="fields"
      :disableActions="disableActions"
    />
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import HandshakeView from '@/components/handshake/List';

export default {
  name: 'Requests',

  components: {
    HandshakeView
  },

  props: {
    requestType: {
      type: String,
      default: 'Pending'
    }
  },

  computed: {
    ...mapGetters('auth', ['getCompanyId', 'isRole']),

    handshakeUrl() {
      if (this.content.request) {
        return `/handshakes?${this.content.request}`;
      }

      return '/handshakes';
    }
  },

  data() {
    return {
      content: {
        title: '',
        status: ''
      },
      fields: [],
      disableActions: false
    };
  },

  /**
   * * Computed properties runs before the data is loaded and causes the computed function to return the wrong request.
   * ! Because our behavior is synchronous we can use beforeMount instead of mounted to have the code run before computed properties are calculated.
   */

  beforeMount() {
    this.setContent();
  },

  methods: {
    ...mapActions({
      getHandshakes: `handshake/list/default`
    }),

    setContent() {
      this.setTableFields();

      const status = this.requestType;

      if (status === 'Rejected') {
        this.content = {
          title: 'Rejected',
          request: 'status=REJECTED'
        };
      } else if (status === 'History') {
        this.content = {
          title: 'History',
          request: 'status=ACCEPTED'
        };

        this.disableActions = true;
      } else {
        this.content = {
          title: 'Pending Requests',
          request: 'status=PENDING'
        };
        this.disableActions = false;
      }

      if (this.handshakeUrl) {
        this.getHandshakes(`${this.handshakeUrl}`);
      }
    },

    setTableFields() {
      if (this.isRole('ROLE_ACCOUNTANT')) {
        this.fields = ['company.name', 'type', 'status', 'actions'];
      }

      if (this.isRole('ROLE_PROVIDER') || this.isRole('ROLE_ADMIN')) {
        this.fields = [
          'type',
          'company.name',
          'user.email',
          'metadata',
          'actions',
          'status'
        ];
      }

      if (this.isRole('ROLE_USER')) {
        this.fields = [
          'type',
          'company.name',
          'user.email',
          'actions',
          'status'
        ];
      }
    },

    getHandshakeTypes() {
      if (this.isRole('ROLE_ACCOUNTANT')) {
        return '?type[]=ProviderSignupHandshake&type[]=CompanyAnalysisQuestionsHandshake';
      }

      if (this.isRole('ROLE_PROVIDER') || this.isRole('ROLE_ADMIN')) {
        return '?type[]=AccountantSignupHandshake&type[]=CompanyJoinHandshake';
      }
    }
  },

  watch: {
    requestType() {
      this.setContent();
    }
  }
};
</script>

<style lang="scss"></style>
