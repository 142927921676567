<template>
  <div class="invoice-show">
    <div
      class="invoice-show__item"
      v-for="(field, index) in fields"
      :key="index"
    >
      <h1 class="invoice-show__item__title">{{ field.title }}</h1>

      <b-row class="invoice-show__items">
        <b-col
          class="invoice-show__items__col"
          cols="6"
          v-for="(item, indexItem) in field.items"
          :key="indexItem"
        >
          <div
            :label="item.label"
            :label-for="item.label"
            :key="index"
            class="invoice-show__content"
          >
            <span class="invoice-show__content__label">{{ item.label }}</span>
            <span class="invoice-show__content__value">
              {{ formatCurrency(invoiceItem[item.key]) }}
              <span
                class="ml-2"
                v-if="
                  item.key === 'creatorCustomerId' &&
                  invoiceItem.creatorCustomer
                "
              >
                (Creator name: {{ invoiceItem.creatorCustomer.name }})
              </span>
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import helpers from '@/mixins/helpers';

export default {
  name: 'FinancialGeneral',

  mixins: [helpers],

  props: {
    invoiceItem: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      fields: [
        {
          title: 'General',
          items: [
            {
              key: 'creatorInvoiceNumber',
              label: 'Creator+ InvoiceNr'
            },
            {
              key: 'creatorCustomerId',
              label: 'Creator+ BeneficiaryID'
            },
            {
              key: 'description',
              label: 'InvoiceDescription'
            },
            {
              key: 'creatorProgramId',
              label: 'Program ID'
            },
            {
              key: 'creatorProgramName',
              label: 'Program name'
            },
            {
              key: 'customerProcurementNumber',
              label: 'Beneficiary+ Procurement Number'
            },
            {
              key: 'complaints',
              label: 'Complaints'
            },
            {
              key: 'rating',
              label: 'Rating'
            }
          ]
        },

        {
          title: 'Amounts',
          items: [
            {
              key: 'practicaAmountExcludingVAT',
              label: 'Practica excluding VAT'
            },
            {
              key: 'practicaVATAmount',
              label: 'Practica VAT'
            },
            {
              key: 'learningMaterialsAmountExcludingVAT',
              label: 'Learning Materials excluding VAT'
            },
            {
              key: 'learningMaterialsVATAmount',
              label: 'Learning Materials VAT'
            },
            {
              key: 'surchargeAmountExcludingVAT',
              label: 'Surcharge excluding VAT'
            },
            {
              key: 'surchargeVATAmount',
              label: 'Surcharge VAT'
            },
            {
              key: 'othersAmountExcludingVAT',
              label: 'Others excluding VAT'
            },
            {
              key: 'othersVATAmount',
              label: 'Others VAT'
            }
          ]
        },

        {
          title: 'Total Amounts',
          items: [
            {
              key: 'totalAmountExcludingVAT',
              label: 'Excluding VAT'
            },
            {
              key: 'totalVATAmount',
              label: 'VAT'
            },
            {
              key: 'totalAmount',
              label: 'Total'
            },
            {
              key: 'prematurelyCancelled',
              label: 'Prematurely cancelled'
            },
            {
              key: 'percentageExecutedBeforeCancellation',
              label: 'Percentage Executed Before Cancellation'
            },
            {
              key: 'creditedAmountExcludingVAT',
              label: 'Credited excluding VAT'
            }
          ]
        }
      ],

      skippedFields: [
        'creatorInvoiceNumber',
        'creatorProgramId',
        'creatorCustomerId',
        'description'
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.invoice-show {
  margin-top: 25px;

  &__items {
    margin-bottom: 25px;

    &__col {
      &:nth-child(4n + 1),
      &:nth-child(4n + 2) {
        .invoice-show__content__value {
          background-color: $gray-300;
        }
      }
    }
  }

  &__item {
    border-top: 1px solid $black;

    &__title {
      margin-top: 20px;

      // font-size: 24px;
      color: $black;
      font-weight: 600;
    }
  }

  &__content {
    width: 100%;
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &__label {
      font-weight: 600;
      width: 50%;
      // font-size: 14px;
    }

    &__value {
      color: $black;
      margin-left: 15px;

      width: 50%;

      border-radius: 10px;
      padding: 5px;
      text-align: right;

      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>
