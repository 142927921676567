<template>
  <i class="fa fa-times status__icon status__icon--danger"></i>
</template>

<script>
export default {
  name: 'Rejected'
};
</script>

<style lang="scss">
.status {
  &__icon {
    color: $white;
    border-radius: 15px;
    padding: 5px;
    // font-size: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    height: 25px;

    &--danger {
      background-color: $danger;
    }
  }
}
</style>
