<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="program"
      @changePage="changePage"
      showTitle
    >
      <template v-slot:title>
        <img src="@/assets/img/icons/menu/programs.svg" />
        Schedules overview
      </template>
      <template v-slot:customerName="data">
        <i v-if="data.item.isOpenEnrollment">(Open Enrollment)</i>
        <span v-else>{{ data.item.customer.name }}</span>
      </template>
      <template v-slot:actions="data">
        <div class="program__icon">
          <router-link
            :to="{
              name: 'ScheduleUpdate',
              params: { id: iriToId(data.item['@id']) }
            }"
            v-b-tooltip.hover
            title="Calendar"
          >
            <i class="fa fa-calendar text-info"></i>
          </router-link>
          <Cancel
            class="program__icon--reject ml-3"
            @click.native="
              proposalAcceptOrReject(data.item['@id'], 'to_cancelled')
            "
          />
        </div>
      </template>
    </CustomTable>

    <b-modal
      id="greenLight"
      hide-footer
      no-close-on-backdrop
      @hide="closeGreenLightModal"
    >
      <div class="text-center text-primary">
        You must have green light before creating any program
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import helpers from '@/mixins/helpers';
import programs from '@/mixins/programs';
import Cancel from '@/components/_ui/svg/Cancel';
import { entityService } from '@/utils/fetch';

export default {
  components: { CustomTable, Cancel },

  mixins: [programs, helpers],

  data() {
    return {
      editArticle: null,
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
        { key: 'customerName', label: 'Beneficiary', sortable: true },
        { key: 'accountant.name', label: 'Accountant', sortable: true },
        { key: 'theme.name', label: 'Theme', sortable: true },
        {
          key: 'totalPrice',
          label: 'Price',
          sortable: true,
          formatter: (value, key, item) => {
            return this.formatCurrency(item.price);
          }
        },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapGetters('auth', ['getCompany', 'getCompanyId']),

    ...mapFields('program/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/programs?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;
      request += `&status[]=ACCEPTED&status[]=SCHEDULING&status[]=PENDING`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      return request;
    }
  },

  mounted() {
    this.fetchCompany();
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'program/list/default'
    }),

    async fetchCompany() {
      await entityService(this.getCompanyId)
        .then((response) => response.json())
        .then((data) => {
          if (data.semaphoreState !== 'GREEN') {
            this.$bvModal.show('greenLight');
            return;
          }
        });
    },

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    closeGreenLightModal() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
.program {
  &__icon {
    display: flex;
    align-items: center;

    cursor: pointer;

    &--reject {
      height: 20px;

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
</style>
