<template>
  <div>
    <CustomTable
      @handleSortChange="handleSortChange"
      :fields="fields"
      list="sector"
      @changePage="changePage"
      @changeFilter="changeFilter"
      showSearchInput
    >
      <template v-slot:enabled="data">
        <Status class="text-center" :value="data.item.enabled" />
      </template>
      <template v-slot:actions="data">
        <a
          class="mr-2"
          href="#"
          @click.prevent="edit(data.item)"
          title="Edit"
          v-b-tooltip.hover
          ><i class="fa fa-edit text-info"></i
        ></a>
      </template>
    </CustomTable>

    <b-button class="mt-3 float-right" v-b-modal.createSector variant="primary">
      <i class="fas fa-plus"></i> Add Sector
    </b-button>

    <b-modal id="updateSector" title="Edit Sector" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Edit Sector</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('updateSector')"
        ></i>
      </template>

      <SectorUpdate @updated="refreshData" :sector="editSector"></SectorUpdate>
    </b-modal>

    <b-modal id="createSector" title="Create Sector" hide-footer>
      <template #modal-header>
        <div class="custom-modal__popup">
          <span class="custom-modal__popup__title">Create Sector</span>
        </div>
        <i
          class="fa fa-times custom-modal__popup__close"
          @click="$bvModal.hide('createSector')"
        ></i>
      </template>

      <SectorCreate @created="refreshData"></SectorCreate>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import CustomTable from '@/components/_ui/tables/CustomTable';
import Status from '@/components/_ui/tables/Status';
import SectorCreate from './Create';
import SectorUpdate from './Update.vue';

export default {
  components: {
    SectorCreate,
    SectorUpdate,
    CustomTable,
    Status
  },

  data() {
    return {
      editSector: {},
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'enabled', label: 'Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      sort: null,
      filter: null,
      itemsPerPage: 25,
      page: 1
    };
  },
  computed: {
    ...mapFields('sector/del', {
      deletedItem: 'deleted'
    }),

    request() {
      let request = '/sectors?';
      request += `page=${this.page}`;
      request += `&itemsPerPage=${this.itemsPerPage}`;

      if (this.sort) {
        const order = this.sort.sortDesc ? 'asc' : 'desc';
        request += `&order[${this.sort.sortBy}]=${order}`;
      }

      if (this.filter) {
        request += `&search=${this.filter}`;
      }

      return request;
    }
  },

  mounted() {
    this.refreshData();
  },

  methods: {
    ...mapActions({
      getPage: 'sector/list/default'
    }),

    changePage(page) {
      this.page = page;
      this.refreshData();
    },

    handleSortChange(sort) {
      this.sort = sort;
      this.refreshData();
    },

    changeFilter(filter) {
      this.filter = filter;
      this.refreshData();
    },

    refreshData() {
      this.getPage(this.request);
    },

    edit(item) {
      this.editSector = item;
      this.$bvModal.show('updateSector');
    }
  }
};
</script>
