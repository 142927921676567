<template>
  <b-container fluid>
    <b-row class="pt-5">
      <b-col md="12">
        <h1 class="text-left titleSettings">AmplificA Settings</h1>
      </b-col>
    </b-row>

    <b-row class="settingTabs mt-3">
      <div v-if="isRole('ROLE_ADMIN')" class="col-md-3 settingTab">
        <router-link to="/settings" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>General</span>
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="isRole('ROLE_ADMIN')" class="col-md-3 settingTab">
        <router-link to="/templates" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Document templates </span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/themes" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Themes</span>
            </div>
          </div>
        </router-link>
      </div>

      <div v-if="isRole('ROLE_ADMIN')" class="col-md-3 settingTab">
        <router-link to="/sectors" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Sectors</span>
            </div>
          </div>
        </router-link>
      </div>
    </b-row>

    <b-row class="settingTabs mt-3">
      <div class="col-md-3 settingTab">
        <router-link to="/questions" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Questions</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/countries" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Countries</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/languages" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Languages</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/admin" active-class="temp-fix-active">
          <div class="bgContent">
            <div class="header">
              <span>Translations</span>
            </div>
          </div>
        </router-link>
      </div>
    </b-row>
    <b-row class="settingTabs mt-3">
      <div class="col-md-3 settingTab">
        <router-link to="/learning-materials" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Learning Materials</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/protocols" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Protocols</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/articles" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Articles</span>
            </div>
          </div>
        </router-link>
      </div>

      <div class="col-md-3 settingTab">
        <router-link to="/terms-and-conditions" active-class="active">
          <div class="bgContent">
            <div class="header">
              <span>Terms And Conditions</span>
            </div>
          </div>
        </router-link>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  computed: {
    ...mapGetters('auth', ['isRole'])
  }
};
</script>

<style scoped lang="scss">
h1 {
  color: $primary;
  //// font-size: 24px;
  border-bottom: 1px solid $gray-200;
  padding-bottom: 35px;
  line-height: 40px;
}

h1,
h3 {
  &.titlePrograms,
  &.titleSettings,
  &.titleTranslations {
    color: $secondary;
    //// font-size: 24px;
    //border-bottom: 1px solid $gray-200;
    //padding-bottom: 35px;
    padding-left: 60px;
    font-weight: 700;

    &:before {
      left: 20px;
      position: absolute;
      content: '';
      background-repeat: no-repeat;
      width: 31px;
      height: 41px;
      display: inline-block;
      background-image: url('../../assets/img/icons/programs-icon.png');
    }
  }

  &.titleSettings {
    &:before {
      background-image: url('../../assets/img/icons/amplifica-settings-icon.png');
    }

    padding-left: 50px;
    line-height: 30px;
  }
}

.settingTabs {
  // width: 66%;
  //float: right;
  .settingTab {
    .bgContent {
      padding: 15px 30px;
    }

    .header {
      span {
        color: $secondary;
        //// font-size: 15px;
        font-weight: 700;
        line-height: 35px;
        text-decoration: none;
      }

      span.badge {
        background-color: $secondary;
        height: 35px;
        //// font-size: 14px;
        padding: 0px 20px;
        line-height: 32px;
        border-radius: 35px;

        &.orange {
          background-color: fade($orange, 20%);
          color: $orange;
        }

        &.red {
          background-color: fade($danger, 20%);
          color: $danger;
        }

        &.green {
          background-color: fade($success, 20%);
          color: $success;
        }

        &.blue {
          background-color: fade($primary, 20%);
          color: $primary;
        }
      }
    }

    a {
      color: $pink;

      &:hover,
      &.active {
        text-decoration: none;

        .bgContent {
          background-color: $primary;

          span {
            color: $white;
          }

          span.badge {
            background-color: rgba(255, 255, 255, 0.6);
            color: $white;
          }
        }
      }
    }
  }
}

.content.new {
  .settingTabActive {
    border-radius: 5px 0 0 5px;
    position: absolute;
    left: 15px;
    top: -70px;
    background: $white;
    padding: 20px 40px;
    display: inline-block;

    h1,
    h2,
    h3 {
      //// font-size: 20px;
      border: 0px;
      padding: 0px;
      font-weight: 700;
    }
  }
}

.settingsTemplate {
  .row {
    .col-md-12 {
      .content.new {
        background-color: red;
        border-radius: 100px 20px;
      }
    }
  }
}
</style>
