<template>
  <div class="company-roles mt-3">
    <b-form @submit.prevent="onSendForm()">
      <div>
        <span>Role(s)</span>
        <hr />
        <b-form-checkbox-group
          class="company-roles__checkbox"
          v-model="selectedRoles"
          :options="roles"
          size="lg"
          switches
        ></b-form-checkbox-group>
        <hr />
      </div>

      <div class="float-right mt-5">
        <b-button
          type="button"
          variant="secondary mr-2"
          :disabled="isLoading"
          @click="$bvModal.hide('editCompany')"
          >Cancel</b-button
        >
        <b-button type="submit" variant="primary" v-if="!isLoading"
          >Save</b-button
        >
        <b-button type="submit" variant="primary" v-else>
          <b-spinner small></b-spinner>
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';

export default {
  name: 'Roles',

  props: {
    company: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapFields('company/update', {
      isLoading: 'isLoading',
      updated: 'updated'
    })
  },

  data() {
    return {
      selectedRoles: [],
      roles: [
        { text: 'Accountant', value: 'isAccountant' },
        { text: 'Creator', value: 'isProvider' },
        { text: 'Instructor', value: 'isInstructor' },
        { text: 'Amplifica', value: 'isAmplifica' }
      ]
    };
  },

  mounted() {
    if (this.company.isAccountant) {
      this.selectedRoles.push('isAccountant');
    }

    if (this.company.isProvider) {
      this.selectedRoles = [...this.selectedRoles, 'isProvider'];
    }

    if (this.company.isInstructor) {
      this.selectedRoles = [...this.selectedRoles, 'isInstructor'];
    }

    if (this.company.isAmplifica) {
      this.selectedRoles = [...this.selectedRoles, 'isAmplifica'];
    }
  },

  methods: {
    ...mapActions({
      update: 'company/update/update'
    }),

    onSendForm() {
      let item = {};

      this.roles.forEach((role) => {
        if (this.selectedRoles.includes(role.value)) {
          item[role.value] = true;
          return;
        }
        item[role.value] = false;
      });

      this.update(item);
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return;
      }

      this.$emit('updated');
    }
  }
};
</script>

<style lang="scss">
.company-roles {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #c7c7c7;
    background-color: #6fb942;
  }
  .custom-control-input ~ .custom-control-label::before {
    color: #fff;
    border-color: #c7c7c7;
    background-color: #e04a46;
  }
  .custom-switch.b-custom-control-lg .custom-control-label::after,
  .input-group-lg .custom-switch .custom-control-label::after {
    width: calc(1.5rem - 4px);
    height: calc(1.5rem - 4px);
    top: calc(0.2125rem + 2px);
    left: calc(-2.9125rem + 2px);
  }
  .custom-switch .custom-control-label::after {
    background-color: #fff !important;
    border-radius: 1.5rem;
    border: 2px solid #797979;
  }
  &__title {
    // font-size: 14px;
    font-weight: 800;
    color: $black;
  }

  &__checkbox {
    display: grid;
    column-gap: 10%;
    grid-template-columns: 150px 150px;
  }
}
</style>
