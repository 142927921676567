import { entityService } from '@/utils/fetch';
import { SwalAlert } from '@/utils/swal';

const handshakes = {
  methods: {
    confirmHandshakeAcceptOrReject(id, type) {
      entityService(id, {
        method: 'PATCH',
        headers: new Headers({
          'Content-Type': 'application/merge-patch+json'
        }),
        body: JSON.stringify({
          statusTransition: type
        })
      })
        .then((response) => response.json())
        .then((data) => {
          SwalAlert({
            title: type === 'to_approved' ? 'Success' : 'Rejected',
            type: type === 'to_approved' ? 'success' : 'error',
            description:
              type === 'to_approved'
                ? 'Your request was accepted!'
                : 'Your request was rejected!',
            buttons: {
              confirm: {
                label: 'OK',
                type: type === 'to_approved' ? 'success' : 'danger'
              }
            }
          });

          this.$router.push('/requests/pending');
        })
        .catch((e) => {
          let error = e.errors ? e.errors._error : e;
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: error,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        });
    },
    handshakeAcceptOrReject(id, type) {
      SwalAlert({
        subTitle: 'Are you sure?',
        description: 'This action will be definite',
        icon: 'delete',
        type: 'warning',
        buttons: {
          cancel: {
            label: 'Cancel',
            type: 'warning'
          },
          confirm: {
            label: 'Confirm',
            type: 'success',
            callback: () => {
              this.confirmHandshakeAcceptOrReject(id, type);
            }
          }
        }
      });
    },

    requestValidation(companyId, handshakeType) {
      this.isRequestValidationLoading = true;

      const payload = {
        type: handshakeType
      };

      entityService('/handshakes', {
        method: 'POST',
        body: JSON.stringify(payload)
      })
        .then((response) => response.json())
        .then((data) => {
          this.isRequestValidationLoading = false;
          SwalAlert({
            title: 'Request processed!',
            icon: 'user',
            type: 'success',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'success'
              }
            }
          });
        })

        .catch((e) => {
          this.isRequestValidationLoading = false;
          SwalAlert({
            title: 'Error',
            icon: 'error',
            description: e.message,
            type: 'danger',
            buttons: {
              confirm: {
                label: 'OK',
                type: 'danger'
              }
            }
          });
        });
    }
  }
};

export default handshakes;
